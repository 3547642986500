import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import { teal800, grey600 } from "material-ui/styles/colors";
import Chip from "material-ui/Chip";
import Avatar from "material-ui/Avatar";

class PredictionChip extends React.Component {
  render() {
    const { prediction, selected, ...rest } = this.props;
    return (
      <Chip
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: selected
            ? themeMainUI.palette.automationBackgroundColor
            : themeMainUI.palette.primary3Color,
          marginRight: "5px",
          marginBottom: "5px",
          padding: "0 5px"
        }}
        labelColor="white"
        {...rest}
      >
        {this.props.children}&nbsp;
        {prediction ? (
          <Avatar size={24} backgroundColor={selected ? teal800 : grey600}>
            {prediction}%
          </Avatar>
        ) : (
          ""
        )}
      </Chip>
    );
  }
}

export default PredictionChip;
