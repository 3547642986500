import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import ProviderButton from "./ProviderButton";
import { MainContainer, FormContainer, CenterContainer } from "../styles";
import StepButtons from "../../../components/StepButtons";
import { providers } from "../index";

const ProviderSelection = props => {
  const { selectedProviders, addProvider, removeProvider, onNext } = props;

  useEffect(() => {
    if (selectedProviders.length === 0) {
      addProvider(providers[0].value);
    }
  }, [selectedProviders, providers]);

  const handleClick = useCallback(
    provider => {
      if (selectedProviders.includes(provider)) {
        removeProvider(provider);
      } else {
        addProvider(provider);
      }
    },
    [selectedProviders]
  );

  return (
    <MainContainer>
      <CenterContainer>1. Choose your provider(s)</CenterContainer>
      <FormContainer>
        {providers.map(provider => (
          <ProviderButton
            key={provider.label}
            isSelected={selectedProviders.includes(provider.value)}
            icon={provider.icon}
            text={provider.label}
            onClick={() => handleClick(provider.value)}
            disabled={provider.disabled}
          />
        ))}
      </FormContainer>
      <StepButtons onNext={onNext} />
    </MainContainer>
  );
};

ProviderSelection.propTypes = {
  selectedProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
  addProvider: PropTypes.func.isRequired,
  removeProvider: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default ProviderSelection;
