// Stores styles for Tickets.js
import Styles from "../../styles/themeMainUI";

const TicketDetailsStyles = {
  headline: {
    fontSize: 35,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  slide: {},
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 0",
    alignItems: "center"
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    paddingBottom: "5px",
    paddingLeft: 0,
    paddingRight: 0
  },
  fieldContainer: {
    paddingBottom: "5px",
    paddingLeft: 15,
    paddingRight: 15
  },
  chip: {
    margin: 4,
    alignItems: "center"
  },
  wrapper: {
    display: "flex",
    flexWrap: "nowrap"
  },
  mainWrapper: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 30px",
    margin: "0 auto"
  },
  contentHeaderMobile: {
    flexGrow: 1,
    padding: "0px 10px"
  },
  contentWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden"
  },
  contentWrapperMobile: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden"
  },
  contentScroller: {
    maxHeight: "inherit",
    overflow: "hidden",
    padding: "0 30px",
    maxWidth: 1000,
    margin: "0 auto"
  },
  contentActionsContainer: {
    padding: "20px 20px",
    paddingBottom: 30,
    flexGrow: 0,
    flexShrink: 0,
    alignItems: "center",
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  contentActions: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  status: {
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
    marginTop: 10
  },
  estimate: {
    fontSize: "13px",
    display: "flex",
    alignItems: "baseline",
    paddingBottom: "8px"
  },
  primaryFont: {
    marginRight: 10,
    color: Styles.palette.primary2Color
  },
  ticketNumber: {
    fontWeight: 300,
    margin: 0,
    display: "flex"
  },
  errorText: {
    paddingTop: 5,
    fontSize: 12,
    color: Styles.palette.errorColor
  },
  warningText: {
    paddingTop: 5,
    fontSize: 12,
    color: Styles.palette.textColor
  },
  ticketType: {
    marginRight: "40px",
    marginTop: "19px",
    fontSize: "13px"
  },
  lastUpdateDate: {
    fontSize: 12,
    color: "rgb(189, 189, 189)"
  }
};

export default TicketDetailsStyles;
