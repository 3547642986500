import React from "react";
import SecondaryCircularProgress from "../../../templates/SecondaryCircularProgress";
import history from "../../../../history";
import { Chip } from "material-ui";
import themeMainUI from "../../../../styles/themeMainUI";
import uuid from "uuid";

const localStyles = {
  title: {
    fontSize: 15,
    marginTop: 15,
    marginBottom: 5
  },
  items: {
    fontSize: 12,
    color: themeMainUI.palette.primary2Color,
    cursor: "pointer",
    marginBottom: 5
  },
  stateContainer: {
    display: "inline-block"
  },
  stateLabel: {
    fontSize: 11,
    padding: 10,
    lineHeight: 2
  }
};

export default class ServiceRequestLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.handleNewProps(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleNewProps(nextProps);
  }

  handleNewProps(nextProps, isNew) {
    if (
      isNew ||
      this.props.ticket.coreData.number !== nextProps.ticket.coreData.number
    ) {
      const SRnumber =
        nextProps.ticket.serviceData.request ||
        nextProps.ticket.coreData.number;
      this.props.getServiceRequest(SRnumber);
      this.props.getServiceRequestItems(SRnumber);
      this.props.getServiceRequestTasks(SRnumber);
    }
  }

  openTicket(ticket) {
    this.props.closeDetailsTicket(ticket.coreData.number);
    this.props.openDetails(ticket);
    history.push(`/atr/tickets/${ticket.coreData.number}`);
  }

  render() {
    const { serviceRequest, srItems, srTasks } = this.props;

    return (
      <div>
        <div>
          <div style={localStyles.title}>Service Request</div>
          {// Service Request
          serviceRequest && serviceRequest.coreData ? (
            <div
              onClick={() => this.openTicket(serviceRequest)}
              style={localStyles.items}
            >
              <Chip
                style={localStyles.stateContainer}
                labelStyle={localStyles.stateLabel}
              >
                {serviceRequest.coreData.state}
              </Chip>
              &nbsp;&nbsp;
              {serviceRequest.coreData.number}
              {serviceRequest.coreData.shortDescription &&
                ` - ${serviceRequest.coreData.shortDescription}`}
              &nbsp;
            </div>
          ) : (
            <SecondaryCircularProgress size={20} thickness={2} />
          )}
        </div>

        <div>
          <div style={localStyles.title}>Service Request Items</div>
          {// Service Request Items
          srItems ? (
            srItems.map(item => (
              <div
                onClick={() => this.openTicket(item)}
                style={localStyles.items}
                key={uuid.v4()}
              >
                <Chip
                  style={localStyles.stateContainer}
                  labelStyle={localStyles.stateLabel}
                >
                  {item.coreData.state}
                </Chip>
                &nbsp;&nbsp;
                {item.coreData.number}
                {item.coreData.shortDescription &&
                  ` - ${item.coreData.shortDescription}`}
                &nbsp;
              </div>
            ))
          ) : (
            <SecondaryCircularProgress size={20} thickness={2} />
          )}
        </div>

        <div key={uuid.v4()}>
          <div style={localStyles.title}>Service Request Tasks</div>
          {// Service Request Tasks
          srTasks ? (
            srTasks.map(task => (
              <div
                onClick={() => this.openTicket(task)}
                style={localStyles.items}
                key={uuid.v4()}
              >
                <Chip
                  style={localStyles.stateContainer}
                  labelStyle={localStyles.stateLabel}
                >
                  {task.coreData.state}
                </Chip>
                &nbsp;&nbsp;
                {task.coreData.number}
                {task.coreData.shortDescription &&
                  ` - ${task.coreData.shortDescription}`}
                &nbsp;
              </div>
            ))
          ) : (
            <SecondaryCircularProgress size={20} thickness={2} />
          )}
        </div>
      </div>
    );
  }
}
