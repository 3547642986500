import styled from "styled-components";

export const ScrollContainer = styled.div`
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
  }
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 30px, 100% 30px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

  @media screen and (max-width: 992px) {
    height: 55vh;
  }

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    height: 57vh;
  }

  @media screen and (max-height: 700px) {
    height: 55vh;
  }

  @media screen and (min-height: 700px) and (max-height: 800px) {
    height: 57vh;
  }
`;
