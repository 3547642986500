import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { StyledDelete } from "./StyledComponents/shared";
import { useDispatch } from "react-redux";
import {
  DELETE_RUNDECK,
  RESET_DELETE_RUNDECK
} from "actions/rundeckConfiguration";

export default function RundeckConfirmationDialog(rundeckName) {
  const [open, setOpen] = React.useState(false);
  const [rundeckNameValue] = useState(rundeckName.value);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    dispatch({
      type: RESET_DELETE_RUNDECK
    });
  };

  const handleDelete = () => {
    setOpen(false);
    if (rundeckNameValue !== "undefined" || rundeckNameValue !== undefined) {
      dispatch({
        type: DELETE_RUNDECK,
        data: rundeckNameValue
      });
    }
  };

  return (
    <div>
      <StyledDelete onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Automatic Script: " + rundeckNameValue}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this configuration may break existing mappings and rules.
            These will need to be manually updated to ensure automation works as
            expected.
          </DialogContentText>
          <br />
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this configuration?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
