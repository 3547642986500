import React from "react";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faJira } from "@fortawesome/free-brands-svg-icons";
import SNOWForm from "../../components/SNOWForm/container";
import { PROVIDERS } from "../../../../../../models/AutomationFilterModel";

export const providers = [
  {
    label: "ServiceNow",
    icon: faUserCircle,
    form: <SNOWForm />,
    disabled: false,
    value: PROVIDERS.serviceNow
  },
  {
    label: "Jira",
    icon: faJira,
    disabled: true,
    value: PROVIDERS.jira
  },
  {
    label: "Outlook",
    icon: "outlook.svg",
    disabled: true,
    value: PROVIDERS.outlook
  },
  {
    label: "Azure",
    icon: "azure.svg",
    disabled: true,
    value: PROVIDERS.azure
  }
];
