import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InfoPanel from "../../components/InfoPanel";
import { Grid } from "@material-ui/core";
import {
  HeaderText,
  TicketTypeText,
  FilterText,
  FilterContainer
} from "./styles";
import { getSNOWQueryFilter, getProviderDetails } from "./utils";
import { PROVIDERS } from "../../../../../../../../models/AutomationFilterModel";

const ITSMInfo = props => {
  const { handleChange, configs, selectedProviders, providerFilters } = props;
  const [providerDetails, setProviderDetails] = useState([]);

  // TODO update table format

  useEffect(() => {
    const providers = selectedProviders.map(provider => {
      const details = getProviderDetails(provider, configs);
      // only retrieve filters for SNOW
      if (
        provider === PROVIDERS.serviceNow &&
        details !== undefined &&
        providerFilters[PROVIDERS.serviceNow] !== undefined
      ) {
        details.filters = providerFilters[PROVIDERS.serviceNow].ticketTypes.map(
          ticketType => ({
            ticketType: ticketType.ticketType,
            displayName: ticketType.displayName,
            filter: getSNOWQueryFilter(ticketType.filters)
          })
        );
        details.displayName = providerFilters[PROVIDERS.serviceNow].displayName;
      }
      return details;
    });
    setProviderDetails(providers.filter(provider => provider));
  }, [selectedProviders, configs, providerFilters, setProviderDetails]);

  return (
    <InfoPanel title="ITSM provider" onEdit={() => handleChange(0)}>
      <Grid container style={{ height: "100%" }}>
        {providerDetails.map((details, index) => (
          <Grid
            container
            key={`details-${details.provider}-${index}`}
            style={{ height: "100%" }}
          >
            <Grid item xs={12}>
              <HeaderText>{details.displayName ?? details.provider}</HeaderText>
              <div>{details.instance}</div>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <HeaderText>Ticket Type</HeaderText>
              </Grid>
              <Grid item xs={8}>
                <HeaderText>Filter Query</HeaderText>
              </Grid>
            </Grid>
            <FilterContainer container item xs={12}>
              {details.filters.map(({ displayName, filter }) => (
                <Grid
                  key={`filters-${details.provider}-${index}-${displayName}`}
                  container
                  item
                  xs={12}
                  style={{ marginBottom: "5px" }}
                >
                  <Grid item xs={4}>
                    <TicketTypeText>{displayName}</TicketTypeText>
                  </Grid>
                  <Grid item xs={8}>
                    <FilterText>{filter}</FilterText>
                  </Grid>
                </Grid>
              ))}
            </FilterContainer>
          </Grid>
        ))}
      </Grid>
    </InfoPanel>
  );
};

ITSMInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectedProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
  providerFilters: PropTypes.object.isRequired,
  configs: PropTypes.object.isRequired
};

export default ITSMInfo;
