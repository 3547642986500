import history from "../history";
import { openSnackbar } from "./snackbar";
import { CLOSE_DETAILS } from "./details";
import config from "../config";
import { userConfig } from "../userConfigValidations";
import { isEmpty } from "lodash";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const TICKET_GET_ALL_PENDING = "TICKET_GET_ALL_PENDING";
export const TICKET_GET_ALL_COMPLETED = "TICKET_GET_ALL_COMPLETED";
export const TICKET_GET_ALL_FULFILLED = "TICKET_GET_ALL_FULFILLED";
export const TICKET_GET_ALL_ERROR = "TICKET_GET_ALL_ERROR";

export const TICKET_GET_SINGLE_PENDING = "TICKET_GET_SINGLE_PENDING";
export const TICKET_GET_SINGLE_FULFILLED = "TICKET_GET_SINGLE_FULFILLED";
export const TICKET_GET_SINGLE_ERROR = "TICKET_GET_SINGLE_ERROR";

export const TICKET_UPDATE_SINGLE_PENDING = "TICKET_UPDATE_SINGLE_PENDING";
export const TICKET_UPDATE_SINGLE_FULFILLED = "TICKET_UPDATE_SINGLE_FULFILLED";
export const TICKET_GET_SINGLE_FULFILLED_POPULATED =
  "TICKET_GET_SINGLE_FULFILLED_POPULATED";
export const TICKET_UPDATE_SINGLE_ERROR = "TICKET_UPDATE_SINGLE_ERROR";

export const TICKET_CREATE_SINGLE_PENDING = "TICKET_CREATE_SINGLE_PENDING";
export const TICKET_CREATE_SINGLE_FULFILLED = "TICKET_CREATE_SINGLE_FULFILLED";
export const TICKET_CREATE_SINGLE_ERROR = "TICKET_CREATE_SINGLE_ERROR";

export const TICKET_SELECT_AUTOMATION_JOB = "TICKET_SELECT_AUTOMATION_JOB";

export const TICKET_GET_SIMILAR_PENDING = "TICKET_GET_SIMILAR_PENDING";
export const TICKET_GET_SIMILAR_FULFILLED = "TICKET_GET_SIMILAR_FULFILLED";

export function ticketSelectAutomationJob(automationJob) {
  return {
    type: TICKET_SELECT_AUTOMATION_JOB,
    automationJob
  };
}

// ====================
// TICKET CACHE POLLER
// ====================
// change local page using this state.
// moveIndex specifies relative distance from localPage

export const TICKET_CACHE_POLL_BEGIN = "TICKET_CACHE_POLL_BEGIN";
export const TICKET_CACHE_POLL_SUCCESS = "TICKET_CACHE_POLL_SUCCESS";
export const TICKET_CACHE_POLL_ERROR = "TICKET_CACHE_POLL_ERROR";

// ==================
// MOVE CACHE POINTER
// ==================
// change local page using this state.
// moveIndex specifies relative distance from localPage

export const TICKET_CACHE_MOVE_POINTER = "TICKET_CACHE_MOVE_POINTER";

export function moveTicketCachePointer(
  moveIndex,
  localPage,
  cache,
  sortBy,
  filters,
  ticketType,
  ticketTimeRange,
  presetSelected,
  polling
) {
  return async function(dispatch, getState) {
    if (cache == false) {
      cache = getState().tickets.cacheObject;
    }
    if (
      cache.cacheLocalFinal > 0 &&
      (moveIndex + localPage < 1 ||
        moveIndex + localPage > cache.cacheLocalFinal)
    ) {
      return null;
    }

    if (Math.abs(moveIndex) > 0) {
      // Change page state
      dispatch(
        retrieveTicketCache2(
          moveIndex,
          localPage,
          cache,
          sortBy,
          filters,
          ticketType,
          ticketTimeRange,
          presetSelected
        )
      );

      dispatch(retrieveListFromTicketCache(localPage + moveIndex, cache));
    } else {
      // Filter state
      dispatch(
        retrieveTicketCacheAndList(
          localPage,
          cache,
          sortBy,
          filters,
          ticketType,
          ticketTimeRange,
          presetSelected,
          polling
        )
      );
    }
  };
}

export const TICKET_GET_CACHE_SORT_FILTER_PENDING =
  "TICKET_GET_CACHE_SORT_FILTER_PENDING";
export const TICKET_GET_CACHE_SORT_FILTER_INITIALISED =
  "TICKET_GET_CACHE_SORT_FILTER_INITIALISED,";
export const TICKET_GET_SINGLE_TICKET_CACHE_SORT_FILTER =
  "TICKET_GET_SINGLE_TICKET_CACHE_SORT_FILTER";
export const SET_TOTAL_TICKETS_COUNT = "SET_TOTAL_TICKETS_COUNT";

export function retrieveTicketCacheAndList(
  localPage,
  cache,
  sortBy,
  filters,
  ticketType,
  ticketTimeRange,
  presetSelected,
  polling
) {
  return async function(dispatch, getState) {
    if (!polling) {
      dispatch({
        type: TICKET_GET_CACHE_SORT_FILTER_PENDING
      });
    }

    let response;
    if (polling) {
      const globalPage =
        Math.ceil((localPage * LOCAL_CACHE_RANGE) / GLOBAL_CACHE_RANGE) - 1;
      response = await retrieveTickets(
        globalPage,
        GLOBAL_CACHE_RANGE,
        sortBy,
        filters,
        ticketType,
        ticketTimeRange,
        presetSelected,
        null,
        getState
      );
    } else {
      response = await retrieveTickets(
        0,
        GLOBAL_CACHE_RANGE,
        sortBy,
        filters,
        ticketType,
        ticketTimeRange,
        presetSelected,
        null,
        getState
      );
    }

    // If fetched ticketType is not the same as the current one, don't show it
    if (getState().tickets.selectedTicketType !== ticketType) {
      return;
    }
    if (response) {
      dispatch({
        type: SET_TOTAL_TICKETS_COUNT,
        count: response.count
      });

      dispatch({
        type: TICKET_GET_CACHE_SORT_FILTER_INITIALISED,
        cacheLocalFinal: response.cacheLocalFinal,
        cacheLocalTickets: response.cacheLocalTickets,
        cacheLocalPointer: 1
      });
    }

    // Change to user to page 1 when a new filter/sort is selected if outside of list
    if (polling) {
      dispatch(retrieveListFromTicketCache(localPage, response));
    } else {
      dispatch(movePointer(1));
      dispatch(retrieveListFromTicketCache(1, response));
    }
  };
}

export const TICKET_GET_SINGLE_TICKET_CACHE = "TICKET_GET_SINGLE_TICKET_CACHE";

// ==========================
// RETRIEVE TICKET FROM CACHE
// ==========================
// retrieve a ticketList from the cache

export function retrieveListFromTicketCache(localPage, cache) {
  return function(dispatch, getState) {
    if (
      cache &&
      cache.cacheLocalTickets &&
      cache.cacheLocalTickets[localPage]
    ) {
      // retrieve ticket
      dispatch({
        type: TICKET_GET_SINGLE_TICKET_CACHE,
        tickets: cache.cacheLocalTickets[localPage],
        isFound: true
      });
    } else {
      dispatch({
        type: TICKET_GET_SINGLE_TICKET_CACHE,
        tickets: [],
        isFound: false
      });
    }
  };
}

/* Experimental */
export const TICKET_GET_POLLING_TICKET_CACHE =
  "TICKET_GET_POLLING_TICKET_CACHE";
export const TICKET_REFRESH_CURRENT_PAGE = "TICKET_REFRESH_CURRENT_PAGE";

export const TICKET_GET_ALL_TICKET_CACHE_PENDING =
  "TICKET_GET_ALL_TICKET_CACHE_PENDING";
export const TICKET_GET_ALL_TICKET_CACHE_INITIALISED =
  "TICKET_GET_ALL_TICKET_CACHE_INITIALISED";
export const TICKET_GET_ALL_TICKET_CACHE_ADD =
  "TICKET_GET_ALL_TICKET_CACHE_ADD";
export const TICKET_GET_ALL_TICKET_CACHE_ERROR =
  "TICKET_GET_ALL_TICKET_CACHE_ERROR";

// ============================
// RETRIEVE CACHE FROM ENDPOINT
// ============================
// TODO: Add cache freshness features.

export const GLOBAL_CACHE_RANGE = 50; // how many tickets should be cached at one time
const MOVEMENT_INDEX = 1; // how far from the current global page should you cache at worst case
export const LOCAL_CACHE_RANGE = 10; // how much tickets should be rendered on screen at one time

// TODO: deprecate this
export function retrieveTicketCache(
  localPage,
  cache,
  sortBy,
  filters,
  ticketType,
  ticketTimeRange,
  presetSelected
) {
  // localPage start at 1 (as per Frontend)
  // globalPage start at 0 (as per API)

  return async function(dispatch, getState) {
    dispatch({
      type: TICKET_GET_ALL_TICKET_CACHE_PENDING
    });

    // convert local page + local per page into global page and global per page
    const globalPage =
      Math.ceil((localPage * LOCAL_CACHE_RANGE) / GLOBAL_CACHE_RANGE) - 1;
    // ===============
    // CACHE RETRIEVAL
    // ===============

    // check if there is a cache. If there is no cache, preload cache.
    if (typeof cache.cacheLocalTickets === "undefined" || localPage <= 0) {
      const response = await retrieveTickets(
        0,
        GLOBAL_CACHE_RANGE,
        sortBy,
        filters,
        ticketType,
        ticketTimeRange,
        presetSelected,
        null,
        getState
      );
      dispatch(
        Object.assign({}, response, {
          type: TICKET_GET_ALL_TICKET_CACHE_INITIALISED
        })
      );
    }

    dispatch(movePointer(localPage));

    // ===============
    // CACHE ENRICHING
    // ===============

    if (typeof cache.cacheLocalTickets !== "undefined") {
      const incrementalCache = cache.cacheLocalTickets;

      // retrieve next global page up if not existent in the current cache AND if it is not over the final page
      if (
        !incrementalCache[
          localPage +
            Math.abs(MOVEMENT_INDEX * (GLOBAL_CACHE_RANGE / LOCAL_CACHE_RANGE))
        ] &&
        localPage + MOVEMENT_INDEX <= cache.cacheLocalFinal
      ) {
        const response = await retrieveTickets(
          globalPage + MOVEMENT_INDEX,
          GLOBAL_CACHE_RANGE,
          sortBy,
          filters,
          ticketTimeRange,
          presetSelected,
          null,
          null,
          getState
        );

        response.cacheLocalTickets = Object.assign(
          incrementalCache,
          response.cacheLocalTickets
        );
        dispatch(
          Object.assign({}, response, { type: TICKET_GET_ALL_TICKET_CACHE_ADD })
        );
      } else if (
        !incrementalCache[
          localPage -
            Math.abs(MOVEMENT_INDEX * (GLOBAL_CACHE_RANGE / LOCAL_CACHE_RANGE))
        ] &&
        localPage - MOVEMENT_INDEX >= 0
      ) {
        // Retrieve next global page down if not existent AND if it is not over the first page

        const response = await retrieveTickets(
          globalPage - MOVEMENT_INDEX,
          GLOBAL_CACHE_RANGE,
          sortBy,
          filters,
          ticketTimeRange,
          presetSelected,
          null,
          null,
          getState
        );
        response.cacheLocalTickets = Object.assign(
          incrementalCache,
          response.cacheLocalTickets
        );

        dispatch(
          Object.assign({}, response, { type: TICKET_GET_ALL_TICKET_CACHE_ADD })
        );
      } else {
        // All other cases
        const response = await retrieveTickets(
          globalPage,
          GLOBAL_CACHE_RANGE,
          sortBy,
          filters,
          ticketTimeRange,
          presetSelected,
          null,
          null,
          getState
        );

        response.cacheLocalTickets = Object.assign(
          incrementalCache,
          response.cacheLocalTickets
        );
        dispatch(
          Object.assign({}, response, { type: TICKET_GET_ALL_TICKET_CACHE_ADD })
        );
      }
    }
  };
}

let latestTicketRetrieve;

export function retrieveTicketCache2(
  moveIndex,
  localPage,
  cache,
  sortBy,
  filters,
  ticketType,
  ticketTimeRange,
  presetSelected
) {
  return async function(dispatch, getState) {
    const movedPageIndex = localPage + moveIndex;

    dispatch({
      type: TICKET_GET_ALL_TICKET_CACHE_PENDING
    });

    const incrementalCache = cache.cacheLocalTickets;

    // Convert local page + local per page into global page and global per page
    const globalPage =
      Math.ceil((movedPageIndex * LOCAL_CACHE_RANGE) / GLOBAL_CACHE_RANGE) - 1;
    // Update current page pointer
    dispatch(movePointer(movedPageIndex));

    // Check if there is a cache. If there is no cache, preload cache.
    if (typeof cache.cacheLocalTickets === "undefined" || localPage <= 0) {
      const response = await retrieveTickets(
        0,
        GLOBAL_CACHE_RANGE,
        sortBy,
        filters,
        ticketType,
        ticketTimeRange,
        presetSelected,
        null,
        getState
      );
      dispatch(
        Object.assign({}, response, {
          type: TICKET_GET_ALL_TICKET_CACHE_INITIALISED
        })
      );
    } else {
      // Cache is already populated
      // Check if page exists in cache AND movement within the allowed area
      // TODO: Fix calls at the very start and end of the list/array
      if (
        !incrementalCache[
          localPage +
            (Math.sign(moveIndex) * GLOBAL_CACHE_RANGE) / LOCAL_CACHE_RANGE
        ] &&
        getState().tickets.selectedTicketType === ticketType
      ) {
        latestTicketRetrieve = new Date();
        const response = await retrieveTickets(
          globalPage + MOVEMENT_INDEX,
          GLOBAL_CACHE_RANGE,
          sortBy,
          filters,
          ticketType,
          ticketTimeRange,
          presetSelected,
          latestTicketRetrieve,
          getState
        );
        response.cacheLocalTickets = Object.assign(
          cache,
          response.cacheLocalTickets
        );
        dispatch(
          Object.assign({}, response, { type: TICKET_GET_ALL_TICKET_CACHE_ADD })
        );
        if (response.time === latestTicketRetrieve) {
          dispatch(
            retrieveListFromTicketCache(
              localPage + moveIndex,
              getState().tickets.cacheObject
            )
          );
        }
      }
    }
  };
}

export function movePointer(localPage) {
  return {
    type: TICKET_CACHE_MOVE_POINTER,
    cacheLocalPointer: localPage
  };
}

// REST call for retrieving tickets from server
//CHANGING to add date functionality
export async function retrieveTickets(
  globalPage,
  globalPerPage,
  sortBy,
  filters,
  ticketType,
  ticketTimeRange,
  presetSelected,
  time,
  _getState
) {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/tickets`;
  const params = {
    page: globalPage,
    perPage: globalPerPage,
    ticketType
  };

  if (sortBy) {
    params.sortBy = sortBy.sortField;
    params.sortDirection = sortBy.sortDirection;
  }

  if (ticketTimeRange) {
    if (ticketTimeRange.start) {
      const start = `${ticketTimeRange.start} 00:00:00`;
      params.start = start;
    }
    if (ticketTimeRange.end) {
      const end = `${ticketTimeRange.end} 23:59:59`;
      params.end = end;
    }
  }
  if (presetSelected && presetSelected !== "") {
    params.preset = presetSelected;
  }
  if (filters && !isEmpty(filters)) {
    // Implementing multiple filters...
    let filtersParam = "";
    for (const filter in filters) {
      if (filters.hasOwnProperty(filter)) {
        if (filters[filter]) {
          filtersParam += `${filter}:${filters[filter]};`;
        }
      }
    }
    params.filters = filtersParam;
  }

  try {
    const res = await axios.get(url, { params });
    const count = res.headers["x-total-count"];
    const cacheLocalTickets = {};
    for (let i = 1; i <= GLOBAL_CACHE_RANGE / LOCAL_CACHE_RANGE; i++) {
      const shard = res.data.slice(
        (i - 1) * LOCAL_CACHE_RANGE,
        i * LOCAL_CACHE_RANGE
      );
      if (shard.length > 0) {
        cacheLocalTickets[
          globalPage * (GLOBAL_CACHE_RANGE / LOCAL_CACHE_RANGE) + i
        ] = shard;
      }
    }
    return {
      time,
      cacheLocalTickets,
      cacheLocalFinal: Math.ceil(count / LOCAL_CACHE_RANGE),
      count
    };
  } catch (err) {}
}

export function ticketGetSingle(ticketNumber) {
  return async function(dispatch, getState) {
    dispatch({
      type: TICKET_GET_SINGLE_PENDING
    });

    /* GET TICKETS */
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/${ticketNumber}`;

    return axios
      .get(url)
      .then(res => {
        const tmpBody = Object.assign({}, res.data);
        dispatch({
          type: TICKET_GET_SINGLE_FULFILLED,
          ticketNumber: tmpBody
        });

        return res.data;
      })
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch({
            type: TICKET_GET_SINGLE_ERROR,
            error: error.response.data.error
          });
          dispatch(
            openSnackbar(
              `Ticket not found, please check the ID. ${error.response.data.error}`
            )
          );
        }
      });
  };
}

export function getSimilarTickets(ticketType, shortDescription) {
  return async function(dispatch, getState) {
    dispatch({
      type: TICKET_GET_SIMILAR_PENDING
    });

    /* GET SIMILAR TICKETS */
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets`;
    const params = {
      isFuzzy: true,
      isScoreRequired: true,
      ticketType,
      filters: `shortDescription:${shortDescription}`
    };
    return axios.get(url, { params }).then(res => {
      dispatch({
        type: TICKET_GET_SIMILAR_FULFILLED,
        similarTickets: res.data
      });
      return res.data;
    });
  };
}

export const CLEAR_PARSED_TICKET = "CLEAR_PARSED_TICKET";

export function clearParsedTicket() {
  return {
    type: CLEAR_PARSED_TICKET,
    parsedTicket: {
      ticketCleared: true
    }
  };
}

const trimMLdatauserValue = ticketObject => {
  if (ticketObject.mlData) {
    ticketObject.mlData.forEach(data => {
      if (data.userValue) {
        data.userValue = data.userValue.trim();
      }
    });
  }
};

export async function ticketUpdateSingle(_ticketId, ticketObject, _getState) {
  trimMLdatauserValue(ticketObject);
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/tickets`;

  try {
    const res = await axios.put(url, ticketObject);
    return res.data;
  } catch (error) {
    return await Promise.reject(error);
  }
}

export function ticketUpdateFunc(
  ticketId,
  ticketObject,
  updateMessage,
  _ticketTable
) {
  return async function(dispatch, getState) {
    dispatch({
      type: TICKET_UPDATE_SINGLE_PENDING
    });
    try {
      if (!ticketObject.cacheObject) {
        ticketObject.cacheObject = getState().tickets.cacheObject;
      }
      // get required props to fire moveTicketCachePointer function
      const {
        ticket,
        cacheLocalPointer,
        cacheObject,
        ticketSort,
        ticketFilter,
        selectedTicketType,
        ticketTimeRange,
        presetSelected
      } = ticketObject;

      const response = await ticketUpdateSingle(ticketId, ticket, getState);

      if (response) {
        // dispatch new action to retrieve new cacheLocalFinal once user clicks
        // "UPDATE" button
        dispatch(
          moveTicketCachePointer(
            0,
            cacheLocalPointer,
            cacheObject,
            ticketSort,
            ticketFilter,
            selectedTicketType,
            ticketTimeRange,
            presetSelected
          )
        );
      }

      if (updateMessage !== "") dispatch(openSnackbar(updateMessage));
      dispatch({
        type: TICKET_UPDATE_SINGLE_FULFILLED,
        ticketId: response.id,
        ticket: response
      });
      dispatch(clearParsedTicket());
    } catch (err) {
      dispatch({
        type: TICKET_UPDATE_SINGLE_ERROR,
        error: err.response
          ? err.response.data.error || err.response.data.message
          : err.message
      });
    }
  };
}

export async function ticketCreateSingle(ticketObject, _getState) {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/tickets`;

  try {
    const res = await axios.post(url, ticketObject);
    return res.data;
  } catch (error) {
    return await Promise.reject(error);
  }
}

export function createTicketFunc(ticketObject, _ticketTable) {
  return async function(dispatch, getState) {
    dispatch({
      type: TICKET_CREATE_SINGLE_PENDING
    });

    userConfig.actions.beforeCreate();
    try {
      if (!ticketObject.cacheObject) {
        ticketObject.cacheObject = getState().tickets.cacheObject;
      }
      // get required props to fire moveTicketCachePointer function
      const {
        ticketProps,
        cacheLocalPointer,
        cacheObject,
        ticketSort,
        ticketFilter,
        selectedTicketType,
        ticketTimeRange,
        presetSelected
      } = ticketObject;

      const response =
        (await userConfig.actions.onCreate()) ||
        (await ticketCreateSingle(ticketProps, getState));

      if (response) {
        // dispatch new action to retrieve new cacheLocalFinal once user clicks
        // "CREATE TICKET" button
        dispatch(
          moveTicketCachePointer(
            0,
            cacheLocalPointer,
            cacheObject,
            ticketSort,
            ticketFilter,
            selectedTicketType,
            ticketTimeRange,
            presetSelected
          )
        );
      }

      dispatch(openSnackbar("Ticket Successfully Created."));
      history.push(`/atr/tickets/${response.coreData.number}`);
      dispatch({
        type: TICKET_CREATE_SINGLE_FULFILLED,
        ticketId: response.coreData.id,
        ticket: response,
        dateTime: new Date()
      });
    } catch (err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        dispatch(openSnackbar("Error: Unauthorized"));
      }
      dispatch({
        type: TICKET_CREATE_SINGLE_ERROR,
        error: err
          ? err.response.data.error || err.response.data.message || err.message
          : null
      });
    }
    userConfig.actions.afterCreate();
  };
}

export const TICKET_FIELD_AUTOCOMPLETE = "TICKET_FIELD_AUTOCOMPLETE";

export function ticketFieldAutocomplete(fieldID, value, ticket = {}) {
  return function(dispatch, getState) {
    if (value.length > 0) {
      let url;
      let params;
      if (fieldID === "u_knowledge") {
        value = userConfig.actions.getKBID(value, ticket);
        url = `${config.urls.base + config.urls.apis["knowledge-base"]}/search`;
        params = {
          query: value,
          queryField: "u_knowledge",
          dateField: "updateDate",
          perPage: 100
        };
      } else {
        url = `${config.urls.base +
          config.urls.apis["ticket-management"]}/tickets/autocomplete`;
        params = {
          field: fieldID,
          value
        };
      }

      return axios.get(url, { params }).then(res => {
        let descriptionValues;
        if (fieldID === "u_knowledge") {
          descriptionValues = res.data.items.map(item => item.u_knowledge);
        } else {
          descriptionValues = res.data.map(item => item.value);
        }
        dispatch({
          type: TICKET_FIELD_AUTOCOMPLETE,
          descriptions: {
            id: fieldID,
            suggestionList: descriptionValues
          }
        });
      });
    }
    dispatch({
      type: TICKET_FIELD_AUTOCOMPLETE,
      descriptions: {
        id: fieldID,
        suggestionList: []
      }
    });
  };
}

export function ticketGetAllUserGroup(getState) {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/userGroups`;

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => null);
}

export function ticketGetUserName(id, getState) {
  if (!id) return Promise.resolve();

  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/users/${id}`;

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => null);
}

// LOCAL USER FILTER

export const USER_NAMES_GET_ALL = "USER_NAMES_GET_ALL";

export const TICKET_GET_ALL_JOBS_PENDING = "TICKET_GET_ALL_JOBS_PENDING";
export const TICKET_GET_ALL_JOBS_FULFILLED = "TICKET_GET_ALL_JOBS_FULFILLED";
export const TICKET_GET_ALL_JOBS_ERROR = "TICKET_GET_ALL_JOBS_ERROR";

export const TICKET_GET_OPTIONS_PENDING = "TICKET_GET_OPTIONS_PENDING";
export const TICKET_GET_OPTIONS_FULFILLED = "TICKET_GET_OPTIONS_FULFILLED";
export const TICKET_GET_OPTIONS_ERROR = "TICKET_GET_OPTIONS_ERROR";

export const TICKET_MANUAL_RESOLUTION_LINK_RETRIEVED =
  "TICKET_MANUAL_RESOLUTION_LINK_RETRIEVED";
export const TICKET_MANUAL_RESOLUTION_LINK_ERROR =
  "TICKET_MANUAL_RESOLUTION_LINK_ERROR";

export function getManualResolutionLink() {
  return async function(dispatch, getState) {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/config/manual-resolution`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: TICKET_MANUAL_RESOLUTION_LINK_RETRIEVED,
          manualResolutionLink: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: TICKET_MANUAL_RESOLUTION_LINK_ERROR
        });
      });
  };
}

export const TICKET_MANUAL_RESOLUTION_CONFIG_RETRIEVED =
  "TICKET_MANUAL_RESOLUTION_CONFIG_RETRIEVED";
export const TICKET_MANUAL_RESOLUTION_CONFIG_ERROR =
  "TICKET_MANUAL_RESOLUTION_CONFIG_ERROR";

export function getManualResolutionConfig() {
  return async function(dispatch, getState) {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/config/manual-resolution-swagger`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: TICKET_MANUAL_RESOLUTION_CONFIG_RETRIEVED,
          manualResolutionConfig: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: TICKET_MANUAL_RESOLUTION_CONFIG_ERROR
        });
      });
  };
}

export const SERVICE_REQUEST_CATALOG_GET_SUCCESS =
  "SERVICE_REQUEST_CATALOG_GET_SUCCESS";
export const SERVICE_REQUEST_CATALOG_GET_ERROR =
  "SERVICE_REQUEST_CATALOG_GET_ERROR";

export function getServiceRequestCatalog() {
  return async function(dispatch, getState) {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/serviceRequests/catalogs`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: SERVICE_REQUEST_CATALOG_GET_SUCCESS,
          catalog: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: SERVICE_REQUEST_CATALOG_GET_ERROR
        });
      });
  };
}

export const SERVICE_REQUEST_CREATE_PENDING = "SERVICE_REQUEST_CREATE_PENDING";
export const SERVICE_REQUEST_CREATE_SUCCESS = "SERVICE_REQUEST_CREATE_SUCCESS";
export const SERVICE_REQUEST_CREATE_FAIL = "SERVICE_REQUEST_CREATE_FAIL";

export function createServiceRequest(id, values) {
  return async function(dispatch, getState) {
    dispatch({ type: SERVICE_REQUEST_CREATE_PENDING });
    dispatch(openSnackbar("Creating service request..."));

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/serviceRequests/catalogs/${id}`;

    return axiosSnackbar
      .post(url, values)
      .then(res => {
        dispatch({ type: SERVICE_REQUEST_CREATE_SUCCESS });
        dispatch(openSnackbar("Created Service Request successfully"));
        dispatch({ type: CLOSE_DETAILS });
      })
      .catch(err => {
        // TODO - implement SERVICE_REQUEST_CREATE_FAIL reducer action
        dispatch(openSnackbar("Failed to create Service Request"));
      });
  };
}

export const TICKET_GET_SINGLE_JOB_PENDING = "TICKET_GET_SINGLE_JOB_PENDING";
export const TICKET_GET_SINGLE_JOB_FULFILLED =
  "TICKET_GET_SINGLE_JOB_FULFILLED";
export const TICKET_GET_SINGLE_JOB_ERROR = "TICKET_GET_SINGLE_JOB_ERROR";
export const TICKET_CLEAR_SINGLE_JOB = "TICKET_CLEAR_SINGLE_JOB";

export function ticketClearSingleJobDefinition() {
  return {
    type: TICKET_CLEAR_SINGLE_JOB
  };
}

// get all the possible information about the job
export function ticketGetSingleJobDefinition(
  ticketManagement,
  jobId,
  ticketId
) {
  return function(dispatch, getState) {
    if (!jobId) {
      dispatch({
        type: TICKET_GET_SINGLE_JOB_FULFILLED,
        jobSelected: {}
      });
      return {};
    }

    dispatch({
      type: TICKET_GET_SINGLE_JOB_PENDING
    });

    // Pass the shortDescription to the backend
    // The backend will extract the JSON from the shortDescription
    // The JSON values will then mapped to the jobDefinition
    // so that params in the ticket are passed to automation
    const url = `${config.urls.base +
      config.urls.apis[
        "ticket-management"
      ]}/automation/rundeck/job/${jobId}/preview`;
    const params = {
      rundeck: ticketManagement,
      ticketId
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: TICKET_GET_SINGLE_JOB_FULFILLED,
          jobSelected: res.data
        });
        return res.data;
      })
      .catch(err => {
        dispatch({
          type: TICKET_GET_SINGLE_JOB_ERROR
        });
      });
  };
}

export const TICKET_GET_SINGLE_JOB_MODIFIED = "TICKET_GET_SINGLE_JOB_MODIFIED";
export const TICKET_GET_SINGLE_JOB_RESET = "TICKET_GET_SINGLE_JOB_RESET";

// use this to modify the information inside the job.
export function ticketModifySingleJobDefinition(jobSelected) {
  return {
    type: TICKET_GET_SINGLE_JOB_MODIFIED,
    jobSelected
  };
}

export const TICKET_RUN_SINGLE_JOB_PENDING = "TICKET_RUN_SINGLE_JOB_PENDING";
export const TICKET_RUN_SINGLE_JOB_FULFILLED =
  "TICKET_RUN_SINGLE_JOB_FULFILLED";
export const TICKET_RUN_SINGLE_JOB_ERROR = "TICKET_RUN_SINGLE_JOB_ERROR";

export function ticketRunSingleJob(ticketManagement, jobSelected, ticket) {
  return function(dispatch, getState) {
    // No job selected
    if (!jobSelected || !jobSelected.id) {
      dispatch({
        type: TICKET_GET_SINGLE_JOB_FULFILLED,
        jobSelected: {}
      });
      return false;
    }

    let jobOptions = {};
    let isJobValid = true;
    let validationMessage = "";

    // TODO: bring back mandatory field check as a backend response.
    jobOptions = Object.keys(jobSelected.options).map(optionName => {
      return {
        name: optionName,
        value: jobSelected.options[optionName]
      };
    });

    const jobContext = {
      jobId: jobSelected.id,
      options: jobOptions,
      runAs: "admin",
      ticketId: ticket.coreData.id,
      target: ticketManagement
    };

    dispatch({
      type: TICKET_RUN_SINGLE_JOB_PENDING
    });
    dispatch(openSnackbar("Automating ticket..."));
    const url =
      `${config.urls.base +
        config.urls.apis["ticket-management"]}/automation/` +
      // encodeURIComponent(ticketManagement) +
      "rundeck/job/run";

    return axiosSnackbar
      .post(url, jobContext)
      .then(res => {
        dispatch({
          type: TICKET_RUN_SINGLE_JOB_FULFILLED
        });
        dispatch(ticketGetActivities(ticket.coreData.id));
        dispatch(openSnackbar("Ticket automation triggered."));
        dispatch(ticketGetSingle(ticket.coreData.number));

        return res.data;
      })
      .catch(err => {
        dispatch({
          type: TICKET_RUN_SINGLE_JOB_ERROR
        });
      });
  };
}

export const TICKET_GET_ACTIVITIES_PENDING = "TICKET_GET_ACTIVITIES_PENDING";
export const TICKET_GET_ACTIVITIES_FULFILLED =
  "TICKET_GET_ACTIVITIES_FULFILLED";
export const TICKET_GET_ACTIVITIES_ERROR = "TICKET_GET_ACTIVITIES_ERROR";

export function ticketGetActivities(ticketId) {
  return function(dispatch, getState) {
    dispatch({
      type: TICKET_GET_ACTIVITIES_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/${ticketId}/history`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: TICKET_GET_ACTIVITIES_FULFILLED,
          ticketActivities: res.data
        });

        return res.data;
      })
      .catch(err => {
        dispatch({
          type: TICKET_GET_ACTIVITIES_ERROR
        });
        dispatch(openSnackbar("Failed to retrieve ticket activity"));
      });
  };
}

export const TICKET_GET_RESOLUTION_PENDING = "TICKET_GET_RESOLUTION_PENDING";
export const TICKET_GET_RESOLUTION_FULFILLED =
  "TICKET_GET_RESOLUTION_FULFILLED";
export const TICKET_GET_RESOLUTION_ERROR = "TICKET_GET_RESOLUTION_ERROR";

export function ticketGetResolution(u_knowledge, ticket) {
  return function(dispatch, getState) {
    if (u_knowledge === "someRandomNameThatShouldNotExist") {
      dispatch({
        type: TICKET_GET_RESOLUTION_ERROR
      });
    } else {
      dispatch({
        type: TICKET_GET_RESOLUTION_PENDING
      });
      const KBID = userConfig.actions.getKBID(u_knowledge, ticket);
      const url = `${config.urls.base +
        config.urls.apis["knowledge-base"]}/getById`;
      const params = {
        u_knowledge: KBID
      };

      return axios
        .get(url, { params })
        .then(res => {
          dispatch({
            type: TICKET_GET_RESOLUTION_FULFILLED,
            ticketResolution: res.data
          });

          return res.data;
        })
        .catch(err => {
          dispatch({
            ticketResolution: "FAILED",
            type: TICKET_GET_RESOLUTION_ERROR
          });
          if (err.response && err.response.status === 404) {
            dispatch(openSnackbar(`KB Article '${KBID}' not found`));
          } else {
            dispatch(openSnackbar(`Failed to load KB Article '${KBID}'`));
          }
        });
    }
  };
}

export function tryKnowledgebastRedirect(u_knowledge, ticket) {
  return function(dispatch, getState) {
    const KBID = userConfig.actions.getKBID(u_knowledge, ticket);
    const url = `${config.urls.base +
      config.urls.apis["knowledge-base"]}/getById`;
    const params = {
      u_knowledge: KBID
    };
    return axios
      .get(url, { params })
      .then(res => {
        if (res.data.url) {
          window.open(res.data.url, "_blank");
        }
        return res.data;
      })
      .catch(err => err);
  };
}

export const TICKET_SAVE_ACTIVITY_PENDING = "TICKET_SAVE_ACTIVITY_PENDING";
export const TICKET_SAVE_ACTIVITY_FULFILLED = "TICKET_SAVE_ACTIVITY_FULFILLED";
export const TICKET_SAVE_ACTIVITY_ERROR = "TICKET_SAVE_ACTIVITY_ERROR";

export function ticketSaveActivity(ticketId, ticketActivity) {
  return function(dispatch, getState) {
    dispatch({
      type: TICKET_SAVE_ACTIVITY_PENDING
    });

    if (!ticketId) {
      dispatch({
        type: TICKET_SAVE_ACTIVITY_FULFILLED
      });
      return false;
    }

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/${ticketId}/history`;

    let activityType = ticketActivity.type;
    if (activityType === "work_notes") {
      activityType = "Work Note";
    } else {
      activityType = "Comment";
    }

    return axiosSnackbar
      .post(url, ticketActivity)
      .then(res => {
        dispatch(ticketGetActivities(ticketId)).then(() => {
          dispatch(openSnackbar(activityType + " added successfully"));
        });

        return res.data;
      })
      .catch(err => {
        dispatch({
          type: TICKET_SAVE_ACTIVITY_ERROR
        });
      });
  };
}

export const TICKET_STREAM_OPEN_STREAM = "TICKET_STREAM_OPEN_STREAM";
export const TICKET_STREAM_RECEIVE_TICKET = "TICKET_STREAM_RECEIVE_TICKET";
export const TICKET_STREAM_CLOSE_STREAM = "TICKET_STREAM_CLOSE_STREAM";

export function streamTickets() {
  return (dispatch, getState) => {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/stream-tickets`;
    // Opening the connection
    const es = new window.EventSourcePolyfill(url, {
      // Do not reuse the variable "headers", "Accept" needs to be "text/event-stream"
      headers: {
        apiToken: getState().auth.token.token
      }
    });

    // Callback when a message is received
    const listener = function(event) {
      // receive a ticket
      try {
        if (JSON.parse(event.data).coreData) {
          dispatch({
            type: TICKET_STREAM_RECEIVE_TICKET,
            ticket: event.data
          });
        }
      } catch (e) {}

      // else: heartbeat so nothing to do
    };

    // Link the listener to the event. Event types are open, error and message.
    es.addEventListener("message", listener);
    es.onerror = () => {
      // eslint-disable-next-line no-console
      console.warn("stream-tickets error");
    };

    // dispatch the stream object
    dispatch({
      type: TICKET_STREAM_OPEN_STREAM,
      ticketStream: es
    });
  };
}

export function closeTicketStream() {
  return function(dispatch) {
    dispatch({
      type: TICKET_STREAM_CLOSE_STREAM
    });
  };
}

export const ADD_TO_CONTINUOUS_ML_PENDING = "ADD_TO_CONTINUOUS_ML_PENDING";
export const ADD_TO_CONTINUOUS_ML_SUCCESS = "ADD_TO_CONTINUOUS_ML_SUCCESS";
export const ADD_TO_CONTINUOUS_ML_ERROR = "ADD_TO_CONTINUOUS_ML_ERROR";

export function addToContinuousML(ticket) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_TO_CONTINUOUS_ML_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/continuous`;

    return axios
      .post(url, ticket)
      .then(res => {
        dispatch(
          openSnackbar(
            `Successfully added ${ticket.coreData.number} to training`
          )
        );

        dispatch({
          type: ADD_TO_CONTINUOUS_ML_SUCCESS
        });
      })
      .catch(err => {
        dispatch(openSnackbar("Failed to add ticket to training"));
        dispatch({
          type: ADD_TO_CONTINUOUS_ML_ERROR
        });
      });
  };
}

export const GET_SERVICE_REQUEST_PENDING = "GET_SERVICE_REQUEST_PENDING";
export const GET_SERVICE_REQUEST_SUCCESS = "GET_SERVICE_REQUEST_SUCCESS";
export const GET_SERVICE_REQUEST_ERROR = "GET_SERVICE_REQUEST_ERROR";

export function getServiceRequest(SRnumber) {
  return (dispatch, getState) => {
    dispatch({ type: GET_SERVICE_REQUEST_PENDING });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets`;

    const params = {
      perPage: 1,
      sortDirection: "DESC",
      ticketType: "SERVICE_REQUEST",
      isFuzzy: false,
      filters: `number:${SRnumber}`
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_SERVICE_REQUEST_SUCCESS,
          payload: res.data && res.data[0]
        });
      })
      .catch(err => {
        dispatch({
          type: GET_SERVICE_REQUEST_ERROR,
          payload: err
        });
      });
  };
}

export const GET_SERVICE_REQUEST_ITEMS_PENDING =
  "GET_SERVICE_REQUEST_ITEMS_PENDING";
export const GET_SERVICE_REQUEST_ITEMS_SUCCESS =
  "GET_SERVICE_REQUEST_ITEMS_SUCCESS";
export const GET_SERVICE_REQUEST_ITEMS_ERROR =
  "GET_SERVICE_REQUEST_ITEMS_ERROR";

export function getServiceRequestItems(SRnumber) {
  return (dispatch, getState) => {
    dispatch({ type: GET_SERVICE_REQUEST_ITEMS_PENDING });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets`;

    const params = {
      perPage: 1000,
      sortDirection: "DESC",
      ticketType: "SR_ITEM",
      isFuzzy: false,
      filters: `request:${SRnumber}`
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_SERVICE_REQUEST_ITEMS_SUCCESS,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_SERVICE_REQUEST_ITEMS_ERROR,
          payload: err
        });
      });
  };
}

export const GET_SERVICE_REQUEST_TASKS_PENDING =
  "GET_SERVICE_REQUEST_TASKS_PENDING";
export const GET_SERVICE_REQUEST_TASKS_SUCCESS =
  "GET_SERVICE_REQUEST_TASKS_SUCCESS";
export const GET_SERVICE_REQUEST_TASKS_ERROR =
  "GET_SERVICE_REQUEST_TASKS_ERROR";

export function getServiceRequestTasks(SRnumber) {
  return (dispatch, getState) => {
    dispatch({ type: GET_SERVICE_REQUEST_TASKS_PENDING });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets`;

    const params = {
      perPage: 1000,
      sortDirection: "DESC",
      ticketType: "SR_TASK",
      isFuzzy: false,
      filters: `request:${SRnumber}`
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_SERVICE_REQUEST_TASKS_SUCCESS,
          payload: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_SERVICE_REQUEST_TASKS_ERROR,
          payload: err
        });
      });
  };
}

export const SET_TICKET_TYPE = "SET_TICKET_TYPE";

export function setTicketType(type) {
  return {
    type: SET_TICKET_TYPE,
    ticketType: type
  };
}

// Get TICKET Activities Types
export const TICKET_GET_ACTIVITIES_TYPES_PENDING =
  "TICKET_GET_ACTIVITIES_TYPES_PENDING";
export const TICKET_GET_ACTIVITIES_TYPES_FULFILLED =
  "TICKET_GET_ACTIVITIES_TYPES_FULFILLED";
export const TICKET_GET_ACTIVITIES_TYPES_ERROR =
  "TICKET_GET_ACTIVITIES_TYPES_ERROR";

export function ticketGetActivitiesTypes(ticketType) {
  return function(dispatch, getState) {
    dispatch({
      type: TICKET_GET_ACTIVITIES_TYPES_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/${ticketType}/activity`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: TICKET_GET_ACTIVITIES_TYPES_FULFILLED,
          ticketActivitiesTypes: res.data
        });
        return res.data;
      })
      .catch(res => {
        dispatch({
          type: TICKET_GET_ACTIVITIES_TYPES_ERROR
        });
      });
  };
}

export const GET_ALL_GTR_WORKFLOWS_SUCCESS = "GET_ALL_GTR_WORKFLOWS_SUCCESS";
export const GET_GTR_WORKFLOWS_SUCCESS = "GET_GTR_WORKFLOWS_SUCCESS";
export const SET_GTR_WORKFLOW = "SET_GTR_WORKFLOW";
export const SET_GTR_WORKFLOW_FILTER = "SET_GTR_WORKFLOW_FILTER";
export const SET_GTR_WORKFLOW_FILTER_TYPE = "SET_GTR_WORKFLOW_FILTER_TYPE";
export const setGtrWorkflowFilter = filter => ({
  type: SET_GTR_WORKFLOW_FILTER,
  filter: filter
});

export const setGtrWorkflowFilterType = filterType => ({
  type: SET_GTR_WORKFLOW_FILTER_TYPE,
  filterType
});

export const setGtrWorkflow = (selectedGtrWorkflow, selectedCategory) => ({
  type: SET_GTR_WORKFLOW,
  selectedGtrWorkflow: selectedGtrWorkflow,
  selectedCategory: selectedCategory
});

export const getAllGTRWorkflows = () => (dispatch, getState) => {
  const url = `${config.urls.domain}/GTR/api/api/TicketServiceMongo/GetAssignResolutionFlowListBySearch?value=""&endtoendId=1&applicationId=1`;
  const token = getState().auth.token.token;

  axios
    .request({
      url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      data: JSON.stringify({
        url: url,
        token: token
      }),
      timeout: 1000000
    })
    .then(response => {
      dispatch({
        type: GET_ALL_GTR_WORKFLOWS_SUCCESS,
        payload: response.data
          .filter(workflow => workflow.resolutionFlow !== "Un Classified")
          .filter(workflow => workflow.categoryStatus === "ACTIVE")
          .map(workflow => ({
            resolutionFlow: workflow.resolutionFlow,
            categoryName: workflow.categoryName,
            resolutionFlowId: workflow.resolutionFlowId,
            ticketCategoryId: workflow.ticketCategoryId
          }))
      });
    })
    .catch(e => console.log(e.message));

  // axios
  //   .request({
  //     url: "http://localhost:5000",
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     data: JSON.stringify({
  //       url: url,
  //       token: token
  //     }),
  //     timeout: 1000000
  //   })
  //   .then(response => {
  //     dispatch({
  //       type: GET_ALL_GTR_WORKFLOWS_SUCCESS,
  //       payload: response.data
  //         .filter(workflow => workflow.resolutionFlow !== "Un Classified")
  //         .map(workflow => ({
  //           resolutionFlow: workflow.resolutionFlow,
  //           categoryName: workflow.categoryName,
  //           resolutionFlowId: workflow.resolutionFlowId,
  //           ticketCategoryId: workflow.ticketCategoryId
  //         }))
  //     });
  //   })
  //   .catch(e => console.log(e));
};
