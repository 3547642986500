import {
  AllFields,
  ITicketDetailsObject,
  ITicketDetailsProps,
  ITicketTypeProps,
  MapValueType
} from "../../containers/pages/DryRunPage";

const handleActiveTicketTemplates = (activeTemplates: {}): ITicketTypeProps[] => {
  const ticketTypes = Object.keys(activeTemplates)
    .filter(ticketType => {
      return activeTemplates[ticketType]?.ticketSource?.canCreate;
    })
    .map(ticketType => {
      return {
        type: ticketType,
        displayName: activeTemplates[ticketType]?.name
      };
    });
  return ticketTypes;
};

const convertArrayToObject = (
  fieldList: ITicketDetailsProps[]
): ITicketDetailsObject => {
  const fieldObject = fieldList?.reduce(
    (object: ITicketDetailsObject, item: ITicketDetailsProps) => ({
      ...object,
      [item.name]: item
    }),
    {}
  );
  return fieldObject;
};

const arrayIterator = (
  fieldObject: ITicketDetailsObject,
  allFields: AllFields
): Map<string, MapValueType> => {
  const newMap =
    fieldObject &&
    Object.keys(fieldObject)?.reduce(
      (map: Map<string, MapValueType>, item: string) => {
        if (allFields && item in allFields) {
          map.set(item, allFields[item]);
        }
        return map;
      },
      new Map<string, MapValueType>()
    );
  return newMap;
};

const renderTextFieldsOnChange = (
  fieldName: string,
  value: string,
  setState: React.Dispatch<React.SetStateAction<Map<string, MapValueType>>>,
  fieldList: Map<string, MapValueType>
) => {
  const newMap = new Map<string, MapValueType>(fieldList);
  newMap.set(fieldName, value);
  setState(newMap);
};

export const DryRunUtils = {
  handleActiveTicketTemplates,
  convertArrayToObject,
  arrayIterator,
  renderTextFieldsOnChange
};
