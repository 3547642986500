import React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";

export default class EmailTemplatesLayout extends React.Component {
  render() {
    return (
      <Grid
        fluid
        style={{
          padding: "30px 30px 0 30px",
          height: "calc(100vh - 60px)",
          overflowY: "scroll"
        }}
      >
        <Row style={{ alignItems: "center", paddingLeft: 10 }}>
          <Col xs={12} style={{ padding: 0 }}>
            {this.props.heading}
          </Col>
        </Row>

        <Row style={{ padding: 10, height: "90%" }}>{this.props.body}</Row>
      </Grid>
    );
  }
}
