// Stores styles for Tickets.js
import themeMainUI from "../../styles/themeMainUI";

const Styles = {
  tickets: {
    overflowY: "scroll",
    flexBasis: "200px"
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  },
  tableRowColumn: {
    fontSize: "13px"
  },
  tableRowColumnSelected: {
    backgroundColor: "#FFFDE7"
  },
  bundleHeading: {
    padding: "0 16px",
    margin: "15px 0 0 0"
  },
  bundleListAvatar: {
    minWidth: "40px",
    justifyContent: "center"
  },
  bundleListItem: {
    fontSize: "13px",
    alignItems: "center"
  },
  selectedBundleListItem: {
    fontSize: "13px",
    alignItems: "center",
    color: themeMainUI.palette.primary2Color,
    fontWeight: 800
  },
  bundleListItemCount: {
    color: themeMainUI.palette.accent3Color,
    fontSize: "13px",
    textAlign: "right",
    marginTop: 15
  },
  serviceCard: {
    color: themeMainUI.palette.accent2Color,
    buttonBackground: themeMainUI.palette.primaryBtnBackgroundColor,
    ellipsisColor: themeMainUI.palette.primary3Color
  }
};

export default Styles;
