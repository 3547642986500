import { connect } from "react-redux";
import { logout } from "../actions/auth/auth";
import { closeChatbot, openChatbot } from "../actions/chatbot";
import { clearErr } from "../actions/errors";
import { closeGamification, openGamification } from "../actions/gamification";
import { sidebarSetState } from "../actions/sidebar";
import { closeSnackbar, openSnackbar } from "../actions/snackbar";
import { closeTutorial, updateUserDemo } from "../actions/tutorial";
import { fetchUserDetails, fetchUserDetailsProfile } from "../actions/users";
import Main from "../components/Main";

/* return GLOBAL STATE via STATE RETURNERS and store in props */
const mapStateToProps = (state, ownProps) => {
  const auth = state.auth;
  return {
    isChatbotOpen: state.chatbot.isOpen,
    isNotificationOpen: state.notification.isOpen,
    isSnackbarOpen: state.snackbar.isOpen,
    isGamificationOpen: state.gamification.isOpen,
    message: state.snackbar.message,
    isFetching: auth.isFetching,
    isAuthenticated: auth.isAuthenticated,
    errors: state.errors,
    user: state.user.user,
    permissions: state.user.permissions,
    isAValidUser: state.user.isAValidUser,
    jwt: auth.jwt,
    sidebarState: state.sidebar,
    displayJoyride: state.tutorial.displayJoyride,
    userProfile: state.user.userProfile
  };
};

/* pull actions that will change global state and store in props */
const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogoutClick: () => {
    dispatch(logout("Successfully Log Out"));
  },
  clearErr: () => dispatch(clearErr()),
  closeChatbot: () => dispatch(closeChatbot()),
  openChatbot: () => dispatch(openChatbot()),
  closeGamification: () => dispatch(closeGamification()),
  openGamification: () => dispatch(openGamification()),
  sidebarSetState: state => dispatch(sidebarSetState(state)),
  closeSnackbar: () => dispatch(closeSnackbar()),
  openSnackbar: message => dispatch(openSnackbar(message)),
  fetchUserDetails: () => dispatch(fetchUserDetails()),
  fetchUserDetailsProfile: username =>
    dispatch(fetchUserDetailsProfile(username)),
  updateJoyrideSettings: (newSetting, user) =>
    dispatch(updateUserDemo(newSetting, user)),
  closeTutorial: () => dispatch(closeTutorial())
});

const MainContainer = connect(mapStateToProps, mapDispatchToProps)(Main);

export default MainContainer;
