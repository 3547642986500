import { refreshToken } from "../actions/auth/auth";

export function jwtMiddleware({ dispatch, getState }) {
  return next => action => {
    // only worry about expiring token for async actions
    if (
      typeof action === "function" ||
      ![
        "REFRESHING_TOKEN",
        "DONE_REFRESHING_TOKEN",
        "SESSION_INVALID"
      ].includes(action.type)
    ) {
      // if (getState().auth && getState().auth.token) {
      if (getState().auth.inSession) {
        const tmpToken = getState().auth.token;
        // check if token doesn't exist or is about to expire (2 min buffer)
        if (!tmpToken || tmpToken.expirationDate - Date.now() < 60 * 1000 * 2) {
          // make sure we are not already refreshing the token
          if (!getState().auth.freshTokenPromise) {
            return refreshToken()(dispatch).then(() => next(action));
          } else {
            return getState().auth.freshTokenPromise.then(() => {
              next(action);
            });
          }
        }
      }
    }
    return next(action);
  };
}
