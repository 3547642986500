import React from "react";

import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core";

const styles = {
  tableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  }
};

const TicketTemplateTableHeader = props => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableHeader}>Status</TableCell>
        <TableCell className={classes.tableHeader}>Template Name</TableCell>
        <TableCell className={classes.tableHeader}>Source Type</TableCell>
        <TableCell className={classes.tableHeader}>Date Created</TableCell>
        <TableCell className={classes.tableHeader}>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(TicketTemplateTableHeader);
