import { PrimaryTabs } from "../templates/PrimaryTabs";
import React from "react";

const elipseIfNecesasry = tab => {
  if (tab.length > 12) {
    return tab.substring(0, 12) + "..";
  }
  return tab;
};

const TicketDetailsDrawerTabMenu = props => {
  const { slideIndex, handleTabChange, fontSize, tabs } = props;
  return (
    <PrimaryTabs
      value={slideIndex}
      onChange={handleTabChange}
      fontSize={fontSize}
      tabs={tabs.map(tab => elipseIfNecesasry(tab))}
    />
  );
};

export default TicketDetailsDrawerTabMenu;
