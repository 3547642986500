import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutMenu } from "../../actions/auth/auth";
import { closeChatbot, openChatbot } from "../../actions/chatbot";
import {
  getUnreadNotifications,
  markNotificationAsRead,
  streamNotifications
} from "../../actions/notification";
import { getBulkActionTasks } from "../../actions/bulkActions";
import NavHeader from "../../components/navigation/NavHeader";

const mapStateToProps = (state, ownProps) => ({
  chatbotBadge: state.chatbot.chatbotBadge,
  username: state.user.user.username,
  permissions: state.user.permissions,
  isChatbotOpen: state.chatbot.isOpen,
  unreadNotifications: state.notification.unreadNotifications.slice(0, 5),
  unreadNotificationsBadge: state.notification.unreadNotifications,
  notifications: state.notification.notifications,
  isFetchingNotifications: state.notification.isFetching,
  amountOfUnreadNotifications: state.notification.amountOfUnreadNotifications,
  bulkActions: state.bulkActions.bulkActionTasks.items
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => dispatch(logoutMenu()),
  closeChatbot: () => dispatch(closeChatbot()),
  openChatbot: () => dispatch(openChatbot()),
  getUnreadNotifications: () => dispatch(getUnreadNotifications()),
  streamNotifications: () => dispatch(streamNotifications()),
  markNotificationAsRead: id => dispatch(markNotificationAsRead(id)),
  getBulkActionTasks: (page, perPage) =>
    dispatch(getBulkActionTasks(page, perPage))
});

const NavHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavHeader));

export default NavHeaderContainer;
