import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { ScrollContainer } from "./styles";

const BasePanel = props => {
  const { header, footer, content, value, index, ...rest } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`step-tabpanel-${index}`}
      aria-labelledby={`step-tab-${index}`}
      {...rest}
    >
      <Grid container spacing={8}>
        <Grid container item xs={12}>
          {header}
        </Grid>
        <ScrollContainer>
          <Grid container item xs={12} style={{ height: "100%" }}>
            {content}
          </Grid>
        </ScrollContainer>
        <Grid container item xs={12}>
          {footer}
        </Grid>
      </Grid>
    </div>
  );
};

BasePanel.propTypes = {
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired
};

export default BasePanel;
