import { connect } from "react-redux";
import ReviewAndLaunchPanel from "../index";
import { setReviewConfirmed } from "actions/tutorial";

const mapStateToProps = state => {
  return {
    providerFilters: state.tutorial.providerFilters,
    selectedWorkflows: state.tutorial.selectedWorkflows,
    selectedProviders: state.tutorial.selectedProviders,
    configs: state.plugin.configs,
    reviewConfirmed: state.tutorial.reviewConfirmed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setReviewConfirmed: () => dispatch(setReviewConfirmed())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewAndLaunchPanel);
