import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import UseCasePanel from "../UseCasePanel";
import {
  ParentButtonContainer,
  TitleContainer,
  TitleText,
  TitleIcon,
  UseCasePanelContainer,
  StyledCollapse
} from "./styles";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import config from "config";

const UseCaseGroup = props => {
  const { title, workflows, onSelectSingle } = props;
  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Grid item xs={12}>
      <ParentButtonContainer onClick={() => toggleOpen()} color="red">
        <Grid container spacing={8} alignItems="center" justify="space-between">
          <Grid item>
            <TitleContainer
              container
              alignItems="center"
              justify="space-between"
            >
              <Grid item>
                <TitleIcon
                  icon={open ? faMinusCircle : faPlusCircle}
                  size="lg"
                />
              </Grid>
              <Grid item>
                <TitleText>{title}</TitleText>
              </Grid>
            </TitleContainer>
          </Grid>
        </Grid>
      </ParentButtonContainer>
      <StyledCollapse in={open}>
        {workflows.map(workflow => (
          <UseCasePanelContainer
            item
            xs={12}
            key={`${workflow.id}-${workflow.name}`}
          >
            <UseCasePanel
              title={workflow.name}
              category={
                workflow.tags &&
                workflow.tags.length > 0 &&
                workflow.tags[0].split("/")[0]
              }
              description={workflow.description}
              workflowLink={`${config.urls.domain}/wfm/workflow/${workflow.id}`}
              rulesLink={`${config.urls.domain}/atr/settings/automation/rules`}
              isSelected={workflow.isSelected}
              onClick={() => onSelectSingle(workflow)}
            />
          </UseCasePanelContainer>
        ))}
      </StyledCollapse>
    </Grid>
  );
};

UseCaseGroup.propTypes = {
  title: PropTypes.string.isRequired,
  workflows: PropTypes.array.isRequired,
  onSelectSingle: PropTypes.func.isRequired
};

export default UseCaseGroup;
