import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import { grey400 } from "material-ui/styles/colors";
import Chip from "material-ui/Chip";

class AccuracyChip extends React.Component {
  _handleRequestDelete() {}

  _chipPredictionColor(prediction) {
    if (prediction >= 80) {
      return themeMainUI.palette.automationBackgroundColor;
    }
    if (prediction >= 70) {
      return "#99d066";
    }
    if (prediction >= 60) {
      return "#F29906";
    }
    if (prediction >= 50) {
      return "#ff7961";
    }

    return grey400;
  }

  render() {
    const { prediction, addedStyles, ...rest } = this.props;
    const addedStyle = addedStyles || {};

    return (
      <Chip
        style={Object.assign(
          {
            alignItems: "center",
            width: "-moz-fit-content",
            backgroundColor: this._chipPredictionColor(prediction),
            display: "inline-block"
          },
          addedStyle
        )}
        labelColor="white"
        {...rest}
      >
        {this.props.children}
      </Chip>
    );
  }
}

export default AccuracyChip;
