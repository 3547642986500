import React from "react";
import PropTypes from "prop-types";
import history from "../../../../../../history";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  BoldText,
  CompleteIcon,
  ConfirmButton,
  MainContainer,
  TextWrapper,
  SecondaryButton
} from "./styles";
import config from "config";

const FinalPage = ({ setTicketType }) => {
  const goToHome = () => {
    setTicketType("INCIDENT");
    history.push("/atr/tickets");
  };

  const goToWFM = () => {
    const url = config.urls.apps.wfm;
    window.open(url, "_blank");
  };

  return (
    <MainContainer>
      <CompleteIcon icon={faCheckCircle} />
      <TextWrapper>
        <BoldText>Set up complete!</BoldText>
        You're ready to start automating tickets.
      </TextWrapper>
      <ConfirmButton color="primary" variant="contained" onClick={goToHome}>
        Go to tickets
      </ConfirmButton>
      <SecondaryButton variant="contained" onClick={goToWFM}>
        View use cases
      </SecondaryButton>
    </MainContainer>
  );
};

FinalPage.propTypes = {
  setTicketType: PropTypes.func.isRequired
};

export default FinalPage;
