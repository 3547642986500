import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MenuItem, Select } from "@material-ui/core";

const StyledSelect = styled(Select)`
  width: 100%;
`;

const SelectDropdown = props => {
  const { value, options, onChange } = props;

  const handleChange = useCallback(
    event => {
      const newValue = event.target.value;
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <StyledSelect value={value} onChange={handleChange}>
      {options.map(option => (
        <MenuItem key={option.displayText} value={option.value}>
          {option.displayText}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

const DropdownItem = PropTypes.shape({
  displayText: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
});

SelectDropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(DropdownItem),
  onChange: PropTypes.func
};

SelectDropdown.defaultProps = {
  options: [],
  onChange: () => {}
};
export default SelectDropdown;
