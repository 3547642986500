import config from "../config";
import history from "../history";
import { headers } from "./headers";

import {
  userUpdateProgressing,
  userUpdateFailed,
  userUpdateSucceeded,
  userDeletionProgressing,
  userDeletionFailed,
  userDeletionSucceeded
} from "./user";

import { openSnackbar } from "./snackbar";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_PENDING = "FETCH_USER_PENDING";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

export const FETCH_USER_LIST_PENDING = "FETCH_USER_LIST_PENDING";
export const FETCH_USER_LIST_FAILURE = "FETCH_USER_LIST_FAILURE";
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS";

export const SET_CONFIDENTIALITY_POPOVER_STATE =
  "SET_CONFIDENTIALITY_POPOVER_STATE";

function fetchUserListPending() {
  return {
    type: FETCH_USER_LIST_PENDING
  };
}

function fetchUserListSuccess(userList) {
  return {
    type: FETCH_USER_LIST_SUCCESS,
    userList
  };
}

function fetchUserListFailure(error) {
  return {
    type: FETCH_USER_LIST_FAILURE,
    error
  };
}

function fetchUserPending() {
  return {
    type: FETCH_USER_PENDING
  };
}

function fetchUserSuccess(user, isAdmin) {
  return {
    type: FETCH_USER_SUCCESS,
    user,
    isAdmin
  };
}

function fetchUserFailure() {
  return {
    type: FETCH_USER_FAILURE
  };
}

function setConfidentialityPopupIsOpen() {
  return {
    type: SET_CONFIDENTIALITY_POPOVER_STATE
  };
}

function setUserPermissions(permissions) {
  return {
    type: SET_USER_PERMISSIONS,
    permissions
  };
}

export const fetchUserList = (page = 0, perPage = 10) => {
  const url = `${config.urls.base}${config.urls.apis["identity-management"]}/users?page=${page}&perPage=${perPage}`;

  return async (dispatch, getState) => {
    dispatch(fetchUserListPending());
    const options = {
      headers: headers(getState)
    };
    try {
      const data = await fetch(url, options).then(res => res.json());
      const sortedUsers = data.sort((userA, userB) => {
        const usernameA = userA.username.toUpperCase();
        const usernameB = userB.username.toUpperCase();
        return usernameA < usernameB ? -1 : usernameA > usernameB ? 1 : 0;
      });
      dispatch(fetchUserListSuccess(sortedUsers));
    } catch (e) {
      dispatch(fetchUserListFailure(e));
    }
  };
};

export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";

export function fetchUserDetailsProfile(userName) {
  return async (dispatch, getState) => {
    const url = `${config.urls.authBase}${config.urls.apis["identity-management"]}/users/${userName}`;

    try {
      const response = await axios.get(url);
      const userProfile = response.data;
      if (
        !userProfile.demoPerformed.journey &&
        userProfile.username === "admin"
      ) {
        history.push("/atr/settings/tour");
      }
      dispatch({
        type: FETCH_USER_PROFILE_SUCCESS,
        profile: userProfile
      });
    } catch (e) {
      dispatch(openSnackbar("Fetch user profile failed."));
    }
  };
}

/** fetchUserDetails
 *
 * Updated as requirement for comcast 10485 security issue
 * Token user properties endpoint was migrated to a POST request
 */
export function fetchUserDetails() {
  return async (dispatch, getState) => {
    dispatch(fetchUserPending());
    const url = `${config.urls.authBase}${config.urls.apis.auth}/token/properties`;
    const token = getState().auth.token;
    const body = { token: token.token };

    try {
      const response = await axios.post(url, body);
      const user = response.data;
      dispatch(fetchUserDetailsProfile(user.username));

      const permissions = user.permissions.map(item => item.name);
      dispatch(setUserPermissions(permissions));
      dispatch(fetchUserSuccess(user));
      localStorage.setItem("user", user.username);
    } catch (e) {
      dispatch(fetchUserFailure());
    }
  };
}

export const updateUser = (user, id) => (dispatch, getState) => {
  dispatch(userUpdateProgressing());
  const url = `${config.urls.base +
    config.urls.apis["identity-management-v2"]}/users`;
  if (user.password.length < 1) delete user.password;

  axios
    .put(url, user)
    .then(res => {
      dispatch(userUpdateSucceeded());
      dispatch(openSnackbar("Profile updated."));
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            const errorMessage = err.response.data.errors[0].defaultMessage
              ? err.response.data.errors[0].defaultMessage
              : err.response.data.errors[0];
            dispatch(openSnackbar(errorMessage));
            break;
          case 422:
            if (
              err.response.data.error ===
              "Validation error - Incorrect old password"
            ) {
              dispatch(openSnackbar("Incorrect old password."));
            } else if (
              err.response.data.error ===
              "Validation error - 'oldPassword' field must be present when changing password of same user"
            ) {
              dispatch(openSnackbar("Old password needs to be filled in."));
            } else {
              dispatch(openSnackbar("Profile update failed."));
            }
            break;
          default:
            dispatch(openSnackbar("Profile update failed."));
        }
      } else {
        dispatch(openSnackbar("Profile update failed."));
      }
      dispatch(userUpdateFailed());
    });
};

export const deleteUser = id => (dispatch, getState) => {
  dispatch(userDeletionProgressing());
  const url = `${config.urls.base +
    config.urls.apis["identity-management"]}/users/${id}`;

  axiosSnackbar
    .delete(url)
    .then(res => {
      dispatch(userDeletionSucceeded());
    })
    .catch(err => {
      dispatch(userDeletionFailed());
    });
};

export const registerUserConsent = consentDate => (dispatch, getState) => {
  const url = `${config.urls.base +
    config.urls.apis["identity-management-v2"]}/users/consent`;
  let data = new FormData();
  data.append("consentDate", consentDate);
  axios
    .post(url, data, { headers: { "Content-Type": "multipart/form-data" } })
    .then(res => {
      dispatch(setConfidentialityPopupIsOpen());
    })
    .catch(err => {
      dispatch(openSnackbar("Consent not recorded. Please try again"));
      dispatch(setConfidentialityPopupIsOpen());
    });
};
