import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { IMLFieldsProps } from "../DryRunInterface";
import { automationConfigsStyles, ICSSProps } from "../Styles";
import { withStyles } from "@material-ui/core";

interface IMLConditionsProps {
  mlConditions: IMLFieldsProps[];
}

const MLConditions: React.FC<IMLConditionsProps &
  ICSSProps<typeof automationConfigsStyles>> = React.memo(
  ({ mlConditions, classes }) => {
    return (
      <>
        <span className={classes.tableTitle}>
          Additional Trigger Conditions
        </span>
        <Table>
          <colgroup>
            <col width="12%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableColumn}>Field</TableCell>
              <TableCell className={classes.tableColumn}>Condition</TableCell>
              <TableCell className={classes.tableColumn}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mlConditions &&
              mlConditions.map(row => {
                return (
                  <TableRow key={row.name}>
                    <TableCell
                      className={classes.tableBorder}
                      component="th"
                      scope="row"
                    >
                      {row.displayName}
                    </TableCell>
                    <TableCell className={classes.tableBorder}>
                      {row.predictedValue}
                    </TableCell>
                    <TableCell className={classes.tableBorder}>
                      {row.predictedValue}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default withStyles(automationConfigsStyles)(MLConditions);
