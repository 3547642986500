import React from "react";
import PropTypes from "prop-types";
import Styles from "../../styles/themeMainUI";
import AccuracyChip from "../templates/AccuracyChip";

export default class TicketConfidenceChip extends React.Component {
  render() {
    return (
      <div style={{ display: "flex", flex: 1 }}>
        <AccuracyChip
          addedStyles={{
            margin: "8px 0px 4px 5px",
            padding: "5px 10px",
            display: "flex",
            borderRadius: 18,
            height: 58
          }}
          prediction={Math.ceil(100 * parseFloat(this.props.confidenceLevel))}
        >
          <span
            style={{
              fontSize: 17,
              fontWeight: 600
            }}
          >
            {Math.ceil(100 * parseFloat(this.props.confidenceLevel))}%
          </span>
        </AccuracyChip>
        <div
          style={{
            color: Styles.palette.primary3Color,
            fontSize: 13,
            paddingLeft: "15px",
            width: "114px",
            display: "flex",
            paddingTop: 18
          }}
        >
          Average Prediction Accuracy
        </div>
      </div>
    );
  }
}

TicketConfidenceChip.propTypes = {
  confidenceLevel: PropTypes.string
};
