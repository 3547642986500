import React from "react";
import styled, { css } from "styled-components";
import Check from "@material-ui/icons/Check";
import {
  green500,
  yellow500,
  red500,
  blue700
} from "material-ui/styles/colors";
import { Grid, Typography, Paper } from "@material-ui/core";
export const GridCollapseContent = styled(Grid)`
  margin-top: 10px;
`;

export const CollapseContainer = styled.div`
  padding: 10px 0;
  word-wrap: break-word;
`;

export const NodeCardContainer = styled(Paper)`
  ::before {
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #e1e4e8;
    content: "";
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 0 0 -10px;
    left: 0;
    top: calc(50% - 10px);
    ${props =>
      props.position === "top" &&
      css`
        left: 50%;
        top: -10px;
      `}
  }
  position: relative;
  width: 140px;
  min-height: 50px;
  padding: 8px 16px;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px #e1e4e8, 0 2px 4px rgba(0, 0, 0, 0.15);
  word-wrap: break-word;
  box-sizing: border-box;
`;

export const NodeCardContent = styled.div`
  ::before {
    background: #ffffff;
    border-radius: 0px;
    content: "";
    display: block;
    margin: -1px 0 0 -11px;
    position: absolute;
    height: 22px;
    width: 12px;
    left: 11px;
    top: calc(50% - 10px);
    ${props =>
      props.position === "top" &&
      css`
        height: 12px;
        width: 22px;
        left: 50%;
        top: 1px;
      `};
  }
`;

export const SuccessIcon = styled(Check)`
  color: ${green500};
  margin-right: 5px;
  font-size: 20px;
`;

export const ProgressIcon = styled.div`
  background: ${yellow500};
  width: 15px;
  height: 15px;
  border-radius: 20px;
  margin-top: 2px;
  margin-right: 5px;
`;

export const UntouchedIcon = styled.div`
  background: #d1d5da;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  margin-top: 2px;
  margin-right: 5px;
`;

export const FailedIcon = styled.div`
  background: ${red500};
  width: 15px;
  height: 15px;
  border-radius: 20px;
  margin-top: 2px;
  margin-right: 5px;
`;

export const CentralDot = styled.div`
  background: ${({ state }) => (state === "UNTOUCHED" ? "#d1d5da" : blue700)};
  border-radius: 50%;
  position: absolute;
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  margin: 0 0 0 -5px;
  left: 0;
  top: calc(50% - 5px);
  ${props =>
    props.position === "top" &&
    css`
      left: 50%;
      top: -6px;
    `}
`;

export const StateText = styled(({ isLink, ...otherProps }) => (
  <Typography {...otherProps} />
))`
  font-style: italic;
  ${props =>
    props.isLink &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;
