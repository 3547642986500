import React from "react";
import { isEmpty } from "lodash";
import {
  Button,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress,
  Paper,
  Divider
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";

import ATRFormField from "../../../templates/ATRFormField";
import PluginDrawerConfigField from "./Field/Field";
import PluginDrawerConfigGroup from "./Group/Group";
import { withStyles } from "@material-ui/core/styles";
import { Save, Error } from "@material-ui/icons";
import { red500 } from "material-ui/styles/colors";
import classNames from "classnames";

const styles = theme => ({
  mainWrapper: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    flexGrow: 1,
    padding: "0px 0px",
    maxWidth: 1000,
    margin: "0 auto"
  },
  contentWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden"
  },
  buttonSuccess: {
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700]
    }
  },
  buttonProgress: {
    color: blue[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  errorMsg: {
    display: "flex",
    alignItems: "center",
    color: red500,
    paddingTop: 10
  },
  contentActionsContainer: {
    padding: "20px 20px",
    paddingBottom: 30,
    flexGrow: 0,
    flexShrink: 0,
    alignItems: "center"
  },
  contentActions: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  buttonColor: {
    backgroundColor: "#4253AF"
  },
  buttonWrapper: {
    position: "relative"
  }
});

const PluginDrawerConfig = props => {
  const [isShowDefault, setShowDefault] = React.useState(false);
  const error = (errors, id) => {
    return (
      errors &&
      errors.filter(error => error.fieldId === id).length > 0 &&
      errors.filter(error => error.fieldId === id)[0].errorMsg
    );
  };

  const {
    classes,
    isLoading,
    updateConfig,
    submitConfig,
    updateCollapse,
    pluginId: id,
    pluginConfigs,
    pluginConfigs: {
      errors,
      groups: pluginGroups,
      fields: pluginFields,
      activated: isActive = false,
      pollingEnabled: isPulling = false
    } = {
      fields: []
    }
  } = props;

  // const updateCollapse = React.useCallback(index => updateCollapseProp(index), [
  //   index
  // ]);

  const buttonClassname = classNames({
    [classes.buttonSuccess]: !isLoading
  });

  const getGroupedSortedConfigs = groupId => {
    const groupedPlugins = pluginFields.filter(pluginField =>
      groupId
        ? pluginField.field.groupId === groupId
        : !pluginField.field.groupId
    );

    if (isEmpty(groupedPlugins)) {
      return [];
    }

    const sortedPlugins = groupedPlugins.sort((a, b) =>
      a.field.displayOrder > b.field.displayOrder
        ? 1
        : a.field.displayOrder < b.field.displayOrder
        ? -1
        : 0
    );
    sortedPlugins.map(plugin => {
      if (plugin.id === "TICKET_TYPE_JSON_DATA") {
        sortedPlugins.splice(1, 1);
      }
    });
    return sortedPlugins;
  };

  const errorScreen = () => (
    <div className={classes.errorMsg}>
      <div>
        <Error className={classNames(classes.leftIcon, classes.iconSmall)} />
      </div>
      <Typography variant="headline">
        Cannot load configurations for this plugin.
      </Typography>
    </div>
  );

  const normalScreen = () => (
    <div className={classes.mainWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.contentHeader}>
          <ATRFormField formLabel="Activation" xs={3} key="activation">
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={() => {
                    if (!isLoading) {
                      updateConfig({ pluginId: null, value: "activated" });
                    }
                  }}
                  value="activated"
                  color="primary"
                />
              }
            />
          </ATRFormField>
          <ATRFormField formLabel="Enable Pulling" xs={3} key="pullingEnabled">
            <FormControlLabel
              control={
                <Switch
                  checked={isPulling}
                  onChange={() => {
                    if (!isLoading) {
                      updateConfig({
                        pluginId: null,
                        value: "pollingEnabled"
                      });
                    }
                  }}
                  value="pullingEnabled"
                  color="primary"
                />
              }
            />
          </ATRFormField>
          {!isEmpty(getGroupedSortedConfigs()) && (
            <PluginDrawerConfigGroup
              pluginGroup={{
                displayName: "Default",
                collapsed: isShowDefault,
                id: "default"
              }}
              index="default"
              updateCollapse={() => setShowDefault(!isShowDefault)}
            >
              <>
                {getGroupedSortedConfigs().map(pluginField => {
                  return (
                    <PluginDrawerConfigField
                      key={`${pluginField.id}`}
                      pluginField={pluginField}
                      updateConfig={updateConfig}
                      error={error(errors, pluginField.id)}
                    />
                  );
                })}
              </>
            </PluginDrawerConfigGroup>
          )}
          {!isEmpty(pluginGroups) &&
            pluginGroups.map((pluginGroup, index) => {
              const sortedPluginFields = getGroupedSortedConfigs(
                pluginGroup.id
              );
              return (
                <>
                  {!isEmpty(sortedPluginFields) && (
                    <PluginDrawerConfigGroup
                      key={`${pluginGroup.id}`}
                      pluginGroup={pluginGroup}
                      index={index}
                      updateCollapse={updateCollapse}
                    >
                      {sortedPluginFields.map(pluginField => {
                        return (
                          <PluginDrawerConfigField
                            key={`${pluginField.id}`}
                            pluginField={pluginField}
                            updateConfig={updateConfig}
                            error={error(errors, pluginField.id)}
                          />
                        );
                      })}
                    </PluginDrawerConfigGroup>
                  )}
                </>
              );
            })}
        </div>
      </div>
      <Divider />
      <Paper className={classes.contentActionsContainer}>
        <div className={classes.contentActions}>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                isLoading ||
                pluginFields.filter(pf => pf.field.mandatory && !pf.value)
                  .length
              }
              className={classNames(buttonClassname, classes.buttonColor)}
              onClick={() => submitConfig(id)}
            >
              <Save
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              save
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </Paper>
    </div>
  );

  return (
    <div style={{ height: "calc(100vh - 150px)", overflowY: "auto" }}>
      {!pluginConfigs && !isLoading ? errorScreen() : normalScreen()}
    </div>
  );
};

export default withStyles(styles)(PluginDrawerConfig);
