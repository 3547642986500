import React from "react";
import PropTypes from "prop-types";
import { List } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { HeaderText, StyledItemText, StyledListItem } from "./styles";

const SourceTypes = props => {
  const {
    headingText,
    ticketTypes,
    selectedProviderType,
    setSelectedProviderType
  } = props;
  return (
    <div>
      <HeaderText variant={"body2"}>{headingText}</HeaderText>
      <List>
        {ticketTypes.map(item => (
          <StyledListItem
            key={item.ticketType}
            button
            isSelected={
              selectedProviderType &&
              item.ticketType === selectedProviderType.ticketType
            }
            onClick={() => setSelectedProviderType(item)}
          >
            <ChevronRight fontSize="inherit" />
            <StyledItemText>{item.displayName}</StyledItemText>
          </StyledListItem>
        ))}
      </List>
    </div>
  );
};

SourceTypes.propTypes = {
  headingText: PropTypes.string.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  selectedProviderType: PropTypes.object,
  setSelectedProviderType: PropTypes.func.isRequired
};

export default SourceTypes;
