import React from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledContainer = styled.div`
  min-width: 40vh;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0rem 3rem 2rem 3rem;
`;

function createData(category, types) {
  return { category, types };
}

const personalInfo = `Where you are provided with access to Accenture's MyWizard Services, Accenture shall collect the business e-mail account, last name, first name, browser IP address, Work location (Country, City, Office, Zip Code), Designation details.
Accenture will use the above information collected by MyWizard for`;

const rows = [createData("Log on, User Demographics", personalInfo)];
const terms = [
  "Provisioning access to the MyWizard Application and it services.",
  "User Demographics collected are used by the respective applications for assigning ownership, sending email notifications, sharing business reports.",
  "The browser IP address is send to public domain site to gather the geolocation information (Country, State, City) which is displayed on the Application dashboard.",
  "The browser IP address is collected to track the no. of users connected to the application or services and accordingly gauge the application performance."
];

const PrivacyStatement = () => {
  const tableCellStyle = {
    border: "1px solid #ddd",
    fontSize: "1.1em",
    padding: "20px",
    textAlign: "center"
  };
  return (
    <StyledContainer class="modal-content">
      <div class="modal-header">
        <h2 id="termsModalTitle" class="modal-title">
          Accenture Privacy Statement for MyWizard
        </h2>
        <p>
          This privacy statement explains the personal data Accenture processes,
          how Accenture processes it, and for what purposes.
        </p>
        <p>
          Accenture offers a wide range of Services to our Clients, references
          to Accenture Services include Accenture services, websites, and
          applications.
        </p>
        <p>
          This statement applies to the interactions Accenture has with you and
          the MyWizard Services listed below:
        </p>
      </div>
      <div class="modal-body">
        <h2 class="text-center myw-600">MyWizard Tool</h2>
        <p>
          Accenture provides these Services in connection with an Agreement made
          with a business that you are affiliated with, such as your employer,
          our Client, ("Client").
        </p>
        <p>
          Our Client retains our Services, the MyWizard Suite of Applications,
          for their organizational purposes, and your access to the Service is
          managed according to the Agreement between Accenture and our Client,
          along with any applicable Terms of Use. Our Client may also have
          access to and process your Personal Data, including any interaction
          with the data, diagnostic data, and the contents of your
          communications and any files associated with your use of the MyWizard
          Suite of Applications, along with any accounts you sign in to which
          are acquired by our Client for their organization's purposes. To the
          extent our Client processes your personal data, you should direct all
          privacy inquiries, including any request to access your data
          protection rights to your business' administrator. MyWizard Privacy
          Statement governs the personal data that is collected by Accenture
          using MyWizard Application or the services provided by MyWizard.
        </p>
        <p>
          To the extent that you use these Services in connection with the
          Agreement and any applicable Terms of Use, Accenture may provide
          information regarding your use of the Services to our Client and other
          parties such as third-party service providers and public authorities
          as required by the applicable laws. Any third-party service providers
          and professional advisors to whom your personal data are disclosed,
          are required and expected to protect the confidentiality and security
          of your personal data and may only use your personal data in
          compliance with applicable data protection laws.
        </p>
        <p>
          We may also process your personal data using third-party tools, which
          are not developed or owned by Accenture, as part of the MyWizard
          Service.
        </p>
        <p>
          For the MyWizard Suite of Applications, we collect and process the
          additional categories of personal data:
        </p>
        <Table
          style={{ minWidth: "650", tableCellStyle }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={tableCellStyle}>
                <span
                  style={{
                    color: "black",

                    textAlign: "center"
                  }}
                >
                  Category of personal information
                </span>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <span
                  style={{
                    color: "black"
                  }}
                >
                  Types of personal information captured
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.category}>
                <TableCell style={tableCellStyle} component="th" scope="row">
                  {row.category}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    fontSize: "1.1em",
                    padding: "20px"
                  }}
                >
                  {row.types}
                  {terms.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{ border: "none", fontSize: "0.9em" }}
                        component="th"
                        scope="row"
                      >
                        {index + 1}. {row}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <p>
          For complete details and additional information as to how we collect,
          process and secure your data, and our commitment to protecting your
          privacy review our Privacy Statement here. Please select the
          applicable country you are in when consulting our privacy statement.
        </p>
      </div>
    </StyledContainer>
  );
};

export default PrivacyStatement;
