import { connect } from "react-redux";
import {
  setSelectedWorkflows,
  addWorkflowFilter,
  removeWorkflowFilter,
  clearWorkflowFilter
} from "../../../../../../../actions/tutorial";
import EnableUseCasePanel from "../index";

const mapStateToProps = state => {
  return {
    workflows: state.tutorial.demoWorkflows,
    selectedWorkflows: state.tutorial.selectedWorkflows,
    workflowFilters: state.tutorial.workflowFilters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedWorkflows: workflows =>
      dispatch(setSelectedWorkflows(workflows)),
    addWorkflowFilter: category => dispatch(addWorkflowFilter(category)),
    removeWorkflowFilter: category => dispatch(removeWorkflowFilter(category)),
    clearWorkflowFilter: () => dispatch(clearWorkflowFilter())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnableUseCasePanel);
