import { OPEN_GAMIFICATION, CLOSE_GAMIFICATION } from "../actions/gamification";

function gamification(state = { isOpen: false }, action) {
  switch (action.type) {
    case OPEN_GAMIFICATION:
      return {
        isOpen: true
      };
    case CLOSE_GAMIFICATION:
      return {
        isOpen: false
      };
    default:
      return state;
  }
}

export default gamification;
