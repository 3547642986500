const config = window.config;

export const isValid = prop =>
  config && config[prop] && typeof config[prop] === "function";

export const userConfig = {
  variables: {
    filters: config && config.filters ? config.filters : [],
    sidebarFilters:
      config && config.sidebarFilters ? config.sidebarFilters : [],
    state: config && config.state ? config.state : null,
    ticketManagementDisplayName:
      config && config.ticketManagementDisplayName
        ? config.ticketManagementDisplayName
        : null,
    knowledgebaseDisplayName:
      config && config.knowledgebaseDisplayName
        ? config.knowledgebaseDisplayName
        : null,
    atsDisplayName:
      config && config.atsDisplayName ? config.atsDisplayName : null,
    showProgressColumn:
      config && config.showProgressColumn ? config.showProgressColumn : false,
    dryRun: window.isTest
      ? true
      : config && config.featureFlags && config.featureFlags.dryRun
      ? config.featureFlags.dryRun
      : false,
    healthChecks: window.isTest
      ? true
      : config && config.featureFlags && config.featureFlags.healthChecks
      ? config.featureFlags.dryRun
      : false
  },
  actions: {
    beforeCreate: () =>
      isValid("beforeCreate") ? config.beforeCreate() : null,
    onCreate: () => {
      if (isValid("onCreate")) {
        return config.onCreate();
      }
      return null;
    },
    afterCreate: () => (isValid("afterCreate") ? config.afterCreate() : null),
    onAutomate: () => (isValid("onAutomate") ? config.onAutomate() : null),
    getKBID: (u_knowledge, ticket) =>
      isValid("getKBID") ? config.getKBID(u_knowledge, ticket) : u_knowledge,
    overrideKBIDAutoComplete: (u_knowledge_autoComplete, ticket) =>
      isValid("overrideKBIDAutoComplete")
        ? config.overrideKBIDAutoComplete(u_knowledge_autoComplete, ticket)
        : { u_knowledge_autoComplete, ticket },
    generateSAMLLoginURL: relayUrl =>
      isValid("generateSAMLLoginURL")
        ? config.generateSAMLLoginURL(relayUrl)
        : null,
    generateSAMLLogoutUrl: () =>
      isValid("generateSAMLLogoutUrl") ? config.generateSAMLLogoutUrl() : null
  }
};
