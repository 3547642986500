import React, { useState, useRef, useLayoutEffect } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TicketTemplateViewerTemplateFieldsCheckboxCTN from "./Checkbox/CheckboxCTN";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  searchIcon: {
    fontSize: 20,
    color: "grey"
  },
  fieldWapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    alignContent: "flex-start",
    width: "100%",
    padding: "0 20px",
    height: "calc(100% - 145px)"
  },
  field: {
    display: "flex",
    alignItems: "baseline"
  },
  iconButton: {
    fontSize: 18
  },
  page: {
    padding: "0 10px",
    fontSize: 12
  }
});

const paginatedTicketFields = (ticketFields, pageSize, pageNumber) => {
  return ticketFields.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

const TicketTemplateViewerTemplateFields = props => {
  const { ticketFields, filter, classes, setFieldFilter } = props;

  const fieldsRef = useRef(null);
  const [fieldPage, setFieldPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const fieldMaxPage = Math.ceil(ticketFields.length / pageSize);

  const resize = () => {
    if (fieldPage > fieldMaxPage) {
      setFieldPage(fieldMaxPage);
    }
    if (fieldPage === 0 && fieldPage < fieldMaxPage) {
      setFieldPage(1);
    }
    setPageSize(2 * Math.ceil(fieldsRef.current.clientHeight / 24));
  };

  useLayoutEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [fieldPage, fieldMaxPage]);

  return (
    <>
      {/* Filter */}
      <Input
        value={filter}
        onChange={event => {
          setFieldFilter(event.target.value);
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        placeholder="Filter"
        style={{ margin: "10px" }}
      />

      {/* Fields */}
      <div className={classes.fieldWapper} ref={fieldsRef}>
        {paginatedTicketFields(ticketFields, pageSize, fieldPage).map(
          (item, i) => {
            return (
              <Grid key={i} item xs={6} className={classes.field}>
                <TicketTemplateViewerTemplateFieldsCheckboxCTN item={item} />
              </Grid>
            );
          }
        )}
      </div>

      {/* Field Paginator */}
      {ticketFields.length > 0 && (
        <Grid container justify="flex-end" alignItems="center">
          <IconButton
            className={classes.iconButton}
            disabled={fieldPage === 1}
            onClick={() => setFieldPage(fieldPage - 1)}
          >
            <ChevronLeftIcon />
          </IconButton>
          <div className={classes.page}>
            {fieldPage} / {fieldMaxPage}
          </div>
          <IconButton
            className={classes.iconButton}
            disabled={fieldPage === fieldMaxPage}
            onClick={() => setFieldPage(fieldPage + 1)}
          >
            <ChevronRightIcon />
          </IconButton>
        </Grid>
      )}
    </>
  );
};

TicketTemplateViewerTemplateFields.defaultProps = {
  ticketFields: []
};

export default withStyles(styles)(TicketTemplateViewerTemplateFields);
