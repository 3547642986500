import { createSelector } from "reselect";

export const filterSelector = state => state.ticketTemplates.fieldFilter;
export const templateFieldsSelector = state =>
  state.ticketTemplates.templateFields;
export const typeSelector = state =>
  state.ticketTemplates.currentTemplate.ticketType || "";

export const getMandatoryMappingField = state =>
  state.ticketTemplates.MLMappingFields.filter(field => field.mandatory);

export const getMappingTicketField = createSelector(
  templateFieldsSelector,
  typeSelector,
  (templateFields, type) => {
    if (type && templateFields[type]) {
      let Fields = templateFields[type].availableFields || [];
      return sortByKey(Fields, "displayName");
    }
  }
);

export const getTicketField = createSelector(
  templateFieldsSelector,
  typeSelector,
  filterSelector,
  (templateFields, type, filter) => {
    if (type && templateFields[type]) {
      let filteredFields = templateFields[type].availableFields || [];
      if (filter) {
        filteredFields = filteredFields.filter(field =>
          field.displayName.toLowerCase().includes(filter.toLowerCase())
        );
      }
      return sortByKey(filteredFields, "displayName");
    }
  }
);

export const getSecondaryFieldOption = createSelector(
  templateFieldsSelector,
  typeSelector,
  (templateFields, type) => {
    if (type && templateFields[type]) {
      return templateFields[type].secondaryFieldsEnable === null
        ? true
        : templateFields[type].secondaryFieldsEnable;
    }
  }
);

const sortByKey = (array, key) => {
  return [...array].sort((obj1, obj2) => {
    return obj1[key] < obj2[key] ? -1 : obj1[key] > obj2[key] ? 1 : 0;
  });
};
