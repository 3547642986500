import { connect } from "react-redux";
import TicketTemplateViewerPaginator from "./Paginator";
import {
  increasePage,
  decreasePage,
  createTemplate,
  updateTicketTemplate
} from "redux/modules/ticketTemplate";

import {
  getMandatoryMappingField,
  getSecondaryFieldOption
} from "redux/reselect/ticketTemplate";

const mapStateToProps = state => {
  return {
    secondaryFieldEnabled: getSecondaryFieldOption(state),
    mandatoryMLMappingFields: getMandatoryMappingField(state),
    currentTemplate: state.ticketTemplates.currentTemplate,
    currentPage: state.ticketTemplates.currentPage,
    route: state.ticketTemplates.route
  };
};

const mapDispatchToProps = dispatch => {
  return {
    increasePage: () => dispatch(increasePage()),
    decreasePage: () => dispatch(decreasePage()),
    createTemplate: () => dispatch(createTemplate()),
    updateTemplate: () => dispatch(updateTicketTemplate())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateViewerPaginator);
