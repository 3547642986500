import React, { Component } from "react";
import ActionSearch from "material-ui/svg-icons/action/search";

import PrimaryAutoComplete from "./PrimaryAutoComplete";
import FilterDialogContainer from "./Filter/FilterDialogContainer";

export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      dataSource: ["", []],
      userTyped: "",
      width: "",
      height: ""
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this._isMounted = false;
  }

  updateDimensions() {
    this._isMounted && this.setState({ width: "", height: "" });
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flex: "1 1 auto"
          }}
        >
          <ActionSearch
            style={{
              position: "relative"
            }}
          />
          <PrimaryAutoComplete openDetails={this.props.openDetails} />
        </div>
        <FilterDialogContainer
          ticketStatus={this.props.ticketStatus}
          {...this.props}
        />
      </div>
    );
  }
}
