/**
 * Created by rayemagp on 2/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import FlatButton from "material-ui/FlatButton";

class SecondaryBorderedButton extends React.Component {
  render() {
    return (
      <FlatButton
        style={{
          border: this.props.disabled
            ? `2px solid ${themeMainUI.secondaryBorderedStyle.disabled}`
            : `2px solid ${themeMainUI.secondaryBorderedStyle.secondary}`
        }}
        labelStyle={{
          bottom: "3px",
          color: this.props.disabled
            ? themeMainUI.secondaryBorderedStyle.disabled
            : themeMainUI.secondaryBorderedStyle.primary,
          fill: themeMainUI.secondaryBorderedStyle.primary
        }}
        {...this.props}
      />
    );
  }
}

export default SecondaryBorderedButton;
