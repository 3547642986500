import React from "react";
import PropTypes from "prop-types";
import { MainContainer, StyledButton } from "./styles";

const StepButtons = props => {
  const { onBack, onNext } = props;

  return (
    <MainContainer>
      {onBack && (
        <StyledButton variant="contained" color="primary" onClick={onBack}>
          Back
        </StyledButton>
      )}
      {onNext && (
        <StyledButton variant="contained" color="primary" onClick={onNext}>
          Confirm
        </StyledButton>
      )}
    </MainContainer>
  );
};

StepButtons.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func
};

export default StepButtons;
