import * as React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/icons/Link";
import produce from "immer";
import Typography from "@material-ui/core/Typography";
//@ts-ignore
import { ArcherContainer, ArcherElement, Relation } from "react-archer";
import TopologyNode from "./TopologyNode";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep, isEqual } from "lodash";
import { setTicketUpdated } from "actions/details";
import config from "../../../config";
import { axiosInstance as axios } from "../../../utils/axios";

interface Prediction {
  label: string;
  prob: number;
}

interface MLData {
  key: string;
  source: string;
  predictions: Prediction[];
  userValue: string | null;
  type: string;
  weighting: number;
  layer: number;
}

interface TopologyTreeProps {
  mlData: Readonly<MLData[] | undefined>;
  ticket: any;
  handleUpdatePrediction: any;
  tryKnowledgebastRedirect: any;
  _handleTabChange: any;
}

const TopologyTree: React.FC<TopologyTreeProps> = React.memo(
  ({
    mlData,
    ticket,
    handleUpdatePrediction,
    _handleTabChange,
    tryKnowledgebastRedirect
  }) => {
    const [originalActiveNodes, setOriginalActiveNodes] = React.useState<any>(
      {}
    );
    const [activeNodes, setActiveNodes] = React.useState<any>({});
    const [currentMLData, setCurrentMLData] = React.useState<
      MLData[] | undefined
    >(undefined);
    const [originalTicket, setOriginalTicket] = React.useState(ticket);
    const [currentTicket, setCurrentTicket] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    //@ts-ignore
    const selectedTicketType = useSelector(
      //@ts-ignore
      state => state.tickets.selectedTicketType
    );
    const templateFields = useSelector(
      //@ts-ignore
      state => state.ticketTemplates.templateFields
    );

    const originalCopy = React.useRef(null);
    originalCopy.current = originalTicket;
    const isUpdated = useSelector(
      //@ts-ignore
      state => state.details.isUpdated
    );

    React.useEffect(() => {
      const newActiveNodes: any = {};

      const newMlData = (mlData
        ? JSON.parse(JSON.stringify(mlData))
        : undefined) as MLData[] | undefined;

      newMlData?.forEach((singleMlData, index) => {
        const currentValue =
          singleMlData.userValue ?? ticket.serviceData?.[singleMlData.key];
        const currentValueIndex = newMlData[index].predictions.findIndex(
          prediction => prediction.label === currentValue
        );

        newActiveNodes[singleMlData.key] =
          currentValueIndex === -1
            ? newMlData[index].predictions[0].label
            : newMlData[index].predictions[currentValueIndex].label;
      });

      setActiveNodes(newActiveNodes);
    }, [mlData, ticket.serviceData]);

    React.useEffect(() => {
      if (!isUpdated) {
        setOriginalTicket(JSON.parse(JSON.stringify(ticket)));
        if (Object.keys(activeNodes).length !== 0) {
          setOriginalActiveNodes(activeNodes);
        }
      }
    }, [activeNodes, isUpdated, ticket]);

    React.useEffect(() => {
      const newMlData = (mlData
        ? JSON.parse(JSON.stringify(mlData))
        : undefined) as MLData[] | undefined;
      const sortedMLData = newMlData?.sort((mlData1, mlData2) => {
        return mlData1.layer < mlData2.layer
          ? -1
          : mlData1.layer > mlData2.layer
          ? 1
          : 0;
      });
      setCurrentMLData(sortedMLData);
    }, [mlData]);

    React.useEffect(() => {
      setCurrentTicket(JSON.parse(JSON.stringify(ticket)));
    }, [ticket]);

    React.useEffect(() => {
      return () => {
        handleUpdatePrediction(originalCopy.current);
      };
    }, [handleUpdatePrediction]);

    const generateRelations = (key: string, label: string): Relation[] => {
      const relations: Relation[] = [];

      // currentMLData?.forEach(mlField => {
      //   mlField.predictions.forEach(prediction => {
      //     prediction.
      //   })
      // })

      if (activeNodes[key] !== label) {
        return relations;
      }

      if (currentMLData?.slice(-1).pop()?.key === key) {
        return relations;
      }

      const currentLayer = currentMLData?.find(data => data.key === key)?.layer;
      const nextKey = currentMLData?.find(
        data => data.layer === (currentLayer as number) + 1
      )?.key;

      relations.push({
        targetId: `topology-node-${nextKey}-${activeNodes[nextKey as string]}`,
        targetAnchor: "top",
        sourceAnchor: "bottom",
        style: {
          strokeWidth: 1,
          arrowThickness: 1,
          strokeColor: "#82bbd0"
        }
      });

      return relations;
    };

    const updateTree = React.useCallback(
      async (mlField: MLData, currentColumn: number, ticket: any) => {
        if (
          activeNodes[mlField.key] !== mlField.predictions[currentColumn].label
        ) {
          const key = mlField.key;
          const layer = mlField.layer;
          const label = mlField.predictions[currentColumn].label;
          const sendTicket = cloneDeep(ticket);
          const mlDatas = ticket.mlData.filter((d: any) => d.modelId);

          const newMlData = mlDatas.map((d: any) => {
            if (d.key === mlField.key) {
              d.userValue = label;
            } else {
              d.userValue = activeNodes[d.key];
            }
            return d;
          });

          sendTicket.mlData = newMlData;

          if (key === "u_knowledge") {
            sendTicket.automationData.sourceJobName = label;
          } else if (key === "ticket_management") {
            sendTicket.automationData.sourceServiceName = label;
          }

          if (layer === Object.keys(activeNodes).length - 1) {
            handleUpdatePrediction(sendTicket);
            setCurrentTicket(sendTicket);
            dispatch(setTicketUpdated());
            return setActiveNodes(
              produce(draft => {
                draft[key] = mlField.predictions[currentColumn].label;
              })
            );
          }

          setIsLoading(true);
          const url = `${config.urls.base +
            config.urls.apis["ticket-management"]}/ml/prediction`;
          const payload = {
            ticket: sendTicket,
            currentLevel: layer + 1
          };

          try {
            const { data } = await axios.put(url, payload);
            const newMLData: any[] = [];
            dispatch(setTicketUpdated());

            sendTicket.mlData?.forEach((oldMLData: any) => {
              const newDataWithSameKey = data.find(
                (d: any) => oldMLData.key === d.key
              );
              if (!newDataWithSameKey) {
                newMLData.push(oldMLData);
              } else {
                newDataWithSameKey.userValue = oldMLData.userValue;
                newMLData.push(newDataWithSameKey);
              }
            });

            sendTicket.mlData = newMLData;

            const newMlField = newMLData.find(d => d.key === mlField.key);
            const prediction = newMlField?.predictions?.find(
              (p: any) => p.label === mlField.predictions[currentColumn].label
            );

            handleUpdatePrediction(sendTicket);
            setCurrentTicket(sendTicket);
            setActiveNodes(
              produce(draft => {
                draft[key] = prediction.label;
              })
            );
          } finally {
            setIsLoading(false);
          }
        }
      },
      [activeNodes, dispatch, handleUpdatePrediction]
    );

    const getDisplayName = React.useMemo(
      () => (name: string) =>
        templateFields?.[selectedTicketType]?.availableFields?.find(
          (field: any) => field.name === name
        )?.displayName ?? name,
      [selectedTicketType, templateFields]
    );

    return (
      <>
        <div style={{ height: 20, marginBottom: 10, color: "red" }}>
          {isUpdated && !isEqual(activeNodes, originalActiveNodes) && (
            <Typography style={{ color: "red" }}>
              Please click <b>UPDATE</b> to save your changes.
            </Typography>
          )}
        </div>

        <ArcherContainer
          //@ts-ignore
          noCurves
          arrowThickness={1}
        >
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            {isLoading && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: 0.8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  backgroundColor: "white"
                }}
              >
                <CircularProgress />
              </div>
            )}
            {currentMLData?.map(mlField => (
              <Grid
                container
                alignItems="center"
                justify="space-between"
                style={{ marginBottom: 40 }}
                key={mlField.key}
              >
                <Grid item xs={2}>
                  <Grid container alignItems="center" justify="space-between">
                    {mlField.key === "u_knowledge" ? (
                      <>
                        <Typography variant="body1">Knowledge Base </Typography>
                        <div
                          onClick={() => {
                            tryKnowledgebastRedirect(mlField.userValue, ticket);
                            _handleTabChange(2);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Link color="primary" />
                        </div>
                      </>
                    ) : mlField.key === "ticket_management" ? (
                      <Typography variant="body1">Ticket Management</Typography>
                    ) : mlField.key === "gtr" ? (
                      <Typography variant="body1">GTR</Typography>
                    ) : (
                      <Typography variant="body1">
                        {getDisplayName(mlField.key)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Grid container alignItems="center" justify="flex-start">
                    {mlField.predictions
                      .filter(p => p.prob !== 0)
                      .map((prediction, column) => {
                        return (
                          <div
                            key={prediction.label}
                            style={{
                              minHeight: 40,
                              paddingTop: 5,
                              paddingBottom: 5,
                              width: "30%",
                              border: `2px solid ${
                                activeNodes[mlField.key] === prediction.label
                                  ? "#5E90A2"
                                  : "black"
                              }`,
                              borderRadius: 10,
                              backgroundColor:
                                activeNodes[mlField.key] === prediction.label
                                  ? "#C9E3F3"
                                  : "white",
                              marginRight: 5,
                              cursor: isLoading ? undefined : "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly"
                            }}
                            onClick={() => {
                              updateTree(mlField, column, currentTicket);
                            }}
                          >
                            <ArcherElement
                              id={`topology-node-${mlField.key}-${prediction.label}`}
                              data-testid={`topology-node-${mlField.key}-${prediction.label}`}
                              relations={generateRelations(
                                mlField.key,
                                prediction.label
                              )}
                              style={{ width: "100%" }}
                            >
                              <TopologyNode prediction={prediction} />
                            </ArcherElement>
                          </div>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </div>
        </ArcherContainer>
      </>
    );
  }
);

export default TopologyTree;
