import React from "react";
import PropTypes from "prop-types";
import { StyledPaper } from "./styles";
import TableHeader from "./TableHeader/container";
import FilterRow from "./FilterRow/container";

const FilterTable = props => {
  const { selectedProviderType } = props;
  return (
    <StyledPaper>
      <TableHeader />
      {selectedProviderType &&
        selectedProviderType.filters.map((filter, index) => (
          <FilterRow
            key={`quickstart-filter-${index}`}
            filter={filter}
            index={index}
          />
        ))}
      {(!selectedProviderType || selectedProviderType.filters.length === 0) && (
        <p style={{ textAlign: "center" }}>
          <em>No polling filters. All tickets accepted.</em>
        </p>
      )}
    </StyledPaper>
  );
};

FilterTable.propTypes = {
  selectedProviderType: PropTypes.object
};

export default FilterTable;
