import { getSNOWQueryFilter } from "./ResponsiveView/panels/ReviewAndLaunch/info/ITSMInfo/utils";

const getFilterPayload = ticketTypes => {
  const payload = {};
  for (const type of ticketTypes) {
    if (type.filters.length > 0) {
      const query = getSNOWQueryFilter(type.filters);
      if (query !== "") {
        payload[type.ticketType] = {
          sysparm_query: query
        };
      }
    }
  }
  return payload;
};

export const getProviderFilterPayload = providerFilters => {
  const payload = {};
  for (const provider of Object.keys(providerFilters)) {
    const { ticketTypes } = providerFilters[provider];
    payload[provider] = getFilterPayload(ticketTypes);
  }
  return payload;
};

export const getRulesFromWorkflows = (workflowList, mappings, rules) => {
  // get related mappings
  const selectedMappings = mappings.filter(
    mapping =>
      workflowList.findIndex(wf => wf.id === mapping.targetJobName) >= 0
  );
  // get related rules
  return rules.filter(
    rule =>
      selectedMappings.findIndex(mapping => mapping.id === rule.mappingId) >= 0
  );
};
