import { connect } from "react-redux";
// import _ from "lodash";
import AutomationTab from "./AutomationTab";

import {
  ticketSelectAutomationJob,
  ticketClearSingleJobDefinition,
  tryKnowledgebastRedirect,
  ticketGetSingleJobDefinition,
  ticketModifySingleJobDefinition,
  setGtrWorkflow
} from "../../../actions/tickets";

import {
  getRundeckProjects,
  getRundeckJobs,
  clearMappingJobs,
  clearRundeckProjects,
  updateMappingFound
} from "../../../actions/mapping";

const mapStateToProps = (state, ownProps) => {
  return {
    jobSelected: state.jobs.jobSelected,
    jobIsFetching: state.jobs.isFetching,
    mappings: state.mapping.mappings,
    mappingFound: state.mapping.mappingFound,
    matchedMappings: state.mapping.matchedMappings,
    mappingRundecks: state.mapping.mappingRundecks,
    rundeckProjects: state.mapping.rundeckProjects,
    mappingJobs: state.mapping.mappingJobs,
    isFetchingRundeckProjects: state.mapping.isFetchingRundeckProjects,
    isFetchingMappingJobs: state.mapping.isFetchingMappingJobs,
    isDetailsOpen: state.details.isOpen,
    permissions: state.user.permissions,
    gtrWorkflows: state.tickets.gtrWorkflows,
    selectedGtrWorkflow: state.tickets.selectedGtrWorkflow
  };
};

const mapDispatchToProps = dispatch => ({
  getRundeckProjects: rundeck => dispatch(getRundeckProjects(rundeck)),
  getRundeckJobs: (rundeck, project) =>
    dispatch(getRundeckJobs(rundeck, project)),
  clearMappingJobs: () => dispatch(clearMappingJobs()),
  clearRundeckProjects: () => dispatch(clearRundeckProjects()),
  ticketSelectAutomationJob: automationJob =>
    dispatch(ticketSelectAutomationJob(automationJob)),
  ticketClearSingleJobDefinition: () =>
    dispatch(ticketClearSingleJobDefinition()),
  tryKnowledgebastRedirect: (u_knowledge, ticket) => {
    dispatch(tryKnowledgebastRedirect(u_knowledge, ticket));
  },

  ticketGetSingleJobDefinition: (ticketManagement, jobId, shortDescription) =>
    dispatch(
      ticketGetSingleJobDefinition(ticketManagement, jobId, shortDescription)
    ),
  ticketModifySingleJobDefinition: jobSelected =>
    dispatch(ticketModifySingleJobDefinition(jobSelected)),
  updateMappingFound: mappingFound =>
    dispatch(updateMappingFound(mappingFound)),
  setGtrWorkflow: workflow => dispatch(setGtrWorkflow(workflow))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutomationTab);
