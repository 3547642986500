import {
  CHANGE_PAGE_EMAIL_TEMPLATE,
  CHANGE_PAGE_MERGE_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE_ERROR,
  GET_EMAIL_TEMPLATES_ERROR,
  GET_EMAIL_TEMPLATES_PENDING,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_KNOWLEDGEBASE_ARTICLES_SUCCESS,
  GET_MERGE_TAG_AUTOCOMPLETE_SUCCESS,
  GET_MERGE_TAGS_ERROR,
  GET_MERGE_TAGS_PENDING,
  GET_MERGE_TAGS_SUCCESS,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_PENDING,
  SEND_EMAIL_SUCCESS,
  TOGGLE_DETAILS_EMAIL_TEMPLATE,
  TOGGLE_EMAIL_DIALOG,
  TOGGLE_MERGE_TAG_DETAILS
} from "../actions/emailTemplates";

const defaultState = {
  errField: "",
  errMsg: "",

  // Email Template States
  isSendingEmail: false,
  isFetchingEmailTemplates: false,
  emailTemplates: [],
  selectedEmailTemplate: {},
  currentPage: 0,
  lastPage: 0,

  // Merge Tag States
  isFetchingMergeTags: false,
  mergeTags: [],
  selectedMergeTag: {},
  autocompleteFields: [],
  currentPageMerge: 0,
  lastPageMerge: 0,

  // Email Dialog States
  isOpenEmailDialog: false,

  // Knowledgebase
  articles: []
};

function email(state = defaultState, action) {
  switch (action.type) {
    // =============== Email Template Reducers ===============
    case TOGGLE_DETAILS_EMAIL_TEMPLATE: {
      return { ...state, selectedEmailTemplate: action.selectedEmailTemplate };
    }
    case GET_EMAIL_TEMPLATES_PENDING: {
      return { ...state, isFetchingEmailTemplates: true };
    }
    case GET_EMAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isFetchingEmailTemplates: false,
        emailTemplates: action.emailTemplates,
        lastPage: action.lastPage
      };
    }
    case GET_EMAIL_TEMPLATES_ERROR: {
      return { ...state, isFetchingEmailTemplates: false };
    }
    case SEND_EMAIL_PENDING: {
      return { ...state, isSendingEmail: true };
    }
    case SEND_EMAIL_SUCCESS: {
      return { ...state, isSendingEmail: false };
    }
    case SEND_EMAIL_ERROR: {
      return { ...state, isSendingEmail: false };
    }
    case CREATE_EMAIL_TEMPLATE_ERROR: {
      return { ...state, errField: action.errField, errMsg: action.errMsg };
    }
    case CHANGE_PAGE_EMAIL_TEMPLATE: {
      return { ...state, currentPage: action.currentPage };
    }
    // =============== Merge Tag Reducers ===============
    case GET_MERGE_TAGS_PENDING: {
      return { ...state, isFetchingMergeTags: true };
    }
    case GET_MERGE_TAGS_SUCCESS: {
      return {
        ...state,
        isFetchingMergeTags: false,
        mergeTags: action.mergeTags,
        lastPageMerge: action.lastPageMerge
      };
    }
    case GET_MERGE_TAGS_ERROR: {
      return { ...state, isFetchingMergeTags: false, errMsg: action.errMsg };
    }
    case TOGGLE_MERGE_TAG_DETAILS: {
      return { ...state, selectedMergeTag: action.selectedMergeTag };
    }
    case GET_MERGE_TAG_AUTOCOMPLETE_SUCCESS: {
      return { ...state, autocompleteFields: action.autocompleteFields };
    }
    case CHANGE_PAGE_MERGE_EMAIL_TEMPLATE: {
      return { ...state, currentPageMerge: action.currentPageMerge };
    }
    // =============== Email Dialog Reducers ===============
    case TOGGLE_EMAIL_DIALOG: {
      return { ...state, isOpenEmailDialog: action.isOpenEmailDialog };
    }
    case GET_KNOWLEDGEBASE_ARTICLES_SUCCESS: {
      return { ...state, articles: action.articles };
    }
    default:
      return state;
  }
}

export default email;
