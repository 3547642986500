import {
  UPDATE_PROXY_CONFIGURATION,
  RESET_PROXY_CONFIGURATION
} from "../actions/proxyConfiguration";

const initState = {
  proxyUrl: "",
  proxyUser: "",
  proxyPassword: "",
  proxyDomain: "",
  proxyType: ""
};

const proxyConfiguration = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_PROXY_CONFIGURATION:
      return {
        ...action.data
      };
    case RESET_PROXY_CONFIGURATION:
      return initState;
    default:
      return state;
  }
};

export default proxyConfiguration;
