import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import {
  CategoryWrapper,
  SelectCheckbox,
  CategoryText,
  SelectedText
} from "./styles";

const UseCaseCategoryFilter = props => {
  const { name, selected, numUseCases, numSelected, toggleFilter } = props;

  return (
    <Grid container item xs={12} alignItems="center" justify="space-between">
      <CategoryWrapper onClick={() => toggleFilter(name)}>
        <SelectCheckbox checked={selected} />
        <CategoryText>
          {name} ({numUseCases})
        </CategoryText>
      </CategoryWrapper>
      {numSelected > 0 && <SelectedText>{numSelected} selected</SelectedText>}
    </Grid>
  );
};

UseCaseCategoryFilter.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  numUseCases: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  toggleFilter: PropTypes.func.isRequired
};

export default UseCaseCategoryFilter;
