import { connect } from "react-redux";
import PluginNewPlugin from "./NewPlugin";
import {
  closeNewPlugin,
  onDrop,
  onCancel,
  onChangeOverride,
  onSubmitNewPlugin
} from "redux/modules/plugin";

const mapStateToProps = (state, ownProps) => ({
  isNewPlugin: state.plugin.isNewPlugin,
  files: state.plugin.files,
  isOverride: state.plugin.isOverride
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeNewPlugin: () => dispatch(closeNewPlugin()),
  onDrop: files => dispatch(onDrop(files)),
  onCancel: () => dispatch(onCancel()),
  onChangeOverride: () => dispatch(onChangeOverride()),
  onSubmitNewPlugin: () => dispatch(onSubmitNewPlugin())
});

const PluginNewPluginCTN = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginNewPlugin);

export default PluginNewPluginCTN;
