import styled from "styled-components";
import { Save, Cancel, Delete } from "@material-ui/icons";

export const StyledDelete = styled(Delete)`
  && {
    cursor: pointer;
    color: #a9a9a9;
    float: right;
    &:hover {
      color: black;
    }
  }
`;

export const StyledSave = styled(Save)`
  && {
    cursor: pointer;
    padding-right: ${props => (props.paddingTop ? 0 : "10px")};
    padding-top: ${props => props.paddingTop};
    float: ${props => (props.paddingTop ? "right" : null)};
    color: #a9a9a9;
    &:hover {
      color: green;
    }
  }
`;

export const StyledCancel = styled(Cancel)`
  && {
    cursor: pointer;
    color: #a9a9a9;
    &:hover {
      color: red;
    }
  }
`;
