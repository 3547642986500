import config from "../config";
import { openSnackbar } from "./snackbar";
import history from "../history";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

const EMAIL_TEMPLATE_RESOURCE_URL = `${config.urls.base +
  config.urls.apis.notifications}/notifications/emailTemplates`;
const MERGE_TAG_RESOURCE_URL = `${config.urls.base +
  config.urls.apis.notifications}/notifications/mergeTag`;
const PER_PAGE = 10;

// =============== Email Template Actions ===============

export const TOGGLE_DETAILS_EMAIL_TEMPLATE = "TOGGLE_DETAILS_EMAIL_TEMPLATE";

// To open: Pass in emailTemplate obj. To close: Pass in nothing
export function toggleDetails(emailTemplate) {
  return dispatch => {
    dispatch({
      type: TOGGLE_DETAILS_EMAIL_TEMPLATE,
      selectedEmailTemplate: emailTemplate || {}
    });
  };
}

export const CHANGE_PAGE_EMAIL_TEMPLATE = "CHANGE_PAGE_EMAIL_TEMPLATE";

export function changePage(nextPage) {
  return dispatch => {
    dispatch({
      type: CHANGE_PAGE_EMAIL_TEMPLATE,
      currentPage: nextPage
    });
  };
}

export const GET_EMAIL_TEMPLATES_PENDING = "GET_EMAIL_TEMPLATES_PENDING";
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS";
export const GET_EMAIL_TEMPLATES_ERROR = "GET_EMAIL_TEMPLATES_ERROR";

export function getEmailTemplates(page = 0, perPage = PER_PAGE) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_EMAIL_TEMPLATES_PENDING
    });

    const url = `${EMAIL_TEMPLATE_RESOURCE_URL}`;
    const params = {
      page,
      perPage
    };

    return axios
      .get(url, { params })
      .then(res => {
        const total = res.headers["x-total-count"];
        dispatch({
          type: GET_EMAIL_TEMPLATES_SUCCESS,
          emailTemplates: res.data,
          lastPage: Math.ceil(total / PER_PAGE) - 1
        });
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: GET_EMAIL_TEMPLATES_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: GET_EMAIL_TEMPLATES_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const CREATE_EMAIL_TEMPLATE_PENDING = "CREATE_EMAIL_TEMPLATE_PENDING";
export const CREATE_EMAIL_TEMPLATE_SUCCESS = "CREATE_EMAIL_TEMPLATE_SUCCESS";
export const CREATE_EMAIL_TEMPLATE_ERROR = "CREATE_EMAIL_TEMPLATE_ERROR";

export function createEmailTemplate(emailTemplate) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_EMAIL_TEMPLATE_PENDING
    });

    const url = EMAIL_TEMPLATE_RESOURCE_URL;

    return axiosSnackbar
      .post(url, emailTemplate)
      .then(res => {
        dispatch({
          type: CREATE_EMAIL_TEMPLATE_SUCCESS
        });

        // Redirect user
        dispatch(openSnackbar("Successfully created."));
        dispatch(getEmailTemplates());
        history.push("/atr/settings/email-templates");
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: CREATE_EMAIL_TEMPLATE_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: CREATE_EMAIL_TEMPLATE_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const UPDATE_EMAIL_TEMPLATE_PENDING = "UPDATE_EMAIL_TEMPLATE_PENDING";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_ERROR = "UPDATE_EMAIL_TEMPLATE_ERROR";

export function updateEmailTemplate(emailTemplate) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_EMAIL_TEMPLATE_PENDING
    });

    const url = `${EMAIL_TEMPLATE_RESOURCE_URL}/${emailTemplate.id}`;

    return axiosSnackbar
      .put(url, emailTemplate)
      .then(res => {
        dispatch({
          type: UPDATE_EMAIL_TEMPLATE_SUCCESS
        });

        // Redirect user
        dispatch(openSnackbar("Successfully updated."));
        dispatch(getEmailTemplates());
        history.push("/atr/settings/email-templates");
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: UPDATE_EMAIL_TEMPLATE_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: UPDATE_EMAIL_TEMPLATE_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS";
export const DELETE_EMAIL_TEMPLATE_ERROR = "DELETE_EMAIL_TEMPLATE_ERROR";

export function deleteEmailTemplate(emailTemplate) {
  return (dispatch, getState) => {
    const url = `${EMAIL_TEMPLATE_RESOURCE_URL}/${encodeURIComponent(
      emailTemplate.name
    )}`;

    return axiosSnackbar
      .delete(url)
      .then(res => {
        dispatch({
          type: DELETE_EMAIL_TEMPLATE_SUCCESS
        });

        dispatch(openSnackbar("Successfully deleted."));
        dispatch(getEmailTemplates());
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: DELETE_EMAIL_TEMPLATE_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: DELETE_EMAIL_TEMPLATE_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const SEND_EMAIL_PENDING = "SEND_EMAIL_PENDING";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";

export function sendEmail(email) {
  return (dispatch, getState) => {
    dispatch({
      type: SEND_EMAIL_PENDING
    });

    const url = `${EMAIL_TEMPLATE_RESOURCE_URL}/send`;

    return axios
      .post(url, email)
      .then(res => {
        dispatch({ type: SEND_EMAIL_SUCCESS });
        dispatch(openSnackbar("Successfully sent email."));
      })
      .catch(err => {
        dispatch({ type: SEND_EMAIL_ERROR });
        dispatch(openSnackbar("Error sending email. Please try again later."));
      });
  };
}

// =============== Merge Tag Actions ===============
export const GET_MERGE_TAGS_PENDING = "GET_MERGE_TAGS_PENDING";
export const GET_MERGE_TAGS_SUCCESS = "GET_MERGE_TAGS_SUCCESS";
export const GET_MERGE_TAGS_ERROR = "GET_MERGE_TAGS_ERROR";

export function getMergeTags(page = 0, perPage = 10) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_MERGE_TAGS_PENDING
    });

    const url = `${MERGE_TAG_RESOURCE_URL}`;
    const params = {
      page,
      perPage
    };

    return axios
      .get(url, { params })
      .then(res => {
        const total = res.headers["x-total-count"];
        dispatch({
          type: GET_MERGE_TAGS_SUCCESS,
          mergeTags: res.data,
          lastPageMerge: Math.ceil(total / PER_PAGE) - 1
        });
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: GET_MERGE_TAGS_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: GET_MERGE_TAGS_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const TOGGLE_MERGE_TAG_DETAILS = "TOGGLE_MERGE_TAG_DETAILS";

export function toggleMergeTagDetails(mergeTag) {
  return dispatch => {
    dispatch({
      type: TOGGLE_MERGE_TAG_DETAILS,
      selectedMergeTag: mergeTag || {}
    });
  };
}

export const CHANGE_PAGE_MERGE_EMAIL_TEMPLATE =
  "CHANGE_PAGE_MERGE_EMAIL_TEMPLATE";

export function changePageMerge(nextPage) {
  return dispatch => {
    dispatch({
      type: CHANGE_PAGE_MERGE_EMAIL_TEMPLATE,
      currentPageMerge: nextPage
    });
  };
}

export const CREATE_MERGE_TAG_PENDING = "CREATE_MERGE_TAG_PENDING";
export const CREATE_MERGE_TAG_SUCCESS = "CREATE_MERGE_TAG_SUCCESS";
export const CREATE_MERGE_TAG_ERROR = "CREATE_MERGE_TAG_ERROR";

export function createMergeTag(mergeTag) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_MERGE_TAG_PENDING
    });

    const url = MERGE_TAG_RESOURCE_URL;

    return axiosSnackbar
      .post(url, mergeTag)
      .then(res => {
        dispatch({
          type: CREATE_MERGE_TAG_SUCCESS
        });

        // Redirect user
        dispatch(openSnackbar("Successfully created."));
        dispatch(getMergeTags());
        history.push("/atr/settings/merge-tags");
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: CREATE_MERGE_TAG_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: CREATE_MERGE_TAG_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const UPDATE_MERGE_TAG_PENDING = "UPDATE_MERGE_TAG_PENDING";
export const UPDATE_MERGE_TAG_SUCCESS = "UPDATE_MERGE_TAG_SUCCESS";
export const UPDATE_MERGE_TAG_ERROR = "UPDATE_MERGE_TAG_ERROR";

export function updateMergeTag(mergeTag) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_MERGE_TAG_PENDING
    });

    const url = `${MERGE_TAG_RESOURCE_URL}/${mergeTag.id}`;

    return axiosSnackbar
      .put(url, mergeTag)
      .then(res => {
        dispatch({
          type: UPDATE_MERGE_TAG_SUCCESS
        });

        // Redirect user
        dispatch(openSnackbar("Successfully updated."));
        dispatch(getMergeTags());
        history.push("/atr/settings/merge-tags");
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: UPDATE_MERGE_TAG_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: UPDATE_MERGE_TAG_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const DELETE_MERGE_TAG_SUCCESS = "DELETE_MERGE_TAG_SUCCESS";
export const DELETE_MERGE_TAG_ERROR = "DELETE_MERGE_TAG_ERROR";

export function deleteMergeTag(mergeTag) {
  return (dispatch, getState) => {
    const url = `${MERGE_TAG_RESOURCE_URL}/${encodeURIComponent(
      mergeTag.name
    )}`;

    return axiosSnackbar
      .delete(url)
      .then(res => {
        dispatch({
          type: DELETE_MERGE_TAG_SUCCESS
        });

        dispatch(openSnackbar("Successfully deleted."));
        dispatch(getMergeTags());
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: DELETE_MERGE_TAG_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: DELETE_MERGE_TAG_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const GET_MERGE_TAG_AUTOCOMPLETE_SUCCESS =
  "GET_MERGE_TAG_AUTOCOMPLETE_SUCCESS";

export function getMergeTagAutoComplete(field, query, page = 0, perPage = 10) {
  return (dispatch, getState) => {
    const url = `${MERGE_TAG_RESOURCE_URL}/autocomplete`;
    const params = {
      field,
      q: query,
      page,
      perPage
    };

    return axios.get(url, { params }).then(res => {
      // Return just an array of the selected field, should really be done as a selector...
      dispatch({
        type: GET_MERGE_TAG_AUTOCOMPLETE_SUCCESS,
        // Unique fields using Set object
        autocompleteFields: res.data
          ? [...new Set(res.data.items.map(item => item[field]))]
          : []
      });
    });
  };
}

// =============== Email Dialog Actions ===============
export const TOGGLE_EMAIL_DIALOG = "OPEN_EMAIL_DIALOG";

export function toggleEmailDialog() {
  return (dispatch, getState) => {
    dispatch({
      type: TOGGLE_EMAIL_DIALOG,
      isOpenEmailDialog: !getState().email.isOpenEmailDialog
    });
  };
}

export const GET_KNOWLEDGEBASE_ARTICLES_PENDING =
  "GET_KNOWLEDGEBASE_ARTICLES_PENDING";
export const GET_KNOWLEDGEBASE_ARTICLES_SUCCESS =
  "GET_KNOWLEDGEBASE_ARTICLES_SUCCESS";
export const GET_KNOWLEDGEBASE_ARTICLES_ERROR =
  "GET_KNOWLEDGEBASE_ARTICLES_ERROR";

export function getKnowledgebaseArticles() {
  return (dispatch, getState) => {
    dispatch({ type: GET_KNOWLEDGEBASE_ARTICLES_PENDING });

    const url = `${config.urls.base + config.urls.apis["knowledge-base"]}`;
    const params = {
      page: 0,
      perPage: 1000
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_KNOWLEDGEBASE_ARTICLES_SUCCESS,
          articles: res.data.items
        });
      })
      .catch(err => {
        dispatch({ type: GET_KNOWLEDGEBASE_ARTICLES_ERROR });
      });
  };
}
