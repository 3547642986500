import React from "react";
import { Col, Row } from "react-flexbox-grid";
import SecondaryTextField from "../templates/SecondaryTextField";
import PrimaryButton from "../templates/PrimaryButton";
import { FontIcon } from "material-ui";
import ATRFormField from "../templates/ATRFormField";
import GreenButton from "../templates/GreenButton";
import muiTheme from "../../styles/themeMainUI";
import RTEditor, { html, initialValue } from "./RTEditor/RTEditor";

const FORM_FIELDS = [
  { name: "name", text: "Template Name*" },
  { name: "subject", text: "Subject" }
  // {name: 'cc_recipient', text:'CC Recipient'}, // TODO: Reenable once cc and bcc fields are implemented
  // {name: 'bcc_recipient', text:'BCC Recipient'}
];

const PREVIEW_FORM_FIELDS = [
  { name: "address", text: "To" }
  // {name: 'cc', text: 'Cc'}, // TODO: Reenable once cc and bcc fields are implemented
  // {name: 'bcc', text: 'Bcc'}
];

const REQUIRED = {
  name: false,
  subject: false,
  content: false
};

export default class EmailTemplatesForm extends React.Component {
  constructor(props) {
    super(props);

    this._handleTextChange = this._handleTextChange.bind(this);
    this._handleRTEditorChange = this._handleRTEditorChange.bind(this);
    this._handleRTEditorPreviewChange = this._handleRTEditorPreviewChange.bind(
      this
    );

    this.state = {
      isPreviewOpened: false,
      value: initialValue,
      formValues: {
        name: "",
        subject: "",
        cc_recipient: "",
        bcc_recipient: ""
      },
      valuePreview: initialValue,
      previewFormValues: {
        address: "",
        subject: ""
      },
      required: REQUIRED,
      fromField: ""
    };
  }

  componentDidMount() {
    this.props.openDetails(this.props.template);

    // Check if we're passing in a template
    if (!this.props.template) {
      return;
    }

    // Breaking up content from the rest of the fields
    const { content, ...rest } = this.props.template;

    this.setState({
      formValues: rest,
      value: html.deserialize(content),
      update: true
    });
  }

  _handleTextChange(key, value) {
    this.setState(prevValue => ({
      formValues: { ...prevValue.formValues, [key]: value }
    }));
  }

  _handlePreviewTextChange(key, value) {
    this.setState(prevValue => ({
      previewFormValues: { ...prevValue.previewFormValues, [key]: value }
    }));
  }

  _handleRTEditorChange({ value }) {
    const valuePreview = html.deserialize(html.serialize(this.state.value));
    this.setState({ value, valuePreview });
  }

  _handleRTEditorPreviewChange({ value }) {
    this.setState({ valuePreview: value });
  }

  _togglePreview() {
    this.setState({ isPreviewOpened: !this.state.isPreviewOpened });
  }

  _pushNewTemplate() {
    const emailTemplateObj = {
      ...this.state.formValues,
      content: html.serialize(this.state.value)
    };

    // Validate fields
    const required = { ...REQUIRED };
    let flag = false;

    if (emailTemplateObj.name === "") {
      required.name = true;
      flag = true;
    }

    if (emailTemplateObj.subject === "") {
      required.subject = true;
      flag = true;
    }
    if (emailTemplateObj.content === "") {
      required.content = true;
      flag = true;
    }

    if (flag) {
      return this.setState({
        required
      });
    }

    if (this.state.update) {
      this.props.updateEmailTemplate(emailTemplateObj);
    } else {
      this.props.createEmailTemplate(emailTemplateObj);
    }
  }

  // TODO: Implement send email
  _sendTestEmail() {
    if (!this.props.isSendingEmail) {
      this.props.sendEmail();
    }
  }

  render() {
    return (
      <Row
        style={{ width: "100%", height: "80%" }}
        center={!this.state.isPreviewOpened ? "xs" : "lg"}
      >
        <Col xs={this.state.isPreviewOpened ? 6 : 8}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              textAlign: "left"
            }}
          >
            {FORM_FIELDS.map(o => (
              <SecondaryTextField
                style={{ fontSize: 14 }}
                key={o.name}
                name={o.name}
                floatingLabelText={o.text}
                fullWidth
                requiredError={this.state.required[o.name]}
                value={this.state.formValues[o.name]}
                onChange={(e, newValue) =>
                  this._handleTextChange(o.name, newValue)
                }
              />
            ))}

            <p>Body Paragraph</p>
            <div
              style={{
                color: "#9E9E9E",
                fontSize: 12,
                margin: "-10px 0px 10px 0px"
              }}
            >
              Please reference tags in form of |tagName:|. For example,
              |Description:|.
            </div>
            <RTEditor
              value={this.state.value}
              onChange={this._handleRTEditorChange}
              readOnly={this.state.isPreviewOpened}
              style={{
                height: 325,
                backgroundColor: this.state.isPreviewOpened
                  ? muiTheme.palette.accent2Color
                  : "white",
                borderColor: this.state.required.content ? "red" : "#ccc"
              }}
            />
          </div>
        </Col>

        <div
          style={{
            position: "absolute",
            right: 10,
            display: "flex",
            top: 110
          }}
        >
          <GreenButton
            label={
              <strong>
                <FontIcon
                  style={{ color: "white", fontSize: 16 }}
                  className="fa fa-search"
                />
                &nbsp;Preview
              </strong>
            }
            style={{ marginRight: 10 }}
            onClick={() => this._togglePreview()}
          />
          <PrimaryButton
            label={
              this.state.update ? (
                <strong>
                  <FontIcon
                    style={{ color: "white", fontSize: 16 }}
                    className="fa fa-save"
                  />
                  &nbsp;Update
                </strong>
              ) : (
                <strong>
                  <FontIcon
                    style={{ color: "white", fontSize: 16 }}
                    className="fa fa-plus"
                  />
                  &nbsp;Create
                </strong>
              )
            }
            style={{ marginRight: 30 }}
            onClick={() => {
              this._pushNewTemplate();
            }}
          />
        </div>

        {this.state.isPreviewOpened && (
          <Col xs={6} style={{ borderLeft: "1px grey solid" }}>
            <div style={{ padding: "0, 20px", border: "1px #ccc solid" }}>
              <div
                style={{
                  backgroundColor: muiTheme.palette.accent2Color,
                  fontSize: 18,
                  padding: 10
                }}
              >
                <strong>Template Preview</strong>
              </div>
              <div>
                {PREVIEW_FORM_FIELDS.map(o => (
                  <ATRFormField key={o.name} formLabel={`${o.text}:`}>
                    <SecondaryTextField
                      name={o.name}
                      fullWidth
                      value={this.state.previewFormValues[o.name]}
                      onChange={e =>
                        this._handlePreviewTextChange(o.name, e.target.value)
                      }
                    />
                  </ATRFormField>
                ))}

                <ATRFormField formLabel="Subject:">
                  <SecondaryTextField
                    name="subject"
                    value={this.state.formValues.subject}
                    fullWidth
                    disabled
                  />
                </ATRFormField>

                <div
                  style={{
                    backgroundColor: muiTheme.palette.accent2Color,
                    fontSize: 18,
                    padding: 10
                  }}
                >
                  <strong>Email Preview</strong>
                </div>

                <div className="editor-preview">
                  <RTEditor
                    value={this.state.valuePreview}
                    onChange={this._handleRTEditorPreviewChange}
                    style={{
                      height: 320
                    }}
                  />
                </div>
                {/* TODO: Waiting for sending generic email to work first */}
                {/* <Row style={{display: 'flex', justifyContent: 'flex-end'}}> */}
                {/* <GreenButton label={this.props.isSendingEmail ? <FontIcon className={'fa fa-spinner fa-spin'} /> : "Test Email"} style={{marginRight: 35, marginBottom: 10}} onClick={() => this._sendTestEmail()}/> */}
                {/* </Row> */}
              </div>
            </div>
          </Col>
        )}
      </Row>
    );
  }
}
