import React, { Component } from "react";
import update from "immutability-helper";
import { TableRow, TableRowColumn } from "material-ui/Table";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DropzoneComponent from "react-dropzone-component";
import {
  StyledCancel,
  StyledDelete,
  StyledSave
} from "./StyledComponents/shared";
import PasswordComponent from "../PasswordComponent";
import SecondaryTextField from "../../templates/SecondaryTextField";

export default class DataSourceTableRow extends Component {
  sourceTypes = ["None Selected", "Service Now", "Remedy", "Jira", "CSV"];
  ticketTypes = ["Task", "Incident"];

  state = {
    editing: this.props.editing ? this.props.editing : false,
    item: JSON.parse(JSON.stringify(this.props.item)),
    errors: {
      username: "",
      password: "",
      token: "",
      source: "",
      sourceType: ""
    },
    valid: true,
    uploadedFile: null
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return { item: JSON.parse(JSON.stringify(nextProps.item)) };
  // }

  componentDidMount = () => {
    const { password, url, username } = this.props.service.list[0];
    this.props.isNewRow &&
      this.setState({
        item: {
          ...this.state.item,
          password: password,
          source: url,
          username: username
        }
      });
  };

  _handleSaveButtonClick = () => {
    let state = update(this.state, { valid: { $set: true } });
    if (state.valid) {
      if (state.item.sourceType === 1) {
        // we are using service now

        this.props.addTicketConfigThenRefreshList(this.props.service, {
          // serviceNowConfig: {
          password: state.item.password,
          url: state.item.source,
          username: state.item.username
          // }
        });
      } else if (state.item.sourceType === 4) {
        // we are using csv
        this.props.createDataSource({
          files: [].concat(state.uploadedFile),
          sourceName: state.item.source,
          description: state.item.username
        });
      }

      state = update(state, { editing: { $set: false } });

      if (this.props.isNewRow) this.props.hideNewRow(this.props.service);
    }

    this.setState(state);
  };

  _handleCancelButtonClick = () => {
    if (this.props.isNewRow) {
      this.props.hideNewRow(this.props.service);
    } else {
      const state = update(this.state, {
        item: { $set: JSON.parse(JSON.stringify(this.props.item)) },
        editing: { $set: false }
      });

      this.setState(state);
    }
  };

  _handleDeleteButtonClick = () => {
    this.props.deleteTicketConfigThenRefreshList(this.props.service, {
      name: this.state.item.name
    });

    const state = update(this.state, { editing: { $set: false } });
    this.setState(state);
  };

  _handleNameChange = (a, v) => {
    const state = update(this.state, { item: { name: { $set: v } } });
    this.setState(state);
  };

  _handleUsernameChange = (a, v) => {
    const state = update(this.state, { item: { username: { $set: v } } });
    this.setState(state);
  };

  _handlePasswordChange = (a, v) => {
    const state = update(this.state, { item: { password: { $set: v } } });
    this.setState(state);
  };

  _handleSourceChange = (a, v) => {
    const state = update(this.state, { item: { source: { $set: v } } });
    this.setState(state);
  };

  _handleUrlChange = (a, v) => {
    const state = update(this.state, { item: { url: { $set: v } } });
    this.setState(state);
  };

  _handleTicketTypeChange = (a, v) => {
    const state = update(this.state, { item: { ticketType: { $set: v } } });
    this.setState(state);
  };

  _handleSourceTypeChange = (a, v) => {
    const state = update(this.state, { item: { sourceType: { $set: v } } });
    this.setState(state);
  };

  _validateEntry = () => {
    let valid = true;

    const state = update(this.state, {
      errors: {
        username: { $set: "" },
        password: { $set: "" },
        token: { $set: "" }
      }
    });
    this.setState(state);

    // TODO

    return valid;
  };

  _uploadFile = file => {
    const state = update(this.state, { uploadedFile: { $set: file } });
    this.setState(state);
  };

  render() {
    const { username, url, password } = this.props.service.list[0];

    return this.state.editing ? (
      <TableRow style={{ overflow: "auto" }}>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          <SecondaryTextField
            hintText={"Username"}
            errorText={this.state.errors.username}
            value={this.state.item.username || username}
            onChange={(a, v) => {
              this._handleUsernameChange(a, v);
            }}
          />
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          <SelectField
            hintText={"Source Type"}
            value={this.state.item.sourceType}
            errorText={this.state.errors.sourceType}
            onChange={(a, b) => {
              this._handleSourceTypeChange(a, b);
            }}
            style={{
              top: "6px"
            }}
          >
            <MenuItem value={0} primaryText="None Selected" />
            <MenuItem value={1} primaryText="Service Now" />
            <MenuItem value={2} primaryText="Remedy" />
            <MenuItem value={3} primaryText="Jira" />
            <MenuItem value={4} primaryText="CSV" />
          </SelectField>
        </TableRowColumn>
        <TableRowColumn width={150}>
          <SelectField
            hintText={"Ticket Type"}
            value={this.state.item.ticketType}
            errorText={this.state.errors.ticketType}
            onChange={(a, b) => {
              this._handleTicketTypeChange(a, b);
            }}
            style={{
              top: "6px"
            }}
          >
            <MenuItem value={0} primaryText="Task" />
            <MenuItem value={1} primaryText="Incident" />
          </SelectField>
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          <SecondaryTextField
            hintText={"Password"}
            errorText={this.state.errors.password}
            defaultValue={password}
            onChange={(a, v) => {
              this._handlePasswordChange(a, v);
            }}
          />
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          {this.state.item.sourceType === 4 ? ( // check if equal to CSV
            <div
              style={{
                border: "1px dashed black",
                borderRadius: "5px",
                padding: 5
              }}
            >
              <DropzoneComponent
                config={{
                  iconFiletypes: [".csv"],
                  showFiletypeIcon: false,
                  postUrl: "no-url" // todo
                }}
                eventHandlers={{
                  addedfile: file => {
                    this._uploadFile(file);
                  },
                  complete: () => {}
                }}
                djsConfig={{
                  autoProcessQueue: false,
                  // previewsContainer: null,
                  uploadMultiple: false,
                  maxFiles: 1,
                  acceptedFiles: ".csv",
                  previewTemplate:
                    '<div class="dz-preview dz-file-preview">' +
                    '<div class="dz-details">' +
                    '    <div class="dz-filename"><span data-dz-name></span>' +
                    "    </div>" +
                    '    <div class="dz-size" data-dz-size></div>' +
                    " </div>" +
                    '  <div class="dz-error-message" style="color: red">' +
                    "       <span data-dz-errormessage></span></div>" +
                    "</div>"
                }}
              />
            </div>
          ) : (
            <SecondaryTextField
              hintText={"Source"}
              errorText={this.state.errors.source}
              defaultValue={url}
              onChange={(a, v) => {
                this._handleSourceChange(a, v);
              }}
            />
          )}
        </TableRowColumn>
        <TableRowColumn width={70} style={{ textAlign: "right" }}>
          <StyledSave
            onClick={() => {
              this._handleSaveButtonClick();
            }}
          />
          <StyledCancel
            onClick={() => {
              this._handleCancelButtonClick();
            }}
          />
          {!this.props.isNewRow ? (
            <StyledDelete
              onClick={() => {
                this._handleDeleteButtonClick();
              }}
            />
          ) : null}
        </TableRowColumn>
      </TableRow>
    ) : (
      <TableRow style={{ overflow: "auto" }}>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          {username}
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          {this.sourceTypes[this.state.item.sourceType]}
        </TableRowColumn>
        <TableRowColumn width={150}>
          {this.ticketTypes[this.state.item.ticketType]}
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          <PasswordComponent password={password} />
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={150}>
          {url}
        </TableRowColumn>
        <TableRowColumn width={70} style={{ textAlign: "right" }}>
          {!this.props.isNewRow ? (
            <StyledDelete
              onClick={() => {
                this._handleDeleteButtonClick();
              }}
            />
          ) : null}
        </TableRowColumn>
      </TableRow>
    );
  }
}
