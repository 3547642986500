import React from "react";
import SecondaryTextField from "../../../templates/SecondaryTextField";
import { Row, Col } from "react-flexbox-grid/lib";
import SecondaryDropDownMenu from "../../../templates/SecondaryDropDownMenu";
import { Divider, MenuItem } from "material-ui";
import SecondaryBorderedButton from "../../../templates/SecondaryBorderedButton";

export default class ServiceRequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValues: {},
      errors: []
    };
  }

  handleChange(key, value) {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        [key]: value
      }
    }));
  }

  validateAndSubmit() {
    let isValid = true;
    this.setState({ errors: [] });
    // Timeout is required
    setTimeout(() => {
      this.props.data.variables.forEach(variable => {
        if (variable.mandatory && !this.state.formValues[variable.name]) {
          this.setState({ errors: [...this.state.errors, variable.name] });
          isValid = false;
        }
      });
      isValid &&
        this.props.createServiceRequest(
          this.props.data.id,
          this.state.formValues
        );
    }, 10);
  }

  render() {
    const { data } = this.props;
    const { errors } = this.state;

    return (
      <>
        <Row>
          <Col>
            {data.variables.map((variable, i) => (
              <div key={i}>
                <Row key={i} style={{ marginLeft: 10 }} middle="xs">
                  <Col xs={5}>
                    {variable.label} {variable.mandatory && "*"}
                  </Col>
                  <Col xs={7}>
                    {variable.choices.length > 0 ? (
                      <div>
                        <SecondaryDropDownMenu
                          value={this.state.formValues[variable.name]}
                          onChange={(e, i, newValue) =>
                            this.handleChange(variable.name, newValue)
                          }
                          errorText={
                            errors.indexOf(variable.name) !== -1 &&
                            "This field is required."
                          }
                        >
                          {variable.choices.map(item => (
                            <MenuItem
                              value={item.value}
                              primaryText={item.label}
                            />
                          ))}
                        </SecondaryDropDownMenu>
                      </div>
                    ) : (
                      <SecondaryTextField
                        name={variable.name}
                        onChange={(e, newValue) =>
                          this.handleChange(variable.name, newValue)
                        }
                        errorText={
                          errors.indexOf(variable.name) !== -1 &&
                          "This field is required."
                        }
                      />
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
        <br />
        <Divider />
        <Row
          xs={12}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0 20px"
          }}
        >
          <SecondaryBorderedButton
            label="Create Service Request"
            onClick={() => this.validateAndSubmit()}
          />
        </Row>
      </>
    );
  }
}
