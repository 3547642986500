import React, { useEffect, useState, useCallback, useRef } from "react";
import _ from "lodash";
import PrimaryButton from "../templates/PrimaryButton";
import GreenButton from "../templates/GreenButton";
import PrimaryDropDownMenu from "../templates/PrimaryDropDownMenu";
import SecondaryBorderedButton from "../templates/SecondaryBorderedButton";
import SecondaryCircularProgress from "../templates/SecondaryCircularProgress";
import ErrorBox from "../templates/ErrorBox";
import { MenuItem } from "material-ui/Menu";
import FontIcon from "material-ui/FontIcon";
import Avatar from "material-ui/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "material-ui/Paper";
import update from "immutability-helper";
import { Row, Col } from "react-flexbox-grid/lib";
import RefreshIndicator from "material-ui/RefreshIndicator";
import { connect } from "react-redux";

import Styles from "../../styles/themeMainUI";
import SecondaryAutoComplete from "../templates/SecondaryAutoComplete";
import TicketDetailsDrawerTabMenu from "./TicketDetailsDrawerTabMenu";
import TicketDetailsDrawerTabItems from "./TicketDetailsDrawerTabItems";
import styles from "./TicketDetailsDrawerStyles";
import ServiceRequestLinks from "./ServiceRequest/Drawer/ServiceRequestLinks";
import EmailDialogPage from "../../containers/pages/EmailDialogPage";
import TicketConfidenceChip from "../tickets/TicketConfidenceChip";
import ContinuousLearningButton from "./ContinuousLearningButton";
import { userConfig } from "../../userConfigValidations";
import { openManualResolution } from "../../actions/drawer";
import { dynamicTabConfig } from "../../dynamicTabsConfig";
import { cloneDeep } from "lodash";

import SelectAutoComplete from "./SelectAutoComplete";
import { getSimilarTickets } from "actions/tickets";
import { moveTicketCachePointer } from "actions/tickets";
import { predictTicketWorkflow } from "actions/mapping";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const mapStateToProps = state => ({
  isNewTicket: state.details.ticketNumber.isNewTicket,
  templates: state.ticketTemplates.templates,
  selectedTicketType: state.tickets.selectedTicketType,
  allGtrWorkflows: state.tickets.allGtrWorkflows,
  selectedGtrWorkflow: state.tickets.selectedGtrWorkflow,
  selectedCategory: state.tickets.selectedCategory,
  similarTickets: state.tickets.similarTickets,
  isFetchingSimilarTickets: state.tickets.isFetchingSimilarTickets,
  timezone: state.user.user.timezone,
  ticketSort: state.tickets.ticketSort,
  ticketFilter: state.tickets.ticketFilter,
  cacheLocalPointer: state.tickets.cacheLocalPointer,
  ticketTimeRange: state.tickets.ticketTimeRange,
  presetSelected: state.tickets.presetSelected
});
const mapDispatchToProps = dispatch => ({
  openManualResolution: (atsObj, gtr, coreDataNumber, workflow, categoryName) =>
    dispatch(
      openManualResolution(atsObj, gtr, coreDataNumber, workflow, categoryName)
    ),
  getSimilarTickets: (ticketType, shortDescription) =>
    dispatch(getSimilarTickets(ticketType, shortDescription)),
  moveTicketCachePointer: (
    moveIndex,
    localPage,
    sortBy,
    filters,
    ticketType,
    ticketTimeRange,
    presetSelected = "",
    polling = false
  ) =>
    dispatch(
      moveTicketCachePointer(
        moveIndex,
        localPage,
        false,
        sortBy,
        filters,
        ticketType,
        ticketTimeRange,
        presetSelected,
        polling
      )
    ),
  predictTicketWorkflow: ticket => dispatch(predictTicketWorkflow(ticket))
});

const TicketDetails = props => {
  // Tickets have three sub parts
  // 1. Properties (templates, groups, automation codes etc.)
  // 2. KnowledgeBase Articles (automation commands)
  // 3. Activity (changes in the ticket data, properties or KB articles)
  // Note that ticket data is independent of properties, knowledgebase articles and activity...
  // ...so they are stored in separate JSON object as per the state object below.

  const populateOtherFields = (ticket, template) => {
    let otherFields = {
      automationFields: [
        {
          name: "ticket_management",
          displayName:
            userConfig.variables.ticketManagementDisplayName ||
            "Ticket Management",
          type: "text",
          defaultValue: "Rundeck"
        },
        {
          name: "u_knowledge",
          displayName:
            userConfig.variables.knowledgebaseDisplayName ||
            "Knowledge Base ID",
          type: "text",
          defaultValue: null,
          hideField: false
        },
        {
          name: "gtr",
          displayName: userConfig.variables.atsDisplayName || "GTR",
          type: "text",
          defaultValue: null,
          hideField: false
        }
      ],
      propertiesFields: []
    };

    (template.secondaryFields || []).forEach(field => {
      let found = false;

      ticket.mlData.forEach(mlField => {
        if (mlField.key === field.name) {
          found = true;
          otherFields.automationFields.unshift(field);
        }
      });

      if (!found) {
        if (ticket["coreData"].hasOwnProperty(field.name)) {
          otherFields.propertiesFields.push({
            ...field,
            source: "coreData"
          });
        } else {
          otherFields.propertiesFields.push({
            ...field,
            source: "serviceData"
          });
        }
      }
    });

    return otherFields;
  };

  const populateCreateFields = (ticket, template, ticketSources) => {
    let createFields = [];
    (template.primaryFields || []).forEach(field => {
      ticketSources.forEach(source => {
        // Remove any duplicate keys from the create form. This might happen when there is the same field in coreData
        // and serviceData of the ticket. We prioritise viewing and editing the coreData field over the serviceData
        // field.
        if (
          ticket[source][field.name] !== undefined &&
          createFields.findIndex(x => x.name === field.name) === -1
        ) {
          createFields.push({
            ...field,
            source: source
          });
        }
      });
      if (createFields.findIndex(x => x.name === field.name) === -1) {
        if (ticket["coreData"].hasOwnProperty(field.name)) {
          createFields.push({
            ...field,
            source: "coreData"
          });
        } else {
          createFields.push({
            ...field,
            source: "serviceData"
          });
        }
      }
    });

    return createFields;
  };

  let ticketFields = {};
  if (props.isNewTicket) {
    const createFields = props.template.primaryFields.map(field => ({
      ...field,
      source: "newFields"
    }));
    ticketFields = { create: createFields };
  } else {
    const ticketSources = ["coreData", "serviceData"];
    const createFields = populateCreateFields(
      props.defaultTicket,
      props.template,
      ticketSources
    );
    const otherFields = populateOtherFields(
      props.defaultTicket,
      props.template
    );
    ticketFields = {
      create: createFields,
      automation: otherFields.automationFields,
      properties: otherFields.propertiesFields
    };
  }

  const [ticket, setTicket] = useState(props.defaultTicket);
  const [fields] = useState(ticketFields);
  const [articles, setArticles] = useState({
    articlesSelected: {}
  });
  const [slideIndex, setSlideIndex] = useState(0);
  const [showRefreshIndicator, setShowRefreshIndicator] = useState(false);
  const [atsInvalid, setAtsInvalid] = useState(false);
  const [initialState, setInitialState] = useState("");
  const [dynamicTabs, setDynamicTabs] = useState([]);
  const [errorsFields, setErrorsFields] = useState([]);
  const [similarTicketLimit] = useState(3);
  const [similarTicketsShowAll, setSimilarTicketsShowAll] = useState(false);
  const [handleUpdateStateTicket, setHandleUpdateStateTicket] = useState(false);

  useEffect(() => {
    props
      .openAllMappings()
      .then(() => predictTicketWorkflow(props.defaultTicket));
    props.updateMappingFound({});
    !ticket.isNewTicket &&
      ticket.coreData &&
      setInitialState(ticket.coreData.state);
    setDynamicTabs(Object.values(dynamicTabConfig));
    !ticket.isNewTicket &&
      ticket.coreData &&
      props.getSimilarTickets(
        ticket.coreData.type,
        ticket.coreData.shortDescription
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousTicket = usePrevious(ticket);
  useEffect(() => {
    if (
      JSON.stringify(ticket) !== JSON.stringify(previousTicket) &&
      !ticket.isNewTicket
    ) {
      _dbcPredictTicketWorkflow(ticket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  useEffect(() => {
    const { parsedTicket, ticketIsUpdating, defaultTicket } = props;
    const defaultTicketMldata = defaultTicket.mlData;
    const stateTicketMldata = ticket.mlData;
    //Done this check since the state ticket ML data is not updated.
    if (
      ticket &&
      ticket.coreData &&
      defaultTicketMldata.length !== stateTicketMldata.length &&
      handleUpdateStateTicket
    ) {
      setTicket({
        ...ticket,
        mlData: defaultTicketMldata
      });
    }
    if (
      ticket &&
      ticket.coreData &&
      parsedTicket &&
      parsedTicket.coreData &&
      !showRefreshIndicator
    ) {
      // Time comparison to check lastUpdateDate and ticket number comparison to check if it's the same ticket
      if (
        new Date(ticket.coreData.lastUpdateDate).getTime() <
          new Date(parsedTicket.coreData.lastUpdateDate).getTime() &&
        ticket.coreData.number === parsedTicket.coreData.number &&
        !ticketIsUpdating
      ) {
        setShowRefreshIndicator(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket, props.parsedTicket, props.ticketIsUpdating, props.defaultTicket]);

  const _bestGuessTicketNode = fieldName => {
    const bestGuess =
      (["u_knowledge", "ticket_management"].includes(fieldName) &&
        "automationData") || // Check for automationData
      (Object.keys(ticket?.coreData).indexOf(fieldName) > -1 && "coreData") || // Check coreData
      (Object.keys(ticket?.serviceData).indexOf(fieldName) > -1 &&
        "serviceData") || // Check serviceData
      "serviceData"; // default to serviceData
    // eslint-disable-next-line no-console
    console.warn(
      `ticket node for fieldname "${fieldName}" not provided in update call. Using best guess "${bestGuess}"`
    );
    return bestGuess;
  };

  const _handleTicketUpdate = ticketProp => {
    //Guard classes
    if (!(ticketProp?.field ?? false)) {
      throw new Error(`missing template data in ${JSON.stringify(ticketProp)}`);
    }
    if (!(ticketProp?.field?.name ?? false)) {
      throw new Error(
        `Missing field name in template data ${JSON.stringify(ticketProp)}`
      );
    }

    let ticketClone = JSON.parse(JSON.stringify(ticket));

    let fieldName = ticketProp.field.name;
    const mlFieldName = fieldName;
    const ticketNode =
      ticketProp.field?.source ?? _bestGuessTicketNode(fieldName);

    if (ticketNode === "automationData") {
      if (!["u_knowledge", "ticket_management"].includes(fieldName)) {
        throw new Error(
          `Cannot place field ${fieldName} in automationData node`
        );
      }
      fieldName =
        (fieldName === "ticket_management" && "sourceServiceName") ||
        (fieldName === "u_knowledge" && "sourceJobName");
    }
    ticketClone[ticketNode][fieldName] = ticketProp.value;

    if (ticketClone.mlData) {
      ticketClone.mlData = ticketClone.mlData.map(value => {
        if (value.key === mlFieldName) value.userValue = ticketProp.value;
        return value;
      });
    }
    setTicket(ticketClone);
  };

  // handle user selection for the right kb instruction to execute for automation
  const _handleResolutionsChange = article => {
    setArticles(
      update(articles, {
        articlesSelected: { $set: article }
      })
    );
  };

  const _openManualResolution = atsObj => {
    const {
      openManualResolution,
      allGtrWorkflows,
      selectedGtrWorkflow,
      selectedCategory
    } = props;
    let isValid = false;

    if (atsObj.userValue) {
      isValid = _atsValidate(atsObj.userValue);
      !isValid && setAtsInvalid(true);
    } else {
      //ml field is always valid
      isValid = true;
    }

    if (isValid || props.manualResolutionConfig.gtr) {
      if (selectedCategory) {
        const workflow = allGtrWorkflows.find(
          workflow =>
            workflow.resolutionFlowId === selectedGtrWorkflow &&
            workflow.ticketCategoryId === selectedCategory
        );

        workflow &&
          workflow.resolutionFlow &&
          openManualResolution(
            atsObj,
            props.manualResolutionConfig.gtr,
            ticket.coreData.number,
            workflow.resolutionFlow,
            workflow.categoryName
          );
        setAtsInvalid(false);
      } else {
        const workflow = allGtrWorkflows.find(
          workflow => workflow.resolutionFlowId === selectedGtrWorkflow
        );

        const resolutionFlow =
          workflow && workflow.resolutionFlow ? workflow.resolutionFlow : "";

        openManualResolution(
          atsObj,
          props.manualResolutionConfig.gtr,
          ticket.coreData.number,
          resolutionFlow
        );
        setAtsInvalid(false);
      }
    }
  };

  const _atsValidate = userValue => {
    if (typeof userValue === "boolean") return false;
    //Regex
    const re = /[0-9]+[0-9]+[0-9]+/;
    //get rid of black in userValue
    const userInput = userValue.replace(/\s+/g, "");
    return re.test(userInput);
  };

  const _handleCreateTicket = ticket => {
    // get required props from redux store
    const {
      ticketType,
      cacheLocalPointer,
      ticketSort,
      ticketFilter,
      selectedTicketType,
      ticketTimeRange,
      presetSelected
    } = props;

    const ticketProps = {
      ...ticket.newFields,
      type: ticketType
    };

    // pass props to ticketCreateSingle to call moveTicketCachePointer later
    props.ticketCreateSingle({
      ticketProps,
      cacheLocalPointer,
      ticketSort,
      ticketFilter,
      selectedTicketType,
      ticketTimeRange,
      presetSelected
    });
  };

  const _handleTabChange = value => {
    if (value === 2) {
      // const ticket = JSON.parse(JSON.stringify(ticket));
      let mlData = {},
        mlObj = {};
      if (ticket) {
        mlData = ticket.mlData;
        if (mlData) {
          mlData.forEach((item, index) => {
            mlObj[item.key] = index;
          });
          // setState({ ticket });
        }
      }
      let u_knowledge = "someRandomNameThatShouldNotExist";
      if (ticket && ticket.mlData && ticket.mlData[mlObj.u_knowledge]) {
        u_knowledge = ticket.automationData.sourceJobName;
      }
      props.ticketGetResolution(u_knowledge, ticket);
    }
    if (value === 3) {
      // TODO movie this to Activity page.
      props.ticketGetActivities(props.ticketNumber.coreData.id);
      props.ticketGetSingle(props.ticketNumber.coreData.number);
      // Get TICKET Activities Types
      props.ticketGetActivitiesTypes(props.ticketNumber.coreData.type);
    }

    setSlideIndex(value);
  };

  const _ticketUpdateSingle = () => {
    setHandleUpdateStateTicket(true);
    // get required props from redux store
    const {
      ticketType,
      cacheLocalPointer,
      ticketSort,
      ticketFilter,
      selectedTicketType,
      ticketTimeRange,
      presetSelected
    } = props;

    // pass props to ticketUpdateSingle to call moveTicketCachePointer later
    props.ticketUpdateSingle(
      ticket.id,
      {
        ticket,
        ticketType,
        cacheLocalPointer,
        ticketSort,
        ticketFilter,
        selectedTicketType,
        ticketTimeRange,
        presetSelected
      },
      "Ticket Successfully Updated."
    );
  };

  const _automatableTicket = () => {
    return props.template.ticketSource.canAutomate;
  };

  const _showContinuousLearningButton = ticket => {
    if (!_automatableTicket()) {
      return false;
    }

    // Custom configuration for continuous ML button states

    if (Boolean(userConfig.variables.state)) {
      return !!userConfig.variables.state.find(value => {
        return initialState === value;
      });
    } else {
      return true;
    }
  };

  //Debounce
  const _dbcPredictTicketWorkflow = useCallback(
    _.debounce(props.predictTicketWorkflow, 1000),
    []
  );
  const _dbcTicketAutocomplete = useCallback(
    _.debounce(props.ticketFieldAutocomplete, 300),
    []
  );

  const mandatoryFields = (ticket, selectedTemplate) => {
    const mandatoryFields = [];
    selectedTemplate.primaryFields.forEach(fields => {
      if (fields.mandatory) {
        mandatoryFields.push(fields.name);
      }
    });
    if (ticket.coreData) {
      if (selectedTemplate.secondaryFields) {
        selectedTemplate.secondaryFields.forEach(fields => {
          if (fields.mandatory) {
            mandatoryFields.push(fields.name);
          }
        });
      }
    }
    return mandatoryFields;
  };

  const validateInput = ticket => {
    const errors = [];
    const primaryFieldsErrorLength = [];
    const secondaryFieldsErrorLength = [];
    const selectedTemplate = props.template;
    const ticketMandatoryFields = mandatoryFields(ticket, selectedTemplate);
    if (ticket.coreData) {
      ticketMandatoryFields.forEach(fields => {
        if (
          (ticket.coreData[fields] || ticket.serviceData[fields]) ===
            undefined ||
          (ticket.coreData[fields] || ticket.serviceData[fields]).length === 0
        ) {
          errors.push(fields);
        }
      });
    } else {
      ticketMandatoryFields.forEach(fields => {
        if (ticket.newFields[fields].length === 0) {
          errors.push(fields);
        }
      });
    }
    selectedTemplate.primaryFields.forEach(fields => {
      if (errors.indexOf(fields.name) !== -1) {
        primaryFieldsErrorLength.push(errors);
      }
    });
    if (selectedTemplate.secondaryFields) {
      selectedTemplate.secondaryFields.forEach(fields => {
        if (errors.indexOf(fields.name) !== -1) {
          secondaryFieldsErrorLength.push(errors);
        }
      });
    }
    if (
      primaryFieldsErrorLength.length === 0 &&
      secondaryFieldsErrorLength.length !== 0
    ) {
      props.openSnackbar(
        `Please fill in mandatory fields missing in Properties tab.`
      );
    }
    setErrorsFields(errors);
    return errors.length === 0 ? true : false;
  };

  const getModifiedDate = updatedDate => {
    const modDate = dayjs
      .utc(updatedDate)
      .tz(props.timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return modDate;
  };

  const drawerFunctions = {
    // Functions that are not needed for previewing a ticket template are altered here
    handleCreateTicket: _handleCreateTicket,
    ticketFieldAutocomplete: _dbcTicketAutocomplete,
    autocompleteFields: props.autocompleteFields,
    slideIndex: props.previewTab || slideIndex,
    stateField: {
      values: props.template.states
    },
    errors: errorsFields
  };

  let mlData = {},
    mlObj = {};
  if (ticket) {
    mlData = ticket.mlData;
    if (mlData) {
      mlData.forEach((item, index) => {
        mlObj[item.key] = index;
      });
    }
  }

  const currentViewerAvatars = () => {
    if (ticket.editingData && ticket.editingData.editingUsers) {
      let usersViewing = ticket.editingData.editingUsers
        .filter(item => {
          return item !== localStorage.getItem("user");
        })
        .map((item, i) => {
          return (
            <Tooltip title={item}>
              <Avatar key={i} size={20} style={{ marginLeft: 5 }}>
                {item.charAt(0).toUpperCase()}
              </Avatar>
            </Tooltip>
          );
        });

      if (usersViewing.length > 0) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 10,
              color: Styles.palette.primaryBtnBackgroundColor
            }}
          >
            Current viewers
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              {usersViewing}
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const createFields = (item, isDisabled) => {
    if (_.isEmpty(ticket)) return null;

    if (_.isNull(ticket[item.source][item.name]))
      ticket[item.source][item.name] = "";

    try {
      switch (item.type) {
        case "dropdown-search":
          const currentValue = ticket[item.source][item.name];
          return (
            <SelectAutoComplete
              isDisabled={isDisabled}
              options={[
                { value: "", label: "None" },
                ...(item.values
                  ? item.values.map(val => ({
                      value: val,
                      label: val
                    }))
                  : [])
              ]}
              value={
                currentValue
                  ? {
                      label: currentValue,
                      value: currentValue
                    }
                  : null
              }
              onChange={dropItem => {
                if (!dropItem) {
                  dropItem = {
                    value: ""
                  };
                }
                _handleTicketUpdate({
                  field: item,
                  value: dropItem.value
                });
                drawerFunctions.ticketFieldAutocomplete(
                  item.name,
                  dropItem.value
                );
              }}
              placeholder={
                !item.editable
                  ? ""
                  : item.defaultValue
                  ? "Default value: " + item.defaultValue.toLowerCase()
                  : "Enter " + item.displayName.toLowerCase() + " here"
              }
              invalid={errorsFields.indexOf(item.name) !== -1}
            />
          );
        case "dropdown":
          return (
            <PrimaryDropDownMenu
              id={item.name}
              disabled={isDisabled}
              style={{ paddingLeft: "0px" }}
              value={_.isEmpty(ticket) ? "" : ticket[item.source][item.name]}
              invalid={errorsFields.indexOf(item.name) !== -1}
              onChange={(event, index, value) =>
                _handleTicketUpdate({
                  field: item,
                  value
                })
              }
            >
              {item.values.map((option, optionIndex) => (
                <MenuItem
                  key={optionIndex}
                  value={option}
                  primaryText={option}
                />
              ))}
            </PrimaryDropDownMenu>
          );
        default:
          return (
            <SecondaryAutoComplete
              id={item.name}
              disabled={isDisabled}
              hintText={
                !item.editable
                  ? ""
                  : item.defaultValue
                  ? "Default value: " + item.defaultValue.toLowerCase()
                  : "Enter " + item.displayName.toLowerCase() + " here"
              }
              searchText={ticket[item.source][item.name]}
              onNewRequest={chosenRequest => {
                _handleTicketUpdate({
                  field: item,
                  value: chosenRequest
                });
              }}
              onUpdateInput={newValue => {
                if (newValue.length >= 1000) {
                  props.openSnackbar(
                    `Number of characters not greater than 1000`
                  );
                  return;
                }
                _handleTicketUpdate({
                  field: item,
                  value: newValue
                });
                drawerFunctions.ticketFieldAutocomplete(item.name, newValue);
              }}
              multiLine={true}
              maxSearchResults={5}
              dataSource={drawerFunctions.autocompleteFields[item.name] || []}
              filter={(searchText, key) => {
                return true;
              }}
              fullWidth={true}
              floatingLabelFixed={true}
              value={ticket[item.source][item.name]}
              invalid={errorsFields.indexOf(item.name) !== -1}
            />
          );
      }
    } catch (err) {}
  };

  const renderContentHeader = ticket => {
    const renderTicketType = ticket => {
      return (
        <div style={styles.ticketType}>
          Type: &nbsp;&nbsp;
          <span
            style={{
              color: Styles.palette.automationBackgroundColor,
              fontWeight: "bold",
              fontSize: 15,
              paddingLeft: "15px"
            }}
          >
            {props.activeTemplates[ticket.coreData.type].name}
          </span>
        </div>
      );
    };

    const renderTicketState = ticket => {
      return (
        <div style={styles.status}>
          State:
          <PrimaryDropDownMenu
            style={{ marginTop: -10 }}
            id="state"
            value={ticket.coreData.state}
            onChange={(event, index, value) =>
              _handleTicketUpdate({
                field: { name: "state", source: "coreData" },
                value: value
              })
            }
          >
            {(
              (drawerFunctions.stateField &&
                drawerFunctions.stateField.values) ||
              []
            ).map((option, optionIndex) => {
              return (
                <MenuItem
                  key={optionIndex}
                  value={option}
                  primaryText={option}
                />
              );
            })}
          </PrimaryDropDownMenu>
        </div>
      );
    };

    const renderTicketNumber = ticket => {
      return (
        <div>
          <h1 style={styles.ticketNumber}>{ticket.coreData.number} &nbsp;</h1>
          {ticket.coreData.lastUpdateDate && (
            <div style={styles.lastUpdateDate}>
              Updated: {getModifiedDate(ticket.coreData.lastUpdateDate)}
            </div>
          )}
          {/* Other users viewing the ticket*/}
          {currentViewerAvatars()}
        </div>
      );
    };

    const renderTicketConfidenceChip = ticket => {
      return ticket.automationData.confidenceLevel ? (
        <TicketConfidenceChip
          confidenceLevel={ticket.automationData.confidenceLevel.toString()}
        />
      ) : (
        ""
      );
    };

    const renderTicketError = () => {
      return (
        props.ticketError && (
          <div
            style={{
              fontSize: "15px",
              color: "red"
            }}
          >
            {props.ticketError}
          </div>
        )
      );
    };

    const renderRefreshIndicator = ticket => {
      return (
        showRefreshIndicator && (
          <div
            style={{
              fontSize: "15px",
              color: "red",
              display: "flex"
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 10
              }}
            >
              Ticket has been updated. Click the refresh button to try again.
            </span>
            <RefreshIndicator
              percentage={99}
              color="#0277bd"
              loadingColor="#0277bd"
              status={props.refreshing ? "loading" : "ready"}
              style={{
                cursor: "pointer",
                position: "initial",
                transform: null
              }}
              left={0}
              top={0}
              size={30}
              onClick={() => {
                props.refreshDetails(ticket);
              }}
            />
          </div>
        )
      );
    };

    const renderCreatingProgress = () => {
      return (
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <Col
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <SecondaryCircularProgress size={90} thickness={6} />
          </Col>
        </Row>
      );
    };

    let mlTemplateMapping = [];
    let templateMappings = [];
    let transformedSimilarTickets = [];
    let topSimilarTickets = [];

    if (!props.isNewTicket) {
      const originalMLTemplateMapping = ticket.mlData.map(ml => ml.key);
      const selectedTemplate =
        props.selectedTicketType &&
        props.templates &&
        props.templates.find(
          template => template.ticketType === props.selectedTicketType
        );
      templateMappings = (selectedTemplate && selectedTemplate.mapping) || [];

      mlTemplateMapping = originalMLTemplateMapping.map(originalMlData => {
        let foundKey = "";
        Object.keys(templateMappings).forEach(key => {
          if (templateMappings[key] === originalMlData) {
            foundKey = key;
          }
        });
        return foundKey || originalMlData;
      });

      const completedStates = ["resolved", "closed"];
      transformedSimilarTickets = props.similarTickets
        .filter(
          fuzzyTicket =>
            // Remove tickets with scores < 0.2 (and the ticket itself)
            fuzzyTicket.similarityScore >= 0.2 &&
            fuzzyTicket.coreData.number !== ticket.coreData.number
        )
        .sort((a, b) =>
          b.similarityScore !== a.similarityScore
            ? // Similarity scores are different, sort using them
              b.similarityScore - a.similarityScore
            : // Both simliarity scores are equal, sort by last updated date next
              new Date(b.coreData.lastUpdateDate) -
              new Date(a.coreData.lastUpdateDate)
        )
        .sort((a, b) => {
          if (
            completedStates.includes(b.coreData.state.toLowerCase()) &&
            !completedStates.includes(a.coreData.state.toLowerCase())
            // If b is completed and a is not, choose b first
          ) {
            return 1;
          } else if (
            !completedStates.includes(b.coreData.state.toLowerCase()) &&
            completedStates.includes(a.coreData.state.toLowerCase())
          ) {
            // If a is completed and b is not, choose a first
            return -1;
          } else {
            // If both are completed or both not completed, no change.
            return 0;
          }
        });
      topSimilarTickets = similarTicketsShowAll
        ? transformedSimilarTickets
        : transformedSimilarTickets.slice(0, similarTicketLimit);
    }

    const renderForm = () => {
      return (
        <div>
          {// CREATE FORM
          ((fields && fields.create) || []).map(item => {
            const isDisabled =
              item.mandatory && !item.editable
                ? false
                : !item.editable
                ? true
                : props.isNewTicket
                ? false
                : mlTemplateMapping.includes(item.name);
            return (
              <div
                key={item.name}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  paddingBottom: "5px",
                  paddingLeft: "0px",
                  paddingRight: "0px"
                }}
              >
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px",
                    paddingRight: "1rem"
                  }}
                >
                  {item.mandatory
                    ? item.displayName + "*" || item.name + "*"
                    : item.displayName || item.name}
                </Col>
                <Col xs={10} style={{ padding: "0px" }}>
                  {createFields(item, isDisabled)}
                </Col>
              </div>
            );
          })}
          {!props.isNewTicket && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
                paddingBottom: "5px",
                paddingLeft: "0px",
                paddingRight: "0px",
                marginTop: "15px",
                fontSize: "12px"
              }}
            >
              <Col xs={2} style={{ padding: 0 }}>
                Similar tickets
              </Col>
              <Col xs={10} style={{ padding: 0 }}>
                {props.isFetchingSimilarTickets ? (
                  <SecondaryCircularProgress size={12} thickness={1.5} />
                ) : topSimilarTickets.length === 0 ? (
                  "No similar tickets found"
                ) : (
                  <Row
                    style={{
                      width: "100%",
                      margin: "inherit"
                    }}
                  >
                    {topSimilarTickets.map((ticket, i) => (
                      <span
                        key={`similar-ticket-${ticket.coreData.number}-${i}`}
                        style={{
                          border: "1px solid #bababa",
                          padding: "3px 7px",
                          color: "#0277BD",
                          marginRight: "5px",
                          marginBottom: "5px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/atr/tickets/${ticket.coreData.number}`
                          );
                        }}
                      >
                        {ticket.coreData.number}
                      </span>
                    ))}
                    {transformedSimilarTickets.length > similarTicketLimit && (
                      <span
                        style={{
                          alignSelf: "center",
                          color: "#0277BD",
                          marginLeft: "5px",
                          marginBottom: "5px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setSimilarTicketsShowAll(!similarTicketsShowAll);
                        }}
                      >
                        {similarTicketsShowAll ? "View less" : "View more..."}
                      </span>
                    )}
                  </Row>
                )}
              </Col>
            </div>
          )}
        </div>
      );
    };

    const renderCustomTicketDetails = ticket => {
      return (
        ticket.coreData &&
        Boolean(
          ~["SERVICE_REQUEST", "SR_ITEM", "SR_TASK"].indexOf(
            ticket.coreData.type
          )
        ) && (
          <ServiceRequestLinks
            ticket={ticket}
            getServiceRequest={props.getServiceRequest}
            getServiceRequestItems={props.getServiceRequestItems}
            getServiceRequestTasks={props.getServiceRequestTasks}
            serviceRequest={props.serviceRequest}
            srItems={props.srItems}
            srTasks={props.srTasks}
            closeDetailsTicket={props.closeDetailsTicket}
            openDetails={props.openDetails}
          />
        )
      );
    };

    const renderContinuousLearningButton = ticket => {
      return (
        _showContinuousLearningButton(ticket) && (
          <ContinuousLearningButton
            ticket={ticket}
            addToContinuousML={props.addToContinuousML}
            isDisabled={props.defaultTicket.continuousML}
          />
        )
      );
    };

    const renderGenericTicketDetails = ticket => {
      const {
        template: {
          secondaryFields,
          ticketSource: { canAutomate }
        }
      } = props;
      const showDrawerOnSecondaryFieldsCondition =
        fields && ticket && secondaryFields && secondaryFields.length > 0;

      const showDrawer =
        !ticket.isNewTicket &&
        (showDrawerOnSecondaryFieldsCondition || canAutomate);
      if (!showDrawer) {
        return null;
      }

      return (
        <>
          <TicketDetailsDrawerTabMenu
            handleTabChange={_handleTabChange}
            slideIndex={drawerFunctions.slideIndex}
            fontSize={"14px"}
            tabs={[
              "Automation",
              ...dynamicTabs.map(tab => tab.displayName),
              "Properties",
              "Resolution",
              "Activity",
              "Workflow"
            ]}
          />
          <TicketDetailsDrawerTabItems
            stateTicket={ticket}
            fields={fields}
            slideIndex={drawerFunctions.slideIndex}
            articles={articles}
            handleTicketUpdate={_handleTicketUpdate}
            handleReplaceTicket={setTicket}
            _handleTabChange={_handleTabChange}
            handleResolutionsChange={_handleResolutionsChange}
            mlObj={mlObj}
            mlTemplateMapping={mlTemplateMapping}
            drawerFunctions={drawerFunctions}
            // updateMappingFound={_updateMappingFound}
            ticketType={props.ticketType}
            atsInvalid={atsInvalid}
            dynamicTabs={dynamicTabs}
            {...props}
          />
        </>
      );
    };

    return (
      <div style={styles.contentHeader}>
        {!ticket.isNewTicket && ticket.coreData && (
          <div>
            <div style={{ display: "flex" }}>
              {renderTicketType(ticket)}
              {renderTicketState(ticket)}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "left",
                margin: "20px 0"
              }}
            >
              {renderTicketNumber(ticket)}
              {renderTicketConfidenceChip(ticket)}
            </div>
          </div>
        )}
        {renderTicketError()}
        {renderRefreshIndicator(ticket)}
        {props.isTicketSingleCreating ? renderCreatingProgress() : renderForm()}
        {renderCustomTicketDetails(ticket)}
        <br />
        {renderContinuousLearningButton(ticket)}
        {renderGenericTicketDetails(ticket)}
      </div>
    );
  };

  const renderErrorBox = errorMessage => {
    return (
      <div
        style={{
          height: "100%",
          marginTop: "25%"
        }}
      >
        <ErrorBox errorMessage={errorMessage} />
      </div>
    );
  };

  const renderContentActions = ticket => {
    const renderCreateTicketActions = ticket => {
      return (
        <div>
          {!props.isTicketSingleCreating && (
            <SecondaryBorderedButton
              onClick={() => {
                if (validateInput(ticket)) {
                  drawerFunctions.handleCreateTicket(ticket);
                }
              }}
              label={"Create ticket"}
            />
          )}
        </div>
      );
    };

    const renderEditTicketActions = () => {
      const renderEmailButton = () => {
        return (
          <SecondaryBorderedButton
            onClick={() => props.toggleEmailDialog()}
            label={
              <strong>
                <FontIcon
                  className="fa fa-envelope-o"
                  style={{
                    fontSize: 14,
                    color: Styles.secondaryBtn
                  }}
                />
                &nbsp; Email
              </strong>
            }
          />
        );
      };

      const renderUpdateButton = () => {
        return (
          <SecondaryBorderedButton
            disabled={showRefreshIndicator}
            onClick={() => {
              //do not dispatch the update action if there is already one in progress
              if (!props.ticketIsUpdating && validateInput(ticket)) {
                _ticketUpdateSingle();
              }
            }}
            label={
              !props.ticketIsUpdating ? (
                <strong>
                  <FontIcon
                    className="fa fa-save"
                    style={{
                      fontSize: 14,
                      color: Styles.secondaryBtn
                    }}
                  />
                  &nbsp; Update
                </strong>
              ) : (
                <strong>
                  <FontIcon
                    className="fa fa-spinner fa-spin"
                    style={{
                      fontSize: 14,
                      color: Styles.secondaryBtn
                    }}
                  />
                  &nbsp; Update
                </strong>
              )
            }
          />
        );
      };

      const renderManualButton = () => {
        const tm = ticket.mlData.find(d => d.key === window.config.gtr.tmKey);
        const isValueGTR = () => {
          if (!tm) return false;
          if (tm.userValue === "GTR") {
            return true;
          } else if (tm.userValue === null) {
            if (tm.predictions && tm.predictions[0].label === "GTR") {
              return true;
            }
          } else {
            return false;
          }
          return false;
        };
        const atsItem = ticket.mlData.filter(mlItem => mlItem.key === "gtr");
        return (
          isValueGTR() &&
          !_.isEmpty(props.manualResolutionLink) &&
          !_.isEmpty(atsItem) && (
            <PrimaryButton
              onClick={() => _openManualResolution(atsItem[0])}
              style={{ marginRight: 10 }}
              label={
                <strong>
                  <FontIcon
                    className="fa fa-check"
                    style={{
                      fontSize: 14,
                      color: Styles.secondaryBtn
                    }}
                  />{" "}
                  Manual
                </strong>
              }
            />
          )
        );
      };

      const renderAutomateButton = () => {
        return (
          <GreenButton
            onClick={() => {
              if (
                !props.isAutomating &&
                props.mappingFound &&
                props.mappingFound.targetServiceName &&
                props.mappingFound.targetJobName &&
                props.mappingFound.targetProjectName
              ) {
                props.ticketRunSingleJob(
                  props.mappingFound.targetServiceName,
                  props.jobSelected,
                  ticket
                );
              } else {
                props.openSnackbar("Please specifiy Target Job for automation");
              }
            }}
            label={
              props.isAutomating ? (
                <strong>
                  <FontIcon
                    className="fa fa-refresh fa-spin"
                    style={{
                      fontSize: 14,
                      color: "white"
                    }}
                  />{" "}
                  Automating
                </strong>
              ) : (
                <strong>
                  <FontIcon
                    className="fa fa-refresh"
                    style={{
                      fontSize: 14,
                      color: "white"
                    }}
                  />
                  &nbsp;Automate
                </strong>
              )
            }
          />
        );
      };

      return (
        <div>
          {_automatableTicket() && renderEmailButton()}
          &nbsp;&nbsp;
          {ticket.coreData &&
            props.permissions.includes("TM_TICKET_WRITE_UPDATE") &&
            ticket.coreData.type !== "EMAIL" &&
            renderUpdateButton()}{" "}
          &nbsp;&nbsp;
          {_automatableTicket() && renderManualButton()}
          {_automatableTicket() &&
            props.permissions.some(element => {
              if (element) {
                return (
                  element.startsWith("TM_AUTOMATEJOB") ||
                  element === "TM_AUTOMATEJOB_ADMIN"
                );
              }
              return null;
            }) &&
            renderAutomateButton()}
          &nbsp;
        </div>
      );
    };

    return (
      <Paper style={styles.contentActionsContainer}>
        <div style={styles.contentActions}>
          {ticket && ticket.isNewTicket
            ? renderCreateTicketActions(ticket)
            : renderEditTicketActions(ticket)}
        </div>
      </Paper>
    );
  };
  // ======== FORM START HERE ===========
  return (
    <div
      style={{
        width: 40 + dynamicTabs.length * 7 + "rem"
      }}
    >
      <div
        style={{
          overflow: "visible",
          height: "100%",
          paddingBottom: "100px"
        }}
      >
        {!_.isEmpty(props.template) ? (
          <div>
            {// Make sure ticket is defined and is not empty
            ticket
              ? renderContentHeader(ticket)
              : renderErrorBox(
                  "Error getting the ticket data. Try reloading the page."
                )}
          </div>
        ) : (
          renderErrorBox(
            "Error getting ticket template, create and activate a template through the ticket template page under settings"
          )
        )}
      </div>
      {renderContentActions(ticket)}
      {props.isOpenEmailDialog && <EmailDialogPage />}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
