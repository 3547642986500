import { connect } from "react-redux";
import {
  submitConfig,
  updateConfig,
  updateCollapse
} from "redux/modules/plugin";
import PluginDrawerConfig from "./Config";

const mapStateToProps = (state, ownProps) => ({
  configList: state.plugin.configs.allIds,
  pluginConfigs: state.plugin.currentPlugin.configs,
  pluginId: state.plugin.currentPluginId,
  isLoading: state.plugin.configs.byId[state.plugin.currentPluginId].isLoading
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateConfig: ({ pluginId, value }) =>
    dispatch(updateConfig({ pluginId, value })),
  submitConfig: id => dispatch(submitConfig(id)),
  updateCollapse: index => dispatch(updateCollapse(index))
});

const PluginDrawerConfigCTN = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginDrawerConfig);

export default PluginDrawerConfigCTN;
