import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Header from "./components/Header";
import BasePanel from "./components/BasePanel";
import TabPanel from "./components/TabPanel";
import FinalPage from "./components/FinalPage/container";
import { getProviderFilterPayload, getRulesFromWorkflows } from "../utils";
import BaseHeader from "./components/BaseHeader";
import BaseFooter from "./components/BaseFooter";
import {
  ITSMSelection,
  ITSMConfig,
  AutomationFilters,
  EnableUseCase,
  UserManagement,
  ReviewAndLaunch
} from "./panels";
import { HeaderText, SubTitleText } from "./components/BaseHeader/styles";
import Prerequisite from "./components/Prerequisite";

const ResponsiveView = props => {
  const {
    providerFilters,
    configs,
    isSaving,
    saveProviderFilter,
    reviewConfirmed,
    snowConfig,
    updateRule,
    rules,
    mappings,
    selectedWorkflows,
    demoWorkflows
  } = props;
  const [curStep, setCurStep] = useState(0);
  const [wasSaving, setWasSaving] = useState(false);

  const enableWorkflows = useCallback(
    async workflows => {
      const demoRules = getRulesFromWorkflows(demoWorkflows, mappings, rules);
      const enabledRules = getRulesFromWorkflows(workflows, mappings, rules);
      const rulesCopy = [...demoRules];
      const postRequests = [];
      // enable or disable rules appropriately
      for (const rule of rulesCopy) {
        rule.enabled = enabledRules.findIndex(r => r.id === rule.id) !== -1;
        const request = new Promise(resolve => {
          updateRule(rule).then(res => resolve(res));
        });
        postRequests.push(request);
      }
      return await Promise.all(postRequests);
    },
    [updateRule, rules, mappings, demoWorkflows]
  );

  const handleNext = () => {
    if (curStep < steps.length) {
      setCurStep(curStep + 1);
    }
  };

  const handleBack = () => {
    if (curStep > 0) {
      setCurStep(curStep - 1);
    }
  };

  const handleChange = newValue => {
    setCurStep(newValue);
  };

  const handleSave = () => {
    const payload = getProviderFilterPayload(providerFilters);
    saveProviderFilter(configs, payload);
    // update rules
    enableWorkflows(selectedWorkflows).then();
  };

  useEffect(() => {
    if (isSaving && !wasSaving) {
      // remember that save is in progress
      setWasSaving(true);
    }
    if (wasSaving && !isSaving) {
      // once saving done, go to next page
      // Note: timeout is just for visually showing that it's saving
      setTimeout(() => {
        setWasSaving(false);
        setCurStep(curStep + 1);
      }, 1500);
    }
  }, [isSaving, setCurStep, wasSaving, curStep]);

  const steps = [
    {
      label: "Connect to ITSM",
      header: (
        <BaseHeader contentType="NODE">
          <Prerequisite />
          <HeaderText>Connect to your ITSM provider(s)</HeaderText>
        </BaseHeader>
      ),
      content: <ITSMSelection onNext={handleNext} />,
      footer: <BaseFooter onNext={handleNext} />,
      isSubStep: true
    },
    {
      label: "Connect to ITSM",
      header: <BaseHeader title="Connect to your ITSM provider(s)" />,
      content: <ITSMConfig />,
      footer: <BaseFooter onBack={handleBack} onNext={handleNext} />,
      isSubStep: false
    },
    {
      label: "Apply filters",
      header: (
        <BaseHeader contentType="NODE">
          <HeaderText>Select applicable tickets</HeaderText>
          <SubTitleText>
            Select all tickets and tasks that are in scope of your contract by
            selecting appropriate assignment groups or user IDs. Click{" "}
            <a href={snowConfig.url} target="_blank">
              here
            </a>{" "}
            to view the list of assignment groups and user IDs for your client.
          </SubTitleText>
        </BaseHeader>
      ),
      content: <AutomationFilters />,
      footer: <BaseFooter onNext={handleNext} onBack={handleBack} />,
      isSubStep: false
    },
    {
      label: "Select use cases",
      header: (
        <BaseHeader
          title="Select use case templates"
          subTitle="Enable a variety of out-of-the-box use case templates from our service catalog, or create a new one"
        />
      ),
      content: <EnableUseCase />,
      footer: <BaseFooter onNext={handleNext} onBack={handleBack} />,
      isSubStep: false
    },
    {
      label: "Manage users",
      header: (
        <BaseHeader
          title="Manage Users"
          subTitle="Validate your users on ATR"
        />
      ),
      content: <UserManagement />,
      footer: <BaseFooter onNext={handleNext} onBack={handleBack} />,
      isSubStep: false
    },
    {
      label: "Review & Launch",
      header: (
        <BaseHeader
          title="Review &amp; Launch"
          subTitle="You are now set to get started on automation. Please carefully review the configuration and confirm to proceed"
        />
      ),
      content: <ReviewAndLaunch handleChange={handleChange} />,
      footer: (
        <BaseFooter
          onNext={handleSave}
          onBack={handleBack}
          nextBtnText={isSaving || wasSaving ? "Saving..." : "Save & Exit"}
          isNextDisabled={!reviewConfirmed}
        />
      ),
      isSubStep: false
    }
  ];

  const headerSteps = useMemo(
    () =>
      steps
        .map((step, index) => ({
          label: step.label,
          isSubStep: step.isSubStep,
          stepNum: index
        }))
        .filter(step => !step.isSubStep),
    [steps]
  );

  return (
    <div>
      <Header
        steps={headerSteps}
        curStep={curStep}
        handleChange={handleChange}
      />
      {steps.map((step, index) => (
        <BasePanel
          key={`step-tabpanel-${index}`}
          header={step.header}
          content={step.content}
          footer={step.footer}
          value={curStep}
          index={index}
        />
      ))}
      <TabPanel
        key={`step-tabpanel-${steps.length}`}
        index={steps.length}
        value={curStep}
      >
        <FinalPage />
      </TabPanel>
    </div>
  );
};

ResponsiveView.propTypes = {
  providerFilters: PropTypes.object.isRequired,
  configs: PropTypes.object,
  isSaving: PropTypes.bool.isRequired,
  saveProviderFilter: PropTypes.func.isRequired,
  snowConfig: PropTypes.object.isRequired,
  updateRule: PropTypes.func.isRequired,
  mappings: PropTypes.array,
  rules: PropTypes.array,
  selectedWorkflows: PropTypes.any,
  demoWorkflows: PropTypes.array
};

ResponsiveView.defaultProps = {
  mappings: [],
  rules: [],
  selectedWorkflows: [],
  demoWorkflows: []
};

export default ResponsiveView;
