import { connect } from "react-redux";
import { setColumnFilter } from "reducers/tickets";
import { getAvailableTicketField } from "redux/reselect/ticket";
import { toggleCheckBox } from "redux/modules/userData";
import { getCustomizedTicketField } from "redux/reselect/ticket";

import TicketTableCustomizationAvailable from "./Available";

const mapStateToProps = state => {
  const availableTemplateColumn = getAvailableTicketField(state);
  const selectedTicketType = state.tickets.selectedTicketType;
  const selectedUserData =
    state.userData.updatedUserData.ticketTableColumns[
      state.tickets.selectedTicketType
    ] || "";

  return {
    availableTemplateColumn: availableTemplateColumn,
    selectedTicketType: selectedTicketType,
    filter: state.tickets.columnFilter,
    selectedUserData: selectedUserData
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setColumnFilter: filter => dispatch(setColumnFilter(filter)),
    toggleCheckBox: (type, item, value) => {
      dispatch(toggleCheckBox(type, item, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTableCustomizationAvailable);
