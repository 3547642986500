import { connect } from "react-redux";

import AutomationQuickstart from "../../components/settings/AutomationQuickstart";

import { getDemoWorkflows } from "../../actions/tutorial";
import { getConfig, openPluginData } from "../../redux/modules/plugin";
import { openAllMappings } from "../../actions/mapping";
import { openRules } from "../../actions/rules";
import { fetchUserList } from "../../actions/users";

const mapStateToProps = state => {
  return {
    configs: state.plugin.configs
  };
};

const mapDispatchToProps = dispatch => ({
  getConfig: id => dispatch(getConfig(id)),
  openPlugin: id => dispatch(openPluginData(id)),
  getDemoWorkflows: () => dispatch(getDemoWorkflows()),
  getMappings: () => dispatch(openAllMappings()),
  getRules: () => dispatch(openRules(0, 500)),
  getUsers: () => dispatch(fetchUserList(0, 500))
});

const AutomationQuickstartPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationQuickstart);

export default AutomationQuickstartPage;
