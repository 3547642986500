import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";

// TODO when refactoring, make this a reusable component

const MainContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
`;

const Pagination = props => {
  const { page, count, handleChange } = props;

  const prevPages = page - 2 < 0 ? 0 : page - 2;
  const nextPages = page + 2 > count ? count : page + 2;

  const pageButtons = [];

  for (let i = prevPages; i < nextPages; i++) {
    pageButtons.push(
      <Button
        key={`button-${i}`}
        children={i + 1}
        disabled={page === i}
        onClick={() => handleChange(i)}
      />
    );
  }

  return (
    <MainContainer>
      {page > 0 && <Button onClick={() => handleChange(page - 1)}>BACK</Button>}
      {pageButtons}
      {page < count - 1 && (
        <Button onClick={() => handleChange(page + 1)}>NEXT</Button>
      )}
    </MainContainer>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default Pagination;
