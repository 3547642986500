import React, { useState, useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const styles = {
  container: {
    position: "absolute",
    outline: 0,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    background: "#fefefe",
    overflow: "auto",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Graphik, Arial, Helvetica, sans-serif",
    lineHeight: 1.5,
    color: "#262626",
    margin: "auto",
    padding: 0,
    borderRadius: 18
  },
  title: {
    padding: "16px 32px",
    borderColor: "#008",
    background: "#FFFFFF",
    borderRadius: "18px 18px 0 0",
    fontSize: 20,
    fontWeight: 700,
    color: "#008"
  },
  content: {
    padding: "0 28px",
    maxHeight: 320,
    overflow: "auto",
    margin: "0.5rem"
  },
  footer: {
    padding: "12px 32px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    backgroundImage: "linear-gradient(90deg, #004DFF, #00BAFF)",
    boxShadow: "0 0 4px 1px rgba(0, 0, 0, 0.2)",
    borderRadius: 20,
    color: "#FFFFFF"
  }
};

const ConfidentialityPopover = props => {
  const [isOpen, setIsOpen] = useState(true);

  const {
    classes,
    registerUserConsent,
    confidentialityPopoverPressed,
    userProfile
  } = props;

  useEffect(() => {
    const toOpen =
      !(
        userProfile &&
        userProfile.id &&
        userProfile["dataCollectionConsentDate"]
      ) &&
      !confidentialityPopoverPressed &&
      true;
    setIsOpen(toOpen);
  }, []);

  const AssetPoCEmailId = "myWizAiOps.ATR.ProductOwners@accenture.com";
  const AssetSupportTeamEmailId = "myWizAiOps.L3Support@accenture.com";

  const handleClick = () => {
    const consentDate = new Date().toISOString();
    registerUserConsent(consentDate);
    sessionStorage.setItem("isConfidentialityChecked", true);
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen}>
      <div className={classes.container}>
        <Typography className={classes.title} variant="h6" component="h2">
          Confidential Information – Reminder
        </Typography>
        <Typography className={classes.content}>
          You are using an Accenture proprietary tool ATR. You understand that
          this tool may contain
          <strong>
            {" "}
            ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION
          </strong>
          . You are obligated to adhere to applicable confidentiality
          obligations and Accenture’s policies, including Policy 69 –
          Confidentiality, when using the tool and information in the tool.
          <br />
          Information in the tool can only be shared with those authorized to
          receive it. If you are downloading/exporting Confidential Information
          to another file or report, you must label that file or report as
          Accenture Confidential. If you have any questions, please email your
          question to{" "}
          <a
            href={`mailto:confidentiality@accenture.com?subject=Confidential Information Query (Asset: ATR})&cc=${AssetPoCEmailId};${AssetSupportTeamEmailId}`}
          >
            confidentiality@accenture.com
          </a>{" "}
          or contact your Leadership. Thank you!
        </Typography>
        <div className={classes.footer}>
          <Button className={classes.button} onClick={handleClick}>
            I understand
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(ConfidentialityPopover);
