import { connect } from "react-redux";
import Configuration from "../../components/settings/configuration/Configuration";
import { closeBundles, openBundles } from "../../actions/bundles";
import { closeSnackbar, openSnackbar } from "../../actions/snackbar";
import { getActiveTemplates } from "../../actions/mapping";
import {
  getTicketConfig,
  addTicketConfigThenRefreshList,
  deleteTicketConfig,
  deleteTicketConfigThenRefreshList,
  getMLModels
} from "../../actions/ticketConfig";
import {
  retrieveDataSourceList,
  createDataSource
} from "../../actions/dataSource";

const mapStateToProps = state => ({
  activeTemplates: state.mapping.activeTemplates,
  isBundlesOpen: state.bundles.isOpen,
  isSnackbarOpen: state.snackbar.isOpen,
  services: state.ticketConfig.services,
  dataSource: state.dataSource.dataSource,
  dataSources: state.dataSource.dataSources,
  MLModels: state.ticketConfig.MLModels,
  permissions: state.user.permissions,
  token: state.auth.token
});

const mapDispatchToProps = dispatch => ({
  getActiveTemplates: () => dispatch(getActiveTemplates()),
  getMLModels: () => dispatch(getMLModels()),
  createDataSource: dataSource => dispatch(createDataSource(dataSource)),
  retrieveDataSourceList: () => dispatch(retrieveDataSourceList()),
  closeBundles: () => dispatch(closeBundles()),
  openBundles: () => dispatch(openBundles()),
  closeSnackbar: () => dispatch(closeSnackbar()),
  openSnackbar: message => dispatch(openSnackbar(message)),
  getTicketConfig: service => dispatch(getTicketConfig(service)),
  deleteTicketConfig: service => dispatch(deleteTicketConfig(service)),
  addTicketConfigThenRefreshList: (service, newInstance) =>
    dispatch(addTicketConfigThenRefreshList(service, newInstance)),
  deleteTicketConfigThenRefreshList: (service, toDelete) =>
    dispatch(deleteTicketConfigThenRefreshList(service, toDelete))
});

const SettingsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Configuration);

export default SettingsPage;
