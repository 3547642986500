/**
 * Created by x1 on 5/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import Toggle from "material-ui/Toggle";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

const muiTheme = getMuiTheme({
  fontFamily: '"Open Sans", sans-serif',
  palette: {
    primary1Color: themeMainUI.palette.primary2Color
  }
});

class SecondaryCheckbox extends React.Component {
  render() {
    const { requiredError, ...rest } = this.props;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <Toggle {...rest} />
      </MuiThemeProvider>
    );
  }
}

export default SecondaryCheckbox;
