import { connect } from "react-redux";
import TicketTemplateViewerTemplate from "./Template";
import {
  setTicketType,
  setName,
  setFieldTab,
  resetCurrentTemplate
} from "redux/modules/ticketTemplate";
import { getSecondaryFieldOption } from "redux/reselect/ticketTemplate";

const mapStateToProps = state => {
  return {
    secondaryFieldEnabled: getSecondaryFieldOption(state),
    route: state.ticketTemplates.route,
    templateTypes: state.ticketTemplates.templateTypes,
    currentTab: state.ticketTemplates.currentTab,
    name: state.ticketTemplates.currentTemplate.name,
    ticketType: state.ticketTemplates.currentTemplate.ticketType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTicketType: ticketType => dispatch(setTicketType(ticketType)),
    setName: name => dispatch(setName(name)),
    setFieldTab: tab => dispatch(setFieldTab(tab)),
    resetCurrentTemplate: () => dispatch(resetCurrentTemplate())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateViewerTemplate);
