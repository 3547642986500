import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TabLabelContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StepContainer = styled.span`
  padding: 10px;
  font-size: 20px;

  @media screen and (max-width: 992px) {
    padding: 0px;
    margin-right: 10px;
  }

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    padding: 0px;
    margin-right: 10px;
  }

  @media screen and (max-height: 768px) {
    padding: 0px;
    margin-right: 10px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    padding: 0px;
    margin-right: 10px;
  }
`;

export const StepNumber = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  color: #636363;
`;

export const StepLabel = styled.p`
  font-size: 15px;
  line-height: 27px;
  font-weight: bold;
  padding: 10px;
  margin: 0;
  color: #636363;

  @media screen and (max-width: 1120px) {
    padding: 0px;
    margin-left: 10px;
    font-size: 8px;
  }

  @media screen and (min-width: 1120px) and (max-width: 1400px) {
    padding: 0px;
    margin-left: 10px;
    font-size: 10px;
  }

  @media screen and (max-height: 768px) {
    padding: 0px;
    margin-left: 10px;
    font-size: 8px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    padding: 0px;
    margin-left: 10px;
    font-size: 10px;
  }
`;

export const Divider = styled.span`
  border-right: 1px solid #aaaaaa;
  height: 40px;
`;

export const CompleteIcon = styled(FontAwesomeIcon)`
  color: #00cc83;
`;
