import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const GridContent = styled(Grid)`
  height: calc(100% - 50px);
`;

export const GridFooter = styled(Grid)`
  height: 50px;
`;
