import React, { useState } from "react";
import Dropzone from "react-dropzone";
import FontIcon from "material-ui/FontIcon";
//dialogs
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import {
  FormControlLabel,
  Checkbox,
  withStyles,
  Typography
} from "@material-ui/core";
import { isEmpty } from "lodash";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import { red400, red500 } from "material-ui/styles/colors";
import { Error } from "@material-ui/icons";
import classNames from "classnames";

const styles = theme => ({
  heading: {
    marginTop: 20
  },
  configIcon: {
    paddingTop: 8
  },
  btnArea: {
    marginTop: 30,
    marginBottom: 30,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center"
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  iconMedium: {
    fontSize: 30
  },
  errorMsg: {
    display: "flex",
    alignItems: "center",
    color: red500,
    paddingTop: 10
  },
  contentActionsContainer: {
    padding: "20px 20px",
    paddingBottom: 30,
    flexGrow: 0,
    flexShrink: 0,
    alignItems: "center"
  },
  fileList: {
    display: "flex",
    alignItems: "center"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const PluginNewPlugin = props => {
  const { isNewPlugin: open, files, isOverride: checked, classes } = props;
  const { closeNewPlugin, onDrop, onChangeOverride, onSubmitNewPlugin } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = () => setIsSubmitting(true);
  const onCancel = () => {
    setIsSubmitting(false);
    closeNewPlugin();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      onClose={closeNewPlugin}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {!isSubmitting ? (
        <>
          <DialogTitle id="alert-dialog-slide-title">
            UPLOAD A PLUGIN
            <div className={classes.errorMsg}>
              <div>
                <Error
                  color="secondary"
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
              </div>
              <Typography variant="subheading" color="secondary">
                Successfully uploading a plugin will cause the server to
                restart.
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <div>
              {isEmpty(files) && (
                <Dropzone
                  accept=".jar"
                  style={{
                    marginTop: 15,
                    paddingTop: 20,
                    paddingBottom: 10,
                    borderWidth: 2,
                    borderColor: "#92C2E0",
                    borderStyle: "dashed",
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    color: "#2389C5",
                    backgroundColor: "#F9FDFF",
                    alignItems: "center"
                  }}
                  onDrop={onDrop}
                >
                  <FontIcon
                    style={{ fontSize: 40, color: "inherit" }}
                    className="fa fa-upload"
                  />
                  <p>Drag and drop your JAR to upload</p>
                  <p>or</p>
                  <p>
                    <strong>Click to select file</strong>
                  </p>
                </Dropzone>
              )}
              {!isEmpty(files) && (
                <>
                  <div className={classes.fileList}>
                    <div>{files[0].name}</div>
                    <IconButton aria-label="remove" onClick={onCancel}>
                      <RemoveIcon style={{ fontSize: 25, color: red400 }} />
                    </IconButton>
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onClick={onChangeOverride}
                        value="checkedB"
                        color="primary"
                      />
                    }
                    label="Checking this box will override any existing plugin with the same ID"
                  />
                </>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeNewPlugin} color="secondary">
              Cancel
            </Button>
            <Button
              disabled={isEmpty(files)}
              onClick={onSubmit}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id="alert-dialog-slide-title">
            <div className={classes.errorMsg}>
              <div>
                <Error
                  className={classNames(classes.leftIcon, classes.iconMedium)}
                />
              </div>
              <Typography variant="subheading">
                Successfully uploading a plugin will cause the server to
                restart, which may take a few minutes.
              </Typography>
            </div>
          </DialogTitle>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={onSubmitNewPlugin} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(PluginNewPlugin);
