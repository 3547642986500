import React from "react";
import PropTypes from "prop-types";
import {
  ButtonContainer,
  DescriptionText,
  TitleText,
  SelectCheckbox,
  LinkContainer,
  SmallButton
} from "./styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faListOl } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";

const UseCasePanel = props => {
  const {
    title,
    category,
    description,
    workflowLink,
    rulesLink,
    isSelected,
    onClick
  } = props;
  return (
    <ButtonContainer isSelected={isSelected} focusRipple onClick={onClick}>
      <Grid container spacing={8} alignItems="center" justify="space-between">
        <Grid item>
          <TitleText>
            {category && `${category} > `}
            {title}
          </TitleText>
          <DescriptionText>{description}</DescriptionText>
        </Grid>
        <Grid item>
          <LinkContainer>
            <Link
              to={{ pathname: workflowLink }}
              target="_blank"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Tooltip title="Show Workflow">
                <SmallButton>
                  <FontAwesomeIcon icon={faEye} />
                </SmallButton>
              </Tooltip>
            </Link>
            <Link
              to={{ pathname: rulesLink }}
              target="_blank"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Tooltip title="Edit Rules">
                <SmallButton>
                  <FontAwesomeIcon icon={faListOl} />
                </SmallButton>
              </Tooltip>
            </Link>
          </LinkContainer>
          <SelectCheckbox checked={isSelected} />
        </Grid>
      </Grid>
    </ButtonContainer>
  );
};

UseCasePanel.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  workflowLink: PropTypes.string.isRequired,
  rulesLink: PropTypes.string.isRequired
};

UseCasePanel.defaultProps = {
  isSelected: false,
  onClick: () => {}
};

export default UseCasePanel;
