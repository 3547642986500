const success = {
  time: 3040,
  machineLearning: {
    time: 324,
    success: true,
    info: null,
    results: [
      {
        time: 100,
        success: true,
        info: null,
        output: {
          configMatched: {
            key: "assignmentGroupATR",
            source: null,
            type: null,
            weighting: 5,
            predictions: [
              {
                label: "Access Issue",
                prob: 0.9678087578876188
              },
              {
                label: "Service Request",
                prob: 0.1321912421123812
              }
            ],
            userValue: null,
            layer: null,
            modelId: "619f1d4dda6b16002eaf018e"
          }
        }
      },
      {
        time: 100,
        success: true,
        info: null,
        output: {
          configMatched: {
            key: "u_knowledge",
            source: null,
            type: null,
            weighting: 5,
            predictions: [
              {
                label: "AWS Details",
                prob: 0.8
              },
              {
                label: "esetup submission",
                prob: 0.2
              }
            ],
            userValue: null,
            layer: null,
            modelId: "619f1d7cda6b160031f6f7a4"
          }
        }
      }
    ]
  },
  mapping: {
    time: 324,
    success: true,
    info: null,
    results: [
      {
        time: 100,
        success: true,
        info: null,
        output: {
          configMatched: {
            id: "619f1cdd9efb791e1901bffb",
            mappingName: "AWS Instance Access",
            ticketType: "STANDALONE",
            triggerConditions: {
              fields: [
                {
                  name: "category",
                  displayName: "Category", // field
                  type: "dropdown",
                  value: "Process Issue", // condition
                  regex: false,
                  options: [
                    "Application Issue",
                    "Training Issue",
                    "Human Error",
                    "Configuration Issue",
                    "Process Issue"
                  ],
                  valueFromTicket: "Process Issue"
                },
                {
                  name: "shortDescription",
                  displayName: "Short Description",
                  type: "string",
                  value: ".*AWS.*",
                  regex: true,
                  options: [],
                  valueFromTicket: ".*AWS.*"
                }
              ],
              mlFields: {
                predictions: [
                  {
                    name: "u_knowledge",
                    displayName: "Knowledge Base Id",
                    predictedValue: "AWS Details" // Condition, no Value column,
                  },
                  {
                    name: "assignmentGroupATR",
                    displayName: "Assignment Group ATR",
                    predictedValue: "Access Issue"
                  }
                ],
                confidenceLevel: 60
              },
              triggerStates: ["New", "In Progress"]
            },
            targetServiceName: "automation-queue",
            targetProjectName: "default",
            targetJobName: "5fd7eec71554e01a9413e5ad",
            resultState: "Closed",
            executionStrategy: "ONCE_PER_TICKET",
            automatable: "true",
            fieldMappingRules: [
              {
                parameterId: "ticketNumber",
                priority: 0,
                defaultValue: "replaceMe",
                extractor: {
                  fieldName: "",
                  regex: ""
                }
              }
            ],
            resultMappingRules: []
          },
          workflowDescription: {
            providerId: "automation-queue",
            namespace: "default",
            id: "5fd7eec71554e01a9413e5ad",
            name: "AWS Instance Access",
            description: null,
            options: {
              ticketNumber: "ATR000001"
            },
            detailedOptions: {
              ticketNumber: {
                name: "Ticket Number",
                mandatory: true,
                defaultValue: "",
                value: "ATR000001",
                description: "Ticket Number"
              }
            }
          }
        }
      },
      {
        time: 100,
        success: true,
        info: null,
        output: {
          configMatched: {
            id: "619f26cd9efb791e1901bffe",
            mappingName: "Jira Authorization",
            ticketType: "STANDALONE",
            triggerConditions: {
              fields: [],
              mlFields: {
                predictions: [],
                confidenceLevel: 0
              },
              triggerStates: ["In Progress", "On Hold"]
            },
            targetServiceName: "automation-queue",
            targetProjectName: "default",
            targetJobName: "5fd7eec71554e01a9413e5a9",
            resultState: "",
            executionStrategy: "ONCE_PER_TICKET",
            automatable: "true",
            fieldMappingRules: [
              {
                parameterId: "ticketNumber",
                priority: 0,
                defaultValue: null,
                extractor: {
                  fieldName: "",
                  regex: ""
                }
              }
            ],
            resultMappingRules: []
          },
          workflowDescription: {
            providerId: "automation-queue",
            namespace: "default",
            id: "5fd7eec71554e01a9413e5a9",
            name: "Jira Access",
            description: null,
            options: {
              ticketNumber: "ATR000001"
            },
            detailedOptions: {
              ticketNumber: {
                name: "Ticket Number",
                mandatory: true,
                defaultValue: null,
                value: "ATR000001",
                description: null
              }
            }
          }
        }
      }
    ]
  },
  rules: {
    time: 324,
    success: true,
    info: null,
    results: [
      {
        time: 100,
        success: true,
        info: null,
        output: {
          configMatched: {
            id: "619f28289efb791e1901bfff",
            name: "AWS Instance Details Self Healing",
            description: "",
            approver: "admin",
            author: "admin",
            mappingId: "619f1cdd9efb791e1901bffb",
            mapping: {
              id: "619f1cdd9efb791e1901bffb",
              mappingName: "AWS Instance Details",
              ticketType: "STANDALONE",
              triggerConditions: {
                fields: [
                  {
                    name: "category",
                    displayName: "Category",
                    type: "dropdown",
                    value: "Human Error",
                    regex: false,
                    options: [
                      "Application Issue",
                      "Training Issue",
                      "Human Error",
                      "Configuration Issue",
                      "Process Issue"
                    ]
                  },
                  {
                    name: "shortDescription",
                    displayName: null,
                    type: "string",
                    value: ".*SAP.*",
                    regex: true,
                    options: []
                  }
                ],
                // additional trigger conditions
                mlFields: {
                  predictions: [
                    {
                      name: "u_knowledge",
                      displayName: "Knowledge Base Id",
                      predictedValue: "AWS Details" // Condition, no Value column,
                    },
                    {
                      name: "assignmentGroupATR",
                      displayName: "Assignment Group ATR",
                      predictedValue: "AWS Services"
                    }
                  ],
                  confidenceLevel: 60
                },
                triggerStates: ["New", "In Progress"]
              },
              targetServiceName: "automation-queue",
              targetProjectName: "default",
              targetJobName: "5fd7eec71554e01a9413e5ad",
              resultState: "Closed",
              executionStrategy: "ONCE_PER_TICKET",
              automatable: "true",
              fieldMappingRules: [
                {
                  parameterId: "ticketNumber",
                  priority: 0,
                  defaultValue: "replaceMe",
                  extractor: {
                    fieldName: "",
                    regex: ""
                  }
                }
              ],
              resultMappingRules: []
            },
            priority: null,
            triggerConditions: {
              fields: [
                {
                  name: "category",
                  displayName: "Category",
                  type: "dropdown",
                  value: "Human Error",
                  regex: false,
                  options: [
                    "Application Issue",
                    "Training Issue",
                    "Human Error",
                    "Configuration Issue",
                    "Process Issue"
                  ]
                },
                {
                  name: "shortDescription",
                  displayName: null,
                  type: "string",
                  value: ".*SAP.*",
                  regex: true,
                  options: []
                }
              ],
              mlFields: {
                predictions: [
                  {
                    name: "u_knowledge",
                    displayName: "Knowledge Base Id",
                    predictedValue: "AWS Details" // Condition, no Value column,
                  },
                  {
                    name: "assignmentGroupATR",
                    displayName: "Assignment Group ATR",
                    predictedValue: "AWS Services"
                  }
                ],
                confidenceLevel: 60
              },
              triggerStates: ["New", "In Progress"]
            },
            enabled: true
          },
          // Job/Workflow Name
          workflowDescription: {
            providerId: "automation-queue",
            namespace: "default",
            id: "5fd7eec71554e01a9413e5ad",
            name: "AWS Instance Access", // this goes below Job/Workflow Name
            description: null,
            options: {
              ticketNumber: "ATR000001"
            },
            // Job Parameters
            detailedOptions: {
              ticketNumber: {
                name: "Ticket Number", // Input Parameter Value
                mandatory: true,
                defaultValue: "",
                value: "ATR000001", // Value
                description: "Ticket Number"
              }
            }
          }
        }
      },
      {
        time: 100,
        success: true,
        info: null,
        output: {
          configMatched: {
            id: "619f28519efb791e1901c000",
            name: "Jira Access Issue Self Healing",
            description: "",
            approver: "admin",
            author: "admin",
            mappingId: "619f26cd9efb791e1901bffe",
            mapping: {
              id: "619f26cd9efb791e1901bffe",
              mappingName: "Jira Authorization",
              ticketType: "STANDALONE",
              triggerConditions: {
                fields: [],
                mlFields: {
                  predictions: [],
                  confidenceLevel: 0
                },
                triggerStates: {
                  triggerState: ["In Progress", "On Hold"],
                  ticketState: "In Progress"
                }
              },
              targetServiceName: "automation-queue",
              targetProjectName: "default",
              targetJobName: "5fd7eec71554e01a9413e5a9",
              resultState: "",
              executionStrategy: "ONCE_PER_TICKET",
              automatable: "true",
              fieldMappingRules: [
                {
                  parameterId: "ticketNumber",
                  priority: 0,
                  defaultValue: null,
                  extractor: {
                    fieldName: "",
                    regex: ""
                  }
                }
              ],
              resultMappingRules: []
            },
            priority: null,
            triggerConditions: {
              fields: [],
              mlFields: {
                predictions: [
                  {
                    name: "u_knowledge",
                    displayName: "Knowledge Base Id",
                    predictedValue: "JIRA Access" // Condition, no Value column,
                  }
                ],
                confidenceLevel: 80
              },
              triggerStates: ["In Progress", "On Hold"]
            },
            enabled: true
          },
          workflowDescription: {
            providerId: "automation-queue",
            namespace: "default",
            id: "5fd7eec71554e01a9413e5a9",
            name: "Jira Access",
            description: null,
            options: {
              ticketNumber: "ATR000001"
            },
            detailedOptions: {
              ticketNumber: {
                name: "Ticket Number",
                mandatory: true,
                defaultValue: null,
                value: "ATR000001",
                description: null
              }
            }
          }
        }
      }
    ]
  }
};

const failure = {
  totalTime: 30543,
  machineLearning: {
    time: 324,
    success: false,
    info: "Machine Learning is Down .....",
    results: []
  },
  mapping: {
    time: 9324,
    success: true,
    info: null,
    results: [
      {
        time: 100,
        success: false,
        info: "Regex match timeout",
        output: {
          configMatched: {},
          workflowDescription: {}
        }
      },
      {
        time: 100,
        success: true,
        info: null,
        output: {
          configMatched: {
            id: "619f26cd9efb791e1901bffe",
            mappingName: "Jira Authorization",
            ticketType: "STANDALONE",
            triggerConditions: {
              fields: [],
              mlFields: {
                predictions: [],
                confidenceLevel: 0
              },
              triggerStates: ["In Progress", "On Hold"]
            },
            targetServiceName: "automation-queue",
            targetProjectName: "default",
            targetJobName: "5fd7eec71554e01a9413e5a9",
            resultState: "",
            executionStrategy: "ONCE_PER_TICKET",
            automatable: "true",
            fieldMappingRules: [
              {
                parameterId: "ticketNumber",
                priority: 0,
                defaultValue: null,
                extractor: {
                  fieldName: "",
                  regex: ""
                }
              }
            ],
            resultMappingRules: []
          },
          workflowDescription: {
            providerId: "automation-queue",
            namespace: "default",
            id: "5fd7eec71554e01a9413e5a9",
            name: "Jira Access",
            description: null,
            options: {
              ticketNumber: "ATR000001"
            },
            detailedOptions: {
              ticketNumber: {
                name: "Ticket Number",
                mandatory: true,
                defaultValue: null,
                value: "ATR000001",
                description: null
              }
            }
          }
        }
      }
    ]
  },
  rules: {
    time: 324,
    success: false,
    info: "Ticket created before ATR install date",
    results: [{}]
  }
};

export { success, failure };
