import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import TextField from "material-ui/TextField";

class SecondaryTextField extends React.Component {
  render() {
    // import modifier props
    const { requiredError, ...rest } = this.props;
    const renderError = typeof requiredError === "boolean" && requiredError;

    return (
      <TextField
        errorText={renderError ? "This is required" : ""}
        underlineStyle={
          renderError
            ? { borderColor: themeMainUI.palette.errorColor, borderWidth: 2 }
            : {}
        }
        floatingLabelStyle={{ color: themeMainUI.palette.accent3Color }}
        underlineFocusStyle={{ border: "none" }}
        {...rest}
      />
    );
  }
}

export default SecondaryTextField;
