import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

export const CategoryWrapper = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SelectCheckbox = styled(Checkbox)`
  color: #378bee !important;
  @media screen and (max-width: 992px) {
    height: 24px !important;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    height: 36px !important;
  }

  @media screen and (max-height: 768px) {
    height: 24px !important;
  }
`;

export const CategoryText = styled.span`
  font-size: 1rem;
  @media screen and (max-width: 992px) {
    font-size: 0.9rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 0.9rem;
  }
`;

export const SelectedText = styled.span`
  font-size: 12px;
  font-style: italic;
`;
