import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import { deletedTicketTemplate } from "redux/modules/ticketTemplate";

const TicketTemplateTableAction = props => {
  const { name, id, active } = props;
  const { activateTemplate, deleteTemplate, deactivateTemplate } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  // function created to handle ticket template deletion action
  const handleTemplateDeletion = name => {
    // before ticket template gets deleted, it first gets dispatched to redux store for future reference
    dispatch(deletedTicketTemplate(name));
    deleteTemplate(name);
  };

  return (
    <>
      <IconButton
        aria-label="More"
        aria-owns={Boolean(anchorEl) ? "long-menu" : undefined}
        aria-haspopup="true"
        onClick={event => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={event => {
          event.stopPropagation();
          setAnchorEl(null);
        }}
      >
        {active === false ? (
          <MenuItem
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(null);
              activateTemplate(id);
            }}
          >
            Activate
          </MenuItem>
        ) : (
          <MenuItem
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(null);
              deactivateTemplate(id);
            }}
          >
            Deactivate
          </MenuItem>
        )}
        <MenuItem
          onClick={event => {
            event.stopPropagation();
            setAnchorEl(null);
            handleTemplateDeletion(name);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default TicketTemplateTableAction;
