import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";

export const BoldText = styled.div`
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 10px;
`;

export const CompleteIcon = styled(FontAwesomeIcon)`
  color: #00cc83;
  font-size: 150px;
`;

export const ConfirmButton = styled(Button)`
  background-color: #0085ff !important;
  text-transform: none !important;
  width: 250px !important;
  margin-bottom: 10px !important;
`;

export const TextWrapper = styled.div`
  padding: 20px;
  text-align: center;
`;

export const MainContainer = styled.div`
  width: 100%;
  margin: 20vh 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SecondaryButton = styled(Button)`
  border: 0.5px solid #0085ff !important;
  color: #0085ff !important;
  text-transform: none !important;
  background-color: #fff !important;
  width: 250px !important;
`;
