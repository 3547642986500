import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  BodyContainer,
  ButtonContainer,
  PageText,
  DeepDiveButton
} from "./styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Chip,
  Tooltip
} from "@material-ui/core";
import { ArrowBack, ArrowForward, OpenInNew } from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";
import config from "../../../../../../config";
import useWindowDimensions from "../../../../../../hook/useWindowDimensions";

const UserManagementPanel = props => {
  const { userList, isFetchingUserList } = props;
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const { height: windowHeight } = useWindowDimensions();

  useEffect(() => {
    if (windowHeight <= 700) {
      setPerPage(5);
    } else if (windowHeight <= 850) {
      setPerPage(6);
    } else if (windowHeight <= 1000) {
      setPerPage(8);
    } else {
      setPerPage(10);
    }
  }, [windowHeight]);

  const maxPages = Math.round(userList.length / perPage);

  const handleNextPage = () => {
    if (page < maxPages - 1) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    const start = page * perPage;
    const end =
      start + perPage < userList.length ? start + perPage : userList.length;
    const partialUsers = userList.slice(start, end);
    setDisplayedUsers(partialUsers);
  }, [page, userList, perPage]);

  return (
    <>
      <BodyContainer item xs={12}>
        <Paper>
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: "#E3F0FC" }}>
                <TableCell>Username</TableCell>
                <TableCell style={{ textAlign: "center" }}>Groups</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Permissions
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <DeepDiveButton
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        config.urls.apps["identity-management"],
                        "_blank"
                      )
                    }
                  >
                    <span style={{ marginRight: 10 }}>Modify Access</span>
                    <OpenInNew />
                  </DeepDiveButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isFetchingUserList &&
                displayedUsers.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>{user.username.split("@")[0]}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Tooltip title={user.groups.join(", ")}>
                        <Chip
                          label={user.groups.length}
                          style={{
                            backgroundColor: "#5D7BE5",
                            color: "#FFF"
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Chip
                        label={user.permissions.length}
                        style={{
                          backgroundColor: "#5D7BE5",
                          color: "#FFF"
                        }}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ))}
              {isFetchingUserList &&
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(row => (
                  <TableRow key={`skeleton-row-${row}`}>
                    {[0, 1, 2, 3, 4].map(col => (
                      <TableCell key={`skeleton-col-${col}`}>
                        <Skeleton />
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </BodyContainer>
      <ButtonContainer>
        <Button onClick={handlePrevPage} disabled={page === 0}>
          <ArrowBack />
        </Button>
        <PageText>{`Page ${page + 1} of ${maxPages}`}</PageText>
        <Button onClick={handleNextPage} disabled={page >= maxPages - 1}>
          <ArrowForward />
        </Button>
      </ButtonContainer>
    </>
  );
};

UserManagementPanel.propTypes = {
  userList: PropTypes.array.isRequired,
  isFetchingUserList: PropTypes.bool.isRequired
};

export default UserManagementPanel;
