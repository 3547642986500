import { connect } from "react-redux";
import PluginTableRow from "./Row";
import { updateActivation, openPlugin } from "redux/modules/plugin";
const mapStateToProps = (state, ownProps) => ({
  plugin: state.plugin.plugins.byId[ownProps.id],
  currentPluginId: state.plugin.currentPluginId,
  report: state.plugin.healthReports.reports.find(
    hr => hr.pluginId === ownProps.id
  )
});

const mapDispatchToProps = dispatch => ({
  openPlugin: id => dispatch(openPlugin(id)),
  updateActivation: id => dispatch(updateActivation(id))
});

const PluginTableRowCTN = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginTableRow);

export default PluginTableRowCTN;
