import React from "react";

import { IconButton, MenuItem, Grid } from "@material-ui/core";
import FontIcon from "material-ui/FontIcon";
import { Drawer, IconMenu } from "material-ui";
import SecondaryNavigationClose from "../templates/SecondaryNavigationClose";
import styles from "../drawers/TicketDetailsDrawerStyles";
import history from "../../history";
import themeMainUI from "../../styles/themeMainUI";
import { userConfig } from "userConfigValidations";

export const DrawerBase = props => (
  <div>
    {/*  <MediaQuery minWidth={769}> */}
    <Drawer
      open={props.isDetailsOpen}
      onRequestChange={props.handleCloseDetails}
      openSecondary={true}
      docked={true}
      width={props.customWidth ? true : 640}
      style={{
        overflow: "hidden"
      }}
      containerStyle={{
        overflow: "hidden"
      }}
    >
      <Grid
        container
        justify="flex-end"
        style={{ marginBottom: "-50px", position: "absolute" }}
      >
        <Grid item>
          {userConfig.variables.dryRun && (
            <IconMenu
              iconButtonElement={
                <IconButton
                  style={{
                    color: themeMainUI.palette.primary3Color,
                    fontSize: 20
                  }}
                >
                  <FontIcon className="fa fa-ellipsis-v" />
                </IconButton>
              }
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  history.push({
                    pathname: "/atr/settings/dry-run",
                    state: {
                      // TODO: use only url for this
                      activeTemplate: props.activeTemplate,
                      ticket: props.ticket
                    }
                  });
                }}
              >
                Test execution in Playground
              </MenuItem>
              <MenuItem onClick={() => {}}>Save as preset</MenuItem>
            </IconMenu>
          )}
        </Grid>
        <Grid item>
          <SecondaryNavigationClose onClick={props.handleCloseDetails} />
        </Grid>
      </Grid>
      <div style={styles.mainWrapper}>
        <div style={styles.contentWrapper}>{props.children}</div>
      </div>
    </Drawer>
    {/*  </MediaQuery> */}
  </div>
);
