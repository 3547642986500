import React, { useState } from "react";
import muiTheme from "../../../styles/themeMainUI";
import { Link } from "react-router-dom";

const WorkflowSuggestionList = props => {
  const [workflowSuggestionLimit, setWorkflowSuggestionLimit] = useState(3);
  const [workflowSuggestionShowAll, setWorkflowSuggestionShowAll] = useState(
    false
  );
  const _handleWorkflowSuggestionClick = (mapping, ticketID) => {
    props.updateMappingFound(mapping);
    props.ticketGetSingleJobDefinition(
      mapping.targetServiceName,
      mapping.targetJobName,
      ticketID
    );
  };
  const _workflowSuggestionSelected = (mappingSuggestion, mappingFound) => {
    return mappingFound?.mappingName === mappingSuggestion?.mappingName;
  };
  const _renderWorkflowSuggestionChips = () => {
    return (
      props.matchedMappings
        // .sort((a,b) => {return a.isSelected > b.isSelected ? -1 : 1})
        // .slice(0, workflowSuggestionShowAll ? props.matchedMappings.length : workflowSuggestionLimit)
        .map(workflowSuggestion => {
          workflowSuggestion.isSelected = _workflowSuggestionSelected(
            workflowSuggestion,
            props.mappingFound
          );
          return workflowSuggestion;
        })
        .map((mappingSuggestion, index) => {
          const isSelected = _workflowSuggestionSelected(
            mappingSuggestion,
            props.mappingFound
          );
          return (
            <div style={{ marginLeft: 2, marginRight: 2 }}>
              <div
                key={index}
                style={{
                  cursor: "pointer",
                  border: "2px solid",
                  fontSize: "14px",
                  borderColor: muiTheme.palette.primaryBtnBackgroundColor,
                  backgroundColor: isSelected
                    ? muiTheme.palette.primaryBtnBackgroundColor
                    : muiTheme.palette.canvasColor,
                  borderRadius: "5px",
                  color: isSelected
                    ? muiTheme.palette.canvasColor
                    : muiTheme.palette.primaryBtnBackgroundColor,
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: 4,
                  paddingBottom: 4,
                  marginRight: "2px"
                }}
                onClick={() =>
                  _handleWorkflowSuggestionClick(
                    mappingSuggestion,
                    props.ticketID
                  )
                }
              >
                {mappingSuggestion.mappingName}
              </div>
            </div>
          );
        })
    );
  };
  const mappingSelected = props.matchedMappings.find(item => {
    return props.mappingFound.mappingName === item.mappingName;
  });
  // const extraSuggestions = props.matchedMappings.slice(workflowSuggestionLimit + 1, props.matchedMappings.length).length;
  return (
    <div>
      <div
        style={{
          fontSize: 12,
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          overflowX: "auto"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexShrink: 0
          }}
        >
          {props.matchedMappings.length > 0 && (
            <div style={{ marginRight: 5 }}>MAPPED FROM</div>
          )}
          {_renderWorkflowSuggestionChips()}
        </div>
      </div>
      <div style={{ fontSize: 12, marginTop: 6 }}>
        {mappingSelected && (
          <Link
            style={{ color: muiTheme.palette.accent3Color }}
            to={`/atr/settings/automation/mapping/${mappingSelected.mappingName}`}
          >
            Open Mapping in Settings
          </Link>
        )}
      </div>
    </div>
  );
};

export default WorkflowSuggestionList;
