import { connect } from "react-redux";
import Mapping from "../../components/mapping/Mapping";
import { openRule, closeRule, openRules } from "../../actions/rules";
import {
  openMapping,
  openMappingByName,
  closeMapping,
  openMappings,
  mappingChangeTicketManagement,
  updateMapping,
  deleteMapping,
  createMapping,
  changePage,
  getRundeckProjects,
  getRundeckJobs,
  updateLocalMapping,
  getActiveTemplates,
  openAllMappings,
  clearMappingJobs,
  clearRundeckProjects
} from "../../actions/mapping";
import { closeSnackbar, openSnackbar } from "../../actions/snackbar";
import { openBundles, closeBundles } from "../../actions/bundles";
import { getTicketConfig } from "../../actions/ticketConfig";
import { isEmpty } from "lodash";

const mapStateToProps = state => ({
  jobSelected:
    !isEmpty(state.mapping.mappingJobs) &&
    !isEmpty(state.mapping.mappingSelected)
      ? state.mapping.mappingJobs.find(
          job => job.id === state.mapping.mappingSelected.targetJobName
        )
      : {},
  deletedTicketTemplate: state.ticketTemplates.deletedTicketTemplate,
  currentPage: state.mapping.currentPage, // Displayed on table: currentPage + 1, conversion done at component level
  lastPage: state.mapping.lastPage, // Displayed on table: lastPage + 1, conversion done at component level
  isSnackbarOpen: state.snackbar.isOpen,
  isBundlesOpen: state.bundles.isOpen,
  ruleSelected: state.rules.ruleSelected,
  rules: state.rules.rules,
  mappingSelected: state.mapping.mappingSelected,
  mappings: state.mapping.mappings,
  mappingJobs: state.mapping.mappingJobs,
  isFetchingMappingJobs: state.mapping.isFetchingMappingJobs,
  mappingJobsTicketManagement: state.mapping.mappingJobsTicketManagement,
  isFetchingMappings: state.mapping.isFetchingMappings,
  mappingRundecks: state.mapping.mappingRundecks,
  isFetchingMappingRundecks: state.mapping.isFetchingMappingRundecks,
  isFetchingMappingJobsTicketManagement:
    state.mapping.isFetchingMappingJobsTicketManagement,
  rundeckProjects: state.mapping.rundeckProjects,
  isFetchingRundeckProjects: state.mapping.isFetchingRundeckProjects,
  activeTemplates: state.mapping.activeTemplates,
  permissions: state.user.permissions,
  isFetchingAvailablePredictions: state.mapping.isFetchingAvailablePredictions,
  availablePredictions: state.mapping.availablePredictions
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  openBundles: () => dispatch(openBundles()),
  closeBundles: () => dispatch(closeBundles()),
  openSnackbar: message => dispatch(openSnackbar(message)),
  closeSnackbar: () => dispatch(closeSnackbar()),
  closeRule: () => dispatch(closeRule()),
  openRule: ruleSelected => dispatch(openRule(ruleSelected)),
  openRules: () => dispatch(openRules()),
  closeMapping: () => dispatch(closeMapping()),
  openMapping: mappingSelected => dispatch(openMapping(mappingSelected)),
  openMappingByName: mappingName => dispatch(openMappingByName(mappingName)),
  openMappings: (page, perPage) => dispatch(openMappings(page, perPage)),
  openAllMappings: () => dispatch(openAllMappings()),
  updateMapping: (mappingEdited, page) =>
    dispatch(updateMapping(mappingEdited, page)),
  deleteMapping: (mappingEdited, page) =>
    dispatch(deleteMapping(mappingEdited, page)),
  createMapping: (mappingEdited, page) =>
    dispatch(createMapping(mappingEdited, page)),
  mappingChangeTicketManagement: ticketManagement =>
    dispatch(mappingChangeTicketManagement(ticketManagement)), // TODO: Same as getRumdeckProjects
  changePage: page => dispatch(changePage(page)),
  getAllRundecks: () => dispatch(getTicketConfig({ url: "rundeck" })),
  getRundeckProjects: rundeck => dispatch(getRundeckProjects(rundeck)),
  getRundeckJobs: (rundeck, project) =>
    dispatch(getRundeckJobs(rundeck, project)),
  updateLocalMapping: mapping => dispatch(updateLocalMapping(mapping)),
  getActiveTemplates: ticketType => dispatch(getActiveTemplates(ticketType)),
  clearMappingJobs: () => dispatch(clearMappingJobs()),
  clearRundeckProjects: () => dispatch(clearRundeckProjects()),
  getMlConfig: () => dispatch(getTicketConfig({ url: "machineLearningModels" }))
});

const MappingPage = connect(mapStateToProps, mapDispatchToProps)(Mapping);

export default MappingPage;
