import { connect } from "react-redux";
import TicketTemplateViewerPreview from "./Preview";

const mapStateToProps = state => {
  return {
    primaryFields: state.ticketTemplates.currentTemplate.primaryFields,
    secondaryFields: state.ticketTemplates.currentTemplate.secondaryFields
  };
};

export default connect(mapStateToProps, undefined)(TicketTemplateViewerPreview);
