import { connect } from "react-redux";
import ResponsiveView from "../index";
import { saveProviderFilter } from "actions/tutorial";
import { updateSingleRules } from "../../../../../actions/rules";

const mapStateToProps = state => ({
  providerFilters: state.tutorial.providerFilters,
  isSaving: state.tutorial.isSaving,
  configs: state.plugin.configs,
  reviewConfirmed: state.tutorial.reviewConfirmed,
  snowConfig: state.tutorial.snowConfig,
  mappings: state.mapping.mappings,
  rules: state.rules.rules,
  selectedWorkflows: state.tutorial.selectedWorkflows,
  demoWorkflows: state.tutorial.demoWorkflows
});

const mapDispatchToProps = dispatch => ({
  saveProviderFilter: (configs, providerPayload) =>
    dispatch(saveProviderFilter(configs, providerPayload)),
  updateRule: rule => dispatch(updateSingleRules(rule, 0, "toggle", 500))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveView);
