import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import TicketTemplateViewerMappingItemCTN from "./Item/ItemCTN";

const styles = {
  wrapper: {
    padding: "0"
  },
  paper: {
    height: "calc(100vh - 360px)",
    overflow: "hidden"
  },
  label: {
    padding: "0",
    marginTop: "18px",
    fontSize: "12px"
  },
  mappingItem: {
    padding: "0 20px 20px 20px",
    overflow: "auto",
    position: "relative",
    height: "calc(100% - 100px)"
  },
  tabRoot: {
    maxWidth: "10000px",
    width: "50%"
  },
  info: {
    color: "rgb(189,189,189)",
    fontSize: "13px",
    margin: "10px"
  },
  mappingTitle: {
    padding: "10px",
    display: "flex",
    alignItems: "center"
  }
};

const TicketTemplateViewerMapping = props => {
  const { MLMappingFields, name, classes } = props;
  const [tab, setTab] = useState(0);
  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid container alignItems="center">
          <Grid item xs={2} className={classes.label}>
            Template Name*
          </Grid>
          <Grid item xs={10}>
            <TextField fullWidth margin="normal" disabled value={name} />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ height: "100%" }}>
          <Paper className={classes.paper}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              value={tab}
              onChange={(event, value) => setTab(value)}
            >
              <Tab classes={{ root: classes.tabRoot }} label="MANDATORY *" />
              <Tab classes={{ root: classes.tabRoot }} label="OPTIONAL" />
            </Tabs>
            <Grid item style={{ height: "100%", marginTop: "10px" }}>
              {/* <Grid item xs={12}>
                <div className={classes.mappingTitle}>
                  <Typography variant="display3">
                    {tab === 0 ? "Mandatory" : "Optional"} Inputs for Machine
                    Learning
                  </Typography>
                </div>
              </Grid> */}
              <div className={classes.mappingItem}>
                {MLMappingFields.filter(field =>
                  tab === 0 ? field.mandatory : !field.mandatory
                ).map((field, index) => (
                  <TicketTemplateViewerMappingItemCTN
                    key={index}
                    field={field}
                  />
                ))}
              </div>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.info}>
          Select the fields that will be mapped to your database schema. After
          changing anything here, you will need to reset your database and
          initialise all your data again for this mapping to take effect. By
          changing mappings, it will also affect what you see in your ticket
          table and automation process.
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(TicketTemplateViewerMapping);
