import React, { PureComponent } from "react";
import { connect } from "react-redux";
import moment from "moment";

import ActivityTab from "./ActivityTab";
import { ticketSaveActivity } from "../../../actions/tickets";

class ActivityTabContainer extends PureComponent {
  constructor(props) {
    super(props);
    this._handleCommentSend = this._handleCommentSend.bind(this);
    this._handleCommentChange = this._handleCommentChange.bind(this);
    this._handleTypeChange = this._handleTypeChange.bind(this);
    this.state = {
      value: "",
      comment: "",
      type: "comments"
    };
  }

  _handleCommentChange(event) {
    this.setState({
      comment: event.target.value
    });
  }

  _handleTypeChange(event) {
    this.setState({
      type: event.target.value
    });
  }

  _handleCommentSend() {
    if (this.state.comment.length) {
      this.props.ticketSaveActivity(this.props.ticketId, {
        content: this.state.comment,
        date: moment(),
        ticketId: this.props.ticketId,
        type: this.state.type
      });
      this.setState({
        comment: ""
      });
    }
  }

  render() {
    return (
      <ActivityTab
        handleCommentChange={this._handleCommentChange}
        handleCommentSend={this._handleCommentSend}
        handleTypeChange={this._handleTypeChange}
        comment={this.state.comment}
        type={this.state.type}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  activity: state.details.ticketActivities,
  activityIsFetching: state.details.ticketActivityIsFetching,
  ticketActivitiesTypes: state.details.ticketActivitiesTypes,
  ticketActivitiesTypesIsFetching: state.details.ticketActivitiesTypesIsFetching
});

const mapDispatchToProps = dispatch => ({
  ticketSaveActivity: (ticketId, ticketActivity) =>
    dispatch(ticketSaveActivity(ticketId, ticketActivity))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTabContainer);
