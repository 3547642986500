import React, { useState, useEffect } from "react";
import PropertiesTabContainer from "./PropertiesTab/PropertiesTabContainer";
import styles from "./TicketDetailsDrawerStyles";
import ResolutionTabContainer from "../../components/drawers/ResolutionTab/ResolutionTabContainer";
import ActivityTabContainer from "../../components/drawers/ActivityTab/ActivityTabContainer";
import AutomationTabContainer from "./AutomationTab/AutomationTabContainer";
import AQETab from "./AQETab/AQETab";
import ProgressTimeline from "../templates/ProgressTimeline";
import { userConfig } from "../../userConfigValidations";
const TicketDetailDrawerTabItems = props => {
  const ticket = props.stateTicket;
  const dictOfUIBasedFieldNames = {
    "knowledge base id": "u_knowledge",
    "ticket management": "ticket_management",
    assignment_group: "assignmentgroupatr"
  };
  const [
    predictionFieldsGroupedById,
    setPredictionFieldsGroupedById
  ] = useState({});

  const [
    predictionFieldsWithGroupId,
    setPredictionFieldsWithGroupId
  ] = useState({});

  useEffect(() => {
    const predictionFieldsGroupedById = {};
    const predictionFieldsWithGroupId = {};

    ticket.mlData.forEach(singleMLData => {
      if (singleMLData.modelId) {
        if (predictionFieldsGroupedById.hasOwnProperty(singleMLData.modelId)) {
          predictionFieldsGroupedById[singleMLData.modelId].add(
            singleMLData.key
          );
        } else {
          predictionFieldsGroupedById[singleMLData.modelId] = new Set([
            singleMLData.key
          ]);
        }
      } else {
        predictionFieldsGroupedById[
          singleMLData.key.toLowerCase().trim()
        ] = new Set([singleMLData.key.toLowerCase().trim()]);
      }
      predictionFieldsWithGroupId[singleMLData.key.toLowerCase().trim()] =
        singleMLData.modelId || singleMLData.key.toLowerCase().trim();
    });
    setPredictionFieldsGroupedById(predictionFieldsGroupedById);
    setPredictionFieldsWithGroupId(predictionFieldsWithGroupId);
  }, [ticket.mlData]);

  //should probably be moved to a redux variable -> code repeated twice (here + TicketTable)
  const ticketStatus = () => {
    switch (ticket.coreData.state) {
      case "Cancelled":
        return 0;
      case "Open":
      case "New":
        return 1;
      case "In Progress":
      case "Pending":
      case "On Hold":
      case "Work in Progress":
        return 2;
      default:
        return 3;
    }
  };

  const convertNameIfNecessary = name => {
    if (dictOfUIBasedFieldNames.hasOwnProperty(name))
      return dictOfUIBasedFieldNames[name];
    return name;
  };

  const groupTabPredictions = (
    tabContent,
    predictionFieldsWithGroupId,
    groupedTabSetByModelId
  ) => {
    tabContent.fields.mlPredictionFields.forEach(i => {
      let neatName = i.toLowerCase().trim();
      neatName = convertNameIfNecessary(neatName);

      if (predictionFieldsWithGroupId.hasOwnProperty(neatName)) {
        const modelId = predictionFieldsWithGroupId[neatName];
        if (groupedTabSetByModelId.hasOwnProperty(modelId)) {
          groupedTabSetByModelId[modelId].add(neatName);
        } else {
          groupedTabSetByModelId[modelId] = new Set([neatName]);
        }
      }
    });
  };

  const renderAutomationTab = () => {
    const { dynamicTabs } = props;
    const copyOfpredictionFieldsWithGroupId = {
      ...predictionFieldsWithGroupId
    };
    const copyOfpredictionFieldsGroupedById = {
      ...predictionFieldsGroupedById
    };
    const validHomemadeFields = new Set();
    for (let tabindx in dynamicTabs) {
      const groupedTabSetByModelId = {};
      groupTabPredictions(
        dynamicTabs[tabindx],
        copyOfpredictionFieldsWithGroupId,
        groupedTabSetByModelId
      );
      for (let [modelId, grouping] of Object.entries(groupedTabSetByModelId)) {
        if (
          copyOfpredictionFieldsGroupedById.hasOwnProperty(modelId) &&
          copyOfpredictionFieldsGroupedById[modelId].size === grouping.size
        ) {
          copyOfpredictionFieldsGroupedById[modelId].forEach(field =>
            validHomemadeFields.add(field)
          );
          delete copyOfpredictionFieldsGroupedById[modelId];
        }
      }
    }
    const setOfValidValues = new Set();
    Object.values(copyOfpredictionFieldsGroupedById).forEach(fields =>
      fields.forEach(field => setOfValidValues.add(field.toLowerCase().trim()))
    );

    const filteredTicket = { ...ticket };
    filteredTicket.mlData = ticket.mlData.filter(singleMLData => {
      return setOfValidValues.has(singleMLData.key.toLowerCase().trim());
    });
    const filteredFields = { ...props.fields };
    filteredFields.automation = filteredFields.automation.filter(autoItem =>
      setOfValidValues.has(autoItem.name.toLowerCase().trim())
    );
    const mlKeyIndx = {};
    if (filteredTicket.mlData) {
      let count = 0;
      for (let val of filteredTicket.mlData) {
        mlKeyIndx[val.key] = count;
        count++;
      }
    }
    return (
      props.slideIndex === 0 && (
        <div style={styles.slide}>
          <AutomationTabContainer
            drawerFunctions={props.drawerFunctions}
            manualResolutionConfig={props.manualResolutionConfig}
            ticket={filteredTicket}
            fields={filteredFields}
            mlObj={mlKeyIndx}
            handleTicketUpdate={props.handleTicketUpdate}
            handleReplaceTicket={props.handleReplaceTicket}
            _handleOverrideML={props._handleOverrideML}
            _handleTabChange={props._handleTabChange}
            homemadeFields={Array.from(validHomemadeFields)}
            /* Mapping props */
            // updateMappingFound={props.updateMappingFound}
            atsInvalid={props.atsInvalid}
          />
        </div>
      )
    );
  };

  const renderPropertiesTab = () => {
    const { dynamicTabs } = props;
    const slideIndexValue = dynamicTabs ? dynamicTabs.length + 1 : 1;
    return (
      props.slideIndex === slideIndexValue && (
        <div style={styles.slide}>
          {
            <PropertiesTabContainer
              drawerFunctions={props.drawerFunctions}
              ticket={ticket}
              fields={props.fields}
              mlObj={props.mlObj}
              mlTemplateMapping={props.mlTemplateMapping}
              handleTicketUpdate={props.handleTicketUpdate}
            />
          }
        </div>
      )
    );
  };

  const renderResolutionTab = () => {
    const { dynamicTabs } = props;
    const slideIndexValue = dynamicTabs ? dynamicTabs.length + 2 : 2;
    return (
      props.slideIndex === slideIndexValue && (
        <div style={styles.slide}>
          <ResolutionTabContainer
            ticket={ticket}
            _handleTabChange={props._handleTabChange}
          />
          {userConfig.showProgressColumn && (
            <ProgressTimeline ticketStatus={ticketStatus()} />
          )}
        </div>
      )
    );
  };

  //AQE Monitor Tab
  const renderAQETab = () => {
    //TODO: Add permission check to AQE
    const { dynamicTabs } = props;
    const slideIndexValue = dynamicTabs ? dynamicTabs.length + 4 : 4;
    return (
      props.slideIndex === slideIndexValue &&
      ticket.coreData && (
        <div style={styles.slide}>
          <AQETab ticketId={ticket.coreData.id} />
        </div>
      )
    );
  };

  const renderActivityTab = () => {
    const { dynamicTabs } = props;
    const slideIndexValue = dynamicTabs ? dynamicTabs.length + 3 : 3;
    return (
      props.slideIndex === slideIndexValue &&
      ticket.coreData && (
        <div style={styles.slide}>
          <ActivityTabContainer ticketId={ticket.coreData.id} />
        </div>
      )
    );
  };

  const renderDynamicTabs = () => {
    const { dynamicTabs, slideIndex } = props;
    if (dynamicTabs && slideIndex > 0 && slideIndex < dynamicTabs.length + 1) {
      const tabContent = dynamicTabs[slideIndex - 1];
      const groupedTabSetByModelId = {};

      const copyOfpredictionFieldsGroupedWithId = {
        ...predictionFieldsWithGroupId
      };
      const copyOfpredictionFieldsGroupedById = {
        ...predictionFieldsGroupedById
      };
      const setOfValidValues = new Set();

      groupTabPredictions(
        tabContent,
        copyOfpredictionFieldsGroupedWithId,
        groupedTabSetByModelId
      );
      for (let [modelId, grouping] of Object.entries(groupedTabSetByModelId)) {
        if (
          copyOfpredictionFieldsGroupedById.hasOwnProperty(modelId) &&
          copyOfpredictionFieldsGroupedById[modelId].size === grouping.size
        ) {
          grouping.forEach(item => setOfValidValues.add(item));
        }
      }
      const filteredTicket = { ...ticket };
      filteredTicket.mlData = ticket.mlData.filter(singleMLData => {
        return setOfValidValues.has(singleMLData.key.toLowerCase().trim());
      });
      const filteredFields = { ...props.fields };
      filteredFields.automation = filteredFields.automation.filter(autoItem =>
        setOfValidValues.has(autoItem.name.toLowerCase().trim())
      );

      const mlKeyIndx = {};
      if (filteredTicket.mlData) {
        let count = 0;
        for (let val of filteredTicket.mlData) {
          mlKeyIndx[val.key] = count;
          count++;
        }
      }

      return (
        <div style={styles.slide}>
          <AutomationTabContainer
            drawerFunctions={props.drawerFunctions}
            ticket={filteredTicket}
            fields={filteredFields}
            mlObj={mlKeyIndx}
            handleTicketUpdate={props.handleTicketUpdate}
            handleReplaceTicket={props.handleReplaceTicket}
            _handleOverrideML={props._handleOverrideML}
            _handleTabChange={props._handleTabChange}
            /* Mapping props */
            // updateMappingFound={props.updateMappingFound}
            atsInvalid={props.atsInvalid}
            disableAutomation={true}
          />
        </div>
      );
    }
  };

  return (
    <div style={styles.contentWrapper}>
      <div
        style={{
          maxHeight: "inherit",
          overflow: "hidden",
          margin: "0 auto"
        }}
      >
        <div>
          {/* Automation Tab */}
          {renderAutomationTab()}

          {/* Properties Tab */}
          {renderPropertiesTab()}

          {/* Resolution Tab */}
          {renderResolutionTab()}

          {/* Activity Tab */}
          {renderActivityTab()}

          {/* AQE Tab */}
          {renderAQETab()}

          {/* Dynamic Tabs */}
          {renderDynamicTabs()}
        </div>
      </div>
    </div>
  );
};

export default TicketDetailDrawerTabItems;
