import React from "react";
import PropTypes from "prop-types";
import { FooterContainer } from "./styles";
import CancelConfirmButtons from "../CancelConfirmButtons";

const BaseFooter = props => {
  const { onNext, onBack, onCancel, nextBtnText, isNextDisabled } = props;
  return (
    <FooterContainer>
      <CancelConfirmButtons
        handleNext={onNext}
        handleBack={onBack}
        handleCancel={onCancel}
        nextBtnText={nextBtnText}
        isNextDisabled={isNextDisabled}
      />
    </FooterContainer>
  );
};

BaseFooter.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  nextBtnText: PropTypes.string,
  isNextDisabled: PropTypes.bool
};

export default BaseFooter;
