import { connect } from "react-redux";
import BulkActions from "components/page/BulkActions";
import { getBulkActionTasks, cancelBulkAction } from "actions/bulkActions";
import { openSnackbar, closeSnackbar } from "../../actions/snackbar";

const mapStateToProps = state => ({
  bulkActionTasks: state.bulkActions.bulkActionTasks,
  isFetchingBulkActionTasks: state.bulkActions.isFetchingBulkActionTasks,
  isSnackbarOpen: state.snackbar.isOpen,
  timezone: state.user.user.timezone
});

const mapDispatchToProps = dispatch => ({
  getBulkActionTasks: (page, perPage) =>
    dispatch(getBulkActionTasks(page, perPage)),
  cancelBulkAction: taskId => dispatch(cancelBulkAction(taskId)),
  openSnackbar: message => dispatch(openSnackbar(message)),
  closeSnackbar: () => dispatch(closeSnackbar())
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkActions);
