import { connect } from "react-redux";
import Chatbot from "../../components/drawers/Chatbot";

import { sendMessage } from "../../actions/chatbot";

const mapStateToProps = (state, ownProps) => ({
  messages: state.chatbot.messages,
  user: state.user.user.username,
  jwt: state.auth.jwt,
  uuid: state.auth.uuid,
  permissions: state.user.permissions
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendMessage: (message, jwt) => {
    dispatch(sendMessage(message, jwt));
  }
});

const ChatbotPage = connect(mapStateToProps, mapDispatchToProps)(Chatbot);

export default ChatbotPage;
