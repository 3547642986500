import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LaunchIcon from "@material-ui/icons/Launch";
import { MenuItem } from "material-ui";
import { Grid } from "@material-ui/core";

import {
  UPDATE_PROXY_CONFIGURATION,
  RESET_PROXY_CONFIGURATION
} from "actions/proxyConfiguration";

export default function ProxyConfigurationDialog(proxyConfiguration) {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [hasProxy] = useState(proxyConfiguration.value);

  const [proxyUrl, setProxyUrl] = useState("");
  const [proxyUser, setProxyUser] = useState("");
  const [proxyPassword, setProxyPassword] = useState("");
  const [proxyDomain, setProxyDomain] = useState("");
  const [proxyType, setProxyType] = useState("");

  const proxyTypes = [
    {
      value: "ntlm",
      label: "NTLM"
    },
    {
      value: "basic",
      label: "Basic"
    }
  ];

  // load all proxy type values when page first renders
  useEffect(() => {
    if (hasProxy) {
      setProxyType(hasProxy.proxyType);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setDisabled(false);
  };

  const viewProxyConfiguration = () => {
    setOpen(true);

    if (hasProxy !== "undefined" || hasProxy === undefined) {
      setDisabled(true);
      setProxyUrl(hasProxy.proxyUrl);
      setProxyUser(hasProxy.proxyUser);
      setProxyPassword(hasProxy.proxyPassword);
      if (hasProxy.proxyType === "ntlm") {
        setProxyDomain(hasProxy.proxyDomain);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: RESET_PROXY_CONFIGURATION
    });
  };

  const handleSave = () => {
    setOpen(false);
    if (hasProxy === "undefined" || hasProxy === undefined) {
      dispatch({
        type: UPDATE_PROXY_CONFIGURATION,
        data: {
          proxyUrl,
          proxyUser,
          proxyPassword,
          proxyDomain,
          proxyType
        }
      });
    }
  };

  return (
    <div>
      {hasProxy === undefined || hasProxy === "undefined" ? (
        <AddCircleIcon
          aria-hidden={true}
          style={{ color: "#396686", cursor: "pointer" }}
          onClick={handleClickOpen}
        />
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {proxyType && proxyType.toUpperCase()}
          <LaunchIcon
            aria-hidden={true}
            style={{ color: "#396686", marginLeft: "5px", cursor: "pointer" }}
            onClick={viewProxyConfiguration}
          />
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        PaperProps={{
          style: {
            border: 2
          }
        }}
      >
        <DialogTitle id="form-dialog-title">
          {<strong>Proxy Configuration</strong>}
        </DialogTitle>
        <Grid item xs={12}>
          <div className="auto">
            <DialogContent>
              <TextField
                autoFocus
                required
                disabled={isDisabled}
                select
                margin="dense"
                id="proxyType"
                label="Select"
                value={proxyType}
                onChange={e => setProxyType(e.target.value)}
                fullWidth
                helperText="Please select your proxyType"
              >
                {proxyTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                disabled={isDisabled}
                margin="dense"
                id="proxyUrl"
                label="Proxy URL"
                type="text"
                fullWidth
                value={proxyUrl}
                onChange={e => setProxyUrl(e.target.value)}
              />
              <TextField
                required={!(proxyType === "ntlm")}
                disabled={isDisabled}
                margin="dense"
                id="proxyUser"
                label="Proxy User"
                type="text"
                fullWidth
                value={proxyUser}
                onChange={e => setProxyUser(e.target.value)}
              />
              <TextField
                required={!(proxyType === "ntlm")}
                disabled={isDisabled}
                margin="dense"
                id="proxyPassword"
                label="Proxy Password"
                type={
                  hasProxy === "undefined" || hasProxy === undefined
                    ? "text"
                    : "password"
                }
                fullWidth
                value={proxyPassword}
                onChange={e => setProxyPassword(e.target.value)}
              />
              <TextField
                disabled={isDisabled}
                margin="dense"
                id="proxyDomain"
                label="Proxy Domain"
                type="text"
                fullWidth
                value={proxyDomain}
                onChange={e => setProxyDomain(e.target.value)}
              />
            </DialogContent>
          </div>
        </Grid>
        <DialogActions>
          {isDisabled ? (
            <Button onClick={handleClose} color="secondary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary">
                OK
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
