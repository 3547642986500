import { connect } from "react-redux";
import PluginDrawer from "./Drawer";
import {
  closePlugin,
  getConfig,
  updateConfig,
  openPluginData,
  submitConfig
} from "redux/modules/plugin";
import { createSelector } from "reselect";

const getTicketSources = state => state.plugin.ticketSources;
const getCurrentPluginId = state => state.plugin.currentPluginId;

const selectTicketSources = createSelector(
  [getTicketSources, getCurrentPluginId],
  (ticketSources, currentPluginId) =>
    ticketSources.filter(
      ticketSource => ticketSource.description.providerId === currentPluginId
    )
);

const mapStateToProps = (state, ownProps) => ({
  currentPluginId: state.plugin.currentPluginId,
  configList: state.plugin.configs.allIds,
  basicInfo: state.plugin.currentPlugin.basicInfo,
  pluginConfig: state.plugin.currentPlugin.configs,
  pluginTicketSources: selectTicketSources(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  closePlugin: () => dispatch(closePlugin()),
  getConfig: id => dispatch(getConfig(id)),
  updateConfig: ({ pluginId, index, value }) =>
    dispatch(updateConfig({ pluginId, index, value })),
  openPluginData: id => dispatch(openPluginData(id)),
  submitConfig: id => dispatch(dispatch(submitConfig(id)))
});

const PluginDrawerCTN = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginDrawer);

export default PluginDrawerCTN;
