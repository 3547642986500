import { connect } from "react-redux";
import UserProfile from "../../components/settings/UserProfile";
import { closeBundles, openBundles } from "../../actions/bundles";
import { closeSnackbar, openSnackbar } from "../../actions/snackbar";
import { updateUser } from "../../actions/users";

const mapStateToProps = state => ({
  jwt: state.auth.jwt,
  isBundlesOpen: state.bundles.isOpen,
  isSnackbarOpen: state.snackbar.isOpen,
  templateList: state.templates.templateList,
  template: state.templates.template,
  templatePreview: state.templates.templatePreview,
  permissions: state.user.permissions,
  token: state.auth.token
});

const mapDispatchToProps = dispatch => ({
  closeBundles: () => dispatch(closeBundles()),
  openBundles: () => dispatch(openBundles()),
  closeSnackbar: () => dispatch(closeSnackbar()),
  openSnackbar: message => dispatch(openSnackbar(message)),
  saveUser: user => {
    dispatch(updateUser(user, user.id));
  }
  // getUserData: () => dispatch(getUserData())
});

const UserProfilePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);

export default UserProfilePage;
