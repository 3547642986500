/* Returns the GLOBAL STATE, there are no modifiers here. */
/* To modify the global state, dispatch the action in containers. */

import user from "./user";
import tutorial from "./tutorial";
import ticketConfig from "./ticketConfig";
import email from "./emailTemplates";
import mapping from "./mapping";
import notification from "./notification";
import errors from "./errors";
import auth from "./auth";
import snackbar from "./snackbar";
import bundles from "./bundles";
import chatbot from "./chatbot";
import details from "./details";
import templates from "./templates";
import sidebar from "./sidebar";
import users from "./users";
import tickets from "./tickets";
import jobs from "./jobs";
import rules from "./rules";
import dataSource from "./dataSource";
import gamification from "./gamification";
import bulkActions from "./bulkActions";
import proxyConfiguration from "./proxyConfiguration";
import rundeckConfirmation from "./rundeckConfirmation";
import dryRun from "./dryRun";

export default {
  errors,
  snackbar,
  chatbot,
  jobs,
  notification,
  gamification,
  bulkActions,
  bundles,
  details,
  auth,
  sidebar,
  templates,
  tickets,
  rules,
  mapping,
  users,
  dataSource,
  ticketConfig,
  user,
  tutorial,
  email,
  proxyConfiguration,
  rundeckConfirmation,
  dryRun
};
