import React from "react";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";

const GtrListItem = React.memo(({ workflow, checked, setGtrWorkflow }) => {
  return (
    <Grid container alignItems="center">
      <Grid xs={1}>
        <Radio
          value={workflow.resolutionFlowId}
          checked={checked}
          onChange={e =>
            setGtrWorkflow(e.target.value, workflow.ticketCategoryId)
          }
        />
      </Grid>
      <Grid xs={5} style={{ fontSize: 14 }}>
        {workflow.categoryName}
      </Grid>
      <Grid xs={5} style={{ fontSize: 14 }}>
        {workflow.resolutionFlow}
      </Grid>
    </Grid>
  );
});

export default GtrListItem;
