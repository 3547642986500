import React, { useEffect, useState } from "react";

import { Divider, List, ListItem } from "material-ui";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";

import { ICSSProps, automationConfigsStyles } from "../Styles";
import {
  IFieldsProps,
  IMLFieldsProps,
  JobParametersType,
  ResultDetailsType
} from "../DryRunInterface";
import TriggerConditions from "./DryRunTriggerConditions";
import MLConditions from "./DryRunMLConditions";
import JobParameters from "./DryRunJobParam";

const AutomationConfigsCard: React.FC<ICSSProps<
  typeof automationConfigsStyles
> &
  ResultDetailsType> = React.memo(({ classes, resultDetails }) => {
  const [open, setOpen] = useState<boolean>(true);
  const [triggerConditions, setTriggerConditions] = useState<IFieldsProps[]>(
    null
  );
  const [mlConditions, setMLConditions] = useState<IMLFieldsProps[]>(null);
  const [jobWorkflowName, setJobWorkflowName] = useState<string>(null);
  const [jobParameters, setJobParameters] = useState<JobParametersType>(null);

  const {
    fields,
    mlFields
  } = resultDetails.output.configMatched.triggerConditions;

  const { detailedOptions, name } = resultDetails.output.workflowDescription;

  useEffect(() => {
    if (fields && mlFields && name && detailedOptions) {
      setTriggerConditions(fields);
      setMLConditions(mlFields.predictions);
      setJobWorkflowName(name);
      setJobParameters(detailedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className={classes.previewAutomationConfigs}>
      <List>
        <ListItem
          onClick={handleClick}
          leftIcon={
            open ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )
          }
          primaryText={
            name && (
              <span className={classes.automationConfigTitle}>{name}</span>
            )
          }
        />
        {open ? (
          <></>
        ) : (
          <ListItem
            primaryText={
              <span className={classes.jobWorkflow}>Job/Workflow Name</span>
            }
          />
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          {triggerConditions &&
            mlConditions &&
            jobWorkflowName &&
            jobParameters && (
              <List>
                <Divider className={classes.divider} />
                <br />
                <TriggerConditions triggerConditions={triggerConditions} />
                <MLConditions mlConditions={mlConditions} />
                <Divider className={classes.divider} />
                <ListItem
                  primaryText={
                    <span className={classes.jobWorkflow}>
                      Job/Workflow Name
                    </span>
                  }
                />
                <span className={classes.jobWorkflowContent}>{name}</span>
                <br />
                <br />
                <JobParameters jobParameters={jobParameters} />
              </List>
            )}
        </Collapse>
      </List>
    </div>
  );
});

export default AutomationConfigsCard;
