import {
  OPEN_TUTORIAL,
  CLOSE_TUTORIAL,
  GET_DEMO_WORKFLOWS_SUCCESS,
  GET_DEMO_WORKFLOWS_ERROR,
  GET_DEMO_WORKFLOWS_STARTED,
  SET_SELECTED_WORKFLOWS,
  NEW_AUTOMATION_FILTER,
  SET_AUTOMATION_FILTER,
  REMOVE_AUTOMATION_FILTER,
  SET_CLIENT_INFO,
  ADD_WORKFLOW_FILTER,
  REMOVE_WORKFLOW_FILTER,
  CLEAR_WORKFLOW_FILTER,
  ADD_PROVIDER,
  REMOVE_PROVIDER,
  GET_PROVIDER_FILTERS_SUCCESS,
  GET_PROVIDER_FILTERS_ERROR,
  SET_SELECTED_PROVIDER_TYPE,
  CLONE_AUTOMATION_FILTER,
  SAVE_PROVIDER_FILTER_STARTED,
  SAVE_PROVIDER_FILTER_SUCCESS,
  SAVE_PROVIDER_FILTER_ERROR,
  SET_REVIEW_CONFIRMED,
  SET_SNOW_CONFIG
} from "../actions/tutorial";
import {
  STRING_OPERATORS,
  CONDITIONAL_BUILDERS
} from "../models/AutomationFilterModel";

const emptyFilter = {
  field: "",
  value: "",
  conditionalBuilder: CONDITIONAL_BUILDERS.and.value,
  stringOperator: STRING_OPERATORS.is.value
};

const initialClientInfo = {
  name: "Iron Mountain Cloud",
  contract: "9940334425: Iron Mountain Cloud Implementation",
  workstream: "Global Design Plan"
};

export const initialSNOWConfig = {
  url: "",
  pwd: "",
  user: "",
  isValid: false,
  isLoading: true
};

const updateAutomationFilters = (state, newFilters) => {
  // update selected provider type
  const selectedType = {
    ...state.selectedProviderType,
    filters: newFilters
  };
  // update overall filters
  const provider = state.providerFilters[selectedType.providerId];
  const typeIdx = provider.ticketTypes.findIndex(
    type => type.ticketType === selectedType.ticketType
  );
  if (typeIdx >= 0) {
    provider.ticketTypes[typeIdx].filters = newFilters;
  }
  return {
    ...state,
    selectedProviderType: { ...selectedType },
    providerFilters: {
      ...state.providerFilters,
      [selectedType.providerId]: { ...provider }
    }
  };
};

const getNewAutomationFilter = state => {
  const newFilters = [
    ...state.selectedProviderType.filters,
    { ...emptyFilter }
  ];
  return updateAutomationFilters(state, newFilters);
};

const cloneAutomationFilter = state => {
  const newFilters = [...state.selectedProviderType.filters];
  const prevFilter = newFilters[newFilters.length - 1];
  newFilters.push({ ...prevFilter, value: "" });
  return updateAutomationFilters(state, newFilters);
};

const removeAutomationFilter = (state, action) => {
  // remove from selected type
  const newFilters = [...state.selectedProviderType.filters];
  newFilters.splice(action.index, 1);
  return updateAutomationFilters(state, newFilters);
};

const setAutomationFilter = (state, action) => {
  const updatedFilters = [...state.selectedProviderType.filters];
  updatedFilters[action.index] = action.newValue;
  return updateAutomationFilters(state, updatedFilters);
};

// Contains reducer functions and actions for Help page

function tutorial(
  state = {
    displayJoyride: false,
    demoWorkflows: [],
    selectedWorkflows: [],
    workflowFilters: [],
    errorMessage: "",
    clientInfo: initialClientInfo,
    selectedProviders: [],
    providerFilters: {},
    selectedProviderType: undefined,
    isSaving: false,
    reviewConfirmed: false,
    snowConfig: initialSNOWConfig
  },
  action
) {
  switch (action.type) {
    case SET_SNOW_CONFIG:
      return {
        ...state,
        snowConfig: action.config
      };
    case OPEN_TUTORIAL:
      const newState = Object.assign({}, state, {
        displayJoyride: action.displayJoyride
      });
      return newState;
    case CLOSE_TUTORIAL:
      const newState1 = Object.assign({}, state, {
        displayJoyride: action.displayJoyride
      });
      return newState1;
    case GET_DEMO_WORKFLOWS_STARTED:
      return {
        ...state,
        demoWorkflows: [],
        errorMessage: ""
      };
    case GET_DEMO_WORKFLOWS_SUCCESS:
      return {
        ...state,
        demoWorkflows: action.payload.data,
        errorMessage: ""
      };
    case GET_DEMO_WORKFLOWS_ERROR:
      return {
        ...state,
        errorMessage: action.payload.error,
        demoWorkflows: []
      };
    case GET_PROVIDER_FILTERS_SUCCESS:
      return {
        ...state,
        providerFilters: action.payload.data,
        errorMessage: ""
      };
    case GET_PROVIDER_FILTERS_ERROR:
      return {
        ...state,
        errorMessage: action.payload.error,
        providerFilters: []
      };
    case SET_SELECTED_WORKFLOWS:
      return {
        ...state,
        selectedWorkflows: action.workflows
      };
    case ADD_WORKFLOW_FILTER:
      return {
        ...state,
        workflowFilters: [...state.workflowFilters, action.category]
      };
    case REMOVE_WORKFLOW_FILTER:
      return {
        ...state,
        workflowFilters: state.workflowFilters.filter(
          workflowFilter => workflowFilter !== action.category
        )
      };
    case CLEAR_WORKFLOW_FILTER:
      return {
        ...state,
        workflowFilters: []
      };
    case NEW_AUTOMATION_FILTER:
      return getNewAutomationFilter(state);
    case CLONE_AUTOMATION_FILTER:
      return cloneAutomationFilter(state);
    case REMOVE_AUTOMATION_FILTER:
      return removeAutomationFilter(state, action);
    case SET_AUTOMATION_FILTER:
      return setAutomationFilter(state, action);
    case SET_CLIENT_INFO:
      return { ...state, clientInfo: action.newDetails };
    case ADD_PROVIDER:
      return {
        ...state,
        selectedProviders: [...state.selectedProviders, action.provider]
      };
    case REMOVE_PROVIDER:
      return {
        ...state,
        selectedProviders: state.selectedProviders.filter(
          provider => provider !== action.provider
        )
      };
    case SET_SELECTED_PROVIDER_TYPE:
      return {
        ...state,
        selectedProviderType: action.providerType
      };
    case SET_REVIEW_CONFIRMED:
      return {
        ...state,
        reviewConfirmed: action.reviewConfirmed ?? !state.reviewConfirmed
      };
    case SAVE_PROVIDER_FILTER_STARTED:
      return {
        ...state,
        isSaving: true
      };
    case SAVE_PROVIDER_FILTER_SUCCESS:
      return {
        ...state,
        isSaving: false
      };
    case SAVE_PROVIDER_FILTER_ERROR:
      return {
        ...state,
        errorMessage: "",
        isSaving: false
      };
    default:
      return state;
  }
}

export default tutorial;
