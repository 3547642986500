import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Checkbox from "@material-ui/core/Checkbox";

export const ButtonContainer = styled(({ isSelected, ...rest }) => (
  <ButtonBase {...rest} />
))`
  display: flex !important;
  padding: 15px 20px !important;
  align-items: center !important;
  justify-content: center !important;
  border: ${props =>
    props.isSelected
      ? "0.5px solid #378aff !important; background-color: #E7F2FF !important;"
      : "0.5px solid #c9c7c7 !important"};
  box-shadow: ${props =>
    props.isSelected
      ? "0px 4px 10px rgba(2, 119, 189, 0.19);"
      : "0px 0px 0px rgba(0, 0, 0, 0);"};
  border-radius: 5px !important;
  width: 100%
  max-width: 875px;
  min-height: 70px;
  text-align: left;
  &:hover {
    border: 0.5px solid #378aff !important;
  }

  @media screen and (max-width: 992px) {
    padding: 7px 12px !important;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    padding: 10px 15px !important;
  }

  @media screen and (max-height: 768px) {
    padding: 7px 12px !important;
  }
`;

export const TitleText = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  font-family: Open Sans;
  margin: 0;

  @media screen and (max-width: 992px) {
    font-size: 0.9rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 1rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 0.9rem;
  }
`;

export const DescriptionText = styled.div`
  font-size: 0.9rem;
  font-family: Open Sans;
  margin: 0;
  margin-top: 5px;

  @media screen and (max-width: 992px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 0.8rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 0.7rem;
  }
`;

export const SelectCheckbox = styled(Checkbox)`
  color: #378aff !important;
`;

export const LinkContainer = styled.span`
  margin-right: 20px;
`;

export const SmallButton = styled(Button)`
  min-width: 10px !important;
  max-width: 10px !important;
  color: #0084ff !important;
`;
