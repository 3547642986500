import config from "../config";
import { axiosInstance as axios } from "../utils/axios";

export const OPEN_CHATBOT = "OPEN_CHATBOT";
export const CLOSE_CHATBOT = "CLOSE_CHATBOT";
export const INSERT_MESSAGE = "INSERT_MESSAGE";

export function openChatbot() {
  return {
    type: OPEN_CHATBOT
  };
}

export function closeChatbot() {
  return {
    type: CLOSE_CHATBOT
  };
}

export function insertMessage(message) {
  return {
    type: INSERT_MESSAGE,
    message
  };
}

const formatMessage = message => ({
  type: "chatbot",
  name: "myWizard AiOps Helpdesk",
  user: "chatbot",
  text: message.result.speech,
  date: Date.now()
});

export function sendMessage(message, uuidUser) {
  return (dispatch, getState) => {
    // Insert original message
    dispatch(insertMessage(message));
    const url = `${config.urls.base + config.urls.apis.chatbot}/query`;
    const body = {
      query: [message.text],
      lang: "en",
      sessionId: uuidUser,
      contexts: [
        {
          name: "rivescript",
          parameters: [
            {
              name: "username",
              value: message.user
            }
          ]
        }
      ]
    };

    axios.post(url, body).then(res => {
      dispatch(insertMessage(formatMessage(res.data)));
    });
  };
}
