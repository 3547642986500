import React, { useState, useRef, useLayoutEffect } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TicketTemplateViewerTemplateFieldsCheckboxCTN from "./Checkbox/CheckboxCTN";
import { withStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";

const styles = theme => ({
  searchIcon: {
    fontSize: 20,
    color: "grey"
  },
  fieldWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    alignContent: "flex-start",
    width: "100%",
    padding: "0 20px",
    height: 370
  },
  fields: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    alignContent: "flex-start",
    width: "100%",
    height: 300,
    marginTop: "10px"
  },
  field: {
    display: "flex",
    alignItems: "baseline"
  },
  iconButton: {
    fontSize: 18
  },
  page: {
    padding: "0 10px",
    fontSize: 12
  },
  selectAllCheckboxDiv: {
    marginTop: "10px",
    width: "100%"
  }
});

const paginatedTicketFields = (ticketFields, pageSize, pageNumber) => {
  return ticketFields.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

const TicketTemplateViewerTemplateFields = props => {
  const {
    availableTemplateColumn,
    classes,
    toggleCheckBox,
    selectedTicketType,
    selectedUserData
  } = props;

  const fieldsRef = useRef(null);
  const [fieldPage, setFieldPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const fieldMaxPage = Math.ceil(availableTemplateColumn.length / pageSize);

  const resize = () => {
    if (fieldPage > fieldMaxPage) {
      setFieldPage(fieldMaxPage);
    }
    if (fieldPage === 0 && fieldPage < fieldMaxPage) {
      setFieldPage(1);
    }
    setPageSize(2 * Math.ceil(fieldsRef.current.clientHeight / 30));
  };
  useLayoutEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [fieldPage, fieldMaxPage]);

  const selectAll = () => {
    availableTemplateColumn.map((value, index) => {
      if (!selectedUserData.includes(availableTemplateColumn[index].name)) {
        toggleCheckBox(selectedTicketType, value, true);
      }
    });
  };
  const clearAll = () => {
    selectedUserData.map((value, index) => {
      toggleCheckBox(selectedTicketType, value, false);
    });
  };
  return (
    <>
      {/* Fields */}
      <div>
        <div className={classes.selectAllCheckboxDiv}>
          <Button
            color="primary"
            variant="outlined"
            style={{ float: "left" }}
            onClick={selectAll}
          >
            Select All
          </Button>
          <Button
            color="primary"
            variant="outlined"
            style={{ float: "right" }}
            onClick={clearAll}
          >
            Clear All
          </Button>
        </div>
        <div className={classes.fieldWrapper} ref={fieldsRef}>
          <div className={classes.fields}>
            {paginatedTicketFields(
              availableTemplateColumn,
              pageSize,
              fieldPage
            ).map((item, i) => {
              return (
                <Grid key={i} item xs={6} className={classes.field}>
                  <TicketTemplateViewerTemplateFieldsCheckboxCTN
                    key={i}
                    index={i}
                    item={item}
                  />
                </Grid>
              );
            })}
          </div>

          {/* Field Paginator */}
          {availableTemplateColumn.length > 0 && (
            <Grid container justify="flex-end" alignItems="center">
              <IconButton
                className={classes.iconButton}
                disabled={fieldPage === 1}
                onClick={() => setFieldPage(fieldPage - 1)}
              >
                <ChevronLeftIcon />
              </IconButton>
              <div className={classes.page}>
                {fieldPage} / {fieldMaxPage}
              </div>
              <IconButton
                className={classes.iconButton}
                disabled={fieldPage === fieldMaxPage}
                onClick={() => setFieldPage(fieldPage + 1)}
              >
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
};

TicketTemplateViewerTemplateFields.defaultProps = {
  ticketFields: []
};

export default withStyles(styles)(TicketTemplateViewerTemplateFields);
