import React, { useCallback } from "react";
import PropTypes from "prop-types";
import AutomationFilterModel from "models/AutomationFilterModel";
import SelectDropdown from "components/common/SelectDropdown";
import {
  CONDITIONAL_BUILDERS,
  STRING_OPERATORS
} from "models/AutomationFilterModel";
import {
  MainContainer,
  ColumnContainer,
  InputContainer,
  InputValueField
} from "./styles";
import { ButtonBase } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const fieldOptions = ["assignment_group.name", "assigned_to"];

const stringOperators = Object.keys(STRING_OPERATORS).map(
  key => STRING_OPERATORS[key]
);

const conditionalBuilders = Object.keys(CONDITIONAL_BUILDERS).map(
  key => CONDITIONAL_BUILDERS[key]
);

// converts array of string to dropdown options
const toDropdownOptions = options => {
  return options.map(option => ({
    displayText: option,
    value: option
  }));
};

const FilterRow = props => {
  const { filter, index, removeFilter, setFilter, cloneFilter } = props;

  const dropdownFields = toDropdownOptions(fieldOptions);

  const handleChange = useCallback(
    property => {
      return value => {
        const dirtyFilter = { ...filter, [property]: value };
        setFilter(index, dirtyFilter);
      };
    },
    [filter, index, setFilter]
  );
  //on enter, a new filterow is created with field initialised to the previous row
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      cloneFilter();
    }
  };

  return (
    <MainContainer container isEven={(index + 1) % 2 === 0}>
      <ColumnContainer item xs={1}>
        {index === 0 ? (
          "Where"
        ) : (
          <SelectDropdown
            value={filter.conditionalBuilder}
            options={conditionalBuilders}
            onChange={handleChange("conditionalBuilder")}
          />
        )}
      </ColumnContainer>
      <ColumnContainer item xs={4}>
        <InputContainer
          style={{ marginLeft: 10, width: "70%", maxWidth: "calc(70% - 10px)" }}
        >
          <SelectDropdown
            value={filter.field}
            options={dropdownFields}
            onChange={handleChange("field")}
          />
        </InputContainer>
        <InputContainer style={{ width: "30%", maxWidth: "calc(30% - 10px)" }}>
          <SelectDropdown
            value={filter.stringOperator}
            options={stringOperators}
            onChange={handleChange("stringOperator")}
          />
        </InputContainer>
      </ColumnContainer>
      <ColumnContainer item xs={7}>
        <InputValueField
          value={filter.value}
          onKeyPress={handleKeyPress}
          onChange={e => handleChange("value")(e.target.value)}
        />
        <ButtonBase
          onClick={() => removeFilter(index)}
          style={{
            marginLeft: 20,
            color: "#B9B9B9"
          }}
        >
          <Delete />
        </ButtonBase>
      </ColumnContainer>
    </MainContainer>
  );
};

FilterRow.propTypes = {
  index: PropTypes.number.isRequired,
  filter: AutomationFilterModel,
  removeFilter: PropTypes.func,
  setFilter: PropTypes.func,
  cloneFilter: PropTypes.func
};

export default FilterRow;
