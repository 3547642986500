import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const TicketTemplateViewerTemplateFieldsCheckbox = props => {
  const { TogglePrimaryCheckBox, ToggleSecondaryCheckBox } = props;
  const { isChecked, isDisabled, item, currentTab } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          disabled={isDisabled}
          color="primary"
          style={{ height: "23px" }}
          onChange={event => {
            currentTab === 0
              ? TogglePrimaryCheckBox(item, event.target.checked)
              : ToggleSecondaryCheckBox(item, event.target.checked);
          }}
        />
      }
      label={item.displayName}
    />
    // {/* <Grid item xs={2}>
    //   <Checkbox
    //     checked={isChecked}
    //     disabled={isDisabled}
    //     color="primary"
    //     style={{ height: "23px" }}
    //     onChange={event => {
    //       currentTab === 0
    //         ? TogglePrimaryCheckBox(item, event.target.checked)
    //         : ToggleSecondaryCheckBox(item, event.target.checked);
    //     }}
    //   />
    // </Grid>

    // <Grid item xs={10}>
    //   <div>{item.displayName}</div>
    // </Grid> */}
  );
};

export default TicketTemplateViewerTemplateFieldsCheckbox;
