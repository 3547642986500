import { connect } from "react-redux";
// import _ from "lodash";
import ConfidentialityPopover from "./confidentialityPopover";

import { registerUserConsent } from "../../actions/users";

const mapStateToProps = (state, ownProps) => {
  return {
    confidentialityPopoverPressed: state.user.confidentialityPopoverPressed
  };
};

const mapDispatchToProps = dispatch => ({
  registerUserConsent: consentDate => dispatch(registerUserConsent(consentDate))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfidentialityPopover);
