import { LOCAL_CACHE_RANGE } from "../actions/tickets";

import { TICKET_FIELD_AUTOCOMPLETE } from "../actions/tickets";

import {
  TICKET_SET_FILTER,
  TICKET_SET_SORT,
  TICKET_SET_TIME_RANGE,
  TICKET_SET_INITIAL_FILTERS,
  TICKET_GET_PRESET_FILTERS,
  TICKET_SET_PRESET_FILTER,
  TOGGLE_SHOW_MORE_FILTERS
} from "../actions/filterAndSort";

import {
  TICKET_EXPORT_PENDING,
  TICKET_EXPORT_COMPLETED,
  TICKET_EXPORT_ERROR,
  TICKET_EXPORT_DIALOG_OPEN,
  TICKET_EXPORT_SET_FILENAME
} from "../actions/export";

import {
  TICKET_GET_ALL_PENDING,
  TICKET_GET_ALL_COMPLETED,
  TICKET_GET_ALL_FULFILLED,
  TICKET_GET_SINGLE_PENDING,

  // TICKET CREATE
  TICKET_CREATE_SINGLE_FULFILLED,
  TICKET_CREATE_SINGLE_PENDING,
  TICKET_CREATE_SINGLE_ERROR,

  // TICKET options
  TICKET_GET_ALL_TICKET_CACHE_INITIALISED,
  TICKET_GET_ALL_TICKET_CACHE_ADD,
  TICKET_GET_SINGLE_TICKET_CACHE,

  // Manual resolution Link
  TICKET_MANUAL_RESOLUTION_LINK_RETRIEVED,
  TICKET_MANUAL_RESOLUTION_LINK_ERROR,
  TICKET_MANUAL_RESOLUTION_CONFIG_RETRIEVED,
  TICKET_MANUAL_RESOLUTION_CONFIG_ERROR,

  // Service Request
  SERVICE_REQUEST_CATALOG_GET_SUCCESS,
  SERVICE_REQUEST_CATALOG_GET_ERROR,

  // Experimental
  TICKET_GET_POLLING_TICKET_CACHE,
  TICKET_GET_CACHE_SORT_FILTER_PENDING,
  TICKET_GET_CACHE_SORT_FILTER_INITIALISED,
  TICKET_CACHE_MOVE_POINTER,
  TICKET_SELECT_AUTOMATION_JOB,
  TICKET_STREAM_OPEN_STREAM,
  TICKET_STREAM_RECEIVE_TICKET,
  TICKET_STREAM_CLOSE_STREAM,
  GET_SERVICE_REQUEST_PENDING,
  GET_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_ITEMS_PENDING,
  GET_SERVICE_REQUEST_ITEMS_SUCCESS,
  GET_SERVICE_REQUEST_TASKS_PENDING,
  GET_SERVICE_REQUEST_TASKS_SUCCESS,
  SET_TICKET_TYPE,
  CLEAR_PARSED_TICKET,
  SET_TOTAL_TICKETS_COUNT,
  ADD_TO_CONTINUOUS_ML_SUCCESS,
  GET_GTR_WORKFLOWS_SUCCESS,
  GET_ALL_GTR_WORKFLOWS_SUCCESS,
  SET_GTR_WORKFLOW,
  SET_GTR_WORKFLOW_FILTER,

  // Get similar tickets
  TICKET_GET_SIMILAR_PENDING,
  TICKET_GET_SIMILAR_FULFILLED,
  SET_GTR_WORKFLOW_FILTER_TYPE
} from "../actions/tickets";

export const TOGGLE_CUSTOMIZATION_TABLE = "TOGGLE_CUSTOMIZATION_TABLE";
export const SET_COLUMN_FILTER = "SET_COLUMN_FILTER";

const initialState = {
  tickets: [],
  totalTickets: 0,
  ticket: {},
  ticketJobSelected: {},
  gtrWorkflows: [],
  allGtrWorkflows: [],
  selectedGtrWorkflow: "",
  workflowFilter: "",
  workflowFilterType: "categoryName",
  selectedCategory: "",
  // FILTERING AND SORTING
  ticketFilter: {},
  ticketActiveFilters: [],
  ticketSort: {
    sortField: "lastUpdateDate",
    sortDirection: "DESC"
  },
  ticketTimeRange: { start: "", end: "" },
  isFetchingTickets: false,
  isFetchingTicketsPolling: false,
  presetFilters: {}, // object has ticketType followed by its presets
  presetSelected: "",
  toggleShowMoreFilters: false,

  //EXPORTING
  isFetchingExports: false,
  exportErrorMsg: "",
  exportFileName: "",
  isExportDialogOpen: false,
  exportTimer: 0,

  selectedAutomationJob: {},

  cacheLocalTickets: {},
  cacheLocalFinal: 0, //number of pages - not tickets
  cacheLocalPointer: 1,
  cacheObject: {},

  isCacheRefreshing: false,
  isTicketSingleCreating: false,
  // AUTOCOMPLETE
  autocompleteFields: {},

  // Stream
  ticketStream: null,

  // Service Request
  serviceRequestCatalog: [],
  serviceRequest: {},
  srItems: [],
  srTasks: [],

  selectedTicketType: "STANDALONE",
  parsedTicket: {},

  manualResolutionLink: [],
  // Commenting out changes for now for 3.3.6.1
  //manualResolutionConfig: { gtr: false },
  manualResolutionConfig: { gtr: "false" },

  //customization
  isCustomizationTableOpen: false,
  columnFilter: "",

  //Similar tickets
  similarTickets: [],
  isFetchingSimilarTickets: false
};

export const toggleCustomizationTable = () => ({
  type: TOGGLE_CUSTOMIZATION_TABLE
});

export const setColumnFilter = columnFilter => ({
  type: SET_COLUMN_FILTER,
  columnFilter
});

function tickets(state = initialState, action) {
  switch (action.type) {
    case SET_GTR_WORKFLOW_FILTER: {
      return {
        ...state,
        workflowFilter: action.filter
      };
    }
    case SET_GTR_WORKFLOW_FILTER_TYPE: {
      return {
        ...state,
        workflowFilterType: action.filterType
      };
    }
    case GET_ALL_GTR_WORKFLOWS_SUCCESS: {
      return {
        ...state,
        allGtrWorkflows: action.payload
      };
    }
    case SET_GTR_WORKFLOW: {
      return {
        ...state,
        selectedGtrWorkflow: action.selectedGtrWorkflow,
        selectedCategory: action.selectedCategory
      };
    }
    case GET_GTR_WORKFLOWS_SUCCESS: {
      return {
        ...state,
        gtrWorkflows: action.payload
      };
    }
    case ADD_TO_CONTINUOUS_ML_SUCCESS: {
      return {
        ...state,
        ticket: {
          ...state.ticket,
          continuousML: true
        }
      };
    }
    case SET_COLUMN_FILTER: {
      return {
        ...state,
        columnFilter: action.columnFilter
      };
    }
    case TOGGLE_CUSTOMIZATION_TABLE: {
      return {
        ...state,
        isCustomizationTableOpen: !state.isCustomizationTableOpen
      };
    }
    case CLEAR_PARSED_TICKET: {
      return {
        ...state,
        parsedTicket: action.parsedTicket
      };
    }
    case SET_TICKET_TYPE: {
      return {
        ...state,
        selectedTicketType: action.ticketType,
        presetSelected: ""
      };
    }
    case SET_TOTAL_TICKETS_COUNT: {
      return {
        ...state,
        totalTickets: action.count
      };
    }
    case TICKET_SET_FILTER: {
      // Need to update either the state or the assignedTo filters
      // let newFilters = state.ticketFilter;
      // if (action.newFilter.state !== undefined) {
      //   newFilters = Object.assign({}, state.ticketFilter, {
      //     state: action.newFilter.state
      //   });
      // }
      // if (action.newFilter.assignee !== undefined) {
      //   newFilters = Object.assign({}, state.ticketFilter, {
      //     assignee: action.newFilter.assignee
      //   });
      // }

      let newFilters = { ...state.ticketFilter };

      Object.entries(action.newFilter).forEach(([key, value]) => {
        if (value !== undefined) {
          newFilters = { ...newFilters, ...{ [key]: value } };
        }
      });

      return Object.assign({}, state, {
        ticketFilter: newFilters
      });
    }
    case TICKET_SET_SORT: {
      return Object.assign({}, state, {
        ticketSort: action.ticketSort
      });
    }
    case TICKET_SET_TIME_RANGE: {
      return Object.assign({}, state, {
        ticketTimeRange: action.range
      });
    }
    case TICKET_SET_INITIAL_FILTERS: {
      return Object.assign({}, state, {
        ticketActiveFilters: action.filters,
        ticketFilter: action.fields
      });
    }
    case TICKET_GET_PRESET_FILTERS: {
      return Object.assign({}, state, {
        presetFilters: action.presetFilters
      });
    }
    case TICKET_SET_PRESET_FILTER: {
      return Object.assign({}, state, {
        presetSelected: action.preset
      });
    }
    case TOGGLE_SHOW_MORE_FILTERS: {
      return Object.assign({}, state, {
        showMoreFilters: action.flag
      });
    }
    case TICKET_EXPORT_PENDING: {
      return Object.assign({}, state, {
        isFetchingExports: true
      });
    }
    case TICKET_EXPORT_COMPLETED: {
      return Object.assign({}, state, {
        isFetchingExports: false,
        isExportDialogOpen: false,
        exportFileName: ""
      });
    }
    case TICKET_EXPORT_ERROR: {
      return Object.assign({}, state, {
        exportErrorMsg: action.exportErrorMsg,
        isFetchingExports: false
      });
    }
    case TICKET_EXPORT_DIALOG_OPEN: {
      if (action.isOpen === false) {
        return Object.assign({}, state, {
          isExportDialogOpen: action.isOpen,
          exportErrorMsg: "",
          exportFileName: ""
        });
      }
      return Object.assign({}, state, {
        isExportDialogOpen: action.isOpen
      });
    }
    case TICKET_EXPORT_SET_FILENAME: {
      return Object.assign({}, state, {
        exportFileName: action.exportFileName
      });
    }
    case TICKET_FIELD_AUTOCOMPLETE: {
      const tmpAutoFields = Object.assign({}, state.autocompleteFields);
      tmpAutoFields[action.descriptions.id] =
        action.descriptions.suggestionList;
      return Object.assign({}, state, {
        autocompleteFields: tmpAutoFields
      });
    }
    case TICKET_GET_ALL_PENDING: {
      return Object.assign({}, state, {
        isFetchingTickets: true
      });
    }
    case TICKET_GET_ALL_COMPLETED: {
      return Object.assign({}, state, {
        isFetchingTickets: false
      });
    }
    case TICKET_SELECT_AUTOMATION_JOB: {
      return Object.assign({}, state, {
        selectedAutomationJob: action.automationJob
      });
    }
    case TICKET_GET_ALL_FULFILLED: {
      return Object.assign({}, state, {
        tickets: action.tickets,
        isFetchingTickets: false
      });
    }
    case TICKET_GET_SINGLE_PENDING: {
      return Object.assign({}, state, {
        isFetchingTicketsSingle: true
      });
    }
    case TICKET_CREATE_SINGLE_FULFILLED: {
      return Object.assign({}, state, {
        ticket: action.ticket,
        isFetchingTickets: false,
        isTicketSingleCreating: false
      });
    }
    case TICKET_CREATE_SINGLE_ERROR: {
      return Object.assign({}, state, {
        ticket: action.ticket,
        isFetchingTickets: false,
        isTicketSingleCreating: false
      });
    }

    /* Experimental */
    // refresh cache by polling
    case TICKET_GET_POLLING_TICKET_CACHE: {
      return Object.assign({}, state, {
        cacheObject: {
          cacheLocalFinal: action.cacheLocalFinal,
          cacheLocalTickets: action.cacheLocalTickets
        }
      });
    }

    case TICKET_GET_CACHE_SORT_FILTER_PENDING: {
      return Object.assign({}, state, {
        isCacheRefreshing: true
      });
    }
    case TICKET_CREATE_SINGLE_PENDING: {
      return Object.assign({}, state, {
        isTicketSingleCreating: true
      });
    }

    case TICKET_GET_CACHE_SORT_FILTER_INITIALISED: {
      return Object.assign({}, state, {
        isCacheRefreshing: false,
        cacheLocalTickets: action.cacheLocalTickets,
        cacheLocalFinal: action.cacheLocalFinal,
        cacheObject: {
          cacheLocalFinal: action.cacheLocalFinal,
          cacheLocalTickets: action.cacheLocalTickets
        },
        cacheLocalPointer: action.cacheLocalPointer
      });
    }

    // initialise cache
    case TICKET_GET_ALL_TICKET_CACHE_INITIALISED: {
      return Object.assign({}, state, {
        cacheLocalTickets: action.cacheLocalTickets,
        cacheLocalFinal: action.cacheLocalFinal,
        cacheObject: {
          cacheLocalFinal: action.cacheLocalFinal,
          cacheLocalTickets: action.cacheLocalTickets
        }
      });
    }

    // appends or replaces new items into the local cache depending on the key
    case TICKET_GET_ALL_TICKET_CACHE_ADD: {
      return Object.assign({}, state, {
        cacheLocalFinal: action.cacheLocalFinal,
        cacheLocalTickets: Object.assign(
          {},
          state.cacheLocalTickets,
          action.cacheLocalTickets
        ),
        cacheObject: {
          cacheLocalFinal: action.cacheLocalFinal,
          cacheLocalTickets: Object.assign(
            {},
            state.cacheLocalTickets,
            action.cacheLocalTickets
          )
        }
      });
    }

    case TICKET_GET_SINGLE_TICKET_CACHE: {
      return Object.assign({}, state, {
        tickets: action.tickets
      });
    }
    case TICKET_CACHE_MOVE_POINTER: {
      return Object.assign({}, state, {
        cacheLocalPointer: action.cacheLocalPointer
      });
    }

    case TICKET_STREAM_OPEN_STREAM: {
      // Close the stream if already there
      if (state.ticketStream) {
        state.ticketStream.close();
      }

      // Update the state with the new strean
      return Object.assign({}, state, {
        ticketStream: action.ticketStream
      });
    }
    case TICKET_STREAM_RECEIVE_TICKET: {
      // 0 - Parse the ticket
      // 1 - Check for ticketType
      // 2 - Reunified all the tickets in one single array
      // 3 - Remove ticket if already in ticket list
      // 4 - Determine the Filter and Order to know if ticket should be add to the list
      // 5 - Reorganize the ticket list

      // 0 - Parse the ticket
      const parsedTicket = JSON.parse(action.ticket);

      // 1 - Check for ticket type, if not the correct type, don't add to table
      if (parsedTicket.coreData.type !== state.selectedTicketType) {
        return state;
      }

      // 2 - Reunify all the tickets in one single array
      let newTicketList = [];
      // Object.keys( <object> ).length give the length of a map without the ".length" property
      for (
        let i = 1;
        i <= Object.keys(state.cacheObject.cacheLocalTickets).length;
        i++
      ) {
        newTicketList = newTicketList.concat(
          state.cacheObject.cacheLocalTickets[i]
        );
      }

      // 3 - Remove the ticket if already in the list
      const indexTicketToRemove = newTicketList.findIndex(
        ticket => ticket.coreData.id === parsedTicket.coreData.id
      );
      // indexTicketToRemove == -1 if not in ticket list
      if (indexTicketToRemove >= 0) {
        newTicketList.splice(indexTicketToRemove, 1);
      }

      // 4 - Determine the Filter and Order to know if ticket should be add to the list
      // Compare the states: the one on the filter and the ticket state
      if (
        !state.ticketFilter ||
        !Object.keys(state.ticketFilter).some(
          field =>
            (parsedTicket.coreData[field] &&
              state.ticketFilter[field] &&
              state.ticketFilter[field].toUpperCase() !==
                parsedTicket.coreData[field].toUpperCase()) ||
            (parsedTicket.serviceData[field] &&
              state.ticketFilter[field] &&
              state.ticketFilter[field].toUpperCase() !==
                parsedTicket.serviceData[field].toUpperCase())
        )
      ) {
        // Adding at the top as this is the last updated/created ticket
        if (state.ticketSort && state.ticketSort.sortDirection === "DESC") {
          newTicketList = [parsedTicket].concat(newTicketList);
        } else {
          newTicketList = newTicketList.concat([parsedTicket]);
        }
      }

      // 5 - Reorganize the ticket list
      // Reorganizing the tickets into shard
      const newCacheLocalTickets = {};
      for (
        let i = 1;
        i <= Math.ceil(newTicketList.length / LOCAL_CACHE_RANGE);
        i++
      ) {
        // Get a shard of Ticekts
        const shard = newTicketList.slice(
          (i - 1) * LOCAL_CACHE_RANGE,
          i * LOCAL_CACHE_RANGE
        );
        if (shard.length > 0) {
          newCacheLocalTickets[i] = shard;
        }
      }

      // Update the state with the new tickets lists
      return Object.assign({}, state, {
        tickets: newCacheLocalTickets[state.cacheLocalPointer],
        cacheLocalTickets: newCacheLocalTickets,
        cacheObject: {
          cacheLocalFinal: state.cacheObject.cacheLocalFinal,
          cacheLocalTickets: newCacheLocalTickets
        },
        parsedTicket
      });
    }
    case TICKET_STREAM_CLOSE_STREAM: {
      // Close the stream
      if (state.ticketStream) {
        state.ticketStream.close();
      }

      // Remove the stream from the state
      return Object.assign({}, state, {
        ticketStream: null
      });
    }
    case TICKET_MANUAL_RESOLUTION_LINK_RETRIEVED: {
      return Object.assign({}, state, {
        manualResolutionLink: action.manualResolutionLink
      });
    }
    case TICKET_MANUAL_RESOLUTION_LINK_ERROR: {
      return Object.assign({}, state, {
        manualResolutionLink: null
      });
    }
    case TICKET_MANUAL_RESOLUTION_CONFIG_RETRIEVED: {
      return Object.assign({}, state, {
        manualResolutionConfig: action.manualResolutionConfig
      });
    }
    case TICKET_MANUAL_RESOLUTION_CONFIG_ERROR: {
      return Object.assign({}, state, {
        manualResolutionConfig: null
      });
    }
    case SERVICE_REQUEST_CATALOG_GET_SUCCESS: {
      return Object.assign({}, state, {
        serviceRequestCatalog: action.catalog
      });
    }
    case SERVICE_REQUEST_CATALOG_GET_ERROR: {
      return Object.assign({}, state, {
        serviceRequestCatalog: []
      });
    }
    case GET_SERVICE_REQUEST_PENDING: {
      return {
        ...state,
        serviceRequest: false
      };
    }
    case GET_SERVICE_REQUEST_SUCCESS: {
      return {
        ...state,
        serviceRequest: action.payload
      };
    }
    case GET_SERVICE_REQUEST_ITEMS_PENDING: {
      return {
        ...state,
        srItems: false
      };
    }
    case GET_SERVICE_REQUEST_ITEMS_SUCCESS: {
      return {
        ...state,
        srItems: action.payload
      };
    }
    case GET_SERVICE_REQUEST_TASKS_PENDING: {
      return {
        ...state,
        srTasks: false
      };
    }
    case GET_SERVICE_REQUEST_TASKS_SUCCESS: {
      return {
        ...state,
        srTasks: action.payload
      };
    }
    case TICKET_GET_SIMILAR_PENDING: {
      return {
        ...state,
        similarTickets: [],
        isFetchingSimilarTickets: true
      };
    }
    case TICKET_GET_SIMILAR_FULFILLED: {
      return {
        ...state,
        similarTickets: action.similarTickets,
        isFetchingSimilarTickets: false
      };
    }

    default:
      return state;
  }
}

export default tickets;
