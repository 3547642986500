// Contains helper functions used in AutomationQuickstart (DIY Automation) page
import {
  QUERY_OPERATORS,
  PROVIDERS
} from "../../../../../../../../models/AutomationFilterModel";

/**
 * Converts the filter objects into a ServiceNow query
 */
export function getSNOWQueryFilter(filters) {
  if (
    !filters ||
    filters.length === 0 ||
    filters.every(item => item.value === "")
  ) {
    return "None";
  }
  // convert all filter objects to strings
  const strFilters = filters
    .filter(item => item.value !== "")
    .map((filter, index) => {
      const operator = QUERY_OPERATORS[filter.stringOperator];
      let strValue = `${filter.field}${operator}${filter.value}`;
      // attach conditional builder at beginning if not the start query
      if (index !== 0) {
        strValue = `${QUERY_OPERATORS[filter.conditionalBuilder]}${strValue}`;
      }
      return strValue;
    });
  return strFilters.join("");
}

const getDetails = (provider, config) => {
  const details = {
    provider,
    instance: "N/A",
    isConnected: false,
    filters: []
  };
  if (provider === PROVIDERS.serviceNow) {
    const { confValid, errors, fields } = config;
    details.isConnected = confValid && errors.length === 0;
    for (const field of fields) {
      if (field.id === "URL") {
        details.instance = field.value;
        break;
      }
    }
    return details;
  }
  return undefined;
};

export function getProviderDetails(providerName, configs) {
  const config = configs && configs.byId && configs.byId[providerName];
  if (config && !config.isLoading) {
    return getDetails(providerName, config);
  } else {
    return undefined;
  }
}
