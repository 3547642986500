import React from "react";
import PropTypes from "prop-types";
import { ArcherElement } from "react-archer";
import { blue700 } from "material-ui/styles/colors";
import * as Styled from "./NodeStyle";
import AQETabWorkflowNodeCard from "./Card/Card";

export default function AQETabWorkflowNode({
  index,
  version,
  workflowNode,
  workflowNodesList,
  workloadNodesList
}) {
  // Retrieves Current Node information (Id, WorkflowNode)
  const currentNode = workloadNodesList.find(
    node => node.nodeId === workflowNode.id
  );
  const currentState = currentNode ? currentNode.state : void 0;
  const currentParams = currentNode ? currentNode.parameters : void 0;
  const currentResults = currentNode ? currentNode.results : void 0;

  // [V1] Retrieves Error Node information (Id, WorkflowNode, WorkloadNode)
  const errorNodeId =
    version === "V1" &&
    workflowNode.transitions.length > 0 &&
    (workflowNode.transitions[0].targetNode.includes("error")
      ? workflowNode.transitions[0].targetNode
      : void 0);
  const errorNode = workflowNodesList.find(node => node.id === errorNodeId);
  const errorInWorkload = workloadNodesList.find(
    node => node.nodeId === errorNodeId
  );

  // Retrieves information about the next node in the workload
  const findWorkloadIndex = nodeId => {
    return workloadNodesList.findIndex(item => item.nodeId === nodeId);
  };
  const nextNodeData = nodeId => {
    return findWorkloadIndex(nodeId) + 1 < workloadNodesList.length
      ? workflowNodesList.find(
          item =>
            item.id === workloadNodesList[findWorkloadIndex(nodeId) + 1].nodeId
        )
      : false;
  };

  // Generates the link between nodes
  const generateRelation = type => {
    let relation = [];

    // Version 1 Workflow Relations
    if (version === "V1") {
      if (type === "main") {
        // top->bottom
        if (workflowNodesList.length > index + 1) {
          const nextNodeId = workflowNodesList[index + 1].id.includes("error")
            ? workflowNodesList.length > index + 2
              ? workflowNodesList[index + 2].id
              : false
            : workflowNodesList[index + 1].id;

          if (nextNodeId && currentNode) {
            relation.push({
              targetId: nextNodeId,
              targetAnchor: "top",
              sourceAnchor: "bottom",
              style: {
                strokeWidth: 2,
                arrowLength: 2,
                strokeColor:
                  nextNodeData(currentNode.nodeId)?.id === nextNodeId
                    ? blue700
                    : "#d1d5da"
              }
            });
          }
        }

        // left->right
        if (errorNode) {
          relation.push({
            targetId: errorNodeId,
            targetAnchor: "left",
            sourceAnchor: "right",
            style: {
              strokeWidth: 2,
              arrowLength: 2,
              strokeColor: errorInWorkload ? blue700 : "#d1d5da"
            }
          });
        }
      }

      // relations stemming from error node
      if (type === "error") {
        if (nextNodeData(errorNodeId) && errorInWorkload) {
          relation.push({
            targetId: nextNodeData(errorNodeId).id,
            targetAnchor: "top",
            sourceAnchor: "bottom",
            style: {
              strokeWidth: 2,
              arrowLength: 2,
              strokeColor: blue700
            }
          });
        }
      }

      return relation;
    }

    // Version 2 Workflow Relations
    if (version === "V2") {
      // if there are transitions and node not last in workload
      if (
        workflowNode.transitions.length > 0 &&
        workloadNodesList.length >= index + 1
      ) {
        relation.push({
          targetId: workloadNodesList[index + 1]
            ? workloadNodesList[index + 1].nodeId
            : `branch-${workflowNode.id}`,
          targetAnchor: "top",
          sourceAnchor: "bottom",
          style: {
            strokeWidth: 2,
            arrowLength: 2,
            strokeColor: workloadNodesList[index + 1] ? blue700 : "#d1d5da"
          }
        });
      }
    }

    return relation;
  };

  return (
    <>
      <Styled.Row index={index} key={`row-${workflowNodesList[index].id}`}>
        <ArcherElement
          key={workflowNode.id}
          id={workflowNode.id}
          relations={generateRelation("main")}
          style={{ marginRight: 40 }}
        >
          <AQETabWorkflowNodeCard
            title={
              version === "V1"
                ? workflowNode.jobDescription.jobTemplate.name
                : workflowNode.name
            }
            state={currentState}
            params={currentParams}
            results={currentResults}
          />
        </ArcherElement>
        {errorNode && (
          <ArcherElement
            key={errorNodeId}
            id={errorNodeId}
            relations={generateRelation("error")}
          >
            <AQETabWorkflowNodeCard
              archorPosition="left"
              title={errorNode.jobDescription.jobTemplate.name}
              state={errorInWorkload ? errorInWorkload.state : void 0}
              results={errorInWorkload ? errorInWorkload.results : void 0}
            />
          </ArcherElement>
        )}
      </Styled.Row>
      {version === "V2" &&
        workflowNode.transitions.length > 0 &&
        workloadNodesList.length - 1 === index && (
          <Styled.Row
            index={index}
            key={`row-${workflowNodesList[index].id}-error`}
          >
            <ArcherElement
              key={`branch-${workflowNode.id}`}
              id={`branch-${workflowNode.id}`}
              style={{ marginRight: 40, marginTop: 40 }}
            >
              <AQETabWorkflowNodeCard title={"More Branches..."} />
            </ArcherElement>
          </Styled.Row>
        )}
    </>
  );
}

AQETabWorkflowNode.propTypes = {
  index: PropTypes.number.isRequired,
  workflowNodesList: PropTypes.array.isRequired,
  workloadNodesList: PropTypes.array,
  outputParameters: PropTypes.array,
  currentOutputParam: PropTypes.object
};
