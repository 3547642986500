import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  width: 100%;

  @media screen and (max-height: 700px) {
    padding: 10px;
  }

  @media screen and (min-height: 700px) and (max-height: 750px) {
    padding: 15px;
  }
`;
