import React, { useEffect, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { ICSSProps, automationConfigsStyles } from "./Styles";
import { success } from "./data";
import WarningIcon from "@material-ui/icons/Warning";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import AutomationConfigsDetails from "./automationConfigs/AutomationConfigsDetails";

const AutomationConfigsContainer: React.FC<ICSSProps<
  typeof automationConfigsStyles
>> = React.memo(({ classes }) => {
  const [results, setResults] = useState<any>([]);
  const time = success.time * 0.001;

  useEffect(() => {
    if (success.mapping.results.length > 0) {
      setResults(success.mapping.results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.rootContainer}>
      <Grid container className={classes.automationConfigsContainer}>
        <Grid item className={classes.automationTitle}>
          Mapping
        </Grid>
        <Grid item className={classes.executionTime}>
          {time} <span style={{ fontSize: "0.9em" }}>seconds</span>
        </Grid>
        <Grid item className={classes.warningSign}>
          <WarningIcon
            data-testid="warning-icon"
            style={{ fontSize: "1.2em" }}
          />
        </Grid>
        <Grid item className={classes.warningMessage}>
          Multipe mappings triggered
        </Grid>
        <Grid item className={classes.manageMappingButton}>
          Manage Mappings
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            className={classes.faExternalLinkAlt}
          />
        </Grid>
      </Grid>

      {results.length !== 0 ? (
        <AutomationConfigsDetails
          classes={classes}
          results={results}
          theme={undefined}
        />
      ) : (
        <></>
      )}
    </div>
  );
});

export default withStyles(automationConfigsStyles)(AutomationConfigsContainer);
