import React from "react";
import PropTypes from "prop-types";
import InfoPanel from "../../components/InfoPanel";
import { StyledDiv } from "./styles";

const UsecaseInfo = props => {
  const { handleChange, workflows } = props;
  return (
    <InfoPanel title="Selected use cases" onEdit={() => handleChange(3)}>
      {workflows.map((workflow, index) => (
        <StyledDiv key={`${workflow.name}-${index}`}>{workflow.name}</StyledDiv>
      ))}
    </InfoPanel>
  );
};

UsecaseInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  workflows: PropTypes.array.isRequired
};

export default UsecaseInfo;
