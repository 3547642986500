import { connect } from "react-redux";

import {
  TogglePrimaryCheckBox,
  ToggleSecondaryCheckBox
} from "redux/modules/ticketTemplate";
import TicketTemplateViewerTemplateFieldsCheckbox from "./Checkbox";

const mapStateToProps = (state, ownProps) => {
  const currentTab = state.ticketTemplates.currentTab;
  const primaryFields = state.ticketTemplates.currentTemplate.primaryFields;
  const secondaryFields = state.ticketTemplates.currentTemplate.secondaryFields;
  return {
    currentTab: currentTab,
    isChecked:
      [...primaryFields, ...secondaryFields].filter(
        field => field.name === ownProps.item.name
      ).length > 0,
    isDisabled:
      (currentTab === 0 &&
        secondaryFields.filter(field => field.name === ownProps.item.name)
          .length > 0) ||
      (currentTab === 1 &&
        primaryFields.filter(field => field.name === ownProps.item.name)
          .length > 0)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    TogglePrimaryCheckBox: (item, value) =>
      dispatch(TogglePrimaryCheckBox(item, value)),
    ToggleSecondaryCheckBox: (item, value) =>
      dispatch(ToggleSecondaryCheckBox(item, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateViewerTemplateFieldsCheckbox);
