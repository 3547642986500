import config from "../config";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

// Fetches list of available Bulk Actions
// Response: Array of Strings

export const GET_BULK_ACTIONS_LIST_PENDING = "GET_BULK_ACTIONS_LIST_PENDING";
export const GET_BULK_ACTIONS_LIST_SUCCESS = "GET_BULK_ACTIONS_LIST_SUCCESS";
export const GET_BULK_ACTIONS_LIST_ERROR = "GET_BULK_ACTIONS_LIST_ERROR";

export function getBulkActionsList() {
  return (dispatch, getState) => {
    dispatch({ type: GET_BULK_ACTIONS_LIST_PENDING });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/bulk-action`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: GET_BULK_ACTIONS_LIST_SUCCESS,
          bulkActionList: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_BULK_ACTIONS_LIST_ERROR
        });
      });
  };
}

// Triggers Bulk Action for a bunch of tickets
// Response: Object of created Bulk Action

export const SET_BULK_ACTION_TYPE = "SET_BULK_ACTION_TYPE";
export const SET_BULK_ACTION_TICKETS = "SET_BULK_ACTION_TICKETS";
export const TRIGGER_BULK_ACTION_PENDING = "TRIGGER_BULK_ACTION_PENDING";
export const TRIGGER_BULK_ACTION_SUCCESS = "TRIGGER_BULK_ACTION_SUCCESS";
export const TRIGGER_BULK_ACTION_ERROR = "TRIGGER_BULK_ACTION_ERROR";

export function updateBulkActionType(bulkActionType) {
  return dispatch => {
    dispatch({ type: SET_BULK_ACTION_TYPE, bulkActionType: bulkActionType });
  };
}

export function updateBulkActionTickets(selectedTickets) {
  return dispatch => {
    dispatch({
      type: SET_BULK_ACTION_TICKETS,
      selectedTickets: selectedTickets
    });
  };
}

export function triggerBulkAction(bulkActionPayload) {
  return (dispatch, getState) => {
    dispatch({
      type: TRIGGER_BULK_ACTION_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/bulk-action`;

    const payloadConvertor = bulkActionPayload => {
      const payload = {
        action: bulkActionPayload.bulkActionType,
        tickets: bulkActionPayload.selectedTickets
      };
      return payload;
    };

    return axiosSnackbar
      .post(url, payloadConvertor(bulkActionPayload))
      .then(res => {
        dispatch({
          type: TRIGGER_BULK_ACTION_SUCCESS
        });
      })
      .catch(err => {
        dispatch({
          type: TRIGGER_BULK_ACTION_ERROR
        });
      });
  };
}

// Fetches list of on-going and completed Bulk Action Tasks
// Response: Array of Task Objects

export const GET_BULK_ACTIONS_TASKS_PENDING = "GET_BULK_ACTIONS_TASKS_PENDING";
export const GET_BULK_ACTIONS_TASKS_SUCCESS = "GET_BULK_ACTIONS_TASKS_SUCCESS";
export const GET_BULK_ACTIONS_TASKS_ERROR = "GET_BULK_ACTIONS_TASKS_ERROR";

export function getBulkActionTasks(page = 0, perPage = 10) {
  return (dispatch, getState) => {
    dispatch({ type: GET_BULK_ACTIONS_TASKS_PENDING });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/bulk-action/task`;

    const params = {
      page,
      perPage,
      sort: "created,desc"
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_BULK_ACTIONS_TASKS_SUCCESS,
          bulkActionTasks: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: GET_BULK_ACTIONS_TASKS_ERROR
        });
      });
  };
}

// Cancels a triggered Bulk Action

export const SET_BULK_ACTION_TASK_TO_CANCEL = "SET_BULK_ACTION_TASK_TO_CANCEL";
export const CANCEL_BULK_ACTION_PENDING = "CANCEL_BULK_ACTION_PENDING";
export const CANCEL_BULK_ACTION_SUCCESS = "CANCEL_BULK_ACTION_SUCCESS";
export const CANCEL_BULK_ACTION_ERROR = "CANCEL_BULK_ACTION_ERROR";

export function cancelBulkAction(taskId) {
  return (dispatch, getState) => {
    dispatch({ type: CANCEL_BULK_ACTION_PENDING });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/bulk-action/cancel`;

    const body = {
      taskIds: [taskId]
    };

    return axiosSnackbar
      .post(url, body)
      .then(res => {
        dispatch({
          type: CANCEL_BULK_ACTION_SUCCESS
        });
      })
      .catch(err => {
        dispatch({
          type: CANCEL_BULK_ACTION_ERROR
        });
      });
  };
}

export const OPEN_BULK_ACTION_DRAWER = "OPEN_BULK_ACTION_DRAWER";
export const CLOSE_BULK_ACTION_DRAWER = "CLOSE_BULK_ACTION_DRAWER";

export function handleBulkActionDrawer(drawerState, dispatch) {
  if (drawerState) {
    dispatch({ type: OPEN_BULK_ACTION_DRAWER });
  } else {
    dispatch({ type: CLOSE_BULK_ACTION_DRAWER });
  }
}
