import React from "react";
import ATRFormField from "../../../../templates/ATRFormField";
import SecondaryTextField from "../../../../templates/SecondaryTextField";
import { FormHelperText, Switch, FormControlLabel } from "@material-ui/core";
import _ from "lodash";
import MenuItem from "material-ui/MenuItem";
import SecondaryDropDownMenu from "../../../../templates/SecondaryDropDownMenu";
import PrimaryDropDownMenu from "../../../../templates/PrimaryDropDownMenu";

const PluginDrawerConfigField = React.memo(props => {
  const { pluginField, updateConfig, error } = props;
  let field;
  const isBooleanField = pluginField.field.type === "boolean";
  if (isBooleanField) {
    const booleanFieldValue =
      pluginField.value != null && pluginField.value.toLowerCase() === "true";
    field = (
      <FormControlLabel
        control={
          <Switch
            checked={booleanFieldValue}
            onChange={(e, newValue) => {
              updateConfig({
                pluginId: pluginField.id,
                value: newValue.toString()
              });
            }}
            value="activated"
            color="primary"
          />
        }
      />
    );
  } else if (!_.isEmpty(pluginField.field.values)) {
    field = (
      <PrimaryDropDownMenu
        id={pluginField.id}
        style={{ paddingLeft: "0px" }}
        value={!_.isEmpty(pluginField.value) ? pluginField.value : ""}
        onChange={(event, index, value) =>
          updateConfig({
            pluginId: pluginField.id,
            value: value
          })
        }
      >
        {pluginField.field.values.map(value => (
          <MenuItem key={value} value={value} primaryText={value} />
        ))}
      </PrimaryDropDownMenu>
    );
  } else {
    field = (
      <SecondaryTextField
        id={pluginField.id}
        fullWidth
        value={pluginField.value || ""}
        onChange={e =>
          updateConfig({
            pluginId: pluginField.id,
            value: e.target.value
          })
        }
      />
    );
  }
  return (
    <div style={{ width: "92%", margin: "auto" }}>
      <ATRFormField
        formLabel={`${pluginField.field.displayName}${
          pluginField.field.mandatory ? "*" : ""
        }`}
        xs={3}
        description={pluginField.field.description}
      >
        {field}
        {error && (
          <FormHelperText style={{ color: "red" }}>
            Error: {error}
          </FormHelperText>
        )}
      </ATRFormField>
    </div>
  );
});

export default PluginDrawerConfigField;
