import { takeLatest, put, call } from "redux-saga/effects";
import { GET_TICKET_FIELD } from "redux/modules/ticketField";
import {
  getTicketFieldPending,
  getTicketFieldSuccess,
  getTicketFieldError
} from "redux/modules/ticketField";

import config from "../../config";
import { axiosInstance as axios } from "../../utils/axios";

const getTicketField = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/fields/tickets`;
  return axios.get(url);
};

export const ticketFieldSagas = [
  takeLatest(GET_TICKET_FIELD, getTicketFieldSaga)
];

function* getTicketFieldSaga() {
  yield put(getTicketFieldPending);

  try {
    const { data } = yield call(getTicketField);
    yield put(getTicketFieldSuccess(data));
  } catch (err) {
    yield put(getTicketFieldError());
  }
}
