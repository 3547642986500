import { connect } from "react-redux";
import { closeSnackbar, openSnackbar } from "actions/snackbar";
import { downloadAttachments } from "actions/ticketAttachments";
import AttachmentField from "../index";

const mapStateToProps = (state, ownProps) => ({
  ticket: ownProps.ticket,
  token: state.auth.token.token,
  isSnackbarOpen: state.snackbar.isOpen
});

const mapDispatchToProps = dispatch => ({
  openSnackbar: message => dispatch(openSnackbar(message)),
  closeSnackbar: () => dispatch(closeSnackbar()),
  downloadAttachments: (ticketNumber, attachmentId) =>
    dispatch(downloadAttachments(ticketNumber, attachmentId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentField);
