import React, { Component } from "react";
import { Grid, Row, Col } from "react-flexbox-grid/lib";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { CardContent } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { blue300, green500 } from "material-ui/styles/colors";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from "@material-ui/core/styles";
import config from "../../config";
import history from "../../history";
import { isEmpty } from "lodash";
import PrimaryButton from "../templates/PrimaryButton";
import { axiosInstance as axios } from "../../utils/axios";

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans",sans-serif'
  },
  palette: {
    primary: {
      light: "#BBDEFB",
      main: "#1E88E5",
      dark: "#0D47A1",
      contrastText: "#fff"
    }
  }
});

const styles = theme => ({
  typography: {
    fontFamily: '"Open Sans", sans-serif'
  },
  card: {
    margin: "10px 5px",
    height: 60,
    cursor: "pointer"
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  progressCard: {
    marginTop: 80,
    height: 180
  },
  stepper: {
    paddingTop: 15
  },
  progressBar: {
    marginTop: 10,
    marginBottom: 15,
    paddingTop: 10,
    borderRadius: 10,
    bar: {
      borderRadius: 10
    }
  },
  bar: { borderRadius: 10 }
});
class AutomationTour extends Component {
  constructor() {
    super();
    this.state = {
      progress: 14,
      mlDB: false,
      mlModel: false,
      mlMapping: false,
      autoScript: false,
      // manualScript: false,
      autoMapping: false,
      autoRule: false
    };
  }

  componentDidMount() {
    this.getMLDB();
    this.intervalMLDB = setInterval(() => {
      this.getMLDB();
    }, 2000);
    this.getMLModel();
    this.intervalMLModel = setInterval(() => {
      this.getMLModel();
    }, 2000);
    this.getMLMapping();
    this.intervalMLMapping = setInterval(() => {
      this.getMLMapping();
    }, 2000);
    this.getAutoScript();
    this.intervalAutoScript = setInterval(() => {
      this.getAutoScript();
    }, 2000);
    // this.getManualScript();
    // this.intervalManualScript = setInterval(() => {
    //   this.getManualScript();
    // }, 2000);
    this.getAutoMapping();
    this.intervalAutoMapping = setInterval(() => {
      this.getAutoMapping();
    }, 2000);
    this.getAutoRule();
    this.intervalAutoRule = setInterval(() => {
      this.getAutoRule();
    }, 2000);

    this.calcProgress();
  }

  // componentDidUpdate(preProps, prevState) {
  //   if (this.state.progress === 100) {
  //     const { userProfile } = this.props;
  //     console.log(userProfile);
  //     userProfile.finishedJourney = true;
  //     userProfile.password = "";
  //     this.props.saveUser(userProfile);
  //   }
  // }

  componentWillUnmount() {
    window.clearInterval(this.intervalMLDB);
    window.clearInterval(this.intervalMLModel);
    window.clearInterval(this.intervalMLMapping);
    window.clearInterval(this.intervalAutoScript);
    // window.clearInterval(this.intervalManualScript);
    window.clearInterval(this.intervalAutoMapping);
    window.clearInterval(this.intervalAutoRule);
  }

  redirectToAppsPage = url => {
    window.open(`/${url}`, "_blank");
  };

  isFinished = progress => {
    const { userProfile } = this.props;
    if (progress === 86 && userProfile.username === "admin") {
      const { userProfile } = this.props;
      const demo = { journey: true };
      this.props.updateUserDemo(demo, userProfile);
    }
  };
  getMLDB = async () => {
    const url = `${config.urls.baseML + config.urls.apis.ml}/datasources`;
    const params = {
      page: 0,
      perPage: 1
    };

    try {
      const res = await axios.get(url, { params });
      if (res.data.hasOwnProperty("count") && res.data.count) {
        this.setState({ mlDB: true }, () => {
          window.clearInterval(this.intervalMLDB);
          const progress = this.calcProgress();
          this.setState({ progress }, this.isFinished(this.state.progress));
        });
      }
    } catch (err) {
      // Supress axios interceptor error snackbar
      this.props.openSnackbar("");
    }
  };

  getMLModel = async () => {
    //only works in production mode
    const url = `${config.urls.baseML + config.urls.apis.ml}/models`;
    const params = {
      page: 0,
      perPage: 1
    };

    try {
      const res = await axios.get(url, { params });
      if (res.data.hasOwnProperty("count") && res.data.count) {
        this.setState({ mlModel: true }, () => {
          window.clearInterval(this.intervalMLModel);
          const progress = this.calcProgress();
          this.setState({ progress }, this.isFinished(this.state.progress));
        });
      }
    } catch (err) {
      // Supress axios interceptor error snackbar
      this.props.openSnackbar("");
    }
  };

  getMLMapping = async () => {
    const url =
      config.urls.base +
      config.urls.apis["ticket-management"] +
      "/config/machineLearningModels";

    try {
      const res = await axios.get(url);
      if (res.data.length !== 0) {
        this.setState({ mlMapping: true }, () => {
          window.clearInterval(this.intervalMLMapping);
          const progress = this.calcProgress();
          this.setState({ progress }, this.isFinished(this.state.progress));
        });
      }
    } catch (err) {
      // Supress axios interceptor error snackbar
      this.props.openSnackbar("");
    }
  };

  getRundeckProjs = async () => {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/automation/rundeck/projects`;

    const params = {
      rundeck: "Local Rundeck"
    };

    try {
      const res = await axios.get(url, { params });
      return res.data;
    } catch (err) {
      // Supress axios interceptor error snackbar
      this.props.openSnackbar("");
      return null;
    }
  };

  getRundeckJobs = async project => {
    const url = `${config.urls.base +
      config.urls.apis[
        "ticket-management"
      ]}/automation/rundeck/${project}/jobs`;

    const params = {
      rundeck: "Local Rundeck"
    };

    try {
      const res = await axios.get(url, { params });
      return res.data;
    } catch (err) {
      // Supress axios interceptor error snackbar
      this.props.openSnackbar("");
      return null;
    }
  };

  getAutoScript = async () => {
    const projs = await this.getRundeckProjs();
    if (!isEmpty(projs)) {
      for (let proj of projs) {
        const jobs = await this.getRundeckJobs(proj.name);
        if (!isEmpty(jobs)) {
          this.setState({ autoScript: true }, () => {
            window.clearInterval(this.intervalAutoScript);
            const progress = this.calcProgress();
            this.setState({ progress }, this.isFinished(this.state.progress));
          });
          break;
        }
      }
    }
  };

  getAutoMapping = async () => {
    const url =
      config.urls.base +
      config.urls.apis["ticket-management"] +
      "/servicejobmapping";

    const params = {
      page: 0,
      perPage: 1
    };

    try {
      const res = await axios.get(url, { params });
      if (res.data.length) {
        this.setState({ autoMapping: true }, () => {
          window.clearInterval(this.intervalAutoMapping);
          const progress = this.calcProgress();
          this.setState({ progress }, this.isFinished(this.state.progress));
        });
      }
    } catch (err) {
      // Supress axios interceptor error snackbar
      this.props.openSnackbar("");
    }
  };

  getAutoRule = async () => {
    const url =
      config.urls.base + config.urls.apis["ticket-management"] + "/rules";

    const params = {
      page: 0,
      perPage: 1
    };

    try {
      const res = await axios.get(url, { params });
      if (res.data.length) {
        this.setState({ autoRule: true }, () => {
          window.clearInterval(this.intervalAutoRule);
          const progress = this.calcProgress();
          this.setState({ progress }, this.isFinished(this.state.progress));
        });
      }
    } catch (err) {
      // Supress axios interceptor error snackbar
      this.props.openSnackbar("");
    }
  };

  calcProgress = () => {
    const doneNum =
      Object.values(this.state)
        .slice(1)
        .filter(item => item).length + 1;
    const progress = Math.round((doneNum / 7) * 100);
    return progress;
  };

  render() {
    const { classes } = this.props;
    const {
      progress,
      mlDB,
      mlModel,
      mlMapping,
      autoScript,
      // manualScript,
      autoMapping,
      autoRule
    } = this.state;
    return (
      <div
        style={{
          overflow: "auto",
          height: "calc(100vh - 60px)"
        }}
      >
        <MuiThemeProvider theme={theme}>
          <Grid>
            <Row>
              <Col md={8} xs={12}>
                <div style={{ marginTop: 30 }}>
                  <div>
                    <Typography variant="title">
                      <i className="fa fa-rocket" /> Welcome Guide for Your
                      Automation Journey
                    </Typography>
                    <div style={{ paddingTop: 5, marginLeft: 25 }}>
                      <Typography variant="caption">
                        Take this quick welcome start guide and discover how to
                        configure ATR for your easy automation journey
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Stepper orientation="vertical" className={classes.stepper}>
                      {/* Step: Ticket Platform */}
                      <Step active completed>
                        <StepLabel>
                          <Typography variant="body2">
                            Ticket Platform
                          </Typography>
                        </StepLabel>
                        <StepContent>
                          <Card
                            className={classes.card}
                            onClick={() =>
                              this.redirectToAppsPage("atr/settings")
                            }
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                <div
                                  style={{
                                    fontSize: 25,
                                    paddingRight: 8,
                                    color: green500
                                  }}
                                >
                                  <i className="fa fa-check-circle" />
                                </div>
                                Select Ticketing Platform
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card>
                        </StepContent>
                      </Step>
                      {/* Step: Machine Learning Setup */}
                      <Step active completed={mlDB && mlModel && mlMapping}>
                        <StepLabel>
                          <Typography variant="body2">
                            Machine Learning Setup
                          </Typography>
                        </StepLabel>
                        <StepContent>
                          <Card
                            className={classes.card}
                            onClick={() => this.redirectToAppsPage("core/data")}
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                {this.state.mlDB ? (
                                  <div
                                    style={{
                                      fontSize: 25,
                                      paddingRight: 8,
                                      color: green500
                                    }}
                                  >
                                    <i className="fa fa-check-circle" />
                                  </div>
                                ) : (
                                  <img
                                    src={`${config.app.images}/ingrain_colour.png`}
                                    alt="rules"
                                    style={{
                                      maxHeight: 25,
                                      paddingRight: 7,
                                      width: 25
                                    }}
                                  />
                                )}
                                Upload a dataset to Machine Learning
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card>
                          <Card
                            className={classes.card}
                            onClick={() =>
                              this.redirectToAppsPage("core/pipelines")
                            }
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                {this.state.mlModel ? (
                                  <div
                                    style={{
                                      fontSize: 25,
                                      paddingRight: 8,
                                      color: green500
                                    }}
                                  >
                                    <i className="fa fa-check-circle" />
                                  </div>
                                ) : (
                                  <img
                                    src={`${config.app.images}/ingrain_colour.png`}
                                    alt="rules"
                                    style={{
                                      maxHeight: 25,
                                      paddingRight: 7,
                                      width: 25
                                    }}
                                  />
                                )}
                                Build Machine Learning Models
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card>
                          <Card
                            className={classes.card}
                            onClick={() =>
                              this.redirectToAppsPage("atr/settings")
                            }
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                {this.state.mlMapping ? (
                                  <div
                                    style={{
                                      fontSize: 25,
                                      paddingRight: 8,
                                      color: green500
                                    }}
                                  >
                                    <i className="fa fa-check-circle" />
                                  </div>
                                ) : (
                                  <img
                                    src={`${config.app.images}/ticket.png`}
                                    alt="rules"
                                    style={{
                                      maxHeight: 25,
                                      paddingRight: 7,
                                      width: 25
                                    }}
                                  />
                                )}
                                Map Machine Learning models to Ticket Management
                                fields
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card>
                        </StepContent>
                      </Step>
                      {/* Step: Solution Script */}
                      <Step active completed={autoScript}>
                        <StepLabel>
                          <Typography variant="body2">
                            Solution Script
                          </Typography>
                        </StepLabel>
                        <StepContent>
                          <Card
                            className={classes.card}
                            onClick={() => this.redirectToAppsPage("rundeck")}
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                {this.state.autoScript ? (
                                  <div
                                    style={{
                                      fontSize: 25,
                                      paddingRight: 8,
                                      color: green500
                                    }}
                                  >
                                    <i className="fa fa-check-circle" />
                                  </div>
                                ) : (
                                  <img
                                    src={`${config.app.images}/automationScript.png`}
                                    alt="rules"
                                    style={{
                                      maxHeight: 25,
                                      paddingRight: 7,
                                      width: 25
                                    }}
                                  />
                                )}
                                Add Automation Scripts to Rundeck
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card>
                          {/* <Card
                            className={classes.card}
                            onClick={() =>
                              this.redirectToAppsPage("atr/settings")
                            }
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                {this.state.manualScript ? (
                                  <div
                                    style={{
                                      fontSize: 25,
                                      paddingRight: 8,
                                      color: green500
                                    }}
                                  >
                                    <i className="fa fa-check-circle" />
                                  </div>
                                ) : (
                                  <img
                                    src={`${
                                      config.app.images
                                    }/manualScript.png`}
                                    alt="rules"
                                    style={{
                                      maxHeight: 25,
                                      paddingRight: 7,
                                      width: 25
                                    }}
                                  />
                                )}
                                Add in manual script
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card> */}
                        </StepContent>
                      </Step>
                      {/* Step: Automation Process Setup */}
                      <Step active completed={autoMapping && autoRule}>
                        <StepLabel>
                          <Typography variant="body2">
                            Automation Process Setup
                          </Typography>
                        </StepLabel>
                        <StepContent>
                          <Card
                            className={classes.card}
                            onClick={() =>
                              this.redirectToAppsPage(
                                "atr/settings/automation/mapping"
                              )
                            }
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                {this.state.autoMapping ? (
                                  <div
                                    style={{
                                      fontSize: 25,
                                      paddingRight: 8,
                                      color: green500
                                    }}
                                  >
                                    <i className="fa fa-check-circle" />
                                  </div>
                                ) : (
                                  <img
                                    src={`${config.app.images}/rundeckLogo.png`}
                                    alt="rules"
                                    style={{
                                      maxHeight: 25,
                                      paddingRight: 7,
                                      width: 25
                                    }}
                                  />
                                )}
                                Create Automation Mappings
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card>
                          <Card
                            className={classes.card}
                            onClick={() =>
                              this.redirectToAppsPage(
                                "atr/settings/automation/rules"
                              )
                            }
                          >
                            <CardContent className={classes.cardContent}>
                              <Typography
                                variant="subheading"
                                style={{ display: "flex" }}
                              >
                                {this.state.autoRule ? (
                                  <div
                                    style={{
                                      fontSize: 25,
                                      paddingRight: 8,
                                      color: green500
                                    }}
                                  >
                                    <i className="fa fa-check-circle" />
                                  </div>
                                ) : (
                                  <img
                                    src={`${config.app.images}/automationRule.png`}
                                    alt="rules"
                                    style={{
                                      maxHeight: 25,
                                      paddingRight: 7,
                                      width: 25
                                    }}
                                  />
                                )}
                                Create Automation Rules
                              </Typography>
                              <div style={{ color: blue300, fontSize: 20 }}>
                                <i className="fa fa-arrow-circle-right" />
                              </div>
                            </CardContent>
                          </Card>
                        </StepContent>
                      </Step>
                    </Stepper>
                  </div>
                </div>
              </Col>

              <Col md={4} xs={12}>
                <Card className={classes.progressCard}>
                  <CardContent>
                    <strong>Progress: {progress}%</strong>
                    <LinearProgress
                      className={classes.progressBar}
                      classes={{
                        bar: classes.bar
                      }}
                      variant="determinate"
                      value={progress}
                    />
                    <Typography variant="subheading">
                      Need More Help?
                    </Typography>
                    <Typography variant="caption">
                      If you have any questions, you can contact chatbot and ask
                      how, or contact one of our support team member for further
                      assistance.
                    </Typography>
                  </CardContent>
                </Card>
                <PrimaryButton
                  label={<strong>Dismiss</strong>}
                  style={{ marginTop: 15 }}
                  onClick={() => {
                    const { userProfile } = this.props;
                    if (userProfile.username === "admin") {
                      const demo = { journey: true };
                      this.props.updateUserDemo(demo, userProfile);
                    }
                    history.push("/atr/tickets");
                  }}
                />
              </Col>
            </Row>
          </Grid>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(AutomationTour);
