import { connect } from "react-redux";
import { setTicketType } from "actions/tickets";
import FinalPage from "../index";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setTicketType: type => dispatch(setTicketType(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalPage);
