import React from "react";
import RulesStyles from "../rules/RulesStyles";
import ATRFormField from "../templates/ATRFormField";
import SecondaryTextField from "../templates/SecondaryTextField";
import { Row, Col } from "react-flexbox-grid/lib";
import { Divider, FontIcon, MenuItem, Paper } from "material-ui";
import PrimaryButton from "../templates/PrimaryButton";
import muiTheme from "../../styles/themeMainUI";
import SecondaryCheckbox from "../templates/SecondaryCheckbox";
import PrimaryDropDownMenu from "../templates/PrimaryDropDownMenu";
import SecondaryAutoComplete from "../templates/SecondaryAutoComplete";
import _ from "lodash";

// TODO: Don't use RulesStyle and move style to SASS
const suggestedTag = {
  fontSize: 12,
  color: muiTheme.palette.accent3Color,
  marginTop: 5
};

export const formFields = {
  name: "",
  description: "",
  group: "",
  key: "",
  value: "user_defined",
  type: "INCIDENT",
  required: false
};

const REQUIRED = {
  name: false,
  description: false,
  group: false,
  key: false,
  value: false,
  type: false
};

export default class MergeTagForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formFields: { ...formFields },
      required: { ...REQUIRED }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedMergeTag !== this.props.selectedMergeTag) {
      this.setState({
        formFields: nextProps.selectedMergeTag
      });
    }
  }

  // Removes annoying errors caused by drawing closing and setting formFields as undefined on componentWillReceiveProps
  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEmpty(nextState.formFields);
  }

  _handleChange(key, value) {
    this.setState({
      formFields: { ...this.state.formFields, [key]: value }
    });
  }

  _handleUpdateInput(q) {
    this.props.getMergeTagAutoComplete("group", q);
    this._handleChange("group", q);
  }

  _handleSaveButtonClick() {
    // Form validation
    const form = this.state.formFields;
    const required = { ...REQUIRED };

    if (form.name === "") {
      required.name = true;
    }

    if (form.key === "") {
      required.key = true;
    }

    if (form.value === "") {
      required.value = true;
    }

    if (form.type === "") {
      required.type = true;
    }

    const incorrectFilled = Object.values(required).findIndex(x => x === true);
    if (incorrectFilled !== -1) {
      return this.setState({
        required
      });
    }

    if (form.new) {
      this.props.createMergeTag(form);
    } else {
      this.props.updateMergeTag(form);
    }

    this.props.closeMergeTag();
  }

  render() {
    const ticketTypes = this.props.activeTemplates
      ? Object.keys(this.props.activeTemplates)
          .filter(ticketType => {
            return this.props.activeTemplates[ticketType].ticketSource
              .canAutomate;
          })
          .map(ticketType => {
            return {
              type: ticketType,
              displayName: this.props.activeTemplates[ticketType].ticketSource
                .displayName
            };
          })
      : [];
    return (
      <div style={RulesStyles.mainWrapper}>
        <div style={RulesStyles.contentWrapper}>
          <div style={RulesStyles.contentHeader}>
            <Row style={{ display: "flex", alignItems: "flex-start" }}>
              <Col xs={12}>
                <h1 style={RulesStyles.ticketNumber}>New Merge Tag</h1>
              </Col>
            </Row>

            <ATRFormField formLabel="Name">
              <SecondaryTextField
                name="name"
                fullWidth
                requiredError={this.state.required.name}
                value={this.state.formFields.name}
                onChange={(e, v) => this._handleChange("name", v)}
              />
            </ATRFormField>

            <ATRFormField formLabel="Description">
              <SecondaryTextField
                name="description"
                fullWidth
                value={this.state.formFields.description}
                onChange={(e, v) => this._handleChange("description", v)}
              />
            </ATRFormField>

            <ATRFormField formLabel="Group">
              <SecondaryAutoComplete
                name="group"
                fullWidth
                searchText={this.state.formFields.group}
                onUpdateInput={searchText =>
                  this._handleUpdateInput(searchText)
                }
                onNewRequest={chosenRequest =>
                  this._handleChange("group", chosenRequest)
                }
                dataSource={this.props.autocompleteFields}
              />
            </ATRFormField>

            <ATRFormField formLabel="Merge Tag">
              <div>
                <SecondaryTextField
                  name="tag"
                  fullWidth
                  requiredError={this.state.required.key}
                  value={this.state.formFields.key}
                  onChange={(e, v) => this._handleChange("key", v)}
                />
                <div style={suggestedTag}>
                  This will be the label you refer to when using this merge tag
                  in a email template. An example label could be{" "}
                  <b>INCIDENT_NO</b> to refer to a ticket's incident number.
                </div>
              </div>
            </ATRFormField>

            <ATRFormField formLabel="Ticket Type">
              <PrimaryDropDownMenu
                value={this.state.formFields.type}
                onChange={(e, k, v) => this._handleChange("type", v)}
              >
                {ticketTypes.map(type => {
                  return (
                    <MenuItem
                      key={type.displayName}
                      value={type.type}
                      primaryText={type.displayName}
                    />
                  );
                })}
              </PrimaryDropDownMenu>
            </ATRFormField>

            <ATRFormField formLabel="Field Mapping">
              <PrimaryDropDownMenu
                value={this.state.formFields.value}
                requiredError={this.state.required.value}
                onChange={(e, k, v) => this._handleChange("value", v)}
              >
                <MenuItem value="user_defined" primaryText="User Defined" />
                {/* Field mapping depends on which ticket type the user selects */}
                {this.props.templateFields &&
                  this.props.templateFields[this.state.formFields.type] &&
                  !_.isEmpty(
                    this.props.templateFields[this.state.formFields.type]
                      .availableFields
                  ) &&
                  this.props.templateFields[
                    this.state.formFields.type
                  ].availableFields.map((item, i) => (
                    <MenuItem
                      key={i}
                      value={item.name}
                      primaryText={item.displayName}
                    />
                  ))}
              </PrimaryDropDownMenu>
            </ATRFormField>

            <ATRFormField formLabel="Required">
              <div style={{ marginTop: 15 }}>
                <SecondaryCheckbox
                  checked={
                    this.state.formFields.required === "true" ? true : false
                  }
                  onCheck={(e, v) =>
                    this.setState({
                      formFields: {
                        ...this.state.formFields,
                        required: v ? "true" : "false"
                      }
                    })
                  }
                />
                <div style={suggestedTag}>
                  Whether this field is required when sending an email
                  containing this tag
                </div>
              </div>
            </ATRFormField>
          </div>
        </div>

        <Divider />
        <Paper style={RulesStyles.contentActionsContainer}>
          <div style={RulesStyles.contentActions}>
            <PrimaryButton
              onClick={() => this._handleSaveButtonClick()}
              label={
                <strong>
                  <FontIcon
                    className="fa fa-save"
                    style={{ fontSize: 14, color: "white" }}
                  />
                  &nbsp;Save
                </strong>
              }
            />
          </div>
        </Paper>
      </div>
    );
  }
}

MergeTagForm.propTypes = {};
