import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const MainContainer = styled.div`
  padding: 20px 0px;

  @media screen and (max-height: 768px) {
    padding: 5px 0px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    padding: 5px 0px;
  }
`;

export const StyledForm = styled.form`
  width: 500px;
`;

export const StyledButton = styled(Button)`
  margin-top: 10px !important;
  background-color: ${props =>
    props.connected ? "#00d287" : "#0085ff"} !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const ButtonText = styled.span`
  margin-right: 10px;
`;
