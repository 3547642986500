import React from "react";
import config from "../../config";

class Gamification extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      width: 0,
      height: 0
    };
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight - 100
    });
  }
  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    // return (<img src="/app/images/gamification"/>);
    return (
      <div style={{ backgroundColor: "#F2F2F2" }}>
        <p style={{ textAlign: "center" }}>
          <img
            src={`${config.app.images}/gamification_placeholder.png`}
            style={{ height: this.state.height }}
            alt=""
          />
        </p>
      </div>
    );
  }
}

export default Gamification;
