import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import { MainContainer, UseCaseContainer } from "./styles";
import UseCaseGroup from "./UseCaseGroup";

const UseCaseSelection = props => {
  const { workflowGroups, onSelectSingle } = props;
  return (
    <MainContainer>
      <UseCaseContainer container>
        {Object.entries(workflowGroups).map(([name, data]) => (
          <UseCaseGroup
            key={name}
            title={name}
            workflows={data.workflows}
            onSelectSingle={onSelectSingle}
          />
        ))}
        {Object.entries(workflowGroups).length === 0 &&
          [0, 1, 2].map(i => (
            <Grid item xs={12} key={i}>
              <Skeleton height={70} />
            </Grid>
          ))}
      </UseCaseContainer>
    </MainContainer>
  );
};

UseCaseSelection.propTypes = {
  workflowGroups: PropTypes.object.isRequired,
  onSelectSingle: PropTypes.func.isRequired
};

export default UseCaseSelection;
