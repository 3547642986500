import React from "react";
import styled from "styled-components";
import { Tab, Tabs } from "@material-ui/core";

export const StyledTab = styled(Tab)`
  min-width: 220px !important;
  .label-container {
    padding: 6px 12px !important;
  }

  @media screen and (max-width: 1120px) {
    min-width: 130px !important;
    max-width: 130px !important;

    .label-container {
      padding: 6px 8px !important;
    }
  }

  @media screen and (min-width: 1120px) and (max-width: 1400px) {
    min-width: 160px !important;
    max-width: 160px !important;
  }

  @media screen and (max-height: 768px) {
    min-width: 130px !important;
    max-width: 130px !important;
  }
  @media screen and (min-height: 769px) and (max-height: 850px) {
    min-width: 160px !important;
    max-width: 160px !important;
  }
`;

export const StyledTabs = styled(Tabs)`
  display: flex;
  justify-content: center;
  border-radius: 3px;
  width: 100% !important;
  .tab-indicator {
    background: linear-gradient(90deg, #4289dd 0%, #65d1ff 100%) !important;
    height: 5px;
  }
`;
