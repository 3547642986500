import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import helperComponents from "./helperComponents";

const SelectAutoComplete = props => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: props.isDisabled ? "#AAAAAA" : theme.palette.text.primary
    })
  };
  const { classes, theme, ...rest } = props;

  return (
    <div className={classes.root}>
      <Select
        menuPlacement="auto"
        classes={classes}
        styles={selectStyles}
        components={helperComponents}
        isClearable
        isSearchable
        {...rest}
      />
    </div>
  );
};

SelectAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(SelectAutoComplete);
