export const states = {
  LOADING: "LOADING",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  DEFAULT: "DEFAULT"
};

export const USER_UPDATE_PROGRESSING = "USER_UPDATE_PROGRESSING";
export const USER_UPDATE_FAILED = "USER_CREATION_FAILED";
export const USER_UPDATE_SUCCEEDED = "USER_UPDATE_SUCCEEDED";

export const USER_DELETION_PROGRESSING = "USER_DELETION_PROGRESSING";
export const USER_DELETION_FAILED = "USER_DELETION_FAILED";
export const USER_DELETION_SUCCEEDED = "USER_DELETION_SUCCEEDED";

export const userUpdateProgressing = () => ({
  type: USER_UPDATE_PROGRESSING,
  state: states.LOADING,
  message: "Updating user..."
});

export const userUpdateFailed = () => ({
  type: USER_UPDATE_FAILED,
  state: states.FAILED,
  message: "Could not update user."
});

export const userUpdateSucceeded = () => ({
  type: USER_UPDATE_SUCCEEDED,
  state: states.SUCCESS,
  message: "User updated!"
});

export const userDeletionProgressing = () => ({
  type: USER_DELETION_PROGRESSING,
  state: states.LOADING,
  message: "Deleting user..."
});

export const userDeletionFailed = () => ({
  type: USER_DELETION_FAILED,
  state: states.FAILED,
  message: "Could not delete user."
});

export const userDeletionSucceeded = () => ({
  type: USER_DELETION_SUCCEEDED,
  state: states.SUCCESS,
  message: "User deleted!"
});
