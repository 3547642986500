import React from "react";
import FontIcon from "material-ui/FontIcon";

const ErrorBoxContainerStyles = {
  margin: 40,
  color: "#909090",
  textAlign: "center"
};

class ErrorBox extends React.Component {
  render() {
    return (
      <div style={ErrorBoxContainerStyles}>
        <div>
          <div>
            <FontIcon
              className="fa fa-exclamation-triangle"
              style={{ fontSize: 60, color: "#909090" }}
            />
          </div>
          <br />
          <p>Something went wrong.</p>
          <p>{this.props.errorMessage ? this.props.errorMessage : ""}</p>
          <br />
        </div>
      </div>
    );
  }
}

export default ErrorBox;
