import {
  OPEN_CHATBOT,
  CLOSE_CHATBOT,
  INSERT_MESSAGE
} from "../actions/chatbot";

function chatbot(
  state = {
    isOpen: false,
    chatbotBadge: 1,
    messages: [
      {
        type: "chatbot",
        name: "myWizard AiOps Helpdesk",
        user: "chatbot",
        text:
          "Hello and welcome to the myWizard AiOps Helpdesk! Go ahead and ask me a question.",
        date: Date.now()
      }
    ]
  },
  action
) {
  switch (action.type) {
    case OPEN_CHATBOT:
      return Object.assign({}, state, {
        isOpen: true,
        chatbotBadge: 0
      });
    case CLOSE_CHATBOT:
      return Object.assign({}, state, {
        isOpen: false
      });
    case INSERT_MESSAGE:
      return Object.assign({}, state, {
        messages: [...state.messages, action.message]
      });
    default:
      return state;
  }
}

export default chatbot;
