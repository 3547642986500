import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Grid,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import styled from "styled-components";
import BulkActionList from "components/page/BulkActions/BulkActionList";
import Pagination from "./Pagination";

const MainContainer = styled.div`
  padding: 50px 210px;
  height: ${window.innerHeight - 190}px;
`;

const Title = styled(Typography)`
  font-weight: normal !important;
  align-items: center !important;
  display: flex !important;
  margin-left: 10px !important;
`;

const BulkActions = props => {
  const {
    getBulkActionTasks,
    isFetchingBulkActionTasks,
    bulkActionTasks,
    cancelBulkAction,
    openSnackbar,
    closeSnackbar,
    isSnackbarOpen,
    timezone
  } = props;
  const [page, setPage] = useState(0);

  useEffect(() => {
    getBulkActionTasks(page);
  }, [getBulkActionTasks, page]);

  const handleRefresh = useCallback(() => {
    getBulkActionTasks(page);
    if (!isSnackbarOpen) {
      openSnackbar("Refreshed bulk actions");
      setTimeout(() => {
        closeSnackbar();
      }, 1500);
    }
  }, [getBulkActionTasks, openSnackbar, closeSnackbar]);

  const handleChange = value => {
    setPage(value);
  };

  return (
    <MainContainer>
      <Grid container>
        <Grid item container xs justify="flex-start">
          <Title variant="title">Bulk Actions</Title>
        </Grid>
        <Grid item container xs justify="flex-end">
          <IconButton onClick={handleRefresh}>
            {isFetchingBulkActionTasks ? <CircularProgress /> : <Refresh />}
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <BulkActionList
        bulkActionTasks={bulkActionTasks.items}
        cancelBulkAction={cancelBulkAction}
        timezone={timezone}
      />
      <Pagination
        page={page}
        count={bulkActionTasks.lastPage + 1}
        handleChange={handleChange}
      />
    </MainContainer>
  );
};

export default BulkActions;
