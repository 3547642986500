import styled from "styled-components";

export const MainContainer = styled.div`
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 59px;

  @media screen and (max-height: 768px) {
    height: 40px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    height: 47px;
  }
`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0px 40px;
  background: linear-gradient(180deg, #1b60b1 0%, #378bee 100%);
  font-size: 1.2rem;
  width: 250px;
  text-align: center;
  font-weight: 600;
  line-height: 34px;
  height: 100%;

  @media screen and (max-height: 768px) {
    padding: 0px 20px;
    font-size: 12px;
    width: 200px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    padding: 0px 30px;
    font-size: 15px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }

  @media screen and (max-width: 1110px) {
    padding: 0px 10px;
    font-size: 10px;
  }

  @media screen and (min-width: 1111px) and (max-width: 1300px) {
    padding: 0px 20px;
    font-size: 12px;
    width: 150px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
