import { connect } from "react-redux";
import Notification from "../../components/drawers/NotificationsDrawer";
import {
  getNotifications,
  markNotificationAsRead,
  markNotificationsAllRead,
  getUnreadNotifications,
  clearNotification
} from "../../actions/notification";

const mapStateToProps = state => ({
  notifications: state.notification.notifications,
  isFetchingNotifications: state.notification.isFetching,
  permissions: state.user.permissions
});

const mapDispatchToProps = dispatch => ({
  getNotifications: (page, perPage) =>
    dispatch(getNotifications(page, perPage)),
  markNotificationAsRead: id => dispatch(markNotificationAsRead(id)),
  markNotificationsAllRead: () => dispatch(markNotificationsAllRead()),
  getUnreadNotifications: () => dispatch(getUnreadNotifications()),
  clearNotification: () => dispatch(clearNotification())
});

const NotificationsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);

export default NotificationsPage;
