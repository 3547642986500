import styled from "styled-components";
import Paper from "@material-ui/core/Paper";

export const StyledPaper = styled(Paper)`
  width: 65%;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  overflow-y: auto;
`;
