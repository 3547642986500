import React from "react";
import {
  TableRow,
  TableCell,
  FormControlLabel,
  Switch,
  Tooltip
} from "@material-ui/core";
import { ErrorIcon, WarningIcon } from "components/drawers/AQETab/AQETabStyle";
import HealthCheckTooltip from "./HealthCheckTooltip";
import { userConfig } from "userConfigValidations";

const healthy = "HEALTHY";
const unhealthy = "UNHEALTHY";
const partial = "PARTIAL";
const unknown = "UNKNOWN";

const PluginTableRow = props => {
  const {
    plugin: { name, version, activated: isActive, confValid: isConfigured },
    id,
    currentPluginId,
    //func
    openPlugin,
    updateActivation,
    report
  } = props;

  const Configuration = isConfigured ? "YES" : "NO";

  const onUpdateActivation = (e, id) => {
    e.stopPropagation();
    updateActivation(id);
  };

  return (
    <TableRow
      key={id}
      hover
      onClick={e => openPlugin(id)}
      selected={id === currentPluginId}
    >
      {userConfig.variables.healthChecks && (
        <TableCell>
          {report?.pluginHealthStatus === healthy || !report ? null : (
            <Tooltip
              title={
                <HealthCheckTooltip
                  unhealthy={
                    report.healthChecks.filter(hc => hc.status === unhealthy)
                      .length
                  }
                  partial={
                    report.healthChecks.filter(hc => hc.status === partial)
                      .length
                  }
                  unknown={
                    report.healthChecks.filter(hc => hc.status === unknown)
                      .length
                  }
                />
              }
              placement="top"
            >
              {report?.pluginHealthStatus === unhealthy ? (
                <ErrorIcon data-testid="error-icon" />
              ) : (
                <WarningIcon data-testid="warning-icon" />
              )}
            </Tooltip>
          )}
        </TableCell>
      )}
      <TableCell>{name}</TableCell>
      <TableCell>{version}</TableCell>
      <TableCell>{Configuration}</TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <div onClick={e => onUpdateActivation(e, id)}>
              <Switch checked={isActive} value="checkedB" color="primary" />
            </div>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default PluginTableRow;
