import { OPEN_BUNDLES, CLOSE_BUNDLES } from "../actions/bundles";

function bundles(state = { isOpen: true }, action) {
  switch (action.type) {
    case OPEN_BUNDLES:
      return {
        isOpen: true
      };
    case CLOSE_BUNDLES:
      return {
        isOpen: false
      };
    default:
      return state;
  }
}

export default bundles;
