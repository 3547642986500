import { USER_NAMES_GET_ALL } from "../actions/tickets";

function users(state = { userNames: {} }, action) {
  switch (action.type) {
    case USER_NAMES_GET_ALL: {
      return Object.assign({}, state, {
        userNames: action.userNames.reduce((prev, next) => {
          prev[`${next.firstName} ${next.lastName}`] = next;
          return prev;
        }, {})
      });
    }
    default:
      return state;
  }
}

export default users;
