import { connect } from "react-redux";
import TicketTemplateViewer from "./Viewer";
import {
  changeRoute,
  getTemplateMappingFields
} from "redux/modules/ticketTemplate";

const mapStateToProps = state => {
  return {
    currentPage: state.ticketTemplates.currentPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeRoute: route => dispatch(changeRoute(route)),
    getTemplateMappingFields: () => dispatch(getTemplateMappingFields())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateViewer);
