import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles,
  Divider,
  Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactJson from "react-json-view";
import { ErrorIcon, WarningIcon } from "../../../drawers/AQETab/AQETabStyle";
import { Report, HealthStatus } from "./HealthCheckTypes";
import { IStyles, styles } from "./Styles";
import { IProps } from "./HealthCheckCTN";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const PluginDrawerHealthCheck: React.FC<IProps & IStyles> = React.memo(
  ({ report, isLoading, classes }) => {
    const strToJson = (str: string): any | null => {
      try {
        return JSON.parse(str);
      } catch {
        return null;
      }
    };

    const ErrorScreen = () => (
      <div className={classes.error}>
        <ErrorIcon />
        <Typography variant="headline" className={classes.errorMsg}>
          No health checks available for this plugin.
        </Typography>
      </div>
    );

    const DrawersScreen = () => (
      <div>
        {report &&
          report.healthChecks.map(hc => (
            <ExpansionPanel key={hc.name}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {hc.status === HealthStatus.UNHEALTHY ? (
                  <ErrorIcon />
                ) : hc.status === HealthStatus.PARTIAL ||
                  hc.status === HealthStatus.UNKNOWN ? (
                  <WarningIcon />
                ) : null}
                <Typography className={classes.heading}>{hc.name}</Typography>
                {hc.description && (
                  <Tooltip title={hc.description}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={classes.icon}
                      // style={styles.icon}
                    />
                  </Tooltip>
                )}
              </ExpansionPanelSummary>
              <Divider />
              <ExpansionPanelDetails className={classes.content}>
                {hc.cause === null ? (
                  <div className={classes.contentText}>
                    No errors or warnings detected!
                  </div>
                ) : strToJson(hc.cause) ? (
                  <ReactJson
                    src={strToJson(hc.cause)}
                    displayDataTypes={false}
                  />
                ) : (
                  <div className={classes.contentText}>{hc.cause}</div>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
      </div>
    );

    return (
      <div>{!report || isLoading ? <ErrorScreen /> : <DrawersScreen />}</div>
    );
  }
);

export default withStyles(styles)(PluginDrawerHealthCheck);
