import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { JobParametersType } from "../DryRunInterface";
import { automationConfigsStyles, ICSSProps } from "../Styles";
import { withStyles } from "@material-ui/core";

interface IJobParametersProps {
  jobParameters: JobParametersType;
}

const JobParameters: React.FC<IJobParametersProps &
  ICSSProps<typeof automationConfigsStyles>> = React.memo(
  ({ jobParameters, classes }) => {
    const { name, value } = jobParameters.ticketNumber;

    return (
      <>
        <span className={classes.tableTitle}>Job Parameters</span>
        <Table>
          <colgroup>
            <col width="30%" />
            <col width="50%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableColumn}>
                Input Parameter
              </TableCell>
              <TableCell className={classes.tableColumn}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {name && value && (
              <TableRow key={name}>
                <TableCell
                  className={classes.tableBorder}
                  component="th"
                  scope="row"
                >
                  {name}
                </TableCell>
                <TableCell className={classes.tableBorder}>{value}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default withStyles(automationConfigsStyles)(JobParameters);
