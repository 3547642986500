import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const TicketOptions = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { selectedTickets } = props;
  const { toggleCustomizationTable, handleBulkActionDrawer } = props;
  return (
    <>
      <Button
        data-cy="more-options"
        variant="contained"
        color="primary"
        style={{
          minWidth: 0,
          padding: selectedTickets.length ? "2px 2px 2px 8px" : 2,
          marginRight: 5,
          fontWeight: 300,
          height: 20
        }}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        {selectedTickets.length ? `${selectedTickets.length} selected` : ""}
        <MoreVertIcon style={{ fontSize: 18 }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          data-cy="customize-table"
          onClick={event => {
            setAnchorEl(null);
            toggleCustomizationTable();
          }}
        >
          Customize Table
        </MenuItem>
        <MenuItem
          data-cy="bulk-action"
          onClick={event => {
            handleBulkActionDrawer(true);
            setAnchorEl(null);
          }}
          disabled={selectedTickets.length <= 0}
        >
          Bulk Actions
        </MenuItem>
      </Menu>
    </>
  );
};

export default TicketOptions;
