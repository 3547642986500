import config from "../config";
import { axiosInstanceSnackbar as axiosSnackbar } from "../utils/axios";
// Gets list of existing attachments
// Response: Array of Strings

export const DOWNLOAD_ATTACHMENTS_PENDING = "DOWNLOAD_ATTACHMENTS_PENDING";
export const DOWNLOAD_ATTACHMENTS_SUCCESS = "DOWNLOAD_ATTACHMENTS_SUCCESS";
export const DOWNLOAD_ATTACHMENTS_ERROR = "DOWNLOAD_ATTACHMENTS_ERROR";

export function downloadAttachments(ticketNumber, attachmentId) {
  return async (dispatch, getState) => {
    dispatch({ type: DOWNLOAD_ATTACHMENTS_PENDING });

    const url = `${config.urls.base +
      config.urls.apis[
        "ticket-management"
      ]}/tickets/getAttachment/${ticketNumber}`;

    const params = {
      attachmentId
    };

    try {
      const res = await axiosSnackbar.get(url, {
        responseType: "blob",
        params
      });
      dispatch({
        type: DOWNLOAD_ATTACHMENTS_SUCCESS
      });
      return res.data;
    } catch (err) {
      return dispatch({
        type: DOWNLOAD_ATTACHMENTS_ERROR
      });
    }
  };
}
