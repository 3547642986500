import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const styles = theme => ({
  button: {
    marginBottom: "-4px"
  },
  badge: {
    backgroundColor: "rgb(10, 131, 196)",
    color: "#fff"
  },
  hideBadge: {
    opacity: 0
  },
  margin: {
    margin: theme.spacing.unit * 2
  }
});

const FilterButtonBadged = ({ classes, onClick, filtered }) => (
  <Badge className={classes.margin} badgeContent={filtered} color="primary">
    <Button className={classes.button} onClick={onClick}>
      <ArrowDropDown />
    </Button>
  </Badge>
);

export default withStyles(styles)(FilterButtonBadged);
