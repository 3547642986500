import moment from "moment";

import {
  ADD_TEMPLATES,
  ADD_TEMPLATE,
  REMOVE_TEMPLATE,
  OPEN_TEMPLATE,
  CLOSE_TEMPLATE,
  REPLACE_TEMPLATE,
  VIEW_TEMPLATE,
  UNVIEW_TEMPLATE,
  TEMPLATE_GET_ALL_FULFILLED
} from "../actions/templates";

// TODO: Convert to KeyValue objects instead of arrays for templates and fields
function templates(
  state = {
    templateList: [],
    template: {},
    templatePreview: {}
  },
  action
) {
  switch (action.type) {
    case ADD_TEMPLATES: {
      return Object.assign({}, state, {
        templateList: state.templateList.concat(action.templateList),
        templatePreview: {},
        template: {}
      });
    }
    case ADD_TEMPLATE: {
      const templateList = state.templateList.slice();
      if (action.template && templateList.indexOf(action.template) !== -1) {
        templateList.splice(templateList.indexOf(action.template), 0, {
          id: templateList.length,
          name: `${action.template.name} copy`,
          description: action.template.description,
          updated: moment(),
          fields: []
        });
        return Object.assign({}, state, {
          templateList,
          templatePreview: {},
          template: {}
        });
      } else if (action.template) {
        templateList.push(action.template);
        return Object.assign({}, state, {
          templateList,
          templatePreview: {},
          template: {}
        });
      }
      templateList.push({
        name: "Unnamed template",
        description: "",
        updated: moment().format(),
        fields: []
      });
      return Object.assign({}, state, {
        templateList,
        templatePreview: {},
        template: templateList[templateList.length - 1]
      });
    }
    case REMOVE_TEMPLATE: {
      const templateList = state.templateList.slice();
      templateList.splice(templateList.indexOf(action.template), 1);
      return Object.assign({}, state, {
        templateList,
        templatePreview: {},
        template: {}
      });
    }
    case REPLACE_TEMPLATE: {
      const templateList = state.templateList.slice();
      templateList[
        templateList.findIndex(element => element.name === action.template.name)
      ] = action.templateEdited;

      return Object.assign({}, state, {
        templateList,
        templatePreview: state.templatePreview,
        template: state.template
      });
    }
    case OPEN_TEMPLATE:
      return {
        templateList: state.templateList,
        templatePreview: state.templatePreview,
        template: action.template
      };
    case CLOSE_TEMPLATE:
      return {
        templateList: state.templateList,
        templatePreview: state.templatePreview,
        template: {}
      };
    case VIEW_TEMPLATE: {
      return {
        templateList: state.templateList,
        templatePreview: action.template,
        template: state.template
      };
    }
    case UNVIEW_TEMPLATE:
      return {
        templateList: state.templateList,
        templatePreview: {},
        template: state.template
      };
    case TEMPLATE_GET_ALL_FULFILLED:
      return {
        templateList: action.templateList,
        templatePreview: {},
        template: {}
      };
    default:
      return state;
  }
}

export default templates;
