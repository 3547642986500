import React, { useState } from "react";
import PropTypes from "prop-types";
import { ArcherElement } from "react-archer";
import {
  CollapseContainer,
  NodeCardContainer,
  StateText
} from "../Node/Card/CardStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Collapse, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classnames from "classnames";
import styled from "styled-components";
import { blue700 } from "material-ui/styles/colors";
import uuid from "uuid";

const styles = theme => ({
  expand: {
    color: "#e1e4e8",
    transform: "rotate(0deg)",
    marginLeft: 5,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    position: "absolute",
    right: 2
  },
  expandOpen: {
    transform: "rotate(180deg)",
    marginTop: -5
  }
});

const StyledDiv = styled.div`
  padding: 6px 0px;
`;

const StyledPaper = styled(NodeCardContainer)`
  &::before {
    display: none;
  }
`;
const MultiParamCard = ({
  parameters,
  nextNodeId,
  currentParam,
  type,
  classes
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleDetails = () => setOpen(!isOpen);

  const isAllInputFilled = () => {
    return parameters.find(param => currentParam[param.id] === null)
      ? false
      : true;
  };

  return (
    <>
      <ArcherElement
        id={`${type}${currentParam.id}`}
        style={{ marginRight: 40 }}
        relations={
          type === "input"
            ? [
                {
                  targetId: nextNodeId,
                  targetAnchor: "top",
                  sourceAnchor: "bottom",
                  style: {
                    strokeWidth: 2,
                    arrowLength: 2,
                    strokeColor: isAllInputFilled() ? blue700 : "#d1d5da"
                    // strokeColor: blue700
                  }
                }
              ]
            : void 0
        }
      >
        <StyledPaper>
          <Typography variant="body2">Multiple Inputs</Typography>
          <Grid container item xs={12}>
            <StateText isLink onClick={toggleDetails}>
              {`${parameters.length} inputs`}
            </StateText>
            <div
              className={classnames(classes.expand, {
                [classes.expandOpen]: isOpen
              })}
            >
              <ExpandMoreIcon />
            </div>
          </Grid>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <CollapseContainer>
              {parameters.map((param, index) => (
                <div key={uuid.v4()}>
                  <Divider />
                  <StyledDiv>
                    <Typography
                      variant="caption"
                      noWrap={type === "input" ? true : false}
                      color={currentParam[param.id] ? "default" : "error"}
                    >
                      {param.displayName}
                    </Typography>

                    {currentParam[param.id] ? (
                      <Typography variant="body2">
                        {currentParam[param.id]}
                      </Typography>
                    ) : (
                      <Typography variant="body2" color="error">
                        Empty
                      </Typography>
                    )}
                  </StyledDiv>
                  {index + 1 === parameters.length && <Divider />}
                </div>
              ))}
            </CollapseContainer>
          </Collapse>
        </StyledPaper>
      </ArcherElement>
    </>
  );
};

MultiParamCard.propTypes = {
  parameters: PropTypes.array,
  nextNodeId: PropTypes.string,
  currentParam: PropTypes.object,
  type: PropTypes.string.isRequired
};

MultiParamCard.defaultProps = {
  parameters: []
};

export default withStyles(styles)(MultiParamCard);
