import React from "react";
import Grid from "react-flexbox-grid/lib/components/Grid";
import Row from "react-flexbox-grid/lib/components/Row";
import Col from "react-flexbox-grid/lib/components/Col";
import Divider from "material-ui/Divider";
import { List, ListItem } from "material-ui/List";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import config from "../../config";
import UserForm from "./UserForm";
import SettingsStyles from "./SettingsStyles";
import { axiosInstance as axios } from "../../utils/axios";

export default class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this._toggleBundles = this._toggleBundles.bind(this);

    this.state = {
      profileIsFetching: true,
      bundles: [
        {
          name: "User information",
          filters: [
            {
              name: "Personal"
            }
          ]
        }
      ],
      bundleSelected: {},
      user: {},
      timezones: []
    };
  }

  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile = async () => {
    try {
      const user = await this.fetchUser();
      const userDetails = await this.fetchUserDetails(user.username);
      const timezones = await this.fetchTimezones();
      this.setState({ user: userDetails, timezones });
    } catch (e) {
      this.props.openSnackbar("Failed to load User Profile");
    } finally {
      this.setState({
        profileIsFetching: false
      });
    }
  };

  fetchUser = () => {
    const url = `${config.urls.authBase}${config.urls.apis.auth}/token/properties`;
    const body = { token: this.props.token.token };

    return axios
      .post(url, body)
      .then(res => res.data)
      .catch(err => Promise.reject(err));
  };

  fetchUserDetails = userName => {
    const url = `${config.urls.base}${config.urls.apis["identity-management"]}/users/${userName}`;

    return axios
      .get(url)
      .then(res => res.data)
      .catch(err => Promise.reject(err));
  };

  fetchTimezones = () => {
    const url = `${config.urls.base}${config.urls.apis["identity-management-v2"]}/users/timezone`;

    return axios
      .get(url)
      .then(res => {
        const reMapped = res.data.map(o => ({
          label: `${o.label} (${o.value})`,
          value: o.value
        }));

        return reMapped;
      })
      .catch(err => Promise.reject(err));
  };

  _toggleBundles() {
    const { isBundlesOpen, closeBundles, openBundles } = this.props;
    isBundlesOpen ? closeBundles() : openBundles();
  }

  _handleBundle(bundle) {
    this.setState({
      bundleSelected: bundle
    });
  }

  render() {
    const isBundlesOpen = this.props.isBundlesOpen;
    const bundles = this.state.bundles;
    const bundleSelected = this.state.bundleSelected;

    const bundlesList = bundles.map((group, i) => {
      // console.log('item:',group);
      return (
        <div key={i} style={{}}>
          <h4 style={SettingsStyles["BundleHeading"]}>{group.name}</h4>
          <List>
            {group.filters.map((item, k) => {
              return (
                <ListItem
                  key={`${i}-${k}`}
                  primaryText={item.name}
                  style={
                    bundleSelected === item
                      ? SettingsStyles["SelectedBundleListItem"]
                      : SettingsStyles["BundleListItem"]
                  }
                  rightIcon={
                    <span style={SettingsStyles["BundleListItemCount"]}>
                      {item.count}
                    </span>
                  }
                  onClick={() => this._handleBundle(item)}
                />
              );
            })}
          </List>
          <Divider />
        </div>
      );
    });

    return (
      <div style={{ height: "calc(100vh - 60px)", overflowY: "auto" }}>
        <Grid fluid style={{ padding: "20px" }}>
          <Row style={{ alignItems: "center", paddingLeft: 10 }}>
            <Col xs={12} md={2} style={{ padding: 0 }}>
              <h3 style={{ margin: 0 }}>
                <IconButton onClick={this._toggleBundles}>
                  <FontIcon className="fa fa-bars" />
                </IconButton>
                User Profile
              </h3>
            </Col>
          </Row>
          {this.state.profileIsFetching ? (
            <div>Fetching user list...</div>
          ) : (
            <Row style={{ paddingLeft: 10 }}>
              {isBundlesOpen && (
                <Col xs={3} lg={2}>
                  <div>
                    <List className="bundles"> {bundlesList} </List>
                  </div>
                </Col>
              )}
              <Col xs={isBundlesOpen ? 9 : 12} lg={isBundlesOpen ? 10 : 12}>
                <UserForm
                  {...this.props}
                  user={this.state.user}
                  timezones={this.state.timezones}
                />
                {bundleSelected.name === "Templates" ? "other page" : ""}
              </Col>
            </Row>
          )}
        </Grid>
      </div>
    );
  }
}

// export default UserProfile;
