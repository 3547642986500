import { pluginSagas } from "./plugin";
import { ticketTemplateSagas } from "./ticketTemplate";
import { ticketSagas } from "./userData";
import { ticketFieldSagas } from "./ticketField";
import { all } from "redux-saga/effects";

export default function* rootSage() {
  yield all([
    ...pluginSagas,
    ...ticketTemplateSagas,
    ...ticketSagas,
    ...ticketFieldSagas
  ]);
}
