import { Editor } from "slate-react";
import Html from "slate-html-serializer";
import { Value } from "slate";

import React from "react";
import {
  MARK_TAGS,
  HEADING_TAGS,
  BLOCK_TAGS,
  LINK,
  BULLETED_LIST,
  NUMBERED_LIST,
  LIST_ITEM,
  HEADING,
  DEFAULT_NODE,
  rules
} from "./Constants";
import { cloneDeep } from "lodash";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormatListNumbered from "material-ui/svg-icons/editor/format-list-numbered";
import FormatListBulleted from "material-ui/svg-icons/editor/format-list-bulleted";
import InsertLink from "material-ui/svg-icons/editor/insert-link";
import FormatClear from "material-ui/svg-icons/editor/format-clear";

import initialValueJSON from "./value.json";
import PromptDialog from "./PromptDialog.js";

export const html = new Html({ rules });

export const initialValue = Value.fromJSON(initialValueJSON);

/**
 * A change helper to standardize wrapping links.
 *
 * @param {Change} change
 * @param {String} href
 */

const wrapLink = (change, href) => {
  change.wrapInline({
    type: LINK,
    data: { href }
  });

  change.collapseToEnd();
};

/**
 * A change helper to standardize unwrapping links.
 *
 * @param {Change} change
 */

const unwrapLink = change => change.unwrapInline(LINK);

/**
 * Set the block in the editor by type of the node
 *
 * @param {String} type
 * @param {Change} change
 */

const setBlockByType = (type, change) =>
  change
    .setBlocks(type)
    .unwrapBlock(BULLETED_LIST)
    .unwrapBlock(NUMBERED_LIST);

/**
 * The ATR rich text editor
 *
 * @type {Component}
 */

class RTEditor extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.state = {
      [HEADING]: DEFAULT_NODE,
      selectEnabled: false,
      openDialogBox: false
    };
  }

  onChange(change) {
    this.props.onChange(change);
    this.state.selectEnabled && this.switchSelectItem();
  }

  onFocus() {
    this.setState({ selectEnabled: true });
  }

  /**
   * On key down, if it's a formatting command toggle a mark.
   *
   * @param {Event} event
   * @param {Change} change
   * @return {Change}
   */

  onKeyDown(event, change) {
    const { type } = Object.keys(MARK_TAGS).reduce((obj, key) => {
      if (
        MARK_TAGS[key].hasOwnProperty("isHotkey") &&
        MARK_TAGS[key].isHotkey(event)
      )
        obj = cloneDeep(MARK_TAGS[key]);
      return obj;
    }, {});

    if (!type) return;

    event.preventDefault();
    change.toggleMark(type);
    return true;
  }

  /**
   * When a mark button is clicked, toggle the current mark.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickMark(event, type) {
    event.preventDefault();
    const { value } = this.props;
    const change = value.change().toggleMark(type);
    this.props.onChange(change);
  }

  /**
   * When a menu item in the select is clicked, set the block type instead of toggle
   *
   * @param {String} type
   */

  onClickHeading(type) {
    const { value } = this.props;
    const change = value.change();

    if (!this.hasBlock(type)) {
      setBlockByType(type, change);
      this.props.onChange(change);
    }
  }

  /**
   * When a block button is clicked, toggle the block type.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickBlock(event, type) {
    event.preventDefault();
    const { value } = this.props;
    const change = value.change();
    const { document } = value;

    // Handle everything but list buttons.
    if (type !== BULLETED_LIST && type !== NUMBERED_LIST) {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock(LIST_ITEM);

      if (isList) {
        setBlockByType(isActive ? DEFAULT_NODE : type, change);
      } else {
        change.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock(LIST_ITEM);
      const isType = value.blocks.some(
        block =>
          !!document.getClosest(block.key, parent => parent.type === type)
      );

      if (isList && isType) {
        setBlockByType(DEFAULT_NODE, change);
      } else if (isList) {
        change
          .unwrapBlock(type === BULLETED_LIST ? NUMBERED_LIST : BULLETED_LIST)
          .wrapBlock(type);
      } else {
        change.setBlocks(LIST_ITEM).wrapBlock(type);
      }
    }

    this.props.onChange(change);
  }

  /**
   * When clicking a link, if the selection has a link in it, remove the link.
   * Otherwise, add a new link with an href and text.
   *
   * @param {Event} event
   */

  onClickLink = event => {
    event.preventDefault();
    const { value } = this.props;
    const hasLinks = this.hasLinks();
    const change = value.change();

    if (hasLinks) {
      change.call(unwrapLink);
    } else if (value.isExpanded) {
      this.setState({ openDialogBox: true });
    }

    this.onChange(change);
  };

  closeDialogbox = () => {
    this.setState({ openDialogBox: false });
  };

  sendHrefToEditor = href => {
    const { value } = this.props;
    const change = value.change();
    change.call(wrapLink, href);
    this.onChange(change);
  };

  onClickClear = event => {
    event.preventDefault();
    const { value } = this.props;
    const change = value.change();

    const clearAllMarks = () => {
      Object.keys(MARK_TAGS).map(key => change.removeMark(MARK_TAGS[key].type));
      this.onChange(change);
    };
    const clearAllBlocks = () =>
      this.setState({ heading: DEFAULT_NODE }, () => {
        setBlockByType(DEFAULT_NODE, change);
        this.props.onChange(change);
      });

    const clearAllLinks = () => change.call(unwrapLink);

    clearAllMarks();
    clearAllBlocks();
    clearAllLinks();
  };

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasMark(type) {
    const { value } = this.props;
    return value.activeMarks.some(mark => mark.type === type);
  }

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasBlock(type) {
    const { value } = this.props;
    return value.blocks.some(node => node.type === type);
  }

  /**
   * Check whether the current selection has a link in it.
   *
   * @return {Boolean} hasLinks
   */

  hasLinks = () => {
    const { value } = this.props;
    return value.inlines.some(inline => inline.type === LINK);
  };

  /**
   * Render the toolbar.
   *
   * @return {Element}
   */

  renderToolbar() {
    return (
      <div className="menu toolbar-menu">
        <div className="formats">{this.renderSelect()}</div>
        <div className="formats">
          {Object.keys(MARK_TAGS).map(key =>
            this.renderMarkButton(MARK_TAGS[key].type, MARK_TAGS[key].icon)
          )}
          {this.renderLinkButton(LINK, ({ color }) => (
            <InsertLink color={color} />
          ))}
        </div>
        {/* <div className="formats">
          {this.renderBlockButton("heading-one", ({ color }) => (
            <LooksOne color={color} />
          ))}
          {this.renderBlockButton("heading-two", ({ color }) => (
            <LooksTwo color={color} />
          ))}
        </div> */}
        <div className="formats">
          {this.renderBlockButton(NUMBERED_LIST, ({ color }) => (
            <FormatListNumbered color={color} />
          ))}
          {this.renderBlockButton(BULLETED_LIST, ({ color }) => (
            <FormatListBulleted color={color} />
          ))}
        </div>
        <div className="formats">{this.renderClearButton()}</div>
      </div>
    );
  }

  handleSelectChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.onClickHeading(event.target.value)
    );
  }

  renderSelect() {
    return (
      <Select
        value={this.state.heading ? this.state.heading : DEFAULT_NODE}
        SelectDisplayProps={{
          style: {
            height: 18,
            padding: "2px 24px 3px 8px",
            minWidth: 73
          }
        }}
        disableUnderline
        displayEmpty
        onChange={this.handleSelectChange}
        MenuProps={{
          style: {
            zIndex: 1500
          }
        }}
        inputProps={{
          name: HEADING
        }}
      >
        {Object.keys(HEADING_TAGS).map(tag => (
          <MenuItem key={tag} value={HEADING_TAGS[tag].type}>
            {HEADING_TAGS[tag].menuItem}
          </MenuItem>
        ))}
      </Select>
    );
  }

  switchSelectItem() {
    const heading = Object.keys(HEADING_TAGS)
      .map(tag => HEADING_TAGS[tag].type)
      .find(type => this.hasBlock(type));
    this.setState({ heading });
  }

  renderToggleButton(type, icon, isActive, onMouseDown) {
    return (
      type && (
        // eslint-disable-next-line react/jsx-no-bind
        <span
          className="button"
          onMouseDown={onMouseDown}
          data-active={isActive}
          key={type + "button"}
        >
          <span className="material-icons">
            {icon({ color: isActive ? "#06c" : "#444" })}
          </span>
        </span>
      )
    );
  }

  /**
   * Render a mark-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderMarkButton(type, icon) {
    const isActive = this.hasMark(type);
    const onMouseDown = event => this.onClickMark(event, type);

    return this.renderToggleButton(type, icon, isActive, onMouseDown);
  }

  /**
   * Render a block-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderBlockButton(type, icon) {
    let isActive = this.hasBlock(type);

    if ([NUMBERED_LIST, BULLETED_LIST].includes(type)) {
      const { value } = this.props;
      const parent =
        value.blocks.first() &&
        value.document.getParent(value.blocks.first().key);
      isActive = this.hasBlock(LIST_ITEM) && parent && parent.type === type;
    }
    const onMouseDown = event => this.onClickBlock(event, type);

    return this.renderToggleButton(type, icon, isActive, onMouseDown);
  }

  renderLinkButton(type, icon) {
    const isActive = this.hasLinks(type);
    const onMouseDown = event => this.onClickLink(event, type);

    return this.renderToggleButton(type, icon, isActive, onMouseDown);
  }

  renderClearButton() {
    const onMouseDown = event => this.onClickClear(event);
    return this.renderToggleButton(
      "clear",
      () => <FormatClear />,
      false,
      onMouseDown
    );
  }

  /**
   * Render the Slate editor.
   *
   * @return {Element}
   */

  renderEditor() {
    return (
      <div className="editor-container" style={this.props.style}>
        <div className="editor">
          <Editor
            placeholder="Enter your email content here..."
            value={this.props.value}
            onChange={this.onChange}
            onFocus={this.onFocus}
            readOnly={this.props.readOnly}
            onKeyDown={this.onKeyDown}
            renderNode={this.renderNode}
            renderMark={this.renderMark}
            spellCheck
          />
        </div>
      </div>
    );
  }

  /**
   * Render a Slate node.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderNode(props) {
    const { attributes, children, node } = props;

    const renderLink = (attributes, children, node) => (
      <a
        {...attributes}
        href={node.data.get("href")}
        contentEditable={false}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );

    const renderBlocks = (attributes, children, node) =>
      Object.keys(BLOCK_TAGS)
        .reduce((obj, key) => {
          if (BLOCK_TAGS[key].type === node.type)
            obj = cloneDeep(BLOCK_TAGS[key]);
          return obj;
        }, {})
        .render(attributes, children);

    switch (node.type) {
      case LINK: {
        return renderLink(attributes, children, node);
      }
      default:
    }

    return renderBlocks(attributes, children, node);
  }

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderMark(props) {
    const { children, mark, attributes } = props;

    return Object.keys(MARK_TAGS)
      .reduce((obj, key) => {
        if (MARK_TAGS[key].type === mark.type) obj = cloneDeep(MARK_TAGS[key]);
        return obj;
      }, {})
      .render(attributes, children);
  }

  /**
   * Render.
   *
   * @return {Element}
   */

  render() {
    return (
      <div className="rt-editor">
        {this.renderToolbar()}
        {this.renderEditor()}
        <div>
          {this.state.openDialogBox && (
            <PromptDialog
              openDialogBox={this.state.openDialogBox}
              closeDialogbox={this.closeDialogbox}
              sendHrefToEditor={this.sendHrefToEditor}
            />
          )}
        </div>
      </div>
    );
  }
}

/**
 * Export.
 */

export default RTEditor;
