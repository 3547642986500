/**
 * Created by x1 on 5/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import DropDownMenu from "material-ui/DropDownMenu";
import SelectField from "material-ui/SelectField";

class PrimaryDropDownMenu extends React.Component {
  determineErrorText() {
    let errorText;
    if (this.props.invalid === true) {
      errorText = "Required";
    } else {
      errorText = null;
    }
    return errorText;
  }

  render() {
    const { requiredError, ...rest } = this.props;
    const errorStyle =
      typeof this.props.invalid === "boolean" && this.props.invalid;
    const renderError = typeof requiredError === "boolean" && requiredError;

    return this.props.invalid ? (
      <SelectField
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        labelStyle={{
          color: themeMainUI.palette.primary2Color,
          fontWeight: "bold"
        }}
        underlineStyle={
          errorStyle || renderError
            ? { borderColor: themeMainUI.palette.errorColor }
            : {}
        }
        errorText={this.determineErrorText()}
        {...rest}
      />
    ) : (
      <DropDownMenu
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        labelStyle={{
          color: themeMainUI.palette.primary2Color,
          fontWeight: "bold"
        }}
        underlineStyle={
          renderError
            ? { borderColor: themeMainUI.palette.errorColor, borderWidth: 2 }
            : {}
        }
        {...rest}
      />
    );
  }
}

export default PrimaryDropDownMenu;
