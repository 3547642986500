import React, { Component } from "react";
import update from "immutability-helper";

import { TableRow, TableRowColumn } from "material-ui/Table";
import {
  StyledCancel,
  StyledDelete,
  StyledSave
} from "./StyledComponents/shared";

import SecondaryTextField from "../../templates/SecondaryTextField";

export default class ATSTableRow extends Component {
  columns = [
    {
      name: "clientId",
      hintText: "Client ID",
      type: "text"
    },
    {
      name: "baseUrl",
      hintText: "Metadata URL",
      type: "text"
    },
    {
      name: "baseHtmlUrl",
      hintText: "Base URL",
      type: "text"
    }
  ];

  //TODO: change to hooks
  state = {
    editing: this.props.editing ? this.props.editing : false,
    item: JSON.parse(JSON.stringify(this.props.item)),
    selectedModel: null,
    valid: true,
    errors: {
      clientId: "",
      baseHtmlUrl: "",
      baseUrl: ""
    }
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     item: JSON.parse(JSON.stringify(nextProps.item))
  //   };
  // }

  _handleSaveButtonClick = () => {
    let state = update(this.state, { valid: { $set: true } });

    if (state.valid) {
      this.props.addTicketConfigThenRefreshList(this.props.service, state.item);
      state = update(state, { editing: { $set: false } });

      if (this.props.isNewRow) this.props.hideNewRow(this.props.service);
    }

    this.setState(state);
  };

  _handleCancelButtonClick = () => {
    if (this.props.isNewRow) {
      this.props.hideNewRow(this.props.service);
    } else {
      const state = update(this.state, {
        item: { $set: JSON.parse(JSON.stringify(this.props.item)) },
        editing: { $set: false }
      });

      this.setState(state);
    }
  };

  _handleDeleteButtonClick = () => {
    this.props.deleteTicketConfigThenRefreshList(this.props.service, {
      name: this.state.item.name
    });

    const state = update(this.state, { editing: { $set: false } });
    this.setState(state);
  };

  _handleChange = (e, v) => {
    const state = update(this.state, {
      item: { [e.target.name]: { $set: v } }
    });
    this.setState(state);
  };

  _validateEntry = () => {
    let valid = true;

    const state = update(this.state, {
      errors: {
        clientId: { $set: "" },
        baseUrl: { $set: "" }
      }
    });

    this.setState(state);

    return valid;
  };

  renderField = column => {
    if (column.type === "text") {
      return this.renderTextField(column);
    }
    return null;
  };

  renderTextField = column => {
    return (
      <SecondaryTextField
        hintText={column.hintText}
        name={column.name}
        errorText={column.errorText}
        defaultValue={column.defaultValue}
        onChange={(a, v) => {
          this._handleChange(a, v);
        }}
      />
    );
  };

  renderTableRowColumn = (column, index) => {
    column.errorText = this.state.errors[column.name];
    column.defaultValue = this.state.item[column.name];

    return (
      <TableRowColumn
        key={column.name}
        style={{
          overflow: "hidden"
        }}
        width={index === this.columns.length - 1 ? null : 150}
      >
        {this.renderField(column)}
      </TableRowColumn>
    );
  };

  renderActionColumn = () => {
    return (
      <TableRowColumn
        width={70}
        style={{ textAlign: "right", display: "inline-block", float: "right" }}
        key="actionColumn"
      >
        {this.props.isNewRow && (
          <React.Fragment>
            <StyledSave
              onClick={() => {
                this._handleSaveButtonClick();
              }}
            />
            <StyledCancel
              onClick={() => {
                this._handleCancelButtonClick();
              }}
            />
          </React.Fragment>
        )}

        {!this.props.isNewRow && (
          <StyledDelete
            onClick={() => {
              this._handleDeleteButtonClick();
            }}
          />
        )}
      </TableRowColumn>
    );
  };

  render() {
    const {
      hideNewRow,
      editing,
      isNewRow,
      addTicketConfigThenRefreshList,
      deleteTicketConfigThenRefreshList,
      ...rest
    } = this.props;

    return (
      <TableRow style={{ overflow: "auto", borderBottom: "nil" }} {...rest}>
        {this.state.editing ? (
          this.columns
            .map((column, index) => {
              return this.renderTableRowColumn(column, index);
            })
            .concat(this.renderActionColumn())
        ) : (
          <React.Fragment>
            <TableRowColumn
              style={{
                overflow: "auto",
                textOverflow: "null"
              }}
              width={150}
            >
              {this.state.item.clientId}
            </TableRowColumn>
            <TableRowColumn
              style={{
                overflow: "auto"
              }}
              width={150}
            >
              {this.state.item.baseUrl}
            </TableRowColumn>
            <TableRowColumn
              style={{
                overflow: "auto"
              }}
              width={150}
            >
              {this.state.item.baseHtmlUrl}
            </TableRowColumn>
            <TableRowColumn
              width={70}
              style={{ textAlign: "right" }}
              className={"row-edit-delete"}
            >
              <StyledDelete
                onClick={() => {
                  this._handleDeleteButtonClick();
                }}
              />
            </TableRowColumn>
          </React.Fragment>
        )}
      </TableRow>
    );
  }
}
