import { routerReducer } from "react-router-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import reducers from "./reducers";
import * as newReducers from "./redux/modules";
import rootSaga from "redux/saga";
import { persistStore } from "redux-persist";
import { jwtMiddleware } from "./middleware/jwtMiddleware";
import {
  axiosInstance,
  axiosInstanceSnackbar,
  configureAxios
} from "./utils/axios";

const initialState = {};
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({});
const enhancer = composeEnhancers(
  applyMiddleware(jwtMiddleware, sagaMiddleware, thunkMiddleware)
);
const appReducer = combineReducers({
  ...reducers,
  ...newReducers,
  routing: routerReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = createStore(rootReducer, initialState, enhancer);
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
configureAxios(axiosInstance, store, false);
configureAxios(axiosInstanceSnackbar, store, true);
