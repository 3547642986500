import React from "react";
import PropTypes from "prop-types";
import { ListItem } from "material-ui/List";
import FontIcon from "material-ui/FontIcon";
import Styles from "../../../styles/themeMainUI";

const HelpIcon = props => {
  const { onClick, icon, label } = props;
  return (
    <ListItem
      style={{
        width: 160,
        minHeight: 120
      }}
      onClick={onClick}
    >
      <div>
        <FontIcon
          className={`fa ${icon} fa-2x`}
          color={Styles.palette.accent3Color}
          style={{ fontSize: 36, height: 48, width: 32 }}
        />
      </div>
      <div style={{ fontSize: 12 }}>{label}</div>
    </ListItem>
  );
};

HelpIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default HelpIcon;
