import React, { useState, useEffect } from "react";
import { Drawer, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SecondaryNavigationClose from "../../templates/SecondaryNavigationClose";
import PluginDrawerBasicInfoCTN from "./BasicInfo/BasicInfoCTN";
import PluginDrawerConfigCTN from "./Config/ConfigCTN";
import PluginDrawerTicketSourceCTN from "./TicketSource/TicketSourceCTN";
import PluginDrawerHealthCheckCTN from "./HealthCheck/HealthCheckCTN";
import { userConfig } from "../../../userConfigValidations";

const styles = {
  drawerContent: {
    width: 600,
    padding: "0px 30px"
  },
  root: {
    flexGrow: 1
  },
  tabs: {
    marginTop: 10,
    marginBottom: 10
  }
};
//TODO: change other things except return
const PluginDrawer = props => {
  const {
    classes,
    currentPluginId: pluginId,
    closePlugin,
    configList,
    getConfig,
    openPluginData
  } = props;
  const isOpen = pluginId ? true : false;
  const [tabNum, setTabNum] = useState(0);

  const handleTabChange = (e, value) => setTabNum(value);

  useEffect(() => {
    const isCached = configList.find(id => id === pluginId);
    pluginId && !isCached ? getConfig(pluginId) : openPluginData(pluginId);
  }, []); // eslint-disable-line

  return (
    <Drawer anchor="right" open={isOpen} onClose={closePlugin} key={pluginId}>
      <SecondaryNavigationClose
        onClick={closePlugin}
        style={{
          position: "absolute",
          right: 7
        }}
      />
      <div className={classes.drawerContent}>
        <PluginDrawerBasicInfoCTN pluginId={pluginId} />
        <div className={classes.tabs}>
          <Tabs
            value={tabNum}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth
          >
            <Tab label="Configuration" />
            <Tab label="Ticket Sources" />
            {!!userConfig.variables.healthChecks && (
              <Tab label="Health Checkup" />
            )}
          </Tabs>
        </div>

        {tabNum === 0 && <PluginDrawerConfigCTN />}
        {tabNum === 1 && <PluginDrawerTicketSourceCTN />}
        {tabNum === 2 && userConfig.variables.healthChecks && (
          <PluginDrawerHealthCheckCTN id={pluginId} />
        )}
      </div>
    </Drawer>
  );
};

PluginDrawer.defaultProps = {
  pluginConfig: {},
  pluginTicketSources: []
};

export default withStyles(styles)(PluginDrawer);
