import config from "../config";
import { openSnackbar } from "./snackbar";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ADD_TEMPLATES = "ADD_TEMPLATES";
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";
export const OPEN_TEMPLATE = "OPEN_TEMPLATE";
export const CLOSE_TEMPLATE = "CLOSE_TEMPLATE";
export const REPLACE_TEMPLATE = "REPLACE_TEMPLATE";
export const VIEW_TEMPLATE = "VIEW_TEMPLATE";
export const UNVIEW_TEMPLATE = "UNVIEW_TEMPLATE";

export const TEMPLATE_GET_ALL_PENDING = "TEMPLATE_GET_ALL_PENDING";
export const TEMPLATE_GET_ALL_FULFILLED = "TEMPLATE_GET_ALL_FULFILLED";
export const TEMPLATE_GET_ALL_ERROR = "TEMPLATE_GET_ALL_ERROR";

export const TEMPLATE_UPDATE_SINGLE_PENDING = "TEMPLATE_UPDATE_SINGLE_PENDING";
export const TEMPLATE_UPDATE_SINGLE_FULFILLED =
  "TEMPLATE_UPDATE_SINGLE_PENDING";
export const TEMPLATE_UPDATE_SINGLE_ERROR = "TEMPLATE_UPDATE_SINGLE_ERROR";
// add one template
export function addTemplate(template) {
  return {
    type: ADD_TEMPLATE,
    template
  };
}

// remove one template
export function removeTemplate(template) {
  return (dispatch, getState) => {
    const url = `${config.urls.base}${config.urls.apis["ticket-management"]}/mappingTemplates/${template.name}`;

    return axiosSnackbar.delete(url).then(res => {
      dispatch({
        type: REMOVE_TEMPLATE,
        template
      });
      dispatch(openSnackbar("Successfully deleted."));
      dispatch(getAllTemplates());
    });
  };
}

// add an array of templates
export function addTemplates(templateList) {
  return {
    type: ADD_TEMPLATES,
    templateList
  };
}

// open template
export function openTemplate(template) {
  return {
    type: OPEN_TEMPLATE,
    template
  };
}

// close template
export function closeTemplate() {
  return {
    type: CLOSE_TEMPLATE
  };
}

// replace template
export function replaceTemplate(template, templateEdited) {
  return {
    type: REPLACE_TEMPLATE,
    template,
    templateEdited
  };
}

// view template
export function viewTemplate(template) {
  return {
    type: VIEW_TEMPLATE,
    template
  };
}

// unview template
export function unviewTemplate() {
  return {
    type: UNVIEW_TEMPLATE
  };
}

export function getAllTemplates() {
  return function(dispatch, getState) {
    // console.log(localStorage.getItem('jwt'));

    dispatch({
      type: TEMPLATE_GET_ALL_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/mappingTemplates`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: TEMPLATE_GET_ALL_FULFILLED,
          templateList: res.data
        });
      })
      .catch(err => {
        // TODO: implement TEMPLATE_GET_ALL_ERROR reducer action
        dispatch({
          type: TEMPLATE_GET_ALL_ERROR,
          error: err
        });
      });
  };
}

// Will only update if user comes back to main page OR saves the page
export function updateTemplate(template) {
  return function(dispatch, getState) {
    dispatch({
      type: TEMPLATE_UPDATE_SINGLE_PENDING,
      template
    });

    /* CLEAN TEMPLATE FOR SAVING */
    const templateCleaned = Object.assign({}, template);
    templateCleaned.fields = templateCleaned.fields.map(field => {
      delete field.advanced;
      return field;
    });

    // now this will update the template based on the current template
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/mappingTemplates`;

    return axiosSnackbar({
      method: templateCleaned.id ? "put" : "post",
      url,
      data: templateCleaned
    })
      .then(res => {
        dispatch({
          type: TEMPLATE_UPDATE_SINGLE_FULFILLED,
          template: res.data
        });
      })
      .catch(err => {
        // TODO: implement TEMPLATE_UPDATE_SINGLE_ERROR reducer action
        dispatch({
          type: TEMPLATE_UPDATE_SINGLE_ERROR,
          error: err
        });
      });
  };
}
