import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button
} from "@material-ui/core";
import PropTypes from "prop-types";

const BulkActionDialog = props => {
  const { isOpen, onClose, title, actions, description } = props;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {actions.map(action => (
          <Button
            key={action.text}
            variant="contained"
            color="primary"
            onClick={() => action.onClick && action.onClick()}
          >
            {action.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

const ActionProps = PropTypes.shape({
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired
});

BulkActionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  actions: PropTypes.arrayOf(ActionProps).isRequired
};

export default BulkActionDialog;
