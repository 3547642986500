import React from "react";
import PrimaryDropDownMenu from "../../templates/PrimaryDropDownMenu";
import SecondaryAutoComplete from "../../templates/SecondaryAutoComplete";
import { MenuItem } from "material-ui";
import _ from "lodash";
import SelectAutoComplete from "../SelectAutoComplete";

const DropdownSearch = props => {
  const {
    item,
    ticket,
    handleTicketUpdate,
    ticketFieldAutoComplete,
    invalid,
    isDisabled
  } = props;
  // get dropdown options
  let options = [{ value: "", label: "None" }];
  if (item.values) {
    options = [
      ...options,
      ...item.values.map(val => ({ value: val, label: val }))
    ];
  }
  // get current dropdown value
  let currentValue = ticket[item.source][item.name];
  currentValue = currentValue
    ? { label: currentValue, value: currentValue }
    : null;
  // get placeholder value
  const placeholder = !item.editable
    ? ""
    : item.defaultValue
    ? `Default value: ${item.defaultValue.toLowerCase()}`
    : `Enter ${item.displayName.toLowerCase()} here`;
  // create onChange listener
  const handleChange = dropItem => {
    if (!dropItem) {
      dropItem = { value: "" };
    }
    handleTicketUpdate({ field: item, value: dropItem.value });
    ticketFieldAutoComplete(item.name, dropItem.value);
  };
  return (
    <SelectAutoComplete
      isDisabled={isDisabled}
      options={options}
      value={currentValue}
      placeholder={placeholder}
      onChange={handleChange}
      invalid={invalid}
    />
  );
};

export const PropertiesField = props => {
  const {
    item,
    ticket,
    mlObj,
    mlTemplateMapping,
    drawerFunctions,
    handleTicketUpdate
  } = props;
  const mlField = mlObj[item.name];
  const isDisabled =
    item.mandatory && !item.editable
      ? false
      : !item.editable
      ? true
      : mlTemplateMapping.includes(item.name);
  if (!mlField) {
    switch (item.type) {
      case "dropdown-search":
        return (
          <DropdownSearch
            item={item}
            ticket={ticket}
            handleTicketUpdate={handleTicketUpdate}
            ticketFieldAutoComplete={drawerFunctions.ticketFieldAutocomplete}
            invalid={drawerFunctions.errors.indexOf(item.name) !== -1}
            isDisabled={isDisabled}
          />
        );
      case "dropdown":
        return (
          <PrimaryDropDownMenu
            id={item.name}
            disabled={isDisabled}
            style={{ paddingLeft: "0px" }}
            value={!_.isEmpty(ticket) ? ticket[item.source][item.name] : ""}
            onChange={(event, index, value) =>
              handleTicketUpdate({
                field: item,
                value
              })
            }
            invalid={drawerFunctions.errors.indexOf(item.name) !== -1}
          >
            {item.values.map((option, optionIndex) => (
              <MenuItem key={optionIndex} value={option} primaryText={option} />
            ))}
          </PrimaryDropDownMenu>
        );
      default:
        return (
          <SecondaryAutoComplete
            id={item.name}
            disabled={isDisabled}
            hintText={
              !item.editable
                ? ""
                : item.defaultValue
                ? `Default value: ${item.defaultValue.toLowerCase()}`
                : `Enter ${item.displayName.toLowerCase()} here`
            }
            searchText={
              !_.isEmpty(ticket) ? ticket[item.source][item.name] : ""
            }
            onNewRequest={chosenRequest => {
              handleTicketUpdate({
                field: item,
                value: chosenRequest
              });
            }}
            onUpdateInput={searchText => {
              handleTicketUpdate({
                field: item,
                value: searchText
              });
              drawerFunctions.ticketFieldAutocomplete(item.name, searchText);
            }}
            maxSearchResults={5}
            dataSource={drawerFunctions.autocompleteFields[item.name] || []}
            filter={(searchText, key) => {
              return true;
            }}
            multiLine
            fullWidth
            floatingLabelFixed
            value={!_.isEmpty(ticket) ? ticket[item.source][item.name] : ""}
            invalid={drawerFunctions.errors.indexOf(item.name) !== -1}
          />
        );
    }
  }
  return null;
};
