import React from "react";
import PrimaryDropDownMenu from "../../templates/PrimaryDropDownMenu";
import { MenuItem } from "material-ui";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { setGtrWorkflow } from "../../../actions/tickets";

const TicketResolution = ({
  category,
  selectedGtrWorkflow,
  setGtrWorkflow,
  allGtrWorkflows
}) => {
  return (
    <Grid container alignItems="center">
      <Grid item xs={2} style={{ fontSize: 12 }}>
        Ticket Resolution
      </Grid>
      <Grid item xs={10}>
        <PrimaryDropDownMenu
          value={selectedGtrWorkflow}
          onChange={(event, key, value) => setGtrWorkflow(value)}
        >
          {category ? (
            allGtrWorkflows
              .filter(workflow => workflow.categoryName === category)
              .map(gtrWorkflow => (
                <MenuItem
                  value={gtrWorkflow.resolutionFlowId}
                  primaryText={gtrWorkflow.resolutionFlow}
                />
              ))
          ) : (
            <></>
          )}
        </PrimaryDropDownMenu>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    gtrWorkflows: state.tickets.gtrWorkflows,
    selectedGtrWorkflow: state.tickets.selectedGtrWorkflow,
    allGtrWorkflows: state.tickets.allGtrWorkflows
  };
};

const mapDispatchToProps = dispatch => ({
  setGtrWorkflow: workflow => dispatch(setGtrWorkflow(workflow))
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketResolution);
