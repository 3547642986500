export const OPEN_BUNDLES = "OPEN_BUNDLES";
export const CLOSE_BUNDLES = "CLOSE_BUNDLES";

export function openBundles() {
  return {
    type: OPEN_BUNDLES
  };
}

export function closeBundles() {
  return {
    type: CLOSE_BUNDLES
  };
}
