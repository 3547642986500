import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LightGrayTypography } from "../typography";

const StyledContainer = styled.div`
  background-color: rgba(174, 195, 194, 0.13);
  padding: 2rem;
  postion: relative;
`;

const year = new Date().getFullYear();
const Footer = () => (
  <StyledContainer maxWidth={false}>
    <LightGrayTypography variant="body1" align="center">
      <Link to="/atr/privacy">Privacy Policy</Link> | © {year} Accenture. All
      Rights Reserved.
    </LightGrayTypography>
  </StyledContainer>
);

export default Footer;
