import React from "react";
import { ArcherContainer, ArcherElement } from "react-archer";
import AQETabWorkflowNode from "./Node/Node";
import AQETabWorkflowInputNode from "./InputNode/InputNode";
import PropTypes from "prop-types";
import MultiParamCard from "./MultiParamCard/MultiParamCard";
import Styles from "styles/themeMainUI";
import AQETabWorkflowNodeCard from "./Node/Card/Card";
import { Box } from "@mui/material";
import { workflowContainer, row, inputRow } from "./WorkflowStyle";

export default function AQETabWorkflow({
  inputParameters,
  nodes,
  version,
  currentNodes,
  currentInputParam
}) {
  const fetchWorkflowNode = nodeId => {
    return nodes.find(node => node.id === nodeId);
  };

  const versionArray =
    version === "V1" ? nodes : version === "V2" ? currentNodes : null;

  if (currentNodes.length > 0) {
    if (!currentNodes[0].nodeId) {
      return (
        <div
          style={{
            margin: 20,
            padding: 20,
            fontSize: 13,
            backgroundColor: Styles.palette.accent2Color,
            color: Styles.palette.accent3Color
          }}
        >
          This workflow cannot be displayed on the frontend. Please retrigger
          automation to view workflow.
        </div>
      );
    }
  }

  return (
    <Box sx={workflowContainer}>
      <ArcherContainer>
        {/* input */}
        <Box sx={inputRow} type="input" key={`row-${nodes[0]}`}>
          {inputParameters.length > 3 ? (
            <MultiParamCard
              key={`input-${nodes[0].id}`}
              type="input"
              parameters={inputParameters}
              currentParam={currentInputParam}
              nextNodeId={
                version === "V1"
                  ? nodes[0].id
                  : version === "V2" && currentNodes.length > 0
                  ? currentNodes[0]?.nodeId
                  : "more-branches"
              }
            />
          ) : (
            inputParameters.map((inputParam, index) => (
              <AQETabWorkflowInputNode
                key={`input-${nodes[0].id}-${index}`}
                type="input"
                index={index}
                param={inputParam}
                currentParam={currentInputParam}
                nextNodeId={
                  version === "V1"
                    ? nodes[0].id
                    : version === "V2" && currentNodes.length > 0
                    ? currentNodes[0]?.nodeId
                    : "more-branches"
                }
              />
            ))
          )}
        </Box>
        {/* {calculation node} */}
        {currentNodes.length > 0 || version === "V1" ? (
          versionArray.map((node, index) => {
            if (version === "V1" && node.id.includes("error")) return <></>;

            const workflowNode =
              version === "V2" ? fetchWorkflowNode(node.nodeId) : node;

            return (
              workflowNode && (
                <AQETabWorkflowNode
                  key={workflowNode?.id}
                  index={index}
                  version={version}
                  workflowNode={workflowNode}
                  workflowNodesList={nodes}
                  workloadNodesList={currentNodes}
                />
              )
            );
          })
        ) : version === "V2" && currentNodes.length === 0 ? (
          <Box sx={row} index="more-branches" key="more-branches">
            <ArcherElement
              key="more-branches"
              id="more-branches"
              style={{ marginRight: 40 }}
            >
              <AQETabWorkflowNodeCard title={"More Branches..."} />
            </ArcherElement>
          </Box>
        ) : (
          <div
            style={{
              margin: 20,
              padding: 20,
              fontSize: 13,
              backgroundColor: Styles.palette.accent2Color,
              color: Styles.palette.accent3Color
            }}
          >
            This workflow cannot be displayed on the frontend. Please retrigger
            automation to view workflow.
          </div>
        )}
      </ArcherContainer>
    </Box>
  );
}

AQETabWorkflow.propTypes = {
  inputParameters: PropTypes.array,
  outputParameters: PropTypes.array,
  steps: PropTypes.array,
  currentSteps: PropTypes.array,
  currentInputParam: PropTypes.object,
  currentOutputParam: PropTypes.object
};
