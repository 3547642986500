import React from "react";
import PropTypes from "prop-types";
import { HeaderContainer, MainContainer, SourceTypeContainer } from "./styles";
import SourceTypes from "./SourceTypes/container";
import Skeleton from "react-loading-skeleton";

const TicketSources = props => {
  const { providerFilters } = props;
  const providers = Object.keys(providerFilters);

  return (
    <MainContainer>
      <HeaderContainer>TICKET SOURCES</HeaderContainer>
      <SourceTypeContainer>
        {providers.length > 0 ? (
          providers.map(key => {
            const provider = providerFilters[key];
            return (
              <SourceTypes
                key={key}
                headingText={provider.displayName}
                ticketTypes={provider.ticketTypes}
              />
            );
          })
        ) : (
          <Skeleton count={5} />
        )}
      </SourceTypeContainer>
    </MainContainer>
  );
};

TicketSources.propTypes = {
  providerFilters: PropTypes.object.isRequired
};

export default TicketSources;
