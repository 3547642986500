import React, { PureComponent } from "react";

import { connect } from "react-redux";

import ResolutionTab from "./ResolutionTab";
import { setGtrWorkflow, setGtrWorkflowFilter } from "../../../actions/tickets";
class ResolutionTabContainer extends PureComponent {
  _returnMiddleValue = (before, after, wholeString) => {
    let query = before + "(.*)" + after;
    let rexQuery = new RegExp(query);
    let results = rexQuery.exec(wholeString);
    if (results) {
      return results[1];
    }
    return false;
  };

  _getSelectedUKnowledge(ticket) {
    return ticket.automationData.sourceJobName;
  }

  render() {
    return (
      <ResolutionTab
        returnMiddleValue={this._returnMiddleValue}
        getSelectedUKnowledge={this._getSelectedUKnowledge}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  resolution: state.details.ticketResolution,
  resolutionIsFetching: state.details.ticketResolutionIsFetching,
  permissions: state.user.permissions,
  allGtrWorkflows: state.tickets.allGtrWorkflows,
  selectedGtrWorkflow: state.tickets.selectedGtrWorkflow,
  workflowFilter: state.tickets.workflowFilter
});

const mapDispatchToProps = dispatch => {
  return {
    setGtrWorkflow: workflow => dispatch(setGtrWorkflow(workflow)),
    setGtrWorkflowFilter: filter => dispatch(setGtrWorkflowFilter(filter))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResolutionTabContainer);
