import fetch from "isomorphic-fetch";
import config from "../../config";
import history from "../../history";
import { userConfig } from "../../userConfigValidations";
import { setErr } from "../errors";
import * as actionTypes from "./actionTypes";
import { fetchUserDetailsProfile } from "../users";
import { axiosInstance as axios } from "../../utils/axios";

export const userSessionInvalid = () => {
  return {
    type: actionTypes.SESSION_INVALID
  };
};
export const userSessionValid = () => {
  return {
    type: actionTypes.SESSION_VALID
  };
};

const parseJSON = res =>
  res.text().then(text => (text ? JSON.parse(text) : {}));

function signinRequest() {
  return {
    type: actionTypes.SIGNIN_REQUEST,
    isFetching: true,
    isAuthenticated: false
  };
}

function signinSuccess(user, token) {
  token = convertToken(token);
  return {
    type: actionTypes.SIGNIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user,
    token,
    inSession: true
  };
}

function signinFailure(message) {
  return {
    type: actionTypes.SIGNIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

function logoutSuccess() {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    isAuthenticated: false,
    inSession: false
  };
}

function closeAuthSnackbar() {
  return {
    type: actionTypes.CLOSE_AUTH_SNACKBAR,
    isSnackbarOpen: false
  };
}

function signupRequest() {
  return {
    type: actionTypes.SIGNUP_REQUEST,
    isFetching: true,
    isAuthenticated: false
  };
}

function signupSuccess() {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    isSnackbarOpen: true,
    message: "User has been successfully created..."
  };
}

function signupFailure() {
  return {
    type: actionTypes.SIGNUP_FAILURE,
    isFetching: false,
    isAuthenticated: false
  };
}

export const reassignUUID = () => {
  return {
    type: actionTypes.REASSIGN_UUID
  };
};

export const convertToken = token => {
  const dateSplit = token.expirationDate.split("-");
  token.expirationDate = new Date(
    `${dateSplit[2].substring(0, 4)}-${dateSplit[1]}-${
      dateSplit[0]
    }${dateSplit[2].substring(4)}`
  );
  return token;
};

// TODO: Unused function - refactor to axios when used
export const fetchSignin = credentials => dispatch => {
  dispatch(signinRequest());
  const url = `${config.urls.authBase + config.urls.apis.auth}/short-token`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(credentials)
  };

  return fetch(url, options)
    .catch(err => {
      dispatch(signinFailure());
      dispatch(setErr(err.message));
    })
    .then(async res => {
      switch (res.status) {
        case 200:
        case 201:
          return res.json();
        default:
          throw res;
      }
    })
    .then(body => {
      if (!body) return;
      const jwt = {};
      jwt.user = credentials.username;
      jwt.type = "local";
      dispatch(signinSuccess(credentials.username, body));
      localStorage.setItem("jwt", JSON.stringify(jwt));
      dispatch(fetchUserDetailsProfile(credentials.username));

      history.push("/atr/tickets");
    })
    .catch(async res => {
      switch (res.status) {
        case 403:
        case 401:
          const { redirect, url, message } = await parseJSON(res);
          if (redirect) {
            history.push({
              pathname: `/identity-management/${url}`,
              state: { message }
            });
          } else {
            dispatch(signinFailure(message));
            dispatch(setErr("Server error"));
          }
          break;
        default:
          dispatch(signinFailure());
          dispatch(setErr("Server error. Could not login"));
          history.push("/atr/500");
      }
    });
};

export function logout() {
  return async function(dispatch) {
    await dispatch(logoutSuccess());
    await axios.post(`${config.urls.authBase}${config.urls.apis.auth}/logout`);
    try {
      sessionStorage.clear();
      const isSAML =
        localStorage.getItem("jwt") &&
        localStorage.getItem("jwt").type === "saml";
      localStorage.clear();
      if (isSAML) {
        window.location.replace(userConfig.actions.generateSAMLLogoutUrl());
      } else {
        dispatch(logoutSuccess());
        const { protocol, host } = window.location;
        window.location.assign(
          `${protocol}//${host}/identity-management/signin`
        );
      }
    } catch (err) {
      dispatch(setErr("Something went wrong."));
      const { protocol, host } = window.location;
      window.location.assign(`${protocol}//${host}/identity-management/signin`);
    }
  };
}

export function logoutMenu() {
  return async function(dispatch) {
    await dispatch(logoutSuccess());
    await axios.post(`${config.urls.authBase}${config.urls.apis.auth}/logout`);
    try {
      sessionStorage.clear();
      const isSAML =
        localStorage.getItem("jwt") &&
        localStorage.getItem("jwt").type === "saml";
      localStorage.clear();
      if (isSAML) {
        window.location.replace(userConfig.actions.generateSAMLLogoutUrl());
      } else {
        dispatch(logoutSuccess());
        const { protocol, host } = window.location;
        window.location.assign(
          `${protocol}//${host}/identity-management/signout`
        );
      }
    } catch (err) {
      dispatch(setErr("Something went wrong."));
    }
  };
}

// TODO: Unused function - refactor to axios when used
export function fetchSignup(credentials) {
  return function(dispatch) {
    dispatch(signupRequest());
    const url = `${config.urls.base +
      config.urls.apis["identity-management"]}/users?register=true`;
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(credentials)
    };
    return fetch(url, options).then(res => {
      switch (res.status) {
        case 200:
        case 201:
          dispatch(signupSuccess());
          setTimeout(() => {
            dispatch(closeAuthSnackbar());
          }, 3000);
          break;
        case 401:
        case 403:
        case 404:
        case 422:
          res
            .json()
            .then(data => ({
              data
            }))
            .then(res => {
              dispatch({
                type: actionTypes.SIGNUP_FAILURE_WITH_ERROR,
                isFetching: false,
                isAuthenticated: false,
                error: res.data.error
              });
            });
          break;
        default:
          dispatch(signupFailure());
      }
    });
  };
}

// TODO: Unused function - refactor to axios when used
export function onSubmitPasswordReset(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.USER_RESET_PASSWORD_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["identity-management"]}/users/${encodeURIComponent(
      value.username
    )}/resetPassword`;
    const options = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: {}
    };

    return fetch(url, options)
      .then(res => {
        switch (res.status) {
          case 200:
            return null;
          default:
            throw new Error("Password reset failed");
        }
      })
      .then(() => {
        // Successfully reset password

        dispatch({
          type: actionTypes.USER_RESET_PASSWORD_SUCCESS,
          isSnackbarOpen: true,
          message: "Password reset email sent."
        });
        setTimeout(() => {
          dispatch(closeAuthSnackbar());
        }, 3000);
      })
      .catch(err => {
        dispatch({
          type: actionTypes.USER_RESET_PASSWORD_ERROR,
          isSnackbarOpen: true,
          message: "Password reset failed."
        });
        setTimeout(() => {
          dispatch(closeAuthSnackbar());
        }, 3000);
      });
  };
}

export function refreshToken() {
  return function(dispatch) {
    const url = `${config.urls.authBase +
      config.urls.apis["identity-management"]}/auth/short-token`;

    let freshTokenPromise = axios({
      method: "POST",
      url,
      data:
        config.app.env !== "production"
          ? {
              password: process.env.REACT_APP_LOCAL_PASSWORD,
              username: process.env.REACT_APP_LOCAL_USERNAME,
              snowEnabled: true
            }
          : {}
    })
      .then(res => {
        const token = convertToken(res.data);
        dispatch({
          type: actionTypes.DONE_REFRESHING_TOKEN,
          token,
          inSession: true,
          isAuthenticated: true
        });
      })
      .catch(err => {
        if (err.response) {
          switch (err.response.status) {
            case 403:
            case 422:
              if (userConfig.actions.generateSAMLLoginURL("")) {
                const relayUrl = `${window.location.origin}/atr-gateway/auth/api/v1/auth/sso/${config.app.redirectName}`;
                const url = userConfig.actions.generateSAMLLoginURL(relayUrl);
                return window.location.replace(url);
              } else {
                logout()(dispatch);
              }
              break;
            default:
              return;
          }
        }
      })
      .catch(err => {
        const { protocol, host } = window.location;
        window.location.assign(
          `${protocol}//${host}/identity-management/signin`
        );
        dispatch({
          type: actionTypes.DONE_REFRESHING_TOKEN,
          inSession: false,
          isAuthenticated: false
        });
        return Promise.reject(err);
      });

    dispatch({
      // we want to keep track of token promise in the state so that we don't try to refresh
      type: actionTypes.REFRESHING_TOKEN,

      // the token again while refreshing is in process
      freshTokenPromise
    });

    return freshTokenPromise;
  };
}
