/**
 * Created by x1 on 5/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";

import DatePicker from "material-ui/DatePicker";
import areIntlLocalesSupported from "intl-locales-supported";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

let DateTimeFormat;
if (areIntlLocalesSupported(["fr", "fa-IR"])) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} else {
  const IntlPolyfill = require("intl");
  DateTimeFormat = IntlPolyfill.DateTimeFormat;
}

const muiTheme = getMuiTheme({
  fontFamily: '"Open Sans", sans-serif',
  palette: {
    primary1Color: themeMainUI.secondaryBorderedStyle.primary,
    primary2Color: themeMainUI.secondaryBorderedStyle.primary
  }
});

class PrimaryDatePicker extends React.Component {
  render() {
    const { requiredError, ...rest } = this.props;
    const renderError = typeof requiredError === "boolean" && requiredError;

    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>
          <DatePicker
            hintText={
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 5
                }}
              >
                No date selected
              </span>
            }
            formatDate={
              new DateTimeFormat("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric"
              }).format
            }
            container="inline"
            textFieldStyle={{
              width: "58%",
              fontSize: "15px",
              paddingBottom: 5,
              borderColor: renderError ? "This is required" : ""
            }}
            underlineStyle={
              renderError
                ? {
                    borderColor: themeMainUI.palette.errorColor,
                    borderWidth: 2
                  }
                : {}
            }
            {...rest}
          />
        </MuiThemeProvider>
        {renderError ? (
          <div
            style={{
              fontSize: 12,
              marginTop: -5,
              color: themeMainUI.palette.errorColor
            }}
          >
            This is required
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PrimaryDatePicker;
