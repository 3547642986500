import React from "react";
import { isEmpty, findIndex } from "lodash";
import history from "../../history";

import MenuButton from "@material-ui/icons/Menu";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/lab/Slider";
import Grid from "@material-ui/core/Grid";
import Row from "@material-ui/core/Grid";
import Col from "@material-ui/core/Grid";

import TicketOptionsCTN from "./Options/OptionsCTN";
import Footer from "../footer/Footer";
import TicketStyle from "./TicketsStyles";
import SearchBar from "../templates/SearchBar";
import SecondaryCircularProgress from "../templates/SecondaryCircularProgress";
import TicketDetailsDrawerDesktop from "../drawers/TicketDetailsDrawerDesktop";
import ServiceRequestDrawer from "../drawers/ServiceRequest/Catalog/ServiceRequestDrawer";
import styles from "../drawers/TicketDetailsDrawerStyles";
// import ServiceRequestTable from "./ServiceRequestTable";
// import ServiceRequestItemTable from "./ServiceRequestItemTable";
import Filter from "../templates/Filter";
import { DrawerBase } from "../drawers/DrawerBase";
import { userConfig } from "../../userConfigValidations";
import TicketTableCTN from "./Table/TicketTableCTN";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import lightblue from "@material-ui/core/colors/lightBlue";
import PermissionCheck from "../errors/PermissionCheck";
import BackToTop from "./BackToTop";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightblue[800]
    },
    secondary: {
      main: "#05c1af"
    }
  },
  typography: {
    useNextVariants: true
  }
});

//TODO: move styles from inline
class Tickets extends React.Component {
  state = {
    ticketTypes: [],
    tickets: [
      {
        ticket_number: 0,
        problem_abstract: "test1 description"
      },
      {
        ticket_number: 1,
        problem_abstract: "test2 description"
      }
    ],
    newTicketType: "SELECT",

    ticketsLoaded: false,
    drawerMode: "default",
    anchorEl: null,
    loading: false,

    totalTickets: 0,
    numberTicketsToExport: 0
    // exportTimer: 0
  };

  async createServiceRequest(id, values) {
    await this.props.createServiceRequest(id, values);
    this.setState({ newTicketType: "SELECT" });
  }

  componentDidMount() {
    this.props.closeBundles(); //start with bundles closed!!
    this.props.getPresetFilters(); //get presetFilters
    this.props.ticketSetPreset("");
    this.props.streamTickets();
    this.props.getAllRundecks(); // Used for RundeckMapping Component to get all rundeck instances
    this.props.getAllTemplates();
    this.props.getTemplateFields();
    this.props.getAllTicketTemplates();
    this.props.getTicketField();
    this.props.getActiveTemplates(); // FIXME: Second call is currently needed or you can't select ticket types to create new tickets.
    this.props.getActiveTemplates(
      this.props.cacheLocalPointer,
      this.props.cacheObject,
      this.props.ticketSort,
      this.props.ticketFilter
    );
    this.props.getManualResolutionLink();
    this.props.getManualResolutionConfig();
    this.props.getServiceRequestCatalog();
    this.props.getUserData();
    /* Commenting out GTR changes for now for 3.3.6.1
    if (this.props.manualResolutionConfig.gtr) {
      this.props.getAllGTRWorkflows();
    }
    */
    this.props.getAllGTRWorkflows();
    if (this.props.match.params && this.props.match.params.number) {
      if (this.props.match.params.number === "create") {
        this.props.openDetails({ isNewTicket: true });
        this.setState({
          drawerMode: "newTicket"
        });
      } else {
        const ticket = this.getTicketByTicketNumber(
          this.props.match.params.number
        );
        if (ticket) {
          this.props.openDetails(
            this.getTicketByTicketNumber(this.props.match.params.number)
          );
        } else {
          this.props.ticketGetSingle(this.props.match.params.number);
          this.props.editTicketIndicator(
            { number: this.props.match.params.number },
            "start"
          );
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.cacheObject !== nextProps.cacheObject) {
      clearTimeout(this.timeout);

      if (!nextProps.ticketsIsFetching) {
        this.startPoll(this.props.selectedTicketType);
        this.forceUpdate();
      }
      // run once
      if (!this.state.ticketsLoaded) {
        this.props.retrieveList(1, nextProps.cacheObject);
        this.setState({
          ticketsLoaded: true
        });
      }
    }
    if (
      nextProps.activeTemplates &&
      this.props.activeTemplates !== nextProps.activeTemplates
    ) {
      const templateTypes = Object.keys(nextProps.activeTemplates) // convert to keys Array
        .sort((a, b) => {
          return nextProps.activeTemplates[a].name <
            nextProps.activeTemplates[b].name
            ? -1
            : 0;
        });
      const selectedTicketType = nextProps.selectedTicketType
        ? nextProps.selectedTicketType
        : templateTypes[0];
      this.changeTicketType(selectedTicketType);
      const ticketTypes = this.props.activeTemplates
        ? Object.keys(this.props.activeTemplates)
            .filter(ticketType => {
              return this.props.activeTemplates[ticketType].ticketSource
                .canCreate;
            })
            .map(ticketType => {
              return {
                type: ticketType,
                displayName: this.props.activeTemplates[ticketType].name
              };
            })
        : [];
      this.setState({
        ticketTypes
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.props.closeTicketStream();
  }

  reducer = typePriority => {
    return (accum, current) => {
      accum[current] = typePriority.indexOf(current);
      return accum;
    };
  };

  sortValue = (a, b) => {
    return a.value - b.value;
  };

  // Polls server to refresh cache
  startPoll = selectedTicketType => {
    this.timeout = setTimeout(
      () =>
        this.props.moveTicketCachePointer(
          0,
          this.props.cacheLocalPointer,
          this.props.cacheObject,
          this.props.ticketSort,
          this.props.ticketFilter,
          selectedTicketType,
          this.props.ticketTimeRange,
          this.props.presetSelected,
          true
        ),
      120000
    );
  };

  getTicketByTicketNumber = ticketNumber => {
    return this.props.tickets
      ? this.props.tickets[
          findIndex(this.props.tickets, ticket => {
            return ticket.number === ticketNumber;
          })
        ]
      : null;
  };

  createNewTicket = () => {
    this.props.openDetails({ isNewTicket: true });
  };

  handleCloseDetails = () => {
    this.props.closeDetailsTicket(this.props.ticketNumber);
    // this.props.closeDetails();
    this.setState({
      drawerMode: "default",
      newTicketType: "SELECT"
    });
    this.props.ticketModifySingleJobDefinition({});
    history.push("/atr/tickets");
  };

  isEmptyObject = o => {
    if (o) {
      return Object.keys(o).every(x => {
        return o[x] === "" || o[x] === null; // or just "return o[x];" for falsy values
      });
    }
    return true;
  };

  handleDialogInputChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleDialogClose = () => {
    this.setState({
      exportFileName: "",
      openDialog: false, //can probably use the exportErrorMsg variable
      exportErrorMsg: "",
      chosenNumberExportTickets: 0
    });
  };

  getTitles = () => {
    let mapping = { state: "State" };
    if (this.props.activeTemplates[this.props.selectedTicketType]) {
      if (
        this.props.activeTemplates[this.props.selectedTicketType].primaryFields
      ) {
        Object.values(
          this.props.activeTemplates[this.props.selectedTicketType]
            .primaryFields
        ).map(item => {
          return (mapping[item.name] = item.displayName);
        });
      }
      if (
        this.props.activeTemplates[this.props.selectedTicketType]
          .secondaryFields
      ) {
        Object.values(
          this.props.activeTemplates[this.props.selectedTicketType]
            .secondaryFields
        ).map(item => {
          return (mapping[item.name] = item.displayName);
        });
      }
    }
    return mapping;
  };

  renderDialogRow = (key, value) => {
    return (
      (value || key === "Total Filtered Tickets") && (
        <div
          className="dialog-row"
          style={{ display: "flex", paddingTop: "10px" }}
          key={key}
        >
          <div className="dialog-key" style={{ width: "30%" }}>
            {key}:
          </div>
          <div className="dialog-value" style={{ flex: 1 }}>
            {value}
          </div>
        </div>
      )
    );
  };

  //note this also limits the number of tickets a user will export
  changeSliderValue = (event, value) => {
    this.setState({ numberTicketsToExport: value });
  };

  renderDialog = () => {
    let mapping = this.getTitles();
    let totalTickets = parseInt(this.props.totalTickets);

    return (
      <Dialog
        open={this.props.isExportDialogOpen}
        onClose={() => this.props.openExportDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{"Export Parameters"}</DialogTitle>
        <div
          className="dialog-container"
          style={{ width: "800px", paddingLeft: "30px" }}
        >
          {this.props.exportErrorMsg && (
            <div style={{ color: "red", fontSize: "14px" }}>
              <div> {this.props.exportErrorMsg}</div>
              <div> Try limiting your date range or filters further </div>
            </div>
          )}
          {this.renderDialogRow("Total Filtered Tickets", totalTickets)}
          <div
            className="dialog-row"
            style={{ display: "flex", paddingTop: "10px" }}
            key="Number Tickets"
          >
            <div className="dialog-key" style={{ width: "30%" }}>
              Number to Export:
            </div>
            <div className="slider-value" style={{ width: "7%" }}>
              {this.state.numberTicketsToExport}
            </div>
            <div className="dialog-value" style={{ width: "53%" }}>
              <Slider
                value={this.state.numberTicketsToExport}
                min={0}
                max={totalTickets}
                step={1}
                onChange={(event, value) => {
                  this.changeSliderValue(event, value);
                }}
              />
            </div>
          </div>
          <div
            className="dialog-row-input"
            style={{
              display: "flex",
              height: "28px",
              lineHeight: "28px",
              paddingTop: "4px"
            }}
          >
            <div className="dialog-key" style={{ width: "30%" }}>
              File Name:
            </div>
            <Input
              className="dialog-input"
              style={{ flex: 2 }}
              placeholder="ATR_Ticket_Data_Export"
              value={this.props.exportFileName}
              onChange={event =>
                this.props.setExportFileName(event.target.value)
              }
            />
            <div
              className="dialog-value"
              style={{ flex: 1, paddingLeft: "5px" }}
            >
              .csv
            </div>
          </div>
          {this.renderDialogRow("Ticket Type", this.props.selectedTicketType)}
          {!this.isEmptyObject(this.props.ticketTimeRange) && (
            <div
              className="dialog-row"
              style={{ display: "flex", paddingTop: "10px" }}
            >
              <div className="dialog-key" style={{ width: "30%" }}>
                Date Range:
              </div>
              <div style={{ flex: 2 }}>
                {this.props.ticketTimeRange.start
                  ? `${this.props.ticketTimeRange.start} `
                  : "No start date "}
              </div>
              <div style={{ flex: 1, alignText: "center" }}>to</div>
              <div style={{ flex: 2 }}>
                {this.props.ticketTimeRange.end
                  ? `${this.props.ticketTimeRange.end}`
                  : " No end date"}
              </div>
            </div>
          )}
          {this.props.presetSelected &&
            this.props.presetSelected !== "" &&
            this.renderDialogRow("Preset Filters", this.props.presetSelected)}
          {Object.keys(this.props.ticketFilter).map(item => {
            return this.renderDialogRow(
              mapping[item],
              this.props.ticketFilter[item]
            );
          })}
          {this.props.isFetchingExports && (
            <div
              style={{
                paddingTop: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}
            >
              <CircularProgress />
              Retrieving export tickets .... this may take 30 seconds or more
              for large files!
            </div>
          )}
        </div>
        <DialogActions>
          <Button
            onClick={() => this.props.openExportDialog(false)}
            color="primary"
            disabled={this.props.isFetchingExports}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.exportTickets(
                this.state.numberTicketsToExport,
                this.props.selectedTicketType,
                this.props.ticketFilter,
                this.props.ticketSort,
                this.props.ticketTimeRange,
                this.props.exportFileName,
                this.props.presetSelected
              );
            }}
            color="primary"
            autoFocus
            disabled={this.props.isFetchingExports}
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  _filterPress = (newFilter, ticketType) => {
    this.props.ticketSetFilter(newFilter);
    this.props.moveTicketCachePointer(
      0,
      this.props.cacheLocalPointer,
      this.props.cacheObject,
      this.props.ticketSort,
      { ...this.props.ticketFilter, ...newFilter },
      ticketType || this.props.selectedTicketType,
      this.props.ticketTimeRange
    );
  };

  _toggleBundles = () => {
    const { isBundlesOpen, closeBundles, openBundles } = this.props;
    isBundlesOpen ? closeBundles() : openBundles();
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value }, () => this.forceUpdate());
  };

  setFilters = value => {
    let { activeTemplates } = this.props;
    let filters = [];
    let fields = { state: "" };

    if (!activeTemplates) return;

    if (activeTemplates[value]) {
      if (activeTemplates[value].primaryFields) {
        activeTemplates[value].primaryFields.forEach(item => {
          filters.push(item);
          fields[item.name] = "";
        });
      }
      if (activeTemplates[value].secondaryFields) {
        activeTemplates[value].secondaryFields.forEach(item => {
          filters.push(item);
          fields[item.name] = "";
        });
      }
    }
  };

  changeTicketType = value => {
    localStorage.removeItem("customisedColumnDisable");
    let date = {};
    let newFilter = { state: "" };

    Object.keys({ ...this.props.ticketFilter }).map(item => {
      return (newFilter[item] = "");
    });

    this.props.toggleShowMoreFilters(false);
    this.props.ticketSetPreset(""); //clear preset when changing ticketType
    this.props.ticketSetFilter(newFilter);
    this.props.ticketSetTimeRange(date);
    this.props.moveTicketCachePointer(
      0,
      this.props.cacheLocalPointer,
      this.props.cacheObject,
      this.props.ticketSort,
      { ...this.props.ticketFilter, ...newFilter },
      value,
      date,
      "default" //empty when changing ticket types
    );

    clearTimeout(this.timeout);

    this.props.streamTickets();
    this.props.setTicketType(value);
    this.startPoll(value);
    let state = "";

    this.setFilters(value);
    this._filterPress({ state }, value);
    if (this.props.isDetailsOpen && this.state.ticketsLoaded) {
      this.handleCloseDetails();
    }
  };

  renderBundlesList = () => {
    let stateFilters = [];
    try {
      if (!this.props.activeTemplates) return;

      const stateItem = this.props.activeTemplates[
        this.props.selectedTicketType
      ].states;
      stateFilters = stateItem.map(item => {
        return {
          name: item,
          filterKey: item,
          action: () => {
            this._filterPress({ state: item });
          },
          highlight: () => {
            return item === this.props.ticketFilter.state;
          }
        };
      });
      stateFilters.unshift({
        name: "Show All",
        filterKey: "",
        action: () => {
          this._filterPress({ state: "" });
        },
        highlight: () => {
          return "" === this.props.ticketFilter.state;
        }
      });
    } catch (err) {}

    const userConfigFilters = userConfig.variables.sidebarFilters.map(item => {
      const filter = {
        action: str => {
          this._filterPress({ [item.key]: str });
        },
        dataSource: isEmpty(this.props.user)
          ? []
          : item.dataSource(this.props.user),
        type: item.type,
        name: item.key
      };

      return (
        <Filter
          style={TicketStyle["BundleHeading"]}
          name={item.name}
          filter={filter}
          key={item.key}
          ticketFilter={this.props.ticketFilter}
        />
      );
    });

    return userConfigFilters;
  };

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  renderTableHeader = () => {
    if (this.props.activeTemplates) {
      return (
        <Grid container style={{ alignItems: "center", paddingLeft: 5 }}>
          <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
            <Grid item xs={2} sm={2}>
              <h3 style={{ margin: 0 }}>
                <IconButton onClick={this._toggleBundles}>
                  <MenuButton />
                </IconButton>
                Tickets
              </h3>
            </Grid>
            <Grid item xs={10} sm={10}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={5} sm={5}>
                  <Select
                    value={this.props.selectedTicketType}
                    onChange={event =>
                      this.changeTicketType(event.target.value)
                    }
                    style={{ width: "100%" }}
                  >
                    {Object.keys(this.props.activeTemplates)
                      .sort((a, b) => {
                        return this.props.activeTemplates[a].name <
                          this.props.activeTemplates[b].name
                          ? -1
                          : 0;
                      })
                      .map(key => (
                        <MenuItem
                          value={this.props.activeTemplates[key].ticketType}
                          key={key}
                        >
                          {this.props.activeTemplates[key].name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SearchBar
                    openDetails={this.props.openDetails}
                    enableExport={this.toggleIsFiltered}
                    ticketStatus={
                      this.props.activeTemplates[this.props.selectedTicketType]
                    }
                    {...this.props}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div
            item
            style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}
          >
            <MuiThemeProvider theme={theme}>
              <Grid container justify="flex-end">
                {/* <Col xs> */}

                <TicketOptionsCTN />
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: 5,
                    fontWeight: 300,
                    height: 20
                  }}
                  onClick={() => {
                    this.setState({
                      numberTicketsToExport: parseInt(this.props.totalTickets)
                    });
                    this.props.openExportDialog(true);
                  }}
                >
                  <ArrowDownward style={{ fontSize: 18 }} />
                  Export
                </Button>
                {this.props.permissions.includes("TM_TICKET_WRITE_CREATE") && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginRight: 5,
                      fontWeight: 300,
                      height: 20
                    }}
                    onClick={() => {
                      this.createNewTicket();
                      this.forceUpdate();
                      history.push("/atr/tickets/create");
                    }}
                  >
                    <Add style={{ fontSize: 18 }} />
                    New ticket
                  </Button>
                )}
              </Grid>
            </MuiThemeProvider>
          </div>
        </Grid>
      );
    } else {
      return (
        <div>
          <h3 style={{ margin: 0 }}>
            <IconButton onClick={this._toggleBundles}>
              <MenuButton />
            </IconButton>
            Tickets
          </h3>
        </div>
      );
    }
  };

  renderTable = () => {
    const isBundlesOpen = this.props.isBundlesOpen;
    return (
      <Grid container style={{ paddingLeft: 10 }}>
        {isBundlesOpen && (
          <Grid item xs={3} sm={2}>
            <div>
              <List className="bundles">{this.renderBundlesList()}</List>
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={isBundlesOpen ? 9 : 12}
          sm={isBundlesOpen ? 10 : 12}
          style={{ flexDirection: "column", paddingTop: 8 }}
        >
          {this.props.isCacheRefreshing ? (
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%"
              }}
            >
              <Grid
                item
                xs={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <SecondaryCircularProgress
                  style={{ paddingTop: "5em" }}
                  size={80}
                  thickness={6}
                />
              </Grid>
            </Grid>
          ) : (
            <MuiThemeProvider theme={theme}>
              <TicketTableCTN handleRowSelection={this.handleRowSelection} />
            </MuiThemeProvider>
          )}
        </Grid>
      </Grid>
    );
  };

  renderTicketDetails = (ticket, ticketType, activeTemplate) => {
    const { isDetailsOpen } = this.props;
    const template = ticketType
      ? this.props.activeTemplates[ticketType]
      : activeTemplate;

    if (isDetailsOpen) {
      if (ticket && ticket.coreData) {
        return (
          <TicketDetailsDrawerDesktop
            key={ticket.coreData.id + ticket.coreData.lastUpdateDate}
            defaultTicket={ticket}
            ticketType={ticketType ? ticketType : null}
            template={
              ticketType
                ? this.props.activeTemplates[ticketType]
                : activeTemplate
            }
            activeTemplates={this.props.activeTemplates}
            addToContinuousML={this.props.addToContinuousML}
            autocompleteFields={this.props.autocompleteFields}
            cacheLocalPointer={this.props.cacheLocalPointer}
            getMappingJob={this.props.getMappingJob}
            getRundeckProjects={this.props.getRundeckProjects}
            getServiceRequest={this.props.getServiceRequest}
            getServiceRequestItems={this.props.getServiceRequestItems}
            getServiceRequestTasks={this.props.getServiceRequestTasks}
            isAutomating={this.props.isAutomating}
            isOpenEmailDialog={this.props.isOpenEmailDialog}
            isTicketSingleCreating={this.props.isTicketSingleCreating}
            jobSelected={this.props.jobSelected}
            manualResolutionConfig={this.props.manualResolutionConfig}
            manualResolutionLink={this.props.manualResolutionLink}
            mappingFound={this.props.mappingFound}
            moveTicketCachePointer={this.props.moveTicketCachePointer}
            openAllMappings={this.props.openAllMappings}
            openDetails={this.props.openDetails}
            openSnackbar={this.props.openSnackbar}
            parsedTicket={this.props.parsedTicket}
            permissions={this.props.permissions}
            refreshDetails={this.props.refreshDetails}
            refreshing={this.props.refreshing}
            selectedCategory={this.props.selectedCategory}
            selectedGtrWorkflow={this.props.selectedGtrWorkflow}
            selectedTicketType={this.props.selectedTicketType}
            serviceRequest={this.props.serviceRequest}
            srItems={this.props.srItems}
            srTasks={this.props.srTasks}
            ticketClearSingleJobDefinition={
              this.props.ticketClearSingleJobDefinition
            }
            ticketCreateSingle={this.props.ticketCreateSingle}
            ticketError={this.props.ticketError}
            ticketFieldAutocomplete={this.props.ticketFieldAutocomplete}
            ticketGetActivities={this.props.ticketGetActivities}
            ticketGetActivitiesTypes={this.props.ticketGetActivitiesTypes}
            ticketGetResolution={this.props.ticketGetResolution}
            ticketGetSingle={this.props.ticketGetSingle}
            ticketGetSingleJobDefinition={
              this.props.ticketGetSingleJobDefinition
            }
            ticketIsUpdating={this.props.ticketIsUpdating}
            ticketNumber={this.props.ticketNumber}
            ticketRunSingleJob={this.props.ticketRunSingleJob}
            ticketUpdateSingle={this.props.ticketUpdateSingle}
            toggleEmailDialog={this.props.toggleEmailDialog}
            updateMappingFound={this.props.updateMappingFound}
          />
        );
      } else {
        //when create a new ticket
        //populate the necessary fields for a new ticket

        const newTicket = {
          newFields: template.primaryFields.reduce(
            (acc, field) => ({
              ...acc,
              [field.name]: ""
            }),
            {}
          ),
          isNewTicket: true
        };

        return (
          <TicketDetailsDrawerDesktop
            key={ticketType ? ticketType : null}
            defaultTicket={newTicket}
            ticketType={ticketType ? ticketType : null}
            template={
              ticketType
                ? this.props.activeTemplates[ticketType]
                : activeTemplate
            }
            activeTemplates={this.props.activeTemplates}
            addToContinuousML={this.props.addToContinuousML}
            autocompleteFields={this.props.autocompleteFields}
            cacheLocalPointer={this.props.cacheLocalPointer}
            getMappingJob={this.props.getMappingJob}
            getRundeckProjects={this.props.getRundeckProjects}
            getServiceRequest={this.props.getServiceRequest}
            getServiceRequestItems={this.props.getServiceRequestItems}
            getServiceRequestTasks={this.props.getServiceRequestTasks}
            isAutomating={this.props.isAutomating}
            isOpenEmailDialog={this.props.isOpenEmailDialog}
            isTicketSingleCreating={this.props.isTicketSingleCreating}
            jobSelected={this.props.jobSelected}
            manualResolutionConfig={this.props.manualResolutionConfig}
            manualResolutionLink={this.props.manualResolutionLink}
            mappingFound={this.props.mappingFound}
            moveTicketCachePointer={this.props.moveTicketCachePointer}
            openAllMappings={this.props.openAllMappings}
            openDetails={this.props.openDetails}
            openSnackbar={this.props.openSnackbar}
            parsedTicket={this.props.parsedTicket}
            permissions={this.props.permissions}
            refreshDetails={this.props.refreshDetails}
            refreshing={this.props.refreshing}
            selectedCategory={this.props.selectedCategory}
            selectedGtrWorkflow={this.props.selectedGtrWorkflow}
            selectedTicketType={this.props.selectedTicketType}
            serviceRequest={this.props.serviceRequest}
            srItems={this.props.srItems}
            srTasks={this.props.srTasks}
            ticketClearSingleJobDefinition={
              this.props.ticketClearSingleJobDefinition
            }
            ticketCreateSingle={this.props.ticketCreateSingle}
            ticketError={this.props.ticketError}
            ticketFieldAutocomplete={this.props.ticketFieldAutocomplete}
            ticketGetActivities={this.props.ticketGetActivities}
            ticketGetActivitiesTypes={this.props.ticketGetActivitiesTypes}
            ticketGetResolution={this.props.ticketGetResolution}
            ticketGetSingle={this.props.ticketGetSingle}
            ticketGetSingleJobDefinition={
              this.props.ticketGetSingleJobDefinition
            }
            ticketIsUpdating={this.props.ticketIsUpdating}
            ticketNumber={this.props.ticketNumber}
            ticketRunSingleJob={this.props.ticketRunSingleJob}
            ticketUpdateSingle={this.props.ticketUpdateSingle}
            toggleEmailDialog={this.props.toggleEmailDialog}
            updateMappingFound={this.props.updateMappingFound}
          />
        );
      }
    }
  };

  populateCreateFields = (ticket, template, ticketSources) => {
    let createFields = [];
    (template.primaryFields || []).forEach(field => {
      ticketSources.forEach(source => {
        // Remove any duplicate keys from the create form. This might happen when there is the same field in coreData
        // and serviceData of the ticket. We prioritise viewing and editing the coreData field over the serviceData
        // field.
        if (
          ticket[source][field.name] !== undefined &&
          createFields.findIndex(x => x.name === field.name) === -1
        ) {
          createFields.push({
            ...field,
            source: source
          });
        }
      });
      if (createFields.findIndex(x => x.name === field.name) === -1) {
        if (ticket["coreData"].hasOwnProperty(field.name)) {
          createFields.push({
            ...field,
            source: "coreData"
          });
        } else {
          createFields.push({
            ...field,
            source: "serviceData"
          });
        }
      }
    });

    return createFields;
  };

  populateOtherFields = (ticket, template, ticketSources) => {
    let otherFields = {
      automationFields: [
        {
          name: "ticket_management",
          displayName:
            userConfig.variables.ticketManagementDisplayName ||
            "Ticket Management",
          type: "text",
          defaultValue: "Rundeck"
        },
        {
          name: "u_knowledge",
          displayName:
            userConfig.variables.knowledgebaseDisplayName ||
            "Knowledge Base ID",
          type: "text",
          defaultValue: null,
          hideField: false
        },
        {
          name: "gtr",
          displayName: userConfig.variables.atsDisplayName || "GTR",
          type: "text",
          defaultValue: null,
          hideField: false
        }
      ],
      propertiesFields: []
    };

    (template.secondaryFields || []).forEach(field => {
      let found = false;

      ticket.mlData.forEach(mlField => {
        if (mlField.key === field.name) {
          found = true;
          otherFields.automationFields.unshift(field);
        }
      });

      if (!found) {
        if (ticket["coreData"].hasOwnProperty(field.name)) {
          otherFields.propertiesFields.push({
            ...field,
            source: "coreData"
          });
        } else {
          otherFields.propertiesFields.push({
            ...field,
            source: "serviceData"
          });
        }
      }
    });

    return otherFields;
  };

  renderNewTicket = ticket => {
    const ticketType = Object.keys(this.props.activeTemplates).find(
      ticketType => {
        return ticketType === this.state.newTicketType;
      }
    );

    return (
      <Grid container>
        <Col xs={12}>
          <Row style={styles.contentHeader}>
            <h1 style={styles.ticketNumber}>New Ticket</h1>
            <Grid container>
              <Grid item xs={3}>
                Ticket type
              </Grid>
              <Grid item xs={9}>
                <Select
                  value={this.state.newTicketType}
                  onChange={event =>
                    this.handleChange("newTicketType", event.target.value)
                  }
                >
                  {this.state.ticketTypes.map(ticket => {
                    return (
                      this.props.activeTemplates &&
                      this.props.activeTemplates[ticket.type] && (
                        <MenuItem value={ticket.type} key={ticket.type}>
                          {ticket.displayName}
                        </MenuItem>
                      )
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          </Row>

          {// Display the correct ticket template
          //TODO: Clean this up with activeTemplate, instead of reading props.
          this.state.newTicketType === "SERVICE_REQUEST" && (
            <ServiceRequestDrawer
              {...this.props}
              createServiceRequest={this.createServiceRequest.bind(this)}
            />
          )}
          {ticketType && this.renderTicketDetails(ticket, ticketType)}
        </Col>
      </Grid>
    );
  };

  renderDrawer = ticket => {
    // drawerTicketType prioritises ticket object and falls back to the selected tabs
    const drawerTicketType =
      (ticket && ticket.coreData && ticket.coreData.type) ||
      this.props.selectedTicketType;
    const activeTemplate = this.props.activeTemplates
      ? this.props.activeTemplates[drawerTicketType]
      : {};
    return (
      ticket && (
        <DrawerBase
          isDetailsOpen={this.props.isDetailsOpen}
          handleCloseDetails={this.handleCloseDetails}
          customWidth={ticket.isNewTicket ? false : true}
          activeTemplate={activeTemplate}
          ticket={ticket}
        >
          {ticket.isNewTicket
            ? this.renderNewTicket(ticket)
            : // Display ticket
              activeTemplate &&
              this.renderTicketDetails(ticket, null, activeTemplate)}
        </DrawerBase>
      )
    );
  };

  render() {
    return (
      <div
        style={{
          height: "calc(100vh - 60px)",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column"
        }}
        id="ticket-table"
      >
        <PermissionCheck
          requiredPermissions={["TM_TICKET_READ"]}
          permissions={this.props.permissions}
        >
          <Grid container style={{ padding: "20px" }}>
            {this.renderTableHeader()}
            {this.renderTable()}
            {this.props.isExportDialogOpen && this.renderDialog()}
          </Grid>
          {this.renderDrawer(this.props.ticketNumber)}
          {!this.props.isCustomizationTableOpen && <BackToTop />}
        </PermissionCheck>
        <Col style={{ marginTop: "auto" }} className="hidden-xs">
          <Footer />
        </Col>
      </div>
    );
  }
}
localStorage.removeItem("customisedColumnDisable");
export default Tickets;
