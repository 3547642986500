import React from "react";
import PrimaryDropDownMenu from "../../templates/PrimaryDropDownMenu";
import SecondaryAutoComplete from "../../templates/SecondaryAutoComplete";
import { MenuItem } from "material-ui";
import { userConfig } from "../../../userConfigValidations";
import { grey500, red300 } from "material-ui/styles/colors";
import SelectAutoComplete from "../SelectAutoComplete";

export const MLInput = props => {
  const {
    MLItem,
    templateItem,
    drawerFunctions,
    handleTicketUpdate,
    ticket,
    handleReplaceTicket
  } = props;

  const atsHint = {
    fontSize: "0.8rem",
    color: props.atsInvalid ? red300 : grey500,
    fontStyle: "italic",
    fontWeight: 800
  };

  const KBOverride = (chosenRequest, currentTicket) => {
    const tmpTicket = JSON.parse(JSON.stringify(currentTicket));
    const userOverridArray = userConfig.actions.overrideKBIDAutoComplete(
      chosenRequest,
      tmpTicket
    );
    handleReplaceTicket(userOverridArray.ticket);
    handleTicketUpdate({
      field: {
        name: "u_knowledge",
        source: "automationData"
      },
      value: userOverridArray.u_knowledge_autoComplete
    });
  };
  const updateMLInput = searchText => {
    handleTicketUpdate({
      field: {
        name: MLItem.key
      },
      value: searchText
    });
    drawerFunctions.ticketFieldAutocomplete(MLItem.key, searchText, ticket);
  };

  const _handleNewRequest = (chosenRequest, index) => {
    if (MLItem.key === "u_knowledge" && index !== -1) {
      KBOverride(chosenRequest, ticket);
    } else {
      handleTicketUpdate({
        field: {
          name: MLItem.key
        },
        value: chosenRequest
      });
    }
  };

  const _returnTicketValue = (ticket, MLItem) => {
    switch (MLItem.key) {
      case "u_knowledge":
        return ticket.automationData.sourceJobName;
      case "ticket_management":
        return ticket.automationData.sourceServiceName;
      case "gtr":
        return MLItem.userValue || ticket.serviceData.gtr;
      default:
        return (
          ticket.coreData[MLItem.key] ||
          ticket.serviceData[MLItem.key] ||
          MLItem.userValue
        );
    }
  };

  switch (templateItem.type) {
    case "dropdown-search":
      const currentValue = _returnTicketValue(ticket, MLItem);
      return (
        <div style={{ minWidth: "320px" }}>
          <SelectAutoComplete
            options={[
              ...(templateItem.values
                ? templateItem.values.map(val => ({ value: val, label: val }))
                : [])
            ]}
            value={
              currentValue
                ? {
                    label: currentValue,
                    value: currentValue
                  }
                : null
            }
            onChange={selectedItem => {
              updateMLInput((selectedItem && selectedItem.value) ?? "");
            }}
            invalid={
              currentValue && !templateItem.values.includes(currentValue)
            }
          />
        </div>
      );
    case "dropdown":
      return (
        <PrimaryDropDownMenu
          id={templateItem.name}
          style={{ paddingLeft: "0px" }}
          value={_returnTicketValue(ticket, MLItem)}
          onChange={(event, key, value) => updateMLInput(value)}
        >
          {templateItem.values.map((option, optionIndex) => (
            <MenuItem key={optionIndex} value={option} primaryText={option} />
          ))}
        </PrimaryDropDownMenu>
      );
    default:
      const searchText = _returnTicketValue(ticket, MLItem);
      return (
        <SecondaryAutoComplete
          id={MLItem.key}
          hintText={
            templateItem.defaultValue
              ? `Default value: ${templateItem.defaultValue.toLowerCase()}`
              : `Enter ${templateItem.displayName.toLowerCase()} here`
          }
          errorText={
            MLItem.key === "gtr" &&
            props.manualResolutionConfig &&
            !props.manualResolutionConfig.gtr
              ? "*format: BP - BPS - WFID"
              : null
          }
          errorStyle={atsHint}
          listStyle={{ width: 400 }}
          searchText={searchText}
          onNewRequest={(chosenRequest, index) => {
            _handleNewRequest(chosenRequest, index);
          }}
          onUpdateInput={(searchText, dataSource, params) => {
            updateMLInput(searchText);
          }}
          maxSearchResults={5}
          dataSource={drawerFunctions.autocompleteFields[MLItem.key] || []}
          filter={(searchText, key) => {
            return true;
          }}
          fullWidth={false}
          floatingLabelFixed
          value={MLItem.value}
        />
      );
  }
};
