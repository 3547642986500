import React from "react";
import PrimaryButton from "../templates/PrimaryButton";
import PrimaryDropDownMenu from "../templates/PrimaryDropDownMenu";
import { Dialog, FlatButton, MenuItem } from "material-ui";
import ATRFormField from "../templates/ATRFormField";
import SecondaryTextField from "../templates/SecondaryTextField";
import ATRChipInput from "../templates/ATRChipInput";
import muiTheme from "../../styles/themeMainUI";
import _ from "lodash";
import RTEditor, { html, initialValue } from "./RTEditor/RTEditor";

// Styles
const titleStyle = {
  margin: 0,
  padding: "0 0 0 30px",
  backgroundColor: muiTheme.palette.accent2Color
};

const cancelButtonStyle = {
  marginRight: "5px",
  bottom: "2px"
};

// Initial State
const initialState = {
  email: {
    address: "",
    content: ""
  },
  isPreviewWithMergeTags: false,
  contentWithMergeTags: {},
  article: "none",
  articlePreview: "",
  value: initialValue,
  error: null
};

export default class EmailDialog extends React.Component {
  state = {
    ...initialState
  };
  constructor(props) {
    super(props);

    this.props.getEmailTemplates(0, 200);
    this.props.getMergeTags();
    this.props.getKnowledgebaseArticles();

    this._handleEditorChange = this._handleEditorChange.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.ticket !== this.state.ticket) {
      // Ticket in state is the flatten version of the ticket details (key, value) pair
      this.setState({
        ticket: nextProps.ticket,
        ticketFlattened: EmailDialog.flattenTicketData(nextProps.ticket)
      });
    }
  }

  // Helper function to flatten 1 level deep ticket data
  static flattenTicketData(ticket) {
    const flatData = {};

    Object.keys(ticket).forEach(item => {
      const nestedData = Object.keys(ticket[item]);

      nestedData.forEach(val => {
        flatData[val] = ticket[item][val];
      });
    });

    return flatData;
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  _sendEmail() {
    const { isValid, emailForm } = this._addMergeTags(this.state.email);
    if (!emailForm.address) {
      this.setState({ error: "No Recipient" });
      return;
    }
    if (!this.validateEmail(emailForm.address)) {
      return this.setState({ error: "Not a valid email." });
    }
    if (isValid) {
      // Adding knowledgebase article if selected
      // emailForm.content += this.state.articlePreview;
      emailForm.content = html.serialize(this.state.value);
      // TODO: Fix hack workaround for converting cc string to array
      const tmpCc = emailForm.cc;
      const tmpBcc = emailForm.bcc;
      const tmpTo = emailForm.address;

      emailForm.cc = [];
      if (tmpCc) emailForm.cc.push(tmpCc);

      emailForm.bcc = [];
      if (tmpBcc) emailForm.bcc.push(tmpBcc);

      emailForm.address = [];
      if (tmpTo) emailForm.address.push(tmpTo);

      // Send email
      this.props.sendEmail(emailForm);

      // Clearing object state after sending email
      this.setState({ ...initialState });
      this.props.toggleEmailDialog();
    }
  }

  _handleChange(key, value) {
    if (key === "template") {
      const t = this.props.emailTemplates[value];
      this.setState({ error: null });
      return this.setState(
        {
          email: {
            ...this.state.email,
            subject: t.subject,
            content: t.content,
            template: t.name
          }
        },
        this.insertValue
      );
    }

    this.setState({
      email: { ...this.state.email, [key]: value }
    });
  }

  _handleKBChange(article) {
    this.setState(
      {
        article,
        articlePreview: article.content ? `\n\n---\n\n${article.content}` : ""
      },
      this.insertValue
    );
  }

  _handleEditorChange({ value }) {
    this.setState({ value });
  }

  _closeEmailDialog() {
    this.setState({ ...initialState });
    this.props.toggleEmailDialog();
  }

  insertValue() {
    const { document } = html.deserialize(
      this.state.isPreviewWithMergeTags
        ? this.state.contentWithMergeTags
        : this.state.email.content + this.state.articlePreview
    );
    const change = this.state.value
      .change()
      .selectAll()
      .delete()
      .insertFragment(document);
    this._handleEditorChange(change);
  }

  // TODO: Probably good to refactor this to make a bit more sense...
  // Used for either toggle preview of mergeTags in an email template or fill in the variables object for sending email
  _addMergeTags(emailForm) {
    if (_.isEmpty(emailForm.content)) {
      return;
    }
    emailForm.variables = [];

    // Find and replace all tags with fields
    //const exp = new RegExp("\\|.+?\\:\\|", "g");
    const exp = new RegExp("\\|[0-z]+[^\\|]\\:\\|", "g");
    const emailMergeTags = emailForm.content.match(exp);

    let mergeTags;
    // Cannot find any merge tags in the form.
    if (emailMergeTags === null) {
      mergeTags = [];
    } else {
      mergeTags = this.props.mergeTags.filter(tag =>
        emailMergeTags.map(tag => tag.slice(1, -2)).includes(tag.key)
      );
    }

    if (this.isValid(mergeTags)) {
      // Find the tag in mergeTags
      mergeTags.forEach(mergeTag => {
        emailForm.variables.push({
          name: mergeTag.key,
          value: this.state.ticketFlattened[mergeTag.value]
        });
      });

      return { isValid: true, emailForm };
    } else {
      return { isValid: false, emailForm };
    }
  }

  isValid = mergeTags => {
    const mergeTagWithSelectedType = this.props.mergeTags.filter(
      tag =>
        tag.type === this.props.selectedTicketType && tag.required === "true"
    );

    //For every required tag, there exist a tag in the template whose name is the same
    const isValid = mergeTagWithSelectedType.every(tag =>
      mergeTags.some(mergeTag => mergeTag.name === tag.name)
    );

    if (!isValid) {
      const missingTag = mergeTagWithSelectedType
        .filter(tag => mergeTags.every(mergeTag => mergeTag.name !== tag.name))
        .map(tag => tag.name);
      this.setState({
        error: `Required tag${
          missingTag.length > 1 ? "s" : ""
        } ${missingTag.map(tag => `"${tag}"`)} ${
          missingTag.length > 1 ? "are" : "is"
        } missing`
      });
    }
    return isValid;
  };

  componentWillUnmount = () => {
    this.setState({ error: null });
  };

  render() {
    const { email } = this.state;
    const title = () => (
      <div style={titleStyle}>
        <ATRFormField formLabel="Email Template *">
          <PrimaryDropDownMenu
            value={email && email.template}
            onChange={(e, k, v) => this._handleChange("template", k)}
          >
            {this.props.emailTemplates.map((item, i) => (
              <MenuItem key={i} value={item.name} primaryText={item.name} />
            ))}
          </PrimaryDropDownMenu>
        </ATRFormField>
      </div>
    );

    const actions = () => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div style={{ color: "red", marginLeft: "10px" }}>
          {this.state.error}
        </div>
        <div>
          <FlatButton
            label="Cancel"
            style={cancelButtonStyle}
            onClick={() => this._closeEmailDialog()}
          />
          <PrimaryButton
            label="Send"
            disabled={!email.template}
            onClick={() => this._sendEmail()}
          />
        </div>
      </div>
    );

    return (
      <Dialog
        title={title()}
        actions={actions()}
        modal={false}
        autoScrollBodyContent
        open={this.props.isOpenEmailDialog}
        onRequestClose={this.props.toggleEmailDialog}
      >
        <div>
          <ATRFormField formLabel="To">
            <SecondaryTextField
              name="to"
              fullWidth
              autoF
              onChange={(e, v) =>
                this.setState({
                  email: { ...this.state.email, address: v }
                })
              }
            />
          </ATRFormField>

          <ATRFormField formLabel="Cc">
            <ATRChipInput
              name="cc"
              fullWidth
              onChange={(e, v) => this._handleChange("cc", v)}
            />
          </ATRFormField>

          <ATRFormField formLabel="Bcc">
            <ATRChipInput
              name="bcc"
              fullWidth
              value={email.bcc}
              onChange={(e, v) => this._handleChange("bcc", v)}
            />
          </ATRFormField>

          <ATRFormField formLabel="Subject">
            <SecondaryTextField
              name="subject"
              fullWidth
              value={email.subject ? email.subject : ""}
              onChange={(e, v) => this._handleChange("subject", v)}
            />
          </ATRFormField>

          <ATRFormField formLabel="Knowledgebase Article">
            <PrimaryDropDownMenu
              value={this.state.article}
              onChange={(e, k, v) => this._handleKBChange(v)}
            >
              <MenuItem value="none" primaryText="None Selected" />
              {this.props.articles.map((item, i) => (
                <MenuItem key={i} value={item} primaryText={item.title} />
              ))}
            </PrimaryDropDownMenu>
          </ATRFormField>

          <RTEditor
            disabled
            value={this.state.value}
            onChange={this._handleEditorChange}
            readOnly
            style={{
              height: 250,
              backgroundColor: muiTheme.palette.accent2Color
            }}
          />
        </div>
      </Dialog>
    );
  }
}
