import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FilterTable from "./components/FilterTable";
import { BodyContainer } from "./styles";
import TicketSources from "./components/TicketSources";

const AutomationFiltersPanel = props => {
  const {
    selectedProviders,
    getProviderFilters,
    providerFilters,
    setSelectedProviderType,
    selectedProviderType
  } = props;

  useEffect(() => {
    if (
      selectedProviders.some(
        provider => !Object.keys(providerFilters).includes(provider)
      )
    ) {
      getProviderFilters(selectedProviders);
    }
    // assign initial selected type
    const providers = Object.keys(providerFilters);
    if (providers.length > 0 && selectedProviderType === undefined) {
      let selectedType = undefined;
      let providerIdx = 0;
      while (selectedType === undefined && providerIdx < providers.length) {
        const provider = providerFilters[providers[providerIdx]];
        if (provider.ticketTypes.length > 0) {
          selectedType = provider.ticketTypes[0];
        }
        providerIdx++;
      }
      if (selectedType !== undefined) {
        setSelectedProviderType(selectedType);
      }
    }
  }, [
    selectedProviders,
    getProviderFilters,
    providerFilters,
    setSelectedProviderType
  ]);

  return (
    <BodyContainer item xs={12}>
      <TicketSources providerFilters={providerFilters} />
      <FilterTable selectedProviderType={selectedProviderType} />
    </BodyContainer>
  );
};

AutomationFiltersPanel.propTypes = {
  selectedProviders: PropTypes.array.isRequired,
  getProviderFilters: PropTypes.func.isRequired,
  providerFilters: PropTypes.object.isRequired,
  setSelectedProviderType: PropTypes.func.isRequired,
  selectedProviderType: PropTypes.object
};

export default AutomationFiltersPanel;
