import styled from "styled-components";
import { Button } from "@material-ui/core";

export const MainContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
`;

export const StyledButton = styled(Button)`
  background-color: #378bee !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin: 0px 10px !important;
`;
