/**
 * Created by rayemagp on 2/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import AutoComplete from "material-ui/AutoComplete";

class SecondaryAutoComplete extends React.Component {
  determineErrorText() {
    let errorText;
    if (this.props.invalid === true) {
      errorText = "Required";
    } else {
      errorText = null;
    }
    return errorText;
  }
  render() {
    const errorStyle =
      typeof this.props.invalid === "boolean" && this.props.invalid;
    return (
      <AutoComplete
        errorText={this.determineErrorText()}
        floatingLabelStyle={{ color: themeMainUI.palette.textColor }}
        underlineFocusStyle={
          errorStyle ? { borderColor: themeMainUI.palette.errorColor } : {}
        }
        {...this.props}
      />
    );
  }
}

export default SecondaryAutoComplete;
