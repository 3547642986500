import { connect, ConnectedProps } from "react-redux";
import { getActiveTemplates } from "../../actions/mapping";
import { RouteComponentProps } from "react-router-dom";

import DryRunHomePage from "../../components/dryRun/DryRunHomePage";

export const GET_TICKET_TYPE_LIST = "GET_TICKET_TYPE_LIST";

export interface ITicketTypeProps {
  type: string;
  displayName: string;
}

export interface ITicketDetailsProps {
  coreData: boolean;
  defaultValue: string;
  description: null;
  displayName: string;
  displayOrder: null;
  editable: boolean;
  groupId: null;
  mandatory: boolean;
  name: string;
  type: string;
  values: [];
}

export interface IActiveTemplatesProps {
  active: boolean;
  created: string;
  id: string;
  mapping: {};
  name: string;
  primaryFields: ITicketDetailsProps[];
  secondaryFields?: ITicketDetailsProps[];
  states: string[];
  ticketSource: {};
  ticketType: string;
}

export type MapValueType = number | string | null;

export type AllFields = {
  [name: string]: MapValueType;
};

export interface ITicketProps {
  allFields: AllFields;
  attachmentData: [];
  automationData: {};
  coreData: {};
  editingData: {};
  mlData: [];
  serviceData: {};
  similarityScore: number;
  slaData: [];
  ticketIngestDateTime: string;
}

// interface for primary and secondary fields
export interface ITicketDetailsObject {
  [fieldName: string]: ITicketDetailsProps;
}

export type ImportTicketProps = {
  activeTemplate: IActiveTemplatesProps;
  ticket: ITicketProps;
};

interface IStateProps {
  mapping: {
    activeTemplates: { [ticketType: string]: IActiveTemplatesProps };
  };
  dryRun: {
    ticket: ITicketProps;
  };
}

// props object for DryRunHomePage
export interface IProps extends IPropsFromRedux {
  history: RouteComponentProps["history"];
}

const mapStateToProps = (state: IStateProps) => ({
  activeTemplates: state.mapping.activeTemplates,
  ticket: state.dryRun.ticket
});

const mapDispatchToProps = (dispatch: any) => ({
  getActiveTemplates: () => dispatch(getActiveTemplates())
});

const DryRunPage = connect(mapStateToProps, mapDispatchToProps);
export type IPropsFromRedux = ConnectedProps<typeof DryRunPage>;

export default DryRunPage(DryRunHomePage);
