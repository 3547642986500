import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid/lib";
import Drawer from "material-ui/Drawer";
import MediaQuery from "react-responsive";

import PrimaryButton from "../templates/PrimaryButton";
import MappingTable from "./MappingTable";
import MappingDrawer from "./MappingDrawer";
import PermissionCheck from "../errors/PermissionCheck";

class Mapping extends React.Component {
  constructor(props) {
    super(props);
    this._handleRowSelection = this._handleRowSelection.bind(this);
    this._changePage = this._changePage.bind(this);
    this._toggleBundles = this._toggleBundles.bind(this);
    this.props.openMappings(0);

    this.state = {
      mappings: []
    };
  }
  componentDidMount() {
    if (this.props.match.params.name) {
      this.props.openMappingByName(this.props.match.params.name);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.mappings !== this.props.mappings) {
      this.setState({
        mappings: nextProps.mappings
      });
    }
  }
  componentWillUnmount() {
    this.props.changePage(0);
    this.props.openMapping({});
  }

  _handleRowSelection(rowIndex) {
    const { closeMapping, openMapping, mappingSelected } = this.props;
    if (mappingSelected.id) return closeMapping();

    openMapping(
      this.state.mappings[Object.keys(this.state.mappings)[rowIndex]]
    );
  }

  _changePage(moveIndex) {
    const nextPage = this.props.currentPage + moveIndex;

    if (nextPage < 0 || nextPage > this.props.lastPage) return null;

    this.props.changePage(nextPage);
    this.props.openMappings(nextPage);
  }

  _toggleBundles() {
    const { isBundlesOpen, closeBundles, openBundles } = this.props;
    isBundlesOpen ? closeBundles() : openBundles();
  }

  render() {
    const isBundlesOpen = !this.props.isBundlesOpen; // changed to default not show
    const mappingPage = () => (
      <Grid fluid style={{ padding: "30px" }}>
        <Row style={{ alignItems: "center", paddingLeft: 10 }}>
          <Col xs={12} style={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ marginLeft: 30, marginTop: 10, marginBottom: 20 }}>
                {/* <IconButton onClick={this._toggleBundles}> */}
                {/* <FontIcon className="fa fa-bars"/> */}
                {/* </IconButton> */}
                Mappings
              </h3>
              <div>
                <PrimaryButton
                  style={{ marginRight: 30 }}
                  onClick={() => {
                    this.props.openMapping({
                      id: "0",
                      ticketType: "",
                      mappingName: "",
                      targetServiceName: "",
                      targetProjectName: "",
                      targetJobName: "",
                      resultState: "",
                      triggerConditions: {
                        triggerStates: [],
                        mlFields: {
                          confidenceLevel: 0,
                          predictions: {}
                        },
                        fields: []
                      },
                      fieldMappingRules: []
                    });
                  }}
                  label={<strong>Create new mapping</strong>}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ padding: 10 }}>
          <Col
            md={0}
            lg={isBundlesOpen ? 2 : 0}
            className={`${
              isBundlesOpen ? "hide-on-mobile" : "hide-on-mobile hidden"
            } hidden-md`}
            style={{ padding: 0 }}
          >
            {/* <div> */}
            {/* <List className="bundles"> */}
            {/* {bundlesList} */}
            {/* </List> */}
            {/* </div> */}
          </Col>

          <Col xs={12}>
            <MappingTable
              handleRowSelection={this._handleRowSelection}
              changePage={this._changePage}
              lastPage={this.props.lastPage}
              currentPage={this.props.currentPage}
              isFetchingMappings={this.props.isFetchingMappings}
              mappingSelected={this.props.mappingSelected}
              mappings={this.state.mappings}
              activeTemplates={this.props.activeTemplates}
              clearMappingJobs={this.props.clearMappingJobs}
              clearRundeckProjects={this.props.clearRundeckProjects}
              deleteMapping={this.props.deleteMapping}
            />
          </Col>
        </Row>
      </Grid>
    );

    return (
      <div style={{ height: "calc(100vh - 58px)", overflowY: "auto" }}>
        <PermissionCheck
          requiredPermissions={["TM_AUTOMATION_MAPPING_READ", "TM_CONFIG_READ"]}
          permissions={this.props.permissions}
        >
          <MediaQuery maxWidth={768}>
            <div>
              {typeof this.props.mappingSelected.id !== "undefined" ? (
                <MappingDrawer {...this.props} />
              ) : (
                mappingPage()
              )}
            </div>
          </MediaQuery>

          <MediaQuery minWidth={769}>
            {mappingPage()}

            <Drawer
              open={typeof this.props.mappingSelected.id !== "undefined"}
              openSecondary
              docked
              width={600}
              style={{
                overflow: "hidden"
              }}
              containerStyle={{
                overflow: "hidden"
              }}
            >
              <MappingDrawer {...this.props} />
            </Drawer>
          </MediaQuery>
        </PermissionCheck>
      </div>
    );
  }
}

export default Mapping;
