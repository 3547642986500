import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { isEmpty } from "lodash";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/lab/Slider";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import ListIcon from "@material-ui/icons/PlaylistAdd";
import Checkbox from "@material-ui/core/Checkbox";
import { userConfig } from "../../userConfigValidations";
import SelectAutoComplete from "components/drawers/SelectAutoComplete";
import FontIcon from "material-ui/FontIcon";

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans",sans-serif'
  },
  palette: {
    primary: {
      light: "#BBDEFB",
      main: "#1E88E5",
      dark: "#0D47A1",
      contrastText: "#fff"
    }
  }
});

const styles = theme => ({
  grid_button: {
    marginTop: 15,
    marginLeft: -30
  },
  button: {
    marginTop: 8
  }
});

const TriggerConditions = props => {
  const {
    disabled,
    fields,
    classes,
    triggerConditions,
    ticketStates,
    validation,
    mlFields,
    predictableFields
  } = props;
  const currentFields = triggerConditions.fields;
  const currentMlPredictions = mlFields.predictions;
  const {
    addField,
    deleteField,
    handleFieldChange,
    handleValueChange,
    handleTriggerStates,
    handleRegex,
    handleMLConfidenceChange,
    addMlCondition,
    deleteMlCondition,
    handleMlConditionValueChange,
    handleMlConditionFieldChange
  } = props;

  const [missingPredictions, setMissingPredictions] = useState([]);

  useEffect(() => {
    if (mlFields.predictions) {
      const allPredictedFields = Object.keys(mlFields.predictions);
      setMissingPredictions(
        predictableFields.filter(
          predictableField =>
            !allPredictedFields.includes(predictableField.name)
        )
      );
    }
  }, [mlFields.predictions, predictableFields]);

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container spacing={8}>
        {/* Generic trigger conditions */}
        <Grid item xs={10}>
          <div style={{ marginTop: 30, display: "flex" }}>
            <p style={{ margin: 0 }}>
              <FontIcon className="fa fa-key" /> &nbsp;Trigger States
            </p>
          </div>
          <p style={{ margin: 0, marginTop: 5, fontSize: 13, color: "grey" }}>
            Ticket states that can trigger automation
          </p>
        </Grid>
        <Grid item xs={10}>
          <FormControl error={validation}>
            <Select
              value={triggerConditions.triggerStates || []}
              onChange={e => handleTriggerStates(e)}
              multiple
              disabled={disabled}
              data-testid="triggerCondition-select1"
            >
              {ticketStates &&
                ticketStates.map(state => (
                  <MenuItem value={state} key={state}>
                    {state}
                  </MenuItem>
                ))}
            </Select>
            {validation && (
              <FormHelperText>This field is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={10}>
          <div style={{ marginTop: 30, display: "flex" }}>
            <p style={{ margin: 0 }}>
              <FontIcon className="fa fa-rocket" /> &nbsp;ML Prediction
              Conditions
            </p>
          </div>
          <p style={{ margin: 0, marginTop: 5, fontSize: 13, color: "grey" }}>
            Automation triggers based on ML predictions.
          </p>
          {isEmpty(predictableFields) && (
            <p style={{ margin: 0, marginTop: 5, fontSize: 13, color: "red" }}>
              No predictions configured for this ticket type
            </p>
          )}
        </Grid>
        {!isEmpty(predictableFields) && (
          <>
            <Grid item xs={2} className={classes.button}>
              {isEmpty(currentMlPredictions) && (
                <IconButton
                  className={classes.grid_button}
                  aria-label="Add"
                  disabled={disabled}
                  data-testid="mlTriggerCondition-add1"
                  onClick={e => addMlCondition(0)}
                >
                  <AddIcon style={{ fontSize: 35 }} />
                </IconButton>
              )}
            </Grid>
            {!isEmpty(currentMlPredictions) && (
              <>
                {Object.keys(currentMlPredictions).map((predictedField, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>Field</InputLabel>
                        <Select
                          disabled={disabled}
                          data-testid="mlTriggerCondition-select2"
                          value={predictedField}
                          onChange={e =>
                            handleMlConditionFieldChange(predictedField, e)
                          }
                        >
                          {predictableFields.map((field, index) => (
                            <MenuItem
                              value={field.name}
                              key={index + field.name}
                            >
                              {field.displayName || field.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <React.Fragment>
                      <Grid item xs={6}>
                        <FormControl>
                          <TextField
                            id="name"
                            label="Value"
                            disabled={disabled}
                            data-testid="triggerCondition-textField3"
                            className={classes.textField}
                            value={
                              currentMlPredictions[predictedField]
                                .predictedValue
                            }
                            fullWidth
                            onChange={e =>
                              handleMlConditionValueChange(predictedField, e)
                            }
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                    <Grid item xs={1} className={classes.btnGrid}>
                      {!isEmpty(missingPredictions) && (
                        <IconButton
                          className={classes.button}
                          aria-label="Add"
                          disabled={disabled}
                          data-testid="triggerCondition-add2"
                          onClick={e => addMlCondition(i)}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item xs={1} className={classes.btnGrid}>
                      <IconButton
                        className={classes.button}
                        disabled={disabled}
                        data-testid="triggerCondition-remove"
                        aria-label="Delete"
                        onClick={e => deleteMlCondition(predictedField)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={2}>
                  <div style={{ fontSize: 12 }}>Confidence Threshold</div>
                </Grid>
                <Grid item xs={10}>
                  <div style={{ fontSize: 12 }}>
                    Value: {triggerConditions.mlFields.confidenceLevel}
                  </div>
                  <Slider
                    value={triggerConditions.mlFields.confidenceLevel}
                    aria-labelledby="label"
                    disabled={disabled}
                    data-testid="triggerCondition-slider"
                    onChange={(e, value) => handleMLConfidenceChange(value)}
                  />
                </Grid>
              </>
            )}
          </>
        )}
        <Grid container spacing={8}>
          {/* Additional fields */}
          <Grid item xs={10}>
            <div style={{ marginTop: 30, display: "flex" }}>
              <ListIcon
                style={{ fontSize: 30, paddingRight: 5, marginTop: -5 }}
              />
              <div>
                <p style={{ margin: 0 }}>Additional Trigger Conditions:</p>
              </div>
            </div>
            <p style={{ margin: 0, marginTop: 5, fontSize: 13, color: "grey" }}>
              Other trigger conditions that are independent to ML predictions.
            </p>
          </Grid>
          <Grid item xs={2} className={classes.button}>
            {isEmpty(currentFields) && (
              <IconButton
                className={classes.grid_button}
                aria-label="Add"
                disabled={disabled}
                data-testid="triggerCondition-add1"
                onClick={e => addField(0)}
              >
                <AddIcon style={{ fontSize: 35 }} />
              </IconButton>
            )}
          </Grid>
          {!isEmpty(currentFields) &&
            !isEmpty(fields) &&
            currentFields.map((currentField, i) => (
              <React.Fragment key={i}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel>Field</InputLabel>
                    <Select
                      disabled={disabled}
                      data-testid="triggerCondition-select2"
                      value={currentField.name}
                      onChange={e => handleFieldChange(e, i)}
                    >
                      {fields.map((field, index) => (
                        <MenuItem value={field.name} key={index + field.name}>
                          {field.displayName || field.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {currentField.type !== "dropdown" || currentField.regex ? (
                  <React.Fragment>
                    {currentField.type === "dropdown-search" &&
                    !currentField.regex ? (
                      <Grid item xs={6}>
                        <FormControl fullWidth>
                          <div style={{ marginTop: "7px" }}>
                            <SelectAutoComplete
                              onChange={dropItem => {
                                if (!dropItem) {
                                  dropItem = {
                                    value: ""
                                  };
                                }
                                handleValueChange(dropItem, i);
                              }}
                              placeholder="Value"
                              options={[
                                { value: "", label: "None" },
                                ...(currentField.options
                                  ? currentField.options.map(val => ({
                                      value: val,
                                      label: val
                                    }))
                                  : [])
                              ]}
                              value={
                                currentField.value
                                  ? {
                                      label: currentField.value,
                                      value: currentField.value
                                    }
                                  : null
                              }
                            ></SelectAutoComplete>
                          </div>
                        </FormControl>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <FormControl>
                          <TextField
                            id="name"
                            label="Value"
                            disabled={disabled}
                            data-testid="triggerCondition-textField3"
                            className={classes.textField}
                            value={currentField.value}
                            fullWidth
                            onChange={e => handleValueChange(e, i)}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <FormGroup>
                          <InputLabel>Value</InputLabel>
                          <Select
                            value={currentField.value}
                            onChange={e => handleValueChange(e, i)}
                            disabled={disabled}
                            data-testid="triggerCondition-select3"
                          >
                            {currentField.options.map((option, index) => (
                              <MenuItem value={option} key={index + option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormGroup>
                        {/* Checkbox */}
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={1}>
                  <FormGroup style={{ marginLeft: 12 }}>
                    <FormHelperText style={{ marginTop: 2 }}>
                      Regex
                    </FormHelperText>
                    <FormControlLabel
                      className={classes.button}
                      control={
                        <Checkbox
                          checked={currentField.regex}
                          onChange={e => handleRegex(i)}
                          style={{ marginTop: 0 }}
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={1} className={classes.btnGrid}>
                  <IconButton
                    className={classes.button}
                    aria-label="Add"
                    disabled={disabled}
                    data-testid="triggerCondition-add2"
                    onClick={e => addField(i)}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={1} className={classes.btnGrid}>
                  <IconButton
                    className={classes.button}
                    disabled={disabled}
                    data-testid="triggerCondition-remove"
                    aria-label="Delete"
                    onClick={e => deleteField(i)}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

TriggerConditions.propTypes = {
  fields: PropTypes.array,
  classes: PropTypes.object.isRequired,
  currentFields: PropTypes.array,
  addField: PropTypes.func,
  deleteField: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleValueChange: PropTypes.func
};

export default withStyles(styles)(TriggerConditions);
