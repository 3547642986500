import PropTypes from "prop-types";

export const CONDITIONAL_BUILDERS = {
  and: { displayText: "and", value: "AND" },
  or: { displayText: "or", value: "OR" }
};

export const STRING_OPERATORS = {
  is: { displayText: "is", value: "IS" },
  not: { displayText: "is not", value: "NOT" },
  contains: { displayText: "contains", value: "CONTAINS" },
  doesNotContain: {
    displayText: "does not contain",
    value: "DOES_NOT_CONTAIN"
  },
  startsWith: { displayText: "starts with", value: "STARTS_WITH" },
  endsWith: { displayText: "ends with", value: "ENDS_WITH" }
};

export const QUERY_OPERATORS = {
  AND: "^",
  OR: "^OR",
  IS: "=",
  NOT: "!=",
  CONTAINS: "LIKE",
  DOES_NOT_CONTAIN: "NOTLIKE",
  STARTS_WITH: "STARTSWITH",
  ENDS_WITH: "ENDSWITH"
};

// TODO turn Providerrs into a map if being properly built out
export const PROVIDERS = {
  serviceNow: "servicenow",
  jira: "jira",
  outlook: "outlook",
  azure: "azure"
};

export const PROVIDER_DISPLAY_NAME = {
  [PROVIDERS.serviceNow]: "Service Now",
  [PROVIDERS.jira]: "Jira",
  [PROVIDERS.outlook]: "Outlook",
  [PROVIDERS.azure]: "Azure"
};

const AutomationFilterModel = PropTypes.shape({
  field: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  conditionalBuilder: PropTypes.oneOf(
    Object.keys(CONDITIONAL_BUILDERS).map(
      key => CONDITIONAL_BUILDERS[key].value
    )
  ),
  stringOperator: PropTypes.oneOf(
    Object.keys(STRING_OPERATORS).map(key => STRING_OPERATORS[key].value)
  )
});

export default AutomationFilterModel;
