import React from "react";
import { MuiThemeProvider, Step, StepButton, Stepper } from "material-ui";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import Styles from "../../../../styles/themeMainUI";

const muiTheme = getMuiTheme({
  stepper: {
    iconColor: Styles.palette.automationBackgroundColor
  }
});

export default class ServiceRequestStepper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      finished: false,
      stepIndex: 0
    };
  }

  componentWillMount() {
    this.setState({ stepIndex: this.props.steps.length - 1 });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ stepIndex: nextProps.steps.length - 1 });
  }

  render() {
    const { stepIndex } = this.state;
    const { steps, goPreviousStep } = this.props;

    return (
      <div style={{ width: "100%", maxWidth: 700, margin: "auto" }}>
        <MuiThemeProvider muiTheme={muiTheme}>
          <Stepper activeStep={stepIndex} linear={false} orientation="vertical">
            {steps.map((step, i) => (
              <Step key={step.id + 10}>
                <StepButton onClick={() => goPreviousStep(i)}>
                  <b>{step.name ? step.name : "Main Categories"}</b>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </MuiThemeProvider>
      </div>
    );
  }
}
