import { connect } from "react-redux";
import { setSelectedProviderType } from "actions/tutorial";
import SourceTypes from "../index";

const mapStateToProps = state => ({
  selectedProviderType: state.tutorial.selectedProviderType
});

const mapDispatchToProps = dispatch => ({
  setSelectedProviderType: providerType =>
    dispatch(setSelectedProviderType(providerType))
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceTypes);
