import { connect } from "react-redux";
import AutomationFiltersPanel from "../index";
import { getProviderFilters, setSelectedProviderType } from "actions/tutorial";

const mapStateToProps = state => ({
  selectedProviders: state.tutorial.selectedProviders,
  providerFilters: state.tutorial.providerFilters,
  selectedProviderType: state.tutorial.selectedProviderType
});

const mapDispatchToProps = dispatch => ({
  getProviderFilters: selectedProviders =>
    dispatch(getProviderFilters(selectedProviders)),
  setSelectedProviderType: providerType =>
    dispatch(setSelectedProviderType(providerType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationFiltersPanel);
