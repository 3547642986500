const usecases = {
  Cloud: {
    AWS: [
      "Instance Upgrade",
      "Instance increase volume size",
      "Instance termination",
      "Instance change ownership"
    ],
    Splunk: ["Splunk Instance Recovery"]
  },
  "Service Desk": {
    Access: [
      "SSH Request Access",
      "Jira Access",
      "Add user to AD",
      "Offboard user",
      "Password reset"
    ],
    Bug: ["Jira Report Bug", "ServiceNow Report Bug"]
  },
  Infra: {
    Linux: [
      "CPU Usage",
      "Disk Usage",
      "Memory Usage",
      "Patching Automation",
      "Restart Host"
    ]
  },
  SAP: {
    "Cloud Admin": [
      "Provision SAP system",
      "Create SAP User",
      "Schedule job monitoring",
      "Lock Entry Management",
      "Create/Update RFC Destinations",
      "Create/Update Logical Server Path"
    ]
  }
};

export const usecaseDefaults = {
  "SAP Upsert User": {
    description:
      "Create SAP IDs for business users, developers and testing systems in minutes",
    tags: "SAP/Upsert User"
  },
  "SAP Delete User": {
    description: "Deletes a current SAP user",
    tags: "SAP/Delete User"
  },
  "SAP Batch Failure Restart Job": {
    description: "Upon a batch failure, re-run the specific batch job",
    tags: "SAP/Jobs"
  },
  "SAP Terminate Job": {
    description: "Terminate a non responsive failed batch job",
    tags: "SAP/Jobs"
  },
  "SAP: High CPU Utilization": {
    description:
      "Notify SAP admin in the event of high CPU utilisation on nominated instance",
    tags: "SAP/Infra"
  },
  "SAP High Memory Count": {
    description: "Notify SAP admin on high memory count on nominated instance",
    tags: "SAP/Infra"
  },
  "SAP File System Full": {
    description:
      "Notify SAP admin if the file system is full on nominated instance",
    tags: "SAP/Infra"
  },
  "SAP Not Enough Spool Resources": {
    description: "Notify SAP admin on insufficient spool resources",
    tags: "SAP/Resources"
  },
  "SAP Not Enough Batch Resources": {
    description: " Notify SAP admin on insufficient batch resources",
    tags: "SAP/Resources"
  },
  "SAP Not Enough Dialog Resources": {
    description: "Notify SAP admin on insufficient dialog resources",
    tags: "SAP/Resources"
  },
  "Innersource Repo Access": {
    description: "Requests access to an Innersource repository",
    tags: "Access/Innersource"
  },
  "SSH Access Request (WFM)": {
    description: "Request SSH access for a nominated instance",
    tags: "Access/Cloud"
  },
  "Jira Report Bug": {
    description: "Automatically report and raise a bug in Jira",
    tags: "JIRA/Raise Bug"
  },
  "AWS Instance Action - WFM": {
    description: "Start and stop a nominated AWS instance",
    tags: "AWS/Action"
  }
};

export default usecases;
