import React from "react";
import styled from "styled-components";
import { Typography, ListItem } from "@material-ui/core";

export const HeaderText = styled(Typography)`
  font-size: 1rem !important;

  @media screen and (max-width: 992px) {
    font-size: 0.8rem !important;
  }

  @media screen and (min-width: 992px) and (max-width: 1300px) {
    font-size: 0.9rem !important;
  }

  @media screen and (max-height: 700px) {
    font-size: 0.8rem !important;
  }

  @media screen and (min-height: 700px) and (max-height: 750px) {
    font-size: 0.9rem !important;
  }
`;

export const StyledListItem = styled(({ isSelected, ...rest }) => (
  <ListItem {...rest} />
))`
  ${props =>
    props.isSelected && "color: #378BEE !important; font-weight: bold;"}

  @media screen and (max-width: 992px) {
    padding: 5px !important;
  }

  @media screen and (min-width: 992px) and (max-width: 1300px) {
    padding: 5px !important;
  }

  @media screen and (max-height: 700px) {
    padding: 5px !important;
  }

  @media screen and (min-height: 700px) and (max-height: 750px) {
    padding: 5px !important;
  }
`;

export const StyledItemText = styled.p`
  margin: 0;
  font-size: 0.9rem;

  @media screen and (max-width: 1400px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 1400px) and (max-width: 1500px) {
    font-size: 0.8rem;
  }

  @media screen and (max-height: 700px) {
    font-size: 0.7rem;
  }

  @media screen and (min-height: 700px) and (max-height: 750px) {
    font-size: 0.8rem;
  }
`;
