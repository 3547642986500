import React from "react";

import { FontIcon } from "material-ui";

const styles = {
  errorContainer: {
    textAlign: "center",
    marginTop: "50px"
  },
  errorIcon: {
    fontSize: "40px",
    color: "#d8d8d8"
  },
  errorTxt: {
    fontWeight: "bold"
  }
};
class PermissionCheck extends React.Component {
  hasPermission = (requiredPermissions, permissions) => {
    try {
      let tmpPermission = requiredPermissions;
      if (typeof requiredPermissions === "string") {
        tmpPermission = [requiredPermissions];
      }
      return tmpPermission.reduce((finalBool, currentPermission) => {
        return finalBool && permissions.includes(currentPermission);
      }, true);
    } catch (e) {
      console.log(
        `Error calculating permissions: ${requiredPermissions}, hiding component as fallback \n`,
        e
      );
      return false;
    }
  };

  missingPermissions = (requiredPermissions, permissions) => {
    try {
      return requiredPermissions.filter(element => {
        return !permissions.includes(element);
      });
    } catch (e) {
      console.log(
        `Error calculating permissions: ${requiredPermissions}, hiding component as fallback \n`,
        e
      );
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.hasPermission(
          this.props.requiredPermissions,
          this.props.permissions
        ) ? (
          this.props.children
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px"
            }}
          >
            <FontIcon className="fa fa-info-circle" />
            <p className={styles.errorTxt}>Permission Denied</p>
            <p>
              It seems that you have tried to perform an operation which is not
              permitted.
            </p>
            <p>
              You are missing the following permissions:
              {this.missingPermissions(
                this.props.requiredPermissions,
                this.props.permissions
              ).map(element => {
                return <div>{element}</div>;
              })}
            </p>
            <a href={"/identity-management"}>Go to Identity Management</a>
          </div>
        )}
      </div>
    );
  }
}

export default PermissionCheck;
