import React from "react";
import PropTypes from "prop-types";
import { HeaderContainer, HeaderText, SubTitleText } from "./styles";

const BaseHeader = props => {
  const { contentType, title, subTitle, children } = props;
  return (
    <HeaderContainer>
      {contentType === "NODE" ? (
        children
      ) : (
        <>
          <HeaderText>{title}</HeaderText>
          {subTitle && <SubTitleText>{subTitle}</SubTitleText>}
        </>
      )}
    </HeaderContainer>
  );
};

BaseHeader.propTypes = {
  contentType: PropTypes.oneOf(["STRING", "NODE"]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node
};

BaseHeader.defaultProps = {
  contentType: "STRING"
};

export default BaseHeader;
