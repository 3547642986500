import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import RaisedButton from "material-ui/RaisedButton";

class GreenButton extends React.Component {
  render() {
    return (
      <RaisedButton
        labelColor={themeMainUI.palette.canvasColor}
        backgroundColor={themeMainUI.palette.automationBackgroundColor}
        disabledBackgroundColor={themeMainUI.palette.primary1Color}
        {...this.props}
      />
    );
  }
}

export default GreenButton;
