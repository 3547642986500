import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  width: 100%;
  min-height: 80px;

  @media screen and (max-height: 700px) {
    padding: 10px 0px;
    min-height: 60px;
  }

  @media screen and (min-height: 700px) and (max-height: 800px) {
    padding: 15px 0px;
    min-height: 60px;
  }

  @media screen and (max-width: 992px) {
    padding: 10px 0px;
    min-height: 60px;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    padding: 15px 0px;
    min-height: 60px;
  }
`;

export const HeaderText = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 35px;

  @media screen and (max-height: 768px) {
    font-size: 1.2rem;
  }

  @media screen and (min-height: 769px) and (max-height: 992px) {
    font-size: 1.35rem;
  }

  @media screen and (max-width: 992px) {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 1.35rem;
  }
`;

export const SubTitleText = styled.p`
  margin: 0;
  margin-top: 10px;
  font-size: 0.9rem;
  line-height: 19px;

  @media screen and (max-height: 768px) {
    font-size: 0.6rem;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    font-size: 0.75rem;
  }

  @media screen and (max-width: 992px) {
    font-size: 0.6rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 0.75rem;
  }
`;
