import {
  OPEN_DATA_SOURCE_DETAILS,
  CLOSE_DATA_SOURCE_DETAILS,
  RETRIEVE_DATA_SOURCE_LIST_SUCCESS,
  OPEN_CREATE_DATA_SOURCE_DETAILS,
  CREATE_DATA_SOURCE_DETAILS_SUCCESS
} from "../actions/dataSource";

function dataSource(
  state = {
    dataSources: [],
    dataSource: {},
    isDetailsOpen: false,
    isCreateDataSource: false
  },
  action
) {
  switch (action.type) {
    case RETRIEVE_DATA_SOURCE_LIST_SUCCESS: {
      return Object.assign({}, state, {
        dataSources: action.dataSources
      });
    }
    case OPEN_DATA_SOURCE_DETAILS: {
      return Object.assign({}, state, {
        isDetailsOpen: true,
        dataSource: action.dataSource
      });
    }
    case CLOSE_DATA_SOURCE_DETAILS: {
      return Object.assign({}, state, {
        isDetailsOpen: false,
        isCreateDataSource: false,
        dataSource: {}
      });
    }
    case OPEN_CREATE_DATA_SOURCE_DETAILS: {
      return Object.assign({}, state, {
        isDetailsOpen: true,
        isCreateDataSource: true,
        dataSource: action.dataSource
      });
    }
    case CREATE_DATA_SOURCE_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        isCreateDataSource: false,
        isDetailsOpen: false
      });
    }
    default:
      return state;
  }
}

export default dataSource;
