import config from "../config";
import { openSnackbar } from "./snackbar";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const TICKET_GET_CONFIG_PENDING = "TICKET_GET_CONFIG_PENDING";
export const TICKET_GET_CONFIG_SUCCESS = "TICKET_GET_CONFIG_SUCCESS";
export const TICKET_GET_CONFIG_ERROR = "TICKET_GET_CONFIG_ERROR";
export const ML_MODELS_GET_PENDING = "ML_MODELS_GET_PENDING";
export const ML_MODELS_GET_SUCCESS = "ML_MODELS_GET_SUCCESS";
export const ML_MODELS_GET_ERROR = "ML_MODELS_GET_ERROR";

export function getTicketConfig(service) {
  return (dispatch, getState) => {
    dispatch({
      type: TICKET_GET_CONFIG_PENDING,
      service
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/config/${service.url}`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: TICKET_GET_CONFIG_SUCCESS,
          body: res.data,
          service
        });
      })
      .catch(err => {
        dispatch(
          openSnackbar(`Unable to retrieve configurations for '${service.url}'`)
        );
        dispatch({
          type: TICKET_GET_CONFIG_ERROR
        });
      });
  };
}

export const TICKET_DELETE_CONFIG = "TICKET_DELETE_CONFIG";

export function deleteTicketConfig(service) {
  return {
    type: TICKET_DELETE_CONFIG,
    service
  };
}

export const TICKET_ADD_CONFIG_ERROR = "TICKET_ADD_CONFIG_ERROR";
export const TICKET_ADD_CONFIG_SUCCESS = "TICKET_ADD_CONFIG_SUCCESS";
export const TICKET_ADD_CONFIG_PENDING = "TICKET_ADD_CONFIG_PENDING";

export function addTicketConfigThenRefreshList(service, newInstance) {
  return dispatch => {
    dispatch(addTicketConfig(service, newInstance))
      .then(() => {
        dispatch(getTicketConfig(service));
      })
      .catch(err => {});
  };
}

export function deleteTicketConfigThenRefreshList(service, todelete) {
  return dispatch => {
    dispatch(delTicketConfig(service, todelete)).catch(err => {});
  };
}

export function delTicketConfig(service, toDelete) {
  return (dispatch, getState) => {
    dispatch({
      type: TICKET_ADD_CONFIG_PENDING
    });

    let url = "";
    let params = {};

    switch (service.url) {
      case "machineLearningModels":
        url = `${config.urls.base +
          config.urls.apis["ticket-management"]}/config/${service.url}`;
        params.field = toDelete.field;
        params.type = toDelete.type;
        break;
      case "servicenow":
        break;
      case "rundeck":
        url = `${config.urls.base +
          config.urls.apis["ticket-management"]}/config/${
          service.url
        }/${encodeURI(toDelete.name)}`;
        break;
      case "manual-resolution":
        url = `${config.urls.base +
          config.urls.apis["ticket-management"]}/config/${service.url}`;
        break;
      default:
        break;
    }

    return axiosSnackbar
      .delete(url, { params })
      .then(res => {
        dispatch(openSnackbar("Record successfully deleted"));
        dispatch({
          type: TICKET_GET_CONFIG_SUCCESS,
          body: res.data,
          service
        });
        // getTicketConfig(service);
      })
      .catch(err => {
        dispatch({
          type: TICKET_ADD_CONFIG_ERROR
        });
      });
  };
}

export function getMLModels() {
  return (dispatch, getState) => {
    dispatch({
      type: ML_MODELS_GET_PENDING
    });

    const url = `${config.urls.baseML + config.urls.apis.ml}/models`;

    const params = {
      page: 0,
      perPage: 100
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: ML_MODELS_GET_SUCCESS,
          body: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: ML_MODELS_GET_ERROR
        });
      });
  };
}

const redirect = (service, body) => {
  if (service.name === "Email") {
    window.open(body.loginUrl, "_blank");
  }
};

export function addTicketConfig(service, newInstance) {
  return (dispatch, getState) => {
    dispatch({
      type: TICKET_ADD_CONFIG_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/config/${service.url}`;

    return axiosSnackbar
      .put(url, newInstance)
      .then(res => {
        dispatch(openSnackbar("Success"));
        dispatch({
          type: TICKET_ADD_CONFIG_SUCCESS,
          body: res.data
        });
        getTicketConfig(service);
        redirect(service, res.data);
      })
      .catch(err => {
        dispatch({
          type: TICKET_ADD_CONFIG_ERROR
        });
      });
  };
}
