import React, { Component } from "react";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableRowColumn
} from "material-ui/Table";

import DataSourceTableRow from "./DataSourceTableRow";

export default class DataSourceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SNOWListConverted: {},
      list: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const SNOWlist = nextProps.SNOWList.map((item, index) => ({
      password: item.password,
      source: item.url,
      username: item.username,
      sourceType: 1, // service now
      ticketType: 1
    }));
    return {
      SNOWListConverted: SNOWlist,
      list: nextProps.service.fakeList.concat(SNOWlist)
    };
  }

  render() {
    return (
      <Table selectable={false}>
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          style={{
            backgroundColor: "rgb(151, 201, 209)"
          }}
        >
          <TableRow style={{ overflow: "auto" }}>
            <TableRowColumn style={{ color: "white" }} width={150}>
              User Name
            </TableRowColumn>
            <TableRowColumn style={{ color: "white" }} width={150}>
              Source Type
            </TableRowColumn>
            <TableRowColumn style={{ color: "white" }} width={150}>
              Ticket Type
            </TableRowColumn>
            <TableRowColumn style={{ color: "white" }} width={150}>
              Password
            </TableRowColumn>
            <TableRowColumn style={{ color: "white" }} width={150}>
              Source Name
            </TableRowColumn>
            <TableRowColumn width={70} style={{ textAlign: "right" }} />
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {this.state.list.map((item, index) => {
            return (
              <DataSourceTableRow
                key={item}
                addTicketConfigThenRefreshList={(a, b) => {
                  this.props.addTicketConfigThenRefreshList(a, b);
                }}
                deleteTicketConfigThenRefreshList={(a, b) => {
                  this.props.deleteTicketConfigThenRefreshList(a, b);
                }}
                createDataSource={file => {
                  this.props.createDataSource(file);
                }}
                service={this.props.service}
                item={item}
              />
            );
          })}
          {this.props.addingRow ? (
            <DataSourceTableRow
              createDataSource={file => {
                this.props.createDataSource(file);
              }}
              addTicketConfigThenRefreshList={(a, b) => {
                this.props.addTicketConfigThenRefreshList(a, b);
              }}
              deleteTicketConfigThenRefreshList={(a, b) => {
                this.props.deleteTicketConfigThenRefreshList(a, b);
              }}
              hideNewRow={service => {
                this.props.hideNewRow(service);
              }}
              service={this.props.service}
              editing={true}
              isNewRow={true}
              item={{
                name: "",
                url: "",
                username: "",
                password: "",
                token: "",
                editing: true
              }}
            />
          ) : null}
        </TableBody>
      </Table>
    );
  }
}
