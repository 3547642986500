import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const ContainerHeading = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const CenterContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;

  @media screen and (max-height: 768px) {
    margin-bottom: 10px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    margin-bottom: 15px;
  }
`;

export const FormContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  padding: 20px;
  z-index: 1;
`;
