import {
  TICKET_GET_CONFIG_ERROR,
  TICKET_GET_CONFIG_PENDING,
  TICKET_GET_CONFIG_SUCCESS,
  TICKET_ADD_CONFIG_SUCCESS,
  ML_MODELS_GET_PENDING,
  ML_MODELS_GET_SUCCESS,
  ML_MODELS_GET_ERROR
} from "../actions/ticketConfig";

function ticketConfig(
  state = {
    isFetchingConfig: false,
    isFetchingMLModels: false,
    services: [],
    MLModels: []
  },
  action
) {
  switch (action.type) {
    case TICKET_GET_CONFIG_PENDING: {
      return Object.assign({}, state, {
        isFetchingConfig: true
      });
    }
    case TICKET_GET_CONFIG_SUCCESS: {
      const actionService = Object.assign({}, action.service, {
        list: [].concat(action.body)
      });

      let serviceArray = [];
      if (
        state.services.filter(service => service.name === actionService.name)
          .length <= 0
      ) {
        serviceArray = serviceArray.concat(actionService, state.services);
      } else {
        for (const i in state.services) {
          if (state.services[i].name === actionService.name) {
            serviceArray = serviceArray.concat(actionService);
          } else {
            serviceArray = serviceArray.concat(state.services[i]);
          }
        }
      }

      // return new state
      return Object.assign({}, state, {
        isFetchingConfig: false,
        services: serviceArray
      });
    }
    case TICKET_GET_CONFIG_ERROR: {
      return Object.assign({}, state, {
        // error: action.error
      });
    }
    case TICKET_ADD_CONFIG_SUCCESS: {
      return Object.assign({}, state, {
        isFetchingConfig: false
      });
    }
    case ML_MODELS_GET_PENDING: {
      return Object.assign({}, state, {
        isFetchingMLModels: true
      });
    }
    case ML_MODELS_GET_SUCCESS: {
      const MLModels = action.body.items.map(model => ({
        name: model.name,
        algorithm: model.algorithm,
        treeLevels: model["tree_levels"],
        features: model.features,
        createConfig: {
          continuous: model.type === "continuous",
          description: model.description,
          task: model.task
        },
        trainConfig: model.features,
        modelId: model._id
      }));
      // return new state
      return Object.assign({}, state, {
        isFetchingMLModels: false,
        MLModels
      });
    }
    case ML_MODELS_GET_ERROR: {
      return Object.assign({}, state, {
        // error: action.error
      });
    }
    default:
      return state;
  }
}

export default ticketConfig;
