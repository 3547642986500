import { connect } from "react-redux";
import TableHeader from "../index";
import { newAutomationFilter } from "actions/tutorial";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onAddFilter: () => dispatch(newAutomationFilter())
});

export default connect(mapStateToProps, mapDispatchToProps)(TableHeader);
