import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const MainContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 15%;
  margin: 10px 0px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: #e3f0fc;
  padding: 21px;
  font-size: 1rem;

  @media screen and (max-width: 992px) {
    font-size: 0.8rem;
    padding: 11px;
  }

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: 0.9rem;
    padding: 16px;
  }

  @media screen and (max-height: 700px) {
    font-size: 0.8rem;
    padding: 11px;
  }

  @media screen and (min-height: 700px) and (max-height: 800px) {
    font-size: 0.9rem;
    padding: 16px;
  }
`;

export const SourceTypeContainer = styled.div`
  padding: 10px;

  overflow-y: auto;
`;
