import TicketTableHeader from "./Header";
import { connect } from "react-redux";

import { ticketSetSort, ticketSetFilter } from "actions/filterAndSort";
import { getCustomizedTicketField } from "redux/reselect/ticket";
import { moveTicketCachePointer } from "actions/tickets";
import { updateBulkActionTickets } from "actions/bulkActions";

const mapStateToProps = state => {
  return {
    activeTemplates: state.mapping.activeTemplates,
    ticketSort: state.tickets.ticketSort,
    ticketFilter: state.tickets.ticketFilter,
    cacheLocalFinal: state.tickets.cacheLocalFinal,
    cacheLocalPointer: state.tickets.cacheLocalPointer,
    cacheObject: state.tickets.cacheObject,
    ticketTimeRange: state.tickets.ticketTimeRange,
    selectedTicketType: state.tickets.selectedTicketType,
    presetSelected: state.tickets.presetSelected,
    customizedTemplateColumn: getCustomizedTicketField(state),
    selectedTickets: state.bulkActions.bulkActionPayload.selectedTickets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ticketSetSort: ticketSort => dispatch(ticketSetSort(ticketSort)),
    ticketSetFilter: ticketFilter => dispatch(ticketSetFilter(ticketFilter)),
    moveTicketCachePointer: (
      moveIndex,
      localPage,
      cache,
      sortBy,
      filters,
      ticketType,
      ticketTimeRange,
      presetSelected = "",
      polling = false
    ) =>
      dispatch(
        moveTicketCachePointer(
          moveIndex,
          localPage,
          cache,
          sortBy,
          filters,
          ticketType,
          ticketTimeRange,
          presetSelected,
          polling
        )
      ),
    updateBulkActionTickets: selectedTickets =>
      dispatch(updateBulkActionTickets(selectedTickets))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketTableHeader);
