import React from "react";
import { Route, Switch } from "react-router-dom";
import lightblue from "@material-ui/core/colors/lightBlue";
import TicketTemplateTableCTN from "./Table/TableCTN";
import TicketTemplateViewerCTN from "./Viewer/ViewerCTN";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: lightblue[800]
    },
    secondary: {
      main: "#05c1af"
    }
  },
  typography: {
    useNextVariants: true
  }
});

const styles = {
  templateWrapper: {
    height: "calc(100vh - 70px)"
  }
};

const TicketTemplate = props => {
  const { classes } = props;
  return (
    <div className={classes.templateWrapper}>
      <MuiThemeProvider theme={theme}>
        <Switch>
          <Route
            exact
            path={"/atr/settings/ticket-template/new"}
            render={props => <TicketTemplateViewerCTN {...props} />}
          />
          <Route
            exact
            path={"/atr/settings/ticket-template/:id"}
            render={props => <TicketTemplateViewerCTN {...props} />}
          />
          <Route
            path={"/atr/settings/ticket-template"}
            render={props => <TicketTemplateTableCTN {...props} />}
          />
        </Switch>
      </MuiThemeProvider>
    </div>
  );
};

export default withStyles(styles)(TicketTemplate);
