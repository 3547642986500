import React from "react";
import { Divider } from "material-ui";

import SecondaryCircularProgress from "../../templates/SecondaryCircularProgress";
import { userConfig } from "../../../userConfigValidations";
import PermissionCheck from "../../errors/PermissionCheck";
import DOMPurify from "dompurify";
import GtrList from "./gtrList";

const ResolutionTab = props => {
  const {
    ticket,
    getSelectedUKnowledge,
    resolution,
    resolutionIsFetching,
    _handleTabChange,
    permissions
  } = props;

  let UKnowledgeId = "";
  if (ticket && ticket.mlData && ticket.mlData.length > 0) {
    UKnowledgeId = userConfig.actions.getKBID(
      getSelectedUKnowledge(ticket),
      ticket
    );
  }

  const createUrl =
    window.location.origin + "/kb/article/new?u_knowledge=" + UKnowledgeId;

  const tm = ticket.mlData.find(d => d.key === window.config.gtr.tmKey);
  const isValueGTR = () => {
    if (!tm) return false;
    if (tm.userValue === "GTR") {
      return true;
    } else if (tm.userValue === null) {
      if (tm.predictions && tm.predictions[0].label === "GTR") {
        return true;
      }
    } else {
      return false;
    }
    return false;
  };

  return (
    <>
      {isValueGTR() ? (
        <div style={{ width: "100%" }}>
          <GtrList />
        </div>
      ) : (
        <div>
          <PermissionCheck
            requiredPermissions={["KBS_ARTICLE_READ"]}
            permissions={permissions}
          >
            {resolutionIsFetching ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SecondaryCircularProgress />
              </div>
            ) : resolution ? (
              <div>
                {resolution === "FAILED" && (
                  <div style={{ margin: 30, textAlign: "center" }}>
                    You need to{" "}
                    <a href={createUrl} style={{ color: "#000" }}>
                      create
                    </a>{" "}
                    a Knowledge Base for this issue.
                  </div>
                )}
                {resolution.title && (
                  <div>
                    <h3>{resolution.title}</h3>
                    <Divider />
                    <div>
                      {resolution.content.split("\n").map((content, i) => {
                        return (
                          <div
                            key={i}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(content)
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ margin: 30, textAlign: "center" }}>
                There is no Knowledgebase ID found in the&nbsp;
                <span
                  onClick={() => _handleTabChange(0)}
                  tabIndex="0"
                  style={{ color: "#000" }}
                >
                  Automation Tab
                </span>{" "}
                to be able to view this tab.
              </div>
            )}
          </PermissionCheck>
        </div>
      )}
    </>
  );
};

export default ResolutionTab;
