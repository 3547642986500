import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import {
  ColumnText,
  MainContainer,
  FieldsContainer,
  LeftContainer,
  RightContainer,
  ValuesContainer,
  AddButton
} from "./styles";

const TableHeader = props => {
  const { onAddFilter } = props;

  return (
    <MainContainer container>
      <Grid item xs={1} />
      <FieldsContainer item xs={4}>
        <ColumnText>Fields</ColumnText>
      </FieldsContainer>
      <ValuesContainer item xs={7}>
        <LeftContainer>
          <ColumnText>Value</ColumnText>
        </LeftContainer>
        <RightContainer>
          <AddButton onClick={onAddFilter}>
            <Add />
            Add a filter
          </AddButton>
        </RightContainer>
      </ValuesContainer>
    </MainContainer>
  );
};

TableHeader.propTypes = {
  onAddFilter: PropTypes.func.isRequired
};

export default TableHeader;
