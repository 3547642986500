// Enum representing the possible states for the health status
export enum HealthStatus {
  UNHEALTHY = "UNHEALTHY",
  HEALTHY = "HEALTHY",
  UNKNOWN = "UNKNOWN",
  PARTIAL = "PARTIAL"
}

export interface HealthCheck {
  name: string;
  status: HealthStatus;
  cause: string | null;
  description: string | null;
}

export interface Report {
  pluginId: string;
  healthChecks: HealthCheck[];
  pluginHealthStatus: HealthStatus;
}

export interface Plugin {
  activated: boolean;
  confValid: boolean;
  name: string;
  version: string;
}
