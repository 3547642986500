import { connect } from "react-redux";
import Rules from "../../components/rules/Rules";

import {
  openRule,
  closeRule,
  openRules,
  updateSingleRules,
  createRule,
  deleteRule,
  changePage
} from "../../actions/rules";
import {
  setMapping,
  closeMapping,
  openMappings,
  getActiveTemplates,
  openAllMappings
} from "../../actions/mapping";

import { closeSnackbar, openSnackbar } from "../../actions/snackbar";
import { openBundles, closeBundles } from "../../actions/bundles";
import { getTicketConfig } from "../../actions/ticketConfig";

const mapStateToProps = (state, ownProps) => {
  const auth = state.auth;
  return {
    isSnackbarOpen: state.snackbar.isOpen,
    isBundlesOpen: state.bundles.isOpen,
    ruleSelected: state.rules.ruleSelected,
    rules: state.rules.rules,
    user: auth.user,
    currentPage: state.rules.currentPage,
    lastPage: state.rules.lastPage,
    // mapping
    mappingSelected: state.mapping.mappingSelected,
    mappings: state.mapping.mappings,
    activeTemplates: state.mapping.activeTemplates,
    isFetchingMappings: state.mapping.isFetchingMappings,
    permissions: state.user.permissions,
    token: state.auth.token,
    availablePredictions: state.mapping.availablePredictions
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openBundles: () => dispatch(openBundles()),
  closeBundles: () => dispatch(closeBundles()),
  openSnackbar: message => dispatch(openSnackbar(message)),
  closeSnackbar: () => dispatch(closeSnackbar()),
  closeRule: () => dispatch(closeRule()),
  openRule: ruleSelected => dispatch(openRule(ruleSelected)),
  openRules: (page, perPage) => dispatch(openRules(page, perPage)),
  createRule: (rule, currentPage) => dispatch(createRule(rule, currentPage)),
  deleteRule: (rule, currentPage) => dispatch(deleteRule(rule, currentPage)),
  updateSingleRules: (ruleEdited, currentPage, type) =>
    dispatch(updateSingleRules(ruleEdited, currentPage, type)),
  changePage: page => dispatch(changePage(page)),

  // mapping
  closeMapping: () => dispatch(closeMapping()),
  openMapping: mappingSelected => dispatch(setMapping(mappingSelected)),
  openMappings: () => dispatch(openMappings()),
  openAllMappings: () => dispatch(openAllMappings()),
  getActiveTemplates: ticketType => dispatch(getActiveTemplates(ticketType)),
  getMlConfig: () => dispatch(getTicketConfig({ url: "machineLearningModels" }))
});

const RulesPage = connect(mapStateToProps, mapDispatchToProps)(Rules);

export default RulesPage;
