import { AnyAction } from "redux";

const initialState = {
  ticket: {},
  errorMessage: ""
};

function dryRun(state = initialState, action: AnyAction) {
  switch (action.type) {
    default:
      return state;
  }
}
export default dryRun;
