import {
  OPEN_MAPPING,
  SELECT_MAPPING,
  CLOSE_MAPPING,
  GET_RUNDECK_JOBS_PENDING,
  GET_RUNDECK_JOBS_SUCCESS,
  UPDATE_MAPPING_JOBS_TICKET_MANAGEMENT,
  ACTIVE_TEMPLATE_SUCCESS,
  MAPPING_CHANGE_PAGE,
  OPEN_MAPPINGS_PENDING,
  OPEN_MAPPINGS_SUCCESS,
  OPEN_ALL_MAPPINGS_PENDING,
  OPEN_ALL_MAPPINGS_SUCCESS,
  GET_RUNDECK_PROJECTS_PENDING,
  GET_RUNDECK_PROJECTS_SUCCESS,
  UPDATE_LOCAL_MAPPING,
  CLEAR_MAPPING_JOBS,
  CLEAR_RUNDECK_PROJECTS,
  SHOW_MAPPING_ERROR,
  UPDATE_MAPPING_FOUND,
  GET_MAPPING_SUCCESS
} from "../actions/mapping";
import {
  TICKET_GET_CONFIG_ERROR,
  TICKET_GET_CONFIG_PENDING,
  TICKET_GET_CONFIG_SUCCESS
} from "../actions/ticketConfig";

import { cloneDeep } from "lodash";

const defaultState = {
  currentPage: 0,
  lastPage: 0,
  mappingSelected: {},

  mappings: [],
  isFetchingMappings: false,

  mappingRundecks: [],
  isFetchingMappingRundecks: false,

  mappingJobsTicketManagement: "",
  isFetchingMappingJobsTicketManagement: false, // TODO: What is this?

  rundeckProjects: [],
  isFetchingRundeckProjects: false,

  mappingJobs: [],
  isFetchingMappingJobs: false,
  activeTemplates: {}, // TODO: Check why this is in mappings
  newTicket: {}, // TODO: Check why this is in mappings
  mappingFound: {}, //mapping found for prediction of current ticket drawer
  matchedMappings: []
};

function mapping(state = defaultState, action) {
  switch (action.type) {
    case OPEN_MAPPING: {
      return {
        ...state,
        mappingSelected: action.mappingSelected
      };
    }
    case SELECT_MAPPING: {
      return {
        ...state,
        mappingSelected: action.mapping
      };
    }
    case CLOSE_MAPPING: {
      return {
        ...state,
        mappingSelected: {}
      };
    }
    case MAPPING_CHANGE_PAGE: {
      return { ...state, currentPage: action.currentPage };
    }
    case OPEN_MAPPINGS_PENDING: {
      return { ...state, isFetchingMappings: true };
    }
    case OPEN_MAPPINGS_SUCCESS: {
      return {
        ...state,
        isFetchingMappings: false,
        mappings: [...action.mappings],
        lastPage: action.lastPage
      };
    }
    case OPEN_ALL_MAPPINGS_PENDING: {
      return {
        ...state,
        isFetchingMappings: true
      };
    }

    case OPEN_ALL_MAPPINGS_SUCCESS: {
      return {
        ...state,
        isFetchingMappings: false,
        mappings: [...action.mappings]
      };
    }

    case GET_RUNDECK_JOBS_PENDING: {
      return {
        ...state,
        isFetchingMappingJobs: true
      };
    }
    case GET_RUNDECK_JOBS_SUCCESS: {
      return {
        ...state,
        mappingJobs: action.mappingJobs,
        isFetchingMappingJobs: false
      };
    }
    case UPDATE_MAPPING_JOBS_TICKET_MANAGEMENT: {
      return {
        ...state,
        mappingJobsTicketManagement: action.ticketManagement
      };
    }
    case ACTIVE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        activeTemplates: action.activeTemplates,
        newTicket: action.newTicket
      };
    }
    case TICKET_GET_CONFIG_PENDING: {
      if (action.service && action.service.url === "rundeck") {
        return { ...state, isFetchingMappingRundecks: true };
      }
      if (action.service && action.service.url === "machineLearningModels") {
        return {
          ...state,
          isFetchingAvailablePredictions: true,
          availablePredictions: []
        };
      }
      return state;
    }

    case TICKET_GET_CONFIG_SUCCESS: {
      if (action.service && action.service.url === "rundeck") {
        return {
          ...state,
          mappingRundecks: action.body,
          isFetchingMappingRundecks: false
        };
      }
      if (action.service && action.service.url === "machineLearningModels") {
        return {
          ...state,
          availablePredictions: action.body,
          isFetchingAvailablePredictions: false
        };
      }
      return state;
    }

    case TICKET_GET_CONFIG_ERROR: {
      if (action.service && action.service.url === "rundeck") {
        return {
          ...state,
          isFetchingMappingRundecks: false
        };
      }
      if (action.service && action.service.url === "machineLearningModels") {
        return {
          ...state,
          isFetchingAvailablePredictions: false
        };
      }
      return state;
    }

    case GET_RUNDECK_PROJECTS_PENDING: {
      return { ...state, isFetchingRundeckProjects: true };
    }
    case GET_RUNDECK_PROJECTS_SUCCESS: {
      return {
        ...state,
        isFetchingRundeckProjects: false,
        rundeckProjects: action.rundeckProjects
      };
    }
    case UPDATE_LOCAL_MAPPING: {
      return { ...state, mappingSelected: action.mapping };
    }

    case CLEAR_MAPPING_JOBS: {
      return { ...state, mappingJobs: [] };
    }

    case CLEAR_RUNDECK_PROJECTS: {
      return { ...state, rundeckProjects: [] };
    }

    case SHOW_MAPPING_ERROR: {
      const mappingSelected = cloneDeep(state.mappingSelected);
      mappingSelected.error = action.errObj;
      return { ...state, mappingSelected };
    }

    case UPDATE_MAPPING_FOUND: {
      return { ...state, mappingFound: action.mappingFound };
    }

    case GET_MAPPING_SUCCESS: {
      return { ...state, matchedMappings: action.matchedMappings };
    }
    default:
      return state;
  }
}

export default mapping;
