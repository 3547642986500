/**
 * Created by x1 on 5/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import Checkbox from "material-ui/Checkbox";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

const muiTheme = getMuiTheme({
  fontFamily: '"Open Sans", sans-serif',
  palette: {
    primary1Color: themeMainUI.secondaryBorderedStyle.primary
  }
});

class SecondaryCheckbox extends React.Component {
  render() {
    const { requiredError, ...rest } = this.props;
    const renderError = typeof requiredError === "boolean" && requiredError;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <Checkbox
          labelStyle={
            renderError
              ? { color: themeMainUI.palette.errorColor }
              : { fontSize: 14 }
          }
          iconStyle={
            renderError ? { fill: themeMainUI.palette.errorColor } : {}
          }
          {...rest}
        />
      </MuiThemeProvider>
    );
  }
}

export default SecondaryCheckbox;
