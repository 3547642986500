import React from "react";
import PropTypes from "prop-types";
import {
  FontIcon,
  IconButton,
  IconMenu,
  MenuItem,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui";
import muiTheme from "../../styles/themeMainUI";

// Remove all HTML tags
const re = new RegExp("<.*?>", "g");

export default class EmailTemplatesTable extends React.Component {
  render() {
    return (
      <Table
        onCellClick={this.props.handleRowSelection}
        selectable
        displayRowCheckbox={false}
      >
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn width={150}>Name</TableHeaderColumn>
            <TableHeaderColumn width={200}>Subject</TableHeaderColumn>
            <TableHeaderColumn>Content</TableHeaderColumn>
            <TableHeaderColumn width={50} />
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
          deselectOnClickaway={false}
          showRowHover
        >
          {this.props.emailTemplates.map((item, i) => (
            <TableRow key={i}>
              <TableRowColumn width={150}>{item.name}</TableRowColumn>
              <TableRowColumn width={200}>{item.subject}</TableRowColumn>
              <TableRowColumn>{item.content.replace(re, " ")}</TableRowColumn>
              <TableRowColumn width={50}>
                {/* TODO: Change me (styling->sass) */}
                <IconMenu
                  iconButtonElement={
                    <IconButton
                      iconStyle={{
                        color: muiTheme.palette.secondaryBtnBorderColor,
                        fontSize: 20
                      }}
                    >
                      <FontIcon
                        className="fa fa-ellipsis-v"
                        style={{ fontSize: 14, color: "red" }}
                      />
                    </IconButton>
                  }
                >
                  <MenuItem
                    onClick={() => this.props.deleteEmailTemplate(item)}
                  >
                    Delete
                  </MenuItem>
                </IconMenu>
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

EmailTemplatesTable.propTypes = {
  // Array of email template objects
  emailTemplates: PropTypes.array

  // Opening an email template, excludes 5th column (id=4)
  // handleRowSelection: PropTypes.func,

  // Handle deleting a email template from a specific row
  // deleteEmailTemplate: PropTypes.func,
};
