import React from "react";
import EmailTemplatesLayout from "./EmailTemplatesLayout";
import PrimaryButton from "../templates/PrimaryButton";
import SecondaryCircularProgress from "../templates/SecondaryCircularProgress";
import MergeTagsTable from "./MergeTagsTable";
import { Drawer } from "material-ui";
import MergeTagForm, { formFields } from "./MergeTagForm";
import SecondaryNavigationClose from "../templates/SecondaryNavigationClose";
import _ from "lodash";
import ATRPaginator from "../templates/ATRPaginator";

export default class MergeTags extends React.Component {
  constructor(props) {
    super(props);

    this._changePage = this._changePage.bind(this);

    this.props.getMergeTags();
    this.props.getTemplateFields();
  }

  _handleRowSelection(row, col) {
    if (col === 5) {
      return;
    }

    this.props.openMergeTag(this.props.mergeTags[row]);
  }

  _changePage(moveIndex) {
    const nextPage = this.props.currentPageMerge + moveIndex;

    if (nextPage < 0 || nextPage > this.props.lastPageMerge) return null;

    this.props.changePageMerge(nextPage);
    this.props.getMergeTags(nextPage);
  }

  render() {
    return (
      <div>
        <EmailTemplatesLayout
          heading={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ marginLeft: 30, marginTop: 10, marginBottom: 20 }}>
                Merge Tags
              </h3>
              <div>
                <PrimaryButton
                  style={{ marginRight: 30 }}
                  onClick={() =>
                    this.props.openMergeTag({ ...formFields, new: true })
                  }
                  label={<strong>New Merge Tag</strong>}
                />
              </div>
            </div>
          }
          body={
            this.props.isFetchingMergeTags ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%"
                }}
              >
                <SecondaryCircularProgress />
              </div>
            ) : (
              <div>
                <MergeTagsTable
                  deleteMergeTag={this.props.deleteMergeTag}
                  mergeTags={this.props.mergeTags}
                  handleRowSelection={(row, col) =>
                    this._handleRowSelection(row, col)
                  }
                />
                <ATRPaginator
                  changePage={this._changePage}
                  currentPage={this.props.currentPageMerge + 1}
                  lastPage={this.props.lastPageMerge + 1}
                />
              </div>
            )
          }
        />
        ;
        <Drawer
          open={!_.isEmpty(this.props.selectedMergeTag)}
          openSecondary
          docked
          width={600}
          style={{
            overflow: "hidden"
          }}
          containerStyle={{
            overflow: "hidden"
          }}
        >
          <SecondaryNavigationClose
            onClick={() => this.props.closeMergeTag()}
            style={{
              position: "absolute",
              right: 7
            }}
          />

          <MergeTagForm {...this.props} />
        </Drawer>
      </div>
    );
  }
}
