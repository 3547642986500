import { connect } from "react-redux";
import {
  addProvider,
  removeProvider
} from "../../../../../../../../actions/tutorial";
import ProviderSelection from "../index";

const mapStateToProps = state => {
  return {
    selectedProviders: state.tutorial.selectedProviders
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProvider: provider => dispatch(addProvider(provider)),
    removeProvider: provider => dispatch(removeProvider(provider))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSelection);
