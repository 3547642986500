import React from "react";
import PropTypes from "prop-types";
import Styles from "../../styles/themeMainUI";
import SecondaryBorderedButton from "../templates/SecondaryBorderedButton";
import { Col } from "react-flexbox-grid/lib";

export default class ContinuousLearningButton extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          paddingBottom: "5px",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}
      >
        {this.props.ticket.coreData && (
          <Col xs={12} style={{ padding: "0px", display: "flex" }}>
            <div style={{ width: 200 }}>
              <SecondaryBorderedButton
                disabled={this.props.isDisabled}
                label="add to CML*"
                onClick={() => {
                  if (
                    this.props.ticket.mlData &&
                    this.props.ticket.mlData.length !== 0
                  ) {
                    for (let i = 0; i < this.props.ticket.mlData.length; i++) {
                      if (this.props.ticket.mlData[i].userValue === null) {
                        this.props.ticket.mlData[
                          i
                        ].userValue = this.props.ticket.mlData[
                          i
                        ].predictions[0].label;
                      }
                    }
                  }
                  this.props.addToContinuousML(this.props.ticket);
                }}
              />
            </div>
            {this.props.isDisabled ? (
              <div
                style={{
                  fontSize: 11,
                  color: Styles.palette.primary3Color,
                  marginTop: 10,
                  marginLeft: -50
                }}
              >
                * This ticket has been added to CML.
              </div>
            ) : (
              <div
                style={{
                  fontSize: 11,
                  color: Styles.palette.primary3Color
                }}
              >
                * Clicking this will add this ticket to the training dataset for
                continuous machine learning
                <strong> in it's current state</strong>.
              </div>
            )}
          </Col>
        )}
      </div>
    );
  }
}

ContinuousLearningButton.propTypes = {
  ticket: PropTypes.object.isRequired,
  addToContinuousML: PropTypes.func.isRequired
};
