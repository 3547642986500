import React, { useMemo, useCallback } from "react";
import { connect } from "react-redux";
import {
  setGtrWorkflow,
  setGtrWorkflowFilter,
  setGtrWorkflowFilterType
} from "../../../actions/tickets";
import GtrListItem from "./gtrListItem";
import useDebounce from "hook/useDebounce";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const GtrList = ({
  allGtrWorkflows,
  workflowFilter,
  workflowFilterType,
  selectedGtrWorkflow,
  selectedCategory,
  setGtrWorkflow,
  setGtrWorkflowFilter,
  setGtrWorkflowFilterType
}) => {
  const debouncedFilter = useDebounce(workflowFilter, 500).toLowerCase();

  const workflows = useMemo(
    () =>
      allGtrWorkflows.filter(
        w =>
          w.categoryName.toLowerCase().includes(debouncedFilter) ||
          w.resolutionFlow.toLowerCase().includes(debouncedFilter)
      ),
    [allGtrWorkflows, debouncedFilter]
  );

  const handleSetGtrWorkflow = useCallback(
    (workflowId, categoryId) => {
      setGtrWorkflow(workflowId, categoryId);
    },
    [setGtrWorkflow]
  );

  return (
    <Grid container>
      <Grid container alignItems="center" style={{ padding: 20 }}>
        <Input
          onChange={e => {
            setGtrWorkflowFilter(e.target.value);
          }}
          value={workflowFilter}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          style={{ marginRight: 20 }}
        />
      </Grid>
      {workflows.length > 0 ? (
        <>
          <Grid
            container
            alignItems="center"
            style={{ padding: 20, fontSize: 18, fontWeight: 600 }}
          >
            <Grid xs={1}></Grid>
            <Grid xs={5}>Ticket Classification</Grid>
            <Grid xs={5}>Resolution Flow</Grid>
          </Grid>

          {workflows.map(workflow => (
            <GtrListItem
              key={`${workflow.resolutionFlow}-${workflow.ticketCategoryId}`}
              workflow={workflow}
              checked={
                selectedGtrWorkflow === workflow.resolutionFlowId &&
                selectedCategory === workflow.ticketCategoryId
              }
              setGtrWorkflow={handleSetGtrWorkflow}
            />
          ))}
        </>
      ) : (
        <div>No GTR workflow available.</div>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedGtrWorkflow: state.tickets.selectedGtrWorkflow,
    selectedCategory: state.tickets.selectedCategory,
    allGtrWorkflows: state.tickets.allGtrWorkflows,
    workflowFilter: state.tickets.workflowFilter,
    workflowFilterType: state.tickets.workflowFilterType
  };
};

const mapDispatchToProps = dispatch => ({
  setGtrWorkflowFilterType: type => dispatch(setGtrWorkflowFilterType(type)),
  setGtrWorkflowFilter: filter => dispatch(setGtrWorkflowFilter(filter)),
  setGtrWorkflow: (workflowId, categoryId) =>
    dispatch(setGtrWorkflow(workflowId, categoryId))
});

export default connect(mapStateToProps, mapDispatchToProps)(GtrList);
