import { connect } from "react-redux";
import PluginDrawerBasicInfo from "./BasicInfo";

const mapStateToProps = (state, ownProps) => ({
  basicInfo: state.plugin.plugins.byId[ownProps.pluginId]
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

const PluginDrawerBasicInfoCTN = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginDrawerBasicInfo);

export default PluginDrawerBasicInfoCTN;
