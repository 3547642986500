/**
 * Created by rayemagp on 18/05/17.
 */
import React from "react";

import Badge from "material-ui/Badge";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import ActionSearch from "material-ui/svg-icons/action/search";
import Divider from "material-ui/Divider";

import history from "../../history";

import Styles from "./NavHeaderMobileStyles";
import PrimaryTextField from "../templates/PrimaryTextField";
import SecondaryButton from "../templates/SecondaryButton";
import { Row } from "react-flexbox-grid/lib";

class NavHeaderMobile extends React.Component {
  constructor(props) {
    super(props);
    this._toggleChatbot = this._toggleChatbot.bind(this);
    this._toggleNotification = this._toggleNotification.bind(this);
    this._toggleGamification = this._toggleGamification.bind(this);
    this._toggleDrawer = this._toggleDrawer.bind(this);
    this._toggleSidebarState = this._toggleSidebarState.bind(this);

    this.state = {
      sidebarWidth: 40,
      views: [
        {
          icon: "bars", // <EditorShowChart />,
          url: "/menu",
          badge: "",
          state: "menu"
        },
        {
          icon: "tags", // <EditorShowChart />,
          url: "/tickets",
          badge: "",
          state: "tickets"
        },
        {
          icon: "bar-chart-o", // <EditorShowChart />,
          url: "/gamification",
          badge: "",
          state: "gamification"
        },
        {
          icon: "commenting-o", // <ImageViewComfy />,
          url: "/chatbot",
          badge: "2",
          state: "chatbot"
        },
        {
          icon: "bell", // <CommunicationChat />,
          url: "/notifications",
          badge: "10",
          state: "notifications"
        },
        {
          icon: "tasks", // <CommunicationChat />,
          url: "/bulk-actions",
          badge: "10",
          state: "bulkActions"
        }
        /* {
          icon: 'search', // <CommunicationChat />,
          url: '#search',
          state: 'search'
        }, */
      ],
      toggleDrawers: {
        chatbot: this._toggleChatbot,
        notifications: this._toggleNotification,
        gamification: this._toggleGamification
      }
    };
  }

  _handleNewRequest = url => {
    // change url...
    // console.log('handle new request:', url);
    if (url instanceof Array) url = url[0];
    history.push(url);

    // ... & change state
    // TODO Change this to O(1) access
    this._closeDrawers();
    if (url === "/menu") this._toggleSidebarState("menu");
    else if (url === "/apps") this._toggleSidebarState("apps");
    else if (url === "/chatbot") this._toggleSidebarState("chatbot");
    else if (url === "/notifications")
      this._toggleSidebarState("notifications");
    else if (url === "/gamification") this._toggleSidebarState("gamification");
    else this._toggleSidebarState("");
  };

  // Toggle the right drawer
  _toggleDrawer = state => {
    this._closeDrawers();
    this.state.toggleDrawers[state]();
  };

  // Close all drawers
  _closeDrawers() {
    const {
      closeChatbot,
      closeGamification,
      closeNotification,
      isChatbotOpen,
      isGamificationOpen,
      isNotificationOpen
    } = this.props;
    if (isNotificationOpen) closeNotification();
    if (isChatbotOpen) closeChatbot();
    if (isGamificationOpen) closeGamification();
  }

  _toggleSidebarState(state) {
    const { sidebarSetState, sidebarState } = this.props;
    let newState = sidebarState;
    if (sidebarState.indexOf(state) === -1) {
      newState = [];
      newState.push(state);
    }
    sidebarSetState(newState);
  }

  _toggleChatbot() {
    const { isChatbotOpen, closeChatbot, openChatbot } = this.props;
    this._toggleSidebarState("chatbot");
    isChatbotOpen ? closeChatbot() : openChatbot();
  }

  _toggleNotification() {
    const {
      isNotificationOpen,
      closeNotification,
      openNotification
    } = this.props;
    this._toggleSidebarState("notifications");
    isNotificationOpen ? closeNotification() : openNotification();
  }

  _toggleGamification() {
    const {
      isGamificationOpen,
      closeGamification,
      openGamification
    } = this.props;
    this._toggleSidebarState("gamification");
    isGamificationOpen ? closeGamification() : openGamification();
  }
  //calculate what to show in badge content
  badgeContent = item => {
    const {
      chatbotBadge,
      notifications,
      unreadNotificationsBadge
    } = this.props;
    const unreadNotificationAmount = Math.max(
      notifications.filter(notification => !notification.read).length,
      unreadNotificationsBadge.length
    );
    if (item.state === "chatbot") {
      return chatbotBadge;
    } else if (item.state === "notifications") {
      return unreadNotificationAmount > 999 ? "..." : unreadNotificationAmount;
    }
  };

  shouldDisplayBadge = item => {
    const { chatbotBadge, unreadNotifications } = this.props;
    const unreadNotificationAmount = unreadNotifications.length || 0;
    return (
      (item.state === "chatbot" && chatbotBadge > 0) ||
      (item.state === "notifications" && unreadNotificationAmount > 0)
    );
  };

  render() {
    const views = this.state.views;
    const { sidebarState } = this.props;
    let currentUrl;
    history.location.pathname === "/"
      ? (currentUrl = "/tickets")
      : (currentUrl = history.location.pathname);

    return (
      <div>
        <div style={Styles.NavSidebar}>
          {views.map((item, i) => {
            let isSelected = item.url.indexOf(currentUrl) !== -1;
            // (currentUrl === item.url);

            return (
              <div
                key={i}
                style={
                  isSelected
                    ? Styles.NavSidebarItemSelected
                    : Styles.NavSidebarItem
                }
              >
                <Badge
                  badgeContent={this.badgeContent(item) || 0}
                  secondary={true}
                  badgeStyle={{
                    display: this.shouldDisplayBadge(item) ? "flex" : "none"
                  }}
                  style={
                    item.badge
                      ? Styles.NavSidebarBadgeContainer
                      : Styles.NavSidebarBadgeContainerEmpty
                  }
                >
                  <IconButton
                    style={{ height: 20 }}
                    onClick={() => {
                      typeof item.action === "undefined"
                        ? this._handleNewRequest(item.url)
                        : item.action(item.state);
                    }}
                    disableTouchRipple={true}
                  >
                    <FontIcon
                      color={isSelected ? "#ffffff" : "#C3CBCC"}
                      className={"fa fa-" + item.icon}
                    />
                  </IconButton>
                </Badge>
              </div>
            );
          })}
        </div>
        {sidebarState.indexOf("menu") !== -1 ? (
          <div
            style={{
              position: "fixed",
              height: "calc(100vh - 60px)",
              width: "100%",
              background: "white",
              zIndex: 10,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div style={Styles.NavSidebarSearch}>
              <ActionSearch style={{ paddingRight: 5 }} />
              <PrimaryTextField hintText="Search tickets" fullWidth={true} />
            </div>
            <Divider />
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                overflow: "scroll"
              }}
            >
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>My profile</strong>}
                fullWidth={true}
                onClick={() => this._handleNewRequest("/atr/settings/profile")}
              />
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>Apps</strong>}
                fullWidth={true}
                onClick={() => this._handleNewRequest("/apps")}
              />
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>Rules</strong>}
                fullWidth={true}
                onClick={() =>
                  this._handleNewRequest("/atr/settings/automation/rules")
                }
              />
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>Mappings</strong>}
                fullWidth={true}
                onClick={() =>
                  this._handleNewRequest("/atr/settings/automation/mapping")
                }
              />
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>Data Sources</strong>}
                fullWidth={true}
                onClick={() => this._handleNewRequest("/atr/settings/data")}
              />
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>Other Settings</strong>}
                fullWidth={true}
                onClick={() => this._handleNewRequest("/atr/settings")}
              />
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>Help</strong>}
                fullWidth={true}
                onClick={() => this._handleNewRequest("/atr/help")}
              />
              <Divider />
              <SecondaryButton
                style={{ flexShrink: 0 }}
                label={<strong>Sign Out</strong>}
                fullWidth={true}
                onClick={() => {
                  this.props.logout();
                }}
              />
            </Row>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default NavHeaderMobile;
