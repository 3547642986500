import React from "react";
import { Col } from "react-flexbox-grid/lib";
import styles from "../TicketDetailsDrawerStyles";
import MLPredictions from "./MLPredictions";
import { MLInput } from "./MLInput";
import { FontIcon, IconButton } from "material-ui";

const localStyles = {
  displayName: {
    fontSize: "12px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: 0,
    alignItems: "baseline"
  },
  input: {
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center"
  }
};

export default class MLField extends React.Component {
  render() {
    const {
      templateField,
      mlField,
      drawerFunctions,
      handleTicketUpdate
    } = this.props;
    return (
      <div style={styles.chipContainer}>
        <Col xs={12} sm={2} style={localStyles.displayName}>
          {templateField.displayName}
        </Col>
        <Col
          xs={12}
          sm={10}
          style={{
            ...localStyles.input,
            paddingLeft: templateField.type === "dropdown" ? 0 : 24
          }}
        >
          <MLInput
            atsInvalid={this.props.atsInvalid}
            manualResolutionConfig={this.props.manualResolutionConfig}
            MLItem={mlField}
            templateItem={templateField}
            drawerFunctions={drawerFunctions}
            handleTicketUpdate={handleTicketUpdate}
            ticket={this.props.ticket}
            handleReplaceTicket={this.props.handleReplaceTicket}
          />
          {templateField.name === "u_knowledge" && (
            <IconButton
              style={{ marginLeft: "-2rem" }}
              onClick={() => {
                this.props.tryKnowledgebastRedirect(
                  mlField.userValue || mlField.predictions[0].label,
                  this.props.ticket
                );
                this.props._handleTabChange(2);
              }}
              iconStyle={{ fontSize: 15 }}
            >
              <FontIcon className="fa fa-external-link" />
            </IconButton>
          )}
        </Col>

        <MLPredictions
          predictions={mlField.predictions}
          MLKey={mlField.key}
          MLUserValue={mlField.userValue}
          {...this.props}
        />
      </div>
    );
  }
}
