import { connect } from "react-redux";
import Item from "./Item";
import { setMappingField } from "redux/modules/ticketTemplate";
import { getMappingTicketField } from "redux/reselect/ticketTemplate";

const mapStateToProps = (state, ownProps) => {
  return {
    ticketFields: getMappingTicketField(state),
    mappingField:
      state.ticketTemplates.currentTemplate.mapping[ownProps.field.name] || ""
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMappingField: (fieldName, mappingValue) =>
      dispatch(setMappingField(fieldName, mappingValue))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
