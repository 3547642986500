import { call, put, takeLatest, select } from "redux-saga/effects";
import { cloneDeep } from "lodash";
import { openSnackbar } from "actions/snackbar";
import { GET_USER_DATA, CHANGE_USER_DATA } from "redux/modules/userData";
import {
  getUserDataSuccess,
  getUserDataPending,
  getUserDataError,
  changeUserDataError,
  changeUserDataPending,
  changeUserDataSuccess
} from "redux/modules/userData";
import config from "../../config";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../../utils/axios";

const getUserData = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/user-data`;
  return axios.get(url);
};

const changeUserData = data => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/user-data`;
  return axiosSnackbar.put(url, data);
};

export const ticketSagas = [
  takeLatest(GET_USER_DATA, getUserDataSaga),
  takeLatest(CHANGE_USER_DATA, changeUserDataSaga)
];

function* getUserDataSaga() {
  yield put(getUserDataPending());

  try {
    const { data } = yield call(getUserData);
    yield put(getUserDataSuccess(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield put(getUserDataSuccess({}));
    } else {
      yield put(getUserDataError());
    }
  }
}

function* changeUserDataSaga() {
  const {
    userData: { updatedUserData }
  } = yield select();

  yield put(changeUserDataPending());
  try {
    const { data } = yield call(changeUserData, updatedUserData);
    const newUpdatedUserData = cloneDeep(updatedUserData);
    yield put(openSnackbar("Success"));
    yield put(changeUserDataSuccess(newUpdatedUserData));
  } catch (err) {
    yield put(changeUserDataError(err));
  }
}
