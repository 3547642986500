import React from "react";
import { Menu, MenuItem, FontIcon } from "material-ui";
import { v4 as uuidv4 } from "uuid";

const NavMenuList = props => {
  return (
    <Menu style={{ flex: "1 1 auto" }}>
      {props.menuList.map((item, index) => {
        return (
          <div key={uuidv4()} data-cy={`${item.text}`}>
            <MenuItem
              key={index + item}
              primaryText={
                <span className={"popoverMenuItem"}>
                  <FontIcon
                    className={"fa fa-" + item.icon}
                    style={{ color: "#777777", fontSize: 17 }}
                  />
                  <div>{item.text}</div>
                </span>
              }
              onClick={() => {
                props.handleMenuClick(item.url);
              }}
            />
          </div>
        );
      })}
    </Menu>
  );
};

export default NavMenuList;
