import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  background-color: #378bee !important;
  width: 25px !important;
  height: 25px !important;
`;

const EditIconButton = props => {
  const { onClick } = props;
  return (
    <StyledIconButton onClick={onClick}>
      <Edit style={{ color: "white", fontSize: 15 }} />
    </StyledIconButton>
  );
};

EditIconButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default EditIconButton;
