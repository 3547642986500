import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { CheckCircle, MoreHoriz, Warning } from "@material-ui/icons";

export const InfoText = styled(Typography)`
  font-size: 10px;
  line-height: 14px;
  color: #2c2c2c !important;
  margin-left: 5px !important;
`;

export const StyledCheckCircle = styled(CheckCircle)`
  font-size: 15px !important;
  color: #7faf5a;
`;

export const StyledWarning = styled(Warning)`
  font-size: 15px !important;
  color: #d03636;
`;

export const StyledMoreHoriz = styled(MoreHoriz)`
  font-size: 15px !important;
  color: #fbbd44;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
`;

const LabeledIcon = ({ icon, label }) => (
  <IconContainer>
    {icon}
    <InfoText variant="caption">{label}</InfoText>
  </IconContainer>
);

export default LabeledIcon;
