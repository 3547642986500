import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  Button,
  Select,
  withStyles,
  Divider
} from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { ICSSProps, homePageStyles } from "./Styles";
import {
  ImportTicketProps,
  IProps,
  ITicketDetailsObject,
  ITicketDetailsProps,
  ITicketTypeProps,
  MapValueType
} from "../../containers/pages/DryRunPage";
import { DryRunUtils } from "./DryRunUtils";

const DryRunHomePage: React.FC<ICSSProps<typeof homePageStyles> &
  IProps> = React.memo(
  ({ classes, activeTemplates, history, getActiveTemplates }) => {
    const [ticketType, setTicketType] = useState<string>("");
    const [ticketStatus, setTicketStatus] = useState<string>("");
    const [ticketStatusList, setTicketStatusList] = useState<string[]>([]);
    const [primaryFields, setPrimaryFields] = useState<ITicketDetailsObject>(
      {}
    );
    const [secondaryFields, setSecondaryFields] = useState<
      ITicketDetailsObject
    >({});
    const [primaryTextField, setPrimaryTextField] = useState<
      Map<string, MapValueType>
    >(null);
    const [secondaryTextField, setSecondaryTextField] = useState<
      Map<string, MapValueType>
    >(null);
    const [ticketTypeList, setTicketTypeList] = useState<ITicketTypeProps[]>(
      []
    );
    const { state } = history.location;
    const {
      arrayIterator,
      handleActiveTicketTemplates,
      renderTextFieldsOnChange,
      convertArrayToObject
    } = DryRunUtils;
    const isActiveTemplateEmpty: boolean =
      Object.keys(activeTemplates).length === 0;

    useEffect(() => {
      let ticketTypes: ITicketTypeProps[],
        importExistingTicket: ImportTicketProps;

      if (!isActiveTemplateEmpty) {
        ticketTypes = handleActiveTicketTemplates(activeTemplates);
        setTicketTypeList(ticketTypes);
      } else {
        getActiveTemplates();
      }
      // check if state exists for imported tickets
      if (typeof state !== "undefined") {
        importExistingTicket = state as ImportTicketProps;
        const { activeTemplate, ticket } = importExistingTicket;
        setTicketType(activeTemplate?.ticketType);
        setTicketStatusList(activeTemplate?.states);
        setPrimaryFields(convertArrayToObject(activeTemplate?.primaryFields));
        setSecondaryFields(
          convertArrayToObject(activeTemplate?.secondaryFields)
        );
        setTicketStatus(
          typeof ticket?.allFields?.state === "string"
            ? ticket?.allFields?.state
            : ""
        );
        setPrimaryTextField(
          arrayIterator(
            convertArrayToObject(activeTemplate?.primaryFields),
            ticket?.allFields
          )
        );
        setSecondaryTextField(
          arrayIterator(
            convertArrayToObject(activeTemplate?.secondaryFields),
            ticket?.allFields
          )
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTemplates]);

    // handle select and input change
    const handleChange = (
      event: React.ChangeEvent<
        HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
      >,
      fieldName?: string
    ) => {
      const { name, value } = event.target;
      event.preventDefault();

      switch (name) {
        case "ticketType":
          const primaryMap = new Map<string, MapValueType>();
          const secondaryMap = new Map<string, MapValueType>();
          setTicketType(value);
          setPrimaryFields(
            convertArrayToObject(activeTemplates[value]?.primaryFields)
          );
          setSecondaryFields(
            convertArrayToObject(activeTemplates[value]?.secondaryFields)
          );
          setTicketStatusList(activeTemplates[value]?.states);
          activeTemplates[value]?.primaryFields?.map(
            (item: ITicketDetailsProps) => {
              primaryMap.set(item.name, "");
            }
          );
          activeTemplates[value]?.secondaryFields?.map(
            (item: ITicketDetailsProps) => {
              secondaryMap.set(item.name, "");
            }
          );
          setPrimaryTextField(primaryMap);
          setSecondaryTextField(secondaryMap);
          break;
        case "ticketStatus":
          setTicketStatus(value);
          break;
        case "primaryTextField":
          renderTextFieldsOnChange(
            fieldName,
            value,
            setPrimaryTextField,
            primaryTextField
          );
          break;
        case "secondaryTextField":
          renderTextFieldsOnChange(
            fieldName,
            value,
            setSecondaryTextField,
            secondaryTextField
          );
          break;
        default:
          break;
      }
    };
    const renderTicketTypeSelect = () => {
      return (
        <Grid container className={classes.ticketTypeContainer}>
          <Grid item xs={3}>
            <span className={classes.textStyle}>Ticket type</span>
          </Grid>
          <Grid item xs={3}>
            <Select
              data-testid="ticketTypeSelect-test"
              className={classes.ticketTypeSelect}
              value={ticketType}
              name="ticketType"
              onChange={handleChange}
            >
              {ticketTypeList &&
                ticketTypeList.map((item: ITicketTypeProps) => {
                  return (
                    <MenuItem key={item.displayName} value={item.type}>
                      {item.displayName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </Grid>
      );
    };

    const renderDynamicFields = (name: string) => {
      const textField =
        name === "primaryTextField" ? primaryTextField : secondaryTextField;
      const fields =
        name === "primaryTextField" ? primaryFields : secondaryFields;
      const id =
        name === "primaryTextField" ? "primaryFields" : "secondaryFields";
      return (
        <>
          {textField &&
            Array.from(textField.keys()).map((item: string) => {
              return (
                <Grid key={item} container justify="flex-start">
                  <Grid item xs={3}>
                    {fields[item]?.displayName}
                  </Grid>
                  <Grid item xs={3}>
                    {fields[item]?.type === "dropdown" ? (
                      <Select
                        className={classes.fieldDropDown}
                        value={textField.get(item)}
                        name={name}
                        onChange={event => handleChange(event, item)}
                      >
                        {fields[item] &&
                          fields[item]?.values.map((item: string) => {
                            return (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    ) : (
                      <TextField
                        id={id}
                        name={name}
                        value={textField.get(item)}
                        className={classes.textField}
                        onChange={event => handleChange(event, item)}
                      />
                    )}
                  </Grid>
                </Grid>
              );
            })}
        </>
      );
    };

    const renderCreateNewTest = () => {
      return (
        <>
          {renderDynamicFields("primaryTextField")}
          {secondaryFields && Object.keys(secondaryFields).length !== 0 && (
            <Divider style={{ margin: "30px 0px" }} />
          )}
          {renderDynamicFields("secondaryTextField")}
        </>
      );
    };
    return (
      <>
        {!isActiveTemplateEmpty && (
          <Grid container spacing={0} className={classes.rootContainer}>
            <Grid className={classes.testingPlayground}>
              Testing Playground
            </Grid>
            <Grid className={classes.testingIntro}>
              Test your automation configurations such as Automation Rules and
              Mappings and ML Predictions by choosing an existing ticket or
              creating a dummy ticket below. Dummy tickets can be saved as a
              preset for convenience.
            </Grid>
            {renderTicketTypeSelect()}
            <Grid item xs={12} className={classes.createNewTestContainer}>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "20px"
                }}
              >
                <span className={classes.createNewTestTitle}>
                  Create new test
                </span>
                <p className={classes.createNewTestText}>
                  Create a dummy ticket to test your automation configuration by
                  filling in the details below. Alternatively, pre-fill using
                  existing ticket data on the right.
                </p>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  Ticket state
                </Grid>
                <Grid item xs={3}>
                  <Select
                    data-testid="ticketStatusSelect-test"
                    className={classes.ticketStatusSelect}
                    value={ticketStatus}
                    name="ticketStatus"
                    onChange={handleChange}
                  >
                    {ticketStatusList &&
                      ticketStatusList.map((item: string) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Grid>
              </Grid>
              {renderCreateNewTest()}
              <Grid
                data-testid="button-test"
                container
                style={{ display: "flex", marginTop: "150px" }}
              >
                <Button className={classes.saveAsPreset}>SAVE AS PRESET</Button>
                <Button className={classes.clear}>CLEAR</Button>
                <Button className={classes.runTest}>
                  <PlayArrowIcon style={{ marginRight: "5px" }} />
                  RUN TEST
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);
export default withStyles(homePageStyles)(DryRunHomePage);
