import styled from "styled-components";
import { ButtonBase, Grid } from "@material-ui/core";

export const MainContainer = styled(Grid)`
  background-color: #e3f0fc;
  font-size: 1rem;
  padding: 20px;

  @media screen and (max-width: 992px) {
    font-size: 0.8rem;
    padding: 7.5px;
  }

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: 0.9rem;
    padding: 14px;
  }

  @media screen and (max-height: 700px) {
    font-size: 0.8rem;
    padding: 7.5px;
  }

  @media screen and (min-height: 700px) and (max-height: 800px) {
    font-size: 0.9rem;
    padding: 14px;
  }
`;

export const FieldsContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColumnText = styled.span`
  font-weight: bold;
`;

export const ValuesContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

export const AddButton = styled(ButtonBase)`
  border-radius: 20px !important;
  padding: 0px 5px !important;
  color: #378bee !important;
`;
