import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import FileUploader from "../FileUploader";
import config from "config";

const uploaderConfig = {
  fileTypes: ["csv", "pdf", "jpg", "png", "txt", "jpeg"],
  fileAccept: "file_extension",
  fileAddedMessage: "Started!",
  completedMessage: "Complete!",
  baseServiceUrl:
    config.urls.base +
    config.urls.apis["ticket-management"] +
    "/tickets/uploadAttachment/",
  maxFiles: 1
};

const AttachmentUploader = props => {
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  const {
    token,
    ticketNumber,
    isSnackbarOpen,
    closeSnackbar,
    openSnackbar,
    onUploadSuccess
  } = props;

  const onError = useCallback(
    message => {
      if (!isSnackbarOpen) {
        openSnackbar(message);
        setTimeout(() => {
          closeSnackbar();
        }, 1500);
      }
    },
    [isSnackbarOpen, openSnackbar, closeSnackbar]
  );

  const onStartUpload = () => {
    console.log("Upload started");
  };

  const onFileAdded = (file, resumable) => {
    setIsUploadVisible(true);
  };

  const onFileSuccess = (file, fileServer) => {
    const res = JSON.parse(fileServer);
    onUploadSuccess(res.attachmentData);
  };

  return (
    <FileUploader
      headerObject={{
        apiToken: token,
        "X-HTTP-Method-Override": "PUT",
        name: true // dummy value so uploader can upload
      }}
      chunkSize={1024 * 1024 * 1024} // 1024 MB attachment limit in SNOW
      filetypes={uploaderConfig.fileTypes}
      fileAccept={uploaderConfig.fileAccept}
      fileAddedMessage={uploaderConfig.fileAddedMessage}
      completedMessage={uploaderConfig.completedMessage}
      service={uploaderConfig.baseServiceUrl + ticketNumber}
      openSnackbar={onError}
      onFileSuccess={onFileSuccess}
      onFileAdded={onFileAdded}
      maxFiles={uploaderConfig.maxFiles}
      setIsUploadVisible={setIsUploadVisible}
      startButton={isUploadVisible}
      cancelButton={isUploadVisible}
      onStartUpload={onStartUpload}
    />
  );
};

AttachmentUploader.propTypes = {
  token: PropTypes.string.isRequired,
  ticketNumber: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  isSnackbarOpen: PropTypes.bool,
  openSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func
};

export default AttachmentUploader;
