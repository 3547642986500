import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Styles from "../../../../../styles/themeMainUI";

const StyledListItemText = styled.div`
  width: 100%;
  font-size: 13px;
  padding: ${props => (props.isPlaceholder ? "20px" : "10px")};
  color: ${props =>
    props.isPlaceholder ? Styles.palette.accent3Color : "black"};
  ${props =>
    props.isPlaceholder
      ? `background-color: ${Styles.palette.accent2Color};`
      : ""}
`;

const AttachmentItem = props => {
  const { label, onClick, isPlaceholder } = props;
  return (
    <ListItem button={onClick !== undefined} onClick={onClick}>
      <StyledListItemText isPlaceholder={isPlaceholder}>
        {label}
      </StyledListItemText>
      {!isPlaceholder && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="download" onClick={onClick}>
            <GetAppIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

AttachmentItem.propTypes = {
  label: PropTypes.string.isRequired,
  isPlaceholder: PropTypes.bool,
  onClick: PropTypes.func
};

AttachmentItem.defaultProps = {
  isPlaceholder: false
};

export default AttachmentItem;
