import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import PrimaryButton from "../templates/PrimaryButton";
// import SecondaryDropDownMenu from "../templates/SecondaryDropDownMenu";
// import { MenuItem } from "material-ui";
import { MenuItem, FormControl, Select } from "@material-ui/core";

export default class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this._handleInput = this._handleInput.bind(this);

    this.state = {
      user: {},
      errors: [],
      timezones: []
    };
  }

  UNSAFE_componentWillMount() {
    const user = this.props.user;
    const timezones = this.props.timezones;
    user.password = "";
    this.setState({ user, timezones });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const user = props.user;
    const timezones = props.timezones;
    if (Object.keys(props.user).length > 0) {
      user.password = "";
      user.oldPassword = "";
      this.setState({ user: props.user, timezones });
    }
  }

  // formatUser(user){
  // 	return {
  // 		id: user['id'],
  // 		firstName: user['First Name'],
  // 		lastName: user['Last Name'],
  // 		username: user['Username'],
  // 		password: user['Password'],
  // 		emails: user['Secondary Email'].length > 0 ? [ user['Primary Email'], user['Secondary Email'] ] : [ user['Primary Email'] ],
  // 		mobile: user['Mobile'],
  // 		groups: user['Groups'],
  // 		permissions: user['Permissions']
  // 	}
  // }

  _validate(user) {
    return true;
    // const required = ["firstName", "lastName", "username", "email0"];
    // const errors = [];
    // required.forEach(item => {
    //   if (this.state.user[item].length <= 0) {
    //     errors.push(item);
    //   }
    // });
    // this.setState(state =>
    //   Object.assign({}, state, {
    //     errors
    //   })
    // );
    // return errors.length === 0;
  }

  _handleInput(name, value) {
    const user = this.state.user;

    if (name.indexOf("email") !== -1) {
      switch (name) {
        case "email0":
          user.emails[0] = value;
          break;
        case "email1":
          user.emails[1] = value;
          break;
        default:
      }
    } else {
      user[name] = value;
    }
    this.setState(user);
  }

  _searchPermissions(value) {
    this.props._searchPermissions(value, this.props.jwt);
  }

  render() {
    const user = this.state.user;
    const emails = typeof user.emails === "object" ? user.emails : []; // (user.emails)

    return (
      <div>
        <form>
          <Input
            newval={user.firstName}
            type="text"
            name="firstName"
            floatingLabelText="First Name"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("First Name") !== -1}
          />
          <Input
            newval={user.lastName}
            type="text"
            name="lastName"
            floatingLabelText="Last Name"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Last Name") !== -1}
          />
          <Input
            disabled
            newval={user.username}
            type="text"
            name="username"
            floatingLabelText="Username"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Username") !== -1}
          />
          <Input
            disabled
            newval={user.id}
            type="text"
            name="id"
            floatingLabelText="User Id"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("id") !== -1}
          />
          <Input
            newval={user.oldPassword}
            type="password"
            name="oldPassword"
            floatingLabelText="Old Password"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Old Password") !== -1}
          />
          <Input
            newval={user.password}
            type="password"
            name="password"
            floatingLabelText="Password"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Password") !== -1}
          />
          <Input
            newval={this.state.repeatPassword}
            type="password"
            name="repeatPassword"
            floatingLabelText="Repeat password"
            change={(name, value) => {
              this.setState({ ...this.state, repeatPassword: value });
            }}
            invalid={this.state.errors.indexOf("Repeat Password") !== -1}
          />
          <Input
            newval={emails.length > 0 ? emails[0] : ""}
            type="email"
            name="email0"
            floatingLabelText="Primary Email"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Primary Email") !== -1}
          />
          <Input
            newval={emails.length > 1 ? emails[1] : ""}
            type="email"
            name="email1"
            floatingLabelText="Secondary Email"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Secondary Email") !== -1}
          />
          <Input
            newval={user.mobile}
            type="text"
            name="mobile"
            floatingLabelText="Mobile"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Mobile") !== -1}
          />
          <Input
            newval={user.timezone}
            type="timezone"
            name="timezone"
            floatingLabelText="Timezone"
            change={(name, value) => this._handleInput(name, value)}
            invalid={this.state.errors.indexOf("Timezone") !== -1}
            options={this.state.timezones}
          />
        </form>

        <Row center="xs" style={{ marginTop: 20, marginBottom: 100 }}>
          <Col>
            <PrimaryButton
              style={{ width: 170, height: 40 }}
              label={<strong>Update</strong>}
              onClick={() => {
                const user = this.state.user;

                this._validate(user);

                // return;
                this.props.saveUser(this.state.user);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.newval || ""
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const state = this.state;
    switch (this.props.type) {
      case "text":
      default:
        state.value = props.newval;
    }
    this.setState(state);
  }

  // _handleInput(text){
  //   let state = this.state;
  //   state.value = text;
  //   this.setState(state);
  // }

  _handleNewRequest(item) {
    if (
      this.props.autocomplete.indexOf(item) !== -1 &&
      this.state.tags.indexOf(item) === -1
    ) {
      this.props.change(this.props.name, item);
      this.setState({ value: "", tags: this.state.tags });
    }
  }

  _handleRequestDelete(item) {
    const state = this.state;
    state.tags.splice(
      state.tags.indexOf(
        item.target.parentNode.parentNode.firstChild.innerText.trim()
      ),
      1
    );
    this.setState(state);
  }

  render() {
    let box;
    switch (this.props.type) {
      case "timezone":
        box = (
          <FormControl fullWidth>
            <Select
              value={this.state.value}
              onChange={e => {
                this.props.change(this.props.name, e.target.value);
              }}
            >
              {this.props.options &&
                this.props.options.map(({ label, value }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        );
        break;
      default:
        box = (
          <input
            disabled={this.props.disabled}
            type={this.props.type || "text"}
            style={{
              border: this.props.invalid
                ? "2px solid #F44336"
                : "1px solid darkgrey",
              width: "100%",
              minHeight: 25,
              borderRadius: 5,
              textIndent: 10,
              fontSize: 16
            }}
            value={this.state.value || ""}
            onChange={e => {
              this.props.change(this.props.name, e.target.value);
            }}
            // floatingLabelText={this.props.floatingLabelText}
          />
        );
        break;
    }
    return (
      <Row
        style={{
          lineHeight: 3,
          marginLeft: 10,
          padding: 10,
          paddingRight: 0
        }}
      >
        <Col xsOffset={1} xs={2} style={{ padding: 0 }}>
          <div style={{ fontSize: 14, marginTop: 5, color: "#7b7b7b" }}>
            {this.props.floatingLabelText}
          </div>
        </Col>
        <Col xs={6} style={{ padding: 0 }}>
          {box}
        </Col>
      </Row>
    );
  }
}
// export default UserForm;
