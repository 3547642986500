import { normalize } from "normalizr";
import update from "immutability-helper";
import { schema } from "normalizr";
//Async
export const SUBMIT_NEW_PLUGIN = "plugin/SUBMIT_NEW_PLUGIN";
export const SUBMIT_TICKETSOURCE = "plugin/SUBMIT_TICKETSOURCE";
export const SUBMIT_CONFIG = "plugin/SUBMIT_CONFIG";
export const GET_TICKETSOURCES = "plugin/GET_TICKETSOURCES";
export const UPDATE_ACTIVATION = "plugin/UPDATE_ACTIVATION";
export const GET_PLUGINS = "plugin/GET_PLUGINS";
export const GET_CONFIG = "plugin/GET_CONFIG";
export const GET_HEALTH = "plugin/GET_HEALTH";

const UPDATE_COLLAPSE = "plugin/UPDATE_COLLAPSE";
const GET_PLUGINS_SUCCESS = "plugin/GET_PlUGINS_SUCCESS";
const OPEN_PLUGIN = "plugin/OPEN_PLUGIN";
const CLOSE_PLUGIN = "plugin/CLOSE_PLUGIN";
const GET_CONFIG_SUCCESS = "plugin/GET_CONFIG_SUCCESS";
const UPDATE_CONFIG = "plugin/UPDATE_CONFIG";
const OPEN_PLUGIN_DATA = "plugin/OPEN_PLUGIN_DATA";
const GET_TICKETSOURCES_SUCCESS_PLUGIN =
  "plugin/GET_TICKETSOURCES_SUCCESS_PLUGIN";
const SUBMIT_CONFIG_SUCCESS = "plugin/SUBMIT_CONFIG_SUCCESS";
const SUBMIT_CONFIG_FAIL = "plugin/SUBMIT_CONFIG_FAIL";
const START_LOADING = "plugin/START_LOADING";
const STOP_LOADING = "plugin/STOP_LOADING";
const SUBMIT_TICKETSOURCE_SUCCESS = "plugin/SUBMIT_TICKETSOURCE_SUCCESS";
const UPDATE_TICKETSOURCE = "plugin/UPDATE_TICKETSOURCE";
const OPEN_NEW_PLUGIN = "plugin/OPEN_NEW_PLUGIN";
const CLOSE_NEW_PLUGIN = "plugin/CLOSE_NEW_PLUGIN";
const DROP_FILE = "plugin/DROP_FILE";
const CANCEL_FILE = "plugin/CANCEL_FILE";
const CHANGE_OVERRIDE = "plugin/CHANGE_OVERRIDE";
const CANCEL_TICKETSOURCE = "plugin/CANCEL_TICKETSOURCE";
const GET_CONFIG_START_LOADING = "plugin/GET_CONFIG_START_LOADING";
const GET_CONFIG_STOP_LOADING = "plugin/GET_CONFIG_STOP_LOADING";
const UPDATE_ACTIVATION_SUCCESS = "plugin/UPDATE_ACTIVATION_SUCCESS";
const GET_HEALTH_START_LOADING = "plugin/GET_HEALTH_START_LOADING";
const GET_HEALTH_STOP_LOADING = "plugin/GET_HEALTH_STOP_LOADING";
const GET_HEALTH_SUCCESS = "plugin/GET_HEALTH_SUCCESS";

const initState = {
  plugins: {
    byId: {},
    allIds: []
  },
  configs: {
    byId: {},
    allIds: []
  },
  healthReports: {
    reports: [],
    isLoading: false
  },
  ticketSources: [],
  currentPluginId: null,
  currentPlugin: {},
  isLoading: false,
  isNewPlugin: false,
  files: [],
  isOverride: false
};

const plugin = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_COLLAPSE:
      return update(state, {
        currentPlugin: {
          configs: {
            groups: {
              [action.index]: {
                collapsed: {
                  $set: !state.currentPlugin.configs.groups[action.index]
                    .collapsed
                }
              }
            }
          }
        }
      });
    case START_LOADING:
      return update(state, {
        isLoading: { $set: true }
      });
    case STOP_LOADING:
      return update(state, {
        isLoading: { $set: false }
      });
    case GET_PLUGINS_SUCCESS:
      return update(state, {
        plugins: {
          byId: { $set: action.data.entities.plugins },
          allIds: { $set: action.data.result }
        }
      });
    case GET_CONFIG_START_LOADING:
      return update(state, {
        configs: {
          byId: { [action.id]: { $set: { isLoading: true } } }
        }
      });
    case GET_CONFIG_STOP_LOADING:
      return update(state, {
        configs: {
          byId: { [action.id]: { $merge: { isLoading: false } } }
        }
      });
    case GET_CONFIG_SUCCESS:
      return update(state, {
        configs: {
          byId: { [action.id]: { $merge: action.data } },
          allIds: { $set: [...new Set([...state.configs.allIds, action.id])] }
        }
      });
    case GET_HEALTH_START_LOADING:
      return update(state, {
        healthReports: {
          isLoading: { $set: true }
        }
      });
    case GET_HEALTH_STOP_LOADING:
      return update(state, {
        healthReports: {
          isLoading: { $set: false }
        }
      });
    case GET_HEALTH_SUCCESS:
      return update(state, {
        healthReports: {
          reports: { $set: action.data }
        }
      });
    case OPEN_PLUGIN:
      return { ...state, currentPluginId: action.id };
    case CLOSE_PLUGIN:
      return { ...state, currentPluginId: null, currentPlugin: {} };
    case UPDATE_CONFIG:
      const { pluginId, value } = action.data;
      const index = state.currentPlugin.configs.fields
        .map(field => field.id)
        .indexOf(pluginId);
      if (pluginId === null)
        return update(state, {
          currentPlugin: {
            configs: { [value]: { $set: !state.currentPlugin.configs[value] } }
          }
        });
      else if (pluginId === "TICKET_TYPE") {
        const offsetIndex = 2;
        const prefixIndex = 3;
        let ticketTypeJsonData = JSON.parse(
          state.currentPlugin.configs.fields[1].value
        );
        return update(state, {
          currentPlugin: {
            configs: {
              fields: {
                [offsetIndex]: {
                  value: { $set: ticketTypeJsonData[value].TICKET_NUM_OFFSET }
                },
                [prefixIndex]: {
                  value: { $set: ticketTypeJsonData[value].TICKET_NUM_PREFIX }
                },
                [index]: { value: { $set: value } }
              }
            }
          }
        });
      } else
        return update(state, {
          currentPlugin: {
            configs: { fields: { [index]: { value: { $set: value } } } }
          }
        });

    case UPDATE_TICKETSOURCE:
      const { idx, value: fieldValue, field } = action;
      return update(state, {
        currentPlugin: {
          ticketSources: {
            [idx]: { polling: { [field]: { $set: fieldValue } } }
          }
        }
      });

    case OPEN_PLUGIN_DATA:
      return update(state, {
        currentPlugin: {
          basicInfo: { $set: state.plugins.byId[action.id] },
          configs: { $set: state.configs.byId[action.id] },
          ticketSources: {
            $set: state.ticketSources.filter(
              ticketSource =>
                ticketSource.description.providerId === state.currentPluginId
            )
          }
        }
      });
    case GET_TICKETSOURCES_SUCCESS_PLUGIN:
      return update(state, { ticketSources: { $set: action.data } });
    case SUBMIT_CONFIG_SUCCESS:
      const { confValid, id } = action;
      return update(state, {
        plugins: {
          byId: {
            [id]: {
              confValid: { $set: confValid },
              activated: { $set: state.currentPlugin.configs.activated }
            }
          }
        },
        configs: { byId: { [id]: { $set: state.currentPlugin.configs } } }
      });
    case SUBMIT_CONFIG_FAIL:
      const { errors } = action;
      return update(state, {
        currentPlugin: {
          configs: {
            errors: { $set: errors }
          }
        }
      });
    case SUBMIT_TICKETSOURCE_SUCCESS:
      const { idx: sourceIdx } = action;
      return update(state, {
        ticketSources: {
          [sourceIdx]: {
            polling: {
              $set: state.currentPlugin.ticketSources[sourceIdx].polling
            }
          }
        }
      });
    case CANCEL_TICKETSOURCE:
      const { idx: sourceIndex, ticketType } = action;
      return update(state, {
        currentPlugin: {
          ticketSources: {
            [sourceIndex]: {
              $set: state.ticketSources.find(
                ticketSource =>
                  ticketSource.description.ticketType === ticketType
              )
            }
          }
        }
      });

    case OPEN_NEW_PLUGIN:
      return update(state, {
        isNewPlugin: { $set: true }
      });

    case CLOSE_NEW_PLUGIN:
      return update(state, {
        isNewPlugin: { $set: false },
        files: { $set: [] },
        isOverride: { $set: false }
      });
    case DROP_FILE:
      return update(state, {
        files: { $set: action.files }
      });
    case CANCEL_FILE:
      return update(state, {
        files: { $set: [] },
        isOverride: { $set: false }
      });
    case CHANGE_OVERRIDE:
      return update(state, {
        isOverride: { $set: !state.isOverride }
      });

    case UPDATE_ACTIVATION_SUCCESS:
      const activated = !state.plugins.byId[action.id].activated;
      return update(state, {
        plugins: {
          byId: {
            [action.id]: {
              activated: { $set: activated }
            }
          }
        },
        configs: {
          byId: {
            [action.id]: {
              activated: { $set: activated }
            }
          }
        }
      });
    default:
      return state;
  }
};

export default plugin;

export const updateCollapse = index => ({ type: UPDATE_COLLAPSE, index });
export const getPlugins = () => ({ type: GET_PLUGINS });
export const savePlugins = data => ({
  type: GET_PLUGINS_SUCCESS,
  data: normalize(data, pluginListSchema)
});
export const openPlugin = id => ({ type: OPEN_PLUGIN, id });
export const closePlugin = () => ({ type: CLOSE_PLUGIN });
export const getConfig = id => ({ type: GET_CONFIG, id });
export const getConfigStartLoading = id => ({
  type: GET_CONFIG_START_LOADING,
  id
});
export const getConfigStopLoading = id => ({
  type: GET_CONFIG_STOP_LOADING,
  id
});
export const saveConfig = ({ id, data }) => ({
  type: GET_CONFIG_SUCCESS,
  id,
  data
});
export const updateConfig = ({ pluginId, value }) => ({
  type: UPDATE_CONFIG,
  data: { pluginId, value }
});

export const updateActivation = id => ({
  type: UPDATE_ACTIVATION,
  id
});
export const updateActivationSuccess = id => ({
  type: UPDATE_ACTIVATION_SUCCESS,
  id
});

export const openPluginData = id => ({ type: OPEN_PLUGIN_DATA, id });
export const getTicketSources = () => ({ type: GET_TICKETSOURCES });
export const saveTicketSources = data => ({
  type: GET_TICKETSOURCES_SUCCESS_PLUGIN,
  data
});
export const submitConfig = id => ({ type: SUBMIT_CONFIG, id });
export const submitConfigSuccess = ({ id, confValid }) => ({
  type: SUBMIT_CONFIG_SUCCESS,
  id,
  confValid
});

export const submitConfigFail = ({ id, errors }) => ({
  type: SUBMIT_CONFIG_FAIL,
  id,
  errors
});

export const startLoading = () => ({ type: START_LOADING });
export const stopLoading = () => ({ type: STOP_LOADING });
export const submitTicketSource = ({ idx, ticketType }) => ({
  type: SUBMIT_TICKETSOURCE,
  ticketType,
  idx
});
export const cancelTicketSource = ({ idx, ticketType }) => ({
  type: CANCEL_TICKETSOURCE,
  ticketType,
  idx
});
export const submitTicketSourceSuccess = idx => ({
  type: SUBMIT_TICKETSOURCE_SUCCESS,
  idx
});

export const updateTicketSource = ({ idx, field, value }) => ({
  type: UPDATE_TICKETSOURCE,
  field,
  value,
  idx
});

export const openNewPlugin = () => ({
  type: OPEN_NEW_PLUGIN
});

export const closeNewPlugin = () => ({
  type: CLOSE_NEW_PLUGIN
});

export const onDrop = files => ({
  type: DROP_FILE,
  files
});

export const onCancel = () => ({
  type: CANCEL_FILE
});

export const onChangeOverride = () => ({
  type: CHANGE_OVERRIDE
});

export const onSubmitNewPlugin = () => ({
  type: SUBMIT_NEW_PLUGIN
});

export const getHealth = () => ({
  type: GET_HEALTH
});

export const getHealthStartLoading = () => ({ type: GET_HEALTH_START_LOADING });

export const getHealthStopLoading = () => ({ type: GET_HEALTH_STOP_LOADING });

export const saveHealth = data => ({ type: GET_HEALTH_SUCCESS, data });

//other
const pluginSchema = new schema.Entity("plugins");
const pluginListSchema = [pluginSchema];
