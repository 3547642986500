import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  max-width: 1000px;
`;

export const UseCaseContainer = styled(Grid)`
  width: 100%;
`;
