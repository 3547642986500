import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, FormControlLabel, Switch } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import InputField from "../../../../../common/InputField";
import {
  MainContainer,
  StyledForm,
  StyledButton,
  ButtonText,
  ButtonContainer
} from "./styles";
import { initialSNOWConfig as initialConfig } from "../../../../../../reducers/tutorial";

const SNOWForm = props => {
  const {
    handleNext,
    updateField,
    submitConfig,
    currentPlugin,
    isSnackbarOpen,
    snackbarMessage,
    config,
    setConfig
  } = props;
  const [isConnecting, setIsConnecting] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    const snowConfig = currentPlugin.configs;
    if (snowConfig) {
      // if connecting, check if snackbar open, then check for errors
      // workaround for not having async function when updating config
      if (isConnecting && isSnackbarOpen) {
        setIsConnecting(false);
        return;
      }

      if (
        handleNext &&
        !isConnecting &&
        isSnackbarOpen &&
        snackbarMessage.includes("Success")
      ) {
        handleNext();
        return;
      }

      if (!snowConfig.isLoading && !isConnecting) {
        const newConfig = { ...initialConfig };
        newConfig.isLoading = snowConfig.isLoading;
        newConfig.isValid =
          snowConfig.confValid && snowConfig.errors.length === 0;
        for (const field of snowConfig.fields) {
          const key = field.id.toLowerCase();
          if (Object.keys(newConfig).includes(key)) {
            newConfig[key] = field.value;
          }
        }
        setConfig(newConfig);
      }
    }
  }, [
    setConfig,
    currentPlugin,
    handleNext,
    isConnecting,
    isSnackbarOpen,
    setIsConnecting,
    snackbarMessage
  ]);

  useEffect(() => {
    if (!isToggled && config) {
      if (config.pwd && config.url && config.user && config.isValid) {
        setCanEdit(false);
      }
    }
  }, [config, setCanEdit, setIsToggled, isToggled]);

  const handleConnect = useCallback(() => {
    if (!config.isLoading) {
      submitConfig("servicenow");
      setConfig({ ...config, isLoading: true });
      setIsConnecting(true);
    }
  }, [submitConfig, setConfig, config, setIsConnecting]);

  const handleChange = useCallback(
    (key, value) => {
      updateField(key.toUpperCase(), value);
      setConfig({ ...config, [key]: value });
      if (!isToggled) {
        setIsToggled(true);
      }
    },
    [setConfig, updateField, config, isToggled]
  );

  const handleSwitch = useCallback(
    e => {
      setCanEdit(e.target.checked);
    },
    [setCanEdit]
  );

  return (
    <MainContainer>
      <StyledForm>
        <InputField
          label="ServiceNow base URL"
          onChange={value => handleChange("url", value)}
          value={config.url || ""}
          disabled={config.isLoading || !canEdit}
        />
        <InputField
          label="ServiceNow user"
          onChange={value => handleChange("user", value)}
          value={config.user || ""}
          disabled={config.isLoading || !canEdit}
        />
        <InputField
          label="ServiceNow password"
          type="password"
          onChange={value => handleChange("pwd", value)}
          value={config.pwd || ""}
          disabled={config.isLoading || !canEdit}
        />
        <ButtonContainer>
          <FormControlLabel
            control={
              <Switch
                checked={canEdit}
                onChange={handleSwitch}
                color="primary"
              />
            }
            label="Enable editing"
          />
          <StyledButton
            color="primary"
            variant="contained"
            onClick={handleConnect}
            disabled={!canEdit}
            connected={!config.isLoading && config.isValid}
          >
            {config.isLoading && (
              <>
                <ButtonText>CONNECTING...</ButtonText>
                <CircularProgress size={20} style={{ color: "white" }} />
              </>
            )}
            {!config.isLoading && !config.isValid && (
              <span>TEST CONNECTION</span>
            )}
            {!config.isLoading && config.isValid && (
              <>
                <ButtonText>CONNECTED</ButtonText>
                <Done style={{ color: "white" }} />
              </>
            )}
          </StyledButton>
        </ButtonContainer>
      </StyledForm>
    </MainContainer>
  );
};

SNOWForm.propTypes = {
  handleNext: PropTypes.func,
  currentPlugin: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  submitConfig: PropTypes.func.isRequired,
  isSnackbarOpen: PropTypes.bool.isRequired,
  snackbarMessage: PropTypes.string.isRequired
};

export default SNOWForm;
