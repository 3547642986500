import { FontIcon } from "material-ui";
import React from "react";

export default function RundeckDepricationMessage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "16px",
        width: "545px",
        background: "#FFF8EA",
        border: "0.5px solid #F9A901",
        boxSizing: "border-box",
        borderRadius: "4px"
      }}
    >
      <FontIcon
        className="fa fa-exclamation-circle"
        style={{
          fontSize: "1em",
          color: "#F9A901"
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "0px",
          width: "481px",
          flex: "none",
          order: "1",
          flexGrow: "1",
          margin: "0px 8px",
          fontFamily: "Open Sans",
          fontSize: "13px",
          lineHeight: "120%"
        }}
      >
        <div style={{ fontWeight: "700", marginBottom: "6px" }}>
          Rundeck is now deprecated.
        </div>
        <div style={{ fontWeight: "400" }}>
          Rundeck is deprecated and will not be available in the next release.
          Please migrate all automation to Workflow Manager.
        </div>
      </div>
    </div>
  );
}
