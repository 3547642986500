import React from "react";
import PropTypes from "prop-types";
import {
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  NavButtonContainers
} from "./styles";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";

const CancelConfirmButtons = props => {
  const {
    handleBack,
    handleNext,
    handleCancel,
    cancelBtnText,
    nextBtnText,
    backBtnText,
    isNextDisabled,
    ...rest
  } = props;
  return (
    <ButtonContainer {...rest}>
      {handleCancel && (
        <CancelButton onClick={handleCancel}>{cancelBtnText}</CancelButton>
      )}
      <NavButtonContainers>
        {handleBack && (
          <CancelButton onClick={handleBack}>
            <ChevronLeft />
            {backBtnText}
          </CancelButton>
        )}
        <ConfirmButton
          color="primary"
          variant="contained"
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          {nextBtnText}
          <ChevronRight />
        </ConfirmButton>
      </NavButtonContainers>
    </ButtonContainer>
  );
};

CancelConfirmButtons.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func,
  handleCancel: PropTypes.func,
  backBtnText: PropTypes.string,
  nextBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  isNextDisabled: PropTypes.bool
};

CancelConfirmButtons.defaultProps = {
  cancelBtnText: "Cancel",
  backBtnText: "Previous step",
  nextBtnText: "Next step",
  isNextDisabled: false
};

export default CancelConfirmButtons;
