import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

export const UseCaseContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 200px;

  @media screen and (max-width: 992px) {
    padding: 0px 75px;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    padding: 0px 150px;
  }

  @media screen and (max-height: 768px) {
    padding: 0px 75px;
  }
`;

export const UseCaseFilterContainer = styled(Grid)`
  box-shadow: 0px 4px 10px 0px rgba(176, 176, 176, 0.19);
`;

export const UseCaseSelectionContainer = styled(Grid)`
  box-shadow: 0px 4px 10px 0px rgba(176, 176, 176, 0.19);
`;

export const SecondaryButton = styled(Button)`
  color: #378bee !important;
  border-color: #378bee !important;
`;

export const PrimaryButton = styled(Button)`
  color: #fff !important;
  background-color: #378bee !important;
`;

export const ButtonContainer = styled.div`
  padding: 20px !important;
`;

export const GIFImage = styled.img`
  width: 100%;
  margin-top: 10px;
`;
