import React from "react";
import PropTypes from "prop-types";
import { ArcherElement } from "react-archer";
import { Typography } from "@material-ui/core";
import { blue700 } from "material-ui/styles/colors";
import * as Styled from "./InputNodeStyle";

export default function AQETabWorkflowInputNode({
  nextNodeId,
  param,
  currentParam,
  type
}) {
  const currentParamValue = currentParam[param.id];
  return (
    <ArcherElement
      id={`${type}${param.id}`}
      style={{ marginRight: 40 }}
      relations={
        type === "input"
          ? [
              {
                targetId: nextNodeId,
                targetAnchor: "top",
                sourceAnchor: "bottom",
                style: {
                  strokeWidth: 2,
                  arrowLength: 2,
                  strokeColor: currentParamValue ? blue700 : "#d1d5da"
                }
              }
            ]
          : void 0
      }
    >
      <Styled.NodeCardContainer param={currentParamValue} type={type}>
        <Typography variant="caption" noWrap={type === "input" ? true : false}>
          {param.displayName}
        </Typography>

        <Typography variant="body2">
          {currentParamValue ? currentParamValue : "Empty"}
        </Typography>
      </Styled.NodeCardContainer>
    </ArcherElement>
  );
}

AQETabWorkflowInputNode.propTypes = {
  param: PropTypes.object.isRequired,
  nextNodeId: PropTypes.string,
  currentParam: PropTypes.object,
  type: PropTypes.string.isRequired
};
