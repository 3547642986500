import React, { useState } from "react";
import {
  ButtonContainer,
  ContentContainer,
  ListContainer,
  PrerequisiteContainer,
  StyledText
} from "./styles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const Prerequisite = () => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div>
      {isVisible && (
        <PrerequisiteContainer>
          <ContentContainer>
            <StyledText variant="body1" style={{ marginBottom: 5 }}>
              You can now launch automation for your tickets and tasks in 5
              simple steps through our cloud based automation service.
            </StyledText>
            <StyledText variant="body1">
              To get started, you will need
            </StyledText>
            <ListContainer>
              <li>
                <StyledText variant="body1">
                  ITSM URL and access details
                </StyledText>
              </li>
              <li>
                <StyledText variant="body1">
                  Active group directory names and privileges
                </StyledText>
              </li>
              <li>
                <StyledText variant="body1">
                  Identify the use cases you would like to automate
                </StyledText>
              </li>
            </ListContainer>
            <StyledText variant="body1" style={{ marginTop: 5 }}>
              You will be connecting to production systems to implement the
              automation. Please ensure you have the required approvals in place
              from your client and account leadership to connect to the ITSM or
              tooling system and execute scripts and bots to automate.
            </StyledText>
          </ContentContainer>
          <ButtonContainer>
            <Button
              style={{
                color: "#378BEE",
                fontSize: "11px"
              }}
              onClick={() => setIsVisible(false)}
            >
              Dismiss
            </Button>
          </ButtonContainer>
        </PrerequisiteContainer>
      )}
    </div>
  );
};

export default Prerequisite;
