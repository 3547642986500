import { connect } from "react-redux";
import TicketOptions from "./Options";
import { toggleCustomizationTable } from "reducers/tickets";
import { handleBulkActionDrawer } from "actions/bulkActions";

const mapStateToProps = state => ({
  isCustomizationTableOpen: state.tickets.isCustomizationTableOpen,
  selectedTickets: state.bulkActions.bulkActionPayload.selectedTickets,
  bulkActionDrawerOpen: state.bulkActions.bulkActionDrawerOpen
});

const mapDispatchToProps = dispatch => ({
  toggleCustomizationTable: () => dispatch(toggleCustomizationTable()),
  handleBulkActionDrawer: drawerState =>
    handleBulkActionDrawer(drawerState, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketOptions);
