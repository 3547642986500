import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import TicketTableHeaderFilterContainer from "./FilterContainer";

const TicketTableHeaderState = props => {
  const {
    filter,
    displayName,
    handleMenuSelect,
    activeTemplates,
    selectedTicketType
  } = props;

  return (
    <>
      {activeTemplates && (
        <TicketTableHeaderFilterContainer title={displayName}>
          {filter === "state" && (
            <>
              <MenuItem onClick={() => handleMenuSelect(filter, "")}>
                Show All
              </MenuItem>
              {activeTemplates[selectedTicketType] &&
                activeTemplates[selectedTicketType].states.map(item => {
                  return (
                    <MenuItem
                      key={item}
                      onClick={() => {
                        handleMenuSelect(filter, item);
                      }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
            </>
          )}
          {filter === "assignee" && (
            <>
              <MenuItem onClick={() => handleMenuSelect(filter, "")}>
                Anyone
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuSelect(filter, localStorage["user"])}
              >
                Me
              </MenuItem>
            </>
          )}
        </TicketTableHeaderFilterContainer>
      )}
    </>
  );
};

export default TicketTableHeaderState;
