import themeMainUI from "../../styles/themeMainUI";

const Styles = {
  NavSidebar: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#0C2E33",
    justifyContent: "space-between",
    padding: 0
  },
  NavSidebarItem: {
    background: "transparent",
    display: "flex",
    flexGrow: "1",
    justifyContent: "center"
  },
  NavSidebarItemSelected: {
    background: "#0A83C4",
    display: "flex",
    flexGrow: "1",
    justifyContent: "center"
  },
  NavSidebarBadge: {
    top: 10,
    right: 0,
    zIndex: 5,
    display: "flex",
    backgroundColor: "#0A83C4"
  },
  NavSidebarBadgeSelected: {
    top: 10,
    right: 0,
    zIndex: 5,
    display: "flex",
    backgroundColor: "#F17578"
  },
  NavSidebarBadgeHidden: {
    top: 10,
    right: 0,
    zIndex: 5,
    display: "none",
    backgroundColor: "#0A83C4"
  },
  NavSidebarBadgeContainer: {
    padding: "5px",
    paddingBottom: "15px",
    paddingLeft: "0px"
  },
  NavSidebarBadgeContainerEmpty: {
    padding: "5px",
    paddingBottom: "15px",
    paddingLeft: "7px"
  },
  NavSidebarSearch: {
    padding: "5px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  NavSidebarMenu: {
    Back: {
      color: themeMainUI.palette.accent3Color
    }
  }
};

export default Styles;
