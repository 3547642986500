import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const TicketTemplateViewerTemplateFieldsCheckbox = React.memo(props => {
  const { toggleCheckBox, isChecked, item, selectedTicketType, index } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          data-cy={`checkbox-${index}`}
          checked={isChecked}
          color="primary"
          style={{ height: "23px" }}
          onChange={event => {
            toggleCheckBox(selectedTicketType, item, event.target.checked);
          }}
        />
      }
      label={item.displayName}
    />
  );
});

export default TicketTemplateViewerTemplateFieldsCheckbox;
