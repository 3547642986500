import { SET_STATE } from "../actions/sidebar";

function sidebar(state = [], action) {
  switch (action.type) {
    case SET_STATE:
      return action.state;
    default:
      return state;
  }
}

export default sidebar;
