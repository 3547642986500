import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import StepButtons from "../../../components/StepButtons";
import { EmptyConfig } from "./styles";
import { CenterContainer, FormContainer, MainContainer } from "../styles";
import ProviderList from "./ProviderList";
import { providers } from "../index";

const ProviderForm = props => {
  const { provider, children, curProvider } = props;
  return (
    <>
      {provider === curProvider &&
        (children ? (
          children
        ) : (
          <EmptyConfig>Provider is not supported</EmptyConfig>
        ))}
    </>
  );
};

const ProviderConfig = props => {
  const { selectedProviders } = props;
  const [curProvider, setCurProvider] = useState(undefined);

  const curProviderList = useMemo(
    () =>
      providers.filter(provider => selectedProviders.includes(provider.value)),
    [selectedProviders, providers]
  );

  useEffect(() => {
    if (curProviderList.length > 0) {
      setCurProvider(curProviderList[0].value);
    }
  }, [curProviderList]);

  return (
    <MainContainer>
      <CenterContainer>2. Configure your provider(s)</CenterContainer>
      <FormContainer>
        <ProviderList
          selectedProviders={curProviderList}
          curProvider={curProvider}
          setCurProvider={setCurProvider}
        />
        {providers.map(provider => (
          <ProviderForm
            key={provider.label}
            provider={provider.value}
            curProvider={curProvider}
          >
            {provider.form}
          </ProviderForm>
        ))}
      </FormContainer>
    </MainContainer>
  );
};

ProviderConfig.propTypes = {
  selectedProviders: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ProviderConfig;
