import React from "react";
import _ from "lodash";
import { Row, Col } from "react-flexbox-grid/lib";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import Divider from "material-ui/Divider";
import Paper from "material-ui/Paper";
import MenuItem from "material-ui/MenuItem";
import PrimaryButton from "../templates/PrimaryButton";
import { IconMenu } from "material-ui";
import update from "immutability-helper";

import RulesStyle from "../rules/RulesStyles";
import SecondaryTextField from "../templates/SecondaryTextField";
import SecondaryDropDownMenu from "../templates/SecondaryDropDownMenu";
import ATRFormField from "../templates/ATRFormField";
import RundeckMapping from "./RundeckMapping";
import TriggerConditions from "../automation/TriggerConditions";
import JobInputMapping from "./JobInputMapping";

export default class MappingDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.props.getAllRundecks();
    this.props.getActiveTemplates();
    this.props.getMlConfig();

    this.state = {
      disabled: false,
      mapping: {},
      warningMessage: "",
      activeTemplate: {},
      validation: {
        ticketType: false,
        mappingName: false,
        targetServiceName: false,
        targetProjectName: false,
        targetJobName: false
        // triggerState: false
      }
    };
  }

  activeTemplateFields = [];

  //TODO: need refactor this part
  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.mappingSelected !== this.state.mapping &&
      this.setState({
        mapping: nextProps.mappingSelected,
        validation: {
          ticketType: false,
          mappingName: false,
          sourceServiceName: false,
          sourceJobName: false,
          targetServiceName: false,
          targetProjectName: false,
          targetJobName: false
          // resultState: false
        }
      });

    if (nextProps.mappingSelected.automatable === "false") {
      this.setState({
        disabled: true,
        warningMessage: `Current mapping is associated with a ticket type that does not
                have an active ticket template. Please activate/create a
                template for the ${nextProps.mappingSelected.ticketType} ticket
                type to enable polling and automation.`
      });
    } else {
      this.setState({
        disabled: false,
        warningMessage: ""
      });
    }

    if (nextProps.mappingSelected.ticketType) {
      const templates = {
        ...this.props.deletedTicketTemplate,
        ...nextProps.activeTemplates[nextProps.mappingSelected.ticketType]
      };

      this.setState({
        activeTemplate: templates
      });
    }
  }

  validateForm = mapping => {
    return (
      this._saveValidation(mapping) &&
      this.validateJobInputMapping(mapping.fieldMappingRules)
    );
  };

  _saveValidation = () => {
    let mapping = _.cloneDeep(this.state.mapping);
    for (let originKey in mapping) {
      !this.state.validation.hasOwnProperty(originKey) &&
        delete mapping[originKey];
    }
    let isComplete = true;
    for (let key in mapping) {
      if (mapping.hasOwnProperty(key)) {
        if (mapping[key]) {
          mapping[key] = false;
        } else {
          mapping[key] = true;
          isComplete = false;
        }
      }
    }
    // if (_.isEmpty(this.state.mapping.triggerConditions.triggerStates)) {
    //   mapping["triggerStates"] = true;
    //   isComplete = false;
    // } else mapping["triggerStates"] = false;
    this.setState({ validation: mapping });
    return isComplete;
  };

  validateJobInputMapping = fieldMappingRules => {
    // Validate that each field mapping rule has at least either a Ticket Field & Regex or Default Value
    // If a rule has a Ticket Field, it must also have Regex, even if a Default Value is present, and vice versa
    let valid = true;
    fieldMappingRules.forEach(rule => {
      if (
        rule.parameterId !== "ticketId" &&
        rule.parameterId !== "ticketNumber"
      ) {
        if (
          !rule.defaultValue &&
          !(rule.extractor.fieldName && rule.extractor.regex)
        ) {
          valid = false;
        }
        if (
          (rule.extractor.fieldName && !rule.extractor.regex) ||
          (!rule.extractor.fieldName && rule.extractor.regex)
        ) {
          valid = false;
        }
      }
    });
    return valid;
  };

  deleteEmptyFields = mappingSelected => {
    return update(mappingSelected, {
      triggerConditions: {
        fields: {
          $set: mappingSelected.triggerConditions.fields.filter(
            field => field.name
          )
        }
      }
    });
  };

  _handleClose() {
    this.props.closeMapping();
    this.props.clearMappingJobs();
    this.props.clearRundeckProjects();
  }

  _handleChange(key, value) {
    const mapping = update(this.state.mapping, {
      [key]: { $set: value }
    });

    if (key === "targetServiceName" && value === "rundeck")
      this.props.getAllRundecks();

    if (key === "ticketType") {
      mapping.triggerConditions.fields = [];
      mapping.triggerConditions.triggerStates = [];
      mapping.resultState = "";
      this.setState({ activeTemplate: this.props.activeTemplates[value] });
    }
    this.setState({ mapping });
  }

  //handleFields
  backToTop = () => {
    this.refs.node.scrollTop = 0;
  };

  goToBottom = () => {
    this.refs.node.scrollTop = this.refs.node.scrollHeight;
  };

  emptyField = {
    name: "",
    displayName: "",
    type: "string",
    value: "",
    regex: false
  };

  emptyPrediction = {
    predictedValue: ""
  };

  addField = index => {
    if (index === 0 && !this.state.mapping.ticketType) {
      this.setState(
        {
          validation: { ...this.state.validation, ticketType: true }
        },
        this.backToTop
      );
      return;
    }
    const mapping = update(this.state.mapping, {
      triggerConditions: {
        fields: { $splice: [[index + 1, 0, { ...this.emptyField }]] }
      }
    });

    this.setState({ mapping }, this.goToBottom);
  };

  deleteField = index => {
    const mapping = update(this.state.mapping, {
      triggerConditions: { fields: { $splice: [[index, 1]] } }
    });
    this.setState({ mapping });
  };

  handleFieldChange = (e, i) => {
    const {
      primaryFields = [],
      secondaryFields = []
    } = this.state.activeTemplate;

    const fields = [...primaryFields, ...secondaryFields];

    if (
      this.state.mapping.triggerConditions.fields[i].name !== e.target.value
    ) {
      const newFieldObj = fields.filter(
        field => field.name === e.target.value
      )[0];
      const newType = newFieldObj.type;
      const newOptions = newFieldObj.values;

      const mapping = update(this.state.mapping, {
        triggerConditions: {
          fields: {
            $splice: [
              [
                i,
                1,
                { name: e.target.value, value: "", regex: false, type: newType }
              ]
            ]
          }
        }
      });
      if (newType === "dropdown" || "dropdown-search") {
        mapping.triggerConditions.fields[i].options = newOptions;
        delete mapping.triggerConditions.fields[i].regex;
      }
      this.setState({ mapping });
    }
  };

  handleValueChange = (e, i) => {
    const mapping = update(this.state.mapping, {
      triggerConditions: {
        fields: {
          [i]: { value: { $set: e.target ? e.target.value : e.value } }
        }
      }
    });
    this.setState({ mapping });
  };

  handleTriggerStates = e => {
    const mapping = update(this.state.mapping, {
      triggerConditions: { triggerStates: { $set: e.target.value } }
    });
    this.setState({
      mapping,
      validation: { ...this.state.validation, triggerStates: false }
    });
  };

  handleMLConfidenceChange = e => {
    const mapping = update(this.state.mapping, {
      triggerConditions: {
        mlFields: { ["confidenceLevel"]: { $set: Math.round(e) } }
      }
    });
    this.setState({ mapping });
  };

  handleRegex = i => {
    const preRegex = this.state.mapping.triggerConditions.fields[i].regex;
    const mapping = update(this.state.mapping, {
      triggerConditions: { fields: { [i]: { regex: { $set: !preRegex } } } }
    });
    this.setState({ mapping });
  };

  addNewFieldMappingRule = (field, defaultValue = "") => {
    this.setState(({ mapping }) => ({
      mapping: {
        ...mapping,
        fieldMappingRules: mapping.fieldMappingRules.concat({
          parameterId: field,
          priority: 0,
          defaultValue: defaultValue,
          extractor: {
            fieldName: "",
            regex: ""
          }
        })
      }
    }));
  };

  removeFieldMappingRule = jobInputName => {
    this.setState(({ mapping }) => ({
      mapping: {
        ...mapping,
        fieldMappingRules: mapping.fieldMappingRules.filter(
          rule => rule.parameterId !== jobInputName
        )
      }
    }));
  };

  handleJobInputMappingFieldChange = (jobInputName, value) => {
    this.setState(({ mapping }) => ({
      mapping: {
        ...mapping,
        fieldMappingRules: mapping.fieldMappingRules.map(rule =>
          rule.parameterId === jobInputName
            ? {
                ...rule,
                extractor: {
                  ...rule.extractor,
                  fieldName: value
                }
              }
            : rule
        )
      }
    }));
  };

  handleJobInputMappingRegexChange = (jobInputName, value) => {
    this.setState(({ mapping }) => ({
      mapping: {
        ...mapping,
        fieldMappingRules: mapping.fieldMappingRules.map(rule =>
          rule.parameterId === jobInputName
            ? {
                ...rule,
                extractor: {
                  ...rule.extractor,
                  regex: value
                }
              }
            : rule
        )
      }
    }));
  };

  handleJobInputMappingDefaultValueChange = (jobInputName, value) => {
    this.setState(({ mapping }) => ({
      mapping: {
        ...mapping,
        fieldMappingRules: mapping.fieldMappingRules.map(rule =>
          rule.parameterId === jobInputName
            ? {
                ...rule,
                defaultValue: value !== "" ? value : null
              }
            : rule
        )
      }
    }));
  };

  handleMlConditionValueChange = (predictedField, e) => {
    const mapping = update(this.state.mapping, {
      triggerConditions: {
        mlFields: {
          predictions: {
            [predictedField]: {
              $set: { predictedValue: e.target ? e.target.value : e.value }
            }
          }
        }
      }
    });
    this.setState({ mapping });
  };
  handleMlConditionFieldChange = (predictedField, e) => {
    const currentValue = this.state.mapping.triggerConditions.mlFields
      .predictions[predictedField];
    const newFieldName = e.target ? e.target.value : e.value;
    if (newFieldName !== predictedField) {
      const predictions = this.state.mapping.triggerConditions.mlFields
        .predictions;
      predictions[newFieldName] = currentValue;
      delete predictions[predictedField];

      const mapping = update(this.state.mapping, {
        triggerConditions: {
          mlFields: {
            predictions: { $set: predictions }
          }
        }
      });
      this.setState({ mapping });
    }
  };

  deleteMlCondition = predictedField => {
    const mapping = update(this.state.mapping, {
      triggerConditions: {
        mlFields: { predictions: { $unset: [predictedField] } }
      }
    });
    this.setState({ mapping });
  };

  addMlCondition = index => {
    if (index === 0 && !this.state.mapping.ticketType) {
      this.setState(
        {
          validation: { ...this.state.validation, ticketType: true }
        },
        this.backToTop
      );
      return;
    }
    const mapping = update(this.state.mapping, {
      triggerConditions: {
        mlFields: {
          predictions: { ["unknown"]: { $set: { predictedValue: "" } } }
        }
      }
    });
    this.setState({ mapping }, this.goToBottom);
  };

  getAllTicketFields = ticketTemplate => {
    // Fetch primary & secondary & add legacy ML fields
    let fields = [
      { displayName: "KBID", name: "u_knowledge" },
      { displayName: "Ticket Management", name: "ticket_management" },
      ...ticketTemplate.primaryFields
    ];
    if (ticketTemplate.secondaryFields)
      fields = [...fields, ...ticketTemplate.secondaryFields];
    return fields;
  };

  render() {
    //compose 'fields' object based on activeTemplate: some new type of activeTemplate only has primaryFields
    const { disabled } = this.state;
    let predictableFields = [];
    let fields = [];
    if (this.state.activeTemplate && !_.isEmpty(this.state.activeTemplate)) {
      fields = this.getAllTicketFields(this.state.activeTemplate);
      predictableFields = this.props.availablePredictions
        .filter(
          prediction =>
            prediction.ticketType === this.state.activeTemplate.ticketType
        )
        .map(
          prediction =>
            fields.find(field => field.name === prediction.field) || {
              displayName: prediction.field,
              name: prediction.field
            }
        )
        .map(field => {
          return {
            displayName: field.displayName,
            name: field.name
          };
        });
    }

    const ticketTypes = this.props.activeTemplates
      ? Object.keys(this.props.activeTemplates)
          .filter(
            ticketType =>
              this.props.activeTemplates[ticketType].ticketSource.canAutomate
          )
          .map(ticketType => ({
            type: ticketType,
            displayName: this.props.activeTemplates[ticketType].name
          }))
      : [];
    return (
      <div style={RulesStyle.mainWrapper}>
        <div style={RulesStyle.contentWrapper} ref="node">
          <div style={RulesStyle.contentHeader}>
            <Row style={{ display: "flex", alignItems: "flex-start" }}>
              <Col xs={10}>
                <h1 style={RulesStyle.ticketNumber}>
                  {this.props.mappingSelected.mappingName ||
                    "Create New Mapping"}
                </h1>
              </Col>
              <Col xs={2} style={{ display: "flex", justifyContent: "center" }}>
                {!this.props.ruleSelected.editable ? (
                  <IconMenu
                    iconButtonElement={
                      <IconButton
                        iconStyle={{
                          color: RulesStyle.iconStyle.color,
                          fontSize: 20
                        }}
                      >
                        <FontIcon className="fa fa-ellipsis-v" />
                      </IconButton>
                    }
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    targetOrigin={{ horizontal: "left", vertical: "top" }}
                  >
                    {/* TODO: implement delete mappings */}
                    <MenuItem
                      primaryText="Delete"
                      onClick={() =>
                        this.props.deleteMapping(
                          this.props.mappingSelected,
                          this.props.currentPage
                        )
                      }
                    />
                  </IconMenu>
                ) : (
                  ""
                )}
                <IconButton
                  iconStyle={{
                    color: RulesStyle.iconStyle.color,
                    fontSize: 20
                  }}
                  onClick={() => this._handleClose()}
                >
                  <NavigationClose />
                </IconButton>
              </Col>
            </Row>

            <span style={{ color: "red", fontSize: "1em" }}>
              {this.state.warningMessage && this.state.warningMessage}
            </span>

            <ATRFormField formLabel="Ticket Type">
              <SecondaryDropDownMenu
                id="ticketType"
                disabled={disabled}
                data-testid="selectedDropDownMenu1"
                value={this.state.mapping["ticketType"]}
                errorText={
                  this.state.validation["ticketType"]
                    ? "This field is required."
                    : ""
                }
                onChange={(e, k, v) => this._handleChange("ticketType", v)}
                autoWidth={true}
              >
                {ticketTypes.map(ticket => {
                  return (
                    !_.isUndefined(this.props.activeTemplates) &&
                    !_.isUndefined(this.props.activeTemplates[ticket.type]) && (
                      <MenuItem
                        value={ticket.type}
                        key={ticket.type}
                        primaryText={ticket.displayName}
                      />
                    )
                  );
                })}
              </SecondaryDropDownMenu>
            </ATRFormField>
            <div style={{ marginTop: -10 }}>
              <ATRFormField formLabel="Mapping Name">
                <SecondaryTextField
                  id="mappingName"
                  fullWidth={true}
                  errorText={
                    this.state.validation["mappingName"]
                      ? "This field is required."
                      : this.props.mappingSelected.hasOwnProperty("error") &&
                        this.props.mappingSelected.error.fieldName ===
                          "mappingName"
                      ? this.props.mappingSelected.error.errorMessage
                      : ""
                  }
                  disabled={disabled}
                  data-testid="selectedTextField"
                  value={this.state.mapping["mappingName"]}
                  onChange={(e, newValue) => {
                    this._handleChange("mappingName", newValue);
                  }}
                />
              </ATRFormField>
            </div>
            <div style={{ margin: "30px 0 15px 0" }}>
              <p style={{ margin: 0 }}>
                <FontIcon className="fa fa-play" /> &nbsp;Automation Job
              </p>
              <p
                style={{ margin: 0, marginTop: 5, fontSize: 13, color: "grey" }}
              >
                The job triggered based on the combination above.
              </p>
            </div>

            <RundeckMapping
              type={"mapping"}
              disabled={disabled}
              validation={this.state.validation}
              mappingRundecks={this.props.mappingRundecks}
              rundeckProjects={this.props.rundeckProjects}
              isFetchingRundeckProjects={this.props.isFetchingRundeckProjects}
              mappingJobs={this.props.mappingJobs}
              isFetchingMappingJobs={this.props.isFetchingMappingJobs}
              mapping={this.state.mapping}
              handleRundeckDropdownChange={this._handleRundeckDropdownChange}
              getRundeckJobs={this.props.getRundeckJobs}
              getRundeckProjects={this.props.getRundeckProjects}
              updateLocalMapping={this.props.updateLocalMapping}
              updateMappingFound={this.props.updateMappingFound}
              clearMappingJobs={this.props.clearMappingJobs}
              clearRundeckProjects={this.props.clearRundeckProjects}
            />

            <Col xs={12} style={{ marginTop: 30, paddingLeft: 0 }}>
              {!_.isEmpty(this.state.mapping) && (
                <JobInputMapping
                  fields={fields}
                  disabled={disabled}
                  handleJobInputMappingFieldChange={
                    this.handleJobInputMappingFieldChange
                  }
                  handleJobInputMappingRegexChange={
                    this.handleJobInputMappingRegexChange
                  }
                  handleJobInputMappingDefaultValueChange={
                    this.handleJobInputMappingDefaultValueChange
                  }
                  mapping={this.state.mapping}
                  targetJob={this.props.jobSelected}
                  addNewFieldMappingRule={this.addNewFieldMappingRule}
                  removeFieldMappingRule={this.removeFieldMappingRule}
                />
              )}
            </Col>

            <div style={{ margin: "30px 0 15px 0" }}>
              <p style={{ margin: 0 }}>
                <FontIcon className="fa fa-ticket" /> &nbsp;Resulting State
              </p>
              <p
                style={{ margin: 0, marginTop: 5, fontSize: 13, color: "grey" }}
              >
                The state the ticket should be when the job is successful
              </p>
            </div>

            <ATRFormField formLabel="Ticket State">
              <SecondaryDropDownMenu
                id="resultState"
                value={this.state.mapping["resultState"]}
                disabled={disabled}
                data-testid="selectedDropDownMenu2"
                onChange={(e, k, v) => this._handleChange("resultState", v)}
              >
                <MenuItem value="" primaryText="None" />
                <Divider />
                {!_.isEmpty(this.state.activeTemplate) &&
                  this.state.activeTemplate.states.map((item, i) => {
                    return <MenuItem key={i} value={item} primaryText={item} />;
                  })}
              </SecondaryDropDownMenu>
            </ATRFormField>

            <Col xs={12} style={{ marginTop: 30, paddingLeft: 0 }}>
              {!_.isEmpty(this.state.mapping) && (
                <TriggerConditions
                  disabled={disabled}
                  validation={this.state.validation.triggerStates}
                  ticketStates={this.state.activeTemplate.states}
                  fields={fields}
                  predictableFields={predictableFields}
                  mlFields={this.state.mapping.triggerConditions.mlFields}
                  triggerConditions={this.state.mapping.triggerConditions}
                  currentFields={this.state.mapping.triggerConditions.fields}
                  addField={this.addField}
                  deleteField={this.deleteField}
                  handleFieldChange={this.handleFieldChange}
                  handleValueChange={this.handleValueChange}
                  handleTriggerStates={this.handleTriggerStates}
                  handleMLConfidenceChange={this.handleMLConfidenceChange}
                  handleRegex={this.handleRegex}
                  addMlCondition={this.addMlCondition}
                  deleteMlCondition={this.deleteMlCondition}
                  handleMlConditionFieldChange={
                    this.handleMlConditionFieldChange
                  }
                  handleMlConditionValueChange={
                    this.handleMlConditionValueChange
                  }
                />
              )}
            </Col>
          </div>
        </div>

        <Divider />

        <Paper style={RulesStyle.contentActionsContainer}>
          <div style={RulesStyle.contentActions}>
            <PrimaryButton
              disabled={disabled}
              data-testid="disabledSaveButton"
              onClick={() => {
                //validate first
                if (this.validateForm(this.state.mapping)) {
                  //delete empty fields
                  const mapping = this.deleteEmptyFields(this.state.mapping);
                  if (this.state.mapping.id === "0") {
                    // this.props.updateLocalMapping(localMapping);
                    this.props.createMapping(mapping, this.props.currentPage);
                    this.state.mapping.targetServiceName &&
                      this.props.getRundeckProjects(
                        this.state.mapping.targetServiceName
                      );
                    this.state.mapping.targetProjectName &&
                      this.props.getRundeckJobs(
                        this.state.mapping.targetServiceName,
                        this.state.mapping.targetProjectName
                      );
                  } else {
                    // this.props.updateLocalMapping(localMapping);
                    this.props.updateMapping(mapping, this.props.currentPage);
                    this.state.mapping.targetServiceName &&
                      this.props.getRundeckProjects(
                        this.state.mapping.targetServiceName
                      );
                    this.state.mappingtargetProjectName &&
                      this.props.getRundeckJobs(
                        this.state.mapping.targetServiceName,
                        this.state.mapping.targetProjectName
                      );
                  }
                }
              }}
              label={
                <strong>
                  <FontIcon
                    className="fa fa-save"
                    style={{ fontSize: 14, color: "white" }}
                  />
                  &nbsp;Save
                </strong>
              }
            />
          </div>
        </Paper>
      </div>
    );
  }
}
