import React from "react";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilled from "@material-ui/icons/PauseCircleFilled";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Error from "@material-ui/icons/Error";
import Button from "@material-ui/core/Button";
import Warning from "@material-ui/icons/Warning";

export const statusText = {
  display: "flex",
  marginLeft: "-30px"
};

export const textDetails = {
  margin: "4px 0 20px -30px",
  fontFamily: "monospace"
};

export const WorkflowContainer = styled.div`
  height: 100%;
  width: 93%;
  border-radius: 10px;
  border: 1px solid #d1d5da;
  padding: 20px;
`;

export const Row = styled.div`
  display: flex;
  ${({ type }) =>
    type === "input" ? `margin-bottom: 40px` : `margin-top: 40px`}
`;

export const detailContainer = {
  margin: "15px 0"
};

export const NodeContainerDiv = styled.div`
  height: 100%;
  width: 100%;
  min-height: 40px;
  justify-content: center;
`;

export const Node = styled(Paper)`
  height: 100%;
  width: 100%;
  min-height: 40px;
  place-items: center;
  border-radius: 3px;
  ${props => props.color && `border-left: 6px solid ${props.color};`}
  ${props => !props.color && `color: lightgrey; border: 1px solid lightgrey;`}
  display: flex;
  position:
    props.color &&

    props.color &&

  height: 95%;
  z-index: 0;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const AutomateButton = styled(Button)`
  border-radius: 5px;
  border: 1px solid lightgrey;
`;

export const NodeLabel = styled.div`
  width: 100%;
  font-size: 12px;
  padding: 0 1px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTooltip = styled(({ ...other }) => (
  <Tooltip
    {...other}
    classes={{
      tooltip: "tooltip"
    }}
  />
))`
  && {
    & .tooltip {
      background: red !important;
    }
  }
`;

export const DoneIcon = styled(CheckCircle)`
  && {
    width: 22px;
    height: 22px;
    ${props =>
      !props.codeKey &&
      `
      position: absolute;
      width: 20px;
      height: 20px;
      right: -12px;
      top: -10px;
    `}
    background: white;
    border-radius: 50%;
    font-size: 10px;
    opacity: 1;
    color: #34a95f;
  }
`;

export const ErrorIcon = styled(Error)`
  && {
    width: 22px;
    height: 22px;
    background: white;
    border-radius: 50%;
    font-size: 10px;
    opacity: 1;
    color: red;
    align-self: center;
  }
`;

export const WarningIcon = styled(Warning)`
  && {
    width: 22px;
    height: 22px;
    background: white;
    border-radius: 50%;
    font-size: 10px;
    opacity: 1;
    color: #ffbe00;
    align-self: center;
  }
`;

export const CurrentIcon = styled(PlayCircleFilled)`
  && {
    width: 22px;
    height: 22px;
    ${props =>
      !props.codeKey &&
      `
      position: absolute;
      width: 20px;
      height: 20px;
      right: -12px;
      top: -10px;
    `}
    background: white;
    border-radius: 50%;
    font-size: 10px;
    opacity: 1;
    color: #5567e7;
  }
`;

export const PausedIcon = styled(PauseCircleFilled)`
  && {
    width: 22px;
    height: 22px;
    ${props =>
      !props.codeKey &&
      `
      position: absolute;
      width: 20px;
      height: 20px;
      right: -12px;
      top: -10px;
    `}
    background: white;
    border-radius: 50%;
    font-size: 10px;
    opacity: 1;
    color: grey;
  }
`;

export const AQETabContainer = {
  width: "auto",
  padding: "5px",
  marginTop: "10px"
};

export const WorkflowTitle = styled.div`
  text-align: center;
  font-size: 20px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GraphGridDiv = styled.div`
  width: 16%;
  height: 10%;
  min-height: 40px;
  background-color: transparent;
  align-items: center;
  // border: 1px solid grey;
`;

export const GraphGridContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 2%;
  border: 1px solid lightsteelblue;
  padding: 5px 0;
`;

export const ColorKeyRow = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ColorKeyContainer = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
`;

export const ColorPatch = styled.div`
  height: 15px;
  width: 15px;
  background-color: ${props => props.color};
`;

export const StatusKeyRow = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
`;

export const StatusKeyContainer = styled.div`
  display: flex;
  margin: 0 auto;
`;

export const StatusLabel = styled.div`
  font-size: 10px;
  padding: 0 8px;
  padding-top: 4px;
`;
