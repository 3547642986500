import PropTypes from "prop-types";

const BulkActionTicket = PropTypes.shape({
  id: PropTypes.string,
  number: PropTypes.string
});

const BulkActionRequest = PropTypes.shape({
  action: PropTypes.string,
  tickets: PropTypes.arrayOf(BulkActionTicket)
});

const BulkActionModel = PropTypes.shape({
  taskId: PropTypes.string,
  status: PropTypes.string,
  request: BulkActionRequest,
  eta: PropTypes.number,
  percent: PropTypes.number,
  total: PropTypes.number,
  processedCount: PropTypes.number,
  created: PropTypes.string, // ISO date
  lastUpdated: PropTypes.string, // ISO date
  finished: PropTypes.string, // ISO date
  done: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  failedTicketIds: PropTypes.arrayOf(PropTypes.string)
});

export default BulkActionModel;
