import { createStyles, WithStyles } from "@material-ui/core/styles";

export const homePageStyles = createStyles({
  rootContainer: {
    padding: "27px 30px 30px 30px",
    overflowX: "hidden",
    overflowY: "auto",
    minWidth: "40vh",
    maxHeight: "90vh"
  },
  testingPlayground: {
    width: "100%",
    height: "auto",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "30px",
    color: "#000000",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "16px 0px"
  },
  testingIntro: {
    width: "100%",
    height: "auto",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "17px",
    color: "rgba(0, 0, 0, 0.54)",
    flex: "none",
    order: 1,
    alignSelf: "stretch",
    flexGrow: 0,
    margin: "0px 0px 24px 0px"
  },
  ticketTypeContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "20px",
    width: "100%",
    height: "auto",
    background: "#ffffff",
    boxShadow:
      "-1px -1px 4px rgba(0, 0, 0, 0.1), 1px 5px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    order: 2
  },
  createNewTestContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px",
    width: "100%",
    height: "auto",
    background: "#FFFFFF",
    boxShadow:
      "-1px -1px 4px rgba(0, 0, 0, 0.1), 1px 5px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    flex: "none",
    order: 3,
    alignSelf: "stretch",
    margin: "24px 0px"
  },
  textStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "15.5px",
    lineGeight: "17px",
    color: "#000000",
    flex: "none",
    flexGrow: 0
  },
  ticketTypeSelect: {
    width: "100%",
    height: "auto",
    textAlign: "left"
  },
  ticketStatusSelect: {
    width: "100%",
    height: "auto",
    bottom: "10%",
    textAlign: "left",
    marginBottom: "30px"
  },
  textField: {
    width: "280%",
    bottom: "30%",
    flex: "none",
    alignSelf: "stretch",
    flexGrow: 0,
    margin: "8px 0px"
  },
  fieldDropDown: {
    width: "100%",
    height: "auto",
    textAlign: "left",
    marginBottom: "25px"
  },
  createNewTestTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "color: #060708,"
  },
  createNewTestText: {
    position: "static",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "17px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  saveAsPreset: {
    height: "16px",
    left: "6px",
    right: "8px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#0277BD",
    flex: "none",
    flexGrow: 0,
    margin: "0px auto 0px 0px",
    background: "#FFFFFF",
    borderRadius: "4px",
    border: "0.5px solid #0277BD",
    alignContent: "flex-start"
  },
  clear: {
    height: "16px",
    left: "6px",
    right: "8px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    color: "#F05359",
    flex: "none",
    flexGrow: 0,
    margin: "0px 10px",
    background: "#FFFFFF",
    borderRadius: "4px",
    border: "0.5px solid #F05359",
    alignContent: "flex-end"
  },
  runTest: {
    height: "16px",
    left: "6px",
    right: "8px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FFFFFF",
    flex: "none",
    flexGrow: 0,
    margin: "0px 10px",
    background: "#0277BD",
    borderRadius: "4px",
    border: "0.5px solid #0277BD",
    alignContent: "flex-end",
    padding: "10px 14px 10px 13px"
  }
});

export const automationConfigsStyles = createStyles({
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "17.5px 16px auto 16px",
    position: "static",
    width: "auto",
    height: "auto",
    background: "#F3F3F3",
    borderRadius: "8px",
    flex: "none",
    alignSelf: "stretch",
    margin: "0px 20px",
    maxHeight: "90vh",
    overflowX: "hidden",
    overflowY: "auto"
  },
  automationConfigsContainer: {
    marginLeft: "20px",
    marginTop: "20px"
  },
  automationTitle: {
    marginRight: "30px",
    fontWeight: "bold",
    fontSize: "1.2em"
  },
  executionTime: {
    marginRight: "30px",
    marginTop: "4px"
  },
  warningSign: {
    color: "#F9A901",
    marginTop: "5px",
    marginRight: "15px"
  },
  warningMessage: {
    color: "#F9A901",
    fontSize: "0.9em",
    marginTop: "5px",
    marginRight: "15px"
  },
  manageMappingButton: {
    fontSize: "0.9em",
    marginTop: "5px",
    marginLeft: "auto",
    marginRight: "50px",
    color: "#0085FF",
    cursor: "pointer"
  },
  faExternalLinkAlt: {
    marginLeft: "12px",
    fontSize: "0.7em"
  },
  previewAutomationConfigs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px",
    position: "static",
    width: "auto",
    height: "auto",
    background: "#FFFFFF",
    boxShadow:
      "-1px -1px 4px rgba(0, 0, 0, 0.1), 1px 5px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    flex: "none",
    alignSelf: "stretch",
    margin: "16px 20px"
  },
  divider: {
    border: "1px solid #C0C0C0",
    width: "90vw"
  },
  automationConfigTitle: {
    textDecorationLine: "underline",
    color: "#0277BD",
    fontSize: "1.1em"
  },
  jobWorkflow: {
    fontSize: "1.1em",
    marginLeft: "5px",
    fontWeight: "bolder"
  },
  jobWorkflowContent: {
    fontSize: "0.8em",
    marginLeft: "25px"
  },
  tableTitle: {
    marginLeft: "20px",
    marginBottom: "2px",
    fontWeight: "bold"
  },
  tableColumn: {
    fontStyle: "italic",
    borderBottom: "none",
    fontSize: "1em"
  },
  tableBorder: {
    borderBottom: "none"
  }
});

export type ICSSProps<T> = WithStyles<keyof T>;
