import React from "react";
import moment from "moment";
import { Grid, Row, Col } from "react-flexbox-grid/lib";

import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import PrimaryButton from "../../templates/PrimaryButton";
import MediaQuery from "react-responsive";
import DataSourceStyles from "./DataSourceStyles";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";

import { Drawer } from "material-ui";

import DatasourceDrawer from "../../drawers/DatasourceDrawer";
import GreenButton from "../../templates/GreenButton";

class DataSourceList extends React.Component {
  constructor(props) {
    super(props);

    this._handleRowSelection = this._handleRowSelection.bind(this);
    this._handleCloseDetails = this._handleCloseDetails.bind(this);
    this._willPurgeElasticSearch = this._willPurgeElasticSearch.bind(this);
    this._willCreateNewDataSource = this._willCreateNewDataSource.bind(this);
    this._didCreateNewDataSource = this._didCreateNewDataSource.bind(this);

    this.state = {
      dropdownValue: 1,
      csvFiles: [],
      message: {
        error: "",
        open: false
      },
      dataSource: {}
    };

    // this.props.retrieveDataSourceList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.dataSource !== nextProps.dataSource) {
      // console.log(nextProps.dataSource);
      this.setState({
        dataSource: nextProps.dataSource
      });
    }
  }

  _willPurgeElasticSearch() {
    // Closing the details, just in case it is open
    this._handleCloseDetails();

    this.props.purgeElasticSearch();
    this.props.retrieveDataSourceList();
  }

  _willCreateNewDataSource() {
    this._handleCloseDetails();

    this.props.openDetails({
      sourceName: "create",
      description: ""
    });
  }

  _didCreateNewDataSource() {
    // Create the DataSource
    this.props.createDataSource(this.state.dataSource);

    // Close the Details as the datasource is created
    this._handleCloseDetails();
  }

  _handleCloseDetails() {
    this.props.closeDetails();

    // Unselect the row
    this.setState({
      rowSelected: -1
    });
  }

  _handleRowSelection(rowIndex) {
    if (rowIndex === this.state.rowSelected) {
      this._handleCloseDetails();
    } else {
      this._handleCloseDetails();
      if (this.props.dataSources[rowIndex]) {
        this.props.openDetails(this.props.dataSources[rowIndex]);
      }

      this.setState({
        rowSelected: rowIndex
      });
    }
  }

  _handleTouchTap = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  _handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  _handleInputOnChange = obj => {
    let newDataSource = Object.assign({}, this.state.dataSource);
    newDataSource[obj.field] = obj.value;

    this.setState({
      dataSource: newDataSource
    });
  };

  _handleFileDrop = (acceptedFiles, rejectedFiles) => {
    this._handleInputOnChange({
      field: "files",
      value: acceptedFiles
    });
  };

  _replaceSelectedFile = () => {
    this.setState({
      csvFiles: []
    });
  };

  render() {
    const drawer = () => {
      return (
        <DatasourceDrawer
          handleCloseDetails={this._handleCloseDetails}
          handleInputOnChange={this._handleInputOnChange}
          handleFileDrop={this._handleFileDrop}
          replaceSelectedFile={this._replaceSelectedFile}
          didCreateNewDataSource={this._didCreateNewDataSource}
          dataSource={this.state.dataSource}
          files={this.state.dataSource.files}
          isCreateDataSource={this.props.isCreateDataSource}
        />
      );
    };

    const table = () => {
      return (
        <div>
          <Row
            style={{ alignItems: "center", paddingLeft: 10, paddingRight: 30 }}
          >
            <Col xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ margin: 0 }}>
                  <MediaQuery minWidth={769}>
                    <IconButton>
                      <FontIcon className="fa fa-bars" />
                    </IconButton>
                  </MediaQuery>
                  Data Source
                </h3>
                <div>
                  <PrimaryButton
                    label={<strong>Create New Data Source</strong>}
                    onClick={() => {
                      this._willCreateNewDataSource();
                    }}
                  />
                  &nbsp;
                  <GreenButton
                    label={<strong>Purge ElasticSearch and DataSource</strong>}
                    onClick={() => {
                      this._willPurgeElasticSearch();
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Table
            onCellClick={this._handleRowSelection}
            selectable={true}
            displayRowCheckbox={false}
          >
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={DataSourceStyles.TableHeader}>
                  Source Name
                </TableHeaderColumn>
                <TableHeaderColumn style={DataSourceStyles.TableHeader}>
                  Upload Date
                </TableHeaderColumn>
                <TableHeaderColumn style={DataSourceStyles.TableHeader}>
                  Description
                </TableHeaderColumn>
                <TableHeaderColumn style={DataSourceStyles.TableHeader}>
                  Number of tickets
                </TableHeaderColumn>
                <TableHeaderColumn style={DataSourceStyles.TableHeader}>
                  User Name
                </TableHeaderColumn>
                <TableHeaderColumn style={DataSourceStyles.TableHeader}>
                  File Name
                </TableHeaderColumn>
                <TableHeaderColumn style={DataSourceStyles.TableHeader}>
                  {" "}
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={false}
              deselectOnClickaway={false}
              showRowHover={true}
            >
              {this.props.dataSources && this.props.dataSources.length > 0 ? (
                this.props.dataSources.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableRowColumn style={{ fontSize: "12px" }}>
                        {item.sourceName}
                      </TableRowColumn>
                      <TableRowColumn style={{ fontSize: "12px" }}>
                        {moment
                          .unix(item.uploadDate / 1000)
                          .format("MMM Do YYYY, hh:mm:ss a")}
                      </TableRowColumn>
                      <TableRowColumn style={{ fontSize: "12px" }}>
                        {item.description}
                      </TableRowColumn>
                      <TableRowColumn style={{ fontSize: "12px" }}>
                        {item.numberOfTickets}
                      </TableRowColumn>
                      <TableRowColumn style={{ fontSize: "12px" }}>
                        {item.userName}
                      </TableRowColumn>
                      <TableRowColumn style={{ fontSize: "12px" }}>
                        {item.fileName}
                      </TableRowColumn>
                      <TableRowColumn>
                        <IconButton iconStyle={{ fontSize: "16px" }}>
                          <FontIcon
                            className="fa fa-ellipsis-v"
                            style={{ margin: "0 30px 0 20px" }}
                          />
                        </IconButton>
                      </TableRowColumn>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableRowColumn
                    style={{
                      fontSize: "12px",
                      colspan: 7,
                      textAlign: "center"
                    }}
                  >
                    No Data Source. Create a Data Source.
                  </TableRowColumn>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      );
    };

    return (
      <Grid fluid style={{ padding: "20px" }}>
        <MediaQuery maxWidth={768}>
          {this.props.isDetailsOpen ? drawer() : table()}
        </MediaQuery>

        <MediaQuery minWidth={769}>
          {table()}

          <Drawer
            open={this.props.isDetailsOpen}
            onRequestChange={this._handleCloseDetails}
            openSecondary={true}
            docked={true}
            width={600}
            style={{
              overflow: "hidden"
            }}
            containerStyle={{
              overflow: "hidden"
            }}
          >
            {drawer()}
          </Drawer>
        </MediaQuery>
      </Grid>
    );
  }
}

export default DataSourceList;
