import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { MainContainer, StyledIcon, ButtonText, StyledImage } from "./styles";
import config from "../../../../../../../../config";

const ProviderButton = props => {
  const { icon, text, onClick, isSelected, ...rest } = props;

  const logo = useMemo(() => {
    if (typeof icon === "string") {
      return (
        <StyledImage
          key={`${text}-logo`}
          src={`${config.app.images}/${icon}`}
          alt={text}
          style={{ opacity: isSelected ? "1" : "0.5" }}
        />
      );
    }
    return <StyledIcon icon={icon} size="4x" isSelected={isSelected} />;
  }, [icon, text, isSelected]);

  return (
    <MainContainer
      isSelected={isSelected}
      focusRipple
      onClick={onClick}
      {...rest}
    >
      {logo}
      <ButtonText isSelected={isSelected}>{text}</ButtonText>
    </MainContainer>
  );
};

ProviderButton.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool
};

ProviderButton.defaultProps = {
  isSelected: false,
  onClick: () => {}
};

export default ProviderButton;
