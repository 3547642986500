import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import ErrorIcon from "@material-ui/icons/Error";

import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import TicketTemplateViewerTemplateFieldsCTN from "./Fields/FieldsCTN";

const styles = theme => ({
  wrapper: {
    height: "calc(100vh - 210px)"
  },
  paper: {
    width: "100%",
    height: "100%",
    padding: "10px",
    position: "relative"
  },
  label: {
    padding: "0",
    marginTop: "18px",
    fontSize: "12px"
  },
  tabRoot: {
    maxWidth: "10000px",
    width: "50%"
  },
  fieldsWrapper: {
    marginTop: "20px",
    position: "relative",
    height: "calc(100% - 150px)"
  },
  fieldsInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)"
  }
});

const TicketTemplateViewerTemplate = props => {
  const {
    classes,
    name,
    route,
    currentTab,
    secondaryFieldEnabled,
    ticketType,
    templateTypes
  } = props;

  const { setName, setFieldTab, setTicketType } = props;

  return (
    <>
      <div className={classes.wrapper}>
        <Grid container alignItems="center">
          <Grid item xs={2} className={classes.label}>
            Template Name*
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              margin="normal"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={2} className={classes.label}>
            Ticket Type*
          </Grid>
          <Grid item xs={4}>
            <Select
              fullWidth
              value={ticketType}
              disabled={Boolean(route && route !== "new")}
              onChange={event => {
                setTicketType(event.target.value);
              }}
            >
              {templateTypes.map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container className={classes.fieldsWrapper}>
          <Paper className={classes.paper}>
            {ticketType ? (
              <>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  value={currentTab}
                  onChange={(event, value) => setFieldTab(value)}
                >
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    style={{
                      width: secondaryFieldEnabled ? "50%" : "100%"
                    }}
                    label="PRIMARY FIELDS *"
                  />
                  {secondaryFieldEnabled && (
                    <Tab
                      classes={{ root: classes.tabRoot }}
                      label="SECONDARY FIELDS *"
                    />
                  )}
                </Tabs>
                <TicketTemplateViewerTemplateFieldsCTN />
              </>
            ) : (
              <div className={classes.fieldsInfo}>
                {props.match.params.id ? (
                  <CircularProgress />
                ) : (
                  <>
                    <ErrorIcon color="primary" />
                    <div>
                      Please selet template type to view optional fields
                    </div>
                  </>
                )}
              </div>
            )}
          </Paper>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(withRouter(TicketTemplateViewerTemplate));
