import React from "react";

export default class PasswordComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  _handleClick() {
    const state = this.state;
    state.showPassword = !state.showPassword;
    this.setState(state);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontFamily: "monospace",
          position: "relative",
          top: 0
        }}
      >
        {this.state.showPassword
          ? this.props.password
          : this.props.password
          ? this.props.password.replace(/./g, "•")
          : null}
      </div>
    );
  }
}
