import React from "react";
import {
  Grid,
  TextField,
  Paper,
  MenuItem,
  Select,
  Button,
  Tabs,
  Tab,
  withStyles
} from "@material-ui/core";

import TicketConfidenceChip from "../../../tickets/TicketConfidenceChip";

const styles = {
  mainWrapper: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden"
  },
  contentHeader: {
    flexGrow: 1,
    padding: "0px 30px",
    maxWidth: 1000,
    margin: "0 auto"
  },
  ticketNumber: {
    fontWeight: 300,
    margin: "20px 0",
    display: "flex",
    alignItems: "center"
  },
  contentActionsContainer: {
    padding: "20px 20px",
    paddingBottom: 30,
    flexGrow: 0,
    flexShrink: 0,
    alignItems: "center"
  },
  contentActions: {
    // fontSize: 11,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  buttonIcon: {
    fontSize: 14,
    color: "white"
  },
  label: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    paddingBottom: "5px",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  field: {
    padding: "0",
    marginTop: "15px",
    paddingRight: "4px",
    fontSize: "12px"
  },
  tabRoot: {
    minWidth: "0",
    width: "20%"
  }
};

const TicketTemplateViewerPreview = props => {
  const { primaryFields, secondaryFields, classes } = props;
  return (
    <div className={classes.mainWrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.contentHeader}>
          <h1 className={classes.ticketNumber}>
            INCXXXXXXX &nbsp;
            <TicketConfidenceChip confidenceLevel={"0.81"} />
          </h1>
          <div>
            {primaryFields &&
              primaryFields.map(item => {
                return (
                  <div key={item.name} className={classes.label}>
                    <Grid container justify="flex-start" alignItems="center">
                      <Grid item xs={4} className={classes.field}>
                        {`${item.displayName} (${item.name})`}
                      </Grid>
                      <Grid item xs={8}>
                        <TextField id={item.name} fullWidth margin="normal" />
                      </Grid>
                    </Grid>
                  </div>
                );
              })}

            {secondaryFields.length > 0 && (
              <Tabs
                fullWidth
                value={1}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab classes={{ root: classes.tabRoot }} label="AUTOMATION" />
                <Tab classes={{ root: classes.tabRoot }} label="PROPERTIES" />
                <Tab classes={{ root: classes.tabRoot }} label="RESOLUTION" />
                <Tab classes={{ root: classes.tabRoot }} label="ACTIVITY" />
                <Tab classes={{ root: classes.tabRoot }} label="WORKFLOW" />
              </Tabs>
            )}

            {secondaryFields &&
              secondaryFields.map(item => {
                return (
                  <div key={item.name} className={classes.label}>
                    <Grid container justify="flex-start" alignItems="center">
                      <Grid item xs={4} className={classes.field}>
                        {`${item.displayName} (${item.name})`}
                      </Grid>
                      <Grid item xs={8} style={{ padding: "0px" }}>
                        {item.type === "dropdown" ? (
                          <Select fullWidth value="">
                            <MenuItem>Option1</MenuItem>
                            <MenuItem>Option2</MenuItem>
                            <MenuItem>Option3</MenuItem>
                            <MenuItem>Option4</MenuItem>
                          </Select>
                        ) : (
                          <TextField id={item.name} margin="normal" fullWidth />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <Paper className={classes.contentActionsContainer}>
        <div className={classes.contentActions}>
          <Button variant="contained" disableRipple color="primary">
            <i
              className="fa fa-save"
              style={{ fontSize: 14, color: "white" }}
            />
            &nbsp;Save
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            color="secondary"
            disableRipple
            style={{ color: "white" }}
          >
            <i
              className="fa fa-refresh"
              style={{ fontSize: 14, color: "white" }}
            />
            &nbsp;Automate
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(TicketTemplateViewerPreview);
