import React from "react";
import { Row, Col } from "react-flexbox-grid/lib";
import { FlatButton } from "material-ui";
import FontIcon from "material-ui/FontIcon";
import Truncate from "react-truncate";

const styles = {
  image: {
    maxWidth: "100%",
    height: "auto",
    verticalAlign: "center"
  },
  title: {
    fontSize: 14,
    fontWeight: "bold"
  },
  description: {
    fontSize: 12
  }
};

export default class ServiceRequestItem extends React.Component {
  render() {
    const { name, shortDescription, description, image } = this.props;
    const noDescription = !shortDescription && !description;
    return (
      <FlatButton
        style={{ minHeight: noDescription ? 100 : 150, width: "100%" }}
      >
        <Row>
          <Col xs={4}>
            <Row middle="xs" style={{ height: "100%" }}>
              <Col xs={12}>
                {image ? (
                  <img src={image} style={styles.image} alt="" />
                ) : (
                  <FontIcon className="fa fa-list" />
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={8} style={{ textAlign: "left", padding: 2 }}>
            <span style={styles.title}>{name}</span>
            <br />
            <Truncate
              style={styles.description}
              lines={2}
              ellipsis={<span>...</span>}
              trimWhitespace
            >
              {shortDescription}
            </Truncate>
          </Col>
        </Row>
      </FlatButton>
    );
  }
}
