import React, { useEffect } from "react";
import { connect } from "react-redux";
import { DrawerBase } from "./DrawerBase";
import { MenuItem } from "material-ui/Menu";
import {
  handleBulkActionDrawer,
  getBulkActionsList,
  triggerBulkAction,
  updateBulkActionType,
  updateBulkActionTickets
} from "actions/bulkActions";
import { openSnackbar, closeSnackbar } from "actions/snackbar";
import PrimaryDropDownMenu from "../templates/PrimaryDropDownMenu";
import { Row, Col } from "react-flexbox-grid/lib";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const mapStateToProps = state => ({
  bulkActionDrawerOpen: state.bulkActions.bulkActionDrawerOpen,
  bulkActionList: state.bulkActions.bulkActionList,
  bulkActionPayload: state.bulkActions.bulkActionPayload,
  isSnackbarOpen: state.snackbar.isOpen
});

const mapDispatchToProps = dispatch => ({
  getBulkActionsList: () => dispatch(getBulkActionsList()),
  handleBulkActionDrawer: drawerState =>
    handleBulkActionDrawer(drawerState, dispatch),
  triggerBulkAction: actions => dispatch(triggerBulkAction(actions)),
  updateBulkActionType: actionType =>
    dispatch(updateBulkActionType(actionType)),
  updateBulkActionTickets: selectedTickets =>
    dispatch(updateBulkActionTickets(selectedTickets)),
  openSnackbar: message => dispatch(openSnackbar(message)),
  closeSnackbar: () => dispatch(closeSnackbar())
});

const BulkActionDrawer = props => {
  const {
    bulkActionDrawerOpen,
    handleBulkActionDrawer,
    getBulkActionsList,
    bulkActionList,
    bulkActionPayload,
    triggerBulkAction,
    updateBulkActionType,
    updateBulkActionTickets,
    isSnackbarOpen,
    openSnackbar,
    closeSnackbar
  } = props;

  useEffect(() => {
    getBulkActionsList();
  }, [getBulkActionsList]);

  const deleteSelectedTicket = deletedTicket => {
    const selectedTicketsArray = [...bulkActionPayload.selectedTickets];
    const ticketIndex = selectedTicketsArray.findIndex(
      ticket => ticket === deletedTicket
    );

    if (ticketIndex > -1) {
      selectedTicketsArray.splice(ticketIndex, 1);
      updateBulkActionTickets(selectedTicketsArray);
    }
  };

  return (
    <DrawerBase
      isDetailsOpen={bulkActionDrawerOpen}
      handleCloseDetails={() => handleBulkActionDrawer(false)}
    >
      <div style={{ padding: "30px" }}>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            paddingBottom: "4vh"
          }}
        >
          Trigger Bulk Action
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%"
          }}
        >
          <Col
            xs={2}
            style={{
              marginTop: "15px",
              fontSize: "13px",
              paddingRight: "1rem"
            }}
          >
            Action
          </Col>
          <Col xs={10} style={{ padding: "0px" }}>
            <PrimaryDropDownMenu
              value={bulkActionPayload.bulkActionType}
              style={{ paddingLeft: "0px", width: "400px" }}
              onChange={(event, index, value) => updateBulkActionType(value)}
            >
              {bulkActionList.map((action, index) => (
                <MenuItem
                  key={`bulk-action-${index}`}
                  value={action}
                  primaryText={action.toUpperCase()}
                />
              ))}
            </PrimaryDropDownMenu>
          </Col>
        </Row>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          style={{ color: "white" }}
          onClick={() => {
            if (bulkActionPayload.bulkActionType) {
              triggerBulkAction(bulkActionPayload);
              if (!isSnackbarOpen) {
                openSnackbar("Triggered Bulk Action");
                setTimeout(() => {
                  closeSnackbar();
                }, 1500);
              }
              handleBulkActionDrawer(false);
            }
          }}
        >
          Execute
        </Button>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            paddingTop: "2vh",
            paddingBottom: "2vh"
          }}
        >
          Selected Tickets
        </div>
        <List style={{ maxHeight: "600px", overflowY: "scroll" }}>
          {bulkActionPayload.selectedTickets.map(ticket => (
            <ListItem>
              <ListItemText primary={ticket.number} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon onClick={() => deleteSelectedTicket(ticket)} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </DrawerBase>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionDrawer);
