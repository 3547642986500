import { createStyles, WithStyles } from "@material-ui/core/styles";
import Styles from "styles/themeMainUI";

export const styles = createStyles({
  heading: {
    padding: "0 0.5rem",
    fontSize: "15px"
  },
  error: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0"
  },
  errorMsg: {
    margin: "0 0.5rem"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  contentText: {
    textAlign: "center"
  },
  icon: {
    color: Styles.palette.accent3Color,
    fontSize: "15px",
    margin: "auto 0"
  }
});

export type IStyles = WithStyles<typeof styles>;
