import config from "../config";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export function closeNotification() {
  return {
    type: CLOSE_NOTIFICATION
  };
}

export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION
});

export const GET_NOTIFICATIONS_PENDING = "GET_NOTIFICATIONS_PAGE_PENDING";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_PAGE_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_PAGE_ERROR";

export function getNotifications(page = 0, perPage = 10) {
  return (dispatch, getState) => {
    dispatch({ type: GET_NOTIFICATIONS_PENDING });

    const url = `${config.urls.base +
      config.urls.apis.notifications}/notifications`;
    const params = {
      page,
      perPage
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_NOTIFICATIONS_SUCCESS,
          payload: res.data
        });
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: GET_NOTIFICATIONS_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: GET_NOTIFICATIONS_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const GET_NOTIFICATIONS_UNREAD_PENDING =
  "GET_NOTIFICATIONS_UNREAD_PENDING";
export const GET_NOTIFICATIONS_UNREAD_SUCCESS =
  "GET_NOTIFICATIONS_UNREAD_SUCCESS";
export const GET_NOTIFICATIONS_UNREAD_ERROR = "GET_NOTIFICATIONS_UNREAD_ERROR";

export function getUnreadNotifications(page = 0, perPage = 5000) {
  return (dispatch, getState) => {
    dispatch({ type: GET_NOTIFICATIONS_UNREAD_PENDING });

    const url = `${config.urls.base +
      config.urls.apis.notifications}/notifications`;
    const params = {
      page,
      perPage,
      unRead: true
    };

    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_NOTIFICATIONS_UNREAD_SUCCESS,
          notifications: res.data,
          amount: parseInt(res.headers["x-total-count"])
        });
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: GET_NOTIFICATIONS_UNREAD_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: GET_NOTIFICATIONS_UNREAD_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const NOTIFICATIONS_STREAM_OPEN = "NOTIFICATIONS_STREAM_OPEN";
export const NOTIFICATIONS_STREAM_NEW_NOTIFICATION =
  "NOTIFICATIONS_STREAM_NEW_NOTIFICATION";

export function streamNotifications() {
  return (dispatch, getState) => {
    const url = `${config.urls.base +
      config.urls.apis.notifications}/notifications/consume-notifications`;
    // Opening the connection
    const es = new window.EventSourcePolyfill(url, {
      headers: {
        apiToken: getState().auth.token.token
      }
    });

    // Callback when a message is received
    const listener = function(event) {
      // receive a ticket
      const data = JSON.parse(event.data);
      if (data && data.id) {
        dispatch({
          type: NOTIFICATIONS_STREAM_NEW_NOTIFICATION,
          payload: data
        });
      }
    };
    // Link the listener to the event. Event types are open, error and message.
    es.addEventListener("message", listener);
    es.onerror = e => {
      console.warn("consume-notifications error");
    };

    // dispatch the stream object
    dispatch({
      type: NOTIFICATIONS_STREAM_OPEN
    });
  };
}

export const MARK_NOTIFICATION_AS_READ_PENDING =
  "MARK_NOTIFICATION_AS_READ_PENDING";
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_ERROR =
  "MARK_NOTIFICATION_AS_READ_ERROR";

export function markNotificationAsRead(id) {
  return (dispatch, getState) => {
    dispatch({ type: MARK_NOTIFICATION_AS_READ_PENDING });

    const url = `${config.urls.base +
      config.urls.apis.notifications}/notifications/${id}/read`;

    return axiosSnackbar
      .put(url)
      .then(res => {
        dispatch({
          type: MARK_NOTIFICATION_AS_READ_SUCCESS,
          payload: {
            ...res.data,
            read: true
          }
        });
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: MARK_NOTIFICATION_AS_READ_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: MARK_NOTIFICATION_AS_READ_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}

export const MARK_NOTIFICATIONS_ALL_READ_PENDING =
  "MARK_NOTIFICATIONS_ALL_READ_PENDING";
export const MARK_NOTIFICATIONS_ALL_READ_SUCCESS =
  "MARK_NOTIFICATIONS_ALL_READ_SUCCESS";
export const MARK_NOTIFICATIONS_ALL_READ_ERROR =
  "MARK_NOTIFICATIONS_ALL_READ_ERROR";

export function markNotificationsAllRead() {
  return (dispatch, getState) => {
    dispatch({
      type: MARK_NOTIFICATIONS_ALL_READ_PENDING
    });

    const url =
      `${config.urls.base + config.urls.apis.notifications}/notifications/` +
      "readAll";

    return axiosSnackbar
      .put(url)
      .then(res => {
        dispatch({
          type: MARK_NOTIFICATIONS_ALL_READ_SUCCESS
        });
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({
            type: MARK_NOTIFICATIONS_ALL_READ_ERROR,
            errMsg: err.response.data
          });
        } else {
          dispatch({
            type: MARK_NOTIFICATIONS_ALL_READ_ERROR,
            errMsg: {
              error: err.toString()
            }
          });
        }
      });
  };
}
