import produce from "immer";

export const GET_TICKET_FIELD = "ticketField/GET_TICKET_FIELD";
export const GET_TICKET_FIELD_SUCCESS = "ticketField/GET_TICKET_FIELD_SUCCESS";
export const GET_TICKET_FIELD_PENDING = "ticketField/GET_TICKET_FIELD_PENDING";
export const GET_TICKET_FIELD_ERROR = "ticketField/GET_TICKET_FIELD_ERROR";

const initalState = {
  isFetchingTicketFields: true,
  ticketFields: {}
};

export const getTicketField = () => ({
  type: GET_TICKET_FIELD
});

export const getTicketFieldSuccess = ticketFields => ({
  type: GET_TICKET_FIELD_SUCCESS,
  ticketFields
});

export const getTicketFieldPending = () => ({
  type: GET_TICKET_FIELD_PENDING
});

export const getTicketFieldError = () => ({
  type: GET_TICKET_FIELD_ERROR
});

const ticketField = (state = initalState, action) =>
  produce(state, newState => {
    switch (action.type) {
      case GET_TICKET_FIELD_PENDING:
        newState.isFetchingTicketFields = true;
        break;
      case GET_TICKET_FIELD_SUCCESS:
        newState.isFetchingTicketFields = false;
        newState.ticketFields = action.ticketFields;
        return;
      default:
        newState = state;
    }
  });

export default ticketField;
