// Stores styles for Tickets.js
import themeMainUI from "../../styles/themeMainUI";

const Styles = {
  Tickets: {
    overflowY: "scroll",
    flexBasis: "200px"
  },
  TableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  },
  TableRowColumn: {
    fontSize: "13px"
  },
  TableRowColumnSelected: {
    backgroundColor: "#FFFDE7"
  },
  BundleHeading: {
    padding: "0 16px",
    margin: "15px 0 0 0"
  },
  BundleListAvatar: {
    minWidth: "40px",
    justifyContent: "center"
  },
  BundleListItem: {
    fontSize: "13px",
    alignItems: "center"
  },
  BundleListItemCount: {
    color: themeMainUI.palette.accent3Color,
    fontSize: "13px",
    textAlign: "right",
    marginTop: 15
  },
  headline: {
    fontSize: 24,
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400
  },
  slide: {},
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 0"
    // alignItems: 'center'
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    paddingBottom: "5px",
    paddingLeft: 0,
    paddingRight: 0
  },
  fieldContainer: {
    paddingBottom: "5px",
    paddingLeft: 15,
    paddingRight: 15
  },
  chip: {
    margin: 4,
    alignItems: "center"
  },
  wrapper: {
    display: "flex",
    flexWrap: "nowrap"
  },
  mainWrapper: {
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    flexGrow: 1,
    padding: "0px 30px",
    maxWidth: 1000,
    margin: "0 auto"
  },
  contentHeaderMobile: {
    flexGrow: 1,
    padding: "0px 10px"
  },
  contentWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    overflowX: "hidden"
  },
  contentScroller: {
    maxHeight: "inherit",
    overflow: "hidden",
    padding: "0 30px",
    maxWidth: 1000,
    margin: "0 auto"
  },
  contentActionsContainer: {
    padding: "20px 20px",
    paddingBottom: 30,
    flexGrow: 0,
    flexShrink: 0,
    alignItems: "center"
  },
  contentActions: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  status: {
    fontSize: "13px",
    display: "flex",
    alignItems: "baseline"
  },
  primaryFont: {
    marginRight: 10,
    color: themeMainUI.palette.primary2Color
  },
  ticketNumber: {
    fontWeight: 300,
    margin: "20px 0",
    display: "inline-block"
  },
  errorText: {
    paddingTop: 5,
    fontSize: 12,
    color: themeMainUI.palette.errorColor
  },
  warningText: {
    paddingTop: 5,
    fontSize: 12,
    color: themeMainUI.palette.textColor
  },
  toggle: {
    marginBottom: 16
  },
  thumbSwitched: {
    backgroundColor: "rgb(0, 188, 212)"
  },
  trackSwitched: {
    backgroundColor: "rgba(0, 188, 212, 0.5)"
  },
  iconStyle: {
    color: themeMainUI.palette.primary3Color
  }
};

export default Styles;
