import React from "react";
import Grid from "react-flexbox-grid/lib/components/Grid";
import Row from "react-flexbox-grid/lib/components/Row";
import Col from "react-flexbox-grid/lib/components/Col";
import { List, ListItem } from "material-ui/List";
import Divider from "material-ui/Divider";
import HelpStyles from "./HelpStyles";
import history from "../../history";
import config from "../../config";
import HelpButton from "./HelpButton";

const version = window.version;

class Help extends React.Component {
  _toggleBundles = () => {};

  render() {
    const bundles = [
      {
        name: "Filters",
        filters: []
      }
    ];

    const bundlesList = bundles.map((group, i) => (
      <div key={i} style={{}}>
        <h4 style={HelpStyles.BundleHeading}>{group.name}</h4>
        <List>
          {group.filters.map((item, k) => (
            <ListItem
              key={`${i}-${k}`}
              primaryText={item.name}
              style={HelpStyles.BundleListItem}
              rightIcon={
                <span style={HelpStyles.BundleListItemCount}>{item.count}</span>
              }
            />
          ))}
        </List>
        <Divider />
      </div>
    ));

    return (
      <div>
        <Grid fluid style={{ padding: "20px" }}>
          <Row style={{ alignItems: "center", paddingLeft: 10 }}>
            <Col xs={12} style={{ padding: 0 }}>
              <h3 style={{ margin: 0 }}>
                {/* HIDE USELESS BUNDLES
                  <IconButton onClick={this._toggleBundles}>
                  <FontIcon className="fa fa-bars" />
                </IconButton> */}
                Help & Support
              </h3>
            </Col>
          </Row>

          <Row style={{ paddingLeft: 10 }}>
            <Col
              md={0}
              lg={2}
              className={"hide-on-mobile hidden-md"}
              style={{ padding: 0 }}
            >
              <div>
                <List className="bundles">{bundlesList}</List>
              </div>
            </Col>
            <Col xs={12} lg={10} style={{ flexDirection: "column" }}>
              <List
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textAlign: "center",
                  flexWrap: "wrap"
                }}
              >
                <HelpButton
                  onClick={() => {
                    window.location =
                      "mailto:myWizAiOps.L3Support@accenture.com";
                  }}
                  icon="fa-envelope"
                  label="Email Enquiry"
                />
                <HelpButton
                  onClick={() => {
                    history.push("/atr/settings/quickstart");
                  }}
                  icon="fa-rocket"
                  label="DIY Ticket Automation"
                />
                <HelpButton
                  onClick={() => {
                    history.push("/atr/settings/tour");
                  }}
                  icon="fa-info-circle"
                  label="Automation Journey"
                />
                <HelpButton
                  onClick={() => {
                    window.open(
                      "https://chrome.google.com/webstore/detail/ocpkdpgppcadhgfghgpnfhijoeedgkcd",
                      "_blank"
                    );
                  }}
                  icon="fa-briefcase"
                  label="Automation Chrome Extension (ACE)"
                />
              </List>
            </Col>
          </Row>
        </Grid>
        <Grid
          fluid
          style={{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          <Row center="xs" bottom="xs" style={{ height: "10%" }}>
            <p style={{ color: "darkgrey", fontSize: 12 }}>
              {version && version.packageVersion
                ? `Version ${version.packageVersion}`
                : config.app.buildVersion &&
                  `Version ${config.app.buildVersion}`}
            </p>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Help;
