import { connect } from "react-redux";
import Tickets from "./Tickets";
import { closeBundles, openBundles } from "../../actions/bundles";
import {
  closeDetails,
  closeDetailsTicket,
  editTicketIndicator,
  openDetails,
  refreshDetails
} from "../../actions/details";
import { fetchUserDetailsProfile } from "../../actions/users";
import { closeSnackbar, openSnackbar } from "../../actions/snackbar";
import { getAllTemplates } from "../../actions/templates";
import {
  ticketGetSingle,
  ticketUpdateFunc,
  createTicketFunc,
  ticketModifySingleJobDefinition,
  ticketRunSingleJob,
  ticketGetActivities,

  // Activities Types
  ticketGetActivitiesTypes,
  ticketGetResolution,
  moveTicketCachePointer,
  retrieveListFromTicketCache,

  // Manual resolution Link
  getManualResolutionLink,
  getManualResolutionConfig,

  // Service Request
  getServiceRequestCatalog,
  createServiceRequest,
  getServiceRequest,
  getServiceRequestItems,
  getServiceRequestTasks,

  // autocomplete ticket fields
  ticketFieldAutocomplete,

  // Stream of tickets
  streamTickets,
  closeTicketStream,
  addToContinuousML,
  setTicketType,
  ticketClearSingleJobDefinition,
  ticketGetSingleJobDefinition,
  getAllGTRWorkflows
} from "../../actions/tickets";

import {
  openAllMappings,
  mappingGetAllJobs,
  getActiveTemplates,
  updateMappingFound,
  getRundeckProjects,
  getRundeckJobs,
  getMappingJob
} from "../../actions/mapping";

import {
  // filters and sorts (export)
  ticketSetFilter,
  ticketSetSort,
  ticketSetInitialFilters,
  ticketSetTimeRange,
  getPresetFilters,
  ticketSetPreset,
  toggleShowMoreFilters
} from "../../actions/filterAndSort";
import {
  exportTickets,
  openExportDialog,
  setExportFileName
} from "../../actions/export";
import { getTicketConfig } from "../../actions/ticketConfig";
import { toggleEmailDialog } from "../../actions/emailTemplates";

import { getUserData } from "redux/modules/userData";
import {
  getTemplateFields,
  getAllTicketTemplates
} from "redux/modules/ticketTemplate";

import { getTicketField } from "redux/modules/ticketField";

const mapStateToProps = (state, ownProps) => ({
  isBundlesOpen: state.bundles.isOpen,
  isDetailsOpen: state.details.isOpen,
  ticketNumber: state.details.ticketNumber,
  ticketIsUpdating: state.details.isUpdating,
  ticketError: state.details.error,
  tickets: state.tickets.tickets,
  totalTickets: state.tickets.totalTickets,
  ticketsIsFetching: state.tickets.isFetchingTickets,
  templateList: state.templates.templateList,
  jobSelected: state.jobs.jobSelected,
  jobIsFetching: state.jobs.isFetching,
  isAutomating: state.jobs.isAutomating,
  token: state.auth.token,

  selectedTicketType: state.tickets.selectedTicketType,
  isCustomizationTableOpen: state.tickets.isCustomizationTableOpen,
  // Service Request
  serviceRequestCatalog: state.tickets.serviceRequestCatalog,
  serviceRequest: state.tickets.serviceRequest,
  srItems: state.tickets.srItems,
  srTasks: state.tickets.srTasks,

  // CACHE PROPS GETTERS
  // the tickets, indexed by page
  cacheLocalTickets: state.tickets.cacheLocalTickets,

  // the final page
  cacheLocalFinal: state.tickets.cacheLocalFinal,

  // the current page
  cacheLocalPointer: state.tickets.cacheLocalPointer,

  // Experimental
  isCacheRefreshing: state.tickets.isCacheRefreshing,
  isTicketSingleCreating: state.tickets.isTicketSingleCreating,
  // contains the cache object
  // {
  //    cacheLocalTickets: Array (list of tickets per page) ,
  //    cacheLocalFinal: Int (final page local)
  // }
  cacheObject: state.tickets.cacheObject,
  // NOTE: the size of cache and size of local pages can be modified via /actions.

  // autocomplete ticket fields
  autocompleteFields: state.tickets.autocompleteFields,

  // import all mappings
  mappings: state.mapping.mappings,
  mappingJobs: state.mapping.mappingJobs,
  isFetchingRundeckProjects: state.mapping.isFetchingRundeckProjects,
  isFetchingMappingJobs: state.mapping.isFetchingMappingJobs,
  mappingRundecks: state.mapping.mappingRundecks,
  rundeckProjects: state.mapping.rundeckProjects,
  mappingFound: state.mapping.mappingFound,

  // import activeTemplate
  activeTemplates: state.mapping.activeTemplates,
  newTicket: state.mapping.newTicket,

  // filters and sorts
  ticketSort: state.tickets.ticketSort,
  ticketFilter: state.tickets.ticketFilter,
  ticketTimeRange: state.tickets.ticketTimeRange,
  ticketActiveFilters: state.tickets.ticketActiveFilters,
  presetFilters: state.tickets.presetFilters,
  presetSelected: state.tickets.presetSelected,
  showMoreFilters: state.tickets.showMoreFilters,

  // export
  isFetchingExports: state.tickets.isFetchingExports,
  exportErrorMsg: state.tickets.exportErrorMsg,
  isExportDialogOpen: state.tickets.isExportDialogOpen,
  exportFileName: state.tickets.exportFileName,

  // Manual resolution Link
  manualResolutionLink: state.tickets.manualResolutionLink,
  manualResolutionConfig: state.tickets.manualResolutionConfig,

  parsedTicket: state.tickets.parsedTicket,
  refreshing: state.details.refreshing,
  user: state.user.user,
  permissions: state.user.permissions,

  // Emmail
  isOpenEmailDialog: state.email.isOpenEmailDialog
});

const mapDispatchToProps = dispatch =>
  // console.log(ownProps);
  ({
    closeBundles: () => dispatch(closeBundles()),
    openBundles: () => dispatch(openBundles()),
    closeDetails: () => dispatch(closeDetails()),
    openDetails: ticketObj => dispatch(openDetails(ticketObj)),
    // openDetails: (ticketNumber) => dispatch(openDetails(ticketNumber)),
    closeDetailsTicket: ticketNumber =>
      dispatch(closeDetailsTicket(ticketNumber)),
    closeSnackbar: () => dispatch(closeSnackbar()),
    openSnackbar: message => dispatch(openSnackbar(message)),
    ticketGetSingle: ticketNumber => dispatch(ticketGetSingle(ticketNumber)),
    ticketUpdateSingle: (ticketId, ticket, updateMessage = "", ticketTable) =>
      dispatch(ticketUpdateFunc(ticketId, ticket, updateMessage, ticketTable)),
    ticketCreateSingle: (ticket, ticketTable) =>
      dispatch(createTicketFunc(ticket, ticketTable)),
    getAllTemplates: () => dispatch(getAllTemplates()),
    getActiveTemplates: (
      cacheLocalPointer,
      cacheObject,
      ticketSort,
      ticketFilter
    ) =>
      dispatch(
        getActiveTemplates(
          cacheLocalPointer,
          cacheObject,
          ticketSort,
          ticketFilter
        )
      ),

    setTicketType: type => dispatch(setTicketType(type)),

    // Manual resolution link
    getManualResolutionLink: () => dispatch(getManualResolutionLink()),
    getManualResolutionConfig: () => dispatch(getManualResolutionConfig()),

    // Service Request
    getServiceRequestCatalog: () => dispatch(getServiceRequestCatalog()),
    createServiceRequest: (id, values) =>
      dispatch(createServiceRequest(id, values)),
    getServiceRequest: SRnumber => dispatch(getServiceRequest(SRnumber)),
    getServiceRequestItems: SRnumber =>
      dispatch(getServiceRequestItems(SRnumber)),
    getServiceRequestTasks: SRnumber =>
      dispatch(getServiceRequestTasks(SRnumber)),

    ticketModifySingleJobDefinition: jobSelected =>
      dispatch(ticketModifySingleJobDefinition(jobSelected)),
    ticketRunSingleJob: (ticketManagement, jobSelected, ticketId) =>
      dispatch(ticketRunSingleJob(ticketManagement, jobSelected, ticketId)),
    ticketGetActivities: ticketId => dispatch(ticketGetActivities(ticketId)),
    // Actitivies Types
    ticketGetActivitiesTypes: ticketType =>
      dispatch(ticketGetActivitiesTypes(ticketType)),
    editTicketIndicator: (ticketNumber, state) =>
      dispatch(editTicketIndicator(ticketNumber, state)),
    ticketGetResolution: (u_knowledge, ticket) =>
      dispatch(ticketGetResolution(u_knowledge, ticket)),

    // Continous machine learning
    addToContinuousML: ticket => dispatch(addToContinuousML(ticket)),

    // CACHE MUTATORS
    // retrieves tickets from cache
    // adds new tickets to cache if necessary
    // TODO: depreciate this

    // retrieves a list of tickets from cache, specified by localPage
    // adds new tickets to cache if necessary
    // TODO: depreciate this
    retrieveList: (localPage, cache) =>
      dispatch(retrieveListFromTicketCache(localPage, cache)),

    // changes the current pointer
    // adds new tickets to cache if necessary
    // TODO: this should be the only exposed function
    moveTicketCachePointer: (
      moveIndex,
      localPage,
      cache,
      sortBy,
      filters,
      ticketType,
      ticketTimeRange,
      presetSelected = "",
      polling = false
    ) =>
      dispatch(
        moveTicketCachePointer(
          moveIndex,
          localPage,
          cache,
          sortBy,
          filters,
          ticketType,
          ticketTimeRange,
          presetSelected,
          polling
        )
      ),

    // autocomplete ticket fields
    ticketFieldAutocomplete: (field, value, ticket = {}) =>
      dispatch(ticketFieldAutocomplete(field, value, ticket)),

    // import all mappings
    openAllMappings: () => dispatch(openAllMappings()),
    mappingGetAllJobs: ticketManagement =>
      dispatch(mappingGetAllJobs(ticketManagement)), // TODO: Fix this

    // filters and sorts - when these get updated, the ticket cache pointer should be too!
    ticketSetFilter: ticketFilter => dispatch(ticketSetFilter(ticketFilter)),
    ticketSetSort: ticketSort => dispatch(ticketSetSort(ticketSort)),
    ticketSetInitialFilters: (filters, fields) =>
      dispatch(ticketSetInitialFilters(filters, fields)),
    ticketSetTimeRange: range => dispatch(ticketSetTimeRange(range)),
    getPresetFilters: () => dispatch(getPresetFilters()),
    ticketSetPreset: preset => dispatch(ticketSetPreset(preset)),
    toggleShowMoreFilters: flag => dispatch(toggleShowMoreFilters(flag)),

    //export
    openExportDialog: flag => dispatch(openExportDialog(flag)),
    setExportFileName: name => dispatch(setExportFileName(name)),
    exportTickets: (
      totalTickets,
      ticketType,
      filters,
      ticketSort,
      ticketTimeRange,
      exportFileName
    ) =>
      dispatch(
        exportTickets(
          totalTickets,
          ticketType,
          filters,
          ticketSort,
          ticketTimeRange,
          exportFileName
        )
      ),

    // Stream the new tickets
    streamTickets: () => dispatch(streamTickets()),
    closeTicketStream: () => dispatch(closeTicketStream()),

    // ticketConfig
    getAllRundecks: () => dispatch(getTicketConfig({ url: "rundeck" })),

    // Email Dialog Actions
    toggleEmailDialog: () => dispatch(toggleEmailDialog()),

    refreshDetails: ticket => dispatch(refreshDetails(ticket)),
    fetchUserDetailsProfile: username =>
      dispatch(fetchUserDetailsProfile(username)),
    ticketClearSingleJobDefinition: () =>
      dispatch(ticketClearSingleJobDefinition()),
    updateMappingFound: mappingFound =>
      dispatch(updateMappingFound(mappingFound)),
    // Prediction
    getRundeckProjects: rundeck => dispatch(getRundeckProjects(rundeck)),
    getRundeckJobs: (rundeck, project) =>
      dispatch(getRundeckJobs(rundeck, project)),
    ticketGetSingleJobDefinition: (ticketManagement, jobId, shortDescription) =>
      dispatch(
        ticketGetSingleJobDefinition(ticketManagement, jobId, shortDescription)
      ),

    // table customization
    getUserData: () => dispatch(getUserData()),
    getTemplateFields: () => dispatch(getTemplateFields()),
    getAllTicketTemplates: () => dispatch(getAllTicketTemplates()),
    getTicketField: () => dispatch(getTicketField()),
    getMappingJob: ticket => dispatch(getMappingJob(ticket)),
    getAllGTRWorkflows: () => dispatch(getAllGTRWorkflows())
  });

const TicketListPage = connect(mapStateToProps, mapDispatchToProps)(Tickets);

export default TicketListPage;
