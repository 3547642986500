import React from "react";
import styles from "../TicketDetailsDrawerStyles";
import SecondaryCircularProgress from "../../templates/SecondaryCircularProgress";
import { Row, Col } from "react-flexbox-grid/lib";
import { Card, CardText } from "material-ui";
import SecondaryTextField from "../../templates/SecondaryTextField";
import RundeckMapping from "../../mapping/RundeckMapping";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import WorkflowSuggestionList from "./WorkflowSuggestionList";

const JobTitle = styled.h4`
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`;

export default class AutomationJob extends React.Component {
  constructor(props) {
    super(props);
    this._handleJobSelectedOptions = this._handleJobSelectedOptions.bind(this);
  }

  handleAQEJump = () => {
    const w = window.open("about:blank");
    w.location.href = `/wfm/workflow/${
      this.props.jobSelected.id ? this.props.jobSelected.id : null
    }`;
  };

  // Displays all parameters required for a Rundeck job
  automationJobOptions = (key, detailedOptions) => {
    return (
      <div key={key} style={styles.formContainer}>
        <Col
          xs={12}
          sm={2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px",
            wordBreak: "break-word"
          }}
        >
          {detailedOptions[key].name}
          {detailedOptions[key].mandatory && "*"}
        </Col>
        <Col xs={12} sm={10}>
          <SecondaryTextField
            name={key}
            fullWidth={true}
            multiLine={true}
            value={
              detailedOptions[key].value === null
                ? detailedOptions[key].defaultValue
                : detailedOptions[key].value
            }
            requiredError={
              detailedOptions[key].mandatory &&
              detailedOptions[key].value === ""
            }
            onChange={event => {
              this._handleJobSelectedOptions(key, event.target.value);
            }}
          />
        </Col>
      </div>
    );
  };

  // Handles user values into rundeck job fields
  _handleJobSelectedOptions(option, value) {
    let jobSelected = JSON.parse(JSON.stringify(this.props.jobSelected));
    jobSelected.detailedOptions[option].value = value;
    jobSelected.options[option] = value;
    this.props.ticketModifySingleJobDefinition(jobSelected);
  }

  render() {
    const { name, providerId, description, detailedOptions } =
      this.props.jobSelected || {};
    return (
      <Card
        style={{
          margin: "0px",
          padding: "10px"
        }}
      >
        <div>
          <RundeckMapping
            // mapping={this.state.mapping}
            ticket={this.props.ticket}
            mapping={this.props.mappingFound}
            mappingRundecks={this.props.mappingRundecks}
            rundeckProjects={this.props.rundeckProjects}
            mappingJobs={this.props.mappingJobs}
            isFetchingRundeckProjects={this.props.isFetchingRundeckProjects}
            isFetchingMappingJobs={this.props.isFetchingMappingJobs}
            getRundeckProjects={this.props.getRundeckProjects}
            getRundeckJobs={this.props.getRundeckJobs}
            updateMappingFound={this.props.updateMappingFound}
            ticketGetSingleJobDefinition={
              this.props.ticketGetSingleJobDefinition
            }
            clearMappingJobs={this.props.clearMappingJobs}
            clearRundeckProjects={this.props.clearRundeckProjects}
          />

          {// JOB DETAILS
          this.props.jobIsFetching ? (
            <Row
              style={{
                margin: "10px 0",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <SecondaryCircularProgress />
            </Row>
          ) : (
            // Make sure jobSelected is not null or an empty object
            this.props.jobSelected &&
            Object.keys(this.props.jobSelected).length > 0 && (
              <Card
                style={{
                  margin: "10px 0",
                  boxShadow: "none",
                  border: "2px solid #bdbdbd",
                  borderRadius: 8
                }}
              >
                <CardText>
                  {providerId === "automation-queue" ? (
                    <Tooltip title="Click to see details" placement="right">
                      <JobTitle onClick={this.handleAQEJump}>{name}</JobTitle>
                    </Tooltip>
                  ) : (
                    <h4 style={{ margin: 0 }}>{name}</h4>
                  )}
                  <span>{description}</span>
                  {detailedOptions &&
                    Object.keys(detailedOptions).map(key =>
                      this.automationJobOptions(key, detailedOptions)
                    )}
                </CardText>
              </Card>
            )
          )}
          {this.props.mappingFound && !this.props.mappingFound.userSelected && (
            <WorkflowSuggestionList
              matchedMappings={this.props.matchedMappings}
              mappingFound={this.props.mappingFound}
              updateMappingFound={this.props.updateMappingFound}
              ticketID={this.props.ticket.coreData.id}
              ticketGetSingleJobDefinition={
                this.props.ticketGetSingleJobDefinition
              }
            />
          )}
        </div>
      </Card>
    );
  }
}
