import React from "react";

import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const TicketTableHeaderUpdatedDate = props => {
  const { displayName, sortTickets, sortDirection } = props;
  return (
    <div
      onClick={() => sortTickets("lastUpdateDate")}
      style={{
        cursor: "pointer",
        display: "flex",
        paddingTop: 10
      }}
    >
      <div>{displayName}</div>
      <div style={{ paddingLeft: 5, marginTop: -2 }}>
        {sortDirection === "DESC" ? <ArrowDownward /> : <ArrowUpward />}
      </div>
    </div>
  );
};

export default TicketTableHeaderUpdatedDate;
