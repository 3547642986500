import React from "react";
import styles from "../../TicketDetailsDrawerStyles";
import ServiceRequestStepper from "./ServiceRequestStepper";
import { Grid, Row, Col } from "react-flexbox-grid/lib";
import ServiceRequestForm from "./ServiceRequestForm";
import ServiceRequestItem from "./ServiceRequestItem";
import ErrorBox from "../../../templates/ErrorBox";
import SecondaryTextField from "../../../templates/SecondaryTextField";
import Styles from "../../../../styles/themeMainUI";
import FontIcon from "material-ui/FontIcon";
import SecondaryCircularProgress from "../../../templates/SecondaryCircularProgress";
import DOMPurify from "dompurify";

let typingTimer;
const doneTypingInterval = 500;
export default class ServiceRequestDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],
      searchText: "",
      searchResults: [],
      isSearching: false
    };
  }

  componentWillMount() {
    this.setState({ steps: [this.props.serviceRequestCatalog] });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.serviceRequestCatalog)
      this.setState({ steps: [nextProps.serviceRequestCatalog] });
  }

  changeStep(category) {
    const newSteps = this.state.steps;
    newSteps.push(category);
    this.setState({ steps: newSteps });
  }

  goPreviousStep(i) {
    const newSteps = this.state.steps;
    newSteps.length = i + 1;
    this.setState({ steps: newSteps });
  }

  getItems(currentStep) {
    const hasItems = currentStep.items && currentStep.items.length !== 0;
    return Array.isArray(currentStep)
      ? currentStep
      : hasItems
      ? currentStep && currentStep.categories.concat(currentStep.items)
      : currentStep && currentStep.categories;
  }

  handleSearch(searchText) {
    clearTimeout(typingTimer);
    this.setState({
      searchResults: [],
      searchText,
      isSearching: true
    });
    if (searchText && searchText.length > 1) {
      typingTimer = setTimeout(() => {
        this.setState({ isSearching: false });
        this.props.serviceRequestCatalog.forEach(category => {
          this.getSearchResults(category, searchText);
        });
      }, doneTypingInterval);
    }
  }

  getSearchResults(currentStep, searchText) {
    this.getItems(currentStep) &&
      this.getItems(currentStep).forEach(item => {
        if (item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
          this.setState({ searchResults: [...this.state.searchResults, item] });
        }
        item.parent = currentStep;
        this.getSearchResults(item, searchText);
      });
  }

  openResultItem(item) {
    const newSteps = [item];
    let tmpItem = item;
    while (tmpItem.parent) {
      newSteps.unshift(tmpItem.parent);
      tmpItem = tmpItem.parent;
    }
    newSteps.unshift(this.props.serviceRequestCatalog);
    this.setState({ steps: newSteps, searchText: "" });
  }

  render() {
    const { steps, searchText, searchResults, isSearching } = this.state;
    const currentStep = steps[steps.length - 1];
    const showServiceForm = currentStep.variables;
    const drawerStyles = {
      ...styles.mainWrapper,
      height: "auto"
    };

    return (
      <div style={drawerStyles}>
        {steps[0].length === 0 && (
          <ErrorBox errorMessage="Cannot retrieve service request catalog. Please make sure your ServiceNow instance is up and running and has items in its service request catalog." />
        )}
        {steps.length > 0 && (
          <div>
            <div
              style={{
                position: "relative",
                display: "inline-block",
                marginLeft: 30
              }}
            >
              <FontIcon
                className="fa fa-search"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 17,
                  fontSize: 14,
                  color: Styles.palette.primary3Color
                }}
              />
              <SecondaryTextField
                style={{ textIndent: 25 }}
                hintText="Search"
                value={searchText}
                onChange={(e, newValue) => this.handleSearch(newValue)}
              />
            </div>
            <br />
            {searchText ? (
              isSearching ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20
                  }}
                >
                  <SecondaryCircularProgress />
                </div>
              ) : (
                <Grid fluid>
                  <Row>
                    {searchResults.length > 0 ? (
                      searchResults.map(item => (
                        <Col
                          xs={6}
                          key={item.id + 20}
                          style={{ padding: 0 }}
                          onClick={() => this.openResultItem(item)}
                        >
                          <ServiceRequestItem {...item} />
                        </Col>
                      ))
                    ) : (
                      <Col xs={12}>
                        <div style={{ textAlign: "center" }}>
                          {!isSearching && "No results"}
                        </div>
                      </Col>
                    )}
                  </Row>
                </Grid>
              )
            ) : (
              <Grid fluid>
                <ServiceRequestStepper
                  steps={steps}
                  goPreviousStep={i => this.goPreviousStep(i)}
                />
                <Row>
                  {this.getItems(currentStep) &&
                    this.getItems(currentStep).map(item => (
                      <Col
                        xs={6}
                        key={item.id}
                        style={{ padding: 0 }}
                        onClick={() => this.changeStep(item)}
                      >
                        <ServiceRequestItem {...item} />
                      </Col>
                    ))}
                </Row>
                {showServiceForm && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(currentStep.description)
                      }}
                    />
                    <ServiceRequestForm data={currentStep} {...this.props} />
                  </div>
                )}
              </Grid>
            )}
          </div>
        )}
      </div>
    );
  }
}
