import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

import TicketTemplateTableHeader from "./Header/Header";
import TicketTemplateTableBodyCTN from "./Body/BodyCTN";
import history from "../../../history";

const styles = theme => ({
  wrapper: {
    padding: "35px"
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  },
  tableWrapper: {
    maxHeight: "calc(100vh - 240px)",
    overflow: "auto",
    width: "100%",
    marginTop: "20px"
  },
  spinner: {
    marginLeft: "50%"
  },
  dotWrapper: {
    margin: "20px",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  activeDot: {
    height: "14px",
    width: "14px",
    backgroundColor: theme.palette.secondary.main,
    margin: "12px",
    borderRadius: "50%"
  },
  inactiveDot: {
    height: "14px",
    width: "14px",
    backgroundColor: "transparent",
    border: "1px solid grey",
    margin: "12px",
    borderRadius: "50%"
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold"
  }
});

const TicketTemplateTable = props => {
  const { classes } = props;
  const {
    getAllTicketTemplates,
    resetViewerStatus,
    resetCurrentTemplate
  } = props;

  useEffect(() => {
    resetViewerStatus();
    resetCurrentTemplate();
    getAllTicketTemplates();
  }, []);

  return (
    <Grid container className={classes.wrapper}>
      <Grid container alignItems="center" justify="space-between">
        <div className={classes.title}>Ticket Templates</div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/atr/settings/ticket-template/new");
          }}
        >
          Create New Template
        </Button>
      </Grid>

      <Grid item className={classes.tableWrapper}>
        {/* {isFetchingTemplates ? (
          <CircularProgress className={classes.spinner} />
        ) : ( */}
        <Table>
          <TicketTemplateTableHeader />

          <TicketTemplateTableBodyCTN />
        </Table>
        {/* )} */}
      </Grid>
      <Grid container className={classes.dotWrapper}>
        <div className={classes.activeDot} />
        Active
        <div className={classes.inactiveDot} />
        Inactive
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TicketTemplateTable);

TicketTemplateTable.defaultProps = {
  isFetchingTemplates: false
};
