import { connect } from "react-redux";
import Help from "../../components/help/Help";
import { closeBundles, openBundles } from "../../actions/bundles";
import { openTutorial } from "../../actions/tutorial";

const mapStateToProps = state => ({
  isBundlesOpen: state.bundles.isOpen,
  permissions: state.user.permissions
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeBundles: () => dispatch(closeBundles()),
  openBundles: () => dispatch(openBundles()),
  openTutorial: () => dispatch(openTutorial())
});

const HelpPage = connect(mapStateToProps, mapDispatchToProps)(Help);

export default HelpPage;
