import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  Button,
  TextField,
  Typography,
  IconButton
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { isEmpty } from "lodash";

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Open Sans",sans-serif'
  },
  palette: {
    primary: {
      light: "#BBDEFB",
      main: "#1E88E5",
      dark: "#0D47A1",
      contrastText: "#fff"
    }
  }
});

const styles = theme => ({
  jobInputRow: {
    border: "0.5px solid rgba(127, 127, 127, .3)",
    marginTop: 3,
    marginBottom: 3,
    padding: 10
  }
});

class JobInputMapping extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      optionalSelectValue: ""
    };
  }

  updateFieldMappingRules = () => {
    if (
      !isEmpty(this.props.targetJob) &&
      typeof this.props.targetJob !== undefined
    ) {
      const existingFieldMappingRules = this.props.mapping.fieldMappingRules.reduce(
        (rules, fieldMappingRule) => rules.concat(fieldMappingRule.parameterId),
        []
      );

      // Remove all fieldMappingRules for inputs that no longer exist in the selected job
      existingFieldMappingRules.forEach(rule => {
        if (!Object.keys(this.props.targetJob.detailedOptions).includes(rule)) {
          this.props.removeFieldMappingRule(rule);
        }
      });

      // Add all mandatory fields to fieldMappingRules automatically, if not already existing
      Object.entries(this.props.targetJob.detailedOptions).forEach(
        ([optionName, value]) => {
          if (
            value.mandatory &&
            !existingFieldMappingRules.includes(optionName)
          ) {
            const defaultValue = value.defaultValue
              ? value.defaultValue
              : value.value
              ? value.value
              : null;
            this.props.addNewFieldMappingRule(optionName, defaultValue);
          }
        }
      );
    }
  };

  componentDidMount() {
    this.updateFieldMappingRules();
  }

  componentDidUpdate() {
    this.updateFieldMappingRules();
  }

  render() {
    const {
      disabled,
      fields,
      classes,
      mapping,
      handleJobInputMappingFieldChange,
      handleJobInputMappingRegexChange,
      handleJobInputMappingDefaultValueChange,
      addNewFieldMappingRule,
      removeFieldMappingRule,
      targetJob
    } = this.props;

    const { optionalSelectValue } = this.state;

    let optionalInputs = [];
    let selectedOptionDefaultValue = "";

    if (!isEmpty(targetJob) && typeof targetJob !== undefined) {
      if (optionalSelectValue) {
        const selectedOption = targetJob.detailedOptions[optionalSelectValue];
        selectedOptionDefaultValue = selectedOption.defaultValue
          ? selectedOption.defaultValue
          : selectedOption.value
          ? selectedOption.value
          : "";
      }
      optionalInputs = Object.entries(targetJob.detailedOptions).reduce(
        (acc, [optionName, value]) =>
          !value.mandatory ? acc.concat(optionName) : acc,
        []
      );
    }

    // Array of existing job inputs in fieldMappingRules
    const existingFieldMappingRules = mapping.fieldMappingRules.reduce(
      (rules, fieldMappingRule) => rules.concat(fieldMappingRule.parameterId),
      []
    );

    // Disable parameters 'Ticket ID' and 'Ticket Number' as they will automatically be extracted by the backend
    const autoExtractedFields = ["ticketId", "ticketNumber"];

    return (
      <MuiThemeProvider theme={theme}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <div style={{ marginTop: 5, display: "flex" }}>
              <div>
                <p style={{ margin: 0 }}>Job Input Mappings</p>
              </div>
            </div>
            <p
              style={{
                margin: 0,
                marginTop: 5,
                marginBottom: 5,
                fontSize: 13,
                color: "grey"
              }}
            >
              Map specific ticket fields to workflow job inputs
            </p>
          </Grid>
        </Grid>
        {!isEmpty(mapping.fieldMappingRules) && !isEmpty(targetJob) && (
          <Grid
            container
            spacing={0}
            alignItems="flex-start"
            justify="center"
            style={{ marginLeft: 0, paddingLeft: 5 }}
          >
            <Grid item xs={2}>
              <Typography variant="caption">Job Input</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Ticket Field</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Regex</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption">Default Value</Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        )}

        {!isEmpty(mapping.fieldMappingRules) && !isEmpty(targetJob) ? (
          <React.Fragment>
            {mapping.fieldMappingRules.map((fieldMapping, i) => {
              const ignore = autoExtractedFields.includes(
                fieldMapping.parameterId
              );
              return (
                <Grid
                  container
                  spacing={8}
                  className={classes.jobInputRow}
                  key={
                    mapping.id +
                    mapping.targetJobName +
                    fieldMapping.parameterId
                  }
                  alignItems="center"
                  justify="center"
                  style={{ padding: "0px 15px 0px 5px" }}
                  wrap="nowrap"
                >
                  <Grid item xs={2} zeroMinWidth>
                    <Typography
                      variant="body1"
                      style={{
                        overflowWrap: "break-word",
                        opacity: ignore ? "0.5" : "1"
                      }}
                    >
                      {fieldMapping.parameterId}
                      {!optionalInputs.includes(fieldMapping.parameterId)
                        ? "*"
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      disabled={disabled}
                      fullWidth
                      error={
                        ignore
                          ? false
                          : (!fieldMapping.defaultValue &&
                              !fieldMapping.extractor.fieldName) ||
                            (!fieldMapping.extractor.fieldName &&
                              fieldMapping.extractor.regex)
                          ? true
                          : false
                      }
                    >
                      <Select
                        disabled={disabled}
                        value={
                          ignore ? "Auto" : fieldMapping.extractor.fieldName
                        }
                        onChange={e =>
                          handleJobInputMappingFieldChange(
                            fieldMapping.parameterId,
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {ignore && (
                          <MenuItem value="Auto">
                            <em>Auto</em>
                          </MenuItem>
                        )}

                        {fields.map((field, index) => (
                          <MenuItem
                            value={field.name}
                            key={
                              mapping.id +
                              mapping.targetJobName +
                              fieldMapping.parameterId +
                              index +
                              field.name
                            }
                          >
                            {field.displayName || field.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={disabled}
                        error={
                          ignore
                            ? false
                            : (!fieldMapping.defaultValue &&
                                !fieldMapping.extractor.regex) ||
                              (fieldMapping.extractor.fieldName &&
                                !fieldMapping.extractor.regex)
                            ? true
                            : false
                        }
                        value={fieldMapping.extractor.regex}
                        onChange={e =>
                          handleJobInputMappingRegexChange(
                            fieldMapping.parameterId,
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={disabled}
                        error={
                          ignore
                            ? false
                            : !fieldMapping.defaultValue &&
                              !fieldMapping.extractor.regex &&
                              !fieldMapping.extractor.fieldName
                            ? true
                            : false
                        }
                        value={fieldMapping.defaultValue}
                        onChange={e =>
                          handleJobInputMappingDefaultValueChange(
                            fieldMapping.parameterId,
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    {optionalInputs.includes(fieldMapping.parameterId) && (
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          removeFieldMappingRule(fieldMapping.parameterId)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              );
            })}
            {!isEmpty(optionalInputs) && (
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                style={{ marginTop: 10 }}
              >
                <Grid item style={{ marginRight: 10 }}>
                  <FormLabel>Add Optional Input:</FormLabel>
                </Grid>
                <Grid item xs={3} style={{ marginRight: 10 }}>
                  <FormControl fullWidth>
                    <Select
                      disabled={disabled}
                      value={optionalSelectValue}
                      onChange={e =>
                        this.setState({ optionalSelectValue: e.target.value })
                      }
                    >
                      {optionalInputs.map((field, index) => (
                        <MenuItem value={field} key={index + field}>
                          {field}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={
                        !optionalSelectValue ||
                        existingFieldMappingRules.includes(optionalSelectValue)
                      }
                      onClick={e =>
                        addNewFieldMappingRule(
                          optionalSelectValue,
                          selectedOptionDefaultValue
                        )
                      }
                    >
                      Add
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="caption">
                  <p>
                    All inputs require at least either Ticket Field &amp; Regex,
                    or Default Value.
                  </p>
                </Typography>
              </FormControl>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <p
                style={{
                  margin: 0,
                  marginTop: 5,
                  marginBottom: 5,
                  fontSize: 13
                }}
              >
                <em>
                  {isEmpty(targetJob)
                    ? "Please select a job/workflow"
                    : "The selected job/workflow does not have any input parameters"}
                </em>
              </p>
            </Grid>
          </Grid>
        )}
      </MuiThemeProvider>
    );
  }
}

JobInputMapping.propTypes = {
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  handleJobInputMappingFieldChange: PropTypes.func.isRequired,
  handleJobInputMappingRegexChange: PropTypes.func.isRequired,
  handleJobInputMappingDefaultValueChange: PropTypes.func.isRequired,
  targetJob: PropTypes.object,
  addNewFieldMappingRule: PropTypes.func.isRequired,
  removeFieldMappingRule: PropTypes.func.isRequired
};

export default withStyles(styles)(JobInputMapping);
