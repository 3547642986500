import React from "react";

import { Grid, Row, Col } from "react-flexbox-grid/lib";
import { List } from "material-ui/List";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";

import MenuItem from "material-ui/MenuItem";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import Chip from "@material-ui/core/Chip";

import RulesStyles from "./RulesStyles";
import SecondaryButton from "../templates/SecondaryButton";
import PrimaryButton from "../templates/PrimaryButton";
import PrimaryToggle from "../templates/PrimaryToggle";
import MediaQuery from "react-responsive";
import RuleDetails from "./RuleDetails";
import { Drawer } from "material-ui";
import { isEmpty } from "lodash";
import PermissionCheck from "../errors/PermissionCheck";
import automaionPlatformDisplayName from "../utils";

class Rules extends React.Component {
  constructor(props) {
    super(props);
    this._handleRowSelection = this._handleRowSelection.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this.props.openRules(0);
    this.props.changePage(0);

    this._toggleBundles = this._toggleBundles.bind(this);
    this._triggerRuleToggle = this._triggerRuleToggle.bind(this);

    this._changePage = this._changePage.bind(this);

    this.props.openAllMappings();
    // this.props.openAllMappings();

    this.state = {
      rules: {},
      rulesRender: {
        confidenceThreshold: this._renderTextField,
        name: this._renderTextField,
        // description: this._renderTextField,
        workInstruction: this._renderStatic,
        ticketManagement: this._renderStatic,
        automationProcess: this._renderDropDownAutomation,
        assignmentGroup: this._renderTextField,
        author: this._renderAutocompleteUserField,
        approver: this._renderAutocompleteUserField,
        enabled: this._renderToggle
      },
      dataSourceUsers: [],
      confidenceThreshold: 70
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rules !== this.props.rules) {
      this.setState({
        rules: nextProps.rules
      });
    }
  }

  _changePage(moveIndex) {
    const nextPage = this.props.currentPage + moveIndex;

    if (nextPage < 0 || nextPage > this.props.lastPage) return null;

    this.props.changePage(nextPage);
    this.props.openRules(nextPage);
  }

  _handleRowSelection(rowIndex) {
    const { closeRule, openRule, ruleSelected, openMapping } = this.props;
    if (ruleSelected.name) {
      closeRule();
    } else {
      const ruleSelected = {
        ...this.state.rules[Object.keys(this.state.rules)[rowIndex]]
      };
      if (ruleSelected.mapping) {
        openMapping(ruleSelected.mapping);
      } else {
        ruleSelected.triggerConditions = {
          fields: [],
          mlFields: {
            confidenceLevel: 0,
            predictions: {}
          },
          triggerStates: []
        };
      }
      openRule(ruleSelected);
    }
  }

  _triggerRuleToggle(selected, rule) {
    const ruleSelected = rule;
    ruleSelected.enabled = !selected;
    this.props.updateSingleRules(rule, this.props.currentPage, "toggle");
    // this.props.openRules();
    // this.props.changePage(this.props.currentPage);
  }

  _handleClose() {
    this.props.closeRule();
  }

  _toggleBundles() {
    const { isBundlesOpen, closeBundles, openBundles } = this.props;
    isBundlesOpen ? closeBundles() : openBundles();
  }

  render() {
    const rulesTable = () => (
      <Grid fluid style={{ padding: "30px" }}>
        <Row style={{ alignItems: "center", paddingLeft: 10 }}>
          <Col xs={12} style={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ marginLeft: 30, marginTop: 10, marginBottom: 20 }}>
                {/* <IconButton onClick={this._toggleBundles}> */}
                {/* <FontIcon className="fa fa-bars"/> */}
                {/* </IconButton> */}
                Rules
              </h3>
              <div>
                <PrimaryButton
                  style={{ marginRight: 30 }}
                  onClick={() => {
                    this.props.openRule({
                      id: "",
                      name: "",
                      description: "",
                      author: this.props.user,
                      approver: "",
                      enabled: false,
                      mappingId: "",
                      triggerConditions: {
                        triggerStates: [],
                        mlFields: {
                          ticketManagement: "",
                          knowledgeBaseId: "",
                          confidenceLevel: 0
                        },
                        fields: []
                      },
                      editable: true
                    });
                  }}
                  label={<strong>Create new rule</strong>}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ padding: 10 }}>
          {/* <Col md={0} */}
          {/* lg={( isBundlesOpen ? 2 : 0)} */}
          {/* className={( isBundlesOpen ? 'hide-on-mobile' : 'hide-on-mobile hidden') + ' hidden-md'} */}
          {/* style={{padding: 0}}> */}

          {/* <div> */}
          {/* <List className="bundles"> */}
          {/* {bundlesList} */}
          {/* </List> */}
          {/* </div> */}
          {/* </Col> */}

          <Col xs={12} style={{ minHeight: 568 }}>
            <Table onCellClick={this._handleRowSelection}>
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                displayRowCheckbox={false}
              >
                <TableRow>
                  <TableHeaderColumn
                    style={RulesStyles.TableHeader}
                    width={130}
                  >
                    Rule name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs hidden-sm hidden-md"
                    style={RulesStyles.TableHeader}
                    width={130}
                  >
                    Mapping Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs"
                    style={RulesStyles.TableHeader}
                    width={70}
                  >
                    {/* {userConfig.variables.ticketManagementDisplayName ||
                      "Ticket Management"} */}
                    Ticket Type
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs"
                    style={RulesStyles.TableHeader}
                    width={110}
                  >
                    {/* {userConfig.variables.ticketManagementDisplayName ||
                      "Ticket Management"} */}
                    Trigger States
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs"
                    style={RulesStyles.TableHeader}
                    width={70}
                  >
                    {/* {userConfig.variables.knowledgebaseDisplayName ||
                      "Knowledge Base"} */}
                    Automation Platform
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs"
                    style={RulesStyles.TableHeader}
                    width={100}
                  >
                    Target Job Id
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs hidden-sm"
                    style={RulesStyles.TableHeader}
                    width={10}
                  >
                    Enabled
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs hidden-sm"
                    style={RulesStyles.TableHeader}
                    width={5}
                  />
                  {/* !isBundlesOpen ?
                   <TableHeaderColumn
                   className="hidden-xs hidden-sm hidden-md"
                   style={RulesStyles.TableHeader}
                   width={100}>
                   Author
                   </TableHeaderColumn> : '' */}
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false} deselectOnClickaway={false}>
                {this.state.rules
                  ? Object.keys(this.state.rules).map((ruleKey, i) => {
                      const rule = this.state.rules[ruleKey];
                      return (
                        <TableRow
                          selected={this.props.ruleSelected === rule}
                          key={i}
                        >
                          <TableRowColumn
                            style={RulesStyles.TableRowColumn}
                            width={130}
                          >
                            {rule.name}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm hidden-md"
                            style={RulesStyles.TableRowColumn}
                            width={130}
                          >
                            {!isEmpty(rule.mapping) && rule.mapping.mappingName}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm hidden-md"
                            style={RulesStyles.TableRowColumn}
                            width={70}
                          >
                            {!isEmpty(rule.mapping) &&
                            this.props.activeTemplates &&
                            this.props.activeTemplates[rule.mapping.ticketType]
                              ? this.props.activeTemplates[
                                  rule.mapping.ticketType
                                ].name
                              : ""}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs"
                            style={RulesStyles.TableRowColumn}
                            width={110}
                          >
                            {rule.triggerConditions.triggerStates &&
                              rule.triggerConditions.triggerStates.map(
                                triggerState => (
                                  <Chip
                                    key={triggerState}
                                    label={triggerState}
                                  />
                                )
                              )}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs"
                            style={RulesStyles.TableRowColumn}
                            width={70}
                          >
                            {!isEmpty(rule.mapping) &&
                              automaionPlatformDisplayName(
                                rule.mapping.targetServiceName //Updated AQE reference to WFM. Link to ticket number- 'https://acnaop.atlassian.net/browse/ATRV3-10890'
                              )}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm"
                            style={RulesStyles.TableRowColumn}
                            width={100}
                          >
                            {!isEmpty(rule.mapping) &&
                              rule.mapping.targetJobName}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm"
                            style={RulesStyles.TableRowColumn}
                            width={10}
                          >
                            <div
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <PrimaryToggle
                                id="ruleName"
                                style={{
                                  padding: "0",
                                  marginTop: "15px"
                                }}
                                toggled={rule.enabled}
                                onClick={e => {
                                  this._triggerRuleToggle(rule.enabled, rule);
                                }}
                              />
                            </div>
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm"
                            style={RulesStyles.TableRowColumn}
                            width={5}
                          >
                            <div
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <IconMenu
                                iconButtonElement={
                                  <IconButton
                                    iconStyle={{
                                      color: RulesStyles.iconStyle.color,
                                      fontSize: 20
                                    }}
                                  >
                                    <FontIcon
                                      className="fa fa-ellipsis-v"
                                      style={{
                                        fontSize: 14,
                                        color: "red"
                                      }}
                                    />
                                  </IconButton>
                                }
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "top"
                                }}
                                targetOrigin={{
                                  horizontal: "left",
                                  vertical: "top"
                                }}
                              >
                                <MenuItem
                                  primaryText="Delete"
                                  onClick={() =>
                                    this.props.deleteRule(
                                      rule,
                                      this.props.currentPage
                                    )
                                  }
                                />
                              </IconMenu>
                            </div>
                          </TableRowColumn>
                          {/* !isBundlesOpen ?
                       <TableRowColumn
                       className="hidden-xs hidden-sm hidden-md"
                       style={RulesStyles.TableRowColumn}
                       width={100}>
                       {rule.author}
                       </TableRowColumn> : '' */}
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
          </Col>
        </Row>
        <Row style={{ padding: 10 }}>
          <List
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 20,
              marginBottom: 60
            }}
          >
            <SecondaryButton
              disabled={this.props.currentPage + 1 === 1}
              label={<strong>Back</strong>}
              onClick={() => {
                this._changePage(-1);
              }}
            />
            <div style={{ padding: "0 40px", fontSize: 13 }}>
              Page {this.props.currentPage + 1} of {this.props.lastPage + 1}{" "}
            </div>
            <SecondaryButton
              disabled={this.props.lastPage + 1 === this.props.currentPage + 1}
              label={<strong>Next</strong>}
              onClick={() => {
                this._changePage(1);
              }}
            />
          </List>
        </Row>
      </Grid>
    );

    return (
      <div style={{ height: "calc(100vh - 58px)", overflowY: "auto" }}>
        <PermissionCheck
          requiredPermissions={[
            "TM_AUTOMATION_MAPPING_READ",
            "TM_CONFIG_READ",
            "TM_AUTOMATION_RULE_READ"
          ]}
          permissions={this.props.permissions}
        >
          <MediaQuery maxWidth={768}>
            <div>
              {this.props.ruleSelected &&
              typeof this.props.ruleSelected.id !== "undefined" ? (
                <RuleDetails {...this.props} />
              ) : (
                rulesTable()
              )}
            </div>
          </MediaQuery>

          <MediaQuery minWidth={769}>
            {rulesTable()}
            <Drawer
              open={
                this.props.ruleSelected &&
                typeof this.props.ruleSelected.id !== "undefined"
              }
              openSecondary
              docked
              width={600}
              style={{
                overflow: "hidden"
              }}
              containerStyle={{
                overflow: "hidden"
              }}
            >
              <RuleDetails {...this.props} />
            </Drawer>
          </MediaQuery>
        </PermissionCheck>
      </div>
    );
  }
}

export default Rules;
