import { connect } from "react-redux";
import TicketTemplateTable from "./Table";
import {
  getAllTicketTemplates,
  resetCurrentTemplate,
  resetViewerStatus
} from "redux/modules/ticketTemplate";

const mapStateToProps = state => {
  return {
    isFetchingTemplates: state.ticketTemplates.isFetchingTemplates
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllTicketTemplates: () => dispatch(getAllTicketTemplates()),
    resetCurrentTemplate: () => dispatch(resetCurrentTemplate()),
    resetViewerStatus: () => dispatch(resetViewerStatus())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateTable);
