import styled from "styled-components";

export const StyledDiv = styled.div`
  font-size: 1.1rem;

  @media screen and (max-width: 992px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 1rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 0.8rem;
  }
`;
