import React from "react";
import Divider from "@material-ui/core/Divider";
import Grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core";
import config from "../../config";

const styles = {
  icon: {
    color: Grey[300],
    fontSize: 32
  },
  title: {
    padding: "15px 15px",
    fontSize: "14px",
    marginRight: "auto"
  },
  popover: {
    width: "480px"
  },
  iconsWrap: {
    display: "flex",
    flexWrap: "wrap"
  },
  listitem: {
    display: "block",
    margin: "auto",
    textAlign: "center"
  }
};

const apps = [
  {
    name: "Identity Management",
    icon: (
      <img
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
        src={`${config.app.images}/im_colour.png`}
        width="68"
        height="56"
        alt=""
      />
    ),
    args: {
      prefix: config.urls.apps["identity-management"]
    }
  },
  {
    name: "IngrAIn",
    icon: (
      <img
        style={{ paddingBottom: "6px" }}
        src={`${config.app.images}/ingrain_colour.png`}
        width="68"
        height="50"
        alt=""
      />
    ),
    args: {
      prefix: config.urls.apps["machine-learning"]
    }
  },
  {
    name: "Knowledgebase",
    icon: (
      <img
        style={{ paddingTop: "4px", paddingBottom: "2px" }}
        src={`${config.app.images}/kb_colour.png`}
        width="66"
        height="50"
        alt=""
      />
    ),
    args: {
      prefix: config.urls.apps.knowledgebase
    }
  },
  {
    name: "Chatbot",
    icon: (
      <img
        style={{ paddingTop: "4px", paddingBottom: "2px" }}
        src={`${config.app.images}/cb_colour.png`}
        width="60"
        height="50"
        alt=""
      />
    ),
    args: {
      prefix: config.urls.apps.chatbot
    }
  },
  // {
  //   name: "AQE",
  //   icon: (
  //     <img
  //       style={{ paddingTop: "4px", paddingBottom: "2px" }}
  //       src={`${config.app.images}/aqe.png`}
  //       width="60"
  //       height="50"
  //       alt=""
  //     />
  //   ),
  //   args: {
  //     prefix: config.urls.apps.aqe
  //   }
  // },
  {
    name: "Workflow Manager",
    icon: (
      <img
        style={{ paddingTop: "4px", paddingBottom: "2px" }}
        src={`${config.app.images}/wfm.svg`}
        width="62"
        height="50"
        alt=""
      />
    ),
    args: {
      prefix: config.urls.apps.wfm
    }
  },
  {
    name: "ATA",
    icon: (
      <img
        style={{ paddingTop: "4px", paddingBottom: "2px" }}
        src={`${config.app.images}/ata.png`}
        width="58"
        height="30"
        alt=""
      />
    ),
    args: {
      prefix: config.urls.apps.ata
    }
  }
];

const AppsDropdownMenu = props => {
  const { classes } = props;
  return (
    <div className={classes.popover}>
      <Grid>
        <div className={classes.title}>Apps</div>
      </Grid>

      <Divider />

      <Grid className={classes.iconsWrap}>
        {apps.map((item, i) => {
          return (
            <Grid item xs={4} key={i}>
              <ListItem
                button
                className={classes.listitem}
                onClick={() => window.open(item.args.prefix, "_blank")}
              >
                <div>{item.icon}</div>
                <div style={{ fontSize: 12 }}> {item.name}</div>
              </ListItem>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(AppsDropdownMenu);
