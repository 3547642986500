import React from "react";
import moment from "moment";
import { List, ListItem } from "material-ui/List";
import Avatar from "material-ui/Avatar";
import Divider from "material-ui/Divider";
import FlatButton from "material-ui/FlatButton";
import SecondaryButton from "../templates/SecondaryButton";
import SecondaryCircularProgress from "../templates/SecondaryCircularProgress";
import { FontIcon } from "material-ui";
import PermissionCheck from "../errors/PermissionCheck";

export default class NotificationsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0
    };
  }

  componentDidMount() {
    this.props.getNotifications();
  }

  componentWillUnmount() {
    this.props.clearNotification();
  }

  loadMoreNotifs() {
    const nextPage = this.state.page + 1;
    this.props.getNotifications(nextPage);
    this.setState({ page: nextPage });
  }

  render() {
    const { notifications, isFetchingNotifications } = this.props;

    return (
      <PermissionCheck
        requiredPermissions={["NS_NOTIFICATION_READ"]}
        permissions={this.props.permissions}
      >
        <div
          style={{
            margin: "0 auto",
            maxWidth: 1000
          }}
        >
          <div
            className="col-xs-12"
            style={{
              padding: "10px 15px"
            }}
          >
            <h3>Your Notifications</h3>
            <div style={{ marginTop: "-20px" }}>
              <FontIcon
                style={{ fontSize: 14 }}
                color="rgb(10, 131, 196)"
                className="fa fa-check"
              />
              <FlatButton
                style={{
                  display: "inline"
                }}
                secondary
                label="MARK AS ALL READ"
                onClick={() => {
                  this.props.markNotificationsAllRead();
                }}
              />
            </div>
          </div>
          <Divider />

          {notifications.length > 0 ? (
            <List
              style={{
                padding: 0,
                overflow: "scroll",
                height: window.innerHeight - 190
              }}
            >
              {notifications.map((notif, i) => (
                <div
                  key={`${i}_notifications`}
                  onClick={() => {
                    if (!notif.read) {
                      this.props.markNotificationAsRead(notif.id);
                    }
                  }}
                >
                  <ListItem
                    style={{
                      fontSize: 12
                    }}
                    innerDivStyle={{
                      paddingTop: 8,
                      paddingBottom: 8
                    }}
                    leftAvatar={
                      <div>
                        {!notif.read ? (
                          <div
                            style={{
                              position: "absolute",
                              right: 0,
                              top: 0
                            }}
                          >
                            <FontIcon
                              style={{ fontSize: 14 }}
                              color="#ff4146"
                              className="fa fa-circle"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <Avatar>{notif.createdBy[0].toUpperCase()}</Avatar>
                      </div>
                    }
                    containerElement="div"
                    primaryText={
                      <div>
                        <strong style={{ margin: 0 }}>{notif.type}</strong>
                        <p
                          style={{
                            lineHeight: 1.25,
                            margin: "5px 0"
                          }}
                        >
                          {notif.content}
                        </p>
                      </div>
                    }
                    secondaryText={
                      <div
                        style={{
                          fontSize: 12
                        }}
                      >
                        <span>
                          {notif.createdBy} - {moment(notif.date).fromNow()}{" "}
                        </span>
                      </div>
                    }
                    secondaryTextLines={1}
                  />
                </div>
              ))}

              <div style={{ textAlign: "center" }}>
                {isFetchingNotifications ? (
                  <SecondaryCircularProgress />
                ) : (
                  <SecondaryButton
                    label="Load more"
                    secondary
                    onClick={() => this.loadMoreNotifs()}
                  />
                )}
              </div>
            </List>
          ) : (
            <List>No notifications!</List>
          )}
        </div>
      </PermissionCheck>
    );
  }
}
