import config from "../config";
import { axiosInstance as axios } from "../utils/axios";

export const TICKET_SET_FILTER = "TICKET_SET_FILTER";
export const TICKET_SET_SORT = "TICKET_SET_SORT";
export const TICKET_SET_TIME_RANGE = "TICKET_SET_TIME_RANGE";
export const TICKET_SET_INITIAL_FILTERS = "TICKET_SET_INITIAL_FILTERS";
export const TICKET_GET_PRESET_FILTERS = "TICKET_GET_PRESET_FILTERS";
export const TICKET_SET_PRESET_FILTER = "TICKET_SET_PRESET_FILTER";
export const TOGGLE_SHOW_MORE_FILTERS = "TOGGLE_SHOW_MORE_FILTERS";

// Should probably make this 'updateFilter'
export function ticketSetFilter(ticketFilter) {
  return {
    type: TICKET_SET_FILTER,
    newFilter: ticketFilter
  };
}

export function ticketSetSort(ticketSort) {
  return {
    type: TICKET_SET_SORT,
    ticketSort
  };
}

export function ticketSetTimeRange(range) {
  return {
    type: TICKET_SET_TIME_RANGE,
    range
  };
}

export function ticketSetInitialFilters(filters, fields) {
  return {
    type: TICKET_SET_INITIAL_FILTERS,
    filters,
    fields
  };
}

export function ticketSetPreset(preset) {
  return {
    type: TICKET_SET_PRESET_FILTER,
    preset
  };
}

// I actually think it doesn't need to be a separate redux field
// - it will just come through with any other filters
export function getPresetFilters() {
  return async function(dispatch, getState) {
    const url = `${config.urls.base}${config.urls.apis["ticket-management"]}/alias/active`;

    return axios.get(url).then(res => {
      dispatch({
        type: TICKET_GET_PRESET_FILTERS,
        presetFilters: res.data
      });
    });
  };
}

export function toggleShowMoreFilters(flag) {
  return {
    type: TOGGLE_SHOW_MORE_FILTERS,
    flag
  };
}

// BELOW was a dynamic filter option linked to a user ID.
// /**
// Function to retrieve user-defined filters for easy filtering
// Params:
//   user - the user associated with this search
//   ticketType - the ticketType associated with this filter
// Returns:
//   An array list of filter options
// **/
// export function getUserPresetFilters(user, ticketType) {
//   return {
//     type: TICKET_GET_USER_PRESET_FILTERS,
//     user,
//     ticketType
//   };
// }
//
// /**
// Function to save a user-defined filter for easy filtering in future
// Params:
//   user - the user to associate this search with
//   ticketType - the ticketType associated with this filter
//   filters - an object of key value pairs representing the filterType and its value
// **/
// export function saveUserPresetFilter(user, ticketType, filters) {
//   return {
//     type: TICKET_SAVE_USER_PRESET_FILTER,
//     user,
//     ticketType,
//     filters
//   };
// }
//
// /**
// Function to save a user-defined filter for easy filtering in future
// Params:
//   user - the user to associate this search with
//   ticketType - the ticketType associated with this filter
//   filterName - the unique name of the preset Filter
// **/
// export function deleteUserPresetFilter(user, ticketType, filterName) {
//   return {
//     type: TICKET_DELETE_USER_PRESET_FILTER,
//     user,
//     ticketType,
//     filterName
//   };
// }
