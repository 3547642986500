import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem } from "@material-ui/core";

export const StyledIcon = styled(({ isSelected, ...rest }) => (
  <FontAwesomeIcon {...rest} />
))`
  font-size: 50px;
  color: ${props => (props.isSelected ? "#0277BD" : "#3d3d3d")};
`;

export const MainContainer = styled.div`
  width: 200px;
  padding-right: 20px;
  border-right: solid #c9c7c7 1px;
  margin-right: 20px;
`;

export const StyledListItem = styled(({ isSelected, ...rest }) => (
  <ListItem {...rest} />
))`
  border: solid ${props => (props.isSelected ? "#0277BD" : "#c9c7c7")} 1px !important;
  border-radius: 5px !important;
  ${props => props.isSelected && "background-color: #e3f2fc !important;"}
  margin-bottom: 10px !important;
`;
