import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";

const styles = theme => ({
  root: {
    margin: 0,
    padding: 2,
    paddingTop: 5,
    backgroundColor: "transparent"
  },
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0
    }
  },
  step: {
    "& $completed": {
      color: "#66BB6A"
    },
    "& $active": {
      color: "#b0b0b0"
    },
    "& $disabled": {
      color: "#b0b0b0" //not working
    }
  },
  alternativeLabel: {},
  active: {},
  completed: {},
  disabled: {},
  connectorActive: {
    "& $connectorLine": {
      borderColor: theme.palette.secondary.main
    }
  },
  connectorCompleted: {
    "& $connectorLine": {
      borderColor: theme.palette.primary.main
    }
  },
  connectorDisabled: {
    "& $connectorLine": {
      borderColor: theme.palette.grey[100]
    }
  },
  connectorLine: {
    transition: theme.transitions.create("border-color")
  }
});

function ProgressTimeline(props) {
  const numberTasks = [1, 2, 3];
  const labels = ["open", "in progress", "closed"];
  const { classes } = props; //this.props;
  //connector colors not working - stupid mui overrides - horrible setup
  const connector = (
    //TODO: why does the connector not show in resolution tab when ticketStatus = "Resolved" ???
    <StepConnector
      classes={{
        //classes aren't working as expected
        // active: classes.connectorActive,
        // completed: classes.connectorCompleted,
        // disabled: classes.connectorDisabled,
        line: classes.connectorLine
      }}
    />
  );
  return (
    <Stepper
      activeStep={props.ticketStatus || 0}
      connector={connector}
      alternativeLabel
      classes={{
        root: classes.root
      }}
    >
      {numberTasks.map(label => {
        return (
          <Step
            key={label}
            classes={{
              root: classes.step
            }}
          >
            <StepLabel
              classes={{
                alternativeLabel: classes.alternativeLabel,
                labelContainer: classes.labelContainer
              }}
              StepIconProps={{
                classes: {
                  root: classes.step,
                  completed: classes.completed,
                  active: classes.active
                }
              }}
            >
              {labels[label - 1]}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default withStyles(styles)(ProgressTimeline);
