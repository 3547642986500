import styled from "styled-components";

export const EmptyConfig = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  padding: 20px;

  @media screen and (max-height: 768px) {
    padding: 10px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    padding: 15px;
  }
`;
