/**
 * Created by x1 on 5/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import LinearProgress from "material-ui/LinearProgress";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

const muiTheme = getMuiTheme({
  fontFamily: '"Open Sans", sans-serif',
  palette: {
    primary1Color: themeMainUI.palette.textColor
  }
});

class PrimaryDatePicker extends React.Component {
  render() {
    const { requiredError, ...rest } = this.props;
    const renderError = typeof requiredError === "boolean" && requiredError;

    return (
      <div>
        <MuiThemeProvider muiTheme={muiTheme}>
          <LinearProgress {...rest} />
        </MuiThemeProvider>
        {renderError ? (
          <div
            style={{
              paddingTop: 5,
              fontSize: 12,
              color: themeMainUI.palette.errorColor
            }}
          >
            This is required
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PrimaryDatePicker;
