import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import { Tabs, Tab } from "material-ui/Tabs";

class PrimaryTabs extends React.Component {
  render() {
    const { tabs, ...rest } = this.props;
    return (
      <Tabs
        style={{
          borderBottom: `1px solid ${themeMainUI.palette.borderColor}`
        }}
        tabItemContainerStyle={{ backgroundColor: "white" }}
        {...rest}
      >
        {tabs.map((val, index) => (
          <Tab
            disableTouchRipple
            key={index}
            value={index}
            label={val}
            style={
              (this.props.selectChip && {
                fontWeight: "bold",
                color: themeMainUI.palette.primary2Color
              }) ||
              (this.props.fontSize && { fontSize: this.props.fontSize })
            }
          />
        ))}
      </Tabs>
    );
  }
}

class PrimaryTab extends React.Component {
  render() {
    return (
      <Tab
        style={
          this.props.selectChip
            ? { fontWeight: "bold", color: themeMainUI.palette.primary2Color }
            : {}
        }
        {...this.props}
      />
    );
  }
}

export { PrimaryTabs, PrimaryTab };
