export const OPEN_GAMIFICATION = "OPEN_GAMIFICATION";
export const CLOSE_GAMIFICATION = "CLOSE_GAMIFICATION";

export function openGamification() {
  return {
    type: OPEN_GAMIFICATION
  };
}

export function closeGamification() {
  return {
    type: CLOSE_GAMIFICATION
  };
}
