import React, { useState } from "react";
import PropTypes from "prop-types";
import BulkActionModel from "models/BulkActionModel";
import styled from "styled-components";
import { List } from "@material-ui/core";
import BulkAction from "./BulkAction";
import BulkActionDialog from "./BulkActionDialog";

const StyledList = styled(List)`
  overflow-y: scroll;
  height: ${window.innerHeight - 300}px;
`;

const BulkActionList = props => {
  const { bulkActionTasks, cancelBulkAction, timezone } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const onClose = () => {
    setSelectedId(null);
    setIsDialogOpen(false);
  };

  const actions = [
    {
      text: "Cancel",
      onClick: onClose
    },
    {
      text: "Confirm",
      onClick: () => {
        cancelBulkAction(selectedId);
        onClose();
      }
    }
  ];

  return (
    <>
      <StyledList>
        {bulkActionTasks &&
          bulkActionTasks.map(task => (
            <BulkAction
              key={task.taskId}
              bulkAction={task}
              timezone={timezone}
              onDelete={id => {
                setSelectedId(id);
                setIsDialogOpen(true);
              }}
            />
          ))}
      </StyledList>
      <BulkActionDialog
        isOpen={isDialogOpen}
        onClose={onClose}
        title="Cancel Bulk action"
        description="Are you sure you want to cancel this bulk action?"
        actions={actions}
      />
    </>
  );
};

BulkActionList.propTypes = {
  bulkActionTasks: PropTypes.arrayOf(BulkActionModel),
  cancelBulkAction: PropTypes.func.isRequired
};

export default BulkActionList;
