import {
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  SET_USER_PERMISSIONS,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_LIST_FAILURE,
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_PENDING,
  SET_CONFIDENTIALITY_POPOVER_STATE
} from "../actions/users";

function user(
  state = {
    userProfile: {},
    user: {},
    permissions: [],
    isAValidUser: false,
    userList: [],
    isFetchingUserList: false,
    errorMessage: "",
    confidentialityPopoverPressed: false
  },
  action
) {
  switch (action.type) {
    case SET_USER_PERMISSIONS:
      return Object.assign({}, state, {
        permissions: action.permissions
      });
    case FETCH_USER_SUCCESS:
      return Object.assign({}, state, {
        user: action.user,
        isAValidUser: true
      });
    case FETCH_USER_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        userProfile: action.profile
      });
    case FETCH_USER_FAILURE:
      return Object.assign({}, state, {
        isAValidUser: false
      });
    case FETCH_USER_LIST_PENDING:
      return { ...state, isFetchingUserList: true };
    case FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.userList,
        isFetchingUserList: false,
        errorMessage: ""
      };
    case FETCH_USER_LIST_FAILURE:
      return {
        ...state,
        userList: [],
        isFetchingUserList: false,
        errorMessage: action.error
      };
    case SET_CONFIDENTIALITY_POPOVER_STATE:
      return {
        ...state,
        confidentialityPopoverPressed: true
      };
    default:
      return state;
  }
}

export default user;
