import React, { useState } from "react";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core";

const styles = {
  TableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  },
  Wrapper: {
    cursor: "pointer",
    display: "flex",
    paddingTop: 10
  },
  Arrow: {
    paddingLeft: 5,
    marginTop: -4
  }
};

const TicketTableHeaderFilterContainer = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { classes, children, title } = props;

  return (
    <>
      <div
        className={classes.Wrapper}
        onClick={event => setAnchorEl(event.currentTarget)}
        aria-owns={anchorEl ? title : undefined}
        aria-haspopup="true"
      >
        <div className={classes.TableHeader}>
          {title.charAt(0).toUpperCase()}
          {title.slice(1)}
        </div>
        <div className={classes.Arrow}>
          {Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        </div>
      </div>
      <Menu
        id={title}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
};

export default withStyles(styles)(TicketTableHeaderFilterContainer);
