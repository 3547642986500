import React, { Component } from "react";
import Grid from "react-flexbox-grid/lib/components/Grid";
import Row from "react-flexbox-grid/lib/components/Row";
import Col from "react-flexbox-grid/lib/components/Col";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import { MenuItem } from "material-ui";
import {
  Table,
  TableHeader,
  TableRow,
  TableHeaderColumn,
  TableBody
} from "material-ui/Table";
import { ContentAdd } from "material-ui/svg-icons/index";
import uuid from "uuid";
import { toArray, isEmpty } from "lodash";
import update from "immutability-helper";
import RundeckTableRow from "./RundeckTableRow";
import ATSTableRow from "./ATSTableRow";
import FileUploader from "../FileUploader";
import MLModelListValidation from "../configuration/MLModelValidation";
import DataSourceTable from "../../../containers/settings/DataSourceTable";
import SecondaryDropDownMenu from "../../templates/SecondaryDropDownMenu";
import MLTable from "./MLTable";
import config from "../../../config";

export class Configuration extends Component {
  static propTypes = {};
  MLForm = {
    "Field Name": ["text", ""],
    Url: ["text", ""],
    "Ticket Type": ["text", ""],
    Continuous: ["checkbox", ""]
  };

  MLPost = {
    continuous: false,
    field: "",
    ticketType: "",
    url: ""
  };

  RundeckForm = {
    Username: ["text", ""],
    Password: ["text", ""],
    Token: ["text", ""],
    proxyConfig: {
      proxyUrl: "",
      proxyUser: "",
      proxyPassword: "",
      proxyDomain: "",
      proxyType: ""
    }
  };

  RundeckPOST = {
    name: "",
    password: "",
    token: "",
    url: "",
    username: "",
    proxyConfig: {
      proxyUrl: "",
      proxyUser: "",
      proxyPassword: "",
      proxyDomain: "",
      proxyType: ""
    }
  };

  EmailForm = {
    clientId: ["text", ""],
    clientSecret: ["text", ""],
    redirectUrl: ["text", ""],
    folderNames: ["text", ""]
  };

  ATSConfig = {
    clientId: ["text", ""],
    baseUrl: ["text", ""]
  };

  constructor(props) {
    super(props);

    this.state = {
      showUploadButton: true,
      showUpload: false,
      // errMessage:'',
      validation: {
        ticketType: false
      },
      uploadTicketType: "",
      form: this.MLForm,
      formService: {},
      drawerIsOpen: false,
      bundles: [
        {
          name: "User information",
          filters: [
            {
              name: "Personal"
            },
            {
              name: "Help"
            }
          ]
        }
      ],
      bundleSelected: {},
      showNewRows: [false, false, false, false, false],
      services: [
        {
          form: this.MLForm,
          table: () => {},
          name: "Machine Learning",
          url: "machineLearningModels",
          subtitle: "Edit prediction model pointers for a ticket type"
        },
        {
          form: this.RundeckForm,
          table: (service, newrow) => {
            return this.RundeckTable(service, newrow);
          },
          name: "Automatic Script",
          url: "rundeck",
          subtitle:
            "Add or remove additional Rundeck instances that ATR integrates with"
        },
        {
          form: this.ATSConfig,
          table: (service, newrow) => this.ATSTable(service, newrow),
          name: "GTR",
          url: "manual-resolution",
          subtitle: "ATS Config"
        }
      ]
    };
  }

  componentDidMount() {
    for (const i in this.state.services) {
      this.props.getTicketConfig(this.state.services[i]);
    }
    this.props.getMLModels();
    this.props.getActiveTemplates();
    this.props.retrieveDataSourceList();
  }

  _toggleBundles = () => {
    const { isBundlesOpen, closeBundles, openBundles } = this.props;
    isBundlesOpen ? closeBundles() : openBundles();
  };

  _handleBundle = bundle => {
    this.setState({
      bundleSelected: bundle
    });
  };

  RundeckTable = (service, addingRow) => {
    return (
      <Table selectable={false} ref={"RundeckTable"}>
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          style={{
            backgroundColor: "rgb(151, 201, 209)"
          }}
        >
          <TableRow style={{ overflow: "auto" }}>
            <TableHeaderColumn style={{ color: "white" }} width={150}>
              Name
            </TableHeaderColumn>
            <TableHeaderColumn style={{ color: "white" }} width={150}>
              URL
            </TableHeaderColumn>
            <TableHeaderColumn style={{ color: "white" }} width={150}>
              Username
            </TableHeaderColumn>
            <TableHeaderColumn style={{ color: "white" }} width={150}>
              Password
            </TableHeaderColumn>
            <TableHeaderColumn
              className={"token-table-header"}
              style={{ color: "white" }}
              width={150}
            >
              Token
            </TableHeaderColumn>
            <TableHeaderColumn style={{ color: "white" }} width={"50"}>
              Proxy Server
            </TableHeaderColumn>
            <TableHeaderColumn style={{ color: "white" }} width={70} />
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {service.list &&
            service.list.map((item, index) => {
              return (
                <RundeckTableRow
                  rowNumber={index}
                  addTicketConfigThenRefreshList={(a, b) => {
                    this.props.addTicketConfigThenRefreshList(a, b);
                  }}
                  deleteTicketConfigThenRefreshList={(a, b) => {
                    this.props.deleteTicketConfigThenRefreshList(a, b);
                  }}
                  service={service}
                  item={item}
                  key={uuid.v4()}
                />
              );
            })}
          {addingRow ? (
            <RundeckTableRow
              addTicketConfigThenRefreshList={(a, b) => {
                this.props.addTicketConfigThenRefreshList(a, b);
              }}
              deleteTicketConfigThenRefreshList={(a, b) => {
                this.props.deleteTicketConfigThenRefreshList(a, b);
              }}
              hideNewRow={service => {
                this.hideRowForService(service);
              }}
              service={service}
              editing={true}
              isNewRow={true}
              item={{
                name: "",
                url: "",
                username: "",
                password: "",
                token: "",
                editing: true,
                proxyConfig: null
              }}
            />
          ) : null}
        </TableBody>
      </Table>
    );
  };

  SNOWTable = (service, addingRow) => {
    return (
      <DataSourceTable
        ref={"DataSourceTable"}
        createDataSource={file => {
          this.props.createDataSource(file);
        }}
        addTicketConfigThenRefreshList={(a, b) => {
          this.props.addTicketConfigThenRefreshList(a, b);
        }}
        deleteTicketConfigThenRefreshList={(a, b) => {
          this.props.deleteTicketConfigThenRefreshList(a, b);
        }}
        hideNewRow={service => {
          this.hideRowForService(service);
        }}
        service={service}
        addingRow={addingRow}
      />
    );
  };

  ATSTable = (service, addingRow) => {
    const headerColumns = ["Client ID", "Metadata URL", "Base URL"];
    const renderTableHeaderColumns = columns =>
      columns
        .map((column, k) => {
          return (
            <TableHeaderColumn
              style={{ color: "white" }}
              width={150}
              key={column}
            >
              {column}
            </TableHeaderColumn>
          );
        })
        .concat(
          <TableHeaderColumn
            style={{ color: "white" }}
            width={70}
            key={service.name + "new"}
          />
        );

    const renderTableHeader = columns => (
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        style={{
          backgroundColor: "rgb(151, 201, 209)"
        }}
      >
        <TableRow style={{ overflow: "auto" }}>
          {renderTableHeaderColumns(columns)}
        </TableRow>
      </TableHeader>
    );

    const MyATSTableRow = props => (
      <ATSTableRow
        addTicketConfigThenRefreshList={(a, b) => {
          this.props.addTicketConfigThenRefreshList(a, b);
        }}
        deleteTicketConfigThenRefreshList={(a, b) => {
          this.props.deleteTicketConfigThenRefreshList(a, b);
        }}
        {...props}
      />
    );

    const renderTableBody = (service, addingRow) => {
      return (
        <TableBody displayRowCheckbox={false}>
          {service.list &&
            service.list.map((item, index) => {
              return (
                <MyATSTableRow
                  width={150}
                  service={service}
                  item={item}
                  key={index}
                />
              );
            })}
          {addingRow ? (
            <MyATSTableRow
              hideNewRow={service => {
                this.hideRowForService(service);
              }}
              key="addingRow"
              service={service}
              editing={true}
              isNewRow={true}
              item={{
                clientId: "",
                baseUrl: "",
                baseHtmlUrl: ""
              }}
            />
          ) : null}
        </TableBody>
      );
    };

    return (
      <Table
        selectable={false}
        style={{
          borderBottom: "1px solid rgb(224, 224, 224)"
        }}
      >
        {renderTableHeader(headerColumns)}
        {renderTableBody(service, addingRow)}
      </Table>
    );
  };

  hideRowForService = service => {
    this.props.services.forEach((item, index) => {
      if (item.name === service.name) {
        const state = update(this.state, {
          showNewRows: { [index]: { $set: false } }
        });
        this.setState(state);
      }
    });
  };

  _handleAddButton = service => {
    this.props.services.forEach((item, index) => {
      if (item.name === service.name) {
        const state = update(this.state, {
          showNewRows: { [index]: { $set: true } }
        });
        this.setState(state);
      }
    });
  };

  _handleUploadButton = show => {
    this.setState({ showUploadButton: show });
  };

  _handleDropDownChange = value => {
    this.setState({
      uploadTicketType: value,
      validation: { ticketType: false }
    });
  };

  _isRequired = value => {
    this.setState({ validation: { ticketType: value } });
  };

  compare = (a, b) => {
    const urls = this.state.services.map(service => {
      return service.url;
    });
    return urls.indexOf(a.url) - urls.indexOf(b.url);
  };

  render() {
    const bundleSelected = this.state.bundleSelected;

    const configTable = this.props.services
      .sort(this.compare)
      .map((service, i) => {
        return (
          service.name !== "Machine Learning" && (
            <div key={service.name + i}>
              {service.name && (
                <div className={"service-" + i}>
                  <h2
                    style={{
                      color: "#5A819E",
                      paddingLeft: "9px"
                    }}
                  >
                    {service.name}
                    {service.name !== "Email" &&
                      !(service.name === "GTR" && !isEmpty(service.list)) && (
                        <IconButton
                          style={{
                            marginRight: "30px",
                            backgroundColor: "#396686",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            padding: "3px",
                            margin: "10px",
                            top: "4px"
                          }}
                          className={"add-" + i}
                          hoveredStyle={{
                            backgroundColor: "#4c91b4"
                          }}
                          iconStyle={{
                            color: "white"
                          }}
                          onClick={() => this._handleAddButton(service)}
                        >
                          <ContentAdd />
                        </IconButton>
                      )}
                  </h2>
                  {service.table &&
                    service.table(service, this.state.showNewRows[i])}
                  <br />
                </div>
              )}
            </div>
          )
        );
      });

    return (
      <div style={{ height: "calc(100vh - 60px)", overflowY: "auto" }}>
        <Grid fluid style={{ padding: "20px" }}>
          <Row style={{ alignItems: "center", paddingLeft: 10 }}>
            <Col xs={12} md={12} style={{ padding: 0 }}>
              <h2 style={{ margin: 0 }}>
                <FontIcon
                  className={"fa fa-cogs"}
                  style={{
                    margin: 20,
                    fontSize: "25pt",
                    color: "#a8a2a0",
                    top: 2,
                    paddingLeft: 8
                  }}
                />
                Configuration
              </h2>
            </Col>
          </Row>

          <Col xs={12} lg={12}>
            {bundleSelected.name === "Templates" ? "other page" : ""}

            <MLTable
              service={this.props.services.find(
                service => service.name === "Machine Learning"
              )}
              ticketTypes={this.props.activeTemplates}
              mlModels={this.props.MLModels}
              onSliderChange={() => {}}
              onSave={(service, newLine) => {
                this.props.addTicketConfigThenRefreshList(service, newLine);
              }}
              onDelete={(service, param) =>
                this.props.deleteTicketConfigThenRefreshList(service, param)
              }
            />
            {this.props.MLModels.length !== 0 && this.props.activeTemplates && (
              <MLModelListValidation
                activeTemplates={this.props.activeTemplates}
                MLModels={this.props.MLModels}
                MLConfig={
                  this.props.services?.find(
                    service => service.name === "Machine Learning"
                  )?.list ?? []
                }
              />
            )}
            {configTable}
            <h2
              style={{
                color: "#5A819E",
                paddingLeft: "9px"
              }}
            >
              Ticket Upload
              <IconButton
                style={{
                  marginRight: "30px",
                  backgroundColor: "#396686",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  padding: "3px",
                  margin: "10px",
                  top: "4px"
                }}
                className={"add-" + 1}
                hoveredStyle={{
                  backgroundColor: "#4c91b4"
                }}
                iconStyle={{
                  color: "white"
                }}
                onClick={() => {
                  this.setState({ showUpload: true });
                }}
              >
                <ContentAdd />
              </IconButton>
            </h2>
            {this.state.showUpload && (
              <div style={{ paddingBottom: 30 }}>
                <Row style={{ paddingLeft: 17, marginTop: -6 }}>
                  <label
                    style={{
                      paddingLeft: 9,
                      paddingRight: 9,
                      paddingTop: 15
                    }}
                  >
                    Ticket Type:
                  </label>
                  <SecondaryDropDownMenu
                    value={this.state.uploadTicketType}
                    onChange={(e, index, value) =>
                      this._handleDropDownChange(value)
                    }
                    errorText={
                      this.state.validation.ticketType
                        ? "This field is required."
                        : ""
                    }
                  >
                    {toArray(this.props.activeTemplates).map(activeTemplate => (
                      <MenuItem
                        key={activeTemplate.ticketType}
                        value={activeTemplate.ticketType}
                        primaryText={activeTemplate.ticketType}
                      />
                    ))}
                  </SecondaryDropDownMenu>
                </Row>

                <FileUploader
                  headerObject={{
                    apiToken: this.props.token.token,
                    ticketType: this.state.uploadTicketType
                  }}
                  handleUploadButton={this._handleUploadButton}
                  isRequired={this._isRequired}
                  uploaderID="csv-upload"
                  dropTargetID="myDropTarget"
                  filetypes={["csv"]}
                  fileAccept="file_extension"
                  fileAddedMessage="Started!"
                  completedMessage="Complete!"
                  service={
                    config.urls.base +
                    config.urls.apis["ticket-management"] +
                    "/tickets/importCSVChunk?ticketType=" +
                    this.state.uploadTicketType
                  }
                  previousText="text2:"
                  disableDragAndDrop={false}
                  onFileSuccess={(file, fileServer) => {
                    this.props.openSnackbar(
                      `Success: ${file.fileName || fileServer} uploaded`
                    );

                    this.setState({
                      showUploadButton: false
                    });
                  }}
                  openSnackbar={this.props.openSnackbar}
                  maxFiles={1}
                  maxFileSize={1073741824}
                  startButton={this.state.showUploadButton ? true : false}
                  pauseButton={false}
                  cancelButton={this.state.showUploadButton ? true : false}
                  onCancelUpload={() => {
                    this.inputDisable = false;
                  }}
                  onPauseUpload={() => {
                    this.inputDisable = false;
                  }}
                  onResumeUpload={() => {
                    this.inputDisable = true;
                  }}
                />
              </div>
            )}
          </Col>
        </Grid>
      </div>
    );
  }
}

export default Configuration;
