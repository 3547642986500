import download from "downloadjs";
import config from "../config";
import { openSnackbar } from "./snackbar";
import { axiosInstanceSnackbar as axiosSnackbar } from "../utils/axios";

export const TICKET_EXPORT_PENDING = "TICKET_GET_ALL_PENDING";
export const TICKET_EXPORT_COMPLETED = "TICKET_GET_ALL_COMPLETED";
export const TICKET_EXPORT_ERROR = "TICKET_GET_ALL_ERROR";
export const TICKET_EXPORT_DIALOG_OPEN = "TICKET_EXPORT_DIALOG_OPEN";
export const TICKET_EXPORT_SET_FILENAME = "TICKET_EXPORT_SET_FILENAME";

function isEmptyObject(o) {
  if (o) {
    return Object.keys(o).every(x => {
      return o[x] === "" || o[x] === null; // or just "return o[x];" for falsy values
    });
  }
  return true;
}

export function openExportDialog(flag) {
  return function(dispatch) {
    dispatch({
      type: TICKET_EXPORT_DIALOG_OPEN,
      isOpen: flag
    });
  };
}

export function setExportFileName(name) {
  return function(dispatch) {
    dispatch({
      type: TICKET_EXPORT_SET_FILENAME,
      exportFileName: name
    });
  };
}

export function exportTickets(
  totalTickets,
  ticketType,
  filters,
  ticketSort,
  ticketTimeRange,
  exportFileName,
  presetFilterSelected
) {
  return async function(dispatch, getState) {
    dispatch({
      type: TICKET_EXPORT_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/exportticket`;

    const params = {
      page: 0,
      perPage: totalTickets
    };
    if (ticketType) {
      params.ticketType = ticketType;
    }
    if (!isEmptyObject(filters)) {
      // Implementing multiple filters...
      let filtersParam = "";
      for (const filter in filters) {
        if (filters.hasOwnProperty(filter)) {
          if (filters[filter]) {
            filtersParam = `${filtersParam}${filter}:${filters[filter]};`;
          }
        }
      }
      params.filters = filtersParam;
    }
    const { sortDirection, sortField } = ticketSort;
    if (sortDirection) {
      params.sortDirection = sortDirection;
    }
    if (sortField) {
      params.sortBy = sortField;
    }
    if (!isEmptyObject(ticketTimeRange)) {
      //Format time for backend
      let start, end;
      if (ticketTimeRange.start) {
        start = `${ticketTimeRange.start} 00:00:00`;
        params.start = start;
      }
      if (ticketTimeRange.end) {
        end = `${ticketTimeRange.end} 23:59:59`;
        params.end = end;
      }
    }
    if (presetFilterSelected && presetFilterSelected !== "") {
      params.preset = presetFilterSelected;
    }

    return axiosSnackbar
      .get(url, {
        responseType: "blob",
        params
      })
      .then(res => {
        if (res.data.type === "application/octet-stream") {
          download(
            res.data,
            exportFileName
              ? `${exportFileName}.csv`
              : "ATR_Ticket_Data_Export.csv"
          );
          dispatch({
            type: TICKET_EXPORT_COMPLETED
          });
          dispatch(openSnackbar(`Tickets successfully exported`));
        } else {
          dispatch({
            type: TICKET_EXPORT_ERROR,
            exportErrorMsg: `Error exporting tickets`
          });
        }
      })
      .catch(err => {
        dispatch({
          type: TICKET_EXPORT_ERROR,
          exportErrorMsg: `Error exporting tickets: ${err.response.data
            .errorMessage ||
            err.response.data.error ||
            err.response.statusText ||
            err.message}`
        });
      });
  };
} //end all
