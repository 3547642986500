import React from "react";

interface IProps {
  unhealthy: number;
  partial: number;
  unknown: number;
}

/**
 * React component representing a multiline tooltip for the plugin health check
 * Details the number of each status' present in the overall health report
 */
const HealthCheckTooltip: React.FC<IProps> = React.memo(
  ({ unhealthy, partial, unknown }) => {
    return (
      <div>
        <div>{`${unhealthy} critical error${
          unhealthy === 1 ? "" : "s"
        } detected`}</div>
        <div>{`${partial} warning${partial === 1 ? "" : "s"} detected`}</div>
        <div>{`${unknown} check${
          unknown === 1 ? "" : "s"
        } undeterminable`}</div>
      </div>
    );
  }
);

export default HealthCheckTooltip;
