import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const styles = theme => ({
  button: {
    marginBottom: "-4px"
  },
  badge: {
    backgroundColor: "rgb(10, 131, 196)",
    color: "#fff"
  },
  hideBadge: {
    opacity: 0
  },
  margin: {
    margin: theme.spacing.unit * 2
  }
});

const FilterButton = ({ classes, onClick }) => (
  <Button className={classes.button} onClick={onClick}>
    <ArrowDropDown />
  </Button>
);

export default withStyles(styles)(FilterButton);
