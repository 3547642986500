import React from "react";
import moment from "moment";
import { List, ListItem } from "material-ui/List";
import IconButton from "material-ui/IconButton";
import { MenuItem } from "material-ui/Menu";
import Select from "@material-ui/core/Select";
import ContentSend from "material-ui/svg-icons/content/send";
import { Grid, Row, Col } from "react-flexbox-grid/lib";
import SecondaryTextField from "../../templates/SecondaryTextField";
import SecondaryCircularProgress from "../../templates/SecondaryCircularProgress";

const ActivityTab = props => {
  const {
    activity,
    activityIsFetching,
    ticketActivitiesTypes,
    ticketActivitiesTypesIsFetching,
    handleCommentChange,
    handleCommentSend,
    handleTypeChange,
    comment,
    type
  } = props;
  return (
    <div style={{ overflow: "hidden" }}>
      {ticketActivitiesTypesIsFetching ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SecondaryCircularProgress />
        </div>
      ) : (
        <Grid fluid>
          <Row
            style={{
              padding: 20
            }}
          >
            {ticketActivitiesTypes && ticketActivitiesTypes.length > 0 ? (
              <Col
                xs={12}
                sm={3}
                style={{
                  paddingLeft: 0,
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <Select
                  id="add-comments"
                  style={{ paddingLeft: "0px", textTransform: "capitalize" }}
                  value={type}
                  onChange={handleTypeChange.bind(this)}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {ticketActivitiesTypes.map(option => (
                    <MenuItem
                      key={option}
                      value={option}
                      style={{ textTransform: "capitalize" }}
                    >
                      Add {option.replace("_", " ")}
                    </MenuItem>
                  ))}
                </Select>
              </Col>
            ) : (
              <Col
                xs={12}
                sm={3}
                style={{
                  fontSize: "12px",
                  paddingLeft: 0,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Add comments
              </Col>
            )}

            <Col
              xs={12}
              sm={8}
              style={{
                padding: 0,
                paddingLeft: 24,
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <SecondaryTextField
                name="comment"
                value={comment}
                onChange={handleCommentChange.bind(this)}
                onKeyPress={event => {
                  if (event.key === "Enter") handleCommentSend();
                }}
                fullWidth
              />
            </Col>
            <Col xs={12} sm={1}>
              <IconButton onClick={handleCommentSend}>
                <ContentSend />
              </IconButton>
            </Col>
          </Row>
        </Grid>
      )}

      <div>
        {activityIsFetching ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SecondaryCircularProgress />
          </div>
        ) : activity && activity.length > 0 ? (
          <List style={{ paddingBottom: 0 }}>
            {activity
              .sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
              .map((n, i) => (
                <div key={i}>
                  {/* DESKTOP */}
                  <ListItem
                    className="hidden-xs"
                    containerElement="Row"
                    primaryText={
                      <Row>
                        <Col
                          className="hidden-xs"
                          xs={3}
                          style={{
                            fontSize: "12px",
                            lineHeight: "25px"
                          }}
                        >
                          {moment(n.date).fromNow()}
                        </Col>
                        <Col
                          className="hidden-xs"
                          xs={9}
                          style={{
                            textOverflow: "ellipsis",
                            lineHeight: "25px",
                            fontSize: "14px"
                          }}
                        >
                          {n.content}
                        </Col>
                      </Row>
                    }
                  />

                  {/* MOBILE */}
                  <ListItem
                    key={`${i}_mobile`}
                    className="hidden-sm-up"
                    style={{
                      fontSize: 12
                    }}
                    containerElement="div"
                    primaryText={
                      <div>
                        <p style={{ lineHeight: 1.25 }}>{n.content}</p>
                      </div>
                    }
                    secondaryText={
                      <div
                        style={{
                          fontSize: 12
                        }}
                      >
                        <span>{moment(n.date).fromNow()} </span>
                      </div>
                    }
                    secondaryTextLines={1}
                  />
                </div>
              ))}
            {/*
              <Divider />
              <SecondaryButton
              label={<strong>Load more</strong>}
              fullWidth={true}/>
            */}
          </List>
        ) : (
          <List
            style={{
              fontSize: 12,
              padding: 20
            }}
          >
            No activity
          </List>
        )}
      </div>
    </div>
  );
};

export default ActivityTab;
