import { connect } from "react-redux";
import TicketTemplateTableAction from "./Action";
import {
  deleteTemplate,
  activateTemplate,
  deactivateTemplate
} from "redux/modules/ticketTemplate";

const mapDispatchToProps = dispatch => {
  return {
    deleteTemplate: templateObject => dispatch(deleteTemplate(templateObject)),
    activateTemplate: templateObject =>
      dispatch(activateTemplate(templateObject)),
    deactivateTemplate: templateObject =>
      dispatch(deactivateTemplate(templateObject))
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(TicketTemplateTableAction);
