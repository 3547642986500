import {
  // TICKET options
  TICKET_GET_OPTIONS_PENDING,
  TICKET_GET_SINGLE_JOB_PENDING,
  TICKET_GET_SINGLE_JOB_FULFILLED,
  TICKET_GET_SINGLE_JOB_ERROR,
  TICKET_CLEAR_SINGLE_JOB,
  TICKET_GET_SINGLE_JOB_MODIFIED,
  TICKET_GET_ALL_JOBS_PENDING,
  TICKET_GET_ALL_JOBS_FULFILLED,
  TICKET_GET_ALL_JOBS_ERROR,

  // Experimental
  TICKET_RUN_SINGLE_JOB_PENDING,
  TICKET_RUN_SINGLE_JOB_FULFILLED,
  TICKET_RUN_SINGLE_JOB_ERROR,
  TICKET_GET_SINGLE_JOB_RESET
} from "../actions/tickets";

function jobs(
  state = {
    jobs: [],
    jobSelected: {},
    isFetching: false,
    isAutomating: false
  },
  action
) {
  switch (action.type) {
    case TICKET_GET_SINGLE_JOB_PENDING: {
      return {
        jobs: state.jobs,
        jobSelected: state.jobSelected,
        isFetching: true
      };
    }
    case TICKET_CLEAR_SINGLE_JOB: {
      return {
        ...state,
        jobSelected: {}
      };
    }
    case TICKET_GET_SINGLE_JOB_FULFILLED: {
      return {
        jobs: state.jobs,
        jobSelected: action.jobSelected,
        isFetching: false
      };
    }
    case TICKET_GET_SINGLE_JOB_ERROR: {
      return {
        jobs: state.jobs,
        jobSelected: {},
        isFetching: false
      };
    }
    case TICKET_GET_OPTIONS_PENDING: {
      return {
        jobs: state.jobs,
        jobSelected: state.jobSelected,
        isFetching: false
      };
    }
    case TICKET_GET_ALL_JOBS_PENDING: {
      return {
        jobs: state.jobs,
        jobSelected: state.jobSelected,
        isFetching: true
      };
    }
    case TICKET_GET_ALL_JOBS_FULFILLED: {
      return {
        jobs: action.jobs,
        jobSelected: state.jobSelected,
        isFetching: false
      };
    }
    case TICKET_GET_ALL_JOBS_ERROR: {
      return {
        jobs: state.jobs,
        jobSelected: {},
        isFetching: false
      };
    }
    case TICKET_GET_SINGLE_JOB_MODIFIED: {
      return {
        jobs: state.jobs,
        jobSelected: action.jobSelected,
        isFetching: false
      };
    }
    case TICKET_GET_SINGLE_JOB_RESET: {
      return {
        jobs: state.jobs,
        jobSelected: action.jobSelected
      };
    }
    case TICKET_RUN_SINGLE_JOB_PENDING: {
      return Object.assign({}, state, { isAutomating: true });
    }
    case TICKET_RUN_SINGLE_JOB_FULFILLED: {
      return Object.assign({}, state, { isAutomating: false });
    }
    case TICKET_RUN_SINGLE_JOB_ERROR: {
      return Object.assign({}, state, { isAutomating: false });
    }

    default:
      return state;
  }
}

export default jobs;
