import { connect } from "react-redux";
import PluginTable from "./Table";

import { getHealth, getPlugins } from "redux/modules/plugin";

const mapStateToProps = state => ({
  pluginList: state.plugin.plugins.allIds
});

const mapDispatchToProps = dispatch => ({
  getPlugins: () => dispatch(getPlugins()),
  getHealth: () => dispatch(getHealth())
});

const PluginTableCTN = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginTable);

export default PluginTableCTN;
