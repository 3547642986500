import {
  DELETE_RUNDECK,
  RESET_DELETE_RUNDECK
} from "actions/rundeckConfiguration";

const initState = {
  rundeckNameValue: ""
};

const rundeckConfirmation = (state = initState, action) => {
  switch (action.type) {
    case DELETE_RUNDECK:
      return action.data;
    case RESET_DELETE_RUNDECK:
      return initState;
    default:
      return null;
  }
};

export default rundeckConfirmation;
