import React from "react";
import Resumablejs from "resumablejs";
import PrimaryButton from "../templates/PrimaryButton";
import SecondaryLinearProgress from "../templates/SecondaryLinearProgress";
import { FontIcon, IconButton } from "material-ui";
import _ from "lodash";
import { green500, red800 } from "material-ui/styles/colors";

export default class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progressBar: 0,
      messageStatus: "",
      fileList: { files: [] },
      isPaused: false,
      isUploading: false,
      file: {},
      uploadSuccess: false,
      errorMsg: "",
      successMsg: ""
    };

    this.resumable = null;
  }

  componentDidMount = () => {
    let ResumableField = new Resumablejs({
      target: this.props.service,
      query: this.props.query || {},
      fileType: this.props.filetypes,
      maxFiles: this.props.maxFiles,
      maxFileSize: this.props.maxFileSize,
      fileTypeErrorCallback: (file, errorCount) => {
        if (typeof this.props.onFileAddedError === "function") {
          this.props.onFileAddedError(file, errorCount);
        }
      },
      maxFileSizeErrorCallback: (file, errorCount) => {
        if (typeof this.props.onMaxFileSizeErrorCallback === "function") {
          this.props.onMaxFileSizeErrorCallback(file, errorCount);
        }
      },
      testMethod: this.props.testMethod || "post",
      testChunks: this.props.testChunks || false,
      headers: this.props.headerObject || {},
      withCredentials: this.props.withCredentials || false,
      chunkSize: this.props.chunkSize,
      simultaneousUploads: this.props.simultaneousUploads,
      fileParameterName: this.props.fileParameterName,
      generateUniqueIdentifier: this.props.generateUniqueIdentifier,
      forceChunkSize: this.props.forceChunkSize
    });

    if (typeof this.props.maxFilesErrorCallback === "function") {
      ResumableField.opts.maxFilesErrorCallback = this.props.maxFilesErrorCallback;
    }

    ResumableField.assignBrowse(this.uploader);

    //Enable or Disable DragAnd Drop
    if (this.props.disableDragAndDrop === false) {
      ResumableField.assignDrop(this.dropZone);
    }

    ResumableField.on("fileAdded", (file, event) => {
      // this.props.handleFileUploadErr('');
      this.setState({
        messageStatus: this.props.fileAddedMessage || " Starting upload! "
      });

      if (typeof this.props.onFileAdded === "function") {
        this.props.onFileAdded(file, this.resumable);
        this.setState({ file });
      } else {
        ResumableField.upload();
      }
    });

    ResumableField.on("fileSuccess", (file, fileServer) => {
      if (this.props.fileNameServer) {
      } else {
        this.setState({
          uploadSuccess: true,
          successMsg: fileServer
        });
      }

      let currentFiles = this.state.fileList.files;
      currentFiles.push(file);

      this.setState(
        {
          fileList: { files: currentFiles },
          messageStatus:
            this.props.completedMessage + file.fileName || fileServer
        },
        () => {
          if (typeof this.props.onFileSuccess === "function") {
            this.props.onFileSuccess(file, fileServer);
          }
        }
      );
    });

    ResumableField.on("progress", () => {
      this.setState({
        isUploading: ResumableField.isUploading()
      });
      if (ResumableField.progress() * 100 < 100) {
        this.setState({
          messageStatus: parseInt(ResumableField.progress() * 100, 10) + "%",
          progressBar: ResumableField.progress() * 100
        });
      } else {
        setTimeout(() => {
          this.setState({
            progressBar: 0
          });
        }, 1000);
      }
    });

    ResumableField.on("fileError", (file, errorCount) => {
      this.props.onUploadErrorCallback(file, errorCount);
      const message = JSON.parse(errorCount).error;
      this.props.openSnackbar("Error: " + message);
      this.setState({ errorMsg: message });
      this.props.handleUploadButton(false);
    });

    this.resumable = ResumableField;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.headerObject, nextProps.headerObject)) {
      this.resumable.opts.headers = nextProps.headerObject;
      this.resumable.opts.target = nextProps.service;
    }
  }

  removeFile = (event, file, index) => {
    event.preventDefault();

    let currentFileList = this.state.fileList.files;
    delete currentFileList[index];

    this.setState({
      fileList: { files: currentFileList }
    });

    this.props.onFileRemoved(file);
    this.resumable.removeFile(file);
  };

  removeFileChosen = () => {
    this.resumable.cancel();
    this.setState({ file: {}, uploadSuccess: false });
  };

  showFileChosen = () => {
    return (
      <div
        style={{
          paddingLeft: 11,
          paddingTop: 5
        }}
      >
        <label>File Name:</label>
        <label style={{ paddingLeft: 28 }}>{this.state.file.fileName}</label>
        {this.state.successMsg ? (
          <IconButton
            tooltipPosition="top-center"
            iconStyle={{ fontSize: 18, color: green500 }}
          >
            <FontIcon className="fa fa-check" />
          </IconButton>
        ) : this.state.errorMsg ? (
          <IconButton
            onClick={() => {
              this.removeFileChosen();
              this.setState({ successMsg: "", errorMsg: "" });
              this.props.handleUploadButton(true);
            }}
            tooltipPosition="top-center"
            iconStyle={{ fontSize: 18, color: red800 }}
          >
            <FontIcon className="fa fa-close" />
          </IconButton>
        ) : (
          <IconButton
            onClick={event => this.removeFileChosen()}
            tooltip="Replace"
            tooltipPosition="top-center"
            iconStyle={{ fontSize: 18 }}
          >
            <FontIcon className="fa fa-close" />
          </IconButton>
        )}
      </div>
    );
  };

  createFileList = () => {
    let markup = this.state.fileList.files.map((file, index) => {
      let uniqID = this.props.uploaderID + "-" + index;
      let originFile = file.file;
      let media = "";

      media = <label>{originFile.name}</label>;
      return (
        <span key={uniqID}>
          {media}
          <IconButton
            onClick={event => this.removeFile(event, file, index)}
            tooltip="Replace"
            tooltipPosition="top-center"
            iconStyle={{ fontSize: 18 }}
          >
            <FontIcon className="fa fa-close" />
          </IconButton>
        </span>
      );
    });

    return <div style={{ marginTop: 3 }}>{markup}</div>;
  };

  cancelUpload = () => {
    this.resumable.cancel();

    this.setState({
      fileList: { files: [] },
      file: {}
    });

    this.props.onCancelUpload();
  };

  pauseUpload = () => {
    if (!this.state.isPaused) {
      this.resumable.pause();
      this.setState({
        isPaused: true
      });
      this.props.onPauseUpload();
    } else {
      this.resumable.upload();
      this.setState({
        isPaused: false
      });
      this.props.onResumeUpload();
    }
  };

  startUpload = () => {
    if (this.props.headerObject.ticketType) {
      this.props.isRequired(false);
      this.resumable.upload();
      this.setState({
        isPaused: false
      });
      this.props.onStartUpload();
    } else {
      this.props.isRequired(true);
    }
  };

  render() {
    let fileList = null;
    if (!_.isEmpty(this.state.file)) {
      fileList = <div className="resumable-list">{this.showFileChosen()}</div>;
    }

    let startButton = null;
    if (this.props.startButton) {
      if (
        typeof this.props.startButton === "string" ||
        typeof this.props.startButton === "boolean"
      )
        startButton = (
          <label style={{ marginRight: 10 }}>
            <PrimaryButton
              disabled={this.state.isUploading}
              label={<strong>Upload</strong>}
              onClick={this.startUpload}
            />
          </label>
        );
      else startButton = this.props.startButton;
    }

    let reStartButton = null;
    if (this.state.successMsg || this.state.errorMsg) {
      startButton = (
        <label style={{ marginRight: 10 }}>
          <PrimaryButton
            label={<strong>Upload One More</strong>}
            onClick={() => {
              this.removeFileChosen();
              this.setState({ successMsg: "", errorMsg: "" });
              this.props.handleUploadButton(true);
            }}
          />
        </label>
      );
    }

    let cancelButton = null;
    if (this.props.cancelButton) {
      if (
        typeof this.props.cancelButton === "string" ||
        typeof this.props.cancelButton === "boolean"
      )
        cancelButton = (
          <label>
            <PrimaryButton
              disabled={!this.state.isUploading}
              label={<strong>Cancel</strong>}
              onClick={this.cancelUpload}
            />
          </label>
        );
      else cancelButton = this.props.cancelButton;
    }

    let pauseButton = null;
    if (this.props.pauseButton) {
      if (
        typeof this.props.pauseButton === "string" ||
        typeof this.props.pauseButton === "boolean"
      )
        pauseButton = (
          <label>
            <PrimaryButton
              disabled={!this.state.isUploading}
              label={<strong>Pause</strong>}
              onClick={this.pauseUpload}
            />
          </label>
        );
      else pauseButton = this.props.pauseButton;
    }
    const visibility = _.isEmpty(this.state.file) ? "flex" : "none";
    return (
      <div>
        {/* {_.isEmpty(this.state.file)&& */}
        <div
          id={this.props.dropTargetID}
          ref={node => (this.dropZone = node)}
          style={{
            width: "50%",
            height: 200,
            marginTop: 15,
            paddingTop: 20,
            paddingBottom: 10,
            border: "2px dashed rgb(146, 194, 224)",
            borderRadius: 5,
            display: visibility,
            flexDirection: "column",
            placeItems: "center",
            color: "rgb(35, 137, 197)",
            backgroundColor: "rgb(249, 253, 255)"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              placeItems: "center"
            }}
          >
            <FontIcon
              style={{ fontSize: 40, color: "inherit" }}
              className="fa fa-upload"
            />
            <p>Drag and drop your CSV to upload</p>
            <p>or</p>
            <p>
              <strong>Click to select file</strong>
            </p>
          </div>
          <input
            ref={node => (this.uploader = node)}
            type="file"
            id={this.props.uploaderID}
            name={this.props.uploaderID + "-upload"}
            accept={this.props.fileAccept || "*"}
            disabled={this.props.disableInput || false}
            style={{
              marginTop: -200,
              width: "100%",
              height: 200,
              opacity: 0
            }}
          />
        </div>
        {fileList}
        {this.state.progressBar !== 0 && (
          <SecondaryLinearProgress
            style={{ width: "30%" }}
            mode="determinate"
            value={this.state.progressBar}
            color="#E69C38"
          />
        )}
        {this.state.errorMsg && (
          <div
            style={{
              paddingLeft: 11,
              paddingTop: 15,
              color: red800
            }}
          >
            Error: {this.state.errorMsg}
          </div>
        )}
        {this.state.successMsg && (
          <div
            style={{
              paddingLeft: 11,
              paddingTop: 15,
              color: green500
            }}
          >
            Success: {this.state.successMsg}
          </div>
        )}
        <div style={{ marginTop: 15 }}>
          {startButton}
          {pauseButton}
          {cancelButton}
          {reStartButton}
        </div>
      </div>
    );
  }
}

FileUploader.defaultProps = {
  maxFiles: undefined,
  uploaderID: "default-resumable-uploader",
  dropTargetID: "dropTarget",
  filetypes: [],
  fileAccept: "*",
  maxFileSize: 10240000,
  showFileList: true,
  onUploadErrorCallback: (file, errorCount) => {},
  onFileRemoved: function(file) {
    return file;
  },
  onCancelUpload: function() {
    return true;
  },
  onPauseUpload: function() {
    return true;
  },
  onResumeUpload: function() {
    return true;
  },
  onStartUpload: function() {
    return true;
  },
  disableDragAndDrop: false,
  fileNameServer: "",
  tmpDir: "",
  chunkSize: 1024 * 1024,
  simultaneousUploads: 1,
  fileParameterName: "file",
  generateUniqueIdentifier: null,
  maxFilesErrorCallback: null,
  cancelButton: false,
  pause: false,
  startButton: null,
  pauseButton: null,
  previousText: "",
  headerObject: {},
  withCredentials: false,
  forceChunkSize: false
};
