import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { ITSMInfoPanel, UsecaseInfoPanel } from "./info";
import ConfirmationCheckbox from "./components/ConfirmationCheckbox";
import { GridContent, GridFooter } from "./styles";

const ReviewAndLaunchPanel = ({
  providerFilters,
  selectedWorkflows,
  selectedProviders,
  handleChange,
  configs,
  reviewConfirmed,
  setReviewConfirmed
}) => {
  useEffect(() => {
    return () => {
      setReviewConfirmed(false);
    };
  }, [setReviewConfirmed]);

  return (
    <Grid
      container
      item
      xs={12}
      spacing={8}
      style={{ paddingLeft: 40, paddingRight: 40, margin: 0, height: "100%" }}
    >
      <GridContent item xs={8}>
        <ITSMInfoPanel
          configs={configs}
          providerFilters={providerFilters}
          selectedProviders={selectedProviders}
          handleChange={handleChange}
        />
      </GridContent>
      <GridContent item xs={4}>
        <UsecaseInfoPanel
          workflows={selectedWorkflows}
          handleChange={handleChange}
        />
      </GridContent>
      <GridFooter item xs={12}>
        <ConfirmationCheckbox
          checked={reviewConfirmed}
          onClick={setReviewConfirmed}
        >
          By proceeding, I confirm I have received all required approvals from
          my Client or CAL/TSL to integrate with the Client system and automate
          tickets through myWizard.
        </ConfirmationCheckbox>
      </GridFooter>
    </Grid>
  );
};

ReviewAndLaunchPanel.propTypes = {
  providerFilters: PropTypes.object.isRequired,
  selectedWorkflows: PropTypes.array.isRequired,
  selectedProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  configs: PropTypes.object.isRequired
};

export default ReviewAndLaunchPanel;
