import config from "../config";
import { openSnackbar } from "./snackbar";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const OPEN_RULE = "OPEN_RULE";
export const OPEN_RULES = "OPEN_RULES";
export const CLOSE_RULE = "CLOSE_RULE";
export const CREATE_RULE = "CREATE_RULE";
export const UPDATE_EDIT = "UPDATE_EDIT";
export const RULE_CHANGE_PAGE = "RULE_CHANGE_PAGE";
export const OPEN_RULES_SUCCESS = "OPEN_RULES_SUCCESS";

export function openRule(ruleSelected) {
  return async function(dispatch) {
    dispatch({
      type: OPEN_RULE,
      ruleSelected
    });
  };
}

export function closeRule() {
  return {
    type: CLOSE_RULE,
    ruleSelected: {}
  };
}

export function changePage(page) {
  return {
    type: RULE_CHANGE_PAGE,
    currentPage: page
  };
}

export function openRules(page = 0, perPage = 10) {
  return function(dispatch, getState) {
    // dispatch({
    //   type: OPEN_RULES_PENDING
    // });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/rules`;

    const params = {
      page,
      perPage
    };

    return axios.get(url, { params }).then(res => {
      const maxPage = Math.ceil(res.headers["x-total-count"] / perPage);
      if (res.data.length === 0 && page !== 0) {
        dispatch(openRules(page - 1));
        dispatch(changePage(page - 1));
      } else {
        dispatch({
          type: OPEN_RULES_SUCCESS,
          rules: res.data,
          lastPage: maxPage === 0 ? 0 : maxPage - 1
        });
      }
    });
  };
}

export function createRule(rule, currentPage) {
  return function(dispatch, getState) {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/rules`;

    return axiosSnackbar.post(url, rule).then(res => {
      dispatch(openSnackbar(`Created rule '${rule.name}' successfully`));
      dispatch(openRules(currentPage));
      dispatch({
        type: CREATE_RULE
      });
      dispatch(closeRule());
    });
  };

  // return {
  //   type: CREATE_RULE
  // }
}

export function updateSingleRules(ruleEdited, currentPage, type) {
  return function(dispatch, getState) {
    // if (states !== null) ruleEdited.triggerStates = states;
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/rules/${ruleEdited.id}`;

    return axiosSnackbar.put(url, ruleEdited).then(res => {
      dispatch(openSnackbar(`Updated rule '${ruleEdited.name}'`));
      dispatch(openRules(currentPage));

      if (type !== "toggle") dispatch(openRule(res.data));
      dispatch({
        type: UPDATE_EDIT,
        ruleEdited
      });

      dispatch(closeRule());
    });
  };

  // console.log('Original Rule ' + rule);
  // console.log('Edited Rule ' + ruleEdited);
  // return {
  //   type: UPDATE_EDIT,
  //   ruleEdited: ruleEdited
  // }
}

export function deleteRule(rule, currentPage) {
  return function(dispatch, getState) {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/rules/${encodeURIComponent(
      rule.id
    )}`;

    return axios
      .delete(url)
      .then(res => {
        dispatch(openSnackbar(`Deleted rule '${rule.name}'`));
        dispatch(openRules(currentPage));
        dispatch(closeRule());
      })
      .catch(err => {
        dispatch(openSnackbar(`Failed to delete rule '${rule.name}'`));
      });
  };
}
