import config from "../config";
import { CLOSE_CREATE_SERVICE_REQUEST_DETAILS } from "./details";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const RETRIEVE_DATA_SOURCE_LIST_PENDING =
  "RETRIEVE_DATA_SOURCE_LIST_PENDING";
export const RETRIEVE_DATA_SOURCE_LIST_SUCCESS =
  "RETRIEVE_DATA_SOURCE_LIST_SUCCESS";
export const RETRIEVE_DATA_SOURCE_LIST_ERROR =
  "RETRIEVE_DATA_SOURCE_LIST_ERROR";

export function retrieveDataSourceList() {
  return function(dispatch, getState) {
    dispatch({
      type: RETRIEVE_DATA_SOURCE_LIST_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/datasource`;

    return axios
      .get(url)
      .then(res => {
        dispatch({
          type: RETRIEVE_DATA_SOURCE_LIST_SUCCESS,
          dataSources: res.data
        });
      })
      .catch(err => {
        dispatch({
          type: RETRIEVE_DATA_SOURCE_LIST_ERROR
        });
      });
  };
}

export const PURGE_ELASTIC_SEARCH_PENDING = "PURGE_ELASTIC_SEARCH_PENDING";
export const PURGE_ELASTIC_SEARCH_SUCCESS = "PURGE_ELASTIC_SEARCH_SUCCESS";
export const PURGE_ELASTIC_SEARCH_ERROR = "PURGE_ELASTIC_SEARCH_ERROR";

export function purgeElasticSearch() {
  return function(dispatch, getState) {
    dispatch({
      type: PURGE_ELASTIC_SEARCH_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/management/delete-index`;

    return axiosSnackbar
      .put(url)
      .then(res => {
        dispatch({
          type: PURGE_ELASTIC_SEARCH_SUCCESS
        });
      })
      .catch(err => {
        dispatch({
          type: PURGE_ELASTIC_SEARCH_ERROR
        });
      });
  };
}

export const OPEN_DATA_SOURCE_DETAILS = "OPEN_DATA_SOURCE_DETAILS";
export const CLOSE_DATA_SOURCE_DETAILS = "CLOSE_DATA_SOURCE_DETAILS";
export const OPEN_CREATE_DATA_SOURCE_DETAILS =
  "OPEN_CREATE_DATA_SOURCE_DETAILS";

export function openDetails(dataSource) {
  return function(dispatch) {
    // Not elegant...
    if (dataSource.sourceName === "create") {
      dataSource.sourceName = "";
      dispatch({
        type: OPEN_CREATE_DATA_SOURCE_DETAILS,
        dataSource
      });
    } else {
      dispatch({
        type: OPEN_DATA_SOURCE_DETAILS,
        dataSource
      });
    }
  };
}

export function closeDetails() {
  return function(dispatch) {
    dispatch({
      type: CLOSE_DATA_SOURCE_DETAILS
    });
    dispatch({
      type: CLOSE_CREATE_SERVICE_REQUEST_DETAILS
    });
  };
}

export const CREATE_DATA_SOURCE_DETAILS_PENDING =
  "CREATE_DATA_SOURCE_DETAILS_PENDING";
export const CREATE_DATA_SOURCE_DETAILS_SUCCESS =
  "CREATE_DATA_SOURCE_DETAILS_SUCCESS";
export const CREATE_DATA_SOURCE_DETAILS_ERROR =
  "CREATE_DATA_SOURCE_DETAILS_ERROR";

export function createDataSource(dataSource) {
  return function(dispatch, getState) {
    dispatch({
      type: CREATE_DATA_SOURCE_DETAILS_PENDING,
      dataSource
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/datasource/upload-tickets`;

    const params = {
      sourceName: dataSource.sourceName,
      description: dataSource.description
    };

    // const url = 'http://localhost:8383/ticket-management/api/v1/datasource/upload-tickets';
    const formData = new FormData();
    formData.append("file", dataSource.files[0]);
    formData.append("sourceName", dataSource.sourceName);
    formData.append("description", dataSource.description);

    return axiosSnackbar
      .post(url, formData, { params })
      .then(res => {
        dispatch({
          type: CREATE_DATA_SOURCE_DETAILS_SUCCESS
        });
        dispatch(retrieveDataSourceList());
      })
      .catch(err => {
        dispatch({
          type: CREATE_DATA_SOURCE_DETAILS_ERROR
        });
      });
  };
}
