import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import AttachmentList from "./AttachmentList";
import AttachmentUploader from "./AttachmentUploader";

const FieldLabel = styled.div`
  padding: 0px;
  margin-top: 15px;
  font-size: 12px;
`;

const AttachmentField = props => {
  const {
    ticket,
    token,
    isSnackbarOpen,
    openSnackbar,
    closeSnackbar,
    downloadAttachments
  } = props;
  const [attachments, setAttachments] = useState(ticket.attachmentData);

  // reload ticket when successful
  const onUploadSuccess = useCallback(
    async newAttachments => {
      if (newAttachments) {
        setAttachments(newAttachments);
      }
    },
    [setAttachments]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <FieldLabel>Attachments</FieldLabel>
      </Grid>
      <Grid item xs={12}>
        <AttachmentList
          ticketNumber={ticket.coreData.number}
          attachments={attachments}
          downloadAttachments={downloadAttachments}
        />
        <AttachmentUploader
          token={token}
          ticketNumber={ticket.coreData.number}
          isSnackbarOpen={isSnackbarOpen}
          openSnackbar={openSnackbar}
          closeSnackbar={closeSnackbar}
          onUploadSuccess={onUploadSuccess}
        />
      </Grid>
    </Grid>
  );
};

AttachmentField.propTypes = {
  ticket: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  isSnackbarOpen: PropTypes.bool,
  openSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func
};

export default AttachmentField;
