import { connect } from "react-redux";
import DataSourceTableComponent from "../../components/settings/configuration/DataSourceTable";
import { closeBundles, openBundles } from "../../actions/bundles";
import { closeSnackbar, openSnackbar } from "../../actions/snackbar";
import {
  retrieveDataSourceList,
  openDetails,
  closeDetails,
  createDataSource,
  purgeElasticSearch
} from "../../actions/dataSource";

const mapStateToProps = state => {
  let snowlist = [];

  //TODO: move this logic to redux actions
  state.ticketConfig.services.forEach((item, index) => {
    if (item.url === "servicenow") {
      snowlist = item.list;
    }
  });

  return {
    isBundlesOpen: state.bundles.isOpen,
    isSnackbarOpen: state.snackbar.isOpen,

    dataSource: state.dataSource.dataSource,
    dataSources: state.dataSource.dataSources,
    isDetailsOpen: state.dataSource.isDetailsOpen,
    SNOWList: snowlist,
    permissions: state.user.permissions
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeBundles: () => dispatch(closeBundles()),
  openBundles: () => dispatch(openBundles()),
  closeSnackbar: () => dispatch(closeSnackbar()),
  openSnackbar: message => dispatch(openSnackbar(message)),

  retrieveDataSourceList: () => dispatch(retrieveDataSourceList()),
  openDetails: dataSource => dispatch(openDetails(dataSource)),
  closeDetails: () => dispatch(closeDetails()),

  createDataSource: dataSource => dispatch(createDataSource(dataSource)),
  purgeElasticSearch: () => dispatch(purgeElasticSearch())
});

const DataSourceTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataSourceTableComponent);

export default DataSourceTable;
