// Stores styles for Tickets.js
import themeMainUI from "../../styles/themeMainUI";

const Styles = {
  Tickets: {
    overflowY: "scroll",
    flexBasis: "200px"
  },
  TableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  },
  TableHeader1: {
    fontWeight: "bold",
    fontSize: "13px",
    textAlign: "center"
  },
  TableRowColumn: {
    fontSize: "13px"
  },
  TableRowColumnSelected: {
    // backgroundColor: '#FFFDE7',
    fontWeight: "bold"
  },
  BundleHeading: {
    padding: "0 16px",
    margin: "15px 0 0 0"
  },
  BundleListAvatar: {
    minWidth: "40px",
    justifyContent: "center"
  },
  BundleListItem: {
    fontSize: "13px",
    alignItems: "center"
  },
  BundleListItemCount: {
    color: themeMainUI.palette.accent3Color,
    fontSize: "13px",
    textAlign: "right",
    marginTop: 15
  }
};

export default Styles;
