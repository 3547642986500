import styled, { css } from "styled-components";

export const Row = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    props.index &&
    css`
      margin-top: 40px;
    `};
`;

export const NodeContainerDiv = styled.div`
  width: 120px
  min-height: 40px;
`;
