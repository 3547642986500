import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableHeader,
  TableRowColumn
} from "material-ui";
import MenuItem from "material-ui/MenuItem";
import IconMenu from "material-ui/IconMenu";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
//@material-ui
import Chip from "@material-ui/core/Chip";

import SecondaryCircularProgress from "../templates/SecondaryCircularProgress";
import RulesStyle from "../rules/RulesStyles";
import ATRPaginator from "../templates/ATRPaginator";
import automaionPlatformDisplayName from "../utils";
export default class MappingTable extends React.Component {
  render() {
    return (
      <div>
        {this.props.isFetchingMappings ? (
          <div
            style={{
              minHeight: 568,
              width: "100%",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <SecondaryCircularProgress />
          </div>
        ) : (
          <div style={{ minHeight: 568 }}>
            <Table onCellClick={this.props.handleRowSelection}>
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                displayRowCheckbox={false}
              >
                <TableRow>
                  <TableHeaderColumn style={RulesStyle.TableHeader} width={120}>
                    Mapping Name
                  </TableHeaderColumn>
                  <TableHeaderColumn style={RulesStyle.TableHeader} width={100}>
                    {/* {userConfig.variables.ticketManagementDisplayName ||
                      "Ticket Management"} */}
                    Ticket Type
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs"
                    style={RulesStyle.TableHeader}
                    width={60}
                  >
                    {/* {userConfig.variables.knowledgebaseDisplayName ||
                      "Knowledge Base"} */}
                    Trigger States
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs hidden-sm"
                    style={RulesStyle.TableHeader}
                    width={60}
                  >
                    Automation Platform
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs hidden-sm hidden-md"
                    style={RulesStyle.TableHeader}
                    width={70}
                  >
                    Target Job Id
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    className="hidden-xs hidden-sm hidden-md"
                    style={RulesStyle.TableHeader}
                    width={5}
                  />
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false} deselectOnClickaway={false}>
                {this.props.mappings
                  ? Object.keys(this.props.mappings).map((mappingKey, i) => {
                      const mapping = this.props.mappings[mappingKey];
                      return (
                        <TableRow
                          selected={this.props.mappingSelected === mapping}
                          key={i}
                          style={{ cursor: "pointer" }}
                          data-testid="selectedMappingRow"
                        >
                          <TableRowColumn
                            style={RulesStyle.TableRowColumn}
                            width={120}
                          >
                            {mapping.mappingName}
                          </TableRowColumn>
                          <TableRowColumn
                            style={RulesStyle.TableRowColumn}
                            width={100}
                          >
                            {this.props.activeTemplates &&
                            this.props.activeTemplates[mapping.ticketType]
                              ? this.props.activeTemplates[mapping.ticketType]
                                  .name
                              : ""}
                          </TableRowColumn>
                          <TableRowColumn
                            style={RulesStyle.TableRowColumn}
                            width={60}
                          >
                            {mapping.triggerConditions.triggerStates &&
                              mapping.triggerConditions.triggerStates.map(
                                triggerState => (
                                  <Chip
                                    key={triggerState}
                                    label={triggerState}
                                  />
                                )
                              )}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm"
                            style={RulesStyle.TableRowColumn}
                            width={60}
                          >
                            {automaionPlatformDisplayName(
                              mapping.targetServiceName //Updated AQE reference to WFM. Link to ticket number- 'https://acnaop.atlassian.net/browse/ATRV3-10890'
                            )}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm hidden-md"
                            style={RulesStyle.TableRowColumn}
                            width={70}
                          >
                            {mapping.targetJobName}
                          </TableRowColumn>
                          <TableRowColumn
                            className="hidden-xs hidden-sm"
                            width={5}
                          >
                            <div
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <IconMenu
                                iconButtonElement={
                                  <IconButton
                                    iconStyle={{
                                      color: "rgb(189,189,189)",
                                      fontSize: 20
                                    }}
                                  >
                                    <FontIcon
                                      className="fa fa-ellipsis-v"
                                      style={{
                                        fontSize: 14,
                                        color: "red"
                                      }}
                                    />
                                  </IconButton>
                                }
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "top"
                                }}
                                targetOrigin={{
                                  horizontal: "left",
                                  vertical: "top"
                                }}
                              >
                                <MenuItem
                                  primaryText="Delete"
                                  onClick={() => {
                                    this.props.deleteMapping(
                                      mapping,
                                      this.props.currentPage
                                    );
                                  }}
                                />
                              </IconMenu>
                            </div>
                          </TableRowColumn>
                        </TableRow>
                      );
                    })
                  : ""}
              </TableBody>
            </Table>
          </div>
        )}
        {this.props.lastPage && (
          <ATRPaginator
            changePage={this.props.changePage}
            currentPage={this.props.currentPage + 1}
            lastPage={this.props.lastPage}
          />
        )}
      </div>
    );
  }
}

MappingTable.propTypes = {
  mappings: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleRowSelection: PropTypes.func.isRequired,
  mappingSelected: PropTypes.object.isRequired,
  isFetchingMappings: PropTypes.bool.isRequired,
  changePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired
  // lastPage: PropTypes.number.isRequired
};
