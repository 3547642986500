import { connect } from "react-redux";
import {
  getEmailTemplates,
  getKnowledgebaseArticles,
  getMergeTags,
  sendEmail,
  toggleEmailDialog
} from "../../actions/emailTemplates";
import EmailDialog from "../../components/emailTemplates/EmailDialog";

const mapStateToProps = (state, ownProps) => ({
  // Email Templates props
  isFetchingEmailTemplates: state.email.isFetchingEmailTemplates,
  isSendingEmail: state.email.isSendingEmail,
  emailTemplates: state.email.emailTemplates,
  selectedEmailTemplate: state.email.selectedEmailTemplate,

  // Merge Tag props
  isFetchingMergeTags: state.email.isFetchingMergeTags,
  mergeTags: state.email.mergeTags,

  // Email Dialog props
  isOpenEmailDialog: state.email.isOpenEmailDialog,

  // Ticket Details
  ticket: state.details.ticketNumber,

  // Knowledgebase
  articles: state.email.articles,

  // Ticket Template Props
  selectedTicketType: state.details.ticketNumber.coreData.type,

  //All permissions of user
  permissions: state.user.permissions
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  // Email Template actions
  getEmailTemplates: (page, perPage) =>
    dispatch(getEmailTemplates(page, perPage)),
  sendEmail: email => dispatch(sendEmail(email)),

  // Merge tag actions
  getMergeTags: (page, perPage) => dispatch(getMergeTags(page, perPage)),

  // Email Dialog actions
  toggleEmailDialog: () => dispatch(toggleEmailDialog()),

  // Get articles action
  getKnowledgebaseArticles: () => dispatch(getKnowledgebaseArticles())
});

const EmailDialogPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailDialog);

export default EmailDialogPage;
