import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core";
import moment from "moment";

import TicketTemplateTableActionCTN from "./Action/ActionCTN";
import history from "../../../../history";

const styles = {
  body: {
    cursor: "pointer"
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  },
  activeDot: {
    height: "14px",
    width: "14px",
    backgroundColor: "rgb(5, 193, 175)",
    margin: "12px",
    borderRadius: "50%"
  },
  inactiveDot: {
    height: "14px",
    width: "14px",
    backgroundColor: "transparent",
    border: "1px solid grey",
    margin: "12px",
    borderRadius: "50%"
  }
};

const TicketTemplateTableBody = props => {
  const { classes, templates } = props;
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  return (
    <>
      <TableBody className={classes.body}>
        {templates
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((item, i) => (
            <TableRow
              key={i}
              hover
              onClick={event => {
                if (event.target.id !== "long-menu")
                  history.push(`/atr/settings/ticket-template/${item.id}`);
              }}
            >
              <TableCell>
                {item.active ? (
                  <div className={classes.activeDot} />
                ) : (
                  <div className={classes.inactiveDot} />
                )}
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.ticketType}</TableCell>
              <TableCell>{moment(item.created).fromNow()}</TableCell>
              <TableCell>
                <TicketTemplateTableActionCTN
                  name={item.name}
                  id={item.id}
                  active={item.active}
                />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            page={page}
            rowsPerPageOptions={[rowsPerPage]}
            count={templates.length}
            rowsPerPage={rowsPerPage}
            onChangePage={(event, page) => setPage(page)}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default withStyles(styles)(TicketTemplateTableBody);

TicketTemplateTableBody.defaultProps = {
  templates: []
};
