import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Collapse from "@material-ui/core/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ParentButtonContainer = styled(ButtonBase)`
  display: flex !important;
  padding: 15px 20px !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 5px !important;
  width: 100%;
  max-width: 900px;
  min-height: 40px;
  text-align: left;
  border: 0.5px solid #bababa !important;
  &:hover {
    border: 0.5px solid #378bee !important;
  }
  color: #727272 !important;
  box-shadow: 0px 4px 10px rgba(176, 176, 176, 0.19);
  margin-bottom: 5px !important;

  @media screen and (max-width: 992px) {
    padding: 7px 12px !important;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    padding: 10px 15px !important;
  }

  @media screen and (max-height: 768px) {
    padding: 7px 12px !important;
  }
`;

export const TitleContainer = styled(Grid)`
  color: #378bee;
`;

export const TitleText = styled.span`
  font-size: 1.2rem;
  font-family: Open Sans;
  font-weight: 600 !important;

  @media screen and (max-width: 992px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 1.1rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 1rem;
  }
`;

export const TitleIcon = styled(FontAwesomeIcon)`
  margin-right: 15px;
`;

export const SelectedText = styled.span`
  font-size: 11px;
  font-family: Open Sans;
  margin-right: 15px;
  margin-left: 9px;
`;

export const SelectedIcon = styled(FontAwesomeIcon)`
  margin-right: 13px;
`;

export const UseCasePanelContainer = styled(Grid)`
  margin: 0px 0px 5px 25px !important;
`;

export const StyledCollapse = styled(Collapse)`
  margin: 10px 0px;

  @media screen and (max-width: 992px) {
    margin: 5px 0px;
  }

  @media screen and (max-height: 768px) {
    margin: 5px 0px;
  }
`;
