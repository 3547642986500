import React from "react";
import { List, ListItem } from "material-ui/List";
import Divider from "material-ui/Divider";
import SecondaryButton from "../templates/SecondaryButton";
import config from "../../config";

export default class GamificationDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);

    this.state = {
      width: 0,
      height: 0,
      value: "",
      apps: [
        {
          stat: "Total tickets",
          value: 0
        },
        {
          stat: "Tickets Today",
          value: 0
        },
        {
          stat: "Time Elapsed",
          value: 0
        },
        {
          stat: "Average Handling Time",
          value: 0
        }
      ]
    };
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight - 100
    });
  }

  UNSAFE_componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  UNSAFE_componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillMount() {
    this.updateDimensions();
    // this.gamificationData("totalTickets");
    // this.gamificationData("totalTicketsDoneToday");
    // this.gamificationData("timeElapsed");
    // this.gamificationData("averageHandlingTime");
  }

  // gamificationData(stat) {
  //   const url = `${
  //     config.urls.base
  //   }/gamification/api/v1/gamification/gamificationdata?userName=${
  //     this.props.username
  //   }`;
  //   const options = {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       apiToken: JSON.parse(localStorage.jwt).token
  //     }
  //   };

  //   const gamificationData = fetch(url, options)
  //     .then(response => response.json())
  //     .then(promiseResolve => Promise.resolve(promiseResolve))
  //     .then(result => console.log(`${stat}: ${result[stat]}`));
  // }

  render() {
    const cards = this.state.apps;
    return (
      <div>
        <img
          src={`${config.app.images}/gamification_placeholder.png`}
          style={{ width: "100%" }}
          alt=""
        />

        <div style={{ display: "none" }}>
          {cards.length > 0 ? (
            <div>
              <List>
                {cards.map((item, i) => (
                  <ListItem
                    style={{ fontSize: 12 }}
                    key={`${i}_gamification`}
                    disabled
                    primaryText={
                      <div>
                        {item.stat}: {item.value}
                      </div>
                    }
                  />
                ))}
              </List>
              {this.props.redirectToGamificationPage ? (
                <div>
                  <Divider />
                  <SecondaryButton
                    labelStyle={{
                      textTransform: "none",
                      fontWeight: "600",
                      fontSize: 12
                    }}
                    fullWidth
                    onClick={() => this.props.redirectToGamificationPage()}
                    label="See my performance"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            "<div><h3>Cannot retrieve stats</h3></div>"
          )}
        </div>
      </div>
    );
  }
}
