import React from "react";
import { Col } from "react-flexbox-grid/lib";
import { PropertiesField } from "./PropertiesField";
import AttachmentsField from "./AttachmentField/container";
import TicketDetailsTemplates from "../TicketDetailsDrawerTemplates";

const localStyles = {
  propsContainer: {
    padding: "20px 5px",
    overflow: "hidden"
  },
  propContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    paddingBottom: "5px",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  propLabel: {
    padding: "0",
    marginTop: "15px",
    fontSize: "12px"
  },
  propField: {
    padding: "0px"
  }
};

const PropertiesTab = props => {
  const {
    ticket,
    fields,
    mlObj,
    mlTemplateMapping,
    drawerFunctions,
    handleTicketUpdate,
    ticketType
  } = props;

  return (
    <div>
      <div style={localStyles.propsContainer}>
        {fields.properties.map(item => (
          <div key={item.name} style={localStyles.propContainer}>
            <Col xs={2} style={localStyles.propLabel}>
              {item.mandatory ? item.displayName + "*" : item.displayName}
            </Col>
            <Col xs={8} style={localStyles.propField}>
              <PropertiesField
                item={item}
                ticket={ticket}
                mlObj={mlObj}
                mlTemplateMapping={mlTemplateMapping}
                drawerFunctions={drawerFunctions}
                handleTicketUpdate={handleTicketUpdate}
              />
            </Col>
          </div>
        ))}
        {ticketType === "INCIDENT" && (
          <>
            <TicketDetailsTemplates
              {...props}
              ticketEdit={handleTicketUpdate}
            />
            <AttachmentsField ticket={ticket} />
          </>
        )}
      </div>
    </div>
  );
};

export default PropertiesTab;
