import React from "react";
import { connect } from "react-redux";
import { ticketFieldAutocomplete } from "../../../actions/tickets";
import PropertiesTab from "./PropertiesTab";

const PropertiesTabContainer = props => <PropertiesTab {...props} />;

const mapStateToProps = (state, ownProps) => ({
  templateList: state.templates.templateList,
  ticketType: state.tickets.selectedTicketType
});

const mapDispatchToProps = dispatch => ({
  ticketFieldAutocomplete: (field, value, ticket = {}) =>
    dispatch(ticketFieldAutocomplete(field, value, ticket))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesTabContainer);
