import { connect } from "react-redux";
import PluginDrawerTicketSource from "./TicketSource";
import {
  submitTicketSource,
  updateTicketSource,
  cancelTicketSource
} from "redux/modules/plugin";

const mapStateToProps = (state, ownProps) => ({
  pluginTicketSources: state.plugin.currentPlugin.ticketSources,
  isLoading: state.plugin.isLoading
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitTicketSource: ({ ticketType, idx }) =>
    dispatch(submitTicketSource({ ticketType, idx })),
  updateTicketSource: ({ idx, field, value }) =>
    dispatch(updateTicketSource({ idx, field, value })),
  cancelTicketSource: ({ ticketType, idx }) =>
    dispatch(cancelTicketSource({ ticketType, idx }))
});

const PluginDrawerTicketSourceCTN = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginDrawerTicketSource);

export default PluginDrawerTicketSourceCTN;
