import React from "react";
import TableCell from "@material-ui/core/TableCell"; //replaces TableRowColumn & TableHeaderColumn
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core";

import TicketTableHeaderFilter from "./Filter/Filter";
import TicketTableHeaderUpdatedDate from "./UpdatedDate/UpdatedDate";
const styles = {
  TableHeader: {
    fontWeight: "bold",
    fontSize: "13px"
  },
  TableHeader1: {
    fontWeight: "bold",
    fontSize: "13px",
    textAlign: "center"
  }
};

const TicketTableHeader = props => {
  //date sortin by DESC or ASC
  const {
    ticketSetSort,
    ticketSetFilter,
    moveTicketCachePointer,
    updateBulkActionTickets
  } = props;

  const {
    activeTemplates,
    cacheLocalPointer,
    cacheObject,
    ticketFilter,
    selectedTicketType,
    presetSelected,
    ticketSort,
    customizedTemplateColumn,
    selectedTickets
  } = props;

  const sortTickets = field => {
    const sortBy = ticketSort.sortDirection === "DESC" ? "ASC" : "DESC";

    ticketSetSort({
      sortField: field,
      sortDirection: sortBy
    });
    //    ticketSetFilter(null);
    moveTicketCachePointer(
      0,
      cacheLocalPointer,
      cacheObject,
      {
        sortField: field,
        sortDirection: sortBy
      },
      ticketFilter,
      selectedTicketType,
      null,
      presetSelected
    );
  };

  const handleMenuSelect = (filter, name = null) => {
    let newFilter = { ...ticketFilter };
    newFilter[filter] = name;
    ticketSetFilter(newFilter);
    moveTicketCachePointer(
      0,
      cacheLocalPointer,
      cacheObject,
      ticketSort,
      { ...ticketFilter, ...newFilter },
      selectedTicketType,
      null,
      presetSelected
    );
  };

  const handleClearAllClick = () => {
    updateBulkActionTickets([]);
  };

  return (
    <TableHead>
      <TableRow data-cy="table-header">
        {activeTemplates &&
          activeTemplates[selectedTicketType]?.ticketSource.canAutomate && (
            <TableCell padding="checkbox" key={0}>
              {selectedTickets.length > 0 ? (
                <Checkbox
                  defaultChecked
                  indeterminate
                  color="primary"
                  onClick={() => handleClearAllClick()}
                />
              ) : (
                <></>
              )}
            </TableCell>
          )}
        {customizedTemplateColumn.map((column, index) => {
          if (
            column.name === "state" ||
            (column.name === "assignee" && column.displayName !== "Due Date")
          ) {
            return (
              <TableCell key={index + 1}>
                <TicketTableHeaderFilter
                  filter={column.name}
                  displayName={column.displayName}
                  activeTemplates={activeTemplates}
                  selectedTicketType={selectedTicketType}
                  handleMenuSelect={handleMenuSelect}
                />
              </TableCell>
            );
          } else if (column.name === "lastUpdateDate") {
            return (
              <TableCell key={index + 1}>
                <TicketTableHeaderUpdatedDate
                  displayName={column.displayName}
                  sortTickets={sortTickets}
                  sortDirection={ticketSort.sortDirection}
                />
              </TableCell>
            );
          } else {
            return <TableCell key={index + 1}>{column.displayName}</TableCell>;
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(TicketTableHeader);
