import { call, put, takeLatest, select, all } from "redux-saga/effects";
import { openSnackbar } from "actions/snackbar";
import history from "../../history";
import {
  ACTIVATE_TICKET_TEMPLATE,
  DEACTIVATE_TICKET_TEMPLATE,
  DELETE_TICKET_TEMPLATE,
  CREATE_TICKET_TEMPLATE,
  GET_TEMPLATE_FIELDS,
  UPDATE_TICKET_TEMPLATE,
  GET_ALL_TICKET_TEMPLATES,
  GET_ML_MAPPING_FIELDS,
  GET_TEMPLATES_MAPPING_FIELDS
} from "../modules/ticketTemplate";
import {
  changeRoute,
  activateTemplatePending,
  activateTemplateSuccess,
  activateTemplateError,
  deactivateTemplatePending,
  deactivateTemplateSuccess,
  deactivateTemplateError,
  deleteTemplatePending,
  deleteTemplateSuccess,
  deleteTemplateError,
  createTemplatePending,
  createTemplateSuccess,
  createTemplateError,
  getTemplateFieldsPending,
  getTemplateFieldsSuccess,
  getTemplateFieldsError,
  updateTicketTemplatePending,
  updateTicketTemplateSuccess,
  updateTicketTemplateError,
  getAllTicketTemplatesPending,
  getAllTicketTemplatesSuccess,
  getAllTicketTemplatesError,
  getMLMappingFieldsPending,
  getMLMappingFieldsSuccess,
  getMLMappingFieldsError,
  initializeCurrentTemplate
} from "../modules/ticketTemplate";
import config from "../../config";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../../utils/axios";

export const ticketTemplateSagas = [
  takeLatest(ACTIVATE_TICKET_TEMPLATE, activateTicketTemplateSaga),
  takeLatest(DEACTIVATE_TICKET_TEMPLATE, deactivateTicketTemplateSaga),
  takeLatest(DELETE_TICKET_TEMPLATE, deleteTicketTemplateSaga),
  takeLatest(CREATE_TICKET_TEMPLATE, createTicketTemplateSaga),
  takeLatest(UPDATE_TICKET_TEMPLATE, updateTicketTemplateSaga),
  takeLatest(GET_ALL_TICKET_TEMPLATES, getAllTicketTemplateSaga),
  takeLatest(GET_TEMPLATE_FIELDS, getTemplateFieldsSaga),
  takeLatest(GET_ML_MAPPING_FIELDS, getMLMappingFieldsSaga),
  takeLatest(GET_TEMPLATES_MAPPING_FIELDS, getTemplateMappingFieldsSaga)
];

export const activateTicketTemplate = id => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/templates/activate/${id}`;
  return axiosSnackbar.put(url, id);
};

export const deactivateTicketTemplate = id => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/templates/deactivate/${id}`;
  return axiosSnackbar.put(url, id);
};

export const deleteTicketTemplate = id => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/templates/${id}`;
  return axiosSnackbar.delete(url);
};

export const createTicketTemplate = data => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/templates`;
  return axiosSnackbar.post(url, data);
};

const updateTicketTemplate = data => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/templates`;
  return axiosSnackbar.put(url, data);
};

const getAllTicketTemplates = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/templates`;
  return axios.get(url);
};

const getTemplateFields = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/fields/templates`;
  return axios.get(url);
};
const getMLMappingFields = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/fields/mapping`;
  return axios.get(url);
};

export function* getTemplateMappingFieldsSaga() {
  const {
    ticketTemplates: { route }
  } = yield select();
  yield all([
    call(getMLMappingFieldsSaga),
    call(getAllTicketTemplateSaga),
    call(getTemplateFieldsSaga)
  ]);

  yield put(initializeCurrentTemplate(route));
}

export function* activateTicketTemplateSaga({ id }) {
  yield put(activateTemplatePending());

  try {
    const { data } = yield call(activateTicketTemplate, id);
    yield put(activateTemplateSuccess(id));
    yield put(openSnackbar("Successfully Activate the Template"));
  } catch (err) {
    yield put(activateTemplateError());
  }
}

export function* deactivateTicketTemplateSaga({ id }) {
  yield put(deactivateTemplatePending());

  try {
    const { data } = yield call(deactivateTicketTemplate, id);
    yield put(deactivateTemplateSuccess(id));
    yield put(openSnackbar("Successfully Deactivate the Template"));
  } catch (err) {
    yield put(deactivateTemplateError());
  }
}

export function* deleteTicketTemplateSaga({ name }) {
  yield put(deleteTemplatePending());
  try {
    const { data } = yield call(deleteTicketTemplate, name);
    yield put(deleteTemplateSuccess(name));
    yield put(openSnackbar("Successfully Delete the Template"));
  } catch (err) {
    yield put(deleteTemplateError());
  }
}

export function* createTicketTemplateSaga() {
  const {
    ticketTemplates: {
      currentTemplate: {
        mapping,
        name,
        primaryFields,
        secondaryFields,
        ticketType
      }
    }
  } = yield select();

  yield put(createTemplatePending());
  const newTemplate = {
    mapping,
    name,
    primaryFields,
    secondaryFields,
    ticketType
  };

  try {
    const { data } = yield call(createTicketTemplate, newTemplate);
    yield put(createTemplateSuccess());
    yield put(openSnackbar("Successfully Create New Template"));
    yield put(changeRoute(""));
    history.replace("/atr/settings/ticket-template");
  } catch (err) {
    yield put(createTemplateError());
  }
}

export function* updateTicketTemplateSaga() {
  const {
    ticketTemplates: { currentTemplate }
  } = yield select();
  yield put(updateTicketTemplatePending());
  try {
    const { data } = yield call(updateTicketTemplate, currentTemplate);
    yield put(updateTicketTemplateSuccess());
    yield put(openSnackbar("Successfully Update Template"));
    yield put(changeRoute(""));
    history.replace("/atr/settings/ticket-template");
  } catch (err) {
    yield put(updateTicketTemplateError());
  }
}

export function* getAllTicketTemplateSaga() {
  yield put(getAllTicketTemplatesPending());
  try {
    const { data } = yield call(getAllTicketTemplates);
    yield put(getAllTicketTemplatesSuccess(data));
  } catch (err) {
    yield put(getAllTicketTemplatesError());
  }
}

export function* getTemplateFieldsSaga() {
  yield put(getTemplateFieldsPending());

  try {
    const { data } = yield call(getTemplateFields);
    yield put(getTemplateFieldsSuccess(data));
  } catch (err) {
    yield put(getTemplateFieldsError());
  }
}

export function* getMLMappingFieldsSaga() {
  yield put(getMLMappingFieldsPending());
  try {
    const { data } = yield call(getMLMappingFields);
    yield put(getMLMappingFieldsSuccess(data));
  } catch (err) {
    yield put(getMLMappingFieldsError());
  }
}
