import React from "react";
import PropTypes from "prop-types";
import { List } from "@material-ui/core";
import AttachmentItem from "./AttachmentItem";
import download from "downloadjs";

const AttachmentList = props => {
  const { ticketNumber, attachments, downloadAttachments } = props;
  const clickAttachment = async (
    ticketNumber,
    attachmentId,
    attachmentName
  ) => {
    const downloadFile = await downloadAttachments(ticketNumber, attachmentId);
    download(downloadFile, attachmentName);
  };

  return (
    <List component="nav" aria-label="main">
      {attachments && attachments.length > 0 ? (
        attachments.map(attachment => (
          <AttachmentItem
            key={attachment.attachmentId || attachment.uniqueIdentifier}
            label={attachment.name}
            onClick={() =>
              clickAttachment(
                ticketNumber,
                attachment.attachmentId,
                attachment.name
              )
            }
          />
        ))
      ) : (
        <AttachmentItem label="No attachment" isPlaceholder />
      )}
    </List>
  );
};

AttachmentList.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object),
  ticketNumber: PropTypes.string
};

AttachmentList.defaultProps = {
  attachments: []
};

export default AttachmentList;
