import React from "react";
import { SelectField } from "material-ui";
import themeMainUI from "../../styles/themeMainUI";

class SecondaryDropDownMenu extends React.Component {
  render() {
    const { primary2Color, disabledColor } = themeMainUI.palette;
    let color;
    if (this.props.disabled) {
      color = disabledColor;
    } else {
      color = primary2Color;
    }
    return (
      <SelectField
        dropDownMenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          labelStyle: {
            color: { color },
            fontSize: 16,
            fontWeight: "normal",
            top: -5,
            paddingLeft: 10
          }
        }}
        {...this.props}
      />
    );
  }
}

export default SecondaryDropDownMenu;
