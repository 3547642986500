import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/lab/Slider";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11
  },

  sliderColor: {
    backgroundColor: "rgb(151, 201, 209)"
  }
});

const WeightingSlider = ({
  isDisabled,
  weighting,
  onSliderChange,
  classes
}) => {
  return (
    <Tooltip
      title={"Weighting: " + weighting}
      classes={{ tooltip: classes.lightTooltip }}
      placement="top"
    >
      <Slider
        disabled={isDisabled}
        value={weighting}
        min={0}
        max={10}
        step={1}
        onChange={onSliderChange}
        classes={{ track: classes.sliderColor, thumb: classes.sliderColor }}
      />
    </Tooltip>
  );
};

WeightingSlider.prototype = {
  classes: PropTypes.object.isRequired,
  weighting: PropTypes.number.isRequired,
  onSliderChange: PropTypes.func.isRequired
};

export default withStyles(styles)(WeightingSlider);
