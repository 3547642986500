/**
 * Created by aharon.rossano on 17/5/17.
 */
import React from "react";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import {BottomNavigation, BottomNavigationItem} from 'material-ui/BottomNavigation';
// import muiTheme from './Colors';
import { Row, Col } from "react-flexbox-grid/lib";
import PrimaryDropDownMenu from "../templates/PrimaryDropDownMenu";
import TriggerConditions from "../automation/TriggerConditions";

import PrimaryButton from "../templates/PrimaryButton";

import RulesStyles from "../rules/RulesStyles";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import SecondaryDropDownMenu from "../templates/SecondaryDropDownMenu";
import PrimaryToggle from "../templates/PrimaryToggle";
import SecondaryTextField from "../templates/SecondaryTextField";
import IconMenu from "material-ui/IconMenu";
import Paper from "material-ui/Paper";
import MenuItem from "material-ui/MenuItem";
import AutoComplete from "material-ui/AutoComplete";
import update from "immutability-helper";

import config from "../../config";
import _ from "lodash";
import { axiosInstance as axios } from "../../utils/axios";

const REQUIRED = {
  name: false,
  approver: false,
  author: false,
  mappingId: false
  // triggerStates: false
};

export default class RuleDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.props.getActiveTemplates();
    this.props.getMlConfig();

    this._handleClose = this._handleClose.bind(this);
    // this.props.openRules();

    this._saveRule = this._saveRule.bind(this);
    this._validateRule = this._validateRule.bind(this);
    this._renderTextField = this._renderTextField.bind(this);
    // this._renderStatic = this._renderStatic.bind(this);
    this._renderToggle = this._renderToggle.bind(this);
    this._renderDropDownTicketManagement = this._renderDropDownTicketManagement.bind(
      this
    );
    this._renderDropDownAutomation = this._renderDropDownAutomation.bind(this);
    this._renderAutocompleteUserField = this._renderAutocompleteUserField.bind(
      this
    );

    this._handleUpdateInputUserAutocomplete = this._handleUpdateInputUserAutocomplete.bind(
      this
    );
    this._handleUpdateInputGroupAutocomplete = this._handleUpdateInputGroupAutocomplete.bind(
      this
    );
    this._renderAutocompleteGroupsField = this._renderAutocompleteGroupsField.bind(
      this
    );
    // this._renderTicketStates = this._renderTicketStates.bind(this);
    this.props.openAllMappings();

    this.state = {
      rules: {},
      rulesRender: {
        confidenceThreshold: this._renderSliderField,
        name: this._renderTextField,
        // description: this._renderTextField,
        workInstruction: this._renderStatic,
        ticketManagement: this._renderStatic,
        mappingId: this._renderDropDownAutomation,
        assignmentGroup: this._renderAutocompleteGroupsField,
        author: this._renderAutocompleteUserField,
        approver: this._renderAutocompleteUserField,
        enabled: this._renderToggle
      },
      statesChosen: [],
      ticketStates: [],
      dataSourceUsers: [],
      dataSourceGroups: [],
      mappingSelected: {},
      ruleSelected: {},
      fields: [],
      required: { ...REQUIRED },
      predictableFields: [],
      activeTemplate: {}
    };
  }
  componentWillUnmount = () => {
    this.props.openMapping({});
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //used for creating new rule
    if (nextProps.mappingSelected !== this.state.mappingSelected) {
      this.setState({
        mappingSelected: nextProps.mappingSelected
      });
      const ticketType = nextProps.mappingSelected
        ? nextProps.mappingSelected.ticketType
        : null;
      this.setState({
        ticketStates:
          ticketType && this.props.activeTemplates[ticketType]
            ? this.props.activeTemplates[ticketType].states
            : [],
        statesChosen: [],
        activeTemplate: this.props.activeTemplates[ticketType]
      });
      if (ticketType && this.props.activeTemplates[ticketType]) {
        const {
          primaryFields = [],
          secondaryFields = []
        } = this.props.activeTemplates[ticketType];
        const fields = [...primaryFields, ...secondaryFields];
        this.setState({ fields });
      } else {
        this.setState({ fields: [] });
      }
    }
    if (nextProps.ruleSelected !== this.state.ruleSelected) {
      this.setState({
        ruleSelected: nextProps.ruleSelected,
        statesChosen:
          nextProps.ruleSelected && nextProps.ruleSelected.triggerStates
            ? nextProps.ruleSelected.triggerStates
            : []
      });
      if (
        nextProps.ruleSelected &&
        nextProps.ruleSelected.id &&
        nextProps.ruleSelected.automationMapping &&
        nextProps.ruleSelected.automationMapping.ticketType
      ) {
        const ticketType = nextProps.ruleSelected.automationMapping.ticketType;
        this.setState({
          ticketStates: this.props.activeTemplates[ticketType].states
        });
      }
      if (nextProps.availablePredictions) {
        this.setState({
          predictableFields: nextProps.availablePredictions
        });
      }
    }
  }

  _handleClose() {
    this.setState({
      required: REQUIRED,
      statesChosen: []
    });
    this.props.closeRule();
  }

  _renderTextField(rule, ruleKey) {
    // using math random for key because rulekey is not int and the page doesn't
    // loop through the elements to generate it's own unique index.
    return (
      <div
        style={RulesStyles.formContainer}
        // key={Math.random()}
      >
        <Col
          xs={2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px",
            marginLeft: ""
          }}
        >
          {ruleKey.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
            return str.toUpperCase();
          })}
        </Col>
        <Col xs={10}>
          <SecondaryTextField
            id="ruleName"
            value={rule || ""} //check for undefined rule
            hintText="Enter rule name here"
            requiredError={this.state.required[ruleKey]}
            fullWidth={true}
            onChange={(event, newValue) => {
              const ruleSelected = Object.assign({}, this.props.ruleSelected);
              ruleSelected[ruleKey] = newValue;
              this.props.openRule(ruleSelected);
            }}
          />
        </Col>
      </div>
    );
  }

  _renderToggle(rule, ruleKey) {
    // using math random for key because rulekey is not int and the page doesn't
    // loop through the elements to generate it's own unique index.
    return (
      <div
        style={RulesStyles.formContainer}
        // key={Math.random()}
      >
        <Col
          xs={2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {ruleKey.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
            return str.toUpperCase();
          })}
        </Col>
        <Col xs={10}>
          <PrimaryToggle
            id="ruleName"
            style={{
              padding: "0",
              marginTop: "15px"
            }}
            toggled={rule}
            onToggle={(event, isInputChecked) => {
              const ruleSelected = Object.assign({}, this.props.ruleSelected);
              ruleSelected[ruleKey] = isInputChecked;
              this.props.openRule(ruleSelected);
            }}
          />
        </Col>
      </div>
    );
  }

  _renderDropDownAutomation(rule, ruleKey) {
    // let ruleDropdown = [];
    // let mappingIds = this.props.mappings.find(mapping => {
    //   ruleDropdown.push(mapping.id);
    //   return; //rule === mapping.id;
    // });
    return (
      <div style={RulesStyles.formContainer} key={ruleKey}>
        <Col
          xs={2}
          style={{
            padding: "0",
            marginTop: "22px",
            fontSize: "12px"
          }}
        >
          Mapping Name
          {/* {ruleKey
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, function(str) {
              return str.toUpperCase();
            })
            .replace('Id', '')} */}
          {/*
         <div
         onClick={() => history.push('/mapping')}
         style={{
         color: 'grey',
         textDecoration: 'underline',
         cursor: 'pointer'
         }}>
         more</div>
         */}
        </Col>
        <Col xs={10} style={{ paddingLeft: 0 }}>
          <SecondaryDropDownMenu
            id="state"
            errorText={
              this.state.required[ruleKey] ? "This field is required." : ""
            }
            // value={ruleDropdown || []}
            autoWidth
            value={this.state.ruleSelected.mappingId}
            onChange={(event, key, value) => {
              // make the front end changes
              this.setState((state, props) =>
                update(state, { required: { mappingId: { $set: false } } })
              );
              let ruleSelected = Object.assign({}, this.props.ruleSelected); // this is empty
              ruleSelected[ruleKey] = value;
              // // make state changes & actions ONLY if valid. We use '' as an error catch
              if (value !== "") {
                //openMapping(mappingSelected - object param)
                let mappingSelected = this.props.mappings.find(mapping => {
                  if (value === mapping.id) {
                    ruleSelected.triggerConditions = _.cloneDeep(
                      mapping.triggerConditions //slready set
                    );
                    ruleSelected.mappingId = mapping.id; //already set
                    // ruleSelected.ticketType = mapping.ticketType;
                    this.props.openRule(ruleSelected);
                  }
                  return value === mapping.id; //outside of if statement
                });
                this.props.openMapping(mappingSelected);
              } else {
                const ruleSelected = update(this.state.ruleSelected, {
                  triggerConditions: { fields: { $set: [] } },
                  mappingId: { $set: "" }
                });

                this.setState({ ruleSelected });

                this.props.openMapping({});
                this.props.openRule(ruleSelected);
              }
            }}
          >
            <MenuItem key={""} value={""} primaryText="None selected" />
            <Divider />
            {Array.isArray(this.props.mappings)
              ? this.props.mappings.map(mapping => (
                  <MenuItem
                    key={mapping.id}
                    value={mapping.id}
                    primaryText={mapping.mappingName}
                  />
                ))
              : ""}
            {/* <Divider />
            <MenuItem
              key={'0'}
              value={'See all mappings...'}
              style={{ fontSize: 12 }}
              onClick={() => {
                // this.props.closeRule();
                // this.props.closeMap
                this.props.openAllMappings();
              }}
              primaryText="See all mappings..."
            /> */}
          </SecondaryDropDownMenu>
        </Col>
      </div>
    );
  }

  _renderDropDownTicketManagement(rule, ruleKey, options) {
    return (
      <div style={RulesStyles.formContainer} key={ruleKey}>
        <Col
          xs={2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {ruleKey.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
            return str.toUpperCase();
          })}
        </Col>
        <Col xs={10}>
          <PrimaryDropDownMenu
            id="state"
            value={rule.toLowerCase()}
            onChange={(event, key, value) => {
              const ruleSelected = Object.assign({}, this.props.ruleSelected);
              ruleSelected[ruleKey] = value;
              this.props.openRule(ruleSelected);
            }}
          >
            <MenuItem key={"rundeck"} value={"rundeck"} primaryText="Rundeck" />
            <MenuItem key={"ats"} value={"ats"} primaryText="ATS" />
            <MenuItem key={"athos"} value={"athos"} primaryText="ATHOS" />
          </PrimaryDropDownMenu>
        </Col>
      </div>
    );
  }

  // disabled because stopclick didn't work
  // _triggerRuleToggle(event, selected, rule) {
  //   const ruleSelected = rule;
  //   ruleSelected.enabled = selected;
  //   this.props.updateSingleRules(rule);
  // }

  _renderAutocompleteGroupsField = (rule, ruleKey) => {
    return (
      <div style={RulesStyles.formContainer} key={ruleKey}>
        <Col
          xs={2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {ruleKey.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
            return str.toUpperCase();
          })}
        </Col>
        <Col xs={10}>
          <AutoComplete
            floatingLabelText="Type assignment group name"
            errorText={
              this.state.required[ruleKey]
                ? "This field is required for full automation."
                : ""
            }
            // errorStyle={{ color: 'rgba(0, 0, 0, 0.3)' }}
            floatingLabelStyle={{ color: "rgba(0, 0, 0, 0.3)" }}
            dataSource={this.state.dataSourceGroups}
            fullWidth={true}
            // onNewRequest={this._handleNewRequestUserAutocomplete}
            onNewRequest={(str, i) => {
              const ruleSelected = Object.assign({}, this.props.ruleSelected);
              ruleSelected[ruleKey] = str;
              this.props.openRule(ruleSelected);
            }}
            // filter={AutoComplete.caseInsensitiveFilter}
            maxSearchResults={10}
            onUpdateInput={value => {
              this._handleUpdateInputGroupAutocomplete(value, ruleKey);
              const ruleSelected = Object.assign({}, this.props.ruleSelected);
              ruleSelected[ruleKey] = value;
              this.props.openRule(ruleSelected);
            }}
            searchText={this.state.ruleSelected[ruleKey]}
          />
        </Col>
      </div>
    );
  };

  _renderAutocompleteUserField = (rule, ruleKey) => {
    return (
      <div style={RulesStyles.formContainer} key={ruleKey}>
        <Col
          xs={2}
          style={{
            padding: "0",
            marginTop: "40px",
            fontSize: "12px"
          }}
        >
          {ruleKey.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
            return str.toUpperCase();
          })}
        </Col>
        <Col xs={10}>
          {
            //TODO: the below component is throwing a controlled/uncontrolled error - FIXME
          }
          <AutoComplete
            floatingLabelText={`Type ${ruleKey}'s username`}
            floatingLabelStyle={{ color: "rgba(0, 0, 0, 0.3)" }}
            errorText={
              this.state.required[ruleKey]
                ? "This field is required and must be valid for full automation."
                : ""
            }
            // errorStyle={{ color: 'rgba(0, 0, 0, 0.3)' }}
            dataSource={this.state.dataSourceUsers}
            onUpdateInput={value => {
              this._handleUpdateInputUserAutocomplete(value, ruleKey);
              let ruleSelected = _.cloneDeep(this.state.ruleSelected);
              ruleSelected[ruleKey] = value;
              this.setState({ ruleSelected });
            }}
            fullWidth={true}
            // onNewRequest={this._handleNewRequestUserAutocomplete}
            onNewRequest={(str, i) => {
              let ruleSelected = _.cloneDeep(this.state.ruleSelected);
              ruleSelected[ruleKey] = str;
              this.setState({ ruleSelected });
            }}
            // filter={AutoComplete.caseInsensitiveFilter}
            maxSearchResults={10}
            searchText={this.state.ruleSelected[ruleKey] || ""}
            style={{ zIndex: "0" }}
          />
        </Col>
      </div>
    );
  };

  _handleUpdateInputGroupAutocomplete = (value, ruleKey) => {
    let ruleSelected = _.cloneDeep(this.state.ruleSelected);
    ruleSelected[ruleKey] = value;
    this.setState({ ruleSelected });
  };

  _handleUpdateInputUserAutocomplete = (value, ruleKey) => {
    // const url = config.urls.base + config.urls.apis['search-engine'] + '/tickets/autocomplete?field=number&value=' + value
    let ruleSelected = { ...this.state.ruleSelected };
    ruleSelected[ruleKey] = value;
    this.setState({ ruleSelected });
    const url =
      config.urls.base +
      config.urls.apis["identity-management"] +
      "/users/search";

    const params = {
      q: value
    };

    axios
      .get(url, { params })
      .then(res => {
        let dataSourceUsers = [];
        res.data.forEach(i => {
          dataSourceUsers.push(i.username);
        });
        this.setState({ dataSourceUsers });
      })
      .catch(err => {
        this.setState({ dataSourceAuthor: [] });
      });
  };

  deleteEmptyFields = ruleSelected => {
    return update(ruleSelected, {
      triggerConditions: {
        fields: {
          $set: ruleSelected.triggerConditions.fields.filter(
            field => field.name
          )
        }
      }
    });
  };

  _saveRule() {
    if (!this._validateRule(this.state.ruleSelected)) {
      return;
    }

    const rule = this.deleteEmptyFields(this.state.ruleSelected);
    if (!this.state.ruleSelected.id) {
      // console.log(this.state.statesChosen);

      let newRule = this.props
        .createRule(
          rule,
          // this.state.statesChosen,
          this.props.currentPage
        )
        .then(function(response) {
          return response;
        })
        .then(function(response) {
          return Promise.resolve(response);
        });
      newRule.then(response => {
        if (response) {
          this.props.openRule(response);
        }
      });
      //   .then(function(rule) {
      //     console.log('r2', rule);
      //     this.props.openRule(rule);
      //   });
      // console.log('rulee', newRule)
    } else this.props.updateSingleRules(rule, this.props.currentPage);

    this.setState({ required: REQUIRED });
    //
    //CLOSE THE DRAWER ALSO??
    // this._handleClose();
  }

  _validateRule(ruleSelected) {
    let required = { ...REQUIRED };

    if (ruleSelected.name === "") {
      required["name"] = true;
    }

    if (ruleSelected.mappingId === "") {
      required["mappingId"] = true;
    }

    if (ruleSelected.approver === "") {
      required["approver"] = true;
    }

    // if (ruleSelected.assignmentGroup === "") {
    //   required["assignmentGroup"] = true;
    // }

    if (!ruleSelected.author || ruleSelected.author === "") {
      required["author"] = true;
    }

    // if (!ruleSelected.triggerConditions.triggerStates.length) {
    //   required["triggerStates"] = true;
    // }

    // if (
    //   !ruleSelected.triggerStates ||
    //   ruleSelected.triggerStates.length === 0
    // ) {
    //   required["triggerStates"] = true;
    // }

    let incorrectFilled = Object.values(required).findIndex(x => x === true);
    if (incorrectFilled !== -1) {
      this.setState({ required });

      return false;
    }

    return true;
  }

  // _handleNewRequestUserAutocomplete = (str, i) => {
  //
  // }

  //Trigger Conditions:
  emptyField = {
    name: "",
    displayName: "",
    type: "string",
    value: "",
    regex: false
  };

  addField = index => {
    // if (index === 0 && !this.state.mapping.ticketType) {
    //   this.setState({
    //     validation: { ...this.state.validation, ticketType: true }
    //   });
    //   return;
    // }
    // let ruleSelected = _.cloneDeep(this.state.ruleSelected);
    // ruleSelected.triggerConditions.fields.splice(index + 1, 0, {
    //   ...this.emptyField
    // });
    // this.setState({ ruleSelected });
    if (!this.state.ruleSelected.mappingId) {
      const required = update(this.state.required, {
        mappingId: { $set: true }
      });
      this.setState({ required });
    } else {
      const ruleSelected = update(this.state.ruleSelected, {
        triggerConditions: {
          fields: { $splice: [[index + 1, 0, { ...this.emptyField }]] }
        }
      });

      this.setState({ ruleSelected });
    }
  };

  deleteField = index => {
    // let ruleSelected = _.cloneDeep(this.state.ruleSelected);
    // ruleSelected.triggerConditions.fields.splice(index, 1);
    // this.setState({ ruleSelected });

    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: { fields: { $splice: [[index, 1]] } }
    });
    this.setState({ ruleSelected });
  };

  handleFieldChange = (e, i) => {
    if (
      this.state.ruleSelected.triggerConditions.fields[i].name !==
      e.target.value
    ) {
      const newFieldObj = this.state.fields.filter(
        field => field.name === e.target.value
      )[0];
      const newType = newFieldObj.type;
      const newOptions = newFieldObj.values;

      const ruleSelected = update(this.state.ruleSelected, {
        triggerConditions: {
          fields: {
            $splice: [
              [
                i,
                1,
                { name: e.target.value, value: "", regex: false, type: newType }
              ]
            ]
          }
        }
      });
      if (newType === "dropdown" || "dropdown-search") {
        ruleSelected.triggerConditions.fields[i].options = newOptions;
        delete ruleSelected.triggerConditions.fields[i].regex;
      }
      this.setState({ ruleSelected });
    }
  };

  handleValueChange = (e, i) => {
    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: {
        fields: {
          [i]: { value: { $set: e.target ? e.target.value : e.value } }
        }
      }
    });
    this.setState({ ruleSelected });
  };

  handleTriggerStates = e => {
    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: { triggerStates: { $set: e.target.value } }
    });

    if (e.target.value)
      this.setState({
        ruleSelected,
        required: { ...this.state.required, triggerStates: false }
      });
  };

  handleMLConfidenceChange = e => {
    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: {
        mlFields: { ["confidenceLevel"]: { $set: Math.round(e) } }
      }
    });
    this.setState({ ruleSelected });
  };

  handleMlConditionValueChange = (predictedField, e) => {
    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: {
        mlFields: {
          predictions: {
            [predictedField]: {
              $set: { predictedValue: e.target ? e.target.value : e.value }
            }
          }
        }
      }
    });
    this.setState({ ruleSelected });
  };
  handleMlConditionFieldChange = (predictedField, e) => {
    const currentValue = this.state.ruleSelected.triggerConditions.mlFields
      .predictions[predictedField];
    const newFieldName = e.target ? e.target.value : e.value;
    if (newFieldName !== predictedField) {
      const predictions = this.state.ruleSelected.triggerConditions.mlFields
        .predictions;
      predictions[newFieldName] = currentValue;
      delete predictions[predictedField];

      const ruleSelected = update(this.state.ruleSelected, {
        triggerConditions: {
          mlFields: {
            predictions: { $set: predictions }
          }
        }
      });
      this.setState({ ruleSelected });
    }
  };

  deleteMlCondition = predictedField => {
    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: {
        mlFields: { predictions: { $unset: [predictedField] } }
      }
    });
    this.setState({ ruleSelected });
  };

  addMlCondition = index => {
    if (index === 0 && !this.state.ruleSelected.mappingId) {
      this.setState(
        {
          validation: { ...this.state.validation, ticketType: true }
        },
        this.backToTop
      );
      return;
    }
    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: {
        mlFields: {
          predictions: { ["unknown"]: { $set: { predictedValue: "" } } }
        }
      }
    });
    this.setState({ ruleSelected }, this.goToBottom);
  };

  handleRegex = i => {
    const preRegex = this.state.ruleSelected.triggerConditions.fields[i].regex;
    const ruleSelected = update(this.state.ruleSelected, {
      triggerConditions: { fields: { [i]: { regex: { $set: !preRegex } } } }
    });
    this.setState({ ruleSelected });
  };

  getAllTicketFields = ticketTemplate => {
    let fields = [
      { displayName: "KBID", name: "u_knowledge" },
      { displayName: "Ticket Management", name: "ticket_management" }
    ];
    if (ticketTemplate.primaryFields) {
      fields = [...fields, ...ticketTemplate.primaryFields];
    }
    if (ticketTemplate.secondaryFields)
      fields = [...fields, ...ticketTemplate.secondaryFields];
    return fields;
  };

  render() {
    //compose 'fields' object based on activeTemplate: some new type of activeTemplate only has primaryFields
    let predictableFields = [];
    let fields = [];
    if (
      this.state.fields &&
      this.state.predictableFields &&
      this.state.activeTemplate
    ) {
      fields = this.getAllTicketFields(this.state.activeTemplate);
      predictableFields = this.state.predictableFields
        .filter(
          prediction =>
            prediction.ticketType === this.state.activeTemplate.ticketType
        )
        .map(
          prediction =>
            fields.find(field => field.name === prediction.field) || {
              displayName: prediction.field,
              name: prediction.field
            }
        )
        .map(field => {
          return {
            displayName: field.displayName,
            name: field.name
          };
        });
    }
    return (
      <div style={RulesStyles.mainWrapper}>
        <div style={RulesStyles.contentWrapper}>
          <div style={RulesStyles.contentHeader}>
            <Row
              style={{
                display: "flex",
                alignItems: "flex-start"
              }}
            >
              <Col xs={10}>
                <h1 style={RulesStyles.ticketNumber}>
                  {this.props.ruleSelected.name || "Create New Rule"}
                </h1>
              </Col>

              <Col xs={2} style={{ display: "flex", justifyContent: "center" }}>
                {!this.props.ruleSelected.editable ? (
                  <IconMenu
                    iconButtonElement={
                      <IconButton
                        iconStyle={{
                          color: RulesStyles.iconStyle.color,
                          fontSize: 20
                        }}
                      >
                        <FontIcon
                          className="fa fa-ellipsis-v"
                          style={{ fontSize: 14, color: "red" }}
                        />
                      </IconButton>
                    }
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    targetOrigin={{ horizontal: "left", vertical: "top" }}
                  >
                    <MenuItem
                      primaryText="Delete"
                      onClick={() =>
                        this.props.deleteRule(
                          this.props.ruleSelected,
                          this.props.currentPage
                        )
                      }
                    />
                  </IconMenu>
                ) : (
                  ""
                )}

                <IconButton
                  iconStyle={{
                    color: RulesStyles.iconStyle.color,
                    fontSize: 20
                  }}
                  onClick={() => this._handleClose()}
                >
                  <NavigationClose />
                </IconButton>
              </Col>
            </Row>

            {/* TODO: Fix this sloppy code later */}

            {this.state.rulesRender["enabled"](
              this.props.ruleSelected["enabled"],
              "enabled"
            )}
            {this.state.rulesRender["name"](
              this.props.ruleSelected["name"],
              "name"
            )}
            {Object.keys(this.props.ruleSelected)
              .sort()
              .map((ruleKey, i) => {
                const rule = this.props.ruleSelected[ruleKey];
                return (
                  <div key={i}>
                    {this.state.rulesRender[ruleKey] &&
                    !ruleKey.match(
                      /name|confidenceThreshold|approver|assignmentGroup|author|enabled/
                    )
                      ? this.state.rulesRender[ruleKey](rule, ruleKey)
                      : ""}
                  </div>
                );
              })}

            <div style={{ margin: "30px 0 15px 0" }}>
              <p style={{ margin: 0 }}>
                <FontIcon className="fa fa-crosshairs" /> &nbsp;Trigger
                Conditions
              </p>

              <p
                style={{
                  margin: 0,
                  marginTop: 5,
                  fontSize: 13,
                  color: "grey"
                }}
              >
                Automation conditions based on the mapping chosen.
              </p>
            </div>

            <Col xs={12} style={{ marginTop: 30, paddingLeft: 0 }}>
              {!_.isEmpty(this.state.ruleSelected) && (
                <TriggerConditions
                  validation={this.state.required.triggerStates}
                  ticketStates={this.state.ticketStates}
                  fields={this.state.fields}
                  predictableFields={predictableFields}
                  mlFields={this.state.ruleSelected.triggerConditions.mlFields}
                  triggerConditions={this.state.ruleSelected.triggerConditions}
                  currentFields={
                    this.state.ruleSelected.triggerConditions.fields
                  }
                  addField={this.addField}
                  deleteField={this.deleteField}
                  handleFieldChange={this.handleFieldChange}
                  handleValueChange={this.handleValueChange}
                  handleTriggerStates={this.handleTriggerStates}
                  handleRegex={this.handleRegex}
                  handleMLConfidenceChange={this.handleMLConfidenceChange}
                  addMlCondition={this.addMlCondition}
                  deleteMlCondition={this.deleteMlCondition}
                  handleMlConditionFieldChange={
                    this.handleMlConditionFieldChange
                  }
                  handleMlConditionValueChange={
                    this.handleMlConditionValueChange
                  }
                />
              )}
            </Col>

            <div style={{ margin: "30px 0 15px 0" }}>
              <p style={{ margin: 0 }}>
                <FontIcon className="fa fa-user" /> &nbsp;Administration for
                this rule
              </p>

              <p
                style={{
                  margin: 0,
                  marginTop: 5,
                  fontSize: 13,
                  color: "grey"
                }}
              >
                All these fields are required and must be valid.
              </p>
            </div>
            {Object.keys(this.props.ruleSelected)
              .sort()
              .map((ruleKey, i) => {
                const rule = this.props.ruleSelected[ruleKey];
                return (
                  <div key={i}>
                    {this.state.rulesRender[ruleKey] &&
                    ruleKey.match(/approver|assignmentGroup|author/)
                      ? this.state.rulesRender[ruleKey](rule, ruleKey)
                      : ""}
                  </div>
                );
              })}
          </div>
        </div>

        <Divider />
        <Paper style={RulesStyles.contentActionsContainer}>
          <div style={RulesStyles.contentActions}>
            <PrimaryButton
              onClick={this._saveRule}
              label={
                <strong>
                  <FontIcon
                    className="fa fa-save"
                    style={{ fontSize: 14, color: "white" }}
                  />
                  &nbsp;Save
                </strong>
              }
            />
          </div>
        </Paper>
      </div>
    );
  }
}
