import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { red300 } from "material-ui/styles/colors";

export const NodeCardContainer = styled(Paper)`
${({ type, param }) =>
  type === "input" && !param && `border: 2px solid ${red300}`}
  position:relative;
  width: 140px
  min-height: 50px
  height:100%
  padding: 8px 16px
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px #e1e4e8, 0 2px 4px rgba(0,0,0,.15)
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start；
  word-wrap: break-word;
  box-sizing: border-box;
`;

NodeCardContainer.defaultProps = {
  param: "",
  type: "input"
};
