import { TICKET_GET_SINGLE_JOB_RESET, clearParsedTicket } from "./tickets";
import config from "../config";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";

export const OPEN_DETAILS = "OPEN_DETAILS";
export const CLOSE_DETAILS = "CLOSE_DETAILS";
export const SET_TICKET_UPDATED = "SET_TICKET_UPDATED";

export const setTicketUpdated = () => ({
  type: SET_TICKET_UPDATED
});

export function editTicketIndicator(ticket, state) {
  return function(dispatch, getState) {
    const ticketNumber = ticket.number || ticket.coreData.number;
    const url = `${config.urls.base +
      config.urls.apis[
        "ticket-management"
      ]}/tickets/${ticketNumber}/editing/${state}`;

    return axios
      .put(url)
      .then(res => true)
      .catch(err => {
        // TODO: handle errors
      });
  };
}

export function closeDetailsTicket(ticketNumber) {
  return function(dispatch) {
    if (ticketNumber.coreData && !ticketNumber.isNewTicket) {
      dispatch(editTicketIndicator(ticketNumber, "stop"));
    }

    dispatch(closeDetails());
    dispatch(clearParsedTicket());
  };
}

export const CLOSE_CREATE_SERVICE_REQUEST_DETAILS =
  "CLOSE_CREATE_SERVICE_REQUEST_DETAILS";

export function openDetails(ticketObject) {
  return async function(dispatch) {
    dispatch({
      type: OPEN_DETAILS,
      ticketObject
    });

    if (ticketObject && !ticketObject.isNewTicket) {
      dispatch(editTicketIndicator(ticketObject, "start"));

      // Update the resolution page.
      const ticket = ticketObject;
      let mlData = {},
        mlObj = {};
      if (ticket) {
        mlData = ticket.mlData;
        if (mlData) {
          mlData.forEach((item, index) => {
            mlObj[item.key] = index;
          });
        }
      }

      dispatch({
        type: OPEN_DETAILS,
        ticketObject
      });

      dispatch({
        type: "TICKET_GET_SINGLE_JOB_PENDING"
      });
      dispatch({
        type: TICKET_GET_SINGLE_JOB_RESET,
        jobSelected: {}
      });
    }
  };
}

export function closeDetails() {
  return {
    type: CLOSE_DETAILS,
    ticketNumber: {}
  };
}

export const REFRESH_DETAILS_SUCCESS = "REFRESH_DETAILS_SUCCESS";
export const REFRESH_DETAILS_LOADING = "REFRESH_DETAILS_LOADING";

export function refreshDetails(ticket) {
  return (dispatch, getState) => {
    dispatch({
      type: REFRESH_DETAILS_LOADING,
      refreshing: true
    });
    const ticketNumber = ticket.number || ticket.coreData.number;
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/tickets/${ticketNumber}`;

    return axiosSnackbar
      .get(url)
      .then(res => {
        dispatch({
          type: REFRESH_DETAILS_SUCCESS,
          ticketNumber: res.data
        });
        dispatch(clearParsedTicket());
      })
      .catch(err => {
        // TODO: handle errors
      });
  };
}
