import { connect } from "react-redux";
import EmailTemplates from "../../components/emailTemplates/EmailTemplates";
import {
  changePage,
  changePageMerge,
  createEmailTemplate,
  createMergeTag,
  deleteEmailTemplate,
  deleteMergeTag,
  getEmailTemplates,
  getMergeTagAutoComplete,
  getMergeTags,
  sendEmail,
  toggleDetails,
  toggleMergeTagDetails,
  updateEmailTemplate,
  updateMergeTag
} from "../../actions/emailTemplates";
import { getTemplateFields } from "redux/modules/ticketTemplate";
import { getActiveTemplates } from "../../actions/mapping";

const mapStateToProps = (state, ownProps) => ({
  // Errors
  errMsg: state.email.errMsg,

  // Email Templates states
  isFetchingEmailTemplates: state.email.isFetchingEmailTemplates,
  emailTemplates: state.email.emailTemplates,
  selectedEmailTemplate: state.email.selectedEmailTemplate,
  currentPage: state.email.currentPage,
  lastPage: state.email.lastPage,

  // Merge Tag states
  isFetchingMergeTags: state.email.isFetchingMergeTags,
  mergeTags: state.email.mergeTags,
  selectedMergeTag: state.email.selectedMergeTag,
  autocompleteFields: state.email.autocompleteFields,
  currentPageMerge: state.email.currentPageMerge,
  lastPageMerge: state.email.lastPageMerge,

  // Ticket template props
  templateFields: state.ticketTemplates.templateFields,
  activeTemplates: state.mapping.activeTemplates,

  //All Permissions of User
  permissions: state.user.permissions
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  // Email Template props
  changePage: nextPage => dispatch(changePage(nextPage)),
  getEmailTemplates: page => dispatch(getEmailTemplates(page)),
  openDetails: emailTemplate => dispatch(toggleDetails(emailTemplate)),
  closeDetails: () => dispatch(toggleDetails()),
  createEmailTemplate: emailTemplate =>
    dispatch(createEmailTemplate(emailTemplate)),
  updateEmailTemplate: emailTemplate =>
    dispatch(updateEmailTemplate(emailTemplate)),
  deleteEmailTemplate: emailTemplate =>
    dispatch(deleteEmailTemplate(emailTemplate)),
  sendEmail: email => dispatch(sendEmail(email)),

  // Merge Tag props
  changePageMerge: nextPage => dispatch(changePageMerge(nextPage)),
  getMergeTags: (page, perPage) => dispatch(getMergeTags(page, perPage)),
  openMergeTag: mergeTag => dispatch(toggleMergeTagDetails(mergeTag)),
  deleteMergeTag: mergeTag => dispatch(deleteMergeTag(mergeTag)),
  closeMergeTag: () => dispatch(toggleMergeTagDetails()),
  createMergeTag: mergeTag => dispatch(createMergeTag(mergeTag)),
  updateMergeTag: mergeTag => dispatch(updateMergeTag(mergeTag)),
  getMergeTagAutoComplete: (field, query, page, perPage) =>
    dispatch(getMergeTagAutoComplete(field, query, page, perPage)),

  // Ticket Template props
  getTemplateFields: () => dispatch(getTemplateFields()),
  getActiveTemplates: () => dispatch(getActiveTemplates())
});

const EmailTemplatesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplates);

export default EmailTemplatesPage;
