import { cloneDeep } from "lodash";
import produce from "immer";

export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_PENDING = "GET_USER_DATA_PENDING";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";

export const CHANGE_USER_DATA = "CHANGE_USER_DATA";
export const CHANGE_USER_DATA_PENDING = "CHANGE_USER_DATA_PENDING";
export const CHANGE_USER_DATA_SUCCESS = "CHANGE_USER_DATA_SUCCESS";
export const CHANGE_USER_DATA_ERROR = "CHANGE_USER_DATA_ERROR";

export const SET_COLUMN_PAGE = "SET_COLUMN_PAGE";
export const SET_COLUMN_DEFAULT = "SET_COLUMN_DEFAULT";
export const TOGGLE_CHECKBOX = "TOGGLE_CHECKBOX";
export const ON_SORT_END = "ON_SORT_END";
export const RESET_DEFAULT_COLUMNS = "RESET_DEFAULT_COLUMNS";

const initialData = {
  isFetchingUserData: true,
  userData: {},
  updatedUserData: {}
};

export const onSortEnd = (oldIndex, newIndex, selectedTicketType) => {
  return {
    type: ON_SORT_END,
    oldIndex,
    newIndex,
    selectedTicketType
  };
};

export const setColumnDefault = selectedTicketType => ({
  type: SET_COLUMN_DEFAULT,
  selectedTicketType
});

export const toggleCheckBox = (selectedTicketType, item, value) => ({
  type: TOGGLE_CHECKBOX,
  item,
  value,
  selectedTicketType
});

export const getUserData = () => ({
  type: GET_USER_DATA
});

export const getUserDataPending = () => ({
  type: GET_USER_DATA_PENDING
});

export const getUserDataSuccess = userData => ({
  type: GET_USER_DATA_SUCCESS,
  userData
});

export const getUserDataError = () => ({
  type: GET_USER_DATA_ERROR
});

export const changeUserData = () => ({
  type: CHANGE_USER_DATA
});

export const changeUserDataPending = () => ({
  type: CHANGE_USER_DATA_PENDING
});

export const changeUserDataSuccess = userData => ({
  type: CHANGE_USER_DATA_SUCCESS,
  userData
});

export const changeUserDataError = () => ({
  type: CHANGE_USER_DATA_ERROR
});

export const resetToDefaultColumns = (selectedTicketType, item, value) => ({
  type: RESET_DEFAULT_COLUMNS,
  item,
  value,
  selectedTicketType
});

const userDataReducer = (state = initialData, action) =>
  produce(state, newState => {
    switch (action.type) {
      case GET_USER_DATA_PENDING: {
        newState.isFetchingUserData = true;
        break;
      }

      case CHANGE_USER_DATA_SUCCESS: {
        newState.userData = action.userData;
        break;
      }

      case ON_SORT_END: {
        const moveArray = (array, oldIndex, newIndex) =>
          produce(array, newArray => {
            newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);
          });

        newState.updatedUserData.ticketTableColumns[
          action.selectedTicketType
        ] = moveArray(
          state.updatedUserData.ticketTableColumns[action.selectedTicketType],
          action.oldIndex,
          action.newIndex
        );

        return;
      }
      case SET_COLUMN_DEFAULT: {
        newState.updatedUserData = cloneDeep(state.userData);
        break;
      }
      case RESET_DEFAULT_COLUMNS: {
        const updatedUserData = cloneDeep(state.updatedUserData);

        updatedUserData.ticketTableColumns[action.selectedTicketType].splice(
          updatedUserData.ticketTableColumns[action.selectedTicketType].indexOf(
            action.item.name
          ),
          1
        );
        if (
          updatedUserData.ticketTableColumns[action.selectedTicketType]
            .length === 0
        ) {
          delete updatedUserData.ticketTableColumns[action.selectedTicketType];
        }
        newState.updatedUserData = updatedUserData;
        break;
      }

      case TOGGLE_CHECKBOX: {
        const updatedUserData = cloneDeep(state.updatedUserData);

        if (action.value) {
          if (!updatedUserData.ticketTableColumns) {
            updatedUserData.ticketTableColumns = {
              [action.selectedTicketType]: [action.item.name]
            };
          } else if (
            !updatedUserData.ticketTableColumns[action.selectedTicketType]
          ) {
            updatedUserData.ticketTableColumns = {
              ...updatedUserData.ticketTableColumns,
              [action.selectedTicketType]: [action.item.name]
            };
          } else {
            updatedUserData.ticketTableColumns[action.selectedTicketType].push(
              action.item.name
            );
          }
        } else {
          updatedUserData.ticketTableColumns[action.selectedTicketType].splice(
            updatedUserData.ticketTableColumns[
              action.selectedTicketType
            ].indexOf(action.item.name),
            1
          );

          if (
            updatedUserData.ticketTableColumns[action.selectedTicketType]
              .length === 0
          ) {
            localStorage.setItem("customisedColumnDisable", "Y");
            delete updatedUserData.ticketTableColumns[
              action.selectedTicketType
            ];
          }
        }

        newState.updatedUserData = updatedUserData;
        break;
      }

      case GET_USER_DATA_SUCCESS: {
        action.userData.ticketTableColumns =
          action.userData.ticketTableColumns || {};
        newState.userData = action.userData;
        newState.updatedUserData = action.userData;
        newState.isFetchingUserData = false;
        break;
      }

      default:
        newState = state;
    }
  });

export default userDataReducer;
