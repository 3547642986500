import React from "react";
import PropTypes from "prop-types";
import ATRFormField from "../templates/ATRFormField";
import SecondaryDropDownMenu from "../templates/SecondaryDropDownMenu";
import { MenuItem } from "material-ui";
import SecondaryCircularProgress from "../templates/SecondaryCircularProgress";
import _ from "lodash";
import RundeckDepricationMessage from "./RundeckDepricationMessage";
import automaionPlatformDisplayName from "../utils";

export default class RundeckMapping extends React.Component {
  componentDidMount() {
    const mapping = this.props.mapping;
    // If there are already predefined states, fetch the appropriate things
    if (mapping.targetServiceName) {
      this.props.getRundeckProjects(mapping.targetServiceName);
      if (mapping.targetProjectName) {
        this.props.getRundeckJobs(
          mapping.targetServiceName,
          mapping.targetProjectName
        );
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.mappingRundecks.forEach(instance => {
      // find if the instance exist in local instances
      if (instance.name === nextProps.mapping.targetServiceName) {
        // drawer: close->open
        if (
          _.isEmpty(this.props.mapping) &&
          nextProps.mapping.id &&
          nextProps.mapping.targetServiceName
        ) {
          nextProps.getRundeckProjects(nextProps.mapping.targetServiceName);
          if (nextProps.mapping.targetProjectName) {
            nextProps.getRundeckJobs(
              nextProps.mapping.targetServiceName,
              nextProps.mapping.targetProjectName
            );
          }
        }
        // drawer: open, from one mapping to another
        if (
          !_.isEmpty(this.props.mapping) &&
          !_.isEmpty(nextProps.mapping) &&
          !_.isUndefined(this.props.mapping.id) &&
          !_.isUndefined(nextProps.mapping.id) &&
          this.props.mapping.id !== nextProps.mapping.id
        ) {
          nextProps.getRundeckProjects(nextProps.mapping.targetServiceName);
          if (nextProps.mapping.targetProjectName) {
            nextProps.getRundeckJobs(
              nextProps.mapping.targetServiceName,
              nextProps.mapping.targetProjectName
            );
          }
        }
      }
    });
  }

  _handleRundeckDropdownChange(key, value) {
    const mapping = { ...this.props.mapping };
    const AQEServiceName = "automation-queue";
    const AQEProjectName = "default";
    mapping[key] = value;
    // Added flag to tell UI that user has selected an option
    // TODO: Seperate the mapping object from the job selection component.
    mapping["userSelected"] = true;
    // Load the next stage when the user has selected an option.
    switch (key) {
      // Rundeck selected -> Load project.
      case "targetServiceName": {
        this.props.getRundeckProjects(value);

        // Clear the dependant entries
        if (value === AQEServiceName) {
          mapping.targetProjectName = AQEProjectName;
          this.props.getRundeckJobs(AQEServiceName, AQEProjectName);
        } else {
          mapping.targetProjectName = "";
        }
        mapping.targetJobName = "";
        this.props.clearMappingJobs();
        this.props.clearRundeckProjects();
        break;
      }
      // Project selected -> Load jobs.
      case "targetProjectName": {
        this.props.getRundeckJobs(this.props.mapping.targetServiceName, value);
        mapping.targetJobName = "";
        break;
      }
      // Job selected -> Reset fieldMappingRules
      case "targetJobName": {
        mapping.fieldMappingRules = [];
        break;
      }
      default:
        break;
    }
    //TODO: need to fix this later by mapping refactoring
    const ticketID = this.props.ticket && this.props.ticket.coreData.id;
    if (this.props.type === "mapping") this.props.updateLocalMapping(mapping);
    else {
      this.props.updateMappingFound(mapping);
      this.props.ticketGetSingleJobDefinition(
        mapping.targetServiceName,
        mapping.targetJobName,
        ticketID
      );
    }
  }

  render() {
    const { mapping, disabled } = this.props;
    return (
      <div>
        <ATRFormField formLabel="Automation Platform">
          <SecondaryDropDownMenu
            value={mapping && mapping.targetServiceName}
            disabled={disabled}
            data-testid="rundeckMapping-dropdown1"
            errorText={
              this.props.validation.targetServiceName
                ? "This field is required."
                : ""
            }
            onChange={(e, k, v) =>
              this._handleRundeckDropdownChange("targetServiceName", v)
            }
          >
            {this.props.mappingRundecks &&
              this.props.mappingRundecks.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item.name}
                  primaryText={automaionPlatformDisplayName(item.name)} //Updated AQE reference to WFM. Link to ticket number- 'https://acnaop.atlassian.net/browse/ATRV3-10890'
                />
              ))}
          </SecondaryDropDownMenu>
        </ATRFormField>
        {mapping?.targetServiceName === "Local Rundeck" && (
          <RundeckDepricationMessage />
        )}
        {mapping?.targetServiceName !== "automation-queue" && (
          <ATRFormField formLabel="Project Name">
            {this.props.isFetchingRundeckProjects ? (
              <SecondaryCircularProgress />
            ) : (
              <SecondaryDropDownMenu
                value={mapping && mapping.targetProjectName}
                disabled={disabled}
                data-testid="rundeckMapping-dropdown2"
                errorText={
                  this.props.validation.targetProjectName
                    ? "This field is required."
                    : ""
                }
                onChange={(e, k, v) =>
                  this._handleRundeckDropdownChange("targetProjectName", v)
                }
              >
                {this.props.rundeckProjects.map((item, i) => (
                  <MenuItem key={i} value={item.name} primaryText={item.name} />
                ))}
              </SecondaryDropDownMenu>
            )}
          </ATRFormField>
        )}

        <ATRFormField formLabel="Job/Workflow Name">
          {this.props.isFetchingRundeckProjects ||
          this.props.isFetchingMappingJobs ? (
            <SecondaryCircularProgress />
          ) : (
            <SecondaryDropDownMenu
              // mapping doesn't have this real
              errorText={
                this.props.validation.targetJobName
                  ? "This field is required."
                  : !_.isEmpty(this.props.mapping) &&
                    this.props.mapping.hasOwnProperty("error") &&
                    this.props.mapping.error.fieldName === "targetJobName"
                  ? this.props.mapping.error.errorMessage
                  : ""
              }
              disabled={disabled}
              data-testid="rundeckMapping-dropdown3"
              value={mapping && mapping.targetJobName}
              onChange={(e, k, v) =>
                this._handleRundeckDropdownChange("targetJobName", v)
              }
            >
              {!_.isEmpty(this.props.mappingJobs) &&
                this.props.mappingJobs.map((item, i) => (
                  <MenuItem key={i} value={item.id} primaryText={item.name} />
                ))}
            </SecondaryDropDownMenu>
          )}
        </ATRFormField>
      </div>
    );
  }
}

RundeckMapping.propTypes = {
  // mapping: PropTypes.object.isRequired, // Object that holds the select values
  mappingRundecks: PropTypes.array.isRequired, // [targetServiceName] Rundeck instances
  rundeckProjects: PropTypes.array.isRequired, // [targetProjectName] Projects belonging to a rundeck instance
  mappingJobs: PropTypes.array.isRequired, // [targetJobName] Jobs belonging to a project within an instance
  isFetchingRundeckProjects: PropTypes.bool.isRequired,
  isFetchingMappingJobs: PropTypes.bool.isRequired
};

RundeckMapping.defaultProps = {
  validation: {
    targetServiceName: false,
    targetProjectName: false,
    targetJobName: false
  }
};
