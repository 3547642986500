import React from "react";
import List from "@material-ui/core/List";
import SecondaryButton from "../../../templates/SecondaryButton";
import { v4 as uuidv4 } from "uuid";

const Pagination = props => {
  const {
    moveTicketCachePointer,
    cacheLocalFinal,
    cacheObject,
    cacheLocalPointer,
    ticketSort,
    ticketFilter,
    ticketTimeRange,
    presetSelected,
    selectedTicketType
  } = props;
  const paginationBuffer = (pointer, direction, numberOfPages, finalPoint) => {
    if (direction === "left")
      return [
        ...Array(
          pointer - numberOfPages <= 0 ? pointer - 1 : numberOfPages
        ).keys()
      ]
        .map(num => (
          <SecondaryButton
            onClick={() => {
              moveTicketCachePointer(
                -num - 1,
                cacheLocalPointer,
                cacheObject,
                ticketSort,
                ticketFilter,
                selectedTicketType,
                ticketTimeRange,
                presetSelected || ""
              );
            }}
            key={uuidv4()}
            style={{ minWidth: "2rem" }}
          >
            {pointer - num - 1}
          </SecondaryButton>
        ))
        .reverse();
    if (direction === "right") {
      return [
        ...Array(
          pointer + numberOfPages > finalPoint
            ? finalPoint - pointer
            : numberOfPages
        ).keys()
      ].map(num => (
        <SecondaryButton
          onClick={() => {
            moveTicketCachePointer(
              num + 1,
              cacheLocalPointer,
              cacheObject,
              ticketSort,
              ticketFilter,
              selectedTicketType,
              ticketTimeRange,
              presetSelected || ""
            );
          }}
          key={uuidv4()}
          style={{ minWidth: "2rem" }}
        >
          {pointer + num + 1}
        </SecondaryButton>
      ));
    }
  };

  const paginationBufferFromCurrent = bufferNumber => {
    return [
      !(cacheLocalPointer - bufferNumber <= 1) && "...",

      ...paginationBuffer(
        cacheLocalPointer,
        "left",
        bufferNumber,
        cacheLocalFinal
      ),
      <SecondaryButton
        disabled={true}
        style={{ minWidth: "2rem" }}
        key={uuidv4()}
      >
        {cacheLocalPointer}
      </SecondaryButton>,
      ...paginationBuffer(
        cacheLocalPointer,
        "right",
        bufferNumber,
        cacheLocalFinal
      ),
      !(cacheLocalPointer + bufferNumber > cacheLocalFinal - 1) && "..."
    ];
  };
  return (
    <div style={{ width: "100%", display: "flex", marginTop: "20px" }}>
      <List
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 20
        }}
      >
        <SecondaryButton
          disabled={cacheLocalPointer <= 1}
          label={<strong>Back</strong>}
          onClick={() => {
            moveTicketCachePointer(
              -1,
              cacheLocalPointer,
              cacheObject,
              ticketSort,
              ticketFilter,
              selectedTicketType,
              ticketTimeRange,
              presetSelected || ""
            );
          }}
        />
        {paginationBufferFromCurrent(2)}
        <SecondaryButton
          disabled={cacheLocalPointer >= cacheLocalFinal}
          label={<strong>Next</strong>}
          onClick={() => {
            moveTicketCachePointer(
              1,
              cacheLocalPointer,
              cacheObject,
              ticketSort,
              ticketFilter,
              selectedTicketType,
              ticketTimeRange,
              presetSelected
            );
          }}
        />
      </List>
    </div>
  );
};

export default Pagination;
