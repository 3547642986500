import { createSelector } from "reselect";
import { defaultTicketField } from "./defaultColumn";
const columnFilterSelector = state => state.tickets.columnFilter || "";
const selectedTypeSelector = state => state.tickets.selectedTicketType || "";
const ticketTableColumnSelector = state =>
  state.userData.updatedUserData.ticketTableColumns || {};
const ticketFieldSelector = state => state.ticketField.ticketFields || {};

//Check if the selected ticket type is customized
export const getIsCustomized = createSelector(
  selectedTypeSelector,
  ticketTableColumnSelector,
  (selectedType, ticketTableColumn) => {
    return (
      (ticketTableColumn[selectedType] &&
        ticketTableColumn[selectedType].length > 0) ||
      false
    );
  }
);

//Return available ticket field for selected ticket template
export const getAvailableTicketField = createSelector(
  selectedTypeSelector,
  columnFilterSelector,
  ticketFieldSelector,
  (selectedType, columnFilter, ticketField) => {
    const availableTicketColumn = ticketField[selectedType] || [];
    const filteredColumn = availableTicketColumn.filter(column => {
      if (column.displayName) {
        return column.displayName
          .toLowerCase()
          .includes(columnFilter.toLowerCase());
      }
    });
    const sortedColumn = sortByKey(filteredColumn, "displayName");
    return sortedColumn;
  }
);

export const getCustomizedTicketField = createSelector(
  selectedTypeSelector,
  ticketTableColumnSelector,
  ticketFieldSelector,
  (selectedType, ticketTableColumn, ticketField) => {
    const selectedTicketColumn = ticketTableColumn[selectedType] || [];
    let availableTicketColumn = ticketField[selectedType] || [];
    if (selectedType === "SR_ITEM" || selectedType === "STANDALONE_SR_ITEM") {
      availableTicketColumn = mapSrItemFieldDisplayName(availableTicketColumn);
    }
    if (
      selectedType === "SERVICE_REQUEST" ||
      selectedType === "STANDALONE_SERVICE_REQUEST"
    ) {
      availableTicketColumn = mapSrRequestFieldDisplayName(
        availableTicketColumn
      );
    }

    let customizedTicketColumn = selectedTicketColumn.map(field => {
      return availableTicketColumn.find(column => column.name === field) || {};
    });

    let getCustomisedColumnFlagDisabled = localStorage.getItem(
      "customisedColumnDisable"
    );

    if (
      getCustomisedColumnFlagDisabled == null &&
      (customizedTicketColumn.length === 0 ||
        availableTicketColumn.length === 0)
    ) {
      if (selectedType === "SR_ITEM" || selectedType === "STANDALONE_SR_ITEM") {
        customizedTicketColumn = defaultTicketField.SR_ITEM;
      } else if (
        selectedType === "SERVICE_REQUEST" ||
        selectedType === "STANDALONE_SERVICE_REQUEST"
      ) {
        customizedTicketColumn = defaultTicketField.SERVICE_REQUEST;
      } else {
        customizedTicketColumn = defaultTicketField.DEFAULT;
      }
    }

    return customizedTicketColumn;
  }
);

const sortByKey = (array, key) => {
  return [...array].sort((obj1, obj2) => {
    return obj1[key] < obj2[key] ? -1 : obj1[key] > obj2[key] ? 1 : 0;
  });
};

const mapSrRequestFieldDisplayName = array => {
  const newArray = array.map(item => {
    if (item.name === "shortDescription") {
      item.displayName = "Requested For";
    }
    if (item.name === "application") {
      item.displayName = "Opened By";
    }
    if (item.name === "assignee") {
      item.displayName = "Due Date";
    }
    return item;
  });

  return newArray;
};

const mapSrItemFieldDisplayName = array => {
  const newArray = array.map(item => {
    if (item.name === "shortDescription") {
      item.displayName = "Item";
    }
    if (item.name === "application") {
      item.displayName = "Quantity";
    }
    if (item.name === "state") {
      item.displayName = "Approval";
    }
    if (item.name === "assignee") {
      item.displayName = "Due Date";
    }
    return item;
  });

  return newArray;
};
