import {
  OPEN_DETAILS,
  CLOSE_DETAILS,
  CLOSE_CREATE_SERVICE_REQUEST_DETAILS,
  REFRESH_DETAILS_SUCCESS,
  REFRESH_DETAILS_LOADING,
  SET_TICKET_UPDATED
} from "../actions/details";

import {
  TICKET_GET_SINGLE_FULFILLED,

  // TICKET MUTATION
  TICKET_UPDATE_SINGLE_PENDING,
  TICKET_UPDATE_SINGLE_FULFILLED,
  TICKET_UPDATE_SINGLE_ERROR,

  // TICKET CREATE
  TICKET_CREATE_SINGLE_FULFILLED,
  TICKET_CREATE_SINGLE_ERROR,

  // TICKET options
  TICKET_GET_OPTIONS_FULFILLED,
  TICKET_GET_ACTIVITIES_PENDING,
  TICKET_GET_ACTIVITIES_FULFILLED,
  TICKET_GET_ACTIVITIES_ERROR,
  TICKET_SAVE_ACTIVITY_PENDING,
  TICKET_SAVE_ACTIVITY_FULFILLED,
  TICKET_SAVE_ACTIVITY_ERROR,
  TICKET_GET_RESOLUTION_PENDING,
  TICKET_GET_RESOLUTION_FULFILLED,
  TICKET_GET_RESOLUTION_ERROR,
  ADD_TO_CONTINUOUS_ML_SUCCESS,

  // TICKET Activities Types
  TICKET_GET_ACTIVITIES_TYPES_PENDING,
  TICKET_GET_ACTIVITIES_TYPES_FULFILLED,
  TICKET_GET_ACTIVITIES_TYPES_ERROR
} from "../actions/tickets";

const initialState = {
  isOpen: false,
  ticketNumber: {},
  ticketActivities: [],
  ticketResolution: {},
  error: "",
  isUpdating: false,
  isUpdated: false,
  ticketActivityIsFetching: false,
  ticketResolutionIsFetching: false,
  refreshing: false,
  // TICKET Activities Types
  ticketActivitiesTypes: [],
  ticketActivitiesTypesIsFetching: false
};

const details = (state = initialState, action) => {
  switch (action.type) {
    case SET_TICKET_UPDATED: {
      return {
        ...state,
        isUpdated: true
      };
    }
    case ADD_TO_CONTINUOUS_ML_SUCCESS: {
      return {
        ...state,
        ticketNumber: {
          ...state.ticketNumber,
          continuousML: true
        }
      };
    }
    case OPEN_DETAILS: {
      return {
        isOpen: true,
        ticketNumber: action.ticketObject,
        ticketActivities: state.ticketActivities,
        ticketActivityIsFetching: state.ticketActivityIsFetching,
        isUpdated: false
      };
    }
    case CLOSE_DETAILS:
      return {
        isOpen: false,
        ticketNumber: {},
        ticketActivities: state.ticketActivities,
        ticketActivityIsFetching: false
      };

    case CLOSE_CREATE_SERVICE_REQUEST_DETAILS:
      return {
        ...state,
        isOpen: false,
        isServiceRequest: true
      };

    case TICKET_GET_SINGLE_FULFILLED: {
      return {
        isOpen: true,
        ticketNumber: action.ticketNumber,
        ticketActivities: state.ticketActivities,
        ticketActivityIsFetching: state.ticketActivityIsFetching,
        ticketActivitiesTypes: state.ticketActivitiesTypes,
        ticketActivitiesTypesIsFetching: state.ticketActivitiesTypesIsFetching
      };
    }
    case TICKET_UPDATE_SINGLE_PENDING: {
      return Object.assign({}, state, {
        isUpdating: true
      });
    }
    case TICKET_UPDATE_SINGLE_FULFILLED: {
      return {
        isOpen: true,
        isUpdating: false,
        isUpdated: false,
        ticketNumber: action.ticket,
        ticketActivities: state.ticketActivities,
        ticketActivityIsFetching: state.ticketActivityIsFetching
      };
    }
    case TICKET_CREATE_SINGLE_ERROR: {
      return Object.assign({}, state, {
        error: action.error
      });
    }
    case TICKET_UPDATE_SINGLE_ERROR: {
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    }
    case TICKET_CREATE_SINGLE_FULFILLED:
      return {
        isOpen: true,
        ticketNumber: action.ticket,
        ticketActivities: state.ticketActivities,
        ticketActivityIsFetching: state.ticketActivityIsFetching
      };
    case TICKET_GET_OPTIONS_FULFILLED: {
      /* Populate with options */
      const body = Object.assign({}, state.ticketNumber);
      if (body.properties) {
        Object.keys(action.options).forEach(chipKey => {
          if (body.properties[chipKey]) {
            body.properties[chipKey].chipData = body.properties[
              chipKey
            ].chipData
              .splice(0, 1)
              .concat(action.options[chipKey]);
          }
        });
      }
      return {
        isOpen: state.isOpen,
        ticketNumber: body,
        ticketActivities: state.ticketActivities,
        ticketActivityIsFetching: state.ticketActivityIsFetching
      };
    }
    case TICKET_GET_ACTIVITIES_PENDING: {
      return Object.assign({}, state, {
        ticketActivityIsFetching: true
      });
    }
    case TICKET_GET_ACTIVITIES_FULFILLED: {
      return Object.assign({}, state, {
        ticketActivities: action.ticketActivities,
        ticketActivityIsFetching: false
      });
    }
    case TICKET_GET_ACTIVITIES_ERROR: {
      return Object.assign({}, state, {
        ticketActivityIsFetching: false
      });
    }
    case TICKET_SAVE_ACTIVITY_PENDING: {
      return Object.assign({}, state, {
        ticketActivityIsFetching: true
      });
    }
    case TICKET_SAVE_ACTIVITY_FULFILLED: {
      return Object.assign({}, state, {
        ticketActivityIsFetching: false
      });
    }
    case TICKET_SAVE_ACTIVITY_ERROR: {
      return Object.assign({}, state, {
        ticketActivityIsFetching: false
      });
    }
    // TICKET Actitives Types
    case TICKET_GET_ACTIVITIES_TYPES_PENDING: {
      return Object.assign({}, state, {
        ticketActivitiesTypesIsFetching: true
      });
    }
    case TICKET_GET_ACTIVITIES_TYPES_FULFILLED: {
      return Object.assign({}, state, {
        ticketActivitiesTypes: action.ticketActivitiesTypes,
        ticketActivitiesTypesIsFetching: false
      });
    }
    case TICKET_GET_ACTIVITIES_TYPES_ERROR: {
      return Object.assign({}, state, {
        ticketActivitiesTypesIsFetching: false
      });
    }
    case TICKET_GET_RESOLUTION_PENDING: {
      return Object.assign({}, state, {
        ticketResolutionIsFetching: true
      });
    }
    case TICKET_GET_RESOLUTION_FULFILLED: {
      return Object.assign({}, state, {
        ticketResolution: action.ticketResolution,
        ticketResolutionIsFetching: false
      });
    }
    case TICKET_GET_RESOLUTION_ERROR: {
      return Object.assign({}, state, {
        ticketResolution: action.ticketResolution,
        ticketResolutionIsFetching: false
      });
    }
    case REFRESH_DETAILS_SUCCESS: {
      return {
        ...state,
        ticketNumber: action.ticketNumber,
        refreshing: false
      };
    }
    case REFRESH_DETAILS_LOADING: {
      return {
        ...state,
        refreshing: action.refreshing
      };
    }
    default:
      return state;
  }
};

export default details;
