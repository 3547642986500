import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

export const MainContainer = styled(({ isEven, ...rest }) => (
  <Grid {...rest} />
))`
  ${props => (props.isEven ? "background: #F7FCFF;" : "")};
  font-size: 1rem;
  padding: 20px;

  @media screen and (max-width: 992px) {
    font-size: 0.8rem;
    padding: 7.5px;
  }

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: 0.9rem;
    padding: 14px;
  }

  @media screen and (max-height: 700px) {
    font-size: 0.8rem;
    padding: 7.5px;
  }

  @media screen and (min-height: 700px) and (max-height: 800px) {
    font-size: 0.9rem;
    padding: 14px;
  }
`;

export const ColumnContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputContainer = styled.div`
  margin-right: 10px;
  margin-left: 0px !important;
  & div {
    div {
      div {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 992px) {
    & div {
      div {
        div {
          font-size: 0.8rem;
        }
      }
    }
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    & div {
      div {
        div {
          font-size: 0.9rem;
        }
      }
    }
  }

  @media screen and (max-height: 768px) {
    & div {
      div {
        div {
          font-size: 0.8rem;
        }
      }
    }
  }
`;

export const InputValueField = styled(TextField)`
  width: 100%;
  & div {
    font-size: 1rem;
  }

  @media screen and (max-width: 992px) {
    & div {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    & div {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-height: 768px) {
    & div {
      font-size: 0.8rem;
    }
  }
`;
