import React from "react";
import PropTypes from "prop-types";
import EditIconButton from "../EditIconButton";
import {
  StyledPaper,
  HeaderContainer,
  TitleText,
  EditContainer,
  BodyContainer
} from "./styles";

const InfoPanel = props => {
  const { title, onEdit, children } = props;
  return (
    <StyledPaper>
      <HeaderContainer>
        <TitleText>{title}</TitleText>
        <EditContainer>
          <EditIconButton onClick={onEdit} />
        </EditContainer>
      </HeaderContainer>
      <BodyContainer>{children}</BodyContainer>
    </StyledPaper>
  );
};

InfoPanel.propTypes = {
  title: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default InfoPanel;
