import React, { Component } from "react";
import { connect } from "react-redux";

import update from "immutability-helper";
import { TableRow, TableRowColumn } from "material-ui/Table";
import { StyledCancel, StyledSave } from "./StyledComponents/shared";
import SecondaryTextField from "../../templates/SecondaryTextField";
import PasswordComponent from "../PasswordComponent";
import ProxyConfigurationDialog from "./ProxyConfigurationDialog";
import RundeckConfirmationDialog from "./RundeckConfirmationDialog";

const LOCAL_RUNDECK_DEFAULT = "Local Rundeck";
const AUTOMATION_QUEUE_DEFAULT = "automation-queue";

const mapStateToProps = state => ({
  proxyconfiguration: state.proxyConfiguration,
  rundeckconfirmation: state.rundeckConfirmation
});

class RundeckTableRow extends Component {
  static propTypes = {};

  // TODO: move to hooks
  state = {
    editing: this.props.editing ? this.props.editing : false,
    item: JSON.parse(JSON.stringify(this.props.item)),
    errors: {
      username: "",
      password: "",
      token: ""
    },
    valid: true,
    rundeckToDelete: ""
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     item: JSON.parse(JSON.stringify(nextProps.item))
  //   };
  // }

  _handleSaveButtonClick = () => {
    let state = update(this.state, {
      valid: { $set: this._validateEntry() }
    });
    if (state.valid) {
      this.state.item.proxyConfig = this.setProxyConfig();
      this.props.addTicketConfigThenRefreshList(
        this.props.service,
        this.state.item
      );
      state.editing = false;
      if (this.props.isNewRow) this.props.hideNewRow(this.props.service);
    }
    this.setState(state);
  };

  _handleCancelButtonClick = () => {
    if (this.props.isNewRow) {
      this.props.hideNewRow(this.props.service);
    } else {
      const state = update(this.state, {
        item: { $set: JSON.parse(JSON.stringify(this.props.item)) },
        editing: { $set: false }
      });
      this.setState(state);
    }
  };

  _handleDeleteButtonClick() {
    const rundeckConf = this.props.rundeckconfirmation;
    this.state.rundeckToDelete = rundeckConf;
    if (this.state.rundeckToDelete === this.state.item.name) {
      this.props.deleteTicketConfigThenRefreshList(this.props.service, {
        name: this.state.rundeckToDelete
      });
    }
  }

  _handleNameChange = (a, v) => {
    const state = update(this.state, { item: { name: { $set: v } } });
    this.setState(state);
  };

  _handleUsernameChange = (a, v) => {
    const state = update(this.state, { item: { username: { $set: v } } });
    this.setState(state);
  };

  _handlePasswordChange = (a, v) => {
    const state = update(this.state, { item: { password: { $set: v } } });
    this.setState(state);
  };

  _handleTokenChange = (a, v) => {
    const state = update(this.state, { item: { token: { $set: v } } });
    this.setState(state);
  };

  _handleUrlChange = (a, v) => {
    const state = update(this.state, { item: { url: { $set: v } } });
    this.setState(state);
  };

  _validateEntry = () => {
    let valid = true;

    const state = update(this.state, {
      errors: {
        username: { $set: "" },
        password: { $set: "" },
        token: { $set: "" }
      }
    });

    const item = state.item;

    // user has entered a username and password and token
    if ((item.username.length || item.password.length) && item.token.length) {
      state.errors.token = "Token not required with username and password.";

      valid = false;
    } else if (item.username.length > 0 && item.password.length === 0) {
      // user has entered a username but no password
      // console.log("password is required");
      state.errors.password = "Password is required.";

      valid = false;
    } else if (!item.username.length && item.password.length) {
      // user has entered a password but no username
      state.errors.username = "Username is required.";

      valid = false;
    } else if (
      !item.token.length &&
      !item.username.length &&
      !item.password.length
    ) {
      // user hasn't entered a username, password or token
      state.errors.username = "Required if no token entered.";
      state.errors.password = "Required if no token entered.";

      valid = false;
    }
    this.setState(state);

    return valid;
  };

  setProxyConfig = () => {
    const proxyConfig = this.props.proxyconfiguration;
    if (!Object.values(proxyConfig).some(key => key)) {
      return null;
    }
    return proxyConfig;
  };

  render() {
    const {
      addTicketConfigThenRefreshList,
      deleteTicketConfigThenRefreshList,
      ...rest
    } = this.props;

    return this.state.editing ? (
      <TableRow style={{ overflow: "auto" }}>
        <TableRowColumn width={"150"}>
          <SecondaryTextField
            hintText={"Name"}
            errorText={this.state.errors.name}
            defaultValue={this.state.item.name}
            onChange={(a, v) => {
              this._handleNameChange(a, v);
            }}
          />
        </TableRowColumn>
        <TableRowColumn width={"150"}>
          <SecondaryTextField
            hintText={"URL"}
            errorText={this.state.errors.url}
            defaultValue={this.state.item.url}
            onChange={(a, v) => {
              this._handleUrlChange(a, v);
            }}
          />
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={"150"}>
          <SecondaryTextField
            hintText={"Username"}
            errorText={this.state.errors.username}
            defaultValue={this.state.item.username}
            onChange={(a, v) => {
              this._handleUsernameChange(a, v);
            }}
          />
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={"150"}>
          <SecondaryTextField
            hintText={"Password"}
            errorText={this.state.errors.password}
            defaultValue={this.state.item.password}
            onChange={(a, v) => {
              this._handlePasswordChange(a, v);
            }}
          />
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={"150"}>
          <SecondaryTextField
            hintText={"Token"}
            errorText={this.state.errors.token}
            defaultValue={this.state.item.token}
            onChange={(a, v) => {
              this._handleTokenChange(a, v);
            }}
          />
        </TableRowColumn>
        <TableRowColumn width={"50"} style={{ textAlign: "ri" }}>
          <ProxyConfigurationDialog
            value={
              this.state.item.proxyConfig !== null
                ? this.state.item.proxyConfig
                : undefined
            }
          ></ProxyConfigurationDialog>
        </TableRowColumn>
        <TableRowColumn width={70} style={{ textAlign: "right" }}>
          <StyledSave
            onClick={() => {
              this._handleSaveButtonClick();
            }}
          />
          <StyledCancel
            onClick={() => {
              this._handleCancelButtonClick();
            }}
          />
          {!this.props.isNewRow ? (
            this.state.item.name === LOCAL_RUNDECK_DEFAULT ||
            this.state.item.name === AUTOMATION_QUEUE_DEFAULT ? (
              <TableRowColumn
                width={70}
                style={{ textAlign: "right" }}
                className={"row-edit-delete"}
              />
            ) : (
              <TableRowColumn
                width={70}
                style={{ textAlign: "right" }}
                className={"row-edit-delete"}
              >
                <RundeckConfirmationDialog
                  value={this.state.item.name}
                  onClick={this._handleDeleteButtonClick()}
                ></RundeckConfirmationDialog>
              </TableRowColumn>
            )
          ) : null}
        </TableRowColumn>
      </TableRow>
    ) : (
      <TableRow style={{ overflow: "auto" }} {...rest}>
        <TableRowColumn width={"150"}>{this.state.item.name}</TableRowColumn>
        <TableRowColumn width={"150"}>{this.state.item.url}</TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={"150"}>
          {this.state.item.username}
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={"150"}>
          <PasswordComponent password={this.state.item.password} />
        </TableRowColumn>
        <TableRowColumn style={{ overflow: "auto" }} width={"150"}>
          {this.state.item.token ? this.state.item.token : "- -"}
        </TableRowColumn>

        {this.state.item.proxyConfig == null ? (
          <TableRowColumn width={"50"} style={{ textAlign: "left" }}>
            - -
          </TableRowColumn>
        ) : (
          <TableRowColumn width={"50"} style={{ textAlign: "left" }}>
            <ProxyConfigurationDialog
              value={
                this.state.item.proxyConfig !== null
                  ? this.state.item.proxyConfig
                  : undefined
              }
            ></ProxyConfigurationDialog>
          </TableRowColumn>
        )}

        {this.state.item.name === LOCAL_RUNDECK_DEFAULT ||
        this.state.item.name === AUTOMATION_QUEUE_DEFAULT ? (
          <TableRowColumn
            width={70}
            style={{ textAlign: "right" }}
            className={"row-edit-delete"}
          />
        ) : (
          <TableRowColumn
            width={70}
            style={{ textAlign: "right" }}
            className={"row-edit-delete"}
          >
            <RundeckConfirmationDialog
              value={this.state.item.name}
              onClick={this._handleDeleteButtonClick()}
            ></RundeckConfirmationDialog>
          </TableRowColumn>
        )}
      </TableRow>
    );
  }
}

export default connect(mapStateToProps)(RundeckTableRow);
