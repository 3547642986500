import React from "react";
import PropTypes from "prop-types";
import SecondaryButton from "./SecondaryButton";
import { Row } from "react-flexbox-grid/lib";
import { List } from "material-ui";

export default class ATRPaginator extends React.Component {
  render() {
    return (
      this.props.lastPage > 1 && (
        <Row style={{ padding: 10 }}>
          <List
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 20,
              marginBottom: 60
            }}
          >
            <SecondaryButton
              label={<strong>Back</strong>}
              onClick={() => {
                this.props.changePage(-1);
              }}
            />
            <div style={{ padding: "0 40px", fontSize: 13 }}>
              Page {this.props.currentPage} of {this.props.lastPage}{" "}
            </div>
            <SecondaryButton
              label={<strong>Next</strong>}
              onClick={() => {
                this.props.changePage(1);
              }}
            />
          </List>
        </Row>
      )
    );
  }
}

ATRPaginator.propTypes = {
  changePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired
};
