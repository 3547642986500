import React from "react";

import uuid from "uuid";
import { ICSSProps, automationConfigsStyles } from "../Styles";
import {
  IAutomationConfigsDetailsProps,
  ResultsType
} from "../DryRunInterface";
import AutomationConfigsCard from "./AutomationConfigsCard";

const AutomationConfigsDetails: React.FC<ICSSProps<
  typeof automationConfigsStyles
> &
  ResultsType> = React.memo(({ classes, results }) => {
  return (
    <>
      {results &&
        results.map((resultDetails: IAutomationConfigsDetailsProps) => {
          return (
            <AutomationConfigsCard
              key={uuid.v4()}
              classes={classes}
              resultDetails={resultDetails}
              theme={undefined}
            />
          );
        })}
    </>
  );
});

export default AutomationConfigsDetails;
