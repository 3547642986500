import React from "react";
import PropTypes from "prop-types";
import {
  FontIcon,
  IconButton,
  IconMenu,
  MenuItem,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui";
import muiTheme from "../../styles/themeMainUI";

export default class MergeTagsTable extends React.Component {
  render() {
    return (
      <Table
        onCellClick={this.props.handleRowSelection}
        selectable
        displayRowCheckbox={false}
      >
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn width={200}>Group</TableHeaderColumn>
            <TableHeaderColumn width={150}>Key</TableHeaderColumn>
            <TableHeaderColumn width={150}>Value</TableHeaderColumn>
            <TableHeaderColumn width={100}>Type</TableHeaderColumn>
            <TableHeaderColumn width={50} />
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
          deselectOnClickaway={false}
          showRowHover
        >
          {this.props.mergeTags.map((item, i) => (
            <TableRow key={i}>
              <TableRowColumn>{item.name}</TableRowColumn>
              <TableRowColumn width={200}>{item.group}</TableRowColumn>
              <TableRowColumn width={150}>{item.key}</TableRowColumn>
              <TableRowColumn width={150}>{item.value}</TableRowColumn>
              <TableRowColumn width={100}>{item.type}</TableRowColumn>
              <TableRowColumn width={50}>
                {/* TODO: Change me (styling->sass) */}
                <IconMenu
                  iconButtonElement={
                    <IconButton
                      iconStyle={{
                        color: muiTheme.palette.accent3Color,
                        fontSize: 18
                      }}
                    >
                      <FontIcon
                        className="fa fa-ellipsis-v"
                        style={{ fontSize: 14, color: "red" }}
                      />
                    </IconButton>
                  }
                >
                  <MenuItem onClick={() => this.props.deleteMergeTag(item)}>
                    Delete
                  </MenuItem>
                </IconMenu>
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

MergeTagsTable.propTypes = {
  mergeTags: PropTypes.array
};
