import React from "react";
import PropTypes from "prop-types";
import TabLabel from "../TabLabel";
import { StyledTabs, StyledTab } from "./styles";

const a11yProps = index => {
  return {
    id: `step-tab-${index}`,
    "aria-controls": `step-tabpanel-${index}`
  };
};

const TabbedSteps = props => {
  const { curStep, handleChange, steps } = props;

  const onTabChange = (event, value) => {
    handleChange(value);
  };

  return (
    <StyledTabs
      value={curStep < 1 ? 1 : curStep}
      onChange={onTabChange}
      aria-label="aq-tabbed-steps"
      classes={{
        indicator: "tab-indicator"
      }}
      // scrollable
    >
      {steps.map((step, index) => (
        <StyledTab
          classes={{
            root: "tab-root",
            selected: "tab-selected",
            labelContainer: "label-container"
          }}
          value={step.stepNum}
          key={`step-tab-${step.stepNum}`}
          label={
            <TabLabel
              text={step.label}
              index={index}
              curStep={curStep}
              stepNum={step.stepNum}
            />
          }
          {...a11yProps(step.stepNum)}
        />
      ))}
    </StyledTabs>
  );
};

TabbedSteps.propTypes = {
  handleChange: PropTypes.func.isRequired,
  curStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired
};

export default TabbedSteps;
