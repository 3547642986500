import config from "../config";
import history from "../history";
import { axiosInstance as axios } from "../utils/axios";

import { headers } from "./headers";
import { usecaseDefaults } from "constants/AutomationQuickstart/usecases";
import { PROVIDER_DISPLAY_NAME } from "../models/AutomationFilterModel";
// import usecases from "constants/AutomationQuickstart/usecases";

// Contains actions for help page

export const OPEN_TUTORIAL = "OPEN_TUTORIAL";
export const CLOSE_TUTORIAL = "CLOSE_TUTORIAL";
export const GET_DEMO_WORKFLOWS_STARTED = "GET_DEMO_WORKFLOWS_STARTED";
export const GET_DEMO_WORKFLOWS_SUCCESS = "GET_DEMO_WORKFLOWS_SUCCESS";
export const GET_DEMO_WORKFLOWS_ERROR = "GET_DEMO_WORKFLOWS_ERROR";
export const SET_SELECTED_WORKFLOWS = "SET_SELECTED_WORKFLOWS";
export const NEW_AUTOMATION_FILTER = "NEW_AUTOMATION_FILTER";
export const CLONE_AUTOMATION_FILTER = "CLONE_AUTOMATION_FILTER";
export const REMOVE_AUTOMATION_FILTER = "REMOVE_AUTOMATION_FILTER";
export const SET_AUTOMATION_FILTER = "SET_AUTOMATION_FILTER";
export const SET_CLIENT_INFO = "SET_CLIENT_INFO";
export const ADD_WORKFLOW_FILTER = "ADD_WORKFLOW_FILTER";
export const REMOVE_WORKFLOW_FILTER = "REMOVE_WORKFLOW_FILTER";
export const CLEAR_WORKFLOW_FILTER = "CLEAR_WORKFLOW_FILTER";
export const ADD_PROVIDER = "ADD_PROVIDER";
export const REMOVE_PROVIDER = "REMOVE_PROVIDER";
export const GET_PROVIDER_FILTERS_SUCCESS = "GET_PROVIDER_FILTERS_SUCCESS";
export const GET_PROVIDER_FILTERS_ERROR = "GET_PROVIDER_FILTERS_ERROR";
export const SET_SELECTED_PROVIDER_TYPE = "SET_SELECTED_PROVIDER_TYPE";
export const SET_REVIEW_CONFIRMED = "SET_REVIEW_CONFIRMED";
export const SAVE_PROVIDER_FILTER_STARTED = "SAVE_PROVIDER_FILTER_STARTED";
export const SAVE_PROVIDER_FILTER_SUCCESS = "SAVE_PROVIDER_FILTER_SUCCESS";
export const SAVE_PROVIDER_FILTER_ERROR = "SAVE_PROVIDER_FILTER_ERROR";
export const SET_SNOW_CONFIG = "SET_SNOW_CONFIG";

export function setSNOWConfig(config) {
  return {
    type: SET_SNOW_CONFIG,
    config
  };
}

function saveProviderFilterStarted() {
  return {
    type: SAVE_PROVIDER_FILTER_STARTED
  };
}

function saveProviderFilterSuccess() {
  return {
    type: SAVE_PROVIDER_FILTER_SUCCESS
  };
}

function saveProviderFilterError(error) {
  return {
    type: SAVE_PROVIDER_FILTER_ERROR,
    error
  };
}

const getUpdateConfRequest = async (
  providerConf,
  payload,
  provider,
  options
) => {
  const url = `${config.urls.base}/${config.urls.apis["ticket-management"]}/plugin/${provider}/conf`;
  // apply the filters to config field
  const extraFilterIdx = providerConf.fields.findIndex(
    field => field.id === "EXTRA_FILTERS"
  );
  if (extraFilterIdx !== -1) {
    providerConf.fields[extraFilterIdx].value = JSON.stringify(payload);
  }
  options.body = JSON.stringify(providerConf);
  try {
    return await fetch(url, options).then(res => res.json());
  } catch (err) {
    throw err;
  }
};

export function saveProviderFilter(configs, providerPayload) {
  return async (dispatch, getState) => {
    if (configs && configs.byId) {
      dispatch(saveProviderFilterStarted());
      const options = {
        method: "PUT",
        headers: headers(getState)
      };
      const postRequests = []; // all requests for updating provider conf
      for (const provider of Object.keys(providerPayload)) {
        const payload = providerPayload[provider];
        // get the configuration file of provider
        const providerConf = configs.byId[provider];
        if (providerConf) {
          const updateRequest = getUpdateConfRequest(
            providerConf,
            payload,
            provider,
            options
          );
          postRequests.push(updateRequest);
        }
      }
      // resolve all requests before declaring as done
      try {
        await Promise.all(postRequests);
        dispatch(saveProviderFilterSuccess());
      } catch (err) {
        dispatch(saveProviderFilterError(err));
      }
    }
  };
}

export function setSelectedProviderType(providerType) {
  return {
    type: SET_SELECTED_PROVIDER_TYPE,
    providerType
  };
}

export function setClientInfo(newDetails) {
  return {
    type: SET_CLIENT_INFO,
    newDetails
  };
}

export function newAutomationFilter() {
  return {
    type: NEW_AUTOMATION_FILTER
  };
}

export function cloneAutomationFilter() {
  return {
    type: CLONE_AUTOMATION_FILTER
  };
}

export function setAutomationFilter(index, newValue) {
  return {
    type: SET_AUTOMATION_FILTER,
    index,
    newValue
  };
}

export function removeAutomationFilter(index) {
  return {
    type: REMOVE_AUTOMATION_FILTER,
    index
  };
}

export function setSelectedWorkflows(workflows) {
  return {
    type: SET_SELECTED_WORKFLOWS,
    workflows
  };
}

export function addProvider(provider) {
  return {
    type: ADD_PROVIDER,
    provider
  };
}

export function removeProvider(provider) {
  return {
    type: REMOVE_PROVIDER,
    provider
  };
}

export function openTutorial() {
  history.push("/atr/settings");
  return {
    type: OPEN_TUTORIAL,
    displayJoyride: true
  };
}

export function closeTutorial() {
  return {
    type: CLOSE_TUTORIAL,
    displayJoyride: false
  };
}

export function getDemoWorkflows(page = 0, perPage = 500) {
  const url = `${config.urls.base}${config.urls.apis.aqev2}/workflow?page=${page}&perPage=${perPage}`;

  return async (dispatch, getState) => {
    dispatch(getDemoWorkflowsStarted());
    const options = {
      headers: headers(getState)
    };
    try {
      const data = await fetch(url, options).then(res => res.json());
      let demoWorkflows = data.items;
      demoWorkflows = demoWorkflows.map(workflow => {
        if (workflow.name in usecaseDefaults) {
          if (workflow.description === null) {
            workflow.description = usecaseDefaults[workflow.name].description;
          }
          if (workflow.tags === null) {
            workflow.tags = [usecaseDefaults[workflow.name].tags];
          }
        }
        return workflow;
      });
      demoWorkflows = data.items.filter(
        workflow => workflow.tags && workflow.tags.length > 0
      );

      // ====================================================
      // TODO uncomment if mock data is required
      // Mocking response
      // const demoWorkflows = [];
      // const emptyWorkflow = {
      //   creationDate: null,
      //   deletionDate: null,
      //   description: null,
      //   extraTriggerPermissions: [],
      //   id: "",
      //   inputParameters: [],
      //   jobRetry: null,
      //   lastModificationDate: null,
      //   name: "",
      //   nodes: [],
      //   outputParameters: [],
      //   statistics: null,
      //   tags: null,
      //   topology: null,
      //   version: "V2"
      // };
      //
      // Object.entries(usecases).forEach(([category, subcategories]) => {
      //   Object.entries(subcategories).forEach(([subcategory, workflows]) => {
      //     workflows.forEach(workflow => {
      //       demoWorkflows.push({
      //         ...emptyWorkflow,
      //         lastModificationDate: new Date(Date.now()).toISOString(),
      //         name: workflow,
      //         tags: [`${category}/${subcategory}`],
      //         id: `${category}/${subcategory}/${workflow}`
      //       });
      //     });
      //   });
      // });
      // ====================================================
      dispatch(getDemoWorkflowsSuccess(demoWorkflows));
    } catch (e) {
      dispatch(getDemoWorkflowsError(e));
    }
  };
}

function getDemoWorkflowsStarted() {
  return {
    type: GET_DEMO_WORKFLOWS_STARTED
  };
}

function getDemoWorkflowsSuccess(demoWorkflows) {
  return {
    type: GET_DEMO_WORKFLOWS_SUCCESS,
    payload: {
      data: demoWorkflows
    }
  };
}

function getDemoWorkflowsError(error) {
  return {
    type: GET_DEMO_WORKFLOWS_ERROR,
    payload: {
      error
    }
  };
}

export function updateUserDemo(demoObject, user) {
  return (dispatch, getState) => {
    const url = `${config.urls.base +
      config.urls.apis["identity-management"]}/users/demo/${user.id}`;
    return axios.put(url, demoObject).then(res => {
      return true; // no response from api so will not return any json
    });
  };
}

export function addWorkflowFilter(category) {
  return {
    type: ADD_WORKFLOW_FILTER,
    category
  };
}

export function removeWorkflowFilter(category) {
  return {
    type: REMOVE_WORKFLOW_FILTER,
    category
  };
}

export function clearWorkflowFilter() {
  return {
    type: CLEAR_WORKFLOW_FILTER
  };
}

const getProviderFiltersSuccess = filters => {
  return {
    type: GET_PROVIDER_FILTERS_SUCCESS,
    payload: {
      data: filters
    }
  };
};

const getProviderFiltersError = error => {
  return {
    type: GET_PROVIDER_FILTERS_ERROR,
    payload: {
      error
    }
  };
};

export function getProviderFilters(selectedProviders) {
  const url = `${config.urls.base}${config.urls.apis["ticket-management"]}/ticketsource`;
  return async (dispatch, getState) => {
    const options = {
      headers: headers(getState)
    };
    try {
      const data = await fetch(url, options).then(res => res.json());

      // initialise provider filters
      const providerFilters = {};
      for (const provider of selectedProviders) {
        providerFilters[provider] = {
          displayName: PROVIDER_DISPLAY_NAME[provider],
          ticketTypes: []
        };
      }

      // empty filter for selected provider ticket types
      for (const source of data) {
        const {
          description: { ticketType, displayName, providerId }
        } = source;
        if (selectedProviders.includes(providerId)) {
          providerFilters[providerId].ticketTypes.push({
            ticketType,
            displayName,
            filters: [],
            providerId
          });
        }
      }

      dispatch(getProviderFiltersSuccess(providerFilters));
    } catch (err) {
      dispatch(getProviderFiltersError(err));
    }
  };
}

export function setReviewConfirmed() {
  return {
    type: SET_REVIEW_CONFIRMED
  };
}
