import React from "react";
import PropTypes from "prop-types";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Divider,
  StepContainer,
  StepLabel,
  StepNumber,
  TabLabelContainer,
  CompleteIcon
} from "./styles";

const TabLabel = props => {
  const { index, text, curStep, stepNum } = props;
  return (
    <TabLabelContainer>
      <StepContainer>
        {curStep > stepNum ? (
          <CompleteIcon icon={faCheckCircle} />
        ) : (
          <StepNumber>{index + 1}</StepNumber>
        )}
      </StepContainer>
      <Divider />
      <StepLabel>{text}</StepLabel>
    </TabLabelContainer>
  );
};

TabLabel.propTypes = {
  text: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  stepNum: PropTypes.number.isRequired,
  curStep: PropTypes.number
};

export default TabLabel;
