import { connect } from "react-redux";
import FilterRow from "../index";
import {
  removeAutomationFilter,
  setAutomationFilter,
  cloneAutomationFilter
} from "actions/tutorial";

const mapStateToProps = (state, ownProps) => ({
  filter: ownProps.filter,
  index: ownProps.index
});

const mapDispatchToProps = dispatch => ({
  removeFilter: index => dispatch(removeAutomationFilter(index)),
  setFilter: (index, newValue) =>
    dispatch(setAutomationFilter(index, newValue)),
  cloneFilter: () => dispatch(cloneAutomationFilter())
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterRow);
