import React from "react";
import BulkActionModel from "models/BulkActionModel";
import moment from "moment";
import PropTypes from "prop-types";
import { Typography, LinearProgress, Grid } from "@material-ui/core";
import styled from "styled-components";
import LabeledIcon, {
  InfoText,
  StyledMoreHoriz,
  StyledWarning,
  StyledCheckCircle
} from "./LabeledIcon";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const MainContainer = styled.div`
  padding: 15px;
  width: 100%;
`;

const HeaderContainer = styled(Grid)`
  margin: 10px 0px;
`;

const TitleText = styled(Typography)`
  text-transform: uppercase;
  font-size: 13px;
  line-height: 18px;
  color: #2c2c2c;
`;

const InfoIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify: space-between;
  div:not(:first-child) {
    margin-left: 20px;
  }
`;

const getStatus = action => {
  const status = action.status.replace("_", " ");
  return (
    status.charAt(0).toUpperCase() +
    status.substring(1, status.length).toLowerCase()
  );
};

/**
 * Retrieves the time information string
 * @param action
 */
const getTimeInfo = (action, timezone) => {
  if (action.finished) {
    const date = dayjs(action.finished)
      .tz(timezone)
      .format("dddd, MMMM DD, YYYY hh:mm A");
    // TODO fix the spelling of backend cancelled
    const status =
      action.status === "CANCELED" || action.status === "FAILED"
        ? getStatus(action)
        : "Completed";
    return `${status} on ${date}`;
  } else {
    const date = dayjs(action.created)
      .tz(timezone)
      .format("dddd, MMMM DD, YYYY hh:mm A");
    return `Started on ${date}`;
  }
};

const BulkActionDetails = props => {
  const { bulkAction, hasStatus, timezone, ...rest } = props;
  const title = bulkAction.request.action;
  const timeInfo = getTimeInfo(bulkAction, timezone);
  const status = getStatus(bulkAction);
  const { errors, processedCount, total } = bulkAction;

  const errorCount = errors ? errors.length : 0;
  const pendingCount = total - processedCount;

  // TODO update "cancelled" value once backend is fixed
  const color =
    status === "Failed" || status === "Canceled" ? "secondary" : "primary";

  return (
    <MainContainer {...rest}>
      <HeaderContainer container>
        <Grid item xs>
          <TitleText align="left">{title}</TitleText>
        </Grid>
        <Grid item xs>
          <InfoText variant="caption" align="right">
            {hasStatus ? status : timeInfo}
          </InfoText>
        </Grid>
      </HeaderContainer>
      <LinearProgress
        variant="determinate"
        color={color}
        value={bulkAction.percent || 0}
      />
      <InfoIconsContainer>
        <LabeledIcon
          icon={<StyledCheckCircle />}
          label={`${processedCount} Actioned`}
        />
        <LabeledIcon icon={<StyledWarning />} label={`${errorCount} Errors`} />
        <LabeledIcon
          icon={<StyledMoreHoriz />}
          label={`${pendingCount} Pending`}
        />
      </InfoIconsContainer>
    </MainContainer>
  );
};

BulkActionDetails.propTypes = {
  bulkAction: BulkActionModel,
  hasStatus: PropTypes.bool
};

export default BulkActionDetails;
