import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

export const BodyContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

export const PageText = styled.span`
  font-size: 12px;
  padding: 0px 5px;
`;

export const DeepDiveButton = styled(Button)`
  color: #378bee !important;
  border-color: #378bee !important;
`;
