import { connect } from "react-redux";

import AutomationTour from "../../components/settings/AutomationTour";

import { updateUserDemo } from "../../actions/tutorial";

import { openSnackbar } from "../../actions/snackbar";

const mapStateToProps = state => ({
  userProfile: state.user.userProfile,
  permissions: state.user.permissions,
  token: state.auth.token
});

const mapDispatchToProps = dispatch => ({
  updateUserDemo: (demo, user) => dispatch(updateUserDemo(demo, user)),
  openSnackbar: msg => dispatch(openSnackbar(msg))
});

const AutomationTourPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationTour);

export default AutomationTourPage;
