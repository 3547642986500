import update from "immutability-helper";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const UPDATE_TICKET_TEMPLATE_FIELDS =
  "ticketTemplate/UPDATE_TICKET_TEMPLATE_FIELDS";

//Saga
export const GET_TEMPLATE_FIELDS = "ticketTemplate/GET_TEMPLATE_FIELDS";
export const UPDATE_TICKET_TEMPLATE = "ticketTemplate/UPDATE_TICKET_TEMPLATE";
export const GET_ML_MAPPING_FIELDS = "ticketTemplate/GET_ML_MAPPING_FIELDS";
export const DELETE_TICKET_TEMPLATE = "ticketTemplate/DELETE_TICKET_TEMPLATE";
export const ACTIVATE_TICKET_TEMPLATE =
  "ticketTemplate/ACTIVATE_TICKET_TEMPLATE";
export const DEACTIVATE_TICKET_TEMPLATE =
  "ticketTemplate/DEACTIVATE_TICKET_TEMPLATE";
export const CREATE_TICKET_TEMPLATE = "ticketTemplate/CREATE_TICKET_TEMPLATE";
export const GET_ALL_TICKET_TEMPLATES =
  "ticketTemplate/GET_ALL_TICKET_TEMPLATES";
export const GET_TEMPLATES_MAPPING_FIELDS =
  "ticketTemplate/GET_TEMPLATES_MAPPING_FIELDS";

//Testing
export const GET_TEMPLATE_FIELDS_SUCCESS =
  "ticketTemplate/GET_TEMPLATE_FIELDS_SUCCESS";
export const DELETE_TICKET_TEMPLATE_SUCCESS =
  "ticketTemplate/DELETE_TICKET_TEMPLATE_SUCCESS";
export const ACTIVATE_TICKET_TEMPLATE_SUCCESS =
  "ticketTemplate/ACTIVATE_TICKET_TEMPLATE_SUCCESS";
export const DEACTIVATE_TICKET_TEMPLATE_SUCCESS =
  "ticketTemplate/DEACTIVATE_TICKET_TEMPLATE_SUCCESS";
export const GET_ALL_TICKET_TEMPLATES_SUCCESS =
  "ticketTemplate/GET_ALL_TICKET_TEMPLATES_SUCCESS";
export const TOGGLE_PRIMARY_FIELD_CHECKBOX =
  "ticketTemplate/TOGGLE_PRIMARY_FIELD_CHECKBOX";
export const TOGGLE_SECONDARY_FIELD_CHECKBOX =
  "ticketTemplate/TOGGLE_SECONDARY_FIELD_CHECKBOX";
export const SET_MAPPING_FIELD = "ticketTemplate/SET_MAPPING_FIELD";
export const INITIALIZE_CURRENT_TEMPLATE =
  "ticketTemplate/INITIALIZE_CURRENT_TEMPLATE";

const GET_TEMPLATE_FIELDS_PENDING =
  "ticketTemplate/GET_TEMPLATE_FIELDS_PENDING";
const GET_TEMPLATE_FIELDS_ERROR = "ticketTemplate/GET_TEMPLATE_FIELDS_ERROR";

const UPDATE_TICKET_TEMPLATE_PENDING =
  "ticketTemplate/UPDATE_TICKET_TEMPLATE_PENDING";
const UPDATE_TICKET_TEMPLATE_SUCCESS =
  "ticketTemplate/UPDATE_TICKET_TEMPLATE_SUCCESS";
const UPDATE_TICKET_TEMPLATE_ERROR =
  "ticketTemplate/UPDATE_TICKET_TEMPLATE_ERROR";

const GET_ML_MAPPING_FIELDS_PENDING =
  "ticketTemplate/GET_ML_MAPPING_FIELDS_PENDING";
const GET_ML_MAPPING_FIELDS_SUCCESS =
  "ticketTemplate/GET_ML_MAPPING_FIELDS_SUCCESS";
const GET_ML_MAPPING_FIELDS_ERROR =
  "ticketTemplate/GET_ML_MAPPING_FIELDS_ERROR";

const DELETE_TICKET_TEMPLATE_PENDING =
  "ticketTemplate/DELETE_TICKET_TEMPLATE_PENDING";
const DELETE_TICKET_TEMPLATE_ERROR =
  "ticketTemplate/DELETE_TICKET_TEMPLATE_ERROR";

const ACTIVATE_TICKET_TEMPLATE_PENDING =
  "ticketTemplate/ACTIVATE_TICKET_TEMPLATE_PENDING";

const ACTIVATE_TICKET_TEMPLATE_ERROR =
  "ticketTemplate/ACTIVATE_TICKET_TEMPLATE_ERROR";

const DEACTIVATE_TICKET_TEMPLATE_PENDING =
  "ticketTemplate/DEACTIVATE_TICKET_TEMPLATE_PENDING";

const DEACTIVATE_TICKET_TEMPLATE_ERROR =
  "ticketTemplate/DEACTIVATE_TICKET_TEMPLATE_ERROR";

const CREATE_TICKET_TEMPLATE_PENDING =
  "ticketTemplate/CREATE_TICKET_TEMPLATE_PENDING";
const CREATE_TICKET_TEMPLATE_SUCCESS =
  "ticketTemplate/CREATE_TICKET_TEMPLATE_SUCCESS";
const CREATE_TICKET_TEMPLATE_ERROR =
  "ticketTemplate/CREATE_TICKET_TEMPLATE_ERROR";

const GET_ALL_TICKET_TEMPLATES_PENDING =
  "ticketTemplate/GET_ALL_TICKET_TEMPLATES_PENDING";
const GET_ALL_TICKET_TEMPLATES_ERROR =
  "ticketTemplate/GET_ALL_TICKET_TEMPLATES_ERROR";

const SET_NEW_TICKET_NAME = "ticketTemplate/SET_NEW_TICKET_NAME";
const SELECT_TEMPLATE_TYPE = "ticketTemplate/SELECT_TEMPLATE_TYPE";
const TICKET_TEMPLATE_CHANGE_ROUTE =
  "ticketTemplate/TICKET_TEMPLATE_CHANGE_ROUTE";
const INCREASE_PAGE = "ticketTemplate/INCREASE_PAGE";
const DECREASE_PAGE = "ticketTemplate/DECREASE_PAGE";
const SET_FIELD_TAB = "ticketTemplate/SET_FIELD_TAB";
const SET_FIELD_FILTER = "ticketTemplate/SET_FIELD_FILTER";
const SET_ML_TAB = "ticketTemplate/SET_ML_TAB";
const RESET_CURRENT_TEMPLATE = "ticketTemplate/RESET_CURRENT_TEMPLATE";
const RESET_VIEWER_STATUS = "ticketTemplate/RESET_VIEWER_STATUS";
const DELETED_TICKET_TEMPLATE = "ticketTemplate/DELETED_TICKET_TEMPLATE";

const initialState = {
  templates: [],
  templateFields: {},
  deletedTicketTemplate: {},
  templateTypes: [],
  isFetchingTemplates: false,
  isFetchingTemplateFields: false,
  isChangingTemplates: false,
  currentPage: 0,
  currentTab: 0,
  fieldFilter: "",
  route: "",
  MLMappingFields: [],
  currentTemplate: {
    active: null,
    created: null,
    id: null,
    name: "",
    ticketType: "",
    ticketSource: {},
    mapping: {},
    states: [],
    primaryFields: [],
    secondaryFields: []
  }
};

const deletedTemplatePersistConfig = {
  key: "deletedTemplatePersistedStore",
  storage: storage,
  whitelist: ["deletedTicketTemplate"] // only deletedTicketTemplate will be persisted in store
};

const ticketTemplates = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TICKET_TEMPLATES_PENDING: {
      return { ...state, isFetchingTemplates: true };
    }
    case GET_ALL_TICKET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        isFetchingTemplates: false,
        templates: action.items
      };
    }
    case ACTIVATE_TICKET_TEMPLATE_PENDING: {
      return { ...state, isChangingTemplates: true };
    }
    case DEACTIVATE_TICKET_TEMPLATE_PENDING: {
      return { ...state, isChangingTemplates: true };
    }
    case GET_ML_MAPPING_FIELDS_SUCCESS: {
      return {
        ...state,
        MLMappingFields: action.data
      };
    }
    case GET_ML_MAPPING_FIELDS_PENDING: {
      return { ...state };
    }
    case CREATE_TICKET_TEMPLATE_PENDING: {
      return { ...state, isChangingTemplates: true };
    }
    case UPDATE_TICKET_TEMPLATE_PENDING: {
      return { ...state, isChangingTemplates: true };
    }
    case DELETE_TICKET_TEMPLATE_PENDING: {
      return {
        ...state,
        isChangingTemplates: true
      };
    }
    case ACTIVATE_TICKET_TEMPLATE_SUCCESS: {
      const index = state.templates.findIndex(
        template => template.id === action.id
      );
      return {
        ...state,
        isChangingTemplates: false,
        templates: update(state.templates, {
          [index]: {
            active: { $set: true }
          }
        })
      };
    }
    case DEACTIVATE_TICKET_TEMPLATE_SUCCESS: {
      const index = state.templates.findIndex(
        template => template.id === action.id
      );
      return {
        ...state,
        isChangingTemplates: false,
        templates: update(state.templates, {
          [index]: {
            active: { $set: false }
          }
        })
      };
    }
    case CREATE_TICKET_TEMPLATE_SUCCESS: {
      return { ...state, isChangingTemplates: false };
    }
    case UPDATE_TICKET_TEMPLATE_SUCCESS: {
      return { ...state, isChangingTemplates: false };
    }
    case DELETED_TICKET_TEMPLATE: {
      return {
        ...state,
        deletedTicketTemplate: state.templates.filter(
          template => template.name === action.name
        )[0]
      };
    }
    case DELETE_TICKET_TEMPLATE_SUCCESS: {
      return {
        ...state,
        isChangingTemplates: false,
        templates: state.templates.filter(
          template => template.name !== action.name
        )
      };
    }
    case GET_ALL_TICKET_TEMPLATES_ERROR: {
      return { ...state, isFetchingTemplates: false };
    }
    case GET_TEMPLATE_FIELDS_PENDING: {
      return { ...state, isFetchingTemplateFields: true };
    }
    case GET_TEMPLATE_FIELDS_SUCCESS: {
      return {
        ...state,
        templateFields: action.data,
        isFetchingTemplateFields: false,
        templateTypes: Object.keys(action.data)
      };
    }
    case GET_TEMPLATE_FIELDS_ERROR: {
      return { ...state, isFetchingTemplateFields: false };
    }
    case UPDATE_TICKET_TEMPLATE_FIELDS: {
      return { ...state, templateFields: action.templateFields };
    }

    case TICKET_TEMPLATE_CHANGE_ROUTE: {
      return {
        ...state,
        route: action.route
      };
    }
    case SELECT_TEMPLATE_TYPE: {
      return update(state, {
        currentTemplate: {
          ticketType: { $set: action.ticketType },
          primaryFields: { $set: [] },
          secondaryFields: { $set: [] }
        }
      });
    }
    case SET_NEW_TICKET_NAME: {
      return update(state, {
        currentTemplate: {
          name: { $set: action.name }
        }
      });
    }

    case INCREASE_PAGE:
      return {
        ...state,
        currentPage: state.currentPage + 1
      };
    case DECREASE_PAGE:
      return {
        ...state,
        currentPage: state.currentPage - 1
      };
    case SET_FIELD_TAB:
      return {
        ...state,
        currentTab: action.tab
      };
    case SET_FIELD_FILTER:
      return {
        ...state,
        fieldFilter: action.fieldFilter
      };
    case TOGGLE_PRIMARY_FIELD_CHECKBOX: {
      if (action.value) {
        return update(state, {
          currentTemplate: {
            primaryFields: { $push: [action.item] }
          }
        });
      }
      if (!action.value) {
        return {
          ...state,
          currentTemplate: {
            ...state.currentTemplate,
            primaryFields: state.currentTemplate.primaryFields.filter(
              field => field.name !== action.item.name
            )
          }
        };
      }
      break;
    }

    case TOGGLE_SECONDARY_FIELD_CHECKBOX: {
      if (action.value) {
        return update(state, {
          currentTemplate: {
            secondaryFields: { $push: [action.item] }
          }
        });
      }
      if (!action.value) {
        return {
          ...state,
          currentTemplate: {
            ...state.currentTemplate,
            secondaryFields: state.currentTemplate.secondaryFields.filter(
              field => field.name !== action.item.name
            )
          }
        };
      }
      break;
    }
    case RESET_CURRENT_TEMPLATE: {
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          name: "",
          active: null,
          created: null,
          id: null,
          ticketType: "",
          ticketSource: {},
          mapping: {},
          states: [],
          primaryFields: [],
          secondaryFields: []
        }
      };
    }
    case RESET_VIEWER_STATUS: {
      return {
        ...state,
        currentPage: 0,
        currentTab: 0,
        fieldFilter: ""
      };
    }
    case SET_MAPPING_FIELD: {
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          mapping: {
            ...state.currentTemplate.mapping,
            [action.fieldName]: action.mappingValue
          }
        }
      };
    }

    case INITIALIZE_CURRENT_TEMPLATE: {
      if (action.route === "new") {
        return {
          ...state,
          currentTemplate: {
            active: null,
            created: null,
            id: null,
            name: "",
            ticketType: "",
            ticketSource: {},
            mapping: {},
            states: [],
            primaryFields: [],
            secondaryFields: []
          }
        };
      } else {
        return update(state, {
          currentTemplate: {
            $set: {
              ...state.currentTemplate,
              ...state.templates.filter(
                template => template.id === action.route
              )[0]
            }
          }
        });
      }
    }
    default:
      return state;
  }
};

export default persistReducer(deletedTemplatePersistConfig, ticketTemplates);

//get template fields
export const getTemplateFields = () => ({
  type: GET_TEMPLATE_FIELDS
});

export const getTemplateFieldsPending = () => ({
  type: GET_TEMPLATE_FIELDS_PENDING
});

export const getTemplateFieldsSuccess = data => ({
  type: GET_TEMPLATE_FIELDS_SUCCESS,
  data
});

export const getTemplateFieldsError = () => ({
  type: GET_TEMPLATE_FIELDS_ERROR
});

//update ticket template
export const updateTicketTemplate = () => ({
  type: UPDATE_TICKET_TEMPLATE
});
export const updateTicketTemplateSuccess = () => ({
  type: UPDATE_TICKET_TEMPLATE_SUCCESS
});
export const updateTicketTemplateError = () => ({
  type: UPDATE_TICKET_TEMPLATE_ERROR
});
export const updateTicketTemplatePending = () => ({
  type: UPDATE_TICKET_TEMPLATE_PENDING
});

//delete ticket template
export const deleteTemplatePending = () => ({
  type: DELETE_TICKET_TEMPLATE_PENDING
});

export const deleteTemplateSuccess = name => ({
  type: DELETE_TICKET_TEMPLATE_SUCCESS,
  name: name
});

export const deletedTicketTemplate = name => ({
  type: DELETED_TICKET_TEMPLATE,
  name: name
});

export const deleteTemplateError = name => ({
  type: DELETE_TICKET_TEMPLATE_ERROR
});

export const deleteTemplate = name => ({
  type: DELETE_TICKET_TEMPLATE,
  name: name
});

//active ticket template
export const activateTemplatePending = () => ({
  type: ACTIVATE_TICKET_TEMPLATE_PENDING
});

export const activateTemplateSuccess = id => ({
  type: ACTIVATE_TICKET_TEMPLATE_SUCCESS,
  id: id
});

export const activateTemplateError = () => ({
  type: ACTIVATE_TICKET_TEMPLATE_ERROR
});

export const activateTemplate = id => ({
  type: ACTIVATE_TICKET_TEMPLATE,
  id: id
});

//deactivate ticket template
export const deactivateTemplatePending = () => ({
  type: DEACTIVATE_TICKET_TEMPLATE_PENDING
});

export const deactivateTemplateSuccess = id => ({
  type: DEACTIVATE_TICKET_TEMPLATE_SUCCESS,
  id: id
});

export const deactivateTemplateError = () => ({
  type: DEACTIVATE_TICKET_TEMPLATE_ERROR
});

export const deactivateTemplate = id => ({
  type: DEACTIVATE_TICKET_TEMPLATE,
  id: id
});

//create ticket template
export const createTemplatePending = () => ({
  type: CREATE_TICKET_TEMPLATE_PENDING
});

export const createTemplateSuccess = () => ({
  type: CREATE_TICKET_TEMPLATE_SUCCESS
});

export const createTemplateError = () => ({
  type: CREATE_TICKET_TEMPLATE_ERROR
});

export const createTemplate = id => ({
  type: CREATE_TICKET_TEMPLATE,
  id: id
});

//get all ticket template
export const getAllTicketTemplatesPending = () => ({
  type: GET_ALL_TICKET_TEMPLATES_PENDING
});

export const getAllTicketTemplatesSuccess = ({ items }) => ({
  type: GET_ALL_TICKET_TEMPLATES_SUCCESS,
  items
});

export const getAllTicketTemplatesError = () => ({
  type: GET_ALL_TICKET_TEMPLATES_ERROR
});

export const getAllTicketTemplates = () => ({
  type: GET_ALL_TICKET_TEMPLATES
});

//get mapping fields
export const getMLMappingFields = () => ({
  type: GET_ML_MAPPING_FIELDS
});

export const getMLMappingFieldsPending = () => ({
  type: GET_ML_MAPPING_FIELDS_PENDING
});

export const getMLMappingFieldsSuccess = data => ({
  type: GET_ML_MAPPING_FIELDS_SUCCESS,
  data
});

export const getMLMappingFieldsError = () => ({
  type: GET_ML_MAPPING_FIELDS_ERROR
});

//get templates, mappings, fields in one call
export const getTemplateMappingFields = () => ({
  type: GET_TEMPLATES_MAPPING_FIELDS
});

// change route
export const changeRoute = route => ({
  type: TICKET_TEMPLATE_CHANGE_ROUTE,
  route: route
});

//update template
export const updateTemplateFields = templateFields => ({
  type: UPDATE_TICKET_TEMPLATE_FIELDS,
  templateFields
});

//select type

export const setTicketType = ticketType => ({
  type: SELECT_TEMPLATE_TYPE,
  ticketType: ticketType
});

//change name
export const setName = name => ({
  type: SET_NEW_TICKET_NAME,
  name: name
});

export const increasePage = () => ({
  type: INCREASE_PAGE
});

export const decreasePage = () => ({
  type: DECREASE_PAGE
});

export const setFieldTab = tab => ({
  type: SET_FIELD_TAB,
  tab
});

export const setFieldFilter = fieldFilter => ({
  type: SET_FIELD_FILTER,
  fieldFilter: fieldFilter
});

export const setMLTab = tab => ({
  type: SET_ML_TAB,
  tab
});

export const TogglePrimaryCheckBox = (item, value) => {
  return {
    type: TOGGLE_PRIMARY_FIELD_CHECKBOX,
    item,
    value
  };
};

export const ToggleSecondaryCheckBox = (item, value) => {
  return {
    type: TOGGLE_SECONDARY_FIELD_CHECKBOX,
    item,
    value
  };
};

export const resetCurrentTemplate = () => ({
  type: RESET_CURRENT_TEMPLATE
});

export const setMappingField = (fieldName, mappingValue) => ({
  type: SET_MAPPING_FIELD,
  fieldName,
  mappingValue
});

export const initializeCurrentTemplate = route => ({
  type: INITIALIZE_CURRENT_TEMPLATE,
  route: route
});

export const resetViewerStatus = () => ({
  type: RESET_VIEWER_STATUS
});
