import React, { useState, useEffect } from "react";
import Styles from "../../../styles/themeMainUI";
import MLField from "./MLField";
import AutomationJob from "./AutomationJob";
import TopologyTree from "./TopologyTree";
import PermissionCheck from "../../errors/PermissionCheck";
import TicketResolution from "./TicketResolution";

const AutomationTab = props => {
  const [mlData, setMLData] = useState({});
  const {
    mlObj,
    ticket,
    fields,
    _handleOverrideML,
    drawerFunctions,
    handleTicketUpdate,
    jobIsFetching,
    jobSelected,
    ticketGetSingleJobDefinition,
    ticketSelectAutomationJob,
    ticketModifySingleJobDefinition,
    mappingRundecks,
    rundeckProjects,
    mappingJobs,
    matchedMappings,
    isFetchingRundeckProjects,
    isFetchingMappingJobs,
    getRundeckProjects,
    getRundeckJobs,
    clearMappingJobs,
    clearRundeckProjects,
    mappingFound,
    disableAutomation,
    homemadeFields
  } = props;

  const gtr = ticket.mlData.find(d => d.key === window.config.gtr.gtrKey);
  const tm = ticket.mlData.find(d => d.key === window.config.gtr.tmKey);
  const isValueGTR = () => {
    if (!tm) return false;
    if (tm.userValue === "GTR") {
      return true;
    } else if (tm.userValue === null) {
      if (tm.predictions && tm.predictions[0].label === "GTR") {
        return true;
      }
    } else {
      return false;
    }
    return false;
  };

  function renderAutomation() {
    if (disableAutomation) return null;
    return (
      <PermissionCheck
        requiredPermissions={[
          "TM_AUTOMATION_MAPPING_READ",
          "TM_CONFIG_READ",
          "TM_AUTOMATION_RULE_READ"
        ]}
        permissions={props.permissions}
      >
        <AutomationJob
          updateMappingFound={props.updateMappingFound}
          ticket={ticket}
          jobIsFetching={jobIsFetching}
          jobSelected={jobSelected}
          ticketGetSingleJobDefinition={ticketGetSingleJobDefinition}
          ticketSelectAutomationJob={ticketSelectAutomationJob}
          ticketModifySingleJobDefinition={ticketModifySingleJobDefinition}
          mappingRundecks={mappingRundecks}
          rundeckProjects={rundeckProjects}
          mappingJobs={mappingJobs}
          isFetchingRundeckProjects={isFetchingRundeckProjects}
          isFetchingMappingJobs={isFetchingMappingJobs}
          getRundeckProjects={getRundeckProjects}
          getRundeckJobs={getRundeckJobs}
          clearMappingJobs={clearMappingJobs}
          clearRundeckProjects={clearRundeckProjects}
          mappingFound={mappingFound}
          matchedMappings={matchedMappings}
        />
      </PermissionCheck>
    );
  }

  return (
    <div style={{ padding: "20px 5px", overflow: "hidden" }}>
      {ticket?.mlData?.[0] ? (
        <>
          {ticket.mlData
            .filter(mlPrediction => {
              return fields.automation.find(fieldProp => {
                return (
                  fieldProp.name === mlPrediction.key && !fieldProp.hideField
                );
              });
            })
            .map(mlPrediction => {
              const templateField = (fields?.automation || []).filter(
                fieldProp => fieldProp.name === mlPrediction.key
              )[0];
              return (
                <div key={templateField.name}>
                  <MLField
                    manualResolutionConfig={props.manualResolutionConfig}
                    atsInvalid={props.atsInvalid}
                    templateField={templateField}
                    mlField={mlPrediction}
                    handleOverrideML={_handleOverrideML}
                    drawerFunctions={drawerFunctions}
                    handleTicketUpdate={handleTicketUpdate}
                    {...props}
                  />
                </div>
              );
            })}
        </>
      ) : (
        <div
          style={{
            margin: 20,
            padding: 20,
            fontSize: 13,
            backgroundColor: Styles.palette.accent2Color,
            color: Styles.palette.accent3Color
          }}
        >
          {homemadeFields && homemadeFields.length > 0
            ? "All machine learning predictions have been extracted out into separate tabs. Please click those tabs for your ticket's predictions."
            : "No relevant machine learning predictions are made for this ticket. Check whether your models has been configured properly. Press the update button to try again."}
        </div>
      )}
      {/* {Automation Job} */}
      {renderAutomation()}
      {/* //TODO: Get suggestion annotation to clear when changing job manually. */}
      {/* {state.mappingFound && */}
      {/* <div>Suggested by mapping {state.mappingFound.mappingName}</div> */}
      {/* } */}
    </div>
  );
};

export default AutomationTab;
