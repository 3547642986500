import React from "react";
import _ from "lodash";
import muiTheme from "../../../styles/themeMainUI";

class MLPredictions extends React.Component {
  render() {
    const { ticket, handleTicketUpdate, _handleOverrideML, MLKey } = this.props;
    // Uses largest remainder method to ensure values hit rounded target
    const _giveRounded = (valuesArray, target) => {
      // calculate distance from target
      const offBy =
        target - _.reduce(valuesArray, (acc, x) => acc + Math.round(x), 0);
      return (
        _.chain(valuesArray)
          // sort by decimal parts
          .sort(x => Math.round(x) - x)
          // Adjust values to reach target
          .map(
            (x, i) =>
              Math.round(x) + (offBy > i) - (i >= valuesArray.length + offBy)
          )
          .value()
          .sort((a, b) => b - a)
      ); // return in descending order
    };
    const rawPredictions = this.props.predictions.map(item => 100 * item.prob);
    const rawPredictionsSum = Math.ceil(
      _.reduce(rawPredictions, (acc, x) => acc + x)
    );
    const targetValue =
      rawPredictionsSum - (rawPredictionsSum > 100) * (rawPredictionsSum - 100);
    const allocPredictions = _giveRounded(rawPredictions, targetValue);

    const _returnTicketValue = (ticket, MLKey) => {
      switch (MLKey) {
        case "u_knowledge":
          return ticket.automationData.sourceJobName;
        case "ticket_management":
          return ticket.automationData.sourceServiceName;
        case "gtr":
          return (
            ticket.mlData.filter(mlD => mlD.key === "gtr")[0].userValue ||
            ticket.serviceData.gtr
          );
        default:
          return (
            ticket.coreData[MLKey] ||
            ticket.serviceData[MLKey] ||
            ticket.mlData.filter(mlD => mlD.key === MLKey)[0].userValue
          );
      }
    };
    const unknownMLValue = "ingrAIn_unknown";
    const source = Object.keys({ ...ticket.coreData }).includes(MLKey)
      ? "coreData"
      : Object.keys({ ...ticket.serviceData }).includes(MLKey)
      ? "serviceData"
      : false;
    return (
      <div style={{ marginTop: "4px" }}>
        {/* <div style={{fontSize:'11px', marginBottom:'2px'}}>Top {this.props.predictions.length} Suggestions</div> */}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.props.predictions.map((item, index) => {
            /* Regression predictions are null */
            if (item.prob !== null) {
              /* Classification */
              if (allocPredictions[index] > 0) {
                return (
                  <div
                    key={index}
                    style={{
                      cursor: "pointer",
                      border: "2px solid",
                      fontSize: "12px",
                      borderColor:
                        item.label === unknownMLValue
                          ? muiTheme.palette.accent3Color
                          : muiTheme.palette.primaryBtnBackgroundColor,
                      backgroundColor:
                        _returnTicketValue(ticket, MLKey) === item.label
                          ? item.label === unknownMLValue
                            ? muiTheme.palette.accent3Color
                            : muiTheme.palette.primaryBtnBackgroundColor
                          : "inherit",
                      borderRadius: "5px",
                      color:
                        _returnTicketValue(ticket, MLKey) === item.label
                          ? muiTheme.palette.canvasColor
                          : item.label === unknownMLValue
                          ? muiTheme.palette.accent3Color
                          : muiTheme.palette.primaryBtnBackgroundColor,
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      marginRight: "7px",
                      marginTop: "4px",
                      display: "flex"
                    }}
                    onClick={() => {
                      if (
                        !(MLKey === "u_knowledge") &&
                        !(MLKey === "ticket_management") &&
                        !(MLKey === "gtr") &&
                        Object.keys({
                          ...ticket.coreData,
                          ...ticket.serviceData
                        }).find(fieldName => fieldName === MLKey)
                      ) {
                        handleTicketUpdate({
                          field: {
                            name: MLKey,
                            source
                          },
                          value: item.label
                        });
                      } else {
                        handleTicketUpdate({
                          field: {
                            name: MLKey
                          },
                          value: item.label
                        });
                      }
                    }}
                  >
                    {item.label !== unknownMLValue ? item.label : "Unknown"}
                    {item.label !== unknownMLValue && (
                      <span style={{ display: "flex" }}>
                        &nbsp;&nbsp;
                        <div style={{ fontWeight: "900" }}>
                          {allocPredictions[index]}%
                        </div>
                      </span>
                    )}
                  </div>
                );
              }
            } else {
              /* Regression */
              return (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    border: "2px solid",
                    fontSize: "12px",
                    borderColor: muiTheme.palette.automationBackgroundColor,
                    backgroundColor:
                      _returnTicketValue(ticket, MLKey) === item.label
                        ? muiTheme.palette.automationBackgroundColor
                        : "inherit",
                    borderRadius: "5px",
                    color:
                      _returnTicketValue(ticket, MLKey) === item.label
                        ? muiTheme.palette.canvasColor
                        : muiTheme.palette.automationBackgroundColor,
                    paddingLeft: "3px",
                    paddingRight: "3px",
                    marginRight: "7px",
                    marginTop: "4px",
                    display: "flex"
                  }}
                  onClick={() => {
                    this.props._handleOverrideML({
                      field: this.props.MLKey,
                      value: item.label
                    });
                  }}
                >
                  Regression Prediction
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }
}

export default MLPredictions;
