import {
  CLEAR_NOTIFICATION,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_PENDING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_UNREAD_SUCCESS,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  NOTIFICATIONS_STREAM_NEW_NOTIFICATION,
  MARK_NOTIFICATIONS_ALL_READ_SUCCESS
} from "../actions/notification";

const defaultState = {
  isFetching: false,
  unreadNotifications: [],
  unreadNotificationsBadge: [],
  notifications: [],
  amountOfUnreadNotifications: 0
};

function notification(state = defaultState, action) {
  switch (action.type) {
    // =============== Notifications Reducers ===============
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        isFetching: false,
        notifications: []
      };
    }
    case GET_NOTIFICATIONS_PENDING: {
      return { ...state, isFetching: true };
    }
    case GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        notifications: [...state.notifications, ...action.payload]
      };
    }
    case GET_NOTIFICATIONS_ERROR: {
      return { ...state, isFetching: false };
    }
    case NOTIFICATIONS_STREAM_NEW_NOTIFICATION: {
      return {
        ...state,
        notifications: [{ ...action.payload }, ...state.notifications],
        unreadNotifications: [
          { ...action.payload },
          ...state.unreadNotifications
        ],
        amountOfUnreadNotifications: state.amountOfUnreadNotifications + 1
      };
    }

    case GET_NOTIFICATIONS_UNREAD_SUCCESS: {
      return {
        ...state,
        unreadNotifications: action.notifications,
        amountOfUnreadNotifications: action.amount
      };
    }

    case MARK_NOTIFICATION_AS_READ_SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.map(notif =>
          notif.id === action.payload.id ? action.payload : notif
        ),
        unreadNotifications: state.unreadNotifications.filter(
          notif => notif.id !== action.payload.id
        ),
        amountOfUnreadNotifications: state.amountOfUnreadNotifications - 1
      };
    }

    case MARK_NOTIFICATIONS_ALL_READ_SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.map(notif => ({
          ...notif,
          read: true
        })),
        unreadNotifications: [],
        amountOfUnreadNotifications: 0
      };
    }

    default:
      return state;
  }
}

export default notification;
