import React from "react";
import {
  AppBar,
  Avatar,
  Badge,
  Divider,
  FlatButton,
  IconButton,
  FontIcon,
  Toolbar
} from "material-ui";
import Popover from "@material-ui/core/Popover";
import config from "../../config";
import history from "../../history";
import Styles from "../../styles/themeMainUI.js";
import AppsDropdownMenu from "./AppsDropdownMenu";
import NotificationsDropdownMenu from "./NotificationsDropdownMenu";
import BulkActionsDropdownMenu from "./BulkActionsDropdownMenu";
import NavMenuList from "./NavMenuList";
import * as NavData from "./NavData";

class NavHeader extends React.Component {
  state = {
    openedMenu: null
  };

  componentDidMount() {
    this.props.getUnreadNotifications();
    this.props.streamNotifications();
  }

  openMenu = (event, state) => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      openedMenu: state,
      anchorEl: event.currentTarget
    });
  };

  closeMenu = () => {
    this.setState({
      openedMenu: null
    });
  };

  handleMenuClick = url => {
    const { logout } = this.props;
    this.closeMenu();
    url.includes("logout") ? logout() : history.push(url);
  };

  toggleChatbot = () => {
    const { isChatbotOpen, closeChatbot, openChatbot } = this.props;
    isChatbotOpen ? closeChatbot() : openChatbot();
  };

  openChatbot = () => {
    window.open(
      "/chatbot/chat",
      "Talk to Chatbot",
      "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=400,height=600"
    );
  };

  //calculate what to show in badge content
  badgeContent = item => {
    const { chatbotBadge, amountOfUnreadNotifications } = this.props;
    if (item.state === "chatbot") {
      return chatbotBadge;
    } else if (item.state === "notifications") {
      return amountOfUnreadNotifications > 999
        ? "..."
        : amountOfUnreadNotifications;
    }
  };

  //Judge if badge should show
  shouldDisplayBadge = item => {
    const { chatbotBadge, unreadNotifications } = this.props;
    const unreadNotificationAmount = unreadNotifications.length || 0;
    return (
      (item.state === "chatbot" && chatbotBadge > 0) ||
      (item.state === "notifications" && unreadNotificationAmount > 0)
    );
  };

  menuItems = menu => {
    const {
      permissions,
      unreadNotifications,
      markNotificationAsRead,
      getUnreadNotifications,
      getBulkActionTasks,
      bulkActions
    } = this.props;

    const { settings, profiles } = NavData;

    switch (menu) {
      case "apps":
        return <AppsDropdownMenu handleMenuClick={this.handleMenuClick} />;
      case "notifications":
        return (
          <NotificationsDropdownMenu
            unreadNotifications={unreadNotifications}
            handleMenuClick={this.handleMenuClick}
            markNotificationAsRead={markNotificationAsRead}
            getUnreadNotifications={getUnreadNotifications}
          />
        );
      case "bulkActions":
        return (
          <BulkActionsDropdownMenu
            bulkActionsList={bulkActions}
            handleMenuClick={this.handleMenuClick}
            getBulkActionTasks={getBulkActionTasks}
          />
        );
      case "settings":
        let navSettings = [...settings];

        permissions.includes("TM_PLUGIN_READ") &&
          permissions.includes("TM_TICKET_SOURCE_READ") &&
          navSettings.unshift({
            icon: "plug",
            text: "Plugins",
            url: "/atr/settings/plugins"
          });
        permissions.includes("TM_CONFIG_READ") &&
          navSettings.unshift({
            icon: "cogs",
            text: "Configuration",
            url: "/atr/settings"
          });
        return (
          <>
            <div
              className="col-xs-12"
              style={{
                padding: "15px 15px",
                fontSize: "14px"
              }}
            >
              Settings
            </div>
            <Divider />
            <NavMenuList
              menuList={navSettings}
              handleMenuClick={this.handleMenuClick}
            />
          </>
        );
      case "profiles":
        return (
          <NavMenuList
            menuList={profiles}
            handleMenuClick={this.handleMenuClick}
          />
        );
      default:
        return;
    }
  };

  renderMenus = () => {
    const { menus } = NavData;

    return menus.map((menu, index) => {
      return (
        <Popover
          key={index}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          anchorEl={this.state.anchorEl}
          open={this.state.openedMenu === menu}
          onClose={this.closeMenu}
        >
          {this.menuItems(menu)}
        </Popover>
      );
    });
  };

  render() {
    const { username } = this.props;
    const { views } = NavData;
    return (
      <AppBar
        className="NavHeader align-xs-middle"
        showMenuIconButton={false}
        titleStyle={{ display: "none" }}
        style={{
          paddingLeft: 10,
          paddingRight: 0,
          backgroundColor: Styles.palette.navbarColor
        }}
      >
        <img
          className="logo-to-homepage"
          onClick={() => history.push("/atr/tickets")}
          src={config.app.images + "/myWizard_AiOps_whiteATR.png"}
          style={{ maxHeight: "70px", cursor: "pointer" }}
          alt=""
        />
        <Toolbar
          className="NavHeader align-xs-middle"
          style={{
            position: "fixed",
            right: 0,
            zIndex: -1,
            backgroundColor: Styles.palette.navbarColor
          }}
        >
          {views.map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  background: "transparent",
                  width: "75px"
                }}
                data-cy={item.state}
              >
                <Badge
                  badgeContent={this.badgeContent(item) || 0}
                  secondary={true}
                  badgeStyle={{
                    top: 12,
                    right: 12,
                    backgroundColor: "#0A83C4",
                    display: this.shouldDisplayBadge(item) ? "flex" : "none"
                  }}
                  style={{
                    paddingTop: 12
                  }}
                >
                  <IconButton
                    onClick={event => {
                      switch (item.state) {
                        case "chatbot":
                          this.openChatbot();
                          break;
                        case "diy":
                          history.push("/atr/settings/quickstart");
                          break;
                        default:
                          this.openMenu(event, item.state);
                      }
                    }}
                  >
                    <FontIcon
                      color={"#C3CBCC"}
                      className={"fa fa-" + item.icon}
                    />
                  </IconButton>
                </Badge>
              </div>
            );
          })}
          <FlatButton
            onClick={event => this.openMenu(event, "profiles")}
            hoverColor={"transparent"}
            disableTouchRipple={true}
            style={{ height: "inherit" }}
          >
            <div
              style={{
                display: "flex",
                margin: "0px 15px",
                alignItems: "center"
              }}
            >
              <Avatar
                className="hidden-xs"
                size={32}
                style={{ marginRight: "10px" }}
              >
                {username ? (
                  username.charAt(0).toUpperCase()
                ) : (
                  <FontIcon className="fa fa-user" style={{ color: "white" }} />
                )}
              </Avatar>
              <FontIcon
                className={
                  this.state.openedMenu === "profiles"
                    ? "fa fa-caret-up"
                    : "fa fa-caret-down"
                }
                color={Styles.palette.accent3Color}
              />
            </div>
          </FlatButton>
          {this.renderMenus()}
        </Toolbar>
      </AppBar>
    );
  }
  x;
}

export default NavHeader;
