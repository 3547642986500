import React from "react";
import Styles from "../../../styles/themeMainUI";
import { userConfig } from "../../../userConfigValidations";

class MLModelListValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      missingModels: []
    };
  }

  /* ----ML Models that don't predict Automation Fields---- */
  _findBadMLConfigs(mlConfig, activeTemplates, modelDetails) {
    return (
      mlConfig
        .filter(config => {
          return activeTemplates[config.ticketType.toUpperCase()];
        })
        // .map(this._getPortNumber)
        // .filter(config => {
        //   return config.port;
        // })
        .map(this._getConfigInfo(modelDetails, activeTemplates))
        .map(this._applyTemplateMapping(activeTemplates))
        .filter(config => {
          return config.model;
        })
        .map(this._highlightInvalidConfigs)
        .filter(config => {
          return config.badConfig;
        })
    );
  }

  _getPortNumber = config => {
    const myString = config.url;
    const myRegexp = /http:\/\/ml:([0-9]{4})\/api\/v1/g;
    const match = myRegexp.exec(myString);
    config.port = match ? parseInt(match[1], 10) : null;
    return config;
  };

  _getConfigInfo(modelDetails, activeTemplates) {
    return config => {
      config.model = modelDetails.find(model => {
        return config.modelId === model.modelId;
      });
      config.inputFields = activeTemplates[
        config.ticketType.toUpperCase()
      ].primaryFields.map(field => {
        return field.name;
      });
      return config;
    };
  }
  _applyTemplateMapping = activeTemplates => {
    return config => {
      config.inputFields = config.inputFields.map(field => {
        field =
          activeTemplates[config.ticketType.toUpperCase()].mapping[field] ||
          field;
        return field;
      });
      return config;
    };
  };

  _highlightInvalidConfigs = config => {
    config.missingInputs = config.model.trainConfig.filter(
      currentInputField => {
        return !config.inputFields.includes(currentInputField.name);
      }
    );
    config.badConfig = config.missingInputs.length > 0;
    return config;
  };

  /* ----Missing Automation Fields---- */
  _findMLIssues(mlConfig, activeTemplates, modelDetails, allowedTicketTypes) {
    return Object.keys(activeTemplates)
      .filter(this._filterValidTicketTypes(allowedTicketTypes))
      .reduce(this._findMissingModels(mlConfig), [])
      .filter(this._fiterAutomationFields(mlConfig));
  }

  _filterValidTicketTypes = activeTemplates => {
    return ticketType => {
      return activeTemplates.some(currentTicketType => {
        return currentTicketType.type === ticketType;
      });
    };
  };

  _findMissingModels(mlConfig) {
    return (accumulator, currentValue) => {
      return accumulator.concat(
        [
          {
            name: "u_knowledge",
            displayName:
              userConfig.variables.knowledgebaseDisplayName ||
              "Knowledgebase ID"
          },
          {
            name: "ticket_management",
            displayName:
              userConfig.variables.ticketManagementDisplayName ||
              "Ticket Management"
          }
        ].map(this._getAutomationFields(currentValue))
      );
    };
  }

  _getAutomationFields(currentValue) {
    return automationField => {
      return {
        ticketType: currentValue,
        missingField: automationField
      };
    };
  }

  _fiterAutomationFields(mlConfig) {
    return importantModel => {
      const isInATR = mlConfig.find(config => {
        return (
          config.field === importantModel.missingField.name &&
          config.ticketType.toUpperCase() ===
            importantModel.ticketType.toUpperCase()
        );
      });
      if (!isInATR) return true;
    };
  }

  render() {
    const ticketTypes = this.props.activeTemplates
      ? Object.keys(this.props.activeTemplates)
          .filter(ticketType => {
            return this.props.activeTemplates[ticketType].ticketSource
              .canAutomate;
          })
          .map(ticketType => {
            return {
              type: ticketType,
              displayName: this.props.activeTemplates[ticketType].ticketSource
                .displayName
            };
          })
      : [];
    const badConfigs = this._findBadMLConfigs(
      this.props.MLConfig,
      this.props.activeTemplates,
      this.props.MLModels
    );
    const showValidation = badConfigs.length > 0;

    return (
      <div>
        {showValidation && (
          <div
            style={{
              padding: 20,
              fontSize: 13,
              marginTop: 20,
              backgroundColor: Styles.palette.accent2Color,
              color: Styles.palette.accent3Color
            }}
          >
            <div
              style={{
                fontSize: 14
              }}
            >
              ML VALIDATION WARNING
            </div>
            <div>&nbsp; &nbsp;</div>
            {badConfigs.length > 0 && (
              <div
                style={{
                  fontStyle: "italic"
                }}
              >
                The following models cannot be used in ATR as they are missing
                input fields in the ticket template:
              </div>
            )}
            {badConfigs.map(warning => {
              const missingInputs = warning.missingInputs
                .map(item => {
                  return item.name;
                })
                .join(", ");
              return (
                <div key={warning.mlmodelName}>
                  <b> {warning.mlmodelName}</b> is missing input fields:{" "}
                  <b>{missingInputs}</b> for use in ticket type:{" "}
                  <b>{warning.ticketType}</b>.
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default MLModelListValidation;
