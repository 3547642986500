import { connect } from "react-redux";
import { getTicketField } from "redux/reselect/ticketTemplate";

import { setFieldFilter } from "redux/modules/ticketTemplate";
import TicketTemplateViewerTemplateFields from "./Fields";

const mapStateToProps = state => {
  return {
    ticketFields: getTicketField(state),
    filter: state.ticketTemplates.fieldFilter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFieldFilter: filter => dispatch(setFieldFilter(filter))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateViewerTemplateFields);
