import { connect } from "react-redux";
import Plugin from "./Plugin";
import { getTicketSources, openNewPlugin } from "redux/modules/plugin";
const mapStateToProps = (state, ownProps) => ({
  currentPluginId: state.plugin.currentPluginId
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getTicketSources: () => dispatch(getTicketSources()),
  openNewPlugin: () => dispatch(openNewPlugin())
});

const PluginCTN = connect(mapStateToProps, mapDispatchToProps)(Plugin);

export default PluginCTN;
