import React from "react";
import { Col } from "react-flexbox-grid";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import Styles from "styles/themeMainUI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, withStyles } from "@material-ui/core";

class ATRFormField extends React.Component {
  render() {
    const { xs: xsValue = 2 } = this.props;
    return this.props.fullWidth ? (
      <div style={styles.formContainerStyle}>
        <Col
          xs={12}
          style={Object.assign({}, styles.labelColumn, this.props.labelStyle)}
        >
          {this.props.formLabel}
          {this.props.description && (
            <Tooltip title={this.props.description}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={styles.icon}
                placement="right"
              />
            </Tooltip>
          )}
        </Col>
        <Col
          xs={12}
          style={Object.assign({}, { padding: "0" }, this.props.childStyle)}
        >
          {this.props.children}
        </Col>
      </div>
    ) : (
      <div style={styles.formContainerStyle}>
        <Col
          xs={xsValue}
          style={Object.assign({}, styles.labelColumn, this.props.labelStyle)}
        >
          <span>{this.props.formLabel}</span>
          {this.props.description && (
            <Tooltip title={this.props.description} placement="right">
              <FontAwesomeIcon icon={faInfoCircle} style={styles.icon} />
            </Tooltip>
          )}
        </Col>
        <Col xs={12 - xsValue}>
          <div style={this.props.childStyle}>{this.props.children}</div>
        </Col>
      </div>
    );
  }
}

const styles = createStyles({
  formContainerStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    paddingBottom: "5px",
    paddingLeft: 0,
    paddingRight: 0
  },
  icon: {
    color: Styles.palette.accent3Color,
    fontSize: "12px",
    margin: "0 0.5rem"
  },
  labelColumn: {
    padding: "0",
    marginTop: "20px",
    fontSize: "12px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

export default withStyles(styles)(ATRFormField);
