export const workflowContainer = {
  marginBottom: "15px"
};

export const row = {
  display: "flex",
  marginTop: "40px"
};

export const inputRow = {
  display: "flex",
  marginBottom: "40px"
};
