import { connect } from "react-redux";
import FilterDialog from "./FilterDialog";

import {
  ticketSetFilter,
  ticketSetTimeRange
} from "../../../actions/filterAndSort";
import { moveTicketCachePointer } from "../../../actions/tickets";

const mapStateToProps = (state, ownProps) => ({
  cacheLocalPointer: state.tickets.cacheLocalPointer,
  cacheObject: state.tickets.cacheObject,
  ticketSort: state.tickets.ticketSort,
  ticketFilter: state.tickets.ticketFilter,
  selectedTicketType: state.tickets.selectedTicketType,
  isCacheRefreshing: state.tickets.isCacheRefreshing,
  //
  tickets: state.tickets,
  presetSelected: state.tickets.presetSelected
});

const mapDispatchToProps = dispatch => ({
  ticketSetFilter: ticketFilter => dispatch(ticketSetFilter(ticketFilter)),
  moveTicketCachePointer: (
    moveIndex,
    localPage,
    cache,
    sortBy,
    filters,
    ticketType,
    presetSelected,
    polling = false
  ) =>
    dispatch(
      moveTicketCachePointer(
        moveIndex,
        localPage,
        cache,
        sortBy,
        filters,
        ticketType,
        presetSelected,
        polling
      )
    ),
  ticketSetTimeRange: range => dispatch(ticketSetTimeRange(range))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterDialog);
