import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import history from "../../../../history";

const availablePage = ["create template", "mapping field"];

const TicketTemplateViewerPaginator = props => {
  const {
    route,
    currentPage,
    currentTemplate,
    mandatoryMLMappingFields,
    secondaryFieldEnabled
  } = props;
  const { name, primaryFields, secondaryFields, mapping } = currentTemplate;
  const { increasePage, decreasePage, createTemplate, updateTemplate } = props;
  const isLastPage = currentPage === availablePage.length - 1;
  const [error, setError] = useState(null);

  const isValid = () => {
    return mandatoryMLMappingFields.every(
      field => Object.keys(mapping).indexOf(field.name) !== -1
    );
  };
  const handleBack = () => {
    if (currentPage === 0) {
      history.push("/atr/settings/ticket-template");
    } else {
      decreasePage();
    }
  };
  const handleNext = () => {
    if (!isLastPage) {
      if (!name) {
        setError("Please fill template name");
      } else if (primaryFields.length === 0) {
        setError("Please fill primary fields");
      } else if (secondaryFieldEnabled && secondaryFields.length === 0) {
        setError("Please fill secondary fields");
      } else {
        setError(null);
        increasePage();
      }
    } else {
      if (!isValid()) {
        setError("Please fill all mandatory mapping fields");
      } else {
        if (route === "new") {
          createTemplate();
        } else {
          updateTemplate();
        }
      }
    }
  };

  return (
    <>
      <Grid item xs={12} style={{ color: "red" }}>
        {error}
      </Grid>
      <Grid container justify="space-between">
        <Button variant="outlined" color="primary" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {!isLastPage && "Next"}
          {isLastPage && (route === "new" ? "Create" : "Update")}
        </Button>
      </Grid>
    </>
  );
};

export default TicketTemplateViewerPaginator;
