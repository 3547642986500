import EmailTemplatesPage from "../containers/pages/EmailTemplatesPage";

import React from "react";
import SecondaryButton from "./templates/SecondaryButton";
import "just-the-grid";

/* desktop containers */
import NavHeaderContainer from "../containers/navigation/NavHeaderContainer";

/* mobile containers */
import NavHeaderMobileContainer from "../containers/navigation/NavHeaderMobileContainer";

/* component containers */
import { Route, Switch, Redirect } from "react-router-dom";
import TicketsListPage from "./tickets/TicketsCTN";
import GamificationPage from "../containers/pages/GamificationPage";
import NotificationsPage from "../containers/pages/NotificationsPage";
import BulkActionsPage from "../containers/pages/BulkActionsPage";
import ChatbotPage from "../containers/pages/ChatbotPage";
import SettingsPage from "../containers/pages/SettingsPage";
import SettingsDataPage from "../containers/pages/SettingsDataPage";
import UserProfilePage from "../containers/pages/UserProfilePage";
import DryRunPage from "../containers/pages/DryRunPage";
import RulesPage from "../containers/pages/RulesPage";
import MappingPage from "../containers/pages/MappingPage";
import HelpPage from "../containers/pages/HelpPage";
import AutomationTourPage from "../containers/settings/AutomationTourPage";
import AutomationQuickstartPage from "../containers/settings/AutomationQuickstartPage";
import PluginPage from "./plugin/PluginCTN";
import BulkActionDrawer from "./drawers/BulkActionDrawer";
import PrivacyStatement from "./footer/Privacy";
import AutomationConfigsContainer from "./dryRun/DryRunAutomationConfigsContainer";

import Drawer from "material-ui/Drawer";
import Card from "material-ui/Card";
import Avatar from "material-ui/Avatar";
import { Row, Col } from "react-flexbox-grid/lib";

import GamificationDrawer from "./drawers/GamificationDrawer";
import Snackbar from "material-ui/Snackbar";
import Dialog from "material-ui/Dialog";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import ActionOpenInNew from "material-ui/svg-icons/action/open-in-new";

import config from "../config";
import history from "../history";

import TicketTemplatePage from "./ticketTemplates/TicketTemplate";
import Joyride from "react-joyride";
import { userConfig } from "../userConfigValidations";
import PermissionCheck from "./errors/PermissionCheck";
import ConfidentialityPopoverContainer from "./userNotification/ConfidentialityPopoverContainer";

const JoyrideSteps = [
  {
    title: "Machine Learning Instances",
    text:
      "The Machine Learning Table tells ATR which models to use for prediction.",
    selector: ".service-0",
    type: "click",
    position: "top",
    isFixed: true
  },
  {
    title: "Automatic Scripts",
    text:
      "This table contains your integrated automatic scripts, for example Rundeck.",
    selector: ".service-1",
    type: "click",
    position: "top",
    isFixed: true
  },
  {
    title: "Data Sources",
    text:
      "This table contains your data sources, such as Service Now instances or uploaded CSVs.",
    selector: ".service-2",
    type: "click",
    position: "top",
    isFixed: true
  },
  {
    title: "Add a new item",
    text: "Use the + button on any table to add a new configuration item.",
    selector: ".add-1",
    type: "click",
    position: "top",
    isFixed: true
  },
  {
    title: "Tokens & Passwords",
    text:
      "If you enter a username and password, you do not need to provide a token. " +
      "If you have a token, you do not need to provide a username and password.",
    selector: ".token-table-header",
    type: "click",
    position: "top",
    isFixed: true
  },
  {
    title: "Edit & Delete",
    text: "Use these buttons to edit or delete existing configuration items.",
    selector: ".row-edit-delete",
    position: "top",
    type: "click",
    isFixed: true
  },
  {
    title: "View the homepage",
    text:
      "At any time, press the logo to jump to the homepage where you can view all of your tickets.",
    selector: ".logo-to-homepage",
    position: "top",
    type: "click",
    isFixed: true
  }
];

class Main extends React.Component {
  constructor(props) {
    super(props);
    this._handleCloseChatbot = this._handleCloseChatbot.bind(this);
    this._handleCloseGamification = this._handleCloseGamification.bind(this);

    this._redirectToGamificationPage = this._redirectToGamificationPage.bind(
      this
    );

    this.state = {
      isDialogOpen: false,
      isReady: false
    };
  }

  _redirectToLogin() {
    // eslint-disable-next-line no-unused-vars
    const currentPath = window.location.pathname;
    const relayUrl = `${window.location.origin}/atr-gateway/auth/api/v1/auth/sso/${config.app.redirectName}`;

    if (userConfig.actions.generateSAMLLoginURL(relayUrl)) {
      const url = userConfig.actions.generateSAMLLoginURL(relayUrl);
      return window.location.replace(url);
    } else {
      const { protocol, host } = window.location;
      return window.location.assign(
        `${protocol}//${host}/identity-management/signin`
      );
    }
  }

  UNSAFE_componentWillMount() {
    // Authenticated user
    this.props.fetchUserDetails();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors.length) this.setState({ isDialogOpen: true });
  }

  closeDialog() {
    this.setState({ isDialogOpen: false });
    this.props.clearErr();
  }

  _handleCloseChatbot() {
    this.props.closeChatbot();
  }

  _handleCloseGamification() {
    this.props.closeGamification();
  }

  _redirectToGamificationPage() {
    this._handleCloseGamification();
    history.push("/gamification");
  }

  _JoyrideCallback(param) {
    if (param.type === "finished") {
      this.props.closeTutorial();
      this.setState({ isReady: false });
      this.props.updateJoyrideSettings({ atr: true }, this.props.user);
    }

    if (param.action === "close") {
      this.props.closeTutorial();
      this.setState({ isReady: false });
      this.props.updateJoyrideSettings({ atr: true }, this.props.user);
    }
  }

  render() {
    const {
      isSnackbarOpen,
      closeSnackbar,
      message,
      isChatbotOpen,
      isGamificationOpen
    } = this.props;
    return (
      <div>
        {this.props.isAuthenticated && this.props.isAValidUser ? (
          <PermissionCheck
            permissions={this.props.permissions}
            requiredPermissions={["TM_TICKET_TEMPLATE_READ"]}
          >
            <div className="template_container">
              {this.state.isReady &&
              window.location.pathname.toLowerCase() === "/settings" &&
              this.props.templateJoyride ? (
                <Joyride
                  ref="joyride"
                  steps={JoyrideSteps}
                  run
                  autoStart
                  showStepsProgress
                  showBackButton
                  showSkipButton
                  disableOverlay
                  type="continuous"
                  callback={this._JoyrideCallback}
                />
              ) : null}
              <Col className="hidden-xs">
                <Drawer
                  open={isChatbotOpen}
                  docked
                  width={400}
                  onRequestChange={this._handleCloseChatbot}
                  style={{
                    background: "#FDFDFD"
                  }}
                >
                  {isChatbotOpen ? (
                    <div
                      style={{
                        overflow: "hidden"
                      }}
                    >
                      <Card>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start"
                          }}
                        >
                          <Col
                            xs={8}
                            style={{
                              textAlign: "center",
                              paddingLeft: 90,
                              paddingRight: 0
                            }}
                          >
                            <div
                              style={{
                                paddingTop: 10,
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <Avatar
                                src={`${config.app.images}/chatbot.png`}
                              />
                              &nbsp;
                              <Avatar>
                                {this.props.user ? (
                                  this.props.user.username
                                    .charAt(0)
                                    .toUpperCase()
                                ) : (
                                  <FontIcon
                                    className="fa fa-user"
                                    style={{ color: "white" }}
                                  />
                                )}
                              </Avatar>
                            </div>
                            <div
                              style={{
                                display: "inline-block",
                                marginTop: 8,
                                marginBottom: 10
                              }}
                            >
                              Autobot
                            </div>
                          </Col>
                          <Col
                            xs={4}
                            style={{
                              padding: 0,
                              textAlign: "right"
                            }}
                          >
                            <IconButton
                              onClick={this._handleCloseChatbot}
                              style={{
                                marginTop: 8
                              }}
                            >
                              <NavigationClose />
                            </IconButton>
                          </Col>
                        </div>
                      </Card>

                      <Row
                        style={{ flexDirection: "column", flexBasis: "200px" }}
                      >
                        <Col xs={12}>
                          <ChatbotPage />
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                </Drawer>

                <Drawer
                  open={isGamificationOpen}
                  docked
                  width="70%"
                  onRequestChange={this._handleCloseGamification}
                  style={{
                    background: "#FDFDFD"
                  }}
                  containerStyle={{
                    backgroundColor: "#F2F2F2"
                  }}
                >
                  {isGamificationOpen ? (
                    <div
                      style={{
                        overflowX: "hidden",
                        backgroundColor: "#F2F2F2"
                      }}
                    >
                      <Card>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#ffffff"
                          }}
                        >
                          <Col
                            xs={9}
                            style={{
                              textAlign: "left",
                              paddingLeft: "20px"
                            }}
                          >
                            <h3>Gamification</h3>
                          </Col>
                          <Col
                            xs={3}
                            style={{
                              padding: 0,
                              textAlign: "right"
                            }}
                          >
                            <IconButton
                              onClick={this._redirectToGamificationPage}
                              style={{
                                position: "absolute",
                                right: 35
                              }}
                            >
                              <ActionOpenInNew />
                            </IconButton>
                            <IconButton onClick={this._handleCloseGamification}>
                              <NavigationClose />
                            </IconButton>
                          </Col>
                        </div>
                      </Card>

                      <div
                        style={{
                          flexDirection: "column",
                          flexBasis: "200px"
                        }}
                      >
                        <Col
                          xs={12}
                          style={{
                            padding: 0
                          }}
                        >
                          <GamificationDrawer
                            redirectToGamificationPage={
                              this._redirectToGamificationPage
                            }
                            username={this.props.user.username}
                          />
                        </Col>
                      </div>
                    </div>
                  ) : null}
                </Drawer>
              </Col>
              <div
                className="template_main"
                style={{
                  flexDirection: "column"
                }}
              >
                <Col className="hidden-sm-up">
                  <NavHeaderMobileContainer />
                </Col>
                <Col className="hidden-xs">
                  <NavHeaderContainer />
                </Col>
                <Switch>
                  {this.props.children}
                  <Route
                    exact
                    path="/atr/menu"
                    render={props => <TicketsListPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/tickets/:number"
                    render={props => <TicketsListPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/tickets"
                    render={props => <TicketsListPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/chatbot"
                    render={props => <ChatbotPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/gamification"
                    render={props => <GamificationPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/notifications"
                    render={props => <NotificationsPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/bulk-actions"
                    render={props => <BulkActionsPage {...props} />}
                  />
                  {this.props.permissions.includes("TM_PLUGIN_READ") &&
                    this.props.permissions.includes(
                      "TM_TICKET_SOURCE_READ"
                    ) && (
                      <Route
                        exact
                        path="/atr/settings/plugins"
                        render={props => <PluginPage {...props} />}
                      />
                    )}
                  {this.props.permissions.includes("TM_CONFIG_READ") && (
                    <Route
                      exact
                      path="/atr/settings"
                      render={props => <SettingsPage {...props} />}
                    />
                  )}
                  {userConfig.variables.dryRun && (
                    <Route
                      exact
                      path="/atr/settings/dry-run"
                      render={props => <DryRunPage {...props} />}
                    />
                  )}
                  {userConfig.variables.dryRun && (
                    <Route
                      exact
                      path="/atr/settings/dry-run/execution"
                      component={AutomationConfigsContainer}
                    />
                  )}
                  <Route
                    exact
                    path="/atr/settings/profile"
                    render={props => <UserProfilePage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/ticket-template"
                    render={props => <TicketTemplatePage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/ticket-template/:id"
                    render={props => <TicketTemplatePage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/automation/rules"
                    render={props => <RulesPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/automation/mapping"
                    render={props => <MappingPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/automation/mapping/:name"
                    render={props => <MappingPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/email-templates"
                    render={props => <EmailTemplatesPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/email-templates/:templateName"
                    render={props => <EmailTemplatesPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/merge-tags"
                    render={props => <EmailTemplatesPage {...props} />}
                  />
                  {/* <Route
                  path="/atr/settings/ticket-configuration"
                  component={TicketConfigurationPage}
                /> */}
                  <Route
                    exact
                    path="/atr/settings/data"
                    render={props => <SettingsDataPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/tour"
                    render={props => <AutomationTourPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/settings/quickstart"
                    render={props => <AutomationQuickstartPage {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/privacy"
                    render={props => <PrivacyStatement {...props} />}
                  />
                  <Route
                    exact
                    path="/atr/help"
                    render={props => <HelpPage {...props} />}
                  />
                  <Redirect to="/atr/tickets" />
                </Switch>
              </div>
              <Snackbar
                open={isSnackbarOpen}
                message={<span style={{ color: "white" }}>{message}</span>}
                autoHideDuration={8000}
                onRequestClose={closeSnackbar}
                bodyStyle={{
                  height: "auto",
                  lineHeight: "32px",
                  textAlign: "center"
                }}
              >
                <Dialog
                  title="Error!"
                  actions={
                    <SecondaryButton
                      label="Ok"
                      secondary
                      onClick={this.closeDialog}
                    />
                  }
                  modal={false}
                  open={this.state.isDialogOpen}
                  onRequestClose={this.closeDialog}
                />
              </Snackbar>
              <BulkActionDrawer />
            </div>
            {this.props.userProfile && this.props.userProfile.id && (
              <ConfidentialityPopoverContainer
                userProfile={this.props.userProfile}
              />
            )}
          </PermissionCheck>
        ) : (
          <div>Please wait while we verify your identity...</div>
        )}
      </div>
    );
  }
}

export default Main;
