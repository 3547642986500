import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default class PromptDialog extends React.Component {
  state = {
    href: ""
  };
  constructor(props) {
    super(props);
  }
  handleChange(value) {
    this.setState({ href: value });
  }
  sendHrefToEditor() {
    this.props.sendHrefToEditor(this.state.href);
  }
  render() {
    return (
      <div>
        <Dialog open={this.props.openDialogBox}>
          <div style={{ width: 400 }}>
            <DialogContent>
              <DialogContentText>Enter the URL of the link:</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                label="URL"
                onChange={event => this.handleChange(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.closeDialogbox} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.props.closeDialogbox();
                  this.sendHrefToEditor();
                }}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}
