import React from "react";
import { Typography, withStyles } from "@material-ui/core";

const styles = {
  captionList: {
    paddingTop: 5
  }
};

const PluginDrawerBasicInfo = props => {
  const {
    basicInfo: { id, name, version },
    classes
  } = props;
  return (
    <div>
      <Typography variant="headline">{name}</Typography>
      <div className={classes.captionList}>
        <Typography variant="caption">Version: {version}</Typography>
      </div>
      <div className={classes.captionList}>
        <Typography variant="caption">ID: {id}</Typography>
      </div>
    </div>
  );
};

PluginDrawerBasicInfo.defaultProps = {
  basicInfo: {}
};

export default withStyles(styles)(PluginDrawerBasicInfo);
