import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ToolTip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";

const styles = {
  wrapper: {
    justfyContent: "flex-start",
    alignItems: "center",
    height: "30px"
  }
};

const TicketTemplateViewerMappingItem = props => {
  const { classes, field, ticketFields, setMappingField, mappingField } = props;
  return (
    <Grid container className={classes.wrapper}>
      <Grid item xs={3}>
        <ToolTip title={field.description}>
          <Typography>{field.displayName}</Typography>
        </ToolTip>
      </Grid>
      <Grid item xs={9}>
        <Select
          fullWidth
          value={mappingField}
          onChange={event => setMappingField(field.name, event.target.value)}
        >
          {ticketFields.map((item, i) => {
            return (
              <MenuItem key={i} value={item.name}>
                <Typography>
                  {item.displayName} ({item.name})
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TicketTemplateViewerMappingItem);
