import React from "react";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";

const TicketTemplateViewerStepper = props => {
  return (
    <Stepper
      style={{
        width: "90%",
        margin: "auto",
        marginBottom: "-20px"
      }}
      activeStep={props.index}
      nonLinear
    >
      <Step>
        <StepLabel>
          <b>Create Template</b>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <b>Mapping Fields</b>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel>
          <b>Done</b>
        </StepLabel>
      </Step>
    </Stepper>
  );
};

export default TicketTemplateViewerStepper;
