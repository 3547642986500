import { connect } from "react-redux";
import { logout } from "../../actions/auth/auth";
import { closeChatbot, openChatbot } from "../../actions/chatbot";
import {
  closeGamification,
  openGamification
} from "../../actions/gamification";
import { sidebarSetState } from "../../actions/sidebar";
import NavHeaderMobile from "../../components/navigation/NavHeaderMobile";

/* return GLOBAL STATE via STATE RETURNERS and store in props */
const mapStateToProps = (state, ownProps) => ({
  isChatbotOpen: state.chatbot.isOpen,
  isNotificationOpen: state.notification.isOpen,
  isGamificationOpen: state.gamification.isOpen,
  sidebarState: state.sidebar,
  chatbotBadge: state.chatbot.chatbotBadge,
  notifications: state.notification.notifications,
  unreadNotifications: state.notification.unreadNotifications,
  unreadNotificationsBadge: state.notification.unreadNotifications,
  permissions: state.user.permissions
});

/* pull actions that will change global state and store in props */
const mapDispatchToProps = (dispatch, ownProps) => ({
  closeChatbot: () => dispatch(closeChatbot()),
  openChatbot: () => dispatch(openChatbot()),
  closeGamification: () => dispatch(closeGamification()),
  openGamification: () => dispatch(openGamification()),
  sidebarSetState: state => dispatch(sidebarSetState(state)),
  logout: () => dispatch(logout())
});
const NavHeaderMobileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavHeaderMobile);

export default NavHeaderMobileContainer;
