import { connect } from "react-redux";
import TicketTemplateViewerMapping from "./Mapping";

const mapStateToProps = state => {
  return {
    MLMappingFields: state.ticketTemplates.MLMappingFields,
    name: state.ticketTemplates.currentTemplate.name
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateViewerMapping);
