import history from "../history";
import config from "../config";
import { openSnackbar } from "./snackbar";
import { retrieveTicketCache } from "./tickets";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../utils/axios";
import {
  ticketGetSingleJobDefinition,
  ticketClearSingleJobDefinition
} from "./tickets";

export const OPEN_MAPPING = "OPEN_MAPPING";
export const SELECT_MAPPING = "SELECT_MAPPING";
export const CLOSE_MAPPING = "CLOSE_MAPPING";
export const CREATE_MAPPING = "CREATE_MAPPING";
export const UPDATE_MAPPING = "UPDATE_MAPPING";
export const DELETE_MAPPING = "DELETE_MAPPING";
export const MAPPING_CHANGE_PAGE = "MAPPING_CHANGE_PAGE";
export const ACTIVE_TEMPLATE_SUCCESS = "ACTIVE_TEMPLATE_SUCCESS";

export function openMappingByName(mappingName) {
  return async dispatch => {
    const mapping = await getMappingByName(mappingName);
    dispatch(openMapping(mapping));
  };
}

export function setMapping(mapping) {
  return dispatch => {
    dispatch({
      type: SELECT_MAPPING,
      mapping
    });
  };
}

export function openMapping(mappingSelected) {
  return async function(dispatch) {
    dispatch(mappingChangeTicketManagement(mappingSelected.targetServiceName));
    if (mappingSelected.mappingName) {
      history.push(
        `/atr/settings/automation/mapping/${mappingSelected.mappingName}`
      );
    }
    dispatch({
      type: OPEN_MAPPING,
      mappingSelected
    });
  };
}

export const SHOW_MAPPING_ERROR = "SHOW_MAPPING_ERROR";

export function showErrorMessage(errObj) {
  return dispatch => {
    dispatch({
      type: SHOW_MAPPING_ERROR,
      errObj
    });
  };
}

export function closeMapping() {
  history.push("/atr/settings/automation/mapping");
  return {
    type: CLOSE_MAPPING,
    mappingSelected: {}
  };
}

export function changePage(page) {
  return {
    type: MAPPING_CHANGE_PAGE,
    currentPage: page
  };
}

export function getActiveTemplates(ticketFilter) {
  return async function(dispatch) {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/templates/active`;

    const res = await axiosSnackbar.get(url);
    if (res.status === 200) {
      const newTicket = {
        state: "1",
        isNewTicket: true,
        editable: true,
        fields: {}
      };
      for (const key in res.data) {
        res.data[key].primaryFields.forEach(item => {
          newTicket.fields[item.name] =
            item.defaultValue && item.type === "dropdown"
              ? item.defaultValue
              : "";
        });
      }
      dispatch({
        type: ACTIVE_TEMPLATE_SUCCESS,
        activeTemplates: res.data,
        newTicket
      });
      dispatch(
        retrieveTicketCache(
          1,
          {},
          {
            sortField: "lastUpdateDate",
            sortDirection: "DESC"
          },
          ticketFilter,
          Object.keys(res.data).sort((a, b) => {
            return res.data[a].name < res.data[b].name ? -1 : 0;
          })[0]
        )
      );
    } else if (res.status === 404) {
      dispatch(openSnackbar("No active template available."));
    }
  };
}

export const OPEN_MAPPINGS_PENDING = "OPEN_MAPPINGS_PENDING";
export const OPEN_MAPPINGS_SUCCESS = "OPEN_MAPPINGS_SUCCESS";

export const getMappingByName = mappingID => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/servicejobmapping/${mappingID}`;
  return axios.get(url).then(res => {
    if (res.status === 200) {
      return res.data;
    }
  });
};

export function openMappings(page = 0, perPage = 10) {
  return function(dispatch, getState) {
    dispatch({
      type: OPEN_MAPPINGS_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/servicejobmapping`;
    const params = {
      page,
      perPage
    };

    return axios.get(url, { params }).then(res => {
      if (res.status === 200) {
        if (res.data.length === 0 && page !== 0) {
          dispatch(openMappings(page - 1));
          dispatch(changePage(page - 1));
        } else {
          dispatch({
            type: OPEN_MAPPINGS_SUCCESS,
            mappings: res.data,
            lastPage: Math.ceil(res.headers["x-total-count"] / perPage)
          });
        }
      }
    });
  };
}

export function createMapping(mappingEdited, page) {
  //send payload without id as the BE will return the unique ID
  delete mappingEdited.id;
  return function(dispatch, getState) {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/servicejobmapping`;

    return axiosSnackbar
      .post(url, mappingEdited)
      .then(res => {
        dispatch(openMappings(page));
        dispatch({
          type: CREATE_MAPPING
        });
        dispatch(
          openSnackbar(
            `Created mapping '${mappingEdited.mappingName}' successfully`
          )
        );
        dispatch(closeMapping());
      })
      .catch(error => {
        if (error.response) {
          dispatch(showErrorMessage(error.response.data));
        }
      });
  };
}

export function updateMapping(mappingEdited, page) {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/servicejobmapping`;

  return function(dispatch, getState) {
    return axiosSnackbar
      .put(url, mappingEdited)
      .then(res => {
        dispatch(openMappings(page));
        dispatch(
          openSnackbar(
            `Updated mapping '${mappingEdited.mappingName}' successfully`
          )
        );
        dispatch(openMapping(res.data));
        dispatch({
          type: CREATE_MAPPING
        });
      })
      .catch(error => {
        if (error.response) {
          dispatch(showErrorMessage(error.response.data));
        }
      });
  };
}

export function deleteMapping(mappingEdited, page) {
  return function(dispatch, getState) {
    const url = `${config.urls.base +
      config.urls.apis[
        "ticket-management"
      ]}/servicejobmapping/${encodeURIComponent(mappingEdited.id)}`;

    return axios
      .delete(url)
      .then(res => {
        dispatch(
          openSnackbar(`Deleted mapping '${mappingEdited.mappingName}'`)
        );
        dispatch(openMappings(page));
        dispatch(closeMapping());
      })
      .catch(err => {
        dispatch(
          openSnackbar(
            `Failed to delete mapping '${err?.response?.data?.error}'`
          )
        );
      });
  };
}

export const UPDATE_MAPPING_JOBS_TICKET_MANAGEMENT =
  "UPDATE_MAPPING_JOBS_TICKET_MANAGEMENT";

export function mappingChangeTicketManagement(ticketManagement) {
  return {
    type: UPDATE_MAPPING_JOBS_TICKET_MANAGEMENT,
    ticketManagement
  };
}

export const GET_RUNDECK_JOBS_PENDING = "UPDATE_MAPPING_JOBS_PENDING";
export const GET_RUNDECK_JOBS_SUCCESS = "UPDATE_MAPPING_JOBS_FULFILLED";
export const GET_RUNDECK_JOBS_ERROR = "UPDATE_MAPPING_JOBS_ERROR";

export function getRundeckJobs(rundeck, project) {
  return function(dispatch, getState) {
    dispatch({
      type: GET_RUNDECK_JOBS_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis[
        "ticket-management"
      ]}/automation/rundeck/${project}/jobs`;
    const params = {
      rundeck
    };
    return axios
      .get(url, { params })
      .then(res => {
        dispatch({
          type: GET_RUNDECK_JOBS_SUCCESS,
          mappingJobs: res.data
        });

        return res.data;
      })
      .catch(error => {
        dispatch({
          type: GET_RUNDECK_JOBS_ERROR
        });
      });
  };
}

export const GET_RUNDECK_PROJECTS_PENDING = "GET_RUNDECK_PROJECTS_PENDING";
export const GET_RUNDECK_PROJECTS_SUCCESS = "GET_RUNDECK_PROJECTS_SUCCESS";
export const GET_RUNDECK_PROJECTS_ERROR = "GET_RUNDECK_PROJECTS_ERROR";

export function getRundeckProjects(rundeck) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_RUNDECK_PROJECTS_PENDING
    });

    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/automation/rundeck/projects`;
    const params = {
      rundeck
    };

    return axios.get(url, { params }).then(res => {
      return dispatch({
        type: GET_RUNDECK_PROJECTS_SUCCESS,
        rundeckProjects: res.data
      });
    });
  };
}

// TODO: Remove this - just used for not breaking everything...
export function mappingGetAllJobs(ticketManagement) {
  return dispatch => null;
}

export const UPDATE_LOCAL_MAPPING = "UPDATE_LOCAL_MAPPING";

export function updateLocalMapping(mapping) {
  return dispatch => {
    dispatch({
      type: UPDATE_LOCAL_MAPPING,
      mapping
    });
  };
}

export const CLEAR_MAPPING_JOBS = "CLEAR_MAPPING_JOBS";

export const clearMappingJobs = () => dispatch => {
  dispatch({
    type: CLEAR_MAPPING_JOBS
  });
};

export const CLEAR_RUNDECK_PROJECTS = "CLEAR_RUNDECK_PROJECTS";

export const clearRundeckProjects = () => dispatch => {
  dispatch({
    type: CLEAR_RUNDECK_PROJECTS
  });
};

export const OPEN_ALL_MAPPINGS_PENDING = "OPEN_ALL_MAPPINGS_PENDING";
export const OPEN_ALL_MAPPINGS_SUCCESS = "OPEN_ALL_MAPPINGS_SUCCESS";

export const openAllMappings = () => (dispatch, getState) => {
  dispatch({
    type: OPEN_ALL_MAPPINGS_PENDING
  });

  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/servicejobmapping/getMappings`;

  return axios
    .get(url)
    .then(res => {
      return dispatch({
        type: OPEN_ALL_MAPPINGS_SUCCESS,
        mappings: res.data
      });
    })
    .catch(err => null);
};

//find the specific mapping for the current ticket drawer
export const UPDATE_MAPPING_FOUND = "UPDATE_MAPPING_FOUND";
export const updateMappingFound = mappingFound => dispatch => {
  dispatch({
    type: UPDATE_MAPPING_FOUND,
    mappingFound
  });
};

// API call to fetch mapping for a particular ticket.
export const GET_MAPPING_SUCCESS = "GET_MAPPING_SUCCESS";
export const getMappingJob = ticket => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/servicejobmapping/byTicketBody`;
  return axios
    .post(url, ticket)
    .then(res => {
      return res.data;
    })
    .catch(err => null);
};

export const predictTicketWorkflow = ticket => {
  return async dispatch => {
    dispatch(ticketClearSingleJobDefinition());
    const matchedMappings = await getMappingJob(ticket);
    dispatch({
      type: GET_MAPPING_SUCCESS,
      matchedMappings
    });
    if (matchedMappings.length) {
      const mappingFound = matchedMappings[0];
      dispatch(getRundeckProjects(mappingFound.targetServiceName));
      dispatch(
        getRundeckJobs(
          mappingFound.targetServiceName,
          mappingFound.targetProjectName
        )
      );
      const ticketID = ticket && ticket.coreData.id;
      mappingFound.targetServiceName &&
        dispatch(
          ticketGetSingleJobDefinition(
            mappingFound.targetServiceName,
            mappingFound.targetJobName,
            ticketID
          )
        );
      dispatch(updateMappingFound(mappingFound));
    } else {
      // no mapping found
      dispatch(updateMappingFound({}));
      dispatch(clearRundeckProjects());
      dispatch(clearMappingJobs());
    }
  };
};
