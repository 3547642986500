import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell"; //replaces TableRowColumn & TableHeaderColumn
import TableRow from "@material-ui/core/TableRow";
import { isEqual } from "lodash";

import AccuracyChip from "../../../templates/AccuracyChip";
import { withStyles } from "@material-ui/core";
import history from "../../../../history";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const styles = {
  TableRowColumn: {
    fontSize: "13px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "250px"
  }
};

let checkboxClicked = false;

const TicketTableBody = props => {
  const {
    clearRundeckProjects,
    clearMappingJobs,
    closeDetailsTicket,
    openDetails,
    updateBulkActionTickets,

    isDetailsOpen,
    ticket,
    tickets,
    customizedTemplateColumn,
    selectedTickets,
    selectedTicketType,
    activeTemplates,
    classes,
    timezone
  } = props;

  const handleRowSelection = rowIndex => {
    clearMappingJobs();
    clearRundeckProjects();
    if (checkboxClicked) {
      checkboxClicked = false;
      return;
    }
    if (isDetailsOpen && isEqual(tickets[rowIndex], ticket)) {
      closeDetailsTicket(tickets[rowIndex]);
    } else {
      closeDetailsTicket(ticket);
      openDetails(tickets[rowIndex]);
      history.push("/atr/tickets/" + tickets[rowIndex].coreData.number);
    }
  };

  const handleCheckboxClick = item => {
    checkboxClicked = true;

    const selectedIndex = selectedTickets.findIndex(
      ticket => ticket.id === item.id
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTickets, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTickets.slice(1));
    } else if (selectedIndex === selectedTickets.length - 1) {
      newSelected = newSelected.concat(selectedTickets.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTickets.slice(0, selectedIndex),
        selectedTickets.slice(selectedIndex + 1)
      );
    }
    updateBulkActionTickets(newSelected);
  };

  const getModifiedDate = updatedDate => {
    const modDate = dayjs
      .utc(updatedDate)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return modDate;
  };

  return (
    <TableBody style={{ cursor: "pointer" }}>
      {tickets &&
        tickets.map((item, i) => {
          if (tickets[i] === undefined) {
            return;
          }
          const confidenceLevel = Math.ceil(
            item.automationData.confidenceLevel * 100
          );
          return (
            <TableRow
              onClick={() => handleRowSelection(i)}
              hover
              key={i}
              selected={
                ticket.coreData &&
                ticket.coreData.number === item.coreData.number
              }
            >
              {activeTemplates[selectedTicketType]?.ticketSource
                .canAutomate && (
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={() =>
                      handleCheckboxClick({
                        id: item.coreData.id,
                        number: item.coreData.number
                      })
                    }
                    checked={
                      selectedTickets.findIndex(
                        ticket => ticket.id === item.coreData.id
                      ) !== -1
                    }
                  />
                </TableCell>
              )}
              {customizedTemplateColumn.map((column, index) => {
                if (column.name === "confidenceLevel") {
                  return (
                    <TableCell key={index} style={{ fontSize: "12px" }}>
                      <AccuracyChip prediction={confidenceLevel}>
                        {confidenceLevel}%
                      </AccuracyChip>
                    </TableCell>
                  );
                } else if (
                  column.name === "lastUpdateDate" ||
                  column.name === "createdDate"
                ) {
                  return (
                    <TableCell key={index} className={classes.TableRowColumn}>
                      {column.location === "coreData"
                        ? getModifiedDate(item.coreData[column.name])
                        : column.location === "serviceData"
                        ? getModifiedDate(item.serviceData[column.name])
                        : getModifiedDate(item.automationData.name)}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={index} className={classes.TableRowColumn}>
                      {column.location === "coreData"
                        ? item.coreData[column.name]
                        : column.location === "serviceData"
                        ? item.serviceData[column.name]
                        : item.automationData[column.name]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default withStyles(styles)(TicketTableBody);
