import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import AQETabWorkflow from "./Workflow/Workflow";
import {
  AQETabContainer,
  detailContainer,
  statusText,
  textDetails
} from "./AQETabStyle";
import {
  SuccessIcon,
  FailedIcon,
  ProgressIcon,
  UntouchedIcon
} from "./Workflow/Node/Card/CardStyle";
import Skeleton from "react-loading-skeleton";
import Styles from "styles/themeMainUI";
import { useInterval } from "lib/customHooks";
import config from "../../../config";
import { axiosInstance as axios } from "../../../utils/axios";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Button, Typography, IconButton, Grid, Box } from "@mui/material";
import { applyTimezoneToDate } from "utils/helper";
import { useSelector } from "react-redux";

const AQETab = ({ ticketId }) => {
  const [error, setError] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const [workload, setWorkload] = useState(null);
  const [isRunning, setIsRunning] = useState(true);
  const [execIds, setExecIds] = useState(null);
  const [currentExecId, setCurrentExecId] = useState(null);
  const [isLoadingWorkload, setIsLoadingWorkload] = useState(true);

  const timezone = useSelector(state => state.user.user.timezone);

  const handleFetchExecIds = useCallback(async () => {
    try {
      const execJobsRes = await fetchExecutedJobs(ticketId);
      const executionIds = [];
      execJobsRes.data
        .reverse()
        .forEach(execJob => executionIds.push(execJob.executionId));
      setExecIds(executionIds);
      setCurrentExecId(executionIds[0]);
    } catch (err) {
      setError(err);
    }
  }, [ticketId]);

  //interval to fetch workload.
  useInterval(
    () => handleFetchWorkload(workflow?.version),
    isRunning ? 2000 : null
  );

  const handleFetchWorkflow = useCallback(async () => {
    try {
      const workflowRes = await fetchAQEWorkflow(workload?.workflowId);
      setWorkflow(workflowRes.data);
    } catch (err) {
      setError(err);
    }
  }, [workload]);

  const getIsRunning = state =>
    state === "REJECTED" || state === "FAILED" || state === "DONE"
      ? false
      : true;

  const handleFetchWorkload = async () => {
    setIsLoadingWorkload(true);
    if (currentExecId) {
      try {
        const workloadRes = await fetchAQEWorkload(currentExecId);
        setIsRunning(getIsRunning(workloadRes.data.state));
        setWorkload(workloadRes.data);
      } catch (err) {
        setError(err);
        setIsRunning(false);
      } finally {
        setIsLoadingWorkload(false);
      }
    }
  };

  const renderStatusMessage = () => {
    switch (workload.state) {
      case "ACCEPTED":
        return (
          <>
            <ProgressIcon />
            <Typography variant="subheading">ACCEPTED</Typography>
          </>
        );
      case "REJECTED":
        return (
          <>
            <FailedIcon />
            <Typography variant="subheading">REJECTED</Typography>
          </>
        );
      case "FAILED":
        return (
          <>
            <FailedIcon />
            <Typography variant="subheading">FAILED</Typography>
          </>
        );
      case "DONE":
        return (
          <>
            <SuccessIcon />
            <Typography variant="subheading">Success</Typography>
          </>
        );
      default:
        return (
          <>
            <UntouchedIcon />
            <Typography variant="subheading">State</Typography>
          </>
        );
    }
  };

  const handleSelectOlderJob = () => {
    if (execIds.indexOf(currentExecId) !== execIds.length - 1) {
      setCurrentExecId(execIds[execIds.indexOf(currentExecId) + 1]);
    }
  };

  const handleSelectNewerJob = () => {
    if (execIds.indexOf(currentExecId) !== 0) {
      setCurrentExecId(execIds[execIds.indexOf(currentExecId) - 1]);
    }
  };

  useEffect(() => {
    handleFetchExecIds();
  }, [handleFetchExecIds]);

  useEffect(() => {
    // Reset workflow & workload before loading another one
    // so the ui display the skeleton
    setWorkflow(null);
    setWorkload(null);
    handleFetchWorkload();
  }, [currentExecId]);

  useEffect(() => {
    if (workload && workload.workflowId) {
      handleFetchWorkflow(workload.workflowId);
    }
  }, [workload, handleFetchWorkflow]);

  return (
    <Box sx={AQETabContainer}>
      {error || !currentExecId ? (
        <div
          style={{
            margin: 20,
            padding: 20,
            fontSize: 13,
            backgroundColor: Styles.palette.accent2Color,
            color: Styles.palette.accent3Color
          }}
        >
          No AQE job is triggered for this ticket. Select a job from the Target
          Rundeck Instance of "automation-queue". Fill the corresponding inputs
          and press the "AUTOMATE" button to trigger it.
        </div>
      ) : (
        <>
          {execIds.length > 1 && (
            <Grid container justifyContent="center" alignItems="center">
              <IconButton
                onClick={handleSelectOlderJob}
                disabled={
                  isLoadingWorkload ||
                  execIds.indexOf(currentExecId) === execIds.length - 1
                }
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="body2" style={{ margin: "0 20px" }}>
                Workload: {execIds.length - execIds.indexOf(currentExecId)}/
                {execIds.length}
              </Typography>
              <IconButton
                onClick={handleSelectNewerJob}
                disabled={
                  isLoadingWorkload || execIds.indexOf(currentExecId) === 0
                }
              >
                <ArrowForward />
              </IconButton>
            </Grid>
          )}

          <Box sx={detailContainer}>
            {workflow ? (
              <Grid container justifyContent="center">
                <Typography
                  variant="subheading"
                  style={{ textTransform: "uppercase" }}
                >
                  {workflow.name}
                </Typography>
              </Grid>
            ) : (
              <Grid>
                <Typography
                  variant="subheading"
                  style={{ width: 200, margin: "auto" }}
                >
                  <Skeleton />
                </Typography>
              </Grid>
            )}

            <Grid container spacing={8} justifyContent="center">
              <Grid item xs={3}>
                <Typography variant="body2">Status: </Typography>
              </Grid>
              <Grid item xs={9}>
                {workload ? (
                  <Box sx={statusText}>{renderStatusMessage()}</Box>
                ) : (
                  <div style={{ marginLeft: -30 }}>
                    <Skeleton />
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={8} justifyContent="center">
              <Grid item xs={3}>
                <Typography variant="body2">Created at: </Typography>
              </Grid>
              <Grid item xs={9}>
                {workload ? (
                  <Typography variant="body2" sx={statusText}>
                    {applyTimezoneToDate(workload.creationDate, timezone)}
                  </Typography>
                ) : (
                  <div style={{ marginLeft: -30 }}>
                    <Skeleton />
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={8} justifyContent="center">
              <Grid item xs={3}>
                <Typography variant="body2">Details: </Typography>
              </Grid>
              <Grid item xs={9}>
                <Box sx={textDetails}>
                  {workload ? (
                    workload.details ? (
                      workload.details
                    ) : (
                      "No Details"
                    )
                  ) : (
                    <Skeleton count={2} />
                  )}
                </Box>
              </Grid>
            </Grid>

            {workflow && workload && workflow.version === "V2" && (
              <Grid>
                <Button
                  onClick={() =>
                    window.open(`/wfm/workload/${workload.id}`, "_blank")
                  }
                  color="primary"
                >
                  Click here to view workflow in Workflow Manager
                </Button>
              </Grid>
            )}
          </Box>
          {workflow && workload && (
            <AQETabWorkflow
              inputParameters={workflow.inputParameters}
              outputParameters={workflow.outputParameters}
              nodes={workflow.nodes}
              version={workflow.version}
              currentNodes={workload.jobList}
              currentInputParam={workload.inputParameters}
              currentOutputParam={workload.outputParameters}
            />
          )}
        </>
      )}
    </Box>
  );
};

AQETab.propTypes = {
  ticketId: PropTypes.string.isRequired
};

//helper functions
const fetchExecutedJobs = ticketId => {
  const url = `${config.urls.base +
    config.urls.apis[
      "ticket-management"
    ]}/ticket-execution/ticket-id/${ticketId}`;

  return axios
    .get(url)
    .then(res => res)
    .catch(err => Promise.reject(err));
};

const fetchAQEWorkload = (execId, version) => {
  const workflowVersion =
    version === "V1" ? config.urls.apis["aqe"] : config.urls.apis["aqev2"];
  const url = `${config.urls.base}${workflowVersion}/workload/clientReference/${execId}`;

  return axios
    .get(url)
    .then(res => res)
    .catch(err => Promise.reject(err));
};

const fetchAQEWorkflow = async (workflowId, version) => {
  const workflowVersion =
    version === "V1" ? config.urls.apis["aqe"] : config.urls.apis["aqev2"];
  const url = `${config.urls.base}${workflowVersion}/workflow/${workflowId}`;

  return axios
    .get(url)
    .then(res => res)
    .catch(err => Promise.reject(err));
};

export default AQETab;
