import {
  // Get Bulk Actions List
  GET_BULK_ACTIONS_LIST_PENDING,
  GET_BULK_ACTIONS_LIST_SUCCESS,
  GET_BULK_ACTIONS_LIST_ERROR,

  // Get Bulk Actions Task
  GET_BULK_ACTIONS_TASKS_PENDING,
  GET_BULK_ACTIONS_TASKS_SUCCESS,
  GET_BULK_ACTIONS_TASKS_ERROR,

  // Setting Tasks, Types, and Tickets
  SET_BULK_ACTION_TASK_TO_CANCEL,
  SET_BULK_ACTION_TYPE,
  SET_BULK_ACTION_TICKETS,

  // Trigger Bulk Action
  TRIGGER_BULK_ACTION_PENDING,
  TRIGGER_BULK_ACTION_SUCCESS,
  TRIGGER_BULK_ACTION_ERROR,

  // Cancel Bulk Action
  CANCEL_BULK_ACTION_PENDING,
  CANCEL_BULK_ACTION_SUCCESS,
  CANCEL_BULK_ACTION_ERROR,

  // Open/Close Bulk Action Right Drawer
  OPEN_BULK_ACTION_DRAWER,
  CLOSE_BULK_ACTION_DRAWER
} from "../actions/bulkActions";

const defaultState = {
  bulkActionList: [],
  bulkActionPayload: {
    bulkActionType: "",
    selectedTickets: []
  },
  bulkActionTasks: {
    items: []
  },
  bulkActionTaskIdToCancel: "",
  isFetchingBulkActions: false,
  isFetchingBulkActionTasks: false,
  isTriggeringBulkAction: false,
  isCancellingBulkAction: false,
  bulkActionDrawerOpen: false
};

function bulkActions(state = defaultState, action) {
  switch (action.type) {
    // =============== Get List of Available Bulk Actions ===============

    case GET_BULK_ACTIONS_LIST_PENDING: {
      return {
        ...state,
        isFetchingBulkActions: true
      };
    }

    case GET_BULK_ACTIONS_LIST_SUCCESS: {
      return {
        ...state,
        isFetchingBulkActions: false,
        bulkActionList: action.bulkActionList
      };
    }

    case GET_BULK_ACTIONS_LIST_ERROR: {
      return {
        ...state,
        isFetchingBulkActions: false
      };
    }

    // =============== Get List of Running Bulk Action Tasks ===============

    case GET_BULK_ACTIONS_TASKS_PENDING: {
      return {
        ...state,
        isFetchingBulkActionTasks: true
      };
    }

    case GET_BULK_ACTIONS_TASKS_SUCCESS: {
      return {
        ...state,
        isFetchingBulkActionTasks: false,
        bulkActionTasks: action.bulkActionTasks
      };
    }

    case GET_BULK_ACTIONS_TASKS_ERROR: {
      return {
        ...state,
        isFetchingBulkActionTasks: false
      };
    }

    // =============== Set Bulk Action Task to Cancel ===============

    case SET_BULK_ACTION_TASK_TO_CANCEL: {
      return {
        ...state,
        bulkActionTaskIdToCancel: action.bulkActionTaskIdToCancel
      };
    }

    // =============== Set Type of Bulk Action to Run ===============

    case SET_BULK_ACTION_TYPE: {
      return {
        ...state,
        bulkActionPayload: {
          ...state.bulkActionPayload,
          bulkActionType: action.bulkActionType
        }
      };
    }

    // =============== Set List of Tickets to Run Bulk Actions on ===============

    case SET_BULK_ACTION_TICKETS: {
      return {
        ...state,
        bulkActionPayload: {
          ...state.bulkActionPayload,
          selectedTickets: action.selectedTickets
        }
      };
    }

    // =============== Trigger Bulk Actions on the Tickets ===============

    case TRIGGER_BULK_ACTION_PENDING: {
      return {
        ...state,
        isTriggeringBulkAction: true,
        bulkActionPayload: { bulkActionType: "", selectedTickets: [] }
      };
    }

    case TRIGGER_BULK_ACTION_SUCCESS: {
      return {
        ...state,
        isTriggeringBulkAction: false
      };
    }

    case TRIGGER_BULK_ACTION_ERROR: {
      return {
        ...state,
        isTriggeringBulkAction: false
      };
    }

    // =============== Cancel Bulk Actions that are currently running ===============

    case CANCEL_BULK_ACTION_PENDING: {
      return {
        ...state,
        isCancellingBulkAction: true,
        bulkActionTaskIdToCancel: ""
      };
    }

    case CANCEL_BULK_ACTION_SUCCESS: {
      return {
        ...state,
        isCancellingBulkAction: false
      };
    }

    case CANCEL_BULK_ACTION_ERROR: {
      return {
        ...state,
        isCancellingBulkAction: false
      };
    }

    // =============== Opening and Closing Bulk Actions Sidebar ===============

    case OPEN_BULK_ACTION_DRAWER: {
      return {
        ...state,
        bulkActionDrawerOpen: true
      };
    }

    case CLOSE_BULK_ACTION_DRAWER: {
      return {
        ...state,
        bulkActionDrawerOpen: false,
        bulkActionPayload: {
          ...state.bulkActionPayload,
          bulkActionType: ""
        }
      };
    }

    default:
      return state;
  }
}

export default bulkActions;
