import styled from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MainContainer = styled(({ isSelected, ...rest }) => (
  <ButtonBase {...rest} />
))`
  margin-right: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 20px !important;
  justify-content: center !important;
  border: ${props =>
    props.isSelected
      ? "2px solid #0277BD !important; box-shadow: 0px 4px 10px rgba(2, 119, 189, 0.19);"
      : "0.5px solid #c9c7c7 !important"};
  border-radius: 5px !important;
  width: 200px !important;
  height: 185px !important;

  @media screen and (max-height: 768px) {
    width: 120px !important;
    height: 120px !important;
  }

  @media screen and (min-height: 769px) and (max-height: 869px) {
    width: 150px !important;
    height: 150px !important;
  }
`;

export const StyledIcon = styled(({ isSelected, ...rest }) => (
  <FontAwesomeIcon {...rest} />
))`
  font-size: 3rem !important;
  color: ${props => (props.isSelected ? "#0277BD" : "#3d3d3d")};
  ${props => !props.isSelected && "opacity: 0.5;"}

  @media screen and (max-height: 768px) {
    font-size: 1.5rem !important;
  }

  @media screen and (min-height: 769px) and (max-height: 869px) {
    font-size: 2rem !important;
  }
`;

export const ButtonText = styled(({ isSelected, ...rest }) => <p {...rest} />)`
  margin: 0;
  margin-top: 10px;
  font-size: 1.2rem;
  color: ${props => (props.isSelected ? "#0277BD" : "#3d3d3d")};
  opacity: ${props => (props.isSelected ? "1" : "0.5")};

  @media screen and (max-height: 768px) {
    font-size: 0.8rem !important;
  }

  @media screen and (min-height: 769px) and (max-height: 869px) {
    font-size: 1rem !important;
  }
`;

export const StyledImage = styled.img`
  height: 45px;

  @media screen and (max-height: 768px) {
    height: 25px;
  }

  @media screen and (min-height: 769px) and (max-height: 869px) {
    height: 30px;
  }
`;
