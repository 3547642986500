import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TicketTemplateViewerPreviewCTN from "./Preview/PreviewCTN";
import TicketTemplateViewerTemplateCTN from "./Template/TemplateCTN";
import TicketTemplateViewerPaginatorCTN from "./Paginator/PaginatorCTN";
import TicketTemplateViewerMappingCTN from "./Mapping/MappingCTN";
import TicketTemplateViewerStepper from "./Stepper/Stepper";

import { withStyles } from "@material-ui/core";

const styles = theme => ({
  wrapper: {
    padding: "20px",
    paddingLeft: "0px",
    overflow: "auto",
    height: "calc(100vh-100px)"
  },
  viewerWrapper: {
    padding: "0px 20px 0px 20px",
    [theme.breakpoints.up("lg")]: {
      borderRight: "1px solid #BBBBBB"
    }
  },
  previewWrapper: {
    height: "calc(100vh - 170px)",
    margin: "0 0px 0px 20px"
  },
  previewTitle: {
    marginLeft: "20px"
  }
});

const TicketTemplateViewer = props => {
  const {
    classes,
    currentPage,
    changeRoute,

    getTemplateMappingFields
  } = props;

  useEffect(() => {
    changeRoute(props.match.params.id || "new");
    getTemplateMappingFields();
  }, []);
  return (
    <Grid container className={classes.wrapper}>
      <Grid container>
        <Grid item sm={12} lg={6} className={classes.viewerWrapper}>
          <TicketTemplateViewerStepper index={currentPage} />
          {currentPage === 0 && <TicketTemplateViewerTemplateCTN />}
          {currentPage === 1 && <TicketTemplateViewerMappingCTN />}
          <Grid container>
            <TicketTemplateViewerPaginatorCTN />
          </Grid>
        </Grid>
        <Grid item sm={12} lg={6}>
          <Grid item xs={12}>
            <h3 className={classes.previewTitle}>ATR Ticket Preview</h3>
          </Grid>

          <Paper className={classes.previewWrapper}>
            <TicketTemplateViewerPreviewCTN />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TicketTemplateViewer);
