import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  GridCollapseContent,
  CollapseContainer,
  NodeCardContainer,
  NodeCardContent,
  SuccessIcon,
  ProgressIcon,
  UntouchedIcon,
  FailedIcon,
  CentralDot,
  StateText
} from "./CardStyle";
import { Grid, Typography, Collapse, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import uuid from "uuid";

const styles = theme => ({
  expand: {
    color: "#e1e4e8",
    transform: "rotate(0deg)",
    marginLeft: 5,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    position: "absolute",
    right: 2
  },
  expandOpen: {
    transform: "rotate(180deg)",
    marginTop: -5
  }
});

function AQETabWorkflowNodeCard({
  title,
  state,
  archorPosition = "top",
  classes,
  params,
  results
}) {
  const [isOpen, setOpen] = useState(false);

  const toggleDetails = () => setOpen(!isOpen);

  const renderStateField = state => {
    switch (state) {
      case "CREATED":
        return (
          <>
            <ProgressIcon />
            <StateText isLink onClick={toggleDetails}>
              In progress...
            </StateText>
          </>
        );
      case "STARTED":
        return (
          <>
            <ProgressIcon />
            <StateText isLink onClick={toggleDetails}>
              In progress...
            </StateText>
          </>
        );
      case "REJECTED":
        return (
          <>
            <FailedIcon />
            <StateText isLink onClick={toggleDetails}>
              Rejected
            </StateText>
          </>
        );
      case "FAILED":
        return (
          <>
            <FailedIcon />
            <StateText isLink onClick={toggleDetails}>
              Failed
            </StateText>
          </>
        );

      case "NOT_DELIVERED":
        return (
          <>
            <FailedIcon />
            <StateText isLink onClick={toggleDetails}>
              Not Delivered
            </StateText>
          </>
        );

      case "DONE":
        return (
          <>
            <SuccessIcon />
            <StateText isLink onClick={toggleDetails}>
              Success
            </StateText>
          </>
        );
      case "UNTOUCHED":
        return (
          <>
            <UntouchedIcon />
            <StateText>Not Triggered</StateText>
          </>
        );

      default:
        break;
    }
  };
  return (
    <NodeCardContainer position={archorPosition}>
      <NodeCardContent position={archorPosition}>
        <Typography variant="body2">{title}</Typography>
        <Grid container item xs={12}>
          {renderStateField(state)}
          {state !== "UNTOUCHED" && (
            <div
              className={classnames(classes.expand, {
                [classes.expandOpen]: isOpen
              })}
            >
              <ExpandMoreIcon />
            </div>
          )}
        </Grid>
        <CentralDot position={archorPosition} state={state} />
      </NodeCardContent>
      {state !== "UNTOUCHED" && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <CollapseContainer>
            {!isEmpty(params) && (
              <>
                <Divider />
                <div style={{ margin: "10px 0", wordBreak: "break-word" }}>
                  {Object.entries(params).map(row => (
                    <GridCollapseContent
                      container
                      spacing={0}
                      direction="column"
                      key={uuid.v4()}
                    >
                      <Typography variant="caption">{row[0]}:</Typography>
                      <Typography>{row[1]}</Typography>
                    </GridCollapseContent>
                  ))}
                </div>
              </>
            )}
            {!isEmpty(results) && (
              <>
                <Divider />
                <div style={{ margin: "10px 0", wordBreak: "break-word" }}>
                  {Object.entries(results).map(row => (
                    <GridCollapseContent
                      container
                      spacing={0}
                      direction="column"
                      key={uuid.v4()}
                    >
                      <Typography variant="caption">{row[0]}:</Typography>
                      <Typography>{row[1]}</Typography>
                    </GridCollapseContent>
                  ))}
                </div>
              </>
            )}
          </CollapseContainer>
        </Collapse>
      )}
    </NodeCardContainer>
  );
}

AQETabWorkflowNodeCard.propTypes = {
  title: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  archorPosition: PropTypes.string,
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  results: PropTypes.object.isRequired
};

AQETabWorkflowNodeCard.defaultProps = {
  params: {},
  results: {},
  state: "UNTOUCHED"
};

export default withStyles(styles)(AQETabWorkflowNodeCard);
