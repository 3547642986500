// Stores styles for Tickets.js
import themeMainUI from "../../styles/themeMainUI";

const Styles = {
  BundleHeading: {
    padding: "0 16px",
    margin: "15px 0 0 0"
  },
  BundleListAvatar: {
    minWidth: "40px",
    justifyContent: "center"
  },
  BundleListItem: {
    fontSize: "13px",
    alignItems: "center"
  },
  BundleListItemCount: {
    color: themeMainUI.palette.accent3Color,
    fontSize: "13px",
    textAlign: "right",
    marginTop: 15
  }
};

export default Styles;
