import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
//inner reference
import PluginTableCTN from "./Table/TableCTN";
import PluginDrawerCTN from "./Drawer/DrawerCTN";
import PrimaryButton from "../templates/PrimaryButton";
import PluginNewPluginCTN from "./NewPlugin/NewPluginCTN";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  }
});

const Plugin = props => {
  const { currentPluginId, openNewPlugin, getTicketSources } = props;

  useEffect(() => {
    getTicketSources();
  }, []);

  return (
    <div>
      <Grid container spacing={8} style={{ padding: 30 }}>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ marginLeft: 25, marginTop: 10, marginBottom: 20 }}>
              Plugins
            </h3>
            <div>
              <PrimaryButton
                style={{ marginRight: 30 }}
                onClick={openNewPlugin}
                label={<strong>UPLOAD A PLUGIN</strong>}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <PluginTableCTN />
        </Grid>
      </Grid>
      <PluginNewPluginCTN />
      <PluginDrawerCTN key={currentPluginId} />
      {/* dialog plugin uploading */}
    </div>
  );
};

export default withStyles(styles)(Plugin);
