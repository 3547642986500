import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { ListItem } from "material-ui";

export function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function inputComponent({ inputRef, ...props }) {
  const { theme } = props;
  return (
    <div
      ref={inputRef}
      {...props}
      style={{
        color: props.isDisabled
          ? "#AAAAAA"
          : theme.palette?.text?.primary || "black"
      }}
    />
  );
}

inputComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
};

export function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          isDisabled: props.isDisabled,
          theme: props.theme,
          ...props.innerProps
        }
      }}
      error={props.selectProps.invalid}
      helperText={props.selectProps.invalid ? "Required" : null}
      {...props.selectProps.textFieldProps}
    />
  );
}

export function Option(props) {
  return (
    <ListItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        whiteSpace: "nowrap"
      }}
      {...props.innerProps}
    >
      {props.children}
    </ListItem>
  );
}

export function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function SingleValue(props) {
  return (
    <div
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
}

export function ValueContainer(props) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ flexWrap: "nowrap", whiteSpace: "nowrap" }}
    >
      {props.children}
    </div>
  );
}
export function IndicatorsContainer(props) {
  return <ArrowDropDownIcon />;
}

export default {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer
};
