import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {
  IconButton,
  Grid,
  Chip,
  Button,
  Divider,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import TicketTableCustomizationAvailableCTN from "./Available/AvailableCTN";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { openSnackbar } from "actions/snackbar";

const styles = {
  wrapper: {
    borderLeft: "1px solid #BDBDBD",
    width: "100%",
    padding: 6,
    height: 600
  },
  sortableListWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 120px)",
    gridGap: "2px"
  },
  deleteIcon: {
    color: "white",
    fontSize: "18px"
  },
  chipLabelDiv: {
    width: "100%",
    textAlign: "left",
    overflow: "hidden"
  },
  chipLabel: {
    overflow: "hidden",
    position: "relative",
    color: "white"
  },
  chip: {
    height: 24,
    width: 120,
    justifyContent: "space-between"
  },
  reset: {
    color: red[500],
    borderColor: red[500]
  }
};

const TicketTableCustomization = props => {
  const {
    classes,
    columnFilter,
    selectedTicketType,
    selectedUserData,
    isFetchingTicketFields,
    customizedTemplateColumn,

    onSortEnd,
    toggleCheckBox,
    setColumnFilter,
    setColumnDefault,
    toggleCustomizationTable,
    changeUserData,
    openSnackbar,
    resetToDefaultColumns
  } = props;

  const sortEnd = ({ oldIndex, newIndex }) => {
    onSortEnd(oldIndex, newIndex, selectedTicketType);
  };

  const saveUserData = () => {
    if (selectedUserData.length == 0) {
      openSnackbar(
        "No columns selected. Please select at least one column or reset to default"
      );
    } else {
      changeUserData();
    }
  };

  const resetToDefault = () => {
    if (selectedUserData.length !== 0) {
      selectedUserData.map((value, index) => {
        resetToDefaultColumns(selectedTicketType, value, false);
      });
    } else {
      resetToDefaultColumns(selectedTicketType, "", false);
    }
  };

  const SortableItem = SortableElement(({ column }) => {
    return (
      <div>
        <Chip
          classes={{
            root: classes.chip,
            deleteIcon: classes.deleteIcon,
            label: classes.chipLabel
          }}
          color="primary"
          label={
            <span className={classes.chipLabelDiv}>{column.displayName}</span>
          }
          onDelete={() => toggleCheckBox(selectedTicketType, column, false)}
        />
      </div>
    );
  });

  const SortableList = SortableContainer(({ customizedTemplateColumn }) => {
    return (
      <>
        {selectedUserData ? (
          <>
            {isFetchingTicketFields ? (
              <></>
            ) : (
              <div className={classes.sortableListWrapper}>
                {customizedTemplateColumn.map((column, index) => (
                  <SortableItem
                    key={`item-${index}`}
                    id={`selected-column-${index}`}
                    index={index}
                    column={column}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            You haven't customize table for {selectedTicketType} ticket.
          </div>
        )}
      </>
    );
  });

  return (
    <div className={classes.wrapper}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ fontWeight: "bold", fontSize: "16px" }}
      >
        <span>Selected Columns:</span>
        <IconButton
          onClick={() => {
            localStorage.removeItem("customisedColumnDisable");
            setColumnDefault();
            toggleCustomizationTable();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <div style={{ height: 80, overflow: "auto" }}>
        <SortableList
          customizedTemplateColumn={customizedTemplateColumn}
          onSortEnd={sortEnd}
          distance="5"
          axis="xy"
        />
      </div>
      <Grid container alignItems="center" justify="space-between">
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
          Available Columns:
        </span>
        {/* Filter */}
        <Input
          value={columnFilter}
          onChange={event => setColumnFilter(event.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="disabled" style={{ fontSize: 18 }} />
            </InputAdornment>
          }
          placeholder="Filter"
          margin="dense"
          style={{ margin: "10px" }}
        />
      </Grid>
      {isFetchingTicketFields ? (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ height: 450 }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container alignItems="center" wrap="wrap">
          <TicketTableCustomizationAvailableCTN />
        </Grid>
      )}

      <Divider />
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginTop: "10px" }}
      >
        <Button
          variant="outlined"
          classes={{
            root: classes.reset
          }}
          onClick={() => {
            localStorage.removeItem("customisedColumnDisable");
            resetToDefault();
          }}
        >
          Reset to Default
        </Button>
        <Button color="primary" variant="outlined" onClick={saveUserData}>
          Save
        </Button>
      </Grid>
    </div>
  );
};

TicketTableCustomization.defaultProps = {
  customizedTemplateColumn: []
};
export default withStyles(styles)(TicketTableCustomization);
