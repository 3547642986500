import React from "react";
import { ListItem, ListItemText, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
const PluginDrawerConfigGroup = React.memo(props => {
  const { pluginGroup, updateCollapse, index } = props;
  return (
    <>
      <ListItem button onClick={() => updateCollapse(index)}>
        <ListItemText primary={pluginGroup.displayName} />
        {pluginGroup.collapsed ? <ExpandMore /> : <ExpandLess />}
      </ListItem>
      <Collapse in={!pluginGroup.collapsed} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </>
  );
});

export default PluginDrawerConfigGroup;
