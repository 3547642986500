import React, { useState } from "react";
import BulkActionDetails from "./BulkActionDetails";
import {
  IconButton,
  ButtonBase,
  Card,
  List,
  Typography
} from "@material-ui/core";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import BulkActionModel from "models/BulkActionModel";
import LabeledIcon, { StyledWarning } from "./LabeledIcon";
import PropTypes from "prop-types";

const BulkActionContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const DetailsContainer = styled(ButtonBase)`
  width: 100%;
`;

const StyledClose = styled(Close)`
  color: #ff6363;
`;

const ErrorContainer = styled(List)`
  padding: 0px 20px !important;
`;

const ErrorList = props => {
  const { errors } = props;
  return (
    <BulkActionContainer elevation={0}>
      <ErrorContainer>
        <Typography variant="body1">{`Errors (${errors.length})`}</Typography>
        {errors.map((error, index) => (
          <LabeledIcon
            key={error + index}
            icon={<StyledWarning />}
            label={error}
          />
        ))}
      </ErrorContainer>
    </BulkActionContainer>
  );
};

const TicketList = props => {
  const { tickets } = props;
  return (
    <BulkActionContainer elevation={0}>
      <ErrorContainer>
        <Typography variant="body1">
          {`Selected Tickets (${tickets.length})`}
        </Typography>
        {tickets.map(ticket => (
          <LabeledIcon key={ticket.id} label={ticket.number} />
        ))}
      </ErrorContainer>
    </BulkActionContainer>
  );
};

const TicketErrorList = props => {
  const { errors, tickets } = props;
  return (
    <>
      <TicketList tickets={tickets} />
      {errors?.length > 0 && <ErrorList errors={errors} />}
    </>
  );
};

const BulkAction = props => {
  const { bulkAction, onDelete, timezone } = props;
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  return (
    <>
      <BulkActionContainer elevation={0}>
        <DetailsContainer
          disableRipple
          onClick={() => setIsDetailsVisible(!isDetailsVisible)}
        >
          <BulkActionDetails bulkAction={bulkAction} timezone={timezone} />
        </DetailsContainer>
        {!bulkAction.done && (
          <IconButton onClick={() => onDelete(bulkAction.taskId)}>
            <StyledClose />
          </IconButton>
        )}
      </BulkActionContainer>
      {isDetailsVisible && (
        <TicketErrorList
          tickets={bulkAction.request.tickets}
          errors={bulkAction.errors}
        />
      )}
    </>
  );
};

BulkAction.propTypes = {
  bulkAction: BulkActionModel.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default BulkAction;
