import React, { useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { userConfig } from "userConfigValidations";

//inner reference
import PluginTableRowCTN from "./Row/RowCTN";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

const PluginTable = props => {
  const { pluginList, getPlugins, getHealth } = props;

  useEffect(() => {
    getPlugins();
  }, [getPlugins]);

  useEffect(() => {
    if (userConfig.variables.healthChecks) getHealth();
  }, [getHealth]);

  return (
    <div>
      <Table>
        <colgroup>
          {userConfig.variables.healthChecks && <col width="5%" />}
          <col width="30%" />
          <col width="30%" />
          <col width="20%" />
          <col width="15%" />
        </colgroup>
        <TableHead>
          <TableRow>
            {userConfig.variables.healthChecks && <TableCell />}
            <TableCell>Name</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Configured</TableCell>
            <TableCell>Activation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pluginList.map(pluginId => (
            <PluginTableRowCTN key={pluginId} id={pluginId} />
          ))}
        </TableBody>
      </Table>
      {/* <PluginsTableRowPage id={"plugin-mywizard"} /> */}
    </div>
  );
};

export default withStyles(styles)(PluginTable);
