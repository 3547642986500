/* These import statements imports all the STATES */
import {
  CLOSE_AUTH_SNACKBAR,
  LOGOUT_SUCCESS,
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_FAILURE_WITH_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_PENDING,
  USER_RESET_PASSWORD_SUCCESS,
  DONE_REFRESHING_TOKEN,
  REFRESHING_TOKEN,
  REASSIGN_UUID,
  SESSION_INVALID,
  SESSION_VALID
} from "../actions/auth/actionTypes";
import uuid from "uuid";

const initialState = {
  isFetching: false,
  isAuthenticated: false,
  message: "",
  messageEmail: "",
  jwt: {},
  uuid: uuid.v4(),
  user: "",
  isSnackbarOpen: false,
  token: false,
  freshTokenPromise: false,
  inSession: true
};

/* These STATE RETURNERS will return current state */
function auth(state = initialState, action) {
  const {
    isFetching,
    isAuthenticated,
    type,
    user,
    token,
    message,
    inSession,
    isSnackbarOpen
  } = action;
  switch (type) {
    case SIGNUP_REQUEST:
      return Object.assign({}, state, {
        isFetching,
        isAuthenticated
      });

    case SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        isFetching,
        isAuthenticated,
        messageEmail: "",
        isSnackbarOpen: action.isSnackbarOpen,
        message
      });
    case CLOSE_AUTH_SNACKBAR:
      return Object.assign({}, state, {
        isSnackbarOpen: action.isSnackbarOpen
      });
    case SIGNUP_FAILURE:
      return Object.assign({}, state, {
        isFetching,
        isAuthenticated,
        messageSingup: {
          Password:
            "Could not create account. Please check that all fields have been entered and an account with the email address used does not not currently exist."
        }
      });
    case SIGNIN_REQUEST:
      return Object.assign({}, state, {
        isFetching,
        isAuthenticated,
        message: undefined
      });
    case SIGNIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching,
        isAuthenticated,
        user,
        token,
        inSession
      });
    case SIGNIN_FAILURE:
      return Object.assign({}, state, {
        isFetching,
        isAuthenticated,
        message: {
          Password: message
        }
      });
    case SIGNUP_FAILURE_WITH_ERROR:
      return Object.assign({}, state, {
        isFetching,
        isAuthenticated,
        messageSingup: { Password: action.error }
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isFetching: false,
        user: "",
        jwt: {}
      });
    case USER_RESET_PASSWORD_PENDING:
      return Object.assign({}, state, {
        isFetching: true
      });
    case USER_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isSnackbarOpen: isSnackbarOpen,
        message: message
      });
    case USER_RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        isSnackbarOpen: isSnackbarOpen,
        message: message
      });
    case SESSION_INVALID:
      return Object.assign({}, state, {
        inSession: false
      });
    case SESSION_VALID:
      return Object.assign({}, state, {
        inSession: true
      });
    case DONE_REFRESHING_TOKEN:
      return Object.assign({}, state, {
        token,
        isFetching,
        freshTokenPromise: false,
        inSession,
        isAuthenticated
      });
    case REFRESHING_TOKEN:
      return Object.assign({}, state, {
        freshTokenPromise: action.freshTokenPromise
      });
    case REASSIGN_UUID:
      return Object.assign({}, state, {
        uuid: uuid.v4()
      });
    default:
      return state;
  }
}

export default auth;
