/**
 * Created by x1 on 26/06/17.
 */
import React from "react";
import { Row, Col } from "react-flexbox-grid/lib";
import Styles from "./TicketDetailsDrawerStyles";

import SecondaryTextField from "../templates/SecondaryTextField";
import SecondaryRadioButtonGroup from "../templates/SecondaryRadioButtonGroup";
import PrimaryDropDownMenu from "../templates/PrimaryDropDownMenu";
import SecondaryCheckbox from "../templates/SecondaryCheckbox";
import SecondaryDatePicker from "../templates/SecondaryDatePicker";

import { RadioButton } from "material-ui/RadioButton";
import MenuItem from "material-ui/MenuItem";

/*

 Textfield multiline
 Multioption select
 Radio buttons
 Date Picker

 CUSTOM TEMPLATE SCHEMA
 The schema of a form object to be renedered
 template = {
 id: String ~ unique identifier for this field
 title: String ~ title of the form
 description: String ~ description for the form
 fields: [] ~ stores field objects to be rendered
 comment: String ~ add internal comments on the JSON
 }

 FIELD SCHEMA
 The schema of possible fields within the template object
 TextField
 {
 id: String ~ unique identifier for this field
 label: String ~ label associated with the field
 type: 'text' ~ has to be 'text'
 placeholder: String ~ for text fields, this is the placeholder on the input element
 comment: String ~ add internal comments on the JSON
 multiline: Boolean ~ allow user to input multiline
 value: String ~ default value for field
 }
 Radio buttons
 {
 id: String ~ unique identifier for this field
 label: String ~ label associated with the field
 type: 'radio' ~ has to be 'radio'
 comment: String ~ add internal comments on the JSON
 value: String ~ default value selected
 }
 Dropdown
 {
 id: String ~ unique identifier for this field
 label: String ~ label associated with the field
 type: 'dropdown' ~ has to be 'dropdown'
 value: String ~ default value selected
 }
 Date
 {
 id: String ~ unique identifier for this field
 label: String ~ label associated with the field
 type: 'date' ~ has to be 'date'
 value: String ~ default value of the date picker
 }
 Paragraph
 {
 id: String ~ unique identifier for this field
 label: String ~ label associated with the field
 type: 'paragraph' ~ has to be 'paragraph'
 value: true ~ to unblock conditionals
 }

 ADVANCED OPTIONS FOR FIELDS
 {
 mapping: String ~ field to map to with ticketing service, eg. serviceNow/Remedy
 index: Int ~  fields with the same index are put on the same line (max 2)
 required: Boolean ~ this field must be filled before submission
 renderIfTrue: Array(Int) ~ this field would render only if fields specified (specified by index) have values in it
 renderIfTrue: Array({
 index: Int,
 value: String || Boolean || Int || Double
 }(1+)) ~ this field would render only if fields specified (specified by index) match a specific value
 }

 */

// TODO: same index = same line
// TODO: mobile view
// TODO: Display form title and description

export default class TicketDetailsTemplateRead extends React.Component {
  constructor(props) {
    super(props);
    // bind global "this" to local "this".
    // the components that use "this" will now be referencing global "this", instead of local "this".
    this._renderTextField = this._renderTextField.bind(this);
    this._renderRadioButtonGroup = this._renderRadioButtonGroup.bind(this);
    this._renderDropDownMenu = this._renderDropDownMenu.bind(this);
    this._renderCheckbox = this._renderCheckbox.bind(this);
    this._renderDatePicker = this._renderDatePicker.bind(this);
    this._handleFieldsDataChange = this._handleFieldsDataChange.bind(this);

    this.state = {
      form: this.props.templates[this.props.templateSelected.key],
      formMethods: {
        // methods connect the field types to the appropriate render methods
        TEXT: this._renderTextField,
        RADIO: this._renderRadioButtonGroup,
        DROPDOWN: this._renderDropDownMenu,
        CHECKBOX: this._renderCheckbox,
        DATE: this._renderDatePicker,
        PARAGRAPH: this._renderParagraph
      }
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.templates !== this.props.templates ||
      nextProps.templateSelected !== this.props.templateSelected
    ) {
      this.setState({
        form: nextProps.templates.find(
          template => template.id === nextProps.templateSelected.id
        )
      });
      this.forceUpdate();
    }

    if (nextProps.templateSelected !== this.props.templateSelected) {
      this.setState({
        form: this.props.templates.find(
          template => template.id === nextProps.templateSelected.id
        )
      });
    }

    if (nextProps.ticket.template !== this.props.ticket.template) {
      this.setState({
        form: nextProps.ticket.template
      });
    }
  }

  _handleFieldsDataChange(field) {
    let form = JSON.parse(JSON.stringify(this.state.form));
    form.fields[field.id].value = field.value;
    this.setState({
      form: form
    });

    // this.props.ticket.template = JSON.stringify(form);
    // console.log(this.props.ticket.template);

    //TODO: Update the form somewhere in the ticket DB so mapping isn't required to record the value when saved.
    // this.props.ticketEdit({
    //   field: 'template',
    //   value: form,
    //   // node: 'automationData'
    // });
    form.fields[field.id].mapping.forEach(value => {
      if (value != null) {
        this.props.ticketEdit({
          field: value,
          value: field.value
        });
      }
    });
  }

  _renderError(message, style) {
    return <div style={style}>{message}</div>;
  }

  _renderTextField(field, id, size) {
    return (
      <Col key={id} className={"col-xs-" + size} style={Styles.formContainer}>
        <Col
          xs={size < 12 ? 4 : 2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {field.label}
        </Col>
        <Col xs={size < 12 ? 8 : 10}>
          <SecondaryTextField
            name={field.label}
            requiredError={field.required && !field.value}
            fullWidth={true}
            placeholder={field.placeholder}
            value={field.value}
            multiLine={field.multiLine}
            onChange={event => {
              this._handleFieldsDataChange({
                id: id,
                value: event.target.value
              });
            }}
          />
        </Col>
      </Col>
    );
  }

  _renderRadioButtonGroup(field, id, size) {
    const requiredError = typeof field.required === "boolean" && !field.value;
    return (
      <Col key={id} className={"col-xs-" + size} style={Styles.formContainer}>
        <Col
          xs={size < 12 ? 4 : 2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {field.label}
        </Col>
        <Col xs={size < 12 ? 8 : 10}>
          <SecondaryRadioButtonGroup
            requiredError={requiredError}
            name={field.label}
            valueSelected={field.value}
            onChange={(event, value) => {
              this._handleFieldsDataChange({
                id: id,
                value: value
              });
            }}
            style={{ marginTop: 10 }}
          >
            {field.options.map(val => {
              return (
                <RadioButton
                  labelStyle={requiredError ? { color: "red" } : {}}
                  iconStyle={requiredError ? { fill: "red" } : {}}
                  key={val}
                  value={val}
                  label={val}
                />
              );
            })}
          </SecondaryRadioButtonGroup>
        </Col>
      </Col>
    );
  }

  _renderCheckbox(field, id, size) {
    if (typeof field.length === "undefined") return false;

    const thresholdError =
      field.optionsWeightedThreshold &&
      field.value.reduce((prev, next) => next.weight + prev, 0) <
        field.optionsWeightedThreshold;
    const requiredError =
      typeof field.required === "boolean" && field.value.length === 0;
    return (
      <Col key={id} className={"col-xs-" + size} style={Styles.formContainer}>
        <Col
          xs={size < 12 ? 4 : 2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {field.label}
        </Col>
        <Col xs={size < 12 ? 8 : 10}>
          <div style={{ marginTop: 15 }}>
            {/* Check if the options are weighted */}
            {field.optionsWeighted
              ? field.optionsWeighted.map((val, i) => {
                  return (
                    <SecondaryCheckbox
                      key={i}
                      requiredError={thresholdError && requiredError}
                      checked={field.value.indexOf(val) !== -1}
                      label={val.option}
                      onCheck={(event, isInputChecked) => {
                        if (
                          typeof field.optionsLimit === "number" ||
                          typeof field.optionsLimit === "undefined"
                        )
                          this._handleFieldsDataChange({
                            id: id,
                            value:
                              isInputChecked &&
                              field.value.length < field.optionsLimit
                                ? field.value.concat([val])
                                : field.value.filter(option => {
                                    return option !== val;
                                  })
                          });
                      }}
                    />
                  );
                })
              : field.options.map((val, i) => {
                  return (
                    <SecondaryCheckbox
                      key={i}
                      requiredError={requiredError}
                      checked={field.value.indexOf(val) !== -1}
                      label={val}
                      onCheck={(event, isInputChecked) => {
                        if (
                          typeof field.optionsLimit === "number" ||
                          typeof field.optionsLimit === "undefined"
                        )
                          this._handleFieldsDataChange({
                            id: id,
                            value:
                              isInputChecked &&
                              field.value.length < field.optionsLimit
                                ? field.value.concat([val])
                                : field.value.filter(option => {
                                    return option !== val;
                                  })
                          });
                      }}
                    />
                  );
                })}
            {requiredError
              ? this._renderError("This is required", Styles.errorText)
              : ""}
            {thresholdError
              ? this._renderError(
                  "You haven't met the threshold",
                  requiredError ? Styles.errorText : Styles.warningText
                )
              : ""}
          </div>
        </Col>
      </Col>
    );
  }

  _renderDropDownMenu(field, id, size) {
    if (typeof field.options === "undefined") return false;

    return (
      <Col key={id} className={"col-xs-" + size} style={Styles.formContainer}>
        <Col
          xs={size < 12 ? 4 : 2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {field.label}
        </Col>
        <Col xs={size < 12 ? 8 : 10}>
          <PrimaryDropDownMenu
            name={field.label}
            value={field.value}
            style={{ width: "100%", margin: 0 }}
            underlineStyle={{ margin: 0, padding: 0 }}
            labelStyle={{
              padding: 0
            }}
            onChange={(event, key) => {
              this._handleFieldsDataChange({
                id: id,
                value: field.options[key]
              });
            }}
            iconStyle={{ right: -10 }}
            listStyle={{ width: 350 / (size / 2) }}
          >
            {field.options.map(val => {
              return <MenuItem primaryText={val} key={val} value={val} />;
            })}
          </PrimaryDropDownMenu>
        </Col>
      </Col>
    );
  }

  _renderDatePicker(field, id, size) {
    const requiredError = typeof field.required === "boolean" && !field.value;
    return (
      <Col key={id} className={"col-xs-" + size} style={Styles.formContainer}>
        <Col
          xs={size < 12 ? 4 : 2}
          style={{
            padding: "0",
            marginTop: "15px",
            fontSize: "12px"
          }}
        >
          {field.label}
        </Col>
        <Col xs={size < 12 ? 8 : 10}>
          <SecondaryDatePicker
            requiredError={requiredError}
            style={{
              marginLeft: 0,
              marginTop: 8
            }}
            textFieldStyle={{
              padding: 0,
              width: "100%"
            }}
            value={field.value}
            onChange={(event, date) => {
              this._handleFieldsDataChange({
                id: id,
                value: date
              });
            }}
          />
        </Col>
      </Col>
    );
  }

  _renderParagraph(field, id, size) {
    // set value to be always true
    field.value = true;
    return (
      <Col key={id} className={"col-xs-" + size} style={Styles.formContainer}>
        <Col
          xs={12}
          style={{
            padding: 0,
            margin: "15px 0"
          }}
        >
          <strong
            style={{
              marginLeft: 0
            }}
          >
            {field.label}
          </strong>
          <div
            style={{
              marginLeft: 0,
              marginTop: 5,
              padding: "0"
            }}
          >
            {field.description}
          </div>
        </Col>
      </Col>
    );
  }

  _flattenTicket = ticket => {
    return Object.assign({}, ticket.coreData, ticket.serviceData);
  };

  _fieldRenderRuleApplies = ticket => {
    return rule => {
      return rule === null || rule.value === ticket[rule.label];
    };
  };
  _fieldShouldRender = ticket => {
    return field => {
      return field.renderIfTrue.every(this._fieldRenderRuleApplies(ticket));
    };
  };

  _addIndexId = (item, index) => {
    item.indexID = index;
    return item;
  };

  render() {
    // Form variable dictates the state
    const form = this.state.form;
    const formMethods = this.state.formMethods;
    const flatTicket = this._flattenTicket(this.props.ticket);
    const fields = form.fields
      .map(this._addIndexId)
      .filter(this._fieldShouldRender(flatTicket));
    return (
      <div
        style={{
          paddingTop: 15
        }}
      >
        <div>
          {fields.map((field, i) => (
            <div key={i}>
              {field.index && form.fields[i + 1].index === field.index ? (
                <Row style={Styles.fieldContainer}>
                  {formMethods[field.type](field, i, 6)}
                  {formMethods[form.fields[i + 1].type](
                    form.fields[i + 1],
                    i + 1,
                    6
                  )}
                </Row>
              ) : (
                ""
              )}
              {/* render in new line if two fields do not have the same index */}
              {typeof field.index === "undefined" ||
              !field.index ||
              typeof field.index === "undefined" ||
              (i > 0 && field.index !== form.fields[i - 1].index) ? (
                <Row style={Styles.fieldContainer}>
                  {formMethods[field.type](field, field.indexID, 12)}
                </Row>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
