import config from "../config";
import { openSnackbar } from "./snackbar";
import { headers } from "./headers";

const MANUAL_RESOLUTION_SUCCESS = "MANUAL_RESOLUTION_SUCCESS";

export const openManualResolution = (
  atsObj,
  gtr,
  coreDataNumber,
  resolutionFlow,
  categoryName
) => {
  return (dispatch, getState) => {
    const url = `${config.urls.base +
      config.urls.apis["ticket-management"]}/manual-resolution/link`;
    let deepLinkParams = atsObj.userValue || atsObj.predictions[0].label;
    if (gtr) {
      deepLinkParams.replace(/\s+/g, "");
    }
    const body = {
      deepLinkParams,
      ticketNumber: coreDataNumber
    };

    const options = {
      method: "POST",
      headers: headers(getState),
      body: JSON.stringify(body)
    };

    fetch(url, options)
      .then(res => {
        switch (res.status) {
          case 200:
          case 201:
            return res.text();
          case 404:
            dispatch(openSnackbar("Parameters Invalid"));
            break;
          default:
            dispatch(openSnackbar("Server Error"));
        }
      })
      .then(body => {
        if (!categoryName) {
          window.open(`${body}&resolutionFlowName=${resolutionFlow}`, "_blank");
          return dispatch({
            type: MANUAL_RESOLUTION_SUCCESS
          });
        }

        const strings = body.split("&");
        const newStrings = strings.map(s => {
          if (s.startsWith("category")) {
            return `category=${categoryName}`;
          }
          return s;
        });

        body &&
          window.open(
            `${newStrings.join("&")}&resolutionFlowName=${resolutionFlow}`,
            "_blank"
          );
        dispatch({
          type: MANUAL_RESOLUTION_SUCCESS
        });
      });
  };
};
