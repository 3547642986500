import { connect } from "react-redux";
import { Report } from "./HealthCheckTypes";
import PluginDrawerHealthCheck from "./HealthCheck";

export interface IProps {
  report: Report | undefined;
  isLoading: Boolean;
  id: string;
}

const mapStateToProps = (state: any, ownProps: IProps) => ({
  report: state.plugin.healthReports.reports.find(
    (hr: Report) => hr.pluginId === ownProps.id
  ),
  isLoading: state.plugin.healthReports.isLoading
});

const PluginDrawerHealthCheckCTN = connect(mapStateToProps)(
  PluginDrawerHealthCheck
);

export default PluginDrawerHealthCheckCTN;
