import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const MainContainer = styled(Grid)`
  padding: 20px;
`;

export const TitleText = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0px 14px 10px 0px;

  @media screen and (max-height: 768px) {
    font-size: 0.8rem;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    font-size: 0.85rem;
  }
`;
