import React from "react";
import history from "../../history";
import { Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import EmailTemplatesLayout from "./EmailTemplatesLayout";
import SecondaryCircularProgress from "../templates/SecondaryCircularProgress";
import PrimaryButton from "../templates/PrimaryButton";
import EmailTemplatesTable from "./EmailTemplatesTable";
import EmailTemplatesForm from "./EmailTemplatesForm";
import muiTheme from "../../styles/themeMainUI";
import MergeTags from "./MergeTags";
import ATRPaginator from "../templates/ATRPaginator";
import PermissionCheck from "../errors/PermissionCheck";

export default class EmailTemplates extends React.Component {
  constructor(props) {
    super(props);

    this._handleRowSelection = this._handleRowSelection.bind(this);
    this._changePage = this._changePage.bind(this);
    this.props.getActiveTemplates();
    this.props.getEmailTemplates();
  }

  _handleRowSelection(rowNumber, columnId) {
    // Don't open drawer if ellipsis is clicked
    if (columnId === 3) return;

    // this.props.openDetails(this.props.emailTemplates[rowNumber]);

    // Replace with proper react route
    history.push(
      "/atr/settings/email-templates/" +
        this.props.emailTemplates[rowNumber].name
    );
  }

  _createNewTemplate() {
    this.setState({
      pageState: "new"
    });

    history.push("/atr/settings/email-templates/new");
  }

  _changePage(moveIndex) {
    let nextPage = this.props.currentPage + moveIndex;

    if (nextPage < 0 || nextPage > this.props.lastPage) return null;

    this.props.changePage(nextPage);
    this.props.getEmailTemplates(nextPage);
  }

  _homePage = () => {
    return (
      <EmailTemplatesLayout
        heading={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ marginLeft: 30, marginTop: 10, marginBottom: 20 }}>
              Email Templates
            </h3>
            <div>
              <PrimaryButton
                style={{ marginRight: 30 }}
                onClick={() => this._createNewTemplate()}
                label={<strong>New Email Template</strong>}
              />
            </div>
          </div>
        }
        body={
          this.props.isFetchingEmailTemplates ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <SecondaryCircularProgress />
            </div>
          ) : (
            <div>
              <EmailTemplatesTable
                deleteEmailTemplate={this.props.deleteEmailTemplate}
                emailTemplates={this.props.emailTemplates}
                handleRowSelection={this._handleRowSelection}
              />
              <ATRPaginator
                changePage={this._changePage}
                currentPage={this.props.currentPage + 1}
                lastPage={this.props.lastPage + 1}
              />
            </div>
          )
        }
      />
    );
  };

  _newPage = () => {
    return (
      <EmailTemplatesLayout
        heading={
          <h3 style={{ marginLeft: 30, marginTop: 10, marginBottom: 0 }}>
            <Link
              to={"/atr/settings/email-templates"}
              style={{ color: muiTheme.palette.accent1Color }}
            >
              Email Templates
            </Link>{" "}
            {">"} Create Template
          </h3>
        }
        body={
          <EmailTemplatesForm
            createEmailTemplate={this.props.createEmailTemplate}
            openDetails={this.props.openDetails}
            sendEmail={this.props.sendEmail}
            isSendingEmail={this.props.isSendingEmail}
          />
        }
      />
    );
  };

  _editPage = path => {
    // Find template
    let idx = this.props.emailTemplates.findIndex(o => {
      return o.name === path.match.params.templateName;
    });

    if (idx < 0) return null;

    return (
      <EmailTemplatesLayout
        heading={
          <h3 style={{ marginLeft: 30, marginTop: 10, marginBottom: 20 }}>
            <Link
              to={"/atr/settings/email-templates"}
              style={{ color: muiTheme.palette.accent1Color }}
            >
              Email Templates
            </Link>{" "}
            {">"} Edit Template
          </h3>
        }
        body={
          <EmailTemplatesForm
            template={this.props.emailTemplates[idx]}
            updateEmailTemplate={this.props.updateEmailTemplate}
            openDetails={this.props.openDetails}
            sendEmail={this.props.sendEmail}
            isSendingEmail={this.props.isSendingEmail}
          />
        }
      />
    );
  };

  render() {
    return (
      <PermissionCheck
        requiredPermissions={["NS_EMAIL_TEMPLATE_READ", "NS_MERGE_TAG_READ"]}
        permissions={this.props.permissions}
      >
        <Switch>
          <Route
            path={"/atr/settings/email-templates/new"}
            render={this._newPage}
          />
          <Route
            path={"/atr/settings/email-templates/:templateName"}
            render={this._editPage}
          />
          <Route
            path={"/atr/settings/email-templates"}
            render={this._homePage}
          />
          <Route
            path={"/atr/settings/merge-tags"}
            render={() => <MergeTags {...this.props} />}
          />
        </Switch>
      </PermissionCheck>
    );
  }
}
