/**
 * Created by aharon.rossano on 17/5/17.
 */
import React from "react";
import moment from "moment";
import TextField from "material-ui/TextField";
import Chip from "material-ui/Chip";
import Checkbox from "material-ui/Checkbox";
import ContentSend from "material-ui/svg-icons/content/send";
import Divider from "material-ui/Divider";
import { Row, Col } from "react-flexbox-grid/lib";
import Styles from "../../styles/themeMainUI";
import IconButton from "material-ui/IconButton";

import ReactDOM from "react-dom";

export default class Chatbot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      enterEnabled: true
    };

    this._handleChange.bind(this);
    this._addMessage.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  scrollToBottom = () => {
    const messagesContainer = ReactDOM.findDOMNode(this.messagesContainer);
    messagesContainer.scrollTop = messagesContainer.scrollHeight;
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  _handleChange = event => {
    this.setState({
      value: event.target.value
    });
  };

  _onKeyPress(event, value) {
    if (event.charCode === 13 && !event.shiftKey && this.state.enterEnabled) {
      event.preventDefault();
      this._addMessage(true);
    }
  }

  _addMessage(enterKeyEnabled) {
    let message = {
      type: "user",
      name: this.props.user,
      user: this.props.user,
      text: this.state.value,
      date: Date.now()
    };
    this.setState({
      enterEnabled: enterKeyEnabled,
      value: ""
    });
    this.props.sendMessage(message, this.props.uuid);
  }

  render() {
    const messages = this.props.messages;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 100px)",
          overflow: "hidden",
          userSelect: "text",
          padding: 0
        }}
      >
        <div
          ref={el => {
            this.messagesContainer = el;
          }}
          style={{
            flexGrow: 200,
            padding: "10px 10px",
            overflowX: "hidden",
            overflowY: "auto",
            lineHeight: "20px"
          }}
        >
          {messages.map((c, i) => {
            return (
              <Col key={i} xs={12}>
                <Row {...(c.type !== "chatbot" ? { end: "xs" } : {})}>
                  <Chip
                    style={{
                      maxWidth: 265,
                      flexBasis: "auto",
                      margin: "5px 0",
                      padding: "5px 0",
                      backgroundColor:
                        c.type === "chatbot"
                          ? Styles.palette.disabledColor
                          : Styles.palette.borderColor
                    }}
                    labelStyle={{
                      lineHeight: "20px",
                      whiteSpace: "pre-wrap",
                      fontSize: 12,
                      userSelect: "text"
                    }}
                  >
                    <p
                      style={{
                        fontSize: 11,
                        margin: 0
                      }}
                    >
                      <strong>{c.name}</strong>
                      &nbsp;&nbsp;&nbsp;
                      <span
                        style={{
                          fontSize: 11
                        }}
                      >
                        {moment(c.date).fromNow()}
                      </span>
                    </p>
                    <span
                      style={{
                        userSelect: "text",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {c.text}
                    </span>
                  </Chip>
                </Row>
              </Col>
            );
          })}
        </div>
        <div
          style={{
            flexGrow: 1
          }}
        >
          <Divider />
          <div
            style={{
              padding: 0,
              justifyContent: "flex-end"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                maxHeight: "200px",
                overflowY: "auto"
              }}
            >
              <Col xs={this.state.enterEnabled ? 12 : 10}>
                <TextField
                  name="text"
                  hintText="Type a message..."
                  onKeyPress={this._onKeyPress.bind(this)}
                  value={this.state.value}
                  onChange={this._handleChange}
                  underlineShow={false}
                  fullWidth={true}
                  multiLine={true}
                />
              </Col>
              {!this.state.enterEnabled ? (
                <Col
                  xs={2}
                  style={{
                    position: "absolute",
                    right: 5
                  }}
                >
                  <IconButton
                    onClick={() => {
                      this._addMessage(false);
                    }}
                  >
                    <ContentSend />
                  </IconButton>
                </Col>
              ) : (
                ""
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "0 10px",
                alignItems: "center"
              }}
            >
              <Checkbox
                label="Press Enter to send"
                onCheck={() => {
                  this.setState({
                    enterEnabled: !this.state.enterEnabled
                  });
                }}
                style={{
                  fontSize: 12,
                  width: 170
                }}
                iconStyle={{
                  marginTop: 2,
                  height: 15,
                  width: 15
                }}
                disableTouchRipple={true}
                labelStyle={{
                  marginLeft: -10
                }}
                checked={this.state.enterEnabled}
              />
              <div
                style={{
                  flexGrow: 1
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
