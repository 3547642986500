import React from "react";
import { Data } from "slate";

import FormatBold from "material-ui/svg-icons/editor/format-bold";
import FormatItalic from "material-ui/svg-icons/editor/format-italic";
import FormatUnderlined from "material-ui/svg-icons/editor/format-underlined";

import { isKeyHotkey } from "is-hotkey";

export const DEFAULT_NODE = "paragraph";

const BLOCK = "block";
const MARK = "mark";

export const HEADING = "heading";
export const LINK = "link";

export const BULLETED_LIST = "bulleted-list";
export const NUMBERED_LIST = "numbered-list";
export const LIST_ITEM = "list-item";

const OTHER_TAGS = {
  blockquote: {
    type: "block-quote",
    render: (attributes, children) => (
      <blockquote {...attributes}>{children}</blockquote>
    )
  },
  pre: {
    type: "code",
    render: (attributes, children) => <pre {...attributes}>{children}</pre>
  },
  ul: {
    type: "bulleted-list",
    render: (attributes, children) => <ul {...attributes}>{children}</ul>
  },
  li: {
    type: "list-item",
    render: (attributes, children) => <li {...attributes}>{children}</li>
  },
  ol: {
    type: "numbered-list",
    render: (attributes, children) => <ol {...attributes}>{children}</ol>
  }
};

export const HEADING_TAGS = {
  h1: {
    type: "heading-one",
    menuItem: "Heading 1",
    render: (attributes, children) => <h1 {...attributes}>{children}</h1>
  },
  h2: {
    type: "heading-two",
    menuItem: "Heading 2",
    render: (attributes, children) => <h2 {...attributes}>{children}</h2>
  },
  p: {
    type: "paragraph",
    menuItem: "Normal",
    render: (attributes, children) => <p {...attributes}>{children}</p>
  }
};

export const BLOCK_TAGS = Object.assign({}, HEADING_TAGS, OTHER_TAGS);

// Add a dictionary of mark tags.
export const MARK_TAGS = {
  strong: {
    type: "bold",
    render: (attributes, children) => (
      <strong {...attributes}>{children}</strong>
    ),
    icon: ({ color }) => <FormatBold color={color} />,
    isHotkey: isKeyHotkey("mod+b")
  },
  em: {
    type: "italic",
    render: (attributes, children) => <em {...attributes}>{children}</em>,
    icon: ({ color }) => <FormatItalic color={color} />,
    isHotkey: isKeyHotkey("mod+i")
  },
  u: {
    type: "underlined",
    render: (attributes, children) => <u {...attributes}>{children}</u>,
    icon: ({ color }) => <FormatUnderlined color={color} />,
    isHotkey: isKeyHotkey("mod+u")
  }
};

export const rules = [
  {
    deserialize(el, next) {
      const tag = BLOCK_TAGS[el.tagName.toLowerCase()];
      return (
        tag && {
          object: BLOCK,
          type: tag.type,
          nodes: next(el.childNodes)
        }
      );
    },
    serialize(obj, children) {
      if (obj.object === BLOCK) {
        return Object.keys(BLOCK_TAGS)
          .reduce((o, key) => {
            if (BLOCK_TAGS[key].type === obj.type) o = BLOCK_TAGS[key];
            return o;
          }, {})
          .render(null, children);
      }
    }
  },
  // Add a new rule that handles marks...
  {
    deserialize(el, next) {
      const tag = MARK_TAGS[el.tagName.toLowerCase()];
      return (
        tag && {
          object: MARK,
          type: tag.type,
          nodes: next(el.childNodes)
        }
      );
    },
    serialize(obj, children) {
      if (obj.object === MARK) {
        return Object.keys(MARK_TAGS)
          .reduce((o, key) => {
            if (MARK_TAGS[key].type === obj.type) o = MARK_TAGS[key];
            return o;
          }, {})
          .render(null, children);
      }
    }
  },
  {
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === "a" && el.href) {
        return {
          object: "inline",
          type: "link",
          nodes: next(el.childNodes),
          data: Data.fromJSON({
            href: el.href
          })
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === "inline") {
        switch (obj.type) {
          case "link":
            return <a href={obj.data.get("href")}>{children}</a>;
          default:
        }
      }
    }
  }
];
