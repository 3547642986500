import React, { Component } from "react";
import AutoComplete from "material-ui/AutoComplete";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

class FilterDropdown extends Component {
  state = {
    value: null
  };

  handleChange = (event, index, value) => {
    this.setState({ value });
    this.props.filter.action(value);
  };

  render() {
    const { filter } = this.props;

    return (
      <SelectField
        value={this.state.value}
        onChange={this.handleChange}
        autoWidth={true}
        style={{
          padding: "0 16px",
          width: null,
          display: "block"
        }}
        maxHeight={186}
        underlineStyle={{
          position: "inherit",
          marginTop: -8
        }}
      >
        <MenuItem value={null} primaryText="All" key="all" />
        {filter.dataSource.map(item => (
          <MenuItem value={item} primaryText={item} key={item} />
        ))}
      </SelectField>
    );
  }
}

const FilterAutoComplete = props => {
  const { filter, ticketFilter } = props.filter;
  return (
    <AutoComplete
      hintText="Search..."
      searchText={ticketFilter[filter.name]}
      onUpdateInput={text => filter.action(text)}
      filter={AutoComplete.caseInsensitiveFilter}
      onNewRequest={(str, i) => {
        filter.action(str);
      }}
      dataSource={filter.dataSource}
      style={{
        padding: "0 16px",
        width: null,
        display: "block"
      }}
      textFieldStyle={{
        width: "100%"
      }}
    />
  );
};

const FilterInner = props => {
  switch (props.filter.type) {
    case "dropdown":
      return <FilterDropdown {...props} />;
    default:
      return <FilterAutoComplete {...props} />;
  }
};

const Filter = props => {
  return (
    <div>
      <h4 style={props.style}>{props.name}</h4>
      <FilterInner filter={props} />
    </div>
  );
};

export default Filter;
