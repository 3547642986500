import React, { Fragment } from "react";
import update from "immutability-helper";

import { withStyles } from "@material-ui/core/styles";
import { FontIcon } from "material-ui";
import { TextField, Grid, Popover, MenuItem } from "@material-ui/core/";
import FilterButton from "./FilterButton";
import FilterButtonBadged from "./FilterButtonBadged";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";

const styles = theme => ({
  badge: {
    backgroundColor: "rgb(10, 131, 196)",
    color: "#fff"
  },
  popover: {
    padding: "20px 0",
    width: "100%",
    minWidth: "650px"
  }
});

class FilterDialog extends React.Component {
  //move to props!!
  state = {
    activeFilters: this.props.ticketActiveFilters,
    filters: this.props.ticketFilter,
    isDialogOpen: false,
    anchorEl: null,
    showSecondary: false //should the secondary fields be visible to the user
  };

  componentDidMount() {
    this.props.toggleShowMoreFilters(false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isCacheRefreshing !== prevProps.isCacheRefreshing &&
      this.props.isCacheRefreshing === false
    ) {
      this.handleDialogClose();
    }
  }

  handleDialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  handleFilterDialogOpen = e => {
    this.setState({
      anchorEl: e.currentTarget,
      isDialogOpen: true,
      activeFilters: this.props.activeFilters,
      filters: this.props.ticketFilter
    });
  };

  handleReset = () => {
    let date = { start: "", end: "" };
    let newFilter = {};

    Object.keys({ ...this.props.ticketFilter }).map(item => {
      return (newFilter[item] = "");
    });
    this.setState(prevState => {
      return update(prevState, {
        filters: { $set: newFilter },
        isDialogOpen: { $set: false }
      });
    });
    let ticketType = null;

    this.props.ticketSetPreset("");
    this.props.ticketSetFilter(newFilter);
    this.props.ticketSetTimeRange(date);
    this.props.moveTicketCachePointer(
      0,
      this.props.cacheLocalPointer,
      this.props.cacheObject,
      this.props.ticketSort,
      { ...this.props.ticketFilter, ...newFilter },
      ticketType || this.props.selectedTicketType,
      date,
      "" //empty presetFilter
    );
  };

  handleApply = () => {
    let newFilter = this.state.filters;
    let ticketType = null;

    this.props.ticketSetFilter(newFilter);
    // this.props.ticketSetTimeRange(dateTimeRange);
    this.props.moveTicketCachePointer(
      0,
      this.props.cacheLocalPointer,
      this.props.cacheObject,
      this.props.ticketSort,
      { ...this.props.ticketFilter, ...newFilter },
      ticketType || this.props.selectedTicketType,
      this.props.ticketTimeRange,
      this.props.presetSelected
    );
  };

  handleInputChange = name => event => {
    const { target } = event;
    let fieldState = { ...this.state.filters };
    fieldState[name] = target.value;
    this.setState(prevState => {
      return update(prevState, {
        filters: {
          $set: fieldState
        }
      });
    });
  };

  handleDateChange = e => {
    let d = { ...this.props.ticketTimeRange };
    e.target.id === "startDate"
      ? !d.end //set endDate to same as startDate
        ? (d.end = d.start = e.target.value)
        : (d.start = e.target.value)
      : (d.end = e.target.value);
    this.props.ticketSetTimeRange(d);
  };

  isEmptyObject = o => {
    if (o) {
      return Object.keys(o).every(x => {
        return o[x] === "" || o[x] === null; // or just "return o[x];" for falsy values
      });
    }
    return true;
  };

  countFilters = () => {
    let count = 0;
    if (!this.isEmptyObject(this.props.tickets.ticketTimeRange)) {
      count++;
    }
    if (this.props.presetSelected !== "") {
      count++;
    }
    Object.values(this.props.ticketFilter).map(item => {
      if (item) {
        count++;
      }
      return null;
    });
    return count;
  };

  renderFilterDropdownElement = filter => {
    // let fieldState = { ...this.state.filters };
    // fieldState[filter.name] = "";
    // this.setState(prevState => {
    //   return update(prevState, {
    //     filters: {
    //       $set: fieldState
    //     }
    //   });
    // });
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: "60px" }}
        key={filter.name}
      >
        <Grid item xs={4}>
          <div>{filter.displayName}</div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id={filter.name}
            select
            fullWidth
            value={this.state.filters[filter.name] || ""}
            onChange={this.handleInputChange(filter.name)}
            margin="normal"
          >
            <MenuItem key="none" value={""} />
            {filter.values.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  };

  //TO DO: separate out to a dumb component taking props
  /*
    parameters:
      filter: Object
      {    {
            key: "",
            name: "",
            visible: Boolean
            type: "",
            suggestions: []
          },}
  */
  renderFilterTextFieldElement = filter => {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: "60px" }}
        key={filter.name}
      >
        <Grid item xs={2} sm={4}>
          <div>{filter.displayName}</div>
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
            id={filter.name}
            fullWidth
            value={this.state.filters[filter.name]}
            onChange={this.handleInputChange(filter.name)}
            margin="none"
          />
        </Grid>
      </Grid>
    );
  };

  renderPresetDropdown = ticketType => {
    const filters = this.props.presetFilters[ticketType];
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: "60px" }}
        key={"presetFilters"}
      >
        <Grid item xs={4}>
          <div>Preset Filters</div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id={"presetFilters"}
            select
            fullWidth
            value={this.props.presetSelected || ""}
            onChange={e => this.props.ticketSetPreset(e.target.value)}
            margin="normal"
          >
            <MenuItem key="none" value={""} />
            {filters.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  };

  renderDateRange = () => {
    const dateRange = this.props.ticketTimeRange;
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: "60px" }}
      >
        <Grid item xs={4}>
          <div>Last Update Date</div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="startDate"
            label="Start Date"
            type="date"
            error={dateRange ? dateRange.start > dateRange.end : false}
            helperText={
              dateRange && dateRange.start > dateRange.end
                ? "date is after end date"
                : null
            }
            value={dateRange ? dateRange.start : ""}
            onChange={e => this.handleDateChange(e)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="endDate"
            label="End Date"
            type="date"
            error={dateRange ? dateRange.end < dateRange.start : false}
            helperText={
              dateRange && dateRange.end < dateRange.start
                ? "date is before start date"
                : null
            }
            value={dateRange ? dateRange.end : ""}
            onChange={e => this.handleDateChange(e)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    );
  };

  renderTicketStatus = () => {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ height: "60px" }}
      >
        <Grid item xs={4}>
          <div>Ticket Status</div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="state"
            select
            fullWidth
            value={this.state.filters["state"] || ""}
            onChange={this.handleInputChange("state")}
            margin="normal"
          >
            <MenuItem key="none" value="" />
            {this.props.ticketStatus &&
              this.props.ticketStatus.states.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    );
  };

  renderForm = () => {
    const classes = this.props.classes;
    const { activeTemplates, selectedTicketType, presetFilters } = this.props;
    const { dateRange } = this.props.ticketTimeRange;

    return (
      <div className={classes.popover}>
        <Grid container spacing={0}>
          {this.renderDateRange()}
          {presetFilters[selectedTicketType] &&
            presetFilters[selectedTicketType].length > 1 &&
            this.renderPresetDropdown(selectedTicketType)}
          {this.renderTicketStatus()}
          {activeTemplates[selectedTicketType] &&
            activeTemplates[selectedTicketType].primaryFields &&
            activeTemplates[selectedTicketType].primaryFields.map(filter => {
              if (filter.type === "dropdown") {
                return this.renderFilterDropdownElement(filter);
              } else {
                return this.renderFilterTextFieldElement(filter);
              }
            })}
          {this.props.showMoreFilters &&
            activeTemplates[selectedTicketType] &&
            activeTemplates[selectedTicketType].secondaryFields &&
            activeTemplates[selectedTicketType].secondaryFields.map(filter => {
              if (filter.type === "dropdown") {
                return this.renderFilterDropdownElement(filter);
              } else {
                return this.renderFilterTextFieldElement(filter);
              }
            })}
          <Grid container spacing={0}>
            <Grid item xs={11} sm={11}>
              <Grid container direction="row" justify="flex-end">
                <SecondaryButton
                  style={{ marginTop: "10px" }}
                  icon={
                    <FontIcon
                      style={{
                        fontSize: "1em",
                        paddingBottom: "3px"
                      }}
                      className={
                        this.props.showMoreFilters
                          ? "fa fa-minus"
                          : "fa fa-plus"
                      }
                    />
                  }
                  label={
                    <strong>
                      {this.props.showMoreFilters ? "Less" : "More"}
                    </strong>
                  }
                  onClick={() => {
                    this.props.toggleShowMoreFilters(
                      !this.props.showMoreFilters
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            alignItems="flex-end"
            justify="center"
            style={{ height: "60px" }}
          >
            <Grid item xs={10} sm={10}>
              <Grid container direction="row" justify="flex-end">
                <SecondaryButton
                  style={{ marginTop: "20px" }}
                  icon={
                    <FontIcon
                      style={{
                        fontSize: "1em",
                        paddingBottom: "3px"
                      }}
                    />
                  }
                  label={<strong>Reset</strong>}
                  onClick={this.handleReset}
                  disabled={dateRange ? dateRange.start > dateRange.end : false}
                />
                <div style={{ paddingRight: "10px" }} />
                <PrimaryButton
                  style={{ marginTop: "20px" }}
                  icon={
                    <FontIcon
                      style={{
                        fontSize: "1em",
                        paddingBottom: "3px"
                      }}
                    />
                  }
                  label={<strong>Apply</strong>}
                  onClick={this.handleApply}
                  disabled={dateRange ? dateRange.start > dateRange.end : false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const { isDialogOpen } = this.state;
    return (
      <Fragment>
        {this.isEmptyObject(this.props.ticketFilter) &&
        this.isEmptyObject(this.props.tickets.ticketTimeRange) &&
        this.props.presetSelected === "" ? (
          <FilterButton onClick={this.handleFilterDialogOpen} />
        ) : (
          <FilterButtonBadged
            onClick={this.handleFilterDialogOpen}
            filtered={this.countFilters()}
          />
        )}
        <Popover
          onClose={this.handleDialogClose}
          open={isDialogOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          children={this.renderForm()}
          style={{ width: "80%", height: "80%" }}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(FilterDialog);
