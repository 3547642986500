import React from "react";
import DataSourceList from "./datasource/DataSourceList";

class SettingsData extends React.Component {
  constructor(props) {
    super(props);
    this._toggleBundles = this._toggleBundles.bind(this);

    this.state = {
      bundles: [
        {
          name: "Data Settings",
          filters: [
            {
              name: "Sources"
            }
          ]
        }
      ],
      bundleSelected: {}
    };
  }

  _toggleBundles() {
    const { isBundlesOpen, closeBundles, openBundles } = this.props;
    isBundlesOpen ? closeBundles() : openBundles();
  }

  _handleBundle(bundle) {
    this.setState({
      bundleSelected: bundle
    });
  }

  render() {
    return (
      <div style={{ height: "calc(100vh - 78px)", overflowY: "auto" }}>
        <DataSourceList {...this.props} />
      </div>
    );
  }
}

export default SettingsData;
