import TicketTablePagination from "./Pagination";
import { connect } from "react-redux";
import { moveTicketCachePointer } from "actions/tickets";

const mapStateToProps = state => {
  return {
    cacheLocalFinal: state.tickets.cacheLocalFinal,
    cacheLocalPointer: state.tickets.cacheLocalPointer,
    cacheObject: state.tickets.cacheObject,
    ticketSort: state.tickets.ticketSort,
    ticketFilter: state.tickets.ticketFilter,
    ticketTimeRange: state.tickets.ticketTimeRange,
    selectedTicketType: state.tickets.selectedTicketType,
    presetSelected: state.tickets.presetSelected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    moveTicketCachePointer: (
      moveIndex,
      localPage,
      cache,
      sortBy,
      filters,
      ticketType,
      ticketTimeRange,
      presetSelected = "",
      polling = false
    ) =>
      dispatch(
        moveTicketCachePointer(
          moveIndex,
          localPage,
          cache,
          sortBy,
          filters,
          ticketType,
          ticketTimeRange,
          presetSelected,
          polling
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTablePagination);
