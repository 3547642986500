import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import FlatButton from "material-ui/FlatButton";

class SecondaryButton extends React.Component {
  render() {
    return (
      <FlatButton
        disabled={false}
        color={themeMainUI.palette.primary2Color}
        {...this.props}
      />
    );
  }
}

export default SecondaryButton;
