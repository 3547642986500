import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  withStyles,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  Button,
  Divider,
  ExpansionPanelActions
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Error } from "@material-ui/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import classNames from "classnames";
import { red500 } from "material-ui/styles/colors";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 271
  },
  errorMsg: {
    display: "flex",
    alignItems: "center",
    color: red500,
    paddingTop: 10
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  expansionPanelDetails: {
    display: "flex",
    justifyContent: "space-between"
  }
});

const PluginDrawerTicketSources = props => {
  const { pluginTicketSources, isLoading, classes } = props;
  const { submitTicketSource, cancelTicketSource, updateTicketSource } = props;

  const ErrorScreen = () => (
    <div className={classes.errorMsg}>
      <div>
        <Error className={classNames(classes.leftIcon, classes.iconSmall)} />
      </div>
      <Typography variant="headline">
        Cannot load ticket sources for this plugin.
      </Typography>
    </div>
  );

  const NormalScreen = () => (
    <>
      <div>
        {pluginTicketSources.map(
          (
            {
              description: { ticketType, displayName },
              polling: { lastPollingDate, enabled }
            },
            idx
          ) => (
            <ExpansionPanel key={ticketType}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {displayName}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                <TextField
                  id="datetime-local"
                  label="Last Pulling Date (Local System Time)"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={e =>
                    updateTicketSource({
                      idx,
                      field: "lastPollingDate",
                      value: e.target.value
                    })
                  }
                  className={classes.textField}
                  value={moment(lastPollingDate).format("YYYY-MM-DD[T]HH:mm")}
                />
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={enabled}
                        onChange={() =>
                          updateTicketSource({
                            idx,
                            field: "enabled",
                            value: !enabled
                          })
                        }
                        color="primary"
                        value="enabled"
                      />
                    }
                    label={enabled ? "Enabled" : "Disabled"}
                  />
                </FormControl>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  size="small"
                  onClick={() =>
                    cancelTicketSource({
                      idx,
                      ticketType
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() =>
                    submitTicketSource({
                      idx,
                      ticketType
                    })
                  }
                >
                  Save
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          )
        )}
      </div>
    </>
  );

  return (
    <div>
      {isEmpty(pluginTicketSources) && !isLoading
        ? ErrorScreen()
        : NormalScreen()}
    </div>
  );
};

export default withStyles(styles)(PluginDrawerTicketSources);
