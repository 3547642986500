export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export function openSnackbar(message) {
  return {
    type: OPEN_SNACKBAR,
    message
  };
}

export function closeSnackbar() {
  return {
    type: CLOSE_SNACKBAR
  };
}
