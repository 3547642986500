/**
 * Created by x1 on 6/06/17.
 */

import React from "react";
import { CardText } from "material-ui/Card";

import { Col } from "react-flexbox-grid/lib";
import styles from "./TicketDetailsDrawerStyles";
import SecondaryButton from "../templates/SecondaryButton";
import PredictionChip from "../templates/PredictionChip";
import Styles from "../../styles/themeMainUI";

import TicketDetailsTemplateRead from "./TicketDetailsTemplateRead";

/**

 Each ticket has multiple ChipSets (eg. Template, Assignment Group, etc.)
 --> this is stored in this.props.prediction

 Each chipSet has multiple chips representing categories that come underneath the Chipset.
 --> This is stored in this.props.prediction[i].chipData => Array Object storing Chip JSON objects

 Each ChipSet can only have one chip selected (representing the ideal selection for the issue)
 --> The selected chip is stored in this.props.prediction[i].chipSelected => JSON object with Chip payload

 Each ChipSet has an addChip variable that shows/hides the addChip textbox

 * */

export default class TicketDetailsTemplates extends React.Component {
  constructor(props) {
    super(props);

    const templateListChips = this.props.templateList.map((template, i) => ({
      label: template.name,
      key: i,
      id: template.id
    }));

    this._renderChip = this._renderChip.bind(this);
    this.state = {
      templates: [
        {
          chipName: "Templates",
          chipData: templateListChips,
          chipExpanded: false,
          chipSelected: templateListChips[0]
        }
      ]
    };
  }

  _renderChip(chipSet, j) {
    /* chipSet is the parent of the chipSet.chipData Array that stores all the chips */
    /* j is the index of the current chip being rendered from the chipSet.chipData array */
    return (
      <PredictionChip
        key={j}
        prediction={chipSet.chipData[j].prediction}
        onClick={() => {
          // make selected chip = this chip
          chipSet.chipSelected = chipSet.chipData[j];
          this.forceUpdate();
        }}
        // highlight if selected chip = this chip
        selected={chipSet.chipSelected === chipSet.chipData[j]}
      >
        <strong>{chipSet.chipData[j].label}</strong>
      </PredictionChip>
    );
  }

  _moveSelectedToFront(chipSet, chipSetEdited) {
    const templates = this.state.templates.slice();
    chipSetEdited.chipData = chipSetEdited.chipData.sort((x, y) =>
      x === chipSet.chipSelected ? -1 : y === chipSet.chipSelected ? 1 : 0
    );
    templates[templates.indexOf(chipSet)] = chipSetEdited;
    this.setState({
      templates
    });
  }

  render() {
    return (
      <CardText style={{ padding: "0px" }}>
        {this.state.templates ? (
          this.state.templates.map((chipSet, i) => (
            <div key={chipSet.chipName} style={styles.chipContainer}>
              <Col
                xs={12}
                sm={2}
                style={{
                  fontSize: "12px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: 0
                }}
              >
                {chipSet.chipName}
              </Col>
              <Col
                xs={12}
                sm={10}
                style={{
                  padding: 0,
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row"
                }}
              >
                {chipSet.chipData.map((chip, j) => {
                  if (j < 3 || chipSet.chipExpanded) {
                    return this._renderChip(chipSet, j);
                  }
                  return null;
                })}

                {chipSet.chipData.length > 3 ? (
                  <SecondaryButton
                    id={`${chipSet.chipName}_expand`}
                    onClick={() => {
                      const chipSetEdited = Object.assign({}, chipSet);
                      chipSetEdited.chipExpanded = !chipSetEdited.chipExpanded;
                      this._moveSelectedToFront(chipSet, chipSetEdited);
                      this.forceUpdate();
                    }}
                    labelStyle={{
                      textTransform: "none",
                      color: Styles.palette.accent3Color
                    }}
                    style={{ marginLeft: "4px" }}
                    label={chipSet.chipExpanded ? "Show less" : "Show more"}
                  />
                ) : (
                  ""
                )}
                <div style={{ width: "100px", height: "10px" }} />
              </Col>
            </div>
          ))
        ) : (
          <div />
        )}
        {this.props.templateList.length === 0 && (
          <div
            style={{
              margin: 20,
              padding: 20,
              fontSize: 13,
              backgroundColor: Styles.palette.accent2Color,
              color: Styles.palette.accent3Color
            }}
          >
            No Custom Templates
          </div>
        )}
        {this.state.templates[0].chipSelected && (
          <TicketDetailsTemplateRead
            ticket={this.props.ticket}
            ticketEdit={this.props.ticketEdit}
            templates={this.props.templateList}
            templateSelected={this.state.templates[0].chipSelected}
          />
        )}
      </CardText>
    );
  }
}
