import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { IFieldsProps } from "../DryRunInterface";
import { automationConfigsStyles, ICSSProps } from "../Styles";
import { withStyles } from "@material-ui/core";

interface ITriggerConditionsProps {
  triggerConditions: IFieldsProps[];
}

const TriggerConditions: React.FC<ITriggerConditionsProps &
  ICSSProps<typeof automationConfigsStyles>> = React.memo(
  ({ triggerConditions, classes }) => {
    return (
      <>
        <span className={classes.tableTitle}>Trigger Conditions</span>
        <Table>
          <colgroup>
            <col width="12%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableColumn}>Field</TableCell>
              <TableCell className={classes.tableColumn}>Condition</TableCell>
              <TableCell className={classes.tableColumn}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {triggerConditions.map(row => {
              return (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.tableBorder}
                    component="th"
                    scope="row"
                  >
                    {row.displayName}
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    {row.value}
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    {row.valueFromTicket}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  }
);

export default withStyles(automationConfigsStyles)(TriggerConditions);
