import { connect } from "react-redux";
import SettingsData from "../../components/settings/SettingsData";
import { closeBundles, openBundles } from "../../actions/bundles";
import { closeSnackbar, openSnackbar } from "../../actions/snackbar";
import {
  retrieveDataSourceList,
  openDetails,
  closeDetails,
  createDataSource,
  purgeElasticSearch
} from "../../actions/dataSource";

const mapStateToProps = state => ({
  isBundlesOpen: state.bundles.isOpen,
  isSnackbarOpen: state.snackbar.isOpen,

  dataSource: state.dataSource.dataSource,
  dataSources: state.dataSource.dataSources,
  isDetailsOpen: state.dataSource.isDetailsOpen,
  isCreateDataSource: state.dataSource.isCreateDataSource,
  permissions: state.user.permissions
});

const mapDispatchToProps = dispatch => ({
  closeBundles: () => dispatch(closeBundles()),
  openBundles: () => dispatch(openBundles()),
  closeSnackbar: () => dispatch(closeSnackbar()),
  openSnackbar: message => dispatch(openSnackbar(message)),

  retrieveDataSourceList: () => dispatch(retrieveDataSourceList()),
  openDetails: dataSource => dispatch(openDetails(dataSource)),
  closeDetails: () => dispatch(closeDetails()),

  createDataSource: dataSource => dispatch(createDataSource(dataSource)),
  purgeElasticSearch: () => dispatch(purgeElasticSearch())
});

const SettingsPage = connect(mapStateToProps, mapDispatchToProps)(SettingsData);

export default SettingsPage;
