import { connect } from "react-redux";
import SNOWForm from "../index";
import {
  openPluginData,
  submitConfig,
  updateConfig
} from "../../../../../../../redux/modules/plugin";
import { setSNOWConfig } from "../../../../../../../actions/tutorial";

const mapStateToProps = (state, ownProps) => {
  return {
    currentPlugin: state.plugin.currentPlugin,
    isSnackbarOpen: state.snackbar.isOpen,
    snackbarMessage: state.snackbar.message,
    handleNext: ownProps.handleNext,
    config: state.tutorial.snowConfig
  };
};

const mapDispatchToProps = dispatch => ({
  openPlugin: id => dispatch(openPluginData(id)),
  updateField: (pluginId, value) => dispatch(updateConfig({ pluginId, value })),
  submitConfig: id => dispatch(submitConfig(id)),
  setConfig: config => dispatch(setSNOWConfig(config))
});

export default connect(mapStateToProps, mapDispatchToProps)(SNOWForm);
