import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
//inner reference
import {
  GET_CONFIG,
  GET_PLUGINS,
  GET_HEALTH,
  GET_TICKETSOURCES,
  SUBMIT_CONFIG,
  SUBMIT_NEW_PLUGIN,
  SUBMIT_TICKETSOURCE,
  UPDATE_ACTIVATION
} from "../modules/plugin";
import {
  closeNewPlugin,
  getConfigStartLoading,
  getConfigStopLoading,
  getHealthStartLoading,
  getHealthStopLoading,
  openPluginData,
  saveConfig,
  savePlugins,
  saveTicketSources,
  submitConfigFail,
  submitTicketSourceSuccess,
  updateActivationSuccess,
  startLoading,
  stopLoading,
  getConfig,
  saveHealth
} from "../modules/plugin";
import { openSnackbar } from "../../actions/snackbar";
import { isEmpty } from "lodash";
import moment from "moment";
import config from "../../config";
import {
  axiosInstance as axios,
  axiosInstanceSnackbar as axiosSnackbar
} from "../../utils/axios";

const getTicketSources = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/ticketsource`;
  return axios.get(url);
};

const getPlugins = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/plugin`;
  return axios.get(url);
};

const submitNewPlugin = (data, reqConfig) => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/plugin`;
  return axiosSnackbar.post(url, data, reqConfig);
};

const getTicketConfig = id => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/plugin/${id}/conf`;
  return axios.get(url);
};

const submitTicketSource = (ticketType, data) => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/ticketsource/${ticketType}/polling`;
  return axiosSnackbar.put(url, data);
};

const updateActivation = (id, data) => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/plugin/${id}/conf`;
  return axiosSnackbar.put(url, data);
};

const submitTicketConfig = (id, data) => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/plugin/${id}/conf`;
  return axiosSnackbar.put(url, data);
};

const getPluginHealth = () => {
  const url = `${config.urls.base +
    config.urls.apis["ticket-management"]}/plugin/health`;
  return axios.get(url);
};

export const pluginSagas = [
  takeEvery(GET_PLUGINS, handleGetPlugins),
  takeLatest(GET_CONFIG, handleGetConfig),
  takeLatest(SUBMIT_CONFIG, handleSubmitConfig),
  takeLatest(SUBMIT_TICKETSOURCE, handleSubmitTicketSource),
  takeLatest(SUBMIT_NEW_PLUGIN, handleSubmitNewPlugin),
  takeLatest(UPDATE_ACTIVATION, handleUpdateActivation),
  takeEvery(GET_TICKETSOURCES, handleGetTicketSources),
  takeLatest(GET_HEALTH, handleGetHealth)
];

function* handleGetTicketSources() {
  yield put(startLoading());
  try {
    const { data } = yield call(getTicketSources);
    const validTicketSources = isEmpty(data)
      ? []
      : data.filter(ticketSource => ticketSource.description.providerId);
    yield put(saveTicketSources(validTicketSources));
  } finally {
    yield put(stopLoading());
  }
}

function* handleGetPlugins() {
  yield put(startLoading());
  try {
    const { data } = yield call(getPlugins);
    yield put.resolve(savePlugins(data));
    yield handleGetConfigs();
  } finally {
    yield put(stopLoading());
  }
}

function* handleSubmitNewPlugin() {
  const {
    plugin: { files, isOverride }
  } = yield select();
  const payload = new FormData();
  payload.append("file", files[0]);
  const reqConfig = {
    params: { override: isOverride }
  };

  try {
    const { data } = yield call(submitNewPlugin, payload, reqConfig);
    yield put(openSnackbar("Successfully Uploaded the Plugin. "));
    yield put(closeNewPlugin());
  } catch (err) {}
}

function* handleGetConfig({ id }) {
  yield put(getConfigStartLoading(id));

  try {
    const { data } = yield call(getTicketConfig, id);
    yield put(saveConfig({ id, data }));
    const {
      plugin: { currentPluginId }
    } = yield select();
    if (currentPluginId === id) {
      yield put(openPluginData(id));
    }
  } finally {
    yield put(getConfigStopLoading(id));
  }
}

function* handleGetConfigs() {
  const {
    plugin: {
      plugins: { allIds: pluginIds }
    }
  } = yield select();
  yield all(pluginIds.map(id => call(handleGetConfig, { id })));
}

function* handleSubmitTicketSource({ ticketType, idx }) {
  const {
    plugin: {
      currentPlugin: { ticketSources }
    }
  } = yield select();
  const { polling } = ticketSources[idx];
  const payload = convertDate(polling);

  try {
    const { data } = yield call(submitTicketSource, ticketType, payload);
    yield put(submitTicketSourceSuccess(idx));
    yield put(openSnackbar("Successfully Saved Ticket Source."));
  } catch (err) {}
}

function convertDate(polling) {
  return {
    enabled: polling.enabled,
    lastPollingDate: moment(polling.lastPollingDate).format(
      "YYYY-MM-DD[T]hh:mm:ss[Z]"
    )
  };
}

function* handleUpdateActivation({ id }) {
  const {
    plugin: {
      plugins: { byId }
    }
  } = yield select();

  const activated = !byId[id].activated;
  const confValid = null;
  const fields = [];
  const payload = { confValid, activated, fields };

  try {
    const { data } = yield call(updateActivation, id, payload);
    // Response can still have errors property, despite being a 2xx response
    if (!data.hasOwnProperty("errors") || data.errors.length === 0) {
      yield put(updateActivationSuccess(id));
      yield put(openSnackbar(`Successfully Updated the Configuration`));
    } else {
      const { errors } = data;
      yield put(
        openSnackbar((errors[0] && errors[0].errorMsg) || "Unknown Error")
      );
    }
  } catch (err) {}
}

function* handleSubmitConfig({ id }) {
  const {
    plugin: {
      currentPlugin: {
        configs: { pollingEnabled, activated, fields }
      }
    }
  } = yield select();

  const payload = { pollingEnabled, activated, fields };

  try {
    const { data } = yield call(submitTicketConfig, id, payload);
    // Response can still have errors property, despite being a 2xx response
    if (!data.hasOwnProperty("errors") || data.errors.length === 0) {
      yield put(openSnackbar(`Successfully Updated the Configuration`));
      yield put(getConfig(id));
    } else {
      const { errors } = data;
      yield put(openSnackbar(`Error: ${data.errors}`));
      yield put(submitConfigFail({ id, errors }));
      yield put(
        openSnackbar((errors[0] && errors[0].errorMsg) || "Unknown Error")
      );
    }
  } catch (err) {}
}

function* handleGetHealth() {
  yield put(getHealthStartLoading());
  try {
    const { data } = yield call(getPluginHealth);
    yield put.resolve(saveHealth(data));
  } finally {
    yield put(getHealthStopLoading());
  }
}
