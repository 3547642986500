/**
 * Created by x1 on 8/06/17.
 */
/**
 * Created by rayemagp on 2/06/17.
 */
import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import IconButton from "material-ui/IconButton";
import NavigationClose from "material-ui/svg-icons/navigation/close";

class SecondaryNavigationClose extends React.Component {
  render() {
    return (
      <IconButton {...this.props}>
        <NavigationClose color={themeMainUI.palette.accent3Color} />
        {this.props.children}
      </IconButton>
    );
  }
}

export default SecondaryNavigationClose;
