import React from "react";
import PropTypes from "prop-types";
import { axiosInstance as axios } from "../../utils/axios";

import Autosuggest from "react-autosuggest";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";

import config from "../../config";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { isHighlighted }, onClick) {
  return (
    <MenuItem
      component="div"
      selected={isHighlighted}
      onClick={() => onClick(suggestion.value)}
    >
      <div>{suggestion.text}</div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.value;
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    fontSize: 16
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    overflow: "scroll",
    maxHeight: "400px",
    height: "auto",
    boxShadow:
      "0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)"
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    backgroundColor: "#ffffff",
    display: "block",
    gridAutoRows: "min-content",
    width: "fit-content",
    minWidth: "100%"
  }
});

class IntegrationAutosuggest extends React.Component {
  constructor() {
    super();
    this.state = {
      searchText: "",
      dataSource: [],
      tickets: []
    };
    this.timer = null;
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(
      this
    );
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(
      this
    );
    this.debounceHandleSuggestionFetchRequested = this.debounceHandleSuggestionFetchRequested.bind(
      this
    );
    this.handleClickSuggestion = this.handleClickSuggestion.bind(this);
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 0) {
      this.setState(
        {
          dataSource: []
        },
        () => {
          const url =
            config.urls.base +
            config.urls.apis["ticket-management"] +
            "/tickets/search";

          const params = {
            value
          };

          axios
            .get(url, { params })
            .then(res => {
              this.setState({
                tickets: res.data,
                dataSource: res.data.map(i => {
                  return {
                    text:
                      i.coreData.number +
                      (i.coreData.shortDescription &&
                        "  -  " + i.coreData.shortDescription),
                    value: i
                  };
                })
              });
            })
            .catch(err => {
              this.setState({ dataSource: [] });
            });
        }
      );
    }
  };

  debounceHandleSuggestionFetchRequested = ({ value }) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.handleSuggestionsFetchRequested({ value });
    }, 500);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      dataSource: [],
      tickets: [],
      searchText: ""
    });
  };

  handleChange = name => (event, { newValue, method }) => {
    if (["type", "click"].includes(method)) {
      this.setState({
        [name]: newValue
      });
    } else if (method === "enter") {
      this.handleClickSuggestion(newValue);
    }
  };

  handleClickSuggestion = ticket => {
    if (ticket) {
      this.props.openDetails(ticket);
    }
  };

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.dataSource,
      onSuggestionsFetchRequested: this.debounceHandleSuggestionFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion: (suggestion, { isHighlighted }) =>
        renderSuggestion(
          suggestion,
          { isHighlighted },
          this.handleClickSuggestion
        ),
      highlightFirstSuggestion: true
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: "Search...",
            value: this.state.searchText,
            onChange: this.handleChange("searchText")
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(IntegrationAutosuggest);
