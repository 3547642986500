import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  min-height: 160px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #e3f0fc;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;

  @media screen and (max-width: 992px) {
    padding: 5px 10px;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 7px 10px;
  }

  @media screen and (max-height: 768px) {
    padding: 5px 10px;
  }
`;

export const TitleText = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  width: 50%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 992px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 1.1rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 1rem;
  }
`;

export const BodyContainer = styled.div`
  padding: 10px;
  height: calc(100% - 50px);
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;
