import React from "react";
import Dropzone from "react-dropzone";

import * as _ from "lodash";
import moment from "moment";

import SecondaryTextField from "../templates/SecondaryTextField";

import styles from "./TicketDetailsDrawerStyles";

import { Row, Col } from "react-flexbox-grid/lib";

// material-ui
import IconButton from "material-ui/IconButton";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import FontIcon from "material-ui/FontIcon";
import { Divider, Paper } from "material-ui";
import SecondaryBorderedButton from "../templates/SecondaryBorderedButton";

export default class DatasourceDrawer extends React.Component {
  render() {
    return (
      <div style={styles.mainWrapper}>
        <div style={styles.contentWrapper}>
          {this.props.isCreateDataSource && (
            <div style={styles.contentHeader}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "flex-start"
                }}
              >
                <Col xs={10}>
                  <h1 style={styles.ticketNumber}>New Data Source</h1>
                </Col>
                <Col xs={2}>
                  <IconButton onClick={() => this.props.handleCloseDetails()}>
                    <NavigationClose />
                  </IconButton>
                </Col>
              </Row>

              <div style={styles.formContainer}>
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px"
                  }}
                >
                  Name
                </Col>
                <Col xs={10}>
                  <SecondaryTextField
                    id="name"
                    value={this.props.dataSource.sourceName}
                    onChange={(e, newValue) => {
                      this.props.handleInputOnChange({
                        field: "sourceName",
                        value: newValue
                      });
                    }}
                    fullWidth
                    floatingLabelFixed
                  />
                </Col>
              </div>

              <div style={styles.formContainer}>
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px"
                  }}
                >
                  Description
                </Col>
                <Col xs={10}>
                  <SecondaryTextField
                    id="description"
                    value={this.props.dataSource.description}
                    onChange={(e, newValue) => {
                      this.props.handleInputOnChange({
                        field: "description",
                        value: newValue
                      });
                    }}
                    fullWidth
                    floatingLabelFixed
                  />
                </Col>
              </div>

              {!_.isEmpty(this.props.files) ? (
                <div style={styles.formContainer}>
                  <Col
                    xs={2}
                    style={{
                      padding: "0",
                      marginTop: "17px",
                      fontSize: "12px"
                    }}
                  >
                    URL
                  </Col>
                  <Col xs={10}>
                    {this.props.files.map((item, i) => (
                      <span key={i}>
                        {item.name}
                        <IconButton
                          onClick={this.props.replaceSelectedFile}
                          tooltip="Replace"
                          tooltipPosition="top-center"
                          iconStyle={{ fontSize: 18 }}
                        >
                          <FontIcon className="fa fa-close" />
                        </IconButton>
                      </span>
                    ))}
                  </Col>
                </div>
              ) : (
                <div
                  style={Object.assign({}, styles.formContainer, {
                    flexGrow: 1
                  })}
                >
                  <Col
                    xs={12}
                    style={{
                      padding: "0",
                      marginTop: "15px"
                    }}
                  >
                    <Dropzone
                      style={{
                        marginTop: 15,
                        paddingTop: 20,
                        paddingBottom: 10,
                        borderWidth: 2,
                        borderColor: "#92C2E0",
                        borderStyle: "dashed",
                        borderRadius: 5,
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        color: "#2389C5",
                        backgroundColor: "#F9FDFF",
                        alignItems: "center"
                      }}
                      onDrop={this.props.handleFileDrop}
                    >
                      <FontIcon
                        style={{ fontSize: 40, color: "inherit" }}
                        className="fa fa-upload"
                      />
                      <p>Drag and drop your JSON to upload</p>
                      <p>or</p>
                      <p>
                        <strong>Click to select file</strong>
                      </p>
                    </Dropzone>
                  </Col>
                </div>
              )}
            </div>
          )}

          {/* BELOW: Read an existing Data source */}

          {!this.props.isCreateDataSource && !_.isEmpty(this.props.dataSource) && (
            <div style={styles.contentHeader}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "flex-start"
                }}
              >
                <Col xs={10}>
                  <h1 style={styles.ticketNumber}>
                    {this.props.dataSource.sourceName}
                  </h1>
                </Col>
                <Col
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <IconButton onClick={this.props.handleCloseDetails}>
                    <NavigationClose />
                  </IconButton>
                </Col>
              </Row>

              <div style={styles.formContainer}>
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px"
                  }}
                >
                  Upload Date
                </Col>
                <Col xs={10}>
                  <SecondaryTextField
                    value={moment
                      .unix(this.props.dataSource.uploadDate / 1000)
                      .format("MMM Do YYYY, hh:mm:ss a")}
                    fullWidth
                    floatingLabelFixed
                  />
                </Col>
              </div>

              <div style={styles.formContainer}>
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px"
                  }}
                >
                  Description
                </Col>
                <Col xs={10}>
                  <SecondaryTextField
                    value={this.props.dataSource.description}
                    fullWidth
                    floatingLabelFixed
                  />
                </Col>
              </div>

              <div style={styles.formContainer}>
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px"
                  }}
                >
                  Number of tickets
                </Col>
                <Col xs={10}>
                  <SecondaryTextField
                    value={this.props.dataSource.numberOfTickets}
                    fullWidth
                    floatingLabelFixed
                  />
                </Col>
              </div>

              <div style={styles.formContainer}>
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px"
                  }}
                >
                  User Name
                </Col>
                <Col xs={10}>
                  <SecondaryTextField
                    value={this.props.dataSource.userName}
                    fullWidth
                    floatingLabelFixed
                  />
                </Col>
              </div>

              <div style={styles.formContainer}>
                <Col
                  xs={2}
                  style={{
                    padding: "0",
                    marginTop: "15px",
                    fontSize: "12px"
                  }}
                >
                  File Name
                </Col>
                <Col xs={10}>
                  <SecondaryTextField
                    value={this.props.dataSource.fileName}
                    fullWidth
                    floatingLabelFixed
                  />
                </Col>
              </div>
            </div>
          )}
        </div>

        <Divider />

        <Paper style={styles.contentActionsContainer}>
          <div style={styles.contentActions}>
            <div>
              <SecondaryBorderedButton
                onClick={() => {
                  this.props.didCreateNewDataSource();
                }}
                label="Create Datasource"
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}
