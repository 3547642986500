import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import SecondaryButton from "../templates/SecondaryButton";
import styled from "styled-components";
import BulkActionModel from "../../models/BulkActionModel";
import BulkActionDetails from "../page/BulkActions/BulkActionList/BulkAction/BulkActionDetails";

const MainContainer = styled.div`
  width: 25em;
  padding: 15px;
`;

const HeaderContainer = styled(Grid)`
  margin-bottom: 10px;
`;

const HeadingText = styled(Typography)`
  font-size: 14px;
`;

const StyledOpenInNewIcon = styled(OpenInNew)`
  cursor: pointer;
`;

const StyledDivider = styled(Divider)`
  width: calc(100% + 20px);
  position: fixed;
  left: -2px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  width: 100%;
`;

const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: center;
  padding: 0px !important;
`;

const StyledBulkActionDetails = styled(BulkActionDetails)`
  padding: 0px;
`;

const BulkActionDropdownMenu = props => {
  const { bulkActionsList, handleMenuClick, getBulkActionTasks } = props;

  const firstFourActions = bulkActionsList ? bulkActionsList.slice(0, 4) : [];

  useEffect(() => {
    getBulkActionTasks();
  }, [getBulkActionTasks]);

  return (
    <MainContainer>
      <HeaderContainer container>
        <Grid item container alignItems="flex-end" justify="flex-start" xs>
          <HeadingText>Bulk Actions</HeadingText>
        </Grid>
        <Grid item container justify="flex-end" xs>
          <StyledOpenInNewIcon
            onClick={() => handleMenuClick("/atr/bulk-actions")}
          />
        </Grid>
      </HeaderContainer>
      <StyledDivider />
      <List>
        {firstFourActions.length > 0 ? (
          firstFourActions.map(item => (
            <StyledBulkActionDetails
              key={item.taskId}
              bulkAction={item}
              hasStatus
            />
          ))
        ) : (
          <StyledListItem>
            <ListItemText>No bulk actions</ListItemText>
          </StyledListItem>
        )}
        {firstFourActions.length > 0 && (
          <StyledListItem>
            <StyledSecondaryButton
              label="View All"
              secondary
              onClick={() => handleMenuClick("/atr/bulk-actions")}
            />
          </StyledListItem>
        )}
      </List>
    </MainContainer>
  );
};

BulkActionDropdownMenu.propTypes = {
  bulkActionsList: PropTypes.arrayOf(BulkActionModel),
  handleMenuClick: PropTypes.func,
  getBulkActionTasks: PropTypes.func
};

export default BulkActionDropdownMenu;
