export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const SIGNUP_FAILURE_WITH_ERROR = "SIGNUP_FAILURE_WITH_ERROR";

export const CLOSE_AUTH_SNACKBAR = "CLOSE_AUTH_SNACKBAR";
export const REASSIGN_UUID = "REASSIGN_UUID";

export const USER_RESET_PASSWORD_PENDING = "USER_RESET_PASSWORD_PENDING";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_ERROR = "USER_RESET_PASSWORD_ERROR";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const DONE_REFRESHING_TOKEN = "DONE_REFRESHING_TOKEN";
export const SESSION_VALID = "SESSION_VALID";
export const SESSION_INVALID = "SESSION_INVALID";
