import React from "react";
import PropTypes from "prop-types";
import { ItemContainer, MainContainer, PageTitle } from "./styles";
import TabbedSteps from "./TabbedSteps";

const Header = props => {
  const { steps, curStep, handleChange } = props;
  return (
    <MainContainer>
      <ItemContainer style={{ justifyContent: "flex-start", width: "85%" }}>
        <PageTitle>DIY Ticket Automation</PageTitle>
        <TabbedSteps
          handleChange={handleChange}
          curStep={curStep}
          steps={steps}
        />
      </ItemContainer>
      <ItemContainer
        style={{ justifyContent: "flex-end", width: "15%" }}
      ></ItemContainer>
    </MainContainer>
  );
};

Header.propTypes = {
  steps: PropTypes.array.isRequired,
  curStep: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default Header;
