import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

interface Prediction {
  label: string;
  prob: number;
}

interface TopologyNodeProps {
  prediction: Prediction;
}

const TopologyNode: React.FC<TopologyNodeProps> = React.memo(
  ({ prediction }) => {
    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ padding: "0px 10px" }}
      >
        <Grid item xs={8}>
          <Typography
            variant="body2"
            style={{ fontSize: 10, lineHeight: 2, wordBreak: "break-all" }}
          >
            {prediction.label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" style={{ fontSize: 10 }}>
            {(100 * prediction.prob).toFixed(2)}%
          </Typography>
        </Grid>
      </Grid>
    );
  }
);

export default TopologyNode;
