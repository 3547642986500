import TicketTable from "./TicketTable";
import { connect } from "react-redux";
import { getIsCustomized } from "redux/reselect/ticket";

const mapStateToProps = state => {
  return {
    tickets: state.tickets.tickets,
    cacheObject: state.tickets.cacheObject,
    cacheLocalFinal: state.tickets.cacheLocalFinal,
    isCustomizationTableOpen: state.tickets.isCustomizationTableOpen,
    isFetchingTicketFields: state.ticketField.isFetchingTicketFields,
    isFetchingUserData: state.ticketField.isFetchingUserData,
    isCustomized: getIsCustomized(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketTable);
