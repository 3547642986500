import React from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const HeaderColumn = styled(Grid)`
  font-weight: bold !important;
`;

export const BodyColumn = styled(Grid)`
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  font-size: 15px !important;
  word-break: break-all !important;
`;

export const Dot = styled(({ isConnected, ...rest }) => <div {...rest} />)`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props => (props.isConnected ? "#00CC83" : "#E13232")};
  margin: 0px 8px;
`;

export const HeaderText = styled.div`
  font-weight: 700;
  font-size: 1.1rem;

  @media screen and (max-height: 768px) {
    font-size: 0.8rem;
  }

  @media screen and (max-width: 992px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 1rem;
  }
`;

export const TicketTypeText = styled.div`
  color: #9d9d9d;
  font-size: 0.9rem;

  @media screen and (max-width: 992px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 0.8rem;
  }

  @media screen and (max-height: 768px) {
    font-size: 0.7rem;
  }
`;

export const FilterText = styled.div`
  font-size: 0.9rem;

  @media screen and (max-width: 992px) {
    font-size: 0.7rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 0.8rem;
  }

  @media screen and (max-height: 768px) {
    height: calc(100% - 100px);
  }
`;

export const FilterContainer = styled(Grid)`
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 150px);

  @media screen and (max-width: 992px) {
    height: calc(100% - 100px);
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    height: calc(100% - 125px);
  }

  @media screen and (max-height: 768px) {
    height: calc(100% - 100px);
  }
`;
