import React from "react";

import Table from "@material-ui/core/Table";
import CircularProgress from "@material-ui/core/CircularProgress";
import TicketTableHeaderCTN from "./Header/HeaderCTN";
import TicketTableBodyCTN from "./Body/BodyCTN";
import TicketTableNoTicket from "./NoTicket/NoTicket";
import TicketTablePagination from "./Pagination/PaginationCTN";
import TicketTableCustomizationCTN from "./Customization/CustomizationCTN";
import { Grid } from "@material-ui/core";

const TicketTable = props => {
  const {
    tickets,
    cacheLocalFinal,
    cacheObject,
    isCustomizationTableOpen,
    isFetchingTicketFields,
    isCustomized
  } = props;

  const showTickets = tickets && tickets.length > 0;
  const showPagination = cacheLocalFinal > 1 && cacheObject;
  return (
    <div style={{ width: "100%" }}>
      <Grid container justify="center">
        {isCustomized && isFetchingTicketFields ? (
          <div style={{ flex: 1, marginTop: 100, textAlign: "center" }}>
            <CircularProgress size={128} />
          </div>
        ) : (
          <div style={{ flex: 1, overflow: "auto" }}>
            <Table>
              <TicketTableHeaderCTN />
              {showTickets && <TicketTableBodyCTN />}
            </Table>
            {!showTickets && <TicketTableNoTicket />}
          </div>
        )}

        {isCustomizationTableOpen && (
          <div item style={{ width: "450px" }}>
            <TicketTableCustomizationCTN />
          </div>
        )}
      </Grid>
      {showPagination && <TicketTablePagination />}
    </div>
  );
};

export default TicketTable;
