import {
  OPEN_RULE,
  CLOSE_RULE,
  OPEN_RULES_SUCCESS,
  RULE_CHANGE_PAGE
} from "../actions/rules";

function rules(
  state = { ruleSelected: {}, rules: {}, currentPage: 0 },
  action
) {
  switch (action.type) {
    case OPEN_RULE: {
      return {
        ...state,
        ruleSelected: action.ruleSelected,
        rules: state.rules
      };
    }
    case CLOSE_RULE: {
      return {
        ...state,
        ruleSelected: {},
        rules: state.rules
      };
    }

    case OPEN_RULES_SUCCESS: {
      return {
        ...state,
        ruleSelected: state.ruleSelected,
        rules: action.rules,
        lastPage: action.lastPage
      };
    }

    case RULE_CHANGE_PAGE: {
      return { ...state, currentPage: action.currentPage };
    }

    // case OPEN_RULE_SUCCESS: {
    //   return {
    //     ...state,
    //     isFetchingMappings: false,
    //     rules: action.rules,
    //     lastPage: action.lastPage
    //   };
    // }

    default:
      return state;
  }
}

export default rules;
