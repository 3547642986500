import { connect } from "react-redux";
import TicketTableCustomization from "./Customization";
import { toggleCustomizationTable, setColumnFilter } from "reducers/tickets";
import {
  changeUserData,
  toggleCheckBox,
  setColumnDefault,
  onSortEnd,
  resetToDefaultColumns
} from "redux/modules/userData";
import { getCustomizedTicketField } from "redux/reselect/ticket";
import { openSnackbar } from "actions/snackbar";

const mapStateToProps = state => ({
  isFetchingTicketFields: state.ticketField.isFetchingTicketFields,
  selectedTicketType: state.tickets.selectedTicketType,
  columnFilter: state.tickets.columnFilter,
  selectedUserData: pushCustomizationFieldsToUserData(state),
  customizedTemplateColumn: getCustomizedTicketField(state)
});

function pushCustomizationFieldsToUserData(state) {
  const updatedDataFromUser =
    state.userData.updatedUserData.ticketTableColumns[
      state.tickets.selectedTicketType
    ];
  if (updatedDataFromUser === undefined || updatedDataFromUser.length === 0) {
    let customTemplate = [];
    const customizedTemplateColumn = getCustomizedTicketField(state);
    for (let index = 0; index < customizedTemplateColumn.length; index++) {
      customTemplate.push(customizedTemplateColumn[index].name);
    }
    state.userData.updatedUserData.ticketTableColumns = {
      ...state.userData.updatedUserData.ticketTableColumns,
      [state.tickets.selectedTicketType]: customTemplate
    };
    if (customTemplate.length > 0) return customizedTemplateColumn;
    else return "";
  } else {
    return updatedDataFromUser;
  }
}
const mapDispatchToProps = dispatch => ({
  onSortEnd: (oldIndex, newIndex, selectedTicketType) =>
    dispatch(onSortEnd(oldIndex, newIndex, selectedTicketType)),
  toggleCustomizationTable: () => dispatch(toggleCustomizationTable()),
  toggleCheckBox: (type, item, value) =>
    dispatch(toggleCheckBox(type, item, value)),
  setColumnFilter: columnFilter => dispatch(setColumnFilter(columnFilter)),
  setColumnDefault: selectedTicketType =>
    dispatch(setColumnDefault(selectedTicketType)),
  changeUserData: () => dispatch(changeUserData()),
  openSnackbar: message => dispatch(openSnackbar(message)),
  resetToDefaultColumns: (type, item, value) =>
    dispatch(resetToDefaultColumns(type, item, value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTableCustomization);
