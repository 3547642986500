import axios from "axios";
import { openSnackbar } from "../actions/snackbar";

const createAxiosInstance = () =>
  axios.create({
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    timeout: 100000,
    paramsSerializer: params => {
      let queryString = "";
      Object.keys(params).forEach(key => {
        queryString += `${key}=${encodeURIComponent(params[key])}&`;
      });
      // Ignore last &
      return queryString.substr(0, queryString.length - 1);
    }
  });

export const axiosInstance = createAxiosInstance();
export const axiosInstanceSnackbar = createAxiosInstance();

export const configureAxios = (axiosInstance, store, enableErrorSnackbar) => {
  axiosInstance.interceptors.request.use(config => {
    config.headers.apiToken = store.getState().auth.token.token;
    return config;
  });

  if (enableErrorSnackbar) {
    axiosInstance.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          store.dispatch(
            openSnackbar(
              `${error.response.data.errorMessage ||
                error.response.data.error ||
                error.response.statusText}`
            )
          );
        } else {
          store.dispatch(openSnackbar(`${error.message}`));
        }
        return Promise.reject(error);
      }
    );
  }
};
