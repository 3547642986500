import React from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { MainContainer, TitleText } from "./styles";
import UseCaseCategoryFilter from "./UseCaseCategoryFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const UseCaseFilter = props => {
  const {
    workflowCategories,
    workflowFilters,
    toggleFilter,
    clearFilter
  } = props;
  return (
    <MainContainer container spacing={8}>
      <Grid container item xs={12} alignItems="center" justify="space-between">
        <TitleText>Filter by category</TitleText>
        <Button
          variant="outlined"
          size="small"
          onClick={clearFilter}
          disabled={workflowFilters.length === 0}
        >
          <span>
            <FontAwesomeIcon icon={faFilter} /> Clear Filters
          </span>
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {Object.entries(workflowCategories).map(([name, data]) => (
          <UseCaseCategoryFilter
            key={name}
            name={name}
            selected={workflowFilters.includes(name)}
            numUseCases={data.workflows.length}
            numSelected={data.numSelected}
            toggleFilter={toggleFilter}
          />
        ))}
      </Grid>
    </MainContainer>
  );
};

UseCaseFilter.propTypes = {
  workflowCategories: PropTypes.object.isRequired,
  workflowFilters: PropTypes.array.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired
};

export default UseCaseFilter;
