import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { toggleCheckBox } from "redux/modules/userData";
import TicketTemplateViewerTemplateFieldsCheckbox from "./Checkbox";

const mapStateToProps = (state, ownProps) => {
  const selectedTicketType = state.tickets.selectedTicketType;
  const ticketTableColumns = cloneDeep(
    state.userData.updatedUserData.ticketTableColumns
  );

  const isChecked = ticketTableColumns
    ? ticketTableColumns[selectedTicketType]
      ? ticketTableColumns[selectedTicketType].includes(ownProps.item.name)
      : false
    : false;

  return {
    selectedTicketType: selectedTicketType,
    isChecked: isChecked
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCheckBox: (type, item, value) =>
      dispatch(toggleCheckBox(type, item, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTemplateViewerTemplateFieldsCheckbox);
