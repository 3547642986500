import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media screen and (max-height: 768px) {
    margin-bottom: 2px;
  }

  @media screen and (min-height: 769px) and (max-height: 850px) {
    margin-bottom: 10px;
  }
`;

const StyledTextField = styled(TextField)`
  box-sizing: border-box;
  margin-top: 5px;

  .atr-input {
    padding: ${props =>
      props.multiline ? "10px 10px !important" : "5px 10px !important"};
    border: 0.5px solid #c0c0c0 !important;
    border-radius: 3px !important;
    background: #fdfdfd;
    font-size: 13px;
  }
  width: 100%;
`;

const InputField = props => {
  const { label, onChange, ...rest } = props;

  const handleChange = e => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Container>
      <Typography style={{ fontWeight: 600 }}>{label}</Typography>
      <StyledTextField
        onChange={handleChange}
        InputProps={{ disableUnderline: true, className: "atr-input" }}
        {...rest}
      />
    </Container>
  );
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default InputField;
