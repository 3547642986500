import { connect } from "react-redux";
import Gamification from "../../components/gamification/Gamification";

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({});

const GamificationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Gamification);

export default GamificationPage;
