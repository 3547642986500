import TicketTableBody from "./Body";
import { connect } from "react-redux";

import { clearMappingJobs, clearRundeckProjects } from "actions/mapping";
import { closeDetailsTicket, openDetails } from "actions/details";
import { getCustomizedTicketField } from "redux/reselect/ticket";
import { updateBulkActionTickets } from "actions/bulkActions";

const mapStateToProps = state => {
  return {
    tickets: state.tickets.tickets,
    ticket: state.details.ticketNumber,
    isDetailsOpen: state.details.isOpen,
    customizedTemplateColumn: getCustomizedTicketField(state),
    selectedTickets: state.bulkActions.bulkActionPayload.selectedTickets,
    selectedTicketType: state.tickets.selectedTicketType,
    activeTemplates: state.mapping.activeTemplates,
    timezone: state.user.user.timezone
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearMappingJobs: () => dispatch(clearMappingJobs()),
    clearRundeckProjects: () => dispatch(clearRundeckProjects()),
    openDetails: ticketObj => dispatch(openDetails(ticketObj)),
    // openDetails: (ticketNumber) => dispatch(openDetails(ticketNumber)),
    closeDetailsTicket: ticketNumber =>
      dispatch(closeDetailsTicket(ticketNumber)),

    updateBulkActionTickets: selectedTickets =>
      dispatch(updateBulkActionTickets(selectedTickets))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketTableBody);
