import styled from "styled-components";
import { Checkbox } from "@material-ui/core";

export const CheckboxContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  background-color: #ebf7ff;
  border: 1px solid #378bee;
  padding: 1px 0px;
  cursor: pointer;

  @media screen and (max-height: 700px) {
    font-size: 0.8rem;
  }

  @media screen and (min-height: 700px) and (max-height: 800px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 992px) {
    font-size: 0.8rem;
  }

  @media screen and (min-width: 993px) and (max-width: 1400px) {
    font-size: 0.9rem;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: #0085ff !important;
`;
