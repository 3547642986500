import React from "react";
import themeMainUI from "../../styles/themeMainUI";
import TextField from "material-ui/TextField";

class PrimaryTextField extends React.Component {
  render() {
    return (
      <TextField
        floatingLabelStyle={{ color: themeMainUI.palette.textColor }}
        underlineFocusStyle={{ borderColor: "black" }}
        {...this.props}
      />
    );
  }
}

export default PrimaryTextField;
