import * as React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { default as NewIconButton } from "@material-ui/core/IconButton";
//@ts-ignore
import IconButton from "material-ui/IconButton";
//@ts-ignore
import { ContentAdd } from "material-ui/svg-icons";
import WeightingSlider from "./WeightingSlider";
import { Done, Clear } from "@material-ui/icons";
import styled from "styled-components";
import themeMainUI from "../../../styles/themeMainUI";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "@material-ui/core/Tooltip";

interface MLModel {
  name: string;
  modelId: string;
  algorithm: string;
  treeLevels: string[] | null;
  features: any[] | null;
  createConfig: {
    continuous: boolean;
    description: string;
    task: string;
  };
  trainConfig: { name: string; type: string; quality: any; examples: any }[];
}

interface MLConfig {
  algorithm: string | undefined;
  field: string;
  modelId: string | undefined;
  ticketType: string;
  continuous: boolean | undefined;
  mlModelStatus: string;
  mlmodelName: string;
  threshold: null | number;
  automaticMLField: boolean;
  weighting: number;
  performance: number | null;
  endpointDetails: any;
  treeLevels: string[] | null;
  features: string[] | null;
}

interface Service {
  list: MLConfig[] | undefined;
}

interface onDeleteSecondParam {
  field: string;
  type: string;
}

interface SecondaryFields {
  coreData: boolean;
  defaultValue: string;
  description: null | string;
  displayName: string;
  displayOrder: null | string;
  editable: boolean;
  groupId: null | string;
  mandatory: boolean;
  name: string;
  type: string;
  values: [];
}

export interface MLTableProps {
  service: Service | undefined;
  ticketTypes: any | undefined;
  mlModels: MLModel[] | undefined;
  onSave: (service: Service, mlModel: MLConfig) => void;
  onDelete: (service: Service, param: onDeleteSecondParam) => void;
}

const StyledDone = styled(Done)`
  && {
    color: green;
  }
`;

const StyledClear = styled(Clear)`
  && {
    color: red;
  }
`;

const StyledContainer = styled.div`
  min-width: 40vh;
  max-height: 90vh;
  overflow-x: auto;
  overflow-y: hidden;
`;

// show proper display name for a given field instead of name from raw json
export const renderFieldName = (
  field: string | undefined,
  ticketType: string,
  ticketTypes: any | undefined
): string => {
  let result;
  const list: SecondaryFields[] = ticketTypes[ticketType]?.secondaryFields;
  if (field && list) {
    result = list.find((element: SecondaryFields) => element.name === field)
      ?.displayName;
    if (!result) {
      result = field;
    }
  }
  switch (result) {
    case "u_knowledge":
      result = "Knowledge Base ID";
      break;
    case "ticket_management":
      result = "Ticket Management Service";
      break;
    case "gtr":
      result = "GTR";
      break;
    default:
      break;
  }
  return result || "TREE MODEL";
};

export const MLTable: React.FC<MLTableProps> = React.memo(
  ({ service, ticketTypes, mlModels, onSave, onDelete }) => {
    const [newLine, setNewLine] = React.useState<MLConfig | undefined>(
      undefined
    );

    const getAlgorithm = React.useCallback(
      (mlModels: MLModel[] | undefined) => {
        return mlModels?.find(mlModel => mlModel.modelId === newLine?.modelId)
          ?.algorithm;
      },
      [newLine]
    );

    return (
      <>
        <h2 style={{ color: "#5A819E", paddingLeft: "9px" }}>
          Machine Learning
          <IconButton
            style={{
              marginRight: "30px",
              backgroundColor: "#396686",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              padding: "3px",
              margin: "10px",
              top: "4px"
            }}
            className={"add-" + 1}
            hoveredStyle={{
              backgroundColor: "#4c91b4"
            }}
            iconStyle={{
              color: "white"
            }}
            onClick={() =>
              setNewLine({
                algorithm: "",
                field: "",
                modelId: "",
                ticketType: "",
                continuous: false,
                mlModelStatus: "",
                mlmodelName: "",
                threshold: null,
                automaticMLField: false,
                weighting: 5,
                endpointDetails: null,
                performance: null,
                treeLevels: null,
                features: null
              })
            }
          >
            <ContentAdd />
          </IconButton>
        </h2>
        <StyledContainer>
          <Table style={{ marginRight: "100px" }}>
            <TableHead style={{ backgroundColor: "rgb(151, 201, 209)" }}>
              <TableRow style={{ overflow: "auto" }}>
                <TableCell>
                  <Typography style={{ color: "white" }}>
                    Ticket Type
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography style={{ color: "white" }}>Model Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ color: "white" }}>Model ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ color: "white" }}>Field Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ color: "white" }}>
                    Model Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ color: "white" }}>
                    Continuous Learning
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography style={{ color: "white" }}>Weighting</Typography>
                </TableCell>
                <TableCell style={{ color: "white" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {service?.list?.map(mlTableRow => {
                return (
                  <TableRow
                    key={uuidv4()}
                    data-testid="mltable-test"
                    style={
                      mlTableRow.mlModelStatus !== "online"
                        ? {
                            backgroundColor: themeMainUI.palette?.disabledColor
                          }
                        : {}
                    }
                  >
                    <TableCell>{mlTableRow.ticketType}</TableCell>
                    <TableCell>{mlTableRow.mlmodelName}</TableCell>
                    <TableCell>{mlTableRow.modelId}</TableCell>
                    <TableCell>
                      {renderFieldName(
                        mlTableRow.field,
                        mlTableRow.ticketType,
                        ticketTypes
                      )}
                    </TableCell>
                    {mlTableRow.mlModelStatus !== "online" ? (
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              Check IngrAIn for more details
                            </Typography>
                          </React.Fragment>
                        }
                        placement="top-start"
                      >
                        <TableCell>{mlTableRow.mlModelStatus}</TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell>{mlTableRow.mlModelStatus}</TableCell>
                    )}
                    <TableCell>
                      {mlTableRow.continuous ? <StyledDone /> : <StyledClear />}
                    </TableCell>
                    <TableCell>
                      <WeightingSlider
                        isDisabled={true}
                        weighting={mlTableRow.weighting}
                        onSliderChange={() => {}}
                      />
                    </TableCell>
                    <TableCell>
                      <NewIconButton
                        onClick={() =>
                          onDelete(service, {
                            field: mlTableRow.field,
                            type: mlTableRow.ticketType
                          })
                        }
                      >
                        <DeleteIcon />
                      </NewIconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {newLine && (
                <TableRow>
                  <TableCell>
                    <Select
                      value={newLine.ticketType}
                      onChange={event =>
                        setNewLine({
                          ...newLine,
                          ticketType: event.target.value
                        })
                      }
                    >
                      {ticketTypes &&
                        Object.keys(ticketTypes).map(ticketTypeKey => {
                          return (
                            <MenuItem
                              value={ticketTypes[ticketTypeKey].ticketType}
                            >
                              {ticketTypes[ticketTypeKey].ticketType}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      disabled={!newLine.ticketType}
                      value={newLine.mlmodelName}
                      onChange={event => {
                        const model = mlModels?.find(
                          mlModel => mlModel.name === event.target.value
                        );
                        const features = model?.features?.map(
                          feature => feature.name
                        );

                        const treeLevels = model?.treeLevels?.filter(
                          treeLevel => {
                            return treeLevel.indexOf(",") !== -1
                              ? null
                              : !features?.includes(treeLevel);
                          }
                        );

                        setNewLine({
                          ...newLine,
                          continuous: model?.createConfig?.continuous,
                          mlmodelName: event.target.value,
                          algorithm: model?.algorithm,
                          modelId: model?.modelId,
                          treeLevels: treeLevels ?? null,
                          features: features ?? null
                        });
                      }}
                    >
                      {mlModels?.map(mlModel => {
                        return (
                          <MenuItem value={mlModel.name}>
                            {mlModel.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell>{newLine.modelId}</TableCell>
                  <TableCell>
                    {getAlgorithm(mlModels) === "ktree" ||
                    getAlgorithm(mlModels) === "KTREE" ? (
                      "TREE MODEL"
                    ) : (
                      <Select
                        disabled={
                          getAlgorithm(mlModels) === "ktree" ||
                          getAlgorithm(mlModels) === "KTREE" ||
                          !newLine.mlmodelName ||
                          !newLine.ticketType ||
                          false
                        }
                        value={newLine.field}
                        onChange={event => {
                          setNewLine({
                            ...newLine,
                            field: event.target.value
                          });
                        }}
                      >
                        <MenuItem value={"u_knowledge"}>
                          Knowledge Base ID
                        </MenuItem>
                        <MenuItem value={"ticket_management"}>
                          Ticket Management Service
                        </MenuItem>
                        {ticketTypes &&
                          ticketTypes[
                            newLine?.ticketType
                          ]?.secondaryFields?.map((field: SecondaryFields) => {
                            return (
                              <MenuItem value={field.name}>
                                {field.displayName}
                              </MenuItem>
                            );
                          })}
                        <MenuItem value={"gtr"}>GTR</MenuItem>
                      </Select>
                    )}
                  </TableCell>
                  <TableCell>{newLine.mlModelStatus}</TableCell>
                  <TableCell>
                    {newLine.continuous ? <StyledDone /> : <StyledClear />}
                  </TableCell>
                  <TableCell>
                    <WeightingSlider
                      isDisabled={false}
                      weighting={newLine.weighting}
                      onSliderChange={(event: any, value: number) =>
                        setNewLine({
                          ...newLine,
                          weighting: value
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <NewIconButton
                      onClick={() => {
                        if (service && newLine) {
                          onSave(service, newLine);
                          setNewLine(undefined);
                        }
                      }}
                    >
                      <SaveIcon />
                    </NewIconButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledContainer>
      </>
    );
  }
);

export default MLTable;
