const path = require("path");
const dev = process.env.NODE_ENV === "development";
const local = process.env.REACT_APP_LOCAL === "local";
const urlConfig = window.urlConfig || {
  gateway: "/atr-gateway",
  mlGateway: "/atr-gateway",
  tickets: "/atr",
  chatbot: "/chatbot",
  knowledgebase: "/kb",
  aqe: "/kb-workflow",
  "identity-management": "/identity-management",
  "machine-learning": "/ingrain-core",
  wfm: "/wfm",
  ata: "/ata"
};

const atrUrl = "https://atr-develop.atrmywizard-aiops.com";
//const atrUrl = process.env.REACT_APP_DOMAIN;
// const atrUrl = "https://dh3-jacqui-test-x3mepcq0.atrmywizard-aiops.com";
// const atrUrl = "https://dh-atrperformancetesting.atrmywizard-aiops.com";
// const atrUrl = "https://dh-ajdemo.atrmywizard-aiops.com";

const base = {
  app: {
    root: path.normalize(path.join(__dirname)),
    env: process.env.NODE_ENV,
    images: `${process.env.PUBLIC_URL}/images`,
    domain: dev ? "localhost" : "clientSubdomain",
    redirectName: "atr",
    buildVersion: "1.3.20-release-3.3.6.1"
  },

  urls: {
    domain: local ? "http://localhost:8181" : dev ? `${atrUrl}` : "",
    authBase: local
      ? `${atrUrl}/atr-gateway/identity-management`
      : dev
      ? `${atrUrl}/atr-gateway`
      : urlConfig.gateway,
    base: local
      ? "http://localhost:8484"
      : dev
      ? `${atrUrl}/atr-gateway`
      : urlConfig.gateway,
    baseML: local
      ? "https://ingrain-dev.mywizard-aiops.com"
      : dev
      ? `${atrUrl}/atr-gateway`
      : urlConfig.mlGateway,
    apis: {
      auth: local ? "/api/v1/auth" : "/identity-management/api/v1/auth",
      "ticket-management": local ? "/api/v1" : "/ticket-management/api/v1",
      "identity-management": local ? "/api/v1" : "/identity-management/api/v1",
      "identity-management-v2": local
        ? "/api/v2"
        : "/identity-management/api/v2",
      "search-engine": "/search-engine/api/v1",
      automation: "/automation/api/v1",
      "knowledge-base": "/knowledgebase/api/v1/articles",
      ml: "/ingrain-core/api/v1",
      chatbot: "/bot/api/v1",
      notifications: "/notification/api/v1",
      aqe: "/automation-queue/api/v1",
      aqev2: "/automation-queue/api/v2",
      ata: "/ata"
    },
    apps: {
      tickets: urlConfig.tickets,
      chatbot: urlConfig.chatbot,
      "identity-management": urlConfig["identity-management"],
      "machine-learning": urlConfig["machine-learning"],
      knowledgebase: urlConfig.knowledgebase,
      aqe: urlConfig.aqe,
      wfm: urlConfig.wfm,
      ata: urlConfig.ata
    }
  },
  filters: {
    // daterange: true,
    applicationName: true,
    assignedTo: true,
    confidenceLevel: true,
    category: true
  },
  filterLabels: {
    applicationName: "Application Name",
    assignedTo: "Assigned To",
    confidenceLevel: "Prediction"
  }
};

module.exports = base;
