import "babel-polyfill";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import Errorboundary from "components/errorboundry/errorboundary";
import "roboto-npm-webfont";
import "typeface-roboto";
import NotFound from "./components/errors/NotFound";
import ServerError from "./components/errors/ServerError";
import Application from "./containers/MainContainer";
import history from "./history";
import "./index.scss";
import { store, persistor } from "./configureStore";
import muiTheme from "./styles/themeMainUI";
import { PersistGate } from "redux-persist/lib/integration/react";

window.onerror = (message, source, lineno, colno, error) => {
  if (source.indexOf("/src/eventsource.min.js") > -1) {
    return true;
  }
};

function main(app) {
  render(
    <Errorboundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <MuiThemeProvider muiTheme={muiTheme}>
              <Switch>
                <Route
                  path="/atr/404"
                  render={props => <NotFound {...props} />}
                />
                <Route
                  path="/atr/500"
                  render={props => <ServerError {...props} />}
                />
                <Route
                  path="/atr"
                  render={props => <Application {...props} />}
                />
                <Redirect path="/" to="/atr" />
              </Switch>
            </MuiThemeProvider>
          </Router>
        </PersistGate>
      </Provider>
    </Errorboundary>,
    app
  );
}

const app = document.createElement("div");
app.className = "template_main";
document.body.appendChild(app);

main(app);
