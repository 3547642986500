import {
  cyan500,
  lightBlue800,
  red500,
  grey50,
  grey100,
  grey300,
  grey400,
  grey500,
  grey600,
  grey700,
  grey800,
  grey900,
  white,
  darkBlack,
  fullBlack
} from "material-ui/styles/colors";
import { fade } from "material-ui/utils/colorManipulator";
// import spacing from 'material-ui';

// import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import getMuiTheme from "material-ui/styles/getMuiTheme";

const muiTheme = getMuiTheme({
  // spacing: spacing,
  fontFamily: '"Open Sans", sans-serif',
  palette: {
    navbarColor: "#0C2E33",
    primary1Color: grey100,
    primary2Color: lightBlue800,
    primary3Color: grey400,
    accent1Color: "#0A83C4",
    accent2Color: grey100,
    accent3Color: grey500,
    accent4Color: grey700,
    textColor: grey900,
    alternateTextColor: grey900,
    canvasColor: white,
    borderColor: grey300,
    disabledColor: fade(darkBlack, 0.3),
    pickerHeaderColor: cyan500,
    clockCircleColor: fade(darkBlack, 0.07),
    shadowColor: fullBlack,
    errorColor: red500,
    automationBackgroundColor: "#21BE9F",
    primaryBtnBackgroundColor: "#0A83C4",
    secondaryBtnBorderColor: "#C3CBCC",
    secondaryBtn: {
      textColor: "#C3CBCC",
      borderColor: "#C3CBCC",
      borderRadius: "4px"
    },
    warningColor: "#FFBE00"
  },
  appBar: {
    height: 50,
    color: grey50,
    textColor: grey900
  },
  tableRow: {
    fontSize: "12px",
    selectedColor: "#FFFDE7",
    hoverColor: "#FFFDE7"
  },
  secondaryBorderedStyle: {
    primary: grey800,
    secondary: grey600,
    disabled: grey300
  },
  datePicker: {
    textColor: "white",
    calendarTextColor: "white"
  },
  slider: {
    trackColor: "rgb(189, 189, 189)",
    selectionColor: "rgb(0, 188, 212)"
  }
});

export default muiTheme;
