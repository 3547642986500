import React from "react";
import PropTypes from "prop-types";
import { CheckboxContainer, StyledCheckbox } from "./styles";

const ConfirmationCheckbox = props => {
  const { onClick, checked, children } = props;
  return (
    <CheckboxContainer onClick={onClick}>
      <StyledCheckbox color="primary" checked={checked} />
      {children}
    </CheckboxContainer>
  );
};

ConfirmationCheckbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

export default ConfirmationCheckbox;
