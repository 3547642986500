import React from "react";
import moment from "moment";
import { List, ListItem } from "material-ui/List";
import Avatar from "material-ui/Avatar";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import ActionOpenInNew from "material-ui/svg-icons/action/open-in-new";
import PropTypes from "prop-types";
import { FontIcon } from "material-ui";
import SecondaryButton from "../templates/SecondaryButton";

const notificationsDropdownMenu = props => {
  const {
    unreadNotifications,
    handleMenuClick,
    markNotificationAsRead
  } = props;

  return (
    <div
      style={{
        margin: "0 auto",
        width: "25em"
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            padding: "15px 15px",
            fontSize: "14px",
            marginRight: "auto"
          }}
        >
          Notifications
        </div>
        <IconButton onClick={() => handleMenuClick("/atr/notifications")}>
          <ActionOpenInNew />
        </IconButton>
      </div>

      <Divider />
      {unreadNotifications.length > 0 ? (
        <List>
          {unreadNotifications.map((notif, i) => (
            <div
              key={`${i}_notifications`}
              onClick={() => {
                markNotificationAsRead(notif.id);
              }}
            >
              <ListItem
                style={{
                  fontSize: 12
                }}
                innerDivStyle={{
                  paddingTop: 8,
                  paddingBottom: 8
                }}
                leftAvatar={
                  <div>
                    {!notif.read ? (
                      <div
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0
                        }}
                      >
                        <FontIcon
                          style={{ fontSize: 14 }}
                          color="#ff4146"
                          className="fa fa-circle"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <Avatar>{notif.createdBy[0].toUpperCase()}</Avatar>
                  </div>
                }
                containerElement="div"
                primaryText={
                  <div>
                    <strong style={{ margin: 0 }}>{notif.type} </strong>
                    <p
                      style={{
                        lineHeight: 1.25,
                        margin: "5px 0"
                      }}
                    >
                      {notif.content}
                    </p>
                  </div>
                }
                secondaryText={
                  <div
                    style={{
                      fontSize: 12
                    }}
                  >
                    <span>
                      {notif.createdBy} - {moment(notif.date).fromNow()}{" "}
                    </span>
                  </div>
                }
                secondaryTextLines={1}
              />
            </div>
          ))}
          <div style={{ textAlign: "center" }}>
            <SecondaryButton
              style={{ width: "100%" }}
              label="View All"
              secondary
              onClick={() => handleMenuClick("/atr/notifications")}
            />
          </div>
        </List>
      ) : (
        <List
          style={{ fontSize: "0.75rem", color: "grey", textAlign: "center" }}
        >
          No new notifications!
        </List>
      )}
    </div>
  );
};

notificationsDropdownMenu.propTypes = {
  notifications: PropTypes.array,
  handleMenuClick: PropTypes.func,
  markNotificationAsRead: PropTypes.func,
  getUnreadNotifications: PropTypes.func
  //isFetchingNotifications: PropTypes.bool
};

export default notificationsDropdownMenu;
