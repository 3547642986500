import React from "react";
import PropTypes from "prop-types";
import { List, ListItemText } from "@material-ui/core";
import { MainContainer, StyledListItem, StyledIcon } from "./styles";

const ProviderList = props => {
  const { selectedProviders, curProvider, setCurProvider } = props;

  return (
    <MainContainer>
      <span>Selected Providers:</span>
      <List>
        {selectedProviders.map(provider => {
          const isSelected = curProvider === provider.value;
          return (
            <StyledListItem
              button
              key={provider.label}
              isSelected={isSelected}
              onClick={() => setCurProvider(provider.value)}
            >
              <StyledIcon
                icon={provider.icon}
                size="2x"
                isSelected={isSelected}
              />
              <ListItemText>{provider.label}</ListItemText>
            </StyledListItem>
          );
        })}
      </List>
    </MainContainer>
  );
};

ProviderList.propTypes = {
  selectedProviders: PropTypes.array.isRequired,
  curProvider: PropTypes.string,
  setCurProvider: PropTypes.func
};

export default ProviderList;
