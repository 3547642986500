import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const NavButtonContainers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const ConfirmButton = styled(Button)`
  background-color: #0085ff !important;
  text-transform: none !important;
`;

export const CancelButton = styled(Button)`
  border: 0.5px solid #0085ff !important;
  margin-right: 10px !important;
  color: #0085ff !important;
  text-transform: none !important;
`;
