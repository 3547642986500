import React from "react";

const TicketTableNoTicket = () => {
  return (
    <div style={{ textAlign: "center", margin: "50px" }}>
      No tickets. Try changing the filters or refreshing the page.
    </div>
  );
};

export default TicketTableNoTicket;
