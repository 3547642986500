import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { PROVIDERS } from "../../../models/AutomationFilterModel";
import ResponsiveView from "./ResponsiveView/container";

// Responsible for loading values
const AutomationQuickstart = props => {
  const {
    configs,
    getConfig,
    openPlugin,
    getDemoWorkflows,
    getMappings,
    getRules,
    getUsers
  } = props;

  useEffect(() => {
    getDemoWorkflows();
    getMappings();
    getRules();
    getUsers();
    const snowConfig = configs.byId.servicenow;
    if (snowConfig) {
      if (!snowConfig.isLoading) {
        openPlugin(PROVIDERS.serviceNow);
      }
    } else {
      getConfig(PROVIDERS.serviceNow);
    }
  }, [
    configs,
    getConfig,
    openPlugin,
    getDemoWorkflows,
    getMappings,
    getRules,
    getUsers
  ]);

  return <ResponsiveView />;
};

AutomationQuickstart.propTypes = {
  configs: PropTypes.object.isRequired,
  getConfig: PropTypes.func.isRequired,
  openPlugin: PropTypes.func.isRequired,
  getDemoWorkflows: PropTypes.func.isRequired,
  getMappings: PropTypes.func.isRequired,
  getRules: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired
};

export default AutomationQuickstart;
