import React from "react";
import config from "../../config";

const BackToTop = () => {
  const scrollToTop = () => {
    document.querySelector("#ticket-table").scrollTop = 0;
  };

  return (
    <div
      style={{
        float: "right",
        position: "sticky",
        bottom: "12%",
        left: "100%",
        marginRight: "20px"
      }}
    >
      <img
        style={{ cursor: "pointer" }}
        src={`${config.app.images}/back-to-top.svg`}
        width="45"
        height="35"
        alt="back-to-top"
        onClick={scrollToTop}
      ></img>
    </div>
  );
};

export default BackToTop;
