import { userConfig } from "../../userConfigValidations";

export const menus = [
  "apps",
  "bulkActions",
  "notifications",
  "settings",
  "profiles"
];

export const views = [
  {
    icon: "th", // <EditorShowChart />,
    url: "/apps",
    state: "apps"
  },
  {
    icon: "tasks",
    url: "/bulk-actions",
    state: "bulkActions"
  },
  {
    icon: "commenting-o", // <ImageViewComfy />,
    url: "/chatbot",
    state: "chatbot"
  },
  {
    icon: "bell", // <CommunicationChat />,
    url: "/notifications",
    state: "notifications"
  },
  {
    icon: "cog", // <CommunicationChat />,
    url: "/settings",
    state: "settings"
  },
  {
    icon: "rocket",
    url: "/settings/quickstart",
    state: "diy"
  }
];

export const settings = [
  {
    icon: "puzzle-piece",
    text: "Dry Run",
    url: "/atr/settings/dry-run"
  },
  {
    icon: "ticket",
    text: "Ticket Templates",
    url: "/atr/settings/ticket-template"
  },
  // {
  //   icon: "puzzle-piece",
  //   text: "Ticket Configuration",
  //   url: "/atr/settings/ticket-configuration"
  // },
  {
    icon: "list-ol",
    text: "Automation Rules",
    url: "/atr/settings/automation/rules"
  },
  {
    icon: "map",
    text: "Automation Mappings",
    url: "/atr/settings/automation/mapping"
  },
  {
    icon: "envelope-o",
    text: "Email Templates",
    url: "/atr/settings/email-templates"
  },
  {
    icon: "tags",
    text: "Merge Tags",
    url: "/atr/settings/merge-tags"
  },
  {
    icon: "question",
    text: "Help",
    url: "/atr/help"
  }
].filter(value => {
  return !(!userConfig.variables.dryRun && value.text === "Dry Run");
});

export const profiles = [
  {
    icon: "user",
    text: "My Profile",
    url: "/atr/settings/profile"
  },
  {
    icon: "sign-out",
    text: "Sign Out",
    url: "/atr/logout"
  }
];
