import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const PrerequisiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  background: #ebf7ff;
  border: 1px solid #378bee;
  border-radius: 3px;
  padding: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ListContainer = styled.ul`
  margin-top: 1px;
  font-size: 12px;
  font-family: Open Sans;
`;

export const StyledText = styled(Typography)`
  @media screen and (max-height: 768px) {
    font-size: 0.6rem !important;
  }

  @media screen and (min-height: 769px) and (max-height: 992px) {
    font-size: 0.8rem !important;
  }
`;
