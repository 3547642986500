export const defaultTicketField = {
  SR_ITEM: [
    {
      name: "number",
      displayName: "Functional Id",
      location: "coreData"
    },
    {
      name: "state",
      displayName: "Approval",
      location: "coreData"
    },
    {
      name: "shortDescription",
      displayName: "Item",
      location: "coreData"
    },
    {
      name: "application",
      displayName: "Quantity",
      location: "coreData"
    },
    {
      name: "assignee",
      displayName: "Due Date",
      location: "coreData"
    }
  ],
  SERVICE_REQUEST: [
    {
      name: "number",
      displayName: "Functional Id",
      location: "coreData"
    },
    {
      name: "state",
      displayName: "State",
      location: "coreData"
    },
    {
      name: "shortDescription",
      displayName: "Requested For",
      location: "coreData"
    },
    {
      name: "application",
      displayName: "Opened By",
      location: "coreData"
    },
    {
      name: "assignee",
      displayName: "Due Date",
      location: "coreData"
    }
  ],
  DEFAULT: [
    {
      name: "confidenceLevel",
      displayName: "Prediction",
      location: "automationData"
    },
    {
      name: "number",
      displayName: "Functional Id",
      location: "coreData"
    },
    {
      name: "state",
      displayName: "State",
      location: "coreData"
    },
    {
      name: "shortDescription",
      displayName: "Short Description",
      location: "coreData"
    },
    {
      name: "application",
      displayName: "Application",
      location: "coreData"
    },
    {
      name: "assignee",
      displayName: "Assignee",
      location: "coreData"
    },
    {
      name: "lastUpdateDate",
      displayName: "Last Update Date",
      location: "coreData"
    }
  ]
};
