import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import UseCaseFilter from "./components/UseCaseFilter";
import UseCaseSelection from "./components/UseCaseSelection";
import {
  UseCaseContainer,
  UseCaseFilterContainer,
  UseCaseSelectionContainer,
  ButtonContainer,
  PrimaryButton,
  SecondaryButton,
  GIFImage
} from "./styles";
import { ScrollContainer } from "../../components/BasePanel/styles";
import { OpenInNew } from "@material-ui/icons";
import config from "../../../../../../config";
import toMarketplaceGif from "../../../../../../assets/to-marketplace.gif";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

const EnableUseCasePanel = props => {
  const {
    workflows,
    selectedWorkflows,
    workflowFilters,
    addWorkflowFilter,
    removeWorkflowFilter,
    clearWorkflowFilter,
    setSelectedWorkflows
  } = props;

  const [hasDialog, setHasDialog] = useState(false);

  // Workflow categories used for filtering
  const workflowCategories = useMemo(() => {
    const workflowCategories = {};
    workflows.forEach(workflow => {
      const isSelected = selectedWorkflows.some(w => w.id === workflow.id);
      workflow.tags.forEach(tag => {
        const category = tag.split("/")[0];
        if (!workflowCategories[category]) {
          workflowCategories[category] = {};
          workflowCategories[category].workflows = [];
          workflowCategories[category].numSelected = 0;
        }
        // Can have multiple tags on a workflow that have the same category
        // Only add the workflow once
        if (
          workflowCategories[category].workflows.every(
            w => w.id !== workflow.id
          )
        ) {
          workflowCategories[category].numSelected += isSelected ? 1 : 0;
          workflowCategories[category].workflows.push(workflow);
        }
      });
    });
    return workflowCategories;
  }, [selectedWorkflows, workflows]);

  // Logical groupings for workflow/usecase selection
  const workflowGroups = useMemo(() => {
    const workflowGroups = {};
    // Apply filters
    const filteredWorkflows =
      workflowFilters.length > 0
        ? workflows.filter(workflow =>
            workflow.tags.some(tag => {
              const category = tag.split("/")[0];
              return workflowFilters.includes(category);
            })
          )
        : workflows;

    filteredWorkflows.forEach(workflow => {
      const isSelected = selectedWorkflows.some(w => w.id === workflow.id);
      const transformedWorkflow = {
        ...workflow,
        isSelected
      };
      workflow.tags.forEach(tag => {
        const group = tag.split("/")[1];

        if (!workflowGroups[group]) {
          workflowGroups[group] = {};
          workflowGroups[group].workflows = [];
          workflowGroups[group].numSelected = 0;
        }
        // Though not ideal or expected, technically can have multiple tags with multiple groups on a single workflow
        // Only add the workflow once
        if (workflowGroups[group].workflows.every(w => w.id !== workflow.id)) {
          workflowGroups[group].numSelected += isSelected ? 1 : 0;
          workflowGroups[group].workflows.push(transformedWorkflow);
        }
      });
    });
    return workflowGroups;
  }, [selectedWorkflows, workflowFilters, workflows]);

  const toggleWorkflow = useCallback(
    workflow => {
      let isEnabled = false;
      const selectedWfCopy = [...selectedWorkflows];
      const wfIdx = selectedWfCopy.findIndex(wf => wf.id === workflow.id);
      if (wfIdx >= 0) {
        selectedWfCopy.splice(wfIdx, 1);
      } else {
        selectedWfCopy.push(workflow);
        isEnabled = true;
      }
      setSelectedWorkflows(selectedWfCopy);
      return isEnabled;
    },
    [selectedWorkflows, setSelectedWorkflows]
  );

  const selectSingle = useCallback(
    workflow => {
      toggleWorkflow(workflow);
    },
    [toggleWorkflow]
  );

  const toggleFilter = useCallback(
    filter => {
      if (workflowFilters.includes(filter)) {
        removeWorkflowFilter(filter);
      } else {
        addWorkflowFilter(filter);
      }
    },
    [addWorkflowFilter, removeWorkflowFilter, workflowFilters]
  );

  return (
    <UseCaseContainer container>
      <UseCaseFilterContainer item xs={3} direction="column">
        <UseCaseFilter
          workflowCategories={workflowCategories}
          workflowFilters={workflowFilters}
          toggleFilter={toggleFilter}
          clearFilter={clearWorkflowFilter}
        />
        <ButtonContainer>
          <SecondaryButton
            color="primary"
            variant="outlined"
            onClick={() => setHasDialog(true)}
          >
            See more workflows
            <OpenInNew style={{ paddingLeft: 20 }} />
          </SecondaryButton>
        </ButtonContainer>
      </UseCaseFilterContainer>
      <UseCaseSelectionContainer item xs={9}>
        <ScrollContainer>
          <UseCaseSelection
            workflowGroups={workflowGroups}
            onSelectSingle={selectSingle}
          />
          <SecondaryButton
            color="primary"
            variant="outlined"
            onClick={() => setHasDialog(true)}
          >
            See more workflows
            <OpenInNew style={{ paddingLeft: 20 }} />
          </SecondaryButton>
        </ScrollContainer>
      </UseCaseSelectionContainer>
      <Dialog open={hasDialog} onClose={() => setHasDialog(false)}>
        <DialogTitle>See more workflows</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Access a whole range of automation workflows built by myWizard and
            the ATR community by visiting our global{" "}
            <strong>Marketplace</strong>
          </DialogContentText>
          <GIFImage
            src={toMarketplaceGif}
            alt="Navigate to WFM and press the import button"
          />
        </DialogContent>
        <DialogActions style={{ marginBottom: 20 }}>
          <SecondaryButton onClick={() => setHasDialog(false)} color="primary">
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              const url = config.urls.apps.wfm;
              window.open(url);
            }}
            color="primary"
          >
            Proceed
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </UseCaseContainer>
  );
};

EnableUseCasePanel.propTypes = {
  workflows: PropTypes.array.isRequired,
  setSelectedWorkflows: PropTypes.func.isRequired,
  addWorkflowFilter: PropTypes.func.isRequired,
  removeWorkflowFilter: PropTypes.func.isRequired,
  clearWorkflowFilter: PropTypes.func.isRequired,
  selectedWorkflows: PropTypes.array,
  workflowFilters: PropTypes.array.isRequired
};

EnableUseCasePanel.defaultProps = {
  selectedWorkflows: []
};

export default EnableUseCasePanel;
